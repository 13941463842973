const Coordinates = new Map([
  [3450554, [-12.97111, -38.51083]],
  [1269515, [26.91962, 75.78781]],
  [160263, [-6.82349, 39.26951]],
  [1804430, [36.05701, 103.83987]],
  [1843564, [37.45646, 126.70515]],
  [99532, [30.50852, 47.7804]],
  [6619347, [19.03681, 73.01582]],
  [6167865, [43.70011, -79.4163]],
  [1853909, [34.69374, 135.50218]],
  [53654, [2.03711, 45.34375]],
  [1835329, [35.87028, 128.59111]],
  [1179400, [31.41554, 73.08969]],
  [311046, [38.41273, 27.13838]],
  [2253354, [14.6937, -17.44406]],
  [1264733, [26.83928, 80.92313]],
  [360995, [30.00944, 31.20861]],
  [3399415, [-3.71722, -38.54306]],
  [3687925, [3.43722, -76.5225]],
  [1625822, [-7.24917, 112.75083]],
  [3470127, [-19.92083, -43.93778]],
  [1800163, [28.68396, 115.85306]],
  [3169070, [41.89193, 12.51133]],
  [5110302, [40.6501, -73.94958]],
  [4699066, [29.76328, -95.36327]],
  [5133273, [40.68149, -73.83652]],
  [1262180, [21.14631, 79.08491]],
  [3633009, [10.66663, -71.61245]],
  [3469058, [-15.77972, -47.92972]],
  [3492908, [18.47186, -69.89232]],
  [1856057, [35.18147, 136.90641]],
  [2174003, [-27.46794, 153.02809]],
  [3553478, [23.13302, -82.38304]],
  [2988507, [48.85341, 2.3488]],
  [99071, [36.33271, 43.10555]],
  [993800, [-26.20227, 28.04363]],
  [1819609, [22.31667, 114.18333]],
  [388349, [30.50316, 47.81507]],
  [1526384, [43.25667, 76.92861]],
  [1917790, [18.22056, 109.51028]],
  [3674962, [6.25184, -75.56359]],
  [1512569, [41.26465, 69.21627]],
  [2507480, [36.73225, 3.08746]],
  [379252, [15.55177, 32.53241]],
  [2306104, [5.55602, -0.1969]],
  [8347664, [39.6086, 109.78157]],
  [71137, [15.35472, 44.20667]],
  [276781, [33.89332, 35.50157]],
  [2063523, [-31.95224, 115.8614]],
  [2128295, [43.06667, 141.35]],
  [2036502, [43.85083, 126.56028]],
  [683506, [44.43225, 26.10626]],
  [1269743, [22.71792, 75.8333]],
  [3526683, [19.35529, -99.06224]],
  [2422465, [9.53795, -13.67729]],
  [11838258, [40.74143, 107.38599]],
  [3632998, [10.23535, -67.59113]],
  [1214520, [3.58333, 98.66667]],
  [1166993, [33.59733, 73.0479]],
  [625144, [53.9, 27.56667]],
  [3054643, [47.49835, 19.04045]],
  [99072, [36.335, 43.11889]],
  [2911298, [53.55073, 9.99302]],
  [3464975, [-25.42778, -49.27306]],
  [756135, [52.22977, 21.01178]],
  [1650357, [-6.92222, 107.60694]],
  [2761369, [48.20849, 16.37208]],
  [12165956, [11.73379, 78.95925]],
  [2538475, [34.01325, -6.83255]],
  [3529612, [19.60492, -99.06064]],
  [964137, [-25.74486, 28.18783]],
  [170063, [36.20124, 37.16117]],
  [1701668, [14.6042, 120.9822]],
  [6077243, [45.50884, -73.58781]],
  [1785623, [31.37762, 120.95431]],
  [1260086, [25.59408, 85.13563]],
  [1275841, [23.25469, 77.40289]],
  [3663517, [-3.10194, -60.025]],
  [1788534, [32.12278, 114.06556]],
  [2335727, [10.52641, 7.43879]],
  [1821306, [11.56245, 104.91601]],
  [4560349, [39.95233, -75.16379]],
  [5308655, [33.44838, -112.07404]],
  [418863, [32.65246, 51.67462]],
  [1264728, [30.91204, 75.85379]],
  [890299, [-17.82772, 31.05337]],
  [1859171, [34.6913, 135.183]],
  [1649378, [-6.2349, 106.9896]],
  [1673820, [22.61626, 120.31333]],
  [2673730, [59.32938, 18.06871]],
  [1720151, [14.64953, 120.96788]],
  [5125771, [40.78343, -73.96625]],
  [3439389, [-25.28646, -57.647]],
  [3390760, [-8.05389, -34.88111]],
  [4726206, [29.42412, -98.49363]],
  [2298890, [6.68848, -1.62443]],
  [1736376, [6.12361, 102.24333]],
  [1857910, [35.02107, 135.75385]],
  [1735161, [3.1412, 101.68653]],
  [128747, [35.83266, 50.99155]],
  [1283240, [27.70169, 85.3206]],
  [1633070, [-2.91673, 104.7458]],
  [1169825, [30.19679, 71.47824]],
  [1254361, [8.72742, 77.6838]],
  [3521081, [19.03793, -98.20346]],
  [1581130, [21.0245, 105.84117]],
  [706483, [49.98081, 36.25272]],
  [1279259, [27.18333, 78.01667]],
  [3860259, [-31.4135, -64.18105]],
  [113646, [38.08, 46.2919]],
  [1841811, [35.15472, 126.91556]],
  [750269, [40.19559, 29.06013]],
  [1253573, [22.29941, 73.20812]],
  [3405870, [-1.45583, -48.50444]],
  [2037355, [41.88669, 123.94363]],
  [3652462, [-0.22985, -78.52495]],
  [5391811, [32.71571, -117.16472]],
  [1863967, [33.6, 130.41667]],
  [1070940, [-18.91368, 47.53613]],
  [1801792, [34.68361, 112.45361]],
  [5110266, [40.84985, -73.86641]],
  [3625549, [10.16202, -68.00765]],
  [1177662, [32.15567, 74.18705]],
  [3689147, [10.96854, -74.78132]],
  [1802875, [28.15861, 113.62709]],
  [922704, [-11.66089, 27.47938]],
  [1625084, [-6.17806, 106.63]],
  [1262111, [28.60922, 76.97982]],
  [3904906, [-17.78629, -63.18117]],
  [1808963, [36.60999, 114.48764]],
  [232422, [0.31628, 32.58219]],
  [1336135, [22.80979, 89.56439]],
  [2232593, [4.04827, 9.70428]],
  [1270926, [29.44768, 75.67206]],
  [292672, [25.33737, 55.41206]],
  [104515, [21.42664, 39.82563]],
  [1622786, [-5.14861, 119.43194]],
  [4013708, [31.72024, -106.46084]],
  [1859642, [35.52056, 139.71722]],
  [8581443, [-6.28862, 106.71789]],
  [2038432, [40.58337, 110.01669]],
  [3981609, [32.5027, -117.00371]],
  [4684888, [32.78306, -96.80667]],
  [109223, [24.46861, 39.61417]],
  [2220957, [3.86667, 11.51667]],
  [2460596, [12.65, -8.0]],
  [1261731, [19.99727, 73.79096]],
  [1627896, [-6.99306, 110.42083]],
  [2260535, [-4.26613, 15.28318]],
  [1259652, [18.62292, 73.80696]],
  [520555, [56.32867, 44.00205]],
  [250441, [31.95522, 35.94503]],
  [1723510, [7.20417, 124.43972]],
  [3441575, [-34.90328, -56.18816]],
  [909137, [-15.40669, 28.28713]],
  [1788046, [34.03189, 113.86299]],
  [1268295, [19.2437, 73.13554]],
  [1254661, [19.19704, 72.96355]],
  [268743, [33.9, 35.48333]],
  [115019, [29.61031, 52.53113]],
  [325363, [36.98615, 35.32531]],
  [1835553, [37.29111, 127.00889]],
  [3998655, [21.12908, -101.67374]],
  [3173435, [45.46427, 9.18951]],
  [3718426, [18.54349, -72.33881]],
  [2078025, [-34.92866, 138.59863]],
  [1263214, [28.98002, 77.70636]],
  [1261162, [19.23114, 82.54826]],
  [1271951, [28.41124, 77.31316]],
  [1168197, [34.008, 71.57849]],
  [1715348, [7.07306, 125.61278]],
  [1927639, [29.37455, 113.09481]],
  [3492914, [19.4517, -70.69703]],
  [2038632, [41.12361, 122.99]],
  [1787824, [34.18045, 117.15707]],
  [1271308, [28.66535, 77.43915]],
  [1645524, [-6.4, 106.81861]],
  [6940394, [35.90807, 139.65657]],
  [1272423, [19.21667, 73.08333]],
  [1040652, [-25.96553, 32.58322]],
  [3514674, [19.49392, -99.11075]],
  [1810821, [26.06139, 119.30611]],
  [1258847, [22.29161, 70.79322]],
  [3838583, [-32.94682, -60.63932]],
  [1809461, [26.58333, 106.71667]],
  [3462377, [-16.67861, -49.25389]],
  [3461786, [-23.46278, -46.53333]],
  [3067696, [50.08804, 14.42076]],
  [1253405, [25.31668, 83.01041]],
  [6295587, [1.14937, 104.02491]],
  [2618425, [55.67594, 12.56553]],
  [727011, [42.69751, 23.32415]],
  [2210247, [32.88743, 13.18733]],
  [2324774, [4.77742, 7.0134]],
  [3979770, [20.72356, -103.38479]],
  [1813253, [26.86879, 100.22072]],
  [3995465, [25.67507, -100.31847]],
  [499099, [53.20007, 50.15]],
  [1496153, [54.99244, 73.36859]],
  [2347283, [6.33815, 5.62575]],
  [1978681, [7.16083, 124.475]],
  [587084, [40.37767, 49.89201]],
  [2331447, [11.84692, 13.15712]],
  [551487, [55.78874, 49.12214]],
  [3530589, [19.40061, -99.01483]],
  [616052, [40.18111, 44.51361]],
  [1278710, [31.62234, 74.87534]],
  [2357048, [12.36566, -1.53388]],
  [1848254, [35.88333, 139.63333]],
  [501175, [47.23135, 39.72328]],
  [1278994, [25.44478, 81.84322]],
  [1842485, [37.65639, 126.835]],
  [314830, [37.05944, 37.3825]],
  [1253102, [17.68009, 83.20161]],
  [2111149, [38.26667, 140.86667]],
  [1508291, [55.15402, 61.42915]],
  [2037799, [40.09361, 113.29139]],
  [611717, [41.69411, 44.83368]],
  [7283386, [31.64615, 120.74221]],
  [1668399, [24.1469, 120.6839]],
  [1254745, [10.01115, 77.47772]],
  [1790353, [34.33778, 108.70261]],
  [479561, [54.74306, 55.96779]],
  [3467865, [-22.90556, -47.06083]],
  [1269633, [23.16697, 79.95006]],
  [1270396, [22.57688, 88.31857]],
  [1807681, [32.62639, 116.99694]],
  [5392171, [37.33939, -121.89496]],
  [709717, [48.023, 37.80224]],
  [2964574, [53.33306, -6.24889]],
  [5913490, [51.05011, -114.08529]],
  [2800866, [50.85045, 4.34878]],
  [1278149, [19.87757, 75.34226]],
  [698740, [46.48572, 30.74383]],
  [472757, [48.71939, 44.50183]],
  [3456160, [-22.75917, -43.45111]],
  [1797121, [23.5418, 116.36581]],
  [7602670, [35.99502, 119.40259]],
  [709930, [48.46664, 35.04066]],
  [1859307, [33.85181, 130.85034]],
  [1256436, [17.67152, 75.91044]],
  [1816971, [38.85111, 115.49028]],
  [3598132, [14.64072, -90.51327]],
  [2038300, [41.28861, 123.765]],
  [2655603, [52.48142, -1.89983]],
  [511196, [58.01046, 56.25017]],
  [1255634, [34.08565, 74.80555]],
  [2317765, [11.11128, 7.7227]],
  [3617763, [12.13282, -86.2504]],
  [964420, [-33.96109, 25.61494]],
  [2548885, [34.03313, -5.00028]],
  [2886242, [50.93333, 6.95]],
  [1833747, [35.53722, 129.31667]],
  [1274746, [30.73629, 76.7884]],
  [3172394, [40.85216, 14.26811]],
  [3395981, [-9.66583, -35.73528]],
  [3687238, [10.39972, -75.51444]],
  [1815456, [31.77359, 119.95401]],
  [101760, [24.49258, 39.58572]],
  [2274895, [6.30054, -10.7969]],
  [3489854, [17.99702, -76.79358]],
  [95446, [36.18333, 44.01193]],
  [4671654, [30.26715, -97.74306]],
  [1502026, [56.01839, 92.86717]],
  [6611854, [19.745, 96.12972]],
  [1268865, [26.26841, 73.00594]],
  [2113015, [35.6, 140.11667]],
  [3388368, [-2.52972, -44.30278]],
  [1264521, [9.919, 78.11953]],
  [2537763, [34.0531, -6.79846]],
  [1807700, [33.97444, 116.79167]],
  [3919968, [-17.3895, -66.1568]],
  [1528675, [42.87, 74.59]],
  [100077, [33.30563, 44.18477]],
  [1804879, [34.7986, 114.30742]],
  [2293521, [5.41613, -4.0159]],
  [119208, [34.6401, 50.8764]],
  [1271476, [26.1844, 91.7458]],
  [2353151, [5.10658, 7.36667]],
  [1798827, [33.73847, 113.30119]],
  [1270583, [26.22983, 78.17337]],
  [2035265, [47.34088, 123.96045]],
  [1732905, [3.03667, 101.44333]],
  [3844421, [-32.89084, -68.82717]],
  [306571, [37.87135, 32.48464]],
  [209228, [-6.13603, 23.58979]],
  [1253184, [16.50745, 80.6466]],
  [2246678, [14.76457, -17.39071]],
  [3165524, [45.07049, 7.68682]],
  [1262321, [12.29791, 76.63925]],
  [4160021, [30.33218, -81.65565]],
  [1791388, [27.99942, 120.66682]],
  [5391959, [37.77493, -122.41942]],
  [2644210, [53.41058, -2.97794]],
  [498677, [51.54056, 46.00861]],
  [12069922, [28.74322, 77.06778]],
  [3600949, [14.0818, -87.20681]],
  [1838716, [37.49889, 126.78306]],
  [472045, [51.67204, 39.1843]],
  [1258526, [23.34316, 85.3094]],
  [2028462, [47.90771, 106.88324]],
  [1821940, [10.99081, 104.78498]],
  [144448, [31.31901, 48.6842]],
  [3947322, [-16.39889, -71.535]],
  [1633419, [-0.94924, 100.35427]],
  [1269920, [15.34776, 75.13378]],
  [1785412, [31.25861, 121.45972]],
  [2542997, [31.63416, -7.99994]],
  [1645528, [-8.65, 115.21667]],
  [1771023, [3.15, 101.53333]],
  [4691930, [32.72541, -97.32085]],
  [4259418, [39.76838, -86.15804]],
  [4460243, [35.22709, -80.84313]],
  [3464374, [-22.78556, -43.31167]],
  [2335953, [9.92849, 8.89212]],
  [2337639, [8.49664, 4.54214]],
  [3946083, [-12.05659, -77.11814]],
  [3911925, [-16.5, -68.15]],
  [6094817, [45.41117, -75.69812]],
  [3648522, [10.0647, -69.35703]],
  [4014338, [28.63528, -106.08889]],
  [2409306, [8.48714, -13.2356]],
  [1732752, [1.4655, 103.7578]],
  [281184, [31.76904, 35.21633]],
  [1624917, [-5.42917, 105.26111]],
  [1261809, [28.85267, 77.09288]],
  [1648473, [-6.59444, 106.78917]],
  [186301, [-4.05466, 39.66359]],
  [1717512, [10.31672, 123.89071]],
  [287286, [23.58413, 58.40778]],
  [250090, [32.07275, 36.08796]],
  [3522790, [19.47851, -99.23963]],
  [6955677, [39.9179, 32.86268]],
  [1167460, [28.41987, 70.30345]],
  [10630176, [34.77772, 48.47168]],
  [2474141, [36.76639, 3.47717]],
  [1268782, [31.32556, 75.57917]],
  [1254163, [8.4855, 76.94924]],
  [1785294, [36.096, 114.38278]],
  [3836873, [-26.82414, -65.2226]],
  [2394819, [6.36536, 2.41833]],
  [1257629, [11.65376, 78.15538]],
  [3523349, [20.97537, -89.61696]],
  [1254388, [10.8155, 78.69651]],
  [169577, [34.72682, 36.72339]],
  [2036892, [40.81056, 111.65222]],
  [2440485, [13.51366, 2.1098]],
  [1668355, [22.99083, 120.21333]],
  [1817720, [30.01556, 120.87111]],
  [3093133, [51.77058, 19.47395]],
  [110336, [26.43442, 50.10326]],
  [1788852, [36.62554, 101.75739]],
  [23814, [34.3838, 47.0553]],
  [1266049, [25.18254, 75.83907]],
  [3394023, [-5.795, -35.20944]],
  [1275817, [20.27241, 85.83385]],
  [1797595, [39.93167, 119.58833]],
  [1808370, [26.88946, 112.61888]],
  [3094802, [50.06143, 19.93658]],
  [1279017, [27.88145, 78.07464]],
  [1583992, [16.06778, 108.22083]],
  [965301, [-29.61679, 30.39278]],
  [2365267, [6.12874, 1.22154]],
  [3691175, [-8.11599, -79.02998]],
  [1636722, [-7.9797, 112.6304]],
  [3645528, [8.35122, -62.64102]],
  [1277013, [28.36678, 79.43167]],
  [202061, [-1.94995, 30.05885]],
  [3386496, [-5.08917, -42.80194]],
  [1788572, [35.19033, 113.80151]],
  [3449344, [-23.69389, -46.565]],
  [2036986, [47.35118, 130.30012]],
  [456172, [56.946, 24.10589]],
  [2759794, [52.37403, 4.88969]],
  [687700, [47.85167, 35.11714]],
  [2325200, [7.85257, 3.93125]],
  [1167528, [30.18414, 67.00141]],
  [3467747, [-20.44278, -54.64639]],
  [162183, [37.95, 58.38333]],
  [3514663, [19.35867, -99.20329]],
  [1169607, [34.37002, 73.47082]],
  [98463, [35.56496, 45.4329]],
  [3985606, [22.14982, -100.97916]],
  [4019233, [21.88234, -102.28259]],
  [3685533, [7.89391, -72.50782]],
  [1262801, [28.83893, 78.77684]],
  [2427123, [12.10672, 15.0444]],
  [1804540, [39.50972, 116.69472]],
  [1783873, [32.97944, 114.02944]],
  [1787093, [37.47649, 121.44081]],
  [702550, [49.83826, 24.02324]],
  [4004898, [29.1026, -110.97732]],
  [5946768, [53.55014, -113.46871]],
  [1845457, [35.82194, 127.14889]],
  [3988086, [25.42321, -101.0053]],
  [1783763, [27.83333, 113.15]],
  [8504423, [3.04384, 101.58062]],
  [1275901, [19.30023, 73.05881]],
  [1631761, [0.51667, 101.44167]],
  [2510911, [37.38283, -5.97317]],
  [482283, [53.5303, 49.3461]],
  [3397838, [-8.18028, -35.00139]],
  [1851717, [34.98333, 138.38333]],
  [1185128, [24.374, 88.60114]],
  [8601412, [18.48847, -69.85707]],
  [8224624, [-1.24204, 116.89419]],
  [1183105, [34.67719, 73.02329]],
  [1815463, [35.20889, 111.73861]],
  [894701, [-20.15, 28.58333]],
  [3186886, [45.81444, 15.97798]],
  [2561668, [30.42018, -9.59815]],
  [3191281, [43.84864, 18.35644]],
  [3432043, [-34.92145, -57.95453]],
  [2464470, [36.81897, 10.16579]],
  [1783745, [29.34162, 104.77689]],
  [3996069, [32.62781, -115.45446]],
  [2037346, [42.01556, 121.65889]],
  [2343279, [6.44132, 7.49883]],
  [2530335, [35.76727, -5.79975]],
  [1807234, [30.24706, 115.04814]],
  [2036113, [41.27194, 123.17306]],
  [2638077, [53.38297, -1.4659]],
  [5809844, [47.60621, -122.33207]],
  [5419384, [39.73915, -104.9847]],
  [5520993, [31.75872, -106.48693]],
  [1858421, [32.80589, 130.69181]],
  [1258980, [21.23333, 81.63333]],
  [1713022, [6.11278, 125.17167]],
  [1221874, [38.53575, 68.77905]],
  [4990729, [42.33143, -83.04575]],
  [4012176, [24.79032, -107.38782]],
  [1039854, [-25.96222, 32.45889]],
  [3104324, [41.65606, -0.87734]],
  [1790492, [27.85, 112.9]],
  [4005492, [25.67678, -100.25646]],
  [3533462, [16.84942, -99.90891]],
  [1734634, [4.5841, 101.0829]],
  [6075357, [43.5789, -79.6583]],
  [4930956, [42.35843, -71.05977]],
  [1798422, [35.70506, 115.01409]],
  [1799722, [32.03028, 120.87472]],
  [2035715, [44.58333, 129.6]],
  [264371, [37.98376, 23.72784]],
  [3449701, [-23.66389, -46.53833]],
  [2377450, [18.08581, -15.9785]],
  [2255414, [-4.77609, 11.86352]],
  [4641239, [35.14953, -90.04898]],
  [3397277, [-7.115, -34.86306]],
  [1846918, [37.32361, 126.82194]],
  [88319, [32.11486, 20.06859]],
  [2925533, [50.11552, 8.68417]],
  [542420, [45.04484, 38.97603]],
  [1809498, [25.28194, 110.28639]],
  [1248991, [6.93548, 79.84868]],
  [927967, [-13.96692, 33.78725]],
  [2485926, [35.69906, -0.63588]],
  [316541, [37.91363, 40.21721]],
  [1684308, [14.5243, 121.0792]],
  [4645421, [35.08676, -90.05676]],
  [1173055, [33.51836, 73.9022]],
  [1854383, [34.65, 133.93333]],
  [1784990, [21.28145, 110.34271]],
  [1621177, [-7.80139, 110.36472]],
  [618426, [47.00556, 28.8575]],
  [3081368, [51.1, 17.03333]],
  [1845604, [36.63722, 127.48972]],
  [1846898, [37.3925, 126.92694]],
  [2477461, [35.40417, 8.12417]],
  [1784642, [32.21086, 119.45508]],
  [5746545, [45.52345, -122.67621]],
  [6183235, [49.8844, -97.14704]],
  [2037886, [40.12917, 124.39472]],
  [4544349, [35.46756, -97.51643]],
  [554840, [56.84976, 53.20448]],
  [6317344, [-8.11278, -35.01472]],
  [1795874, [24.8, 113.58333]],
  [1787746, [33.3575, 120.1573]],
  [3531673, [21.17429, -86.84656]],
  [3991164, [20.58806, -100.38806]],
  [1275971, [21.20919, 81.4285]],
  [2035513, [41.18806, 122.04944]],
  [703845, [47.90572, 33.39404]],
  [223817, [11.58901, 43.14503]],
  [5506956, [36.17497, -115.13722]],
  [4347778, [39.29038, -76.61219]],
  [128226, [34.31417, 47.065]],
  [3530139, [19.3467, -99.16174]],
  [3451328, [-21.1775, -47.81028]],
  [79415, [14.79781, 42.95452]],
  [7290466, [14.58691, 121.0614]],
  [2654675, [51.45523, -2.59665]],
  [1269300, [22.80278, 86.18545]],
  [1809078, [20.04583, 110.34167]],
  [70225, [13.57952, 44.02091]],
  [3448636, [-23.17944, -45.88694]],
  [3517270, [19.42155, -98.95038]],
  [1788927, [37.06306, 114.49417]],
  [1275248, [19.23496, 72.85976]],
  [3981254, [25.54389, -103.41898]],
  [468902, [57.62987, 39.87368]],
  [1020098, [-26.18848, 28.32078]],
  [1863289, [34.7, 137.73333]],
  [1273874, [9.93988, 76.26022]],
  [1278718, [20.93333, 77.75]],
  [292968, [24.45118, 54.39696]],
  [1581298, [20.86481, 106.68345]],
  [1176615, [33.72148, 73.04329]],
  [94787, [35.46806, 44.39222]],
  [1257416, [16.85438, 74.56417]],
  [5263045, [43.0389, -87.90647]],
  [1626801, [-7.70623, 114.00976]],
  [6173331, [49.24966, -123.11934]],
  [2034786, [46.63611, 131.15389]],
  [1510853, [53.36056, 83.76361]],
  [3995402, [19.70078, -101.18443]],
  [1802204, [37.87917, 114.65167]],
  [118743, [37.27611, 49.58862]],
  [2352947, [7.15571, 3.34509]],
  [2928810, [51.45657, 7.01228]],
  [308464, [38.73222, 35.48528]],
  [2648579, [55.86515, -4.25763]],
  [2165087, [-28.00029, 153.43088]],
  [2033370, [40.66482, 122.22833]],
  [2352778, [9.05785, 7.49508]],
  [1707174, [14.45056, 120.98278]],
  [1785018, [24.51333, 117.65556]],
  [2825297, [48.78232, 9.17702]],
  [3520339, [26.08061, -98.28835]],
  [2935517, [51.51494, 7.466]],
  [2013348, [43.10562, 131.87353]],
  [931755, [-15.78499, 35.00854]],
  [3489297, [18.00747, -76.78319]],
  [738329, [41.02274, 29.01366]],
  [3176219, [44.40478, 8.94439]],
  [3143244, [59.91273, 10.74609]],
  [1273780, [20.46497, 85.87927]],
  [1863440, [35.65583, 139.32389]],
  [2022890, [48.48271, 135.08379]],
  [2056752, [48.43776, 135.13329]],
  [2399697, [0.39241, 9.45356]],
  [128234, [30.28321, 57.07879]],
  [3698350, [-6.77137, -79.84088]],
  [121801, [37.55274, 45.07605]],
  [7627067, [41.00231, 28.8598]],
  [1816440, [32.94083, 117.36083]],
  [1275665, [28.01762, 73.31495]],
  [3981461, [20.64091, -103.29327]],
  [3515428, [19.29513, -99.16206]],
  [738377, [41.01643, 29.12476]],
  [2934246, [51.22172, 6.77616]],
  [1650213, [-3.31987, 114.59075]],
  [2711537, [57.70716, 11.96679]],
  [3688465, [7.12539, -73.1198]],
  [4951305, [42.33343, -71.04949]],
  [1735634, [1.55, 110.33333]],
  [3088171, [52.40692, 16.92993]],
  [2514256, [36.72016, -4.42034]],
  [2290956, [7.69385, -5.03031]],
  [369004, [12.04888, 24.88069]],
  [343300, [15.33805, 38.93184]],
  [2322911, [13.06269, 5.24322]],
  [3445831, [-18.91861, -48.27722]],
  [2326016, [6.14978, 6.78569]],
  [1863905, [35.70129, 139.98648]],
  [5454711, [35.08449, -106.65114]],
  [1877449, [39.91833, 127.53639]],
  [3447399, [-23.50167, -47.45806]],
  [658225, [60.16952, 24.93545]],
  [1252948, [18.0, 79.58333]],
  [3536729, [20.02083, -75.82667]],
  [2034714, [43.16143, 124.37785]],
  [1860827, [31.56667, 130.55]],
  [1625812, [-7.55611, 110.83167]],
  [1797873, [33.58861, 119.01917]],
  [1276032, [21.76287, 72.15331]],
  [3430863, [-38.00042, -57.5562]],
  [1183880, [29.39779, 71.6752]],
  [1159301, [29.4963, 60.8629]],
  [415189, [12.77944, 45.03667]],
  [1261977, [19.16023, 77.31497]],
  [515003, [51.7727, 55.0988]],
  [1846326, [35.22806, 128.68111]],
  [786714, [42.67272, 21.16688]],
  [2036581, [46.79927, 130.31633]],
  [1730501, [14.62578, 121.12251]],
  [1799491, [29.58354, 105.06216]],
  [2944388, [53.07582, 8.80717]],
  [1670029, [25.01427, 121.46719]],
  [1166000, [32.08586, 72.67418]],
  [593116, [54.68916, 25.2798]],
  [1787227, [32.39722, 119.43583]],
  [1496990, [53.7557, 87.1099]],
  [212730, [0.51528, 25.19099]],
  [358619, [31.26531, 32.3019]],
  [304531, [36.81196, 34.63886]],
  [3515001, [16.75973, -93.11308]],
  [1258315, [22.22496, 84.86414]],
  [1809412, [28.88162, 120.03308]],
  [2344082, [6.60086, 3.48818]],
  [1793424, [39.02111, 117.64694]],
  [1806299, [22.58333, 113.08333]],
  [3827409, [19.44506, -99.14612]],
  [5318313, [32.22174, -110.92648]],
  [4644585, [36.16589, -86.78444]],
  [107968, [21.27028, 40.41583]],
  [1052373, [-19.84361, 34.83889]],
  [1270668, [16.29974, 80.45729]],
  [2244322, [14.85, -15.88333]],
  [6325494, [46.81228, -71.21454]],
  [132144, [34.79922, 48.51456]],
  [1816080, [38.31667, 116.86667]],
  [3583361, [13.68935, -89.18718]],
  [1273313, [30.32443, 78.03392]],
  [3465038, [-15.59611, -56.09667]],
  [1735158, [3.10726, 101.60671]],
  [1821274, [22.20056, 113.54611]],
  [1276014, [19.30157, 72.85107]],
  [500096, [54.6269, 39.6916]],
  [5350937, [36.74773, -119.77237]],
  [5969782, [43.25011, -79.84963]],
  [1272175, [23.51583, 87.30801]],
  [4011743, [24.02032, -104.65756]],
  [1279159, [26.4521, 74.63867]],
  [2267057, [38.71667, -9.13333]],
  [1791121, [29.03205, 111.69844]],
  [1805987, [35.23972, 113.23306]],
  [1253894, [19.21667, 73.15]],
  [1256525, [26.71004, 88.42851]],
  [2910831, [52.37052, 9.73322]],
  [315202, [39.77667, 30.52056]],
  [14256, [34.79049, 48.57011]],
  [3838233, [-24.7859, -65.41166]],
  [511565, [53.20066, 45.00464]],
  [6316406, [-16.82333, -49.24389]],
  [1703417, [14.55027, 121.03269]],
  [2034414, [41.71972, 125.92639]],
  [2644668, [52.6386, -1.13169]],
  [1791236, [31.33728, 118.37351]],
  [1855431, [37.88637, 139.00589]],
  [1267696, [28.65136, 77.19072]],
  [2879139, [51.33962, 12.37129]],
  [8504951, [59.99675, 30.3899]],
  [2934691, [51.43247, 6.76516]],
  [1278314, [23.68333, 86.98333]],
  [143127, [34.09493, 49.69809]],
  [580497, [46.34968, 48.04076]],
  [1790437, [22.27694, 113.56778]],
  [1839071, [36.02917, 129.36481]],
  [2861650, [49.45421, 11.07752]],
  [1795816, [30.30722, 112.24472]],
  [532096, [42.97638, 47.50236]],
  [1646448, [-6.87222, 107.5425]],
  [1803936, [37.92672, 102.63202]],
  [1269317, [22.47292, 70.06673]],
  [5389489, [38.58157, -121.4944]],
  [3471872, [-10.91111, -37.07167]],
  [292932, [25.40177, 55.47878]],
  [9931406, [24.2774, 101.224]],
  [377039, [19.61745, 37.21644]],
  [3836277, [-31.64881, -60.70868]],
  [3601782, [15.50417, -88.025]],
  [3515302, [19.28786, -99.65324]],
  [3532624, [19.55793, -99.25675]],
  [359796, [29.97371, 32.52627]],
  [2935022, [51.05089, 13.73832]],
  [1489425, [56.49771, 84.97437]],
  [2593460, [-4.38361, 15.39139]],
  [1257806, [29.9679, 77.54522]],
  [3515807, [19.64388, -99.21598]],
  [1270752, [17.33583, 76.83757]],
  [700569, [46.97625, 31.99296]],
  [1276058, [22.86643, 88.40113]],
  [98860, [32.02594, 44.34625]],
  [3463478, [-12.26667, -38.96667]],
  [1732903, [3.08507, 101.53281]],
  [701822, [47.09514, 37.54131]],
  [1862627, [34.81667, 134.7]],
  [627907, [52.4345, 30.9754]],
  [1806488, [19.2425, 110.46417]],
  [2327220, [7.55122, 6.23589]],
  [1833788, [37.7415, 127.0474]],
  [1006984, [-33.01529, 27.91162]],
  [111822, [31.89722, 54.3675]],
  [57289, [9.56, 44.065]],
  [1164909, [32.49268, 74.53134]],
  [1503901, [55.33333, 86.08333]],
  [1786764, [30.71444, 111.28472]],
  [1843847, [37.20682, 126.8169]],
  [4393217, [39.09973, -94.57857]],
  [1786657, [38.46806, 106.27306]],
  [785842, [41.99646, 21.43141]],
  [944385, [-26.67313, 27.92615]],
  [2747373, [52.07667, 4.29861]],
  [5367929, [33.76696, -118.18923]],
  [2996944, [45.74846, 4.84671]],
  [3458449, [-23.31028, -51.16278]],
  [5304391, [33.42227, -111.82264]],
  [1809061, [28.69844, 121.47331]],
  [1857553, [35.77995, 139.90144]],
  [3459505, [-21.76417, -43.35028]],
  [480562, [54.19609, 37.61822]],
  [5139568, [40.56233, -74.13986]],
  [1859730, [35.80521, 139.71072]],
  [4018390, [25.78195, -100.18839]],
  [1783621, [27.68667, 106.90722]],
  [1805953, [30.7522, 120.75]],
  [3470142, [-22.76417, -43.39944]],
  [1269321, [32.73569, 74.86911]],
  [2036109, [42.90361, 125.13583]],
  [2650225, [55.95206, -3.19648]],
  [4180439, [33.749, -84.38798]],
  [214481, [-5.89624, 22.41659]],
  [1018725, [-29.12107, 26.214]],
  [1790587, [32.0422, 112.14479]],
  [3099434, [54.35227, 18.64912]],
  [2346229, [4.95893, 8.32695]],
  [1798998, [26.5479, 101.70539]],
  [3459712, [-26.30444, -48.84556]],
  [170017, [35.13179, 36.75783]],
  [1265491, [15.82887, 78.03602]],
  [2803138, [51.22047, 4.40026]],
  [1860243, [36.6, 136.61667]],
  [7701384, [38.37396, 27.1352]],
  [1679432, [6.91028, 122.07389]],
  [548408, [58.59665, 49.66007]],
  [1253914, [23.18239, 75.77643]],
  [1733432, [5.9749, 116.0724]],
  [5417598, [38.83388, -104.82136]],
  [3456283, [-22.88333, -43.10361]],
  [1808392, [37.73222, 115.70111]],
  [101628, [28.3998, 36.57151]],
  [1630789, [-0.03194, 109.325]],
  [2644688, [53.79648, -1.54785]],
  [1873757, [38.7375, 125.40778]],
  [1729564, [10.66667, 122.95]],
  [3448877, [-22.80389, -43.37222]],
  [1701500, [10.63111, 122.97889]],
  [4791259, [36.85293, -75.97799]],
  [1258662, [18.80084, 79.45206]],
  [702658, [48.56705, 39.31706]],
  [1636544, [1.48218, 124.84892]],
  [609655, [49.80187, 73.10211]],
  [4487042, [35.7721, -78.63861]],
  [1805518, [35.405, 116.58139]],
  [2501152, [36.365, 6.61472]],
  [8505006, [23.36306, 104.25047]],
  [8074174, [36.89157, 30.76498]],
  [373303, [4.85165, 31.58247]],
  [1849053, [36.56667, 139.88333]],
  [3523466, [25.87972, -97.50417]],
  [298333, [37.16708, 38.79392]],
  [2038087, [40.9519, 117.95883]],
  [1854487, [33.23333, 131.6]],
  [1348843, [22.50862, 88.25322]],
  [2653822, [51.48, -3.18]],
  [3837213, [-31.5375, -68.53639]],
  [569696, [56.13222, 47.25194]],
  [1721080, [8.48222, 124.64722]],
  [5074472, [41.25626, -95.94043]],
  [1926099, [33.83916, 132.76574]],
  [3985241, [25.74167, -100.30222]],
  [1865387, [34.71667, 135.41667]],
  [3728338, [18.54114, -72.39922]],
  [1256409, [22.83333, 88.36667]],
  [1715430, [14.32944, 120.93667]],
  [304922, [38.35018, 38.31667]],
  [4164138, [25.77427, -80.19366]],
  [3672486, [4.81333, -75.69611]],
  [1265873, [11.24802, 75.7804]],
  [1308465, [16.49051, 97.62825]],
  [3696183, [-3.74912, -73.25383]],
  [1858311, [34.58333, 133.76667]],
  [1869012, [39.43167, 125.93278]],
  [7628416, [41.10652, 28.86847]],
  [2231320, [9.30143, 13.39771]],
  [2513416, [37.98704, -1.13004]],
  [152224, [-2.51667, 32.9]],
  [2319480, [5.05127, 7.9335]],
  [2038365, [48.26667, 126.6]],
  [2229798, [12.07689, 15.03063]],
  [1264115, [20.54966, 74.53462]],
  [1273368, [14.46693, 75.92694]],
  [554234, [54.70649, 20.51095]],
  [1841246, [35.12725, 126.83149]],
  [3407669, [-1.36556, -48.37222]],
  [1650527, [-1.26753, 116.82887]],
  [5907364, [43.68341, -79.76633]],
  [2972315, [43.60426, 1.44367]],
  [293397, [32.08088, 34.78057]],
  [1513157, [40.9983, 71.67257]],
  [2334802, [12.99082, 7.60177]],
  [940909, [-27.97742, 26.73506]],
  [360829, [30.97063, 31.1669]],
  [3827407, [19.44361, -99.10499]],
  [1269407, [21.00292, 75.56602]],
  [1848313, [35.28361, 139.66722]],
  [1276533, [15.85212, 74.50447]],
  [3514783, [19.18095, -96.1429]],
  [7303419, [31.78336, 35.23388]],
  [1801757, [28.58023, 121.37491]],
  [571476, [53.25209, 34.37167]],
  [741763, [40.93567, 29.15507]],
  [109353, [24.15541, 47.33457]],
  [1815395, [23.65396, 116.62262]],
  [3526617, [19.53124, -96.91589]],
  [1255783, [22.4382, 88.43205]],
  [2352379, [7.62329, 5.22087]],
  [3060972, [48.14816, 17.10674]],
  [1271439, [24.79686, 85.00385]],
  [1253986, [24.58584, 73.71346]],
  [922741, [-10.98303, 26.7384]],
  [360502, [25.69893, 32.6421]],
  [3680656, [4.43889, -75.23222]],
  [1795855, [30.00237, 120.57864]],
  [555312, [56.99719, 40.97139]],
  [315368, [39.90861, 41.27694]],
  [2350841, [7.25256, 5.19312]],
  [359783, [27.18096, 31.18368]],
  [1642858, [-1.6, 103.61667]],
  [360761, [31.03637, 31.38069]],
  [5378538, [37.80437, -122.2708]],
  [1266122, [22.3458, 82.69633]],
  [922773, [-10.71484, 25.46674]],
  [2193733, [-36.84853, 174.76349]],
  [1164408, [27.70323, 68.85889]],
  [1263780, [12.91723, 74.85603]],
  [1801934, [33.56394, 114.04272]],
  [1694611, [14.53748, 121.00144]],
  [694423, [44.60795, 33.52134]],
  [3532497, [19.48698, -99.18594]],
  [1518980, [42.3, 69.6]],
  [532288, [53.41861, 59.04722]],
  [1269006, [25.45887, 78.57994]],
  [3463237, [-27.59667, -48.54917]],
  [2911285, [53.58334, 10.08305]],
  [3449433, [-23.96083, -46.33361]],
  [3778045, [9.74569, -63.18323]],
  [5037649, [44.97997, -93.26384]],
  [8629640, [8.76735, 78.13425]],
  [143083, [38.2498, 48.2933]],
  [1856177, [32.75, 129.88333]],
  [2038120, [41.57028, 120.45861]],
  [281133, [31.50161, 34.46672]],
  [538560, [51.73733, 36.18735]],
  [3981369, [20.62445, -103.23423]],
  [1846266, [33.50972, 126.52194]],
  [3703443, [8.9936, -79.51973]],
  [3514450, [19.25465, -99.10356]],
  [3083829, [53.42894, 14.55302]],
  [1587923, [10.94469, 106.82432]],
  [3587902, [14.52512, -90.58544]],
  [3451353, [-19.76694, -44.08667]],
  [1862540, [34.81352, 135.64914]],
  [2540483, [34.68139, -1.90858]],
  [347497, [30.78847, 31.00192]],
  [971421, [-27.75796, 29.9318]],
  [1261529, [14.44992, 79.98697]],
  [1675151, [24.80361, 120.96861]],
  [2036389, [45.30109, 130.95718]],
  [148730, [-6.16394, 39.19793]],
  [6696918, [24.99368, 121.29696]],
  [372753, [15.45099, 36.39998]],
  [2332121, [6.40948, 4.09152]],
  [2512989, [39.56939, 2.65024]],
  [911148, [-12.80243, 28.21323]],
  [98854, [31.05799, 46.25726]],
  [480060, [56.85836, 35.90057]],
  [7498240, [31.78199, 35.21961]],
  [1857871, [35.54028, 139.45083]],
  [1806408, [21.85563, 111.96272]],
  [1863641, [35.42291, 136.76039]],
  [1254348, [11.11541, 77.35456]],
  [1678228, [25.12825, 121.7419]],
  [2558545, [35.25165, -3.93723]],
  [2643123, [53.48095, -2.23743]],
  [6159905, [49.10635, -122.82509]],
  [3445026, [-20.32972, -40.2925]],
  [1791681, [36.71, 119.10194]],
  [1864092, [35.34926, 139.47666]],
  [901344, [-12.95867, 28.63659]],
  [1259091, [8.88113, 76.58469]],
  [3447779, [-20.12861, -40.30778]],
  [740264, [41.27976, 36.3361]],
  [588409, [59.43696, 24.75353]],
  [2234974, [5.9597, 10.14597]],
  [379003, [13.18421, 30.21669]],
  [3688928, [6.33732, -75.55795]],
  [1788694, [34.59972, 119.15944]],
  [1734052, [5.8402, 118.1179]],
  [107304, [26.32599, 43.97497]],
  [1138336, [31.61332, 65.71013]],
  [4281730, [37.69224, -97.33754]],
  [4335045, [29.95465, -90.07507]],
  [1185186, [23.46186, 91.18503]],
  [1606590, [13.59934, 100.59675]],
  [1033356, [-15.11646, 39.2666]],
  [4671240, [32.73569, -97.10807]],
  [5150529, [41.4995, -81.69541]],
  [2374775, [11.86357, -15.59767]],
  [1711005, [10.69694, 122.56444]],
  [105072, [18.3, 42.73333]],
  [3467693, [-21.75227, -41.33044]],
  [3429577, [-27.46056, -58.98389]],
  [2214846, [32.37535, 15.09254]],
  [3457381, [-23.66778, -46.46139]],
  [2395914, [6.44852, 2.35566]],
  [2947416, [51.48165, 7.21648]],
  [6957079, [19.3727, -99.1564]],
  [1849837, [34.78244, 135.46932]],
  [3526700, [19.39528, -99.09778]],
  [3470044, [-19.96778, -44.19833]],
  [1863917, [34.48333, 133.36667]],
  [2239076, [-9.29782, 14.91162]],
  [3726786, [18.54472, -72.30278]],
  [3672778, [1.21361, -77.28111]],
  [321836, [37.88738, 41.13221]],
  [2179537, [-41.28664, 174.77557]],
  [2515270, [28.09973, -15.41343]],
  [3996322, [23.2329, -106.4062]],
  [3466537, [-29.16806, -51.17944]],
  [520494, [57.91944, 59.965]],
  [4004330, [20.67675, -101.35628]],
  [6941055, [51.50168, 7.1756]],
  [1812955, [37.45127, 116.31046]],
  [986822, [-26.08577, 27.77515]],
  [1260482, [22.69089, 88.37404]],
  [1185107, [25.00146, 89.32266]],
  [1849846, [34.76667, 137.38333]],
  [1784130, [33.63333, 114.63333]],
  [3939459, [-12.06513, -75.20486]],
  [702320, [48.04782, 37.92576]],
  [1798449, [25.43944, 119.01028]],
  [3183875, [41.3275, 19.81889]],
  [3448639, [-20.81972, -49.37944]],
  [598316, [54.90272, 23.90961]],
  [3522551, [27.47629, -99.51639]],
  [5325738, [35.37329, -119.01871]],
  [2033574, [40.61028, 115.04472]],
  [1734810, [2.7297, 101.9381]],
  [3827408, [19.43411, -99.20024]],
  [2636841, [53.00415, -2.18538]],
  [1798654, [27.61672, 113.85353]],
  [298117, [38.49457, 43.38323]],
  [5856195, [21.30694, -157.85833]],
  [145459, [30.3392, 48.3043]],
  [7304020, [27.93557, 109.59961]],
  [3629672, [10.21382, -64.6328]],
  [689558, [49.2322, 28.46871]],
  [3078610, [49.19522, 16.60796]],
  [7483813, [24.41112, 88.98673]],
  [4174757, [27.94752, -82.45843]],
  [2172517, [-35.28346, 149.12807]],
  [1855612, [34.68505, 135.80485]],
  [1279228, [19.09457, 74.73843]],
  [1272691, [20.9013, 74.77737]],
  [3393536, [-8.00889, -34.85528]],
  [2544571, [34.26101, -6.5802]],
  [3102014, [53.1235, 18.00762]],
  [1735227, [3.8077, 103.326]],
  [3181928, [44.49381, 11.33875]],
  [1845759, [36.8065, 127.1522]],
  [3630297, [10.47679, -66.80786]],
  [379062, [14.03493, 35.38344]],
  [2192362, [-43.53333, 172.63333]],
  [487846, [45.0428, 41.9734]],
  [1849814, [35.08333, 137.15]],
  [1784853, [23.04893, 112.46091]],
  [6947637, [40.9833, 29.1167]],
  [1276300, [25.24446, 86.97183]],
  [1165221, [31.71287, 73.98556]],
  [3466998, [-23.52272, -46.835]],
  [2805753, [51.25627, 7.14816]],
  [2294877, [9.40079, -0.8393]],
  [2014407, [51.82721, 107.60627]],
  [1856215, [36.65, 138.18333]],
  [2362344, [11.17715, -4.2979]],
  [765876, [51.25, 22.56667]],
  [5412347, [39.72943, -104.83192]],
  [2652221, [52.40656, -1.51217]],
  [6324729, [44.6464, -63.57291]],
  [1817993, [30.51365, 117.04723]],
  [1516905, [42.9, 71.36667]],
  [1689395, [14.81389, 121.04528]],
  [3675443, [5.06889, -75.51738]],
  [1948005, [37.47722, 126.86639]],
  [111453, [36.67642, 48.49628]],
  [2112539, [37.05, 140.88333]],
  [1729524, [14.45896, 120.93851]],
  [2130629, [43.77063, 142.36489]],
  [3514519, [19.27032, -98.95088]],
  [1842943, [35.23417, 128.88111]],
  [581049, [64.5401, 40.5433]],
  [1651531, [-3.69583, 128.18333]],
  [1629001, [-0.49167, 117.14583]],
  [3128026, [43.26271, -2.92528]],
  [3835869, [-27.79511, -64.26149]],
  [1850910, [34.84833, 135.61678]],
  [734077, [40.64361, 22.93086]],
  [3514670, [17.98689, -92.93028]],
  [3648546, [8.62261, -70.20749]],
  [3673899, [2.9273, -75.28189]],
  [4005867, [25.79698, -100.31791]],
  [1854376, [34.95, 137.16667]],
  [141681, [27.1865, 56.2808]],
  [291074, [25.78953, 55.9432]],
  [1817968, [26.25, 105.93333]],
  [1851483, [34.76143, 135.51567]],
  [1926004, [34.23333, 135.16667]],
  [5323810, [33.83529, -117.9145]],
  [1674504, [23.97694, 121.60444]],
  [1707267, [10.31028, 123.94944]],
  [8764562, [-25.93312, 28.01213]],
  [1259239, [22.23507, 76.39335]],
  [1262332, [29.47091, 77.70332]],
  [3176959, [43.77925, 11.24626]],
  [117574, [35.31495, 46.99883]],
  [1265014, [18.39721, 76.56784]],
  [3403642, [-7.23056, -35.88111]],
  [3566067, [21.38083, -77.91694]],
  [2038067, [42.26833, 118.96361]],
  [1877030, [39.83167, 127.61861]],
  [578072, [50.61074, 36.58015]],
  [371760, [13.1629, 32.66347]],
  [2035261, [45.76347, 130.85048]],
  [290030, [25.28545, 51.53096]],
  [3625829, [10.22856, -67.47421]],
  [1501321, [55.45, 65.33333]],
  [1185188, [25.74664, 89.25166]],
  [1182637, [32.97465, 74.07846]],
  [620127, [55.1904, 30.2049]],
  [3667849, [10.91843, -74.76459]],
  [1795060, [22.51682, 113.38521]],
  [3453643, [-22.72528, -47.64917]],
  [2657896, [47.36667, 8.55]],
  [1265767, [17.48486, 78.41376]],
  [1175892, [31.26981, 72.31687]],
  [161325, [-3.36667, 36.68333]],
  [1278840, [13.09818, 80.16152]],
  [2112141, [37.4, 140.38333]],
  [728193, [42.15, 24.75]],
  [1106542, [-18.01274, 31.07555]],
  [173576, [35.53168, 35.79011]],
  [1529660, [41.18418, 80.27921]],
  [1850181, [35.79916, 139.46903]],
  [3435217, [-27.46784, -58.8344]],
  [553915, [54.5293, 36.27542]],
  [3396016, [0.03889, -51.06639]],
  [4498303, [35.78682, -78.66389]],
  [3529947, [18.9261, -99.23075]],
  [2990440, [43.70313, 7.26608]],
  [1876373, [37.97083, 126.55444]],
  [3645532, [8.12923, -63.54086]],
  [1859740, [35.90861, 139.48528]],
  [1185098, [23.89154, 90.40232]],
  [8504952, [59.97305, 30.47607]],
  [963241, [-26.0941, 28.00123]],
  [1276509, [15.14205, 76.92398]],
  [3460644, [-23.48611, -46.34833]],
  [693805, [44.95719, 34.11079]],
  [1859146, [33.55, 133.53333]],
  [2636531, [54.90465, -1.38222]],
  [5392900, [33.74557, -117.86783]],
  [3470279, [-22.31472, -49.06056]],
  [2521978, [38.34517, -0.48149]],
  [1851100, [34.33333, 134.05]],
  [2034439, [42.29306, 123.84139]],
  [119505, [36.26877, 50.0041]],
  [1262330, [26.12259, 85.39055]],
  [1268257, [22.67111, 88.37472]],
  [3981941, [21.50951, -104.89569]],
  [364103, [14.40118, 33.51989]],
  [1805741, [35.50222, 112.83278]],
  [2949186, [52.03333, 8.53333]],
  [425378, [-3.38193, 29.36142]],
  [1701947, [10.32361, 123.92222]],
  [127319, [30.44079, 48.18428]],
  [1263364, [27.5035, 77.67215]],
  [127349, [33.48778, 48.35583]],
  [3583096, [13.71024, -89.13989]],
  [1260107, [30.33625, 76.3922]],
  [1866923, [39.15278, 127.44361]],
  [2654789, [51.55306, -0.3023]],
  [1520240, [52.28333, 76.96667]],
  [1274693, [19.95076, 79.29523]],
  [3467467, [-29.91778, -51.18361]],
  [491422, [43.59917, 39.72569]],
  [2044757, [41.79556, 129.77583]],
  [2033467, [42.9075, 129.50778]],
  [1275960, [25.34707, 74.64081]],
  [2528910, [35.57845, -5.36837]],
  [3457001, [-23.52278, -46.18833]],
  [1849876, [36.7, 137.21667]],
  [3693528, [-5.19449, -80.63282]],
  [2655613, [53.39337, -3.01479]],
  [1254187, [10.51667, 76.21667]],
  [1275198, [19.31151, 84.7929]],
  [3448136, [-23.96306, -46.39194]],
  [515012, [52.96508, 36.07849]],
  [4683416, [27.80058, -97.39638]],
  [1793879, [31.71111, 113.36306]],
  [3429886, [-27.36708, -55.89608]],
  [99608, [31.83561, 47.14483]],
  [472231, [48.78583, 44.77973]],
  [5387877, [33.95335, -117.39616]],
  [3526682, [19.31556, -98.88284]],
  [3665900, [4.142, -73.62664]],
  [3459462, [-23.18639, -46.88417]],
  [3701329, [9.05032, -79.47068]],
  [2641170, [52.9536, -1.15047]],
  [491687, [54.7818, 32.0401]],
  [3439101, [-25.50972, -54.61111]],
  [3454244, [-31.76997, -52.34101]],
  [1256728, [27.88165, 79.90918]],
  [2113126, [39.71667, 140.11667]],
  [2244802, [14.78944, -16.92602]],
  [2228373, [10.59095, 14.31593]],
  [3430545, [-34.6509, -58.61956]],
  [3472287, [-16.32667, -48.95278]],
  [1256515, [13.93157, 75.56791]],
  [1216265, [39.65417, 66.95972]],
  [524305, [68.97917, 33.09251]],
  [3556969, [20.88722, -76.26306]],
  [1520316, [49.97143, 82.60586]],
  [99762, [31.99289, 44.92552]],
  [1787858, [28.45179, 117.94287]],
  [1514588, [40.78206, 72.34424]],
  [2639577, [51.45625, -0.97113]],
  [675810, [47.16667, 27.6]],
  [1261481, [28.63576, 77.22445]],
  [1858729, [35.89035, 139.78916]],
  [473249, [43.03667, 44.66778]],
  [627904, [53.6884, 23.8258]],
  [3096472, [50.25841, 19.02754]],
  [1258076, [28.89447, 76.58917]],
  [2038569, [45.60746, 122.82076]],
  [3698304, [-9.08528, -78.57833]],
  [681290, [46.76667, 23.6]],
  [1720681, [14.21167, 121.16528]],
  [2347470, [10.31032, 9.84388]],
  [569223, [59.13333, 37.9]],
  [4407066, [38.62727, -90.19789]],
  [3689560, [4.53389, -75.68111]],
  [99131, [32.5128, 45.81817]],
  [665087, [45.75372, 21.22571]],
  [472459, [59.2239, 39.88398]],
  [739549, [41.06046, 28.98717]],
  [4297999, [38.0498, -84.45855]],
  [2645425, [53.7446, -0.33525]],
  [3667905, [4.57937, -74.21682]],
  [2529013, [33.92866, -6.90656]],
  [2639912, [53.76282, -2.70452]],
  [586523, [40.68278, 46.36056]],
  [2036434, [40.76432, 120.85327]],
  [317109, [37.77417, 29.0875]],
  [2338313, [6.59651, 3.34205]],
  [3068799, [49.83465, 18.28204]],
  [726050, [43.21667, 27.91667]],
  [3444924, [-20.31944, -40.33778]],
  [1792621, [30.95, 117.78333]],
  [1711082, [8.25, 124.4]],
  [3941584, [-13.52264, -71.96734]],
  [3457671, [-23.42528, -51.93861]],
  [1856717, [31.91667, 131.41667]],
  [1214189, [3.6253, 98.864]],
  [3693345, [-8.37915, -74.55387]],
  [1805680, [29.2947, 117.20789]],
  [3461789, [-23.99306, -46.25639]],
  [1870883, [38.50722, 125.75583]],
  [473247, [56.13655, 40.39658]],
  [3899539, [-23.65236, -70.3954]],
  [1016670, [-29.26737, 26.72595]],
  [1649150, [-3.80044, 102.26554]],
  [1722186, [8.94917, 125.54361]],
  [3516355, [22.28519, -97.87777]],
  [6094578, [43.90012, -78.84957]],
  [1721281, [14.2726, 121.1262]],
  [2025339, [52.03171, 113.50087]],
  [3666304, [10.46314, -73.25322]],
  [2503826, [34.85038, 5.72805]],
  [2873891, [49.4891, 8.46694]],
  [710735, [51.50551, 31.28487]],
  [248946, [32.55556, 35.85]],
  [1254089, [13.34136, 77.1022]],
  [1846052, [35.19278, 128.08472]],
  [2641598, [51.58774, -2.99835]],
  [1271885, [27.15092, 78.39781]],
  [361320, [29.30995, 30.8418]],
  [3662762, [-8.76194, -63.90389]],
  [1016181, [-26.23656, 28.36938]],
  [5399020, [37.9577, -121.29078]],
  [1701966, [14.5832, 121.0409]],
  [1261258, [18.67154, 78.0988]],
  [1265711, [23.73166, 86.84372]],
  [3530580, [23.74174, -99.14599]],
  [3515463, [19.28689, -99.00507]],
  [3463011, [-20.53861, -47.40083]],
  [1258932, [17.00517, 81.77784]],
  [5206379, [40.44062, -79.99589]],
  [680332, [44.31667, 23.8]],
  [680963, [44.18073, 28.63432]],
  [498698, [54.1838, 45.1749]],
  [1133616, [36.70904, 67.11087]],
  [1277029, [23.25572, 87.85691]],
  [2692969, [55.60587, 13.00073]],
  [1177654, [32.5742, 74.07542]],
  [1856035, [26.21667, 127.68333]],
  [629634, [52.09755, 23.68775]],
  [1170880, [34.19794, 72.04965]],
  [1490624, [61.25, 73.41667]],
  [2636432, [51.62079, -3.94323]],
  [1519922, [44.85278, 65.50917]],
  [363807, [9.01222, 27.57081]],
  [1787323, [24.78081, 110.48967]],
  [1725115, [14.34267, 121.08071]],
  [442301, [39.96833, 32.73083]],
  [1171305, [24.94343, 67.20591]],
  [1529046, [43.42649, 83.24959]],
  [1735106, [5.41123, 100.33543]],
  [1730737, [15.15, 120.58333]],
  [2654993, [53.79391, -1.75206]],
  [5879400, [61.21806, -149.90028]],
  [4013704, [27.48642, -109.94083]],
  [1859891, [35.24762, 136.97229]],
  [1642588, [-8.17211, 113.69953]],
  [2130658, [40.81667, 140.73333]],
  [1853574, [35.0, 135.86667]],
  [1277065, [22.72154, 88.48198]],
  [315808, [38.67431, 39.22321]],
  [12165955, [18.191, 79.943]],
  [3105976, [42.23282, -8.72264]],
  [1847966, [34.65524, 135.00687]],
  [1277539, [22.64859, 88.34115]],
  [4508722, [39.12711, -84.51439]],
  [1270022, [29.15394, 75.72294]],
  [1258599, [28.81014, 79.02699]],
  [1884138, [34.76062, 127.66215]],
  [2637433, [51.53782, 0.71433]],
  [2111834, [39.7, 141.15]],
  [3868121, [-33.02457, -71.55183]],
  [692194, [50.9216, 34.80029]],
  [2112923, [37.75, 140.46667]],
  [677697, [45.43687, 28.05028]],
  [3469968, [-26.91944, -49.06611]],
  [6954929, [28.49615, 77.53601]],
  [7279746, [28.58, 77.33]],
  [3463030, [-25.54778, -54.58806]],
  [109571, [25.36467, 49.58764]],
  [1268561, [16.96036, 82.23809]],
  [1260476, [29.38747, 76.96825]],
  [1519422, [50.42675, 80.26669]],
  [2331140, [7.73375, 8.52139]],
  [2171507, [-34.424, 150.89345]],
  [3453186, [-25.095, -50.16194]],
  [2330100, [9.61524, 6.54776]],
  [776069, [53.13333, 23.16433]],
  [154380, [-8.9, 33.45]],
  [1608133, [13.86075, 100.51477]],
  [1842225, [36.1136, 128.336]],
  [484646, [52.73169, 41.44326]],
  [2525068, [37.49223, 15.07041]],
  [109101, [25.40768, 49.59028]],
  [2235189, [5.47775, 10.41759]],
  [1174625, [31.11866, 74.45025]],
  [2745912, [52.09083, 5.12222]],
  [1788245, [20.00073, 110.29359]],
  [3392740, [-7.94083, -34.87306]],
  [99347, [32.46367, 44.41963]],
  [3458575, [-22.56472, -47.40167]],
  [1260341, [19.26855, 76.77081]],
  [6174041, [48.4359, -123.35155]],
  [1816705, [21.48333, 109.1]],
  [1806776, [23.11147, 114.41523]],
  [706448, [46.63695, 32.61458]],
  [737071, [40.99441, 28.90417]],
  [1818209, [22.37137, 114.11329]],
  [4300488, [38.41258, -82.70905]],
  [4295940, [38.45647, -82.69238]],
  [2481700, [36.19112, 5.41373]],
  [696643, [49.58925, 34.55367]],
  [2537881, [32.29939, -9.23718]],
  [2040893, [40.10056, 124.39806]],
  [1580240, [16.4619, 107.59546]],
  [1273491, [26.15216, 85.89707]],
  [752850, [40.78056, 30.40333]],
  [7628419, [40.96072, 29.27067]],
  [5505411, [36.0397, -114.98194]],
  [4469146, [36.07264, -79.79198]],
  [3445156, [-30.08111, -51.02333]],
  [359493, [30.58768, 31.502]],
  [5045360, [44.94441, -93.09327]],
  [1734705, [5.3302, 103.1408]],
  [361055, [30.60427, 32.27225]],
  [3487903, [8.5931, -70.2261]],
  [96994, [36.86709, 42.98845]],
  [2892794, [49.00937, 8.40444]],
  [2088122, [-9.47723, 147.15089]],
  [4719457, [33.01984, -96.69889]],
  [2112664, [35.51667, 140.08333]],
  [1843491, [35.94389, 126.95444]],
  [1572151, [12.24507, 109.19432]],
  [3447212, [-23.5425, -46.31083]],
  [1278946, [27.56246, 76.625]],
  [1720840, [14.5786, 121.1222]],
  [3719028, [18.5125, -72.28528]],
  [1857843, [36.4, 139.08333]],
  [1275637, [22.08005, 82.15543]],
  [7281838, [-27.63917, 153.10944]],
  [3868626, [-33.036, -71.62963]],
  [1633034, [-0.90833, 119.87083]],
  [2057087, [-10.17083, 123.60694]],
  [686967, [50.26487, 28.67669]],
  [5101798, [40.73566, -74.17237]],
  [3680450, [6.18461, -75.59913]],
  [747014, [40.80276, 29.43068]],
  [1185117, [23.92298, 90.71768]],
  [2505572, [35.55597, 6.17414]],
  [1814757, [32.32194, 118.29778]],
  [11238838, [37.80544, 29.04236]],
  [3928128, [-18.01465, -70.25362]],
  [8354626, [53.58935, 9.984]],
  [1170395, [34.7795, 72.36265]],
  [5174035, [41.66394, -83.55521]],
  [4006702, [31.87149, -116.60071]],
  [2343983, [7.65649, 4.92235]],
  [466806, [56.63877, 47.89078]],
  [484907, [47.23617, 38.89688]],
  [3627047, [10.23291, -66.66474]],
  [707753, [48.33576, 38.05325]],
  [6182962, [42.30008, -83.01654]],
  [3530569, [19.62923, -99.10689]],
  [2337704, [7.62789, 4.74161]],
  [543878, [57.76647, 40.92686]],
  [3121424, [43.53573, -5.66152]],
  [5072006, [40.8, -96.66696]],
  [418710, [32.6856, 51.53609]],
  [2467454, [34.74056, 10.76028]],
  [2990969, [47.21725, -1.55336]],
  [3658666, [-2.90055, -79.00453]],
  [2325314, [7.1962, 5.58681]],
  [3865086, [-38.71959, -62.27243]],
  [1626381, [-6.91806, 106.92667]],
  [710791, [49.44452, 32.05738]],
  [683844, [45.64861, 25.60613]],
  [2021851, [50.55034, 137.00995]],
  [2130188, [41.77583, 140.73667]],
  [2505854, [36.72615, 3.18291]],
  [2226275, [10.74244, 13.80227]],
  [3402429, [-3.73611, -38.65306]],
  [2973783, [48.58392, 7.74553]],
  [2655984, [54.59682, -5.92541]],
  [1280849, [39.47066, 75.98951]],
  [98717, [33.42056, 43.30778]],
  [1269834, [16.69117, 74.46054]],
  [1862033, [34.81641, 135.56828]],
  [1848522, [34.61667, 135.6]],
  [1140026, [34.34817, 62.19967]],
  [3557689, [20.14444, -75.20917]],
  [523523, [43.49806, 43.61889]],
  [1728930, [16.41639, 120.59306]],
  [3454031, [-22.505, -43.17861]],
  [289888, [25.29194, 51.42444]],
  [2809346, [50.08258, 8.24932]],
  [3674453, [8.74798, -75.88143]],
  [3196359, [46.05108, 14.50513]],
  [106297, [28.43279, 45.97077]],
  [1860704, [34.76943, 134.82905]],
  [706369, [49.41835, 26.97936]],
  [1624647, [-7.3274, 108.2207]],
  [1275701, [16.82442, 75.71537]],
  [4167147, [28.53834, -81.37924]],
  [1803318, [35.06306, 118.34278]],
  [2867543, [51.96236, 7.62571]],
  [2921466, [51.50508, 7.09654]],
  [1841066, [34.81282, 126.39181]],
  [3984583, [25.67325, -100.45813]],
  [7838895, [32.01778, 36.04639]],
  [3352136, [-22.55941, 17.08323]],
  [204953, [-6.41621, 20.79995]],
  [354775, [31.13379, 30.12969]],
  [1850158, [34.06667, 134.56667]],
  [294801, [32.81841, 34.9885]],
  [487495, [53.62462, 55.95015]],
  [710719, [48.29045, 25.93241]],
  [6544100, [41.38896, 2.16179]],
  [1185281, [22.65657, 89.79123]],
  [1786640, [30.94454, 113.55284]],
  [5336899, [32.64005, -117.0842]],
  [2036670, [47.20417, 123.63333]],
  [1279186, [23.72894, 92.71791]],
  [584923, [40.58972, 49.66861]],
  [1701472, [8.16667, 124.21667]],
  [509820, [61.78491, 34.34691]],
  [380148, [11.46186, 26.12583]],
  [2320576, [5.52491, 7.49461]],
  [3980760, [19.41116, -102.05644]],
  [5099836, [40.72816, -74.07764]],
  [1800627, [31.46784, 104.68168]],
  [300619, [39.74833, 37.01611]],
  [939270, [-25.87133, 29.23323]],
  [610611, [50.27969, 57.20718]],
  [3841956, [-31.73271, -60.52897]],
  [1269395, [19.84102, 75.88636]],
  [7302826, [17.34769, 78.55757]],
  [2869894, [51.18539, 6.44172]],
  [6066513, [43.86682, -79.2663]],
  [1273066, [22.96585, 76.05526]],
  [3667983, [9.30472, -75.39778]],
  [2034400, [43.6125, 122.26528]],
  [3445839, [-19.74833, -47.93194]],
  [5289282, [33.30616, -111.84125]],
  [4920423, [41.1306, -85.12886]],
  [2640194, [50.37153, -4.14305]],
  [1277082, [22.64132, 88.37727]],
  [1529641, [40.54184, 81.26566]],
  [1529401, [45.58473, 84.88724]],
  [184622, [-0.30719, 36.07225]],
  [1586203, [10.03711, 105.78825]],
  [2954172, [48.37154, 10.89851]],
  [1862462, [35.32785, 139.33735]],
  [7302833, [17.7, 83.21667]],
  [1805179, [29.70475, 116.00206]],
  [3671916, [2.43823, -76.61316]],
  [103630, [17.49326, 44.12766]],
  [78754, [14.54248, 49.12424]],
  [5110629, [42.88645, -78.87837]],
  [2643339, [51.87967, -0.41748]],
  [1631766, [-6.8886, 109.6753]],
  [3644768, [10.45397, -64.18256]],
  [1257022, [24.57726, 80.82719]],
  [3662574, [-9.97472, -67.81]],
  [4464368, [35.99403, -78.89862]],
  [1271987, [26.77615, 79.02133]],
  [4005775, [25.56985, -103.49588]],
  [3466779, [-24.95583, -53.45528]],
  [4171563, [27.77086, -82.67927]],
  [3120619, [41.35967, 2.10028]],
  [2326171, [7.09316, 4.83528]],
  [1049261, [-19.11639, 33.48333]],
  [5359777, [33.66946, -117.82311]],
  [660158, [60.2052, 24.6522]],
  [6544489, [40.38897, -3.74569]],
  [3997479, [25.79302, -108.99808]],
  [2051523, [56.1325, 101.61417]],
  [3522210, [20.11697, -98.73329]],
  [299817, [36.91766, 34.89277]],
  [4705349, [27.50641, -99.50754]],
  [116996, [36.56332, 53.06009]],
  [1272181, [21.19147, 81.27619]],
  [695594, [50.62308, 26.22743]],
  [3522507, [17.06542, -96.72365]],
  [3982912, [22.18912, -100.93792]],
  [1529114, [42.94769, 89.17886]],
  [2338106, [5.18194, 7.71481]],
  [1808198, [35.23929, 115.47358]],
  [1646170, [-6.7063, 108.557]],
  [3456068, [-29.67833, -51.13056]],
  [1336134, [21.43973, 92.00955]],
  [3099424, [54.51889, 18.53188]],
  [6544493, [40.39094, -3.7242]],
  [1262131, [22.89396, 88.41521]],
  [1804386, [32.38583, 111.66778]],
  [962367, [-28.78301, 32.03768]],
  [3870282, [-36.72494, -73.11684]],
  [2633691, [52.58547, -2.12296]],
  [379555, [13.62793, 25.34936]],
  [1882316, [1.43801, 103.78877]],
  [2244799, [14.78333, -16.96667]],
  [338832, [9.59306, 41.86611]],
  [3682385, [7.06222, -73.08644]],
  [2034655, [46.63954, 126.99508]],
  [3470353, [-23.51056, -46.87611]],
  [140044, [33.8973, 48.7516]],
  [2911293, [53.57416, 9.95679]],
  [8533133, [26.59444, 104.83333]],
  [32767, [35.42867, 51.57544]],
  [3928245, [-12.13588, -77.00742]],
  [1799629, [32.99472, 112.53278]],
  [1690039, [15.03425, 120.68445]],
  [153220, [-6.82102, 37.66122]],
  [3462315, [-18.85111, -41.94944]],
  [1254360, [13.63551, 79.41989]],
  [1215502, [5.54167, 95.33333]],
  [1792260, [39.61833, 122.00806]],
  [1255744, [28.99478, 77.01937]],
  [3537906, [22.40694, -79.96472]],
  [2336905, [7.63527, 4.18156]],
  [1706684, [8.94417, 125.50194]],
  [2340451, [10.28969, 11.16729]],
  [2911296, [53.55, 9.93333]],
  [1278130, [13.1147, 80.10981]],
  [3452465, [-24.00583, -46.40278]],
  [7280708, [19.38611, -99.16204]],
  [366847, [13.1483, 33.93117]],
  [787657, [43.32472, 21.90333]],
  [3872348, [-33.59217, -70.6996]],
  [3444876, [-22.52306, -44.10417]],
  [1697846, [14.66667, 120.95]],
  [3450083, [-29.68417, -53.80694]],
  [5525577, [33.57786, -101.85517]],
  [5261457, [43.07305, -89.40123]],
  [3516109, [18.46422, -97.39735]],
  [1863985, [36.06443, 136.22257]],
  [2992166, [43.61093, 3.87635]],
  [2336056, [9.27949, 12.45819]],
  [3100946, [50.79646, 19.12409]],
  [3673164, [3.53944, -76.30361]],
  [1217662, [39.77472, 64.42861]],
  [2634341, [51.4975, -0.1357]],
  [5295903, [33.35283, -111.78903]],
  [1257845, [23.83877, 78.73874]],
  [1605239, [17.41567, 102.78589]],
  [2940132, [50.8357, 12.92922]],
  [3938527, [-14.06777, -75.72861]],
  [944986, [-26.71171, 27.83795]],
  [3628473, [7.76694, -72.225]],
  [2111901, [36.35, 140.45]],
  [4776222, [36.84681, -76.28522]],
  [1797132, [35.4275, 119.45528]],
  [2637487, [50.90395, -1.40428]],
  [11810635, [15.82288, 98.25257]],
  [3119841, [43.37135, -8.396]],
  [1263311, [25.94167, 83.56111]],
  [1852225, [33.95, 130.95]],
  [3937513, [-15.5, -70.13333]],
  [1806445, [27.11716, 114.97927]],
  [1497543, [60.9344, 76.5531]],
  [132892, [36.8427, 54.44391]],
  [2945024, [52.26594, 10.52673]],
  [1808770, [35.89917, 114.1925]],
  [1300466, [17.33521, 96.48135]],
  [1275716, [25.20084, 85.52389]],
  [3648439, [10.43424, -66.87558]],
  [3430697, [-34.66627, -58.72927]],
  [3112737, [40.39354, -3.662]],
  [738648, [41.005, 39.72694]],
  [1336140, [23.16971, 89.21371]],
  [304827, [38.61202, 27.42647]],
  [4299276, [38.25424, -85.75941]],
  [3450144, [-19.76972, -43.85139]],
  [1842025, [35.97861, 126.71139]],
  [1833105, [37.35139, 127.94528]],
  [12042053, [24.35893, 54.48267]],
  [529237, [55.65, 37.71667]],
  [1270393, [28.72985, 77.78068]],
  [1276070, [30.20747, 74.93893]],
  [170794, [35.33588, 40.14084]],
  [1806988, [34.56528, 110.06639]],
  [64435, [10.43959, 45.01432]],
  [3827598, [19.68028, -99.01]],
  [5959974, [45.47723, -75.70164]],
  [1786770, [28.7593, 104.63994]],
  [3843123, [-38.95161, -68.0591]],
  [1684712, [11.24333, 125.00472]],
  [518255, [44.72439, 37.76752]],
  [5511077, [39.52963, -119.8138]],
  [359792, [24.09082, 32.89942]],
  [4499612, [36.09986, -80.24422]],
  [1271942, [27.39134, 79.5793]],
  [7628420, [41.00244, 29.23187]],
  [1851002, [36.33333, 139.01667]],
  [1278672, [14.67784, 77.60813]],
  [3688451, [3.8801, -77.03116]],
  [147622, [38.75428, 48.85062]],
  [5295985, [33.53865, -112.18599]],
  [1864134, [35.16667, 138.68333]],
  [2655459, [53.81667, -3.05]],
  [1790371, [30.3708, 113.44294]],
  [2130203, [40.5, 141.5]],
  [6173577, [43.8361, -79.49827]],
  [3462089, [-29.94218, -50.99278]],
  [6697563, [34.76615, 135.62759]],
  [3523760, [19.33212, -99.21118]],
  [1185159, [24.05783, 89.87696]],
  [1858088, [33.31667, 130.51667]],
  [322165, [39.64917, 27.88611]],
  [3870011, [-38.73965, -72.59842]],
  [2884509, [51.33645, 6.55381]],
  [288967, [23.67027, 58.18911]],
  [2624652, [56.15674, 10.21076]],
  [1852899, [33.16834, 129.72502]],
  [1726280, [13.7567, 121.0584]],
  [109435, [27.0174, 49.62251]],
  [1624494, [-6.8694, 109.1402]],
  [4158476, [25.8576, -80.27811]],
  [2394560, [9.70853, 1.66598]],
  [1185099, [24.89904, 91.87198]],
  [4693003, [32.91262, -96.63888]],
  [3193044, [42.44111, 19.26361]],
  [1258342, [23.33033, 75.04032]],
  [5313457, [33.50921, -111.89903]],
  [4700168, [32.81402, -96.94889]],
  [707471, [48.92312, 24.71248]],
  [724443, [48.71395, 21.25808]],
  [1180289, [30.04587, 70.64029]],
  [3530517, [18.14905, -94.4447]],
  [1785974, [37.68028, 112.73194]],
  [6544105, [41.41814, 2.19933]],
  [1166548, [30.66595, 73.10186]],
  [3104499, [42.84998, -2.67268]],
  [2013159, [62.03389, 129.73306]],
  [4752186, [36.81904, -76.27494]],
  [3402655, [-8.28333, -35.97611]],
  [350550, [26.16418, 32.72671]],
  [3664980, [2.81972, -60.67333]],
  [1640660, [-7.81667, 112.01667]],
  [3378644, [6.80448, -58.15527]],
  [8347656, [18.755, 79.474]],
  [55671, [-0.35817, 42.54536]],
  [74477, [13.96667, 44.18333]],
  [1219649, [39.07328, 63.57861]],
  [5509403, [36.19886, -115.1175]],
  [2517117, [37.18817, -3.60667]],
  [521118, [55.63657, 51.82447]],
  [2392087, [6.49646, 2.60359]],
  [2911522, [51.48158, 11.97947]],
  [5992996, [43.42537, -80.5112]],
  [1105777, [-25.85891, 28.18577]],
  [709932, [48.51716, 34.60617]],
  [1684269, [7.4475, 125.8046]],
  [2911288, [53.55, 10.01667]],
  [563708, [56.24143, 43.45539]],
  [2294915, [4.89816, -1.76029]],
  [2891122, [54.32133, 10.13489]],
  [1803567, [36.08889, 111.51889]],
  [5350734, [37.54827, -121.98857]],
  [219057, [0.49113, 29.47306]],
  [3031582, [44.84044, -0.5805]],
  [1271685, [29.92009, 73.87496]],
  [1864624, [35.33638, 139.40434]],
  [2797656, [51.05, 3.71667]],
  [1682598, [14.55883, 121.13285]],
  [1851604, [35.83643, 139.79957]],
  [1835648, [34.9505, 127.48784]],
  [11523293, [36.59245, 127.29223]],
  [12076997, [-34.51541, -58.76813]],
  [485239, [61.664, 50.815]],
  [2518559, [38.26218, -0.70107]],
  [54225, [1.71594, 44.77166]],
  [601294, [42.45306, 59.61028]],
  [2349019, [6.01277, 6.74768]],
  [3429652, [-34.72904, -58.26374]],
  [2642465, [52.04172, -0.75583]],
  [3451055, [-17.79806, -50.92806]],
  [1169116, [26.23939, 68.40369]],
  [1276128, [27.21731, 77.49009]],
  [6059891, [45.53121, -73.51806]],
  [1847963, [35.44272, 139.36931]],
  [3727135, [18.57677, -72.22625]],
  [946877, [-33.75757, 25.3971]],
  [1735498, [5.647, 100.48772]],
  [1215355, [3.6001, 98.4854]],
  [1705357, [13.93139, 121.61722]],
  [1267755, [18.43915, 79.12856]],
  [4315588, [30.44332, -91.18747]],
  [2998324, [50.63297, 3.05858]],
  [1738050, [4.39928, 113.99163]],
  [3124964, [40.44505, -3.65132]],
  [2655775, [51.44162, 0.14866]],
  [3447259, [-22.82194, -47.26694]],
  [358448, [31.03408, 30.46823]],
  [3571824, [25.05823, -77.34306]],
  [3437056, [-25.33968, -57.50879]],
  [3887127, [-20.21326, -70.15027]],
  [705812, [48.50834, 32.26618]],
  [1259425, [11.93381, 79.82979]],
  [3085128, [50.28682, 19.10385]],
  [6956648, [23.0881, -82.3597]],
  [360686, [28.09193, 30.75813]],
  [487928, [51.29667, 37.84167]],
  [1803834, [36.45596, 115.97766]],
  [2864072, [52.15, 11.63333]],
  [760778, [51.40253, 21.14714]],
  [3429980, [-34.45866, -58.9142]],
  [1256422, [22.75278, 88.34222]],
  [2035225, [46.64142, 124.86283]],
  [118063, [36.2126, 57.68191]],
  [558418, [43.31195, 45.68895]],
  [2165798, [-38.14711, 144.36069]],
  [1259012, [16.20546, 77.35567]],
  [1807508, [23.67704, 116.99961]],
  [217831, [-2.49077, 28.84281]],
  [4297983, [37.98869, -84.47772]],
  [3397147, [-7.21306, -39.31528]],
  [1185162, [24.75636, 90.40646]],
  [1267708, [29.69197, 76.98448]],
  [936374, [-26.1625, 27.8725]],
  [149606, [-5.06893, 39.09875]],
  [1805618, [30.65, 113.1]],
  [3903987, [-19.03332, -65.26274]],
  [1035025, [-14.56257, 40.68538]],
  [295629, [31.79213, 34.64966]],
  [1857144, [35.49527, 139.44279]],
  [3114711, [43.36029, -5.84476]],
  [3383330, [5.86638, -55.16682]],
  [325330, [37.76441, 38.27629]],
  [1278483, [25.55629, 84.66335]],
  [1168718, [30.81029, 73.45155]],
  [1185155, [23.61352, 90.50298]],
  [1269771, [24.80805, 93.9442]],
  [418606, [32.63464, 51.36525]],
  [5509952, [36.09719, -115.14666]],
  [1216311, [38.86056, 65.78905]],
  [1270896, [22.16547, 88.8007]],
  [1692685, [9.73917, 118.73528]],
  [1788618, [35.90056, 117.75194]],
  [2511174, [28.46824, -16.25462]],
  [1877615, [38.04056, 125.71472]],
  [2778067, [47.06667, 15.45]],
  [2338273, [7.36983, 4.1863]],
  [691650, [49.55404, 25.59067]],
  [3464305, [-23.64889, -46.85222]],
  [3433899, [-26.18489, -58.17313]],
  [496015, [47.70911, 40.21443]],
  [2026609, [50.27961, 127.5405]],
  [1697175, [14.82917, 120.28278]],
  [3204541, [44.77842, 17.19386]],
  [8421490, [10.56815, 107.12999]],
  [122285, [36.21329, 58.79575]],
  [1790894, [29.85058, 115.5525]],
  [630468, [53.1384, 29.2214]],
  [293703, [31.97102, 34.78939]],
  [3531732, [19.84386, -90.52554]],
  [1721906, [15.48586, 120.96648]],
  [8285534, [40.4984, -3.7314]],
  [704147, [49.06253, 33.40484]],
  [1262995, [25.1449, 82.56534]],
  [2524170, [38.19394, 15.55256]],
  [1234569, [6.84019, 79.87116]],
  [1851012, [34.79936, 135.35697]],
  [1266976, [22.33971, 87.32501]],
  [1272979, [23.79759, 86.42992]],
  [1254649, [10.78523, 79.13909]],
  [3129028, [41.45004, 2.24741]],
  [1865714, [35.97145, 139.61382]],
  [1725094, [14.4646, 121.1929]],
  [2860410, [51.47805, 6.8625]],
  [1611110, [13.3622, 100.98345]],
  [3164527, [45.4299, 10.98444]],
  [1494114, [53.9059, 86.719]],
  [198629, [0.52036, 35.26993]],
  [1791249, [39.68442, 106.81583]],
  [2234359, [4.57728, 13.68459]],
  [3398269, [-5.52639, -47.49167]],
  [3493032, [18.4539, -69.30864]],
  [1278903, [19.2, 73.16667]],
  [1734586, [4.85, 100.73333]],
  [1630328, [-7.42139, 109.23444]],
  [500004, [58.0446, 38.84259]],
  [2163355, [-42.87936, 147.32941]],
  [1687894, [14.31222, 121.11139]],
  [191245, [-0.10221, 34.76171]],
  [1710914, [14.42972, 120.93667]],
  [470451, [55.70196, 37.81178]],
  [2925177, [47.9959, 7.85222]],
  [1630341, [-6.55694, 107.44333]],
  [463829, [55.9825, 37.18139]],
  [11777624, [51.56733, -0.13415]],
  [1170295, [25.5276, 69.01255]],
  [2290486, [6.87735, -6.45022]],
  [331180, [13.49667, 39.47528]],
  [1510018, [52.53639, 85.20722]],
  [3893894, [-36.82699, -73.04977]],
  [3194360, [45.25167, 19.83694]],
  [3995523, [26.90687, -101.42056]],
  [3631741, [8.41667, -71.13333]],
  [519336, [58.52131, 31.27104]],
  [2325330, [5.48363, 7.03325]],
  [8504960, [59.93111, 30.36072]],
  [2323411, [6.8485, 3.64633]],
  [3447186, [-23.62611, -46.79167]],
  [330186, [8.55, 39.26667]],
  [1262204, [8.17899, 77.43227]],
  [702569, [50.75932, 25.34244]],
  [3161732, [60.39299, 5.32415]],
  [683902, [45.27152, 27.97429]],
  [122438, [35.95411, 50.60607]],
  [1806466, [32.44201, 105.823]],
  [139889, [36.521, 46.2089]],
  [5811696, [47.65966, -117.42908]],
  [2641181, [52.62783, 1.29834]],
  [3460370, [-23.30528, -45.96583]],
  [1864518, [35.65924, 139.54837]],
  [1637510, [-0.58295, 117.09503]],
  [3873775, [-34.17083, -70.74444]],
  [764484, [52.1934, 21.03487]],
  [1735079, [5.36301, 100.4667]],
  [1706090, [13.9411, 121.1631]],
  [3457692, [-22.21389, -49.94583]],
  [2875601, [53.86893, 10.68729]],
  [747323, [41.02697, 28.67732]],
  [5373900, [37.6391, -120.99688]],
  [307654, [39.84528, 33.50639]],
  [2037078, [49.2, 119.7]],
  [3108286, [41.56667, 2.01667]],
  [110690, [18.21639, 42.50528]],
  [2210221, [32.43501, 13.6332]],
  [355628, [31.3073, 30.2981]],
  [1857519, [36.23333, 137.96667]],
  [1814082, [22.84067, 113.2503]],
  [1568574, [13.77648, 109.22367]],
  [4853828, [41.60054, -93.60911]],
  [3568342, [23.03811, -82.37703]],
  [1337233, [24.85098, 89.37108]],
  [1519928, [53.21435, 63.62463]],
  [323779, [36.20655, 36.15722]],
  [5134086, [43.15478, -77.61556]],
  [1845136, [37.87472, 127.73417]],
  [1562414, [10.34599, 107.08426]],
  [2756253, [51.44083, 5.47778]],
  [1214204, [2.9595, 99.0687]],
  [2043572, [40.96946, 126.58523]],
  [3452324, [-22.12556, -51.38889]],
  [1796663, [26.24861, 117.61861]],
  [347796, [26.55695, 31.69478]],
  [2983990, [48.11198, -1.67429]],
  [3448622, [-29.76028, -51.14722]],
  [12199880, [29.9904, -51.0781]],
  [1252797, [30.12796, 77.28371]],
  [1608529, [14.97066, 102.10196]],
  [3083271, [53.01375, 18.59814]],
  [3909234, [-17.98333, -67.15]],
  [769250, [50.87033, 20.62752]],
  [1258182, [24.53256, 81.29234]],
  [3500957, [18.42733, -68.97285]],
  [933773, [-24.65451, 25.90859]],
  [1808931, [39.24889, 117.78917]],
  [5303929, [33.50199, -112.17765]],
  [5812944, [47.25288, -122.44429]],
  [3347939, [-12.3644, 13.53601]],
  [2185964, [-36.8, 174.75]],
  [1646194, [-6.2375, 106.69556]],
  [114259, [29.45137, 55.6809]],
  [1688830, [14.0683, 121.3256]],
  [2516326, [36.68645, -6.13606]],
  [5349755, [34.09223, -117.43505]],
  [5380184, [34.1975, -119.17705]],
  [1647383, [-6.55472, 106.70083]],
  [1859884, [35.98308, 139.74966]],
  [2506999, [36.9, 7.76667]],
  [3111199, [41.54329, 2.10942]],
  [3116025, [40.32234, -3.86496]],
  [1053384, [-18.1492, 49.40234]],
  [1203891, [25.62745, 88.63779]],
  [3827606, [19.60833, -99.16944]],
  [3460949, [-14.78556, -39.28028]],
  [1859383, [34.46667, 135.36667]],
  [3449319, [-22.0175, -47.89083]],
  [2772400, [48.30639, 14.28611]],
  [3130616, [40.48205, -3.35996]],
  [1810437, [30.77576, 106.10294]],
  [1854902, [35.1, 138.86667]],
  [5374732, [33.93752, -117.23059]],
  [1256922, [17.50427, 78.54263]],
  [6825489, [37.14828, 138.23642]],
  [721472, [47.53167, 21.62444]],
  [1275738, [17.90802, 77.51524]],
  [3171728, [45.40797, 11.88586]],
  [3550598, [20.96167, -76.95111]],
  [1568212, [10.29085, 105.75635]],
  [3461655, [-22.85833, -47.22]],
  [4562831, [18.39856, -66.15572]],
  [2037860, [46.58333, 125.0]],
  [3991328, [20.617, -105.23018]],
  [1279290, [23.83605, 91.27939]],
  [2929670, [50.9787, 11.03283]],
  [2232239, [3.8, 10.13333]],
  [1854747, [35.25556, 139.15972]],
  [2306079, [5.69775, -0.32824]],
  [303195, [37.07417, 36.24778]],
  [5911606, [49.26636, -122.95263]],
  [142363, [36.55102, 52.6786]],
  [1275068, [21.30868, 76.23026]],
  [634963, [61.49911, 23.78712]],
  [3516266, [14.90385, -92.25749]],
  [1808316, [28.58917, 112.32833]],
  [748893, [41.15917, 27.8]],
  [1803331, [35.60028, 103.20639]],
  [1646494, [-6.39472, 106.95917]],
  [1262292, [22.69385, 72.86157]],
  [3394682, [-5.1875, -37.34417]],
  [504341, [57.8136, 28.3496]],
  [4466033, [35.05266, -78.87836]],
  [1785738, [35.02306, 110.99278]],
  [2219905, [32.64861, 14.26191]],
  [5358705, [33.6603, -117.99923]],
  [8063098, [48.16116, 16.38233]],
  [1181096, [31.72091, 72.97836]],
  [3460718, [-23.54889, -46.93417]],
  [1274353, [25.78031, 84.74709]],
  [1629710, [-6.15917, 107.29806]],
  [3447624, [-19.46583, -44.24667]],
  [7281017, [-33.58331, -70.63419]],
  [5145215, [40.93121, -73.89875]],
  [1153671, [18.79038, 98.98468]],
  [1520172, [54.86667, 69.15]],
  [1272543, [10.36896, 77.98036]],
  [146268, [35.17531, 33.3642]],
  [3651297, [-0.25305, -79.17536]],
  [1139715, [34.42647, 70.45153]],
  [2320831, [5.80865, 8.08098]],
  [100425, [24.08954, 38.0618]],
  [2800481, [50.41136, 4.44448]],
  [6690108, [30.69461, 76.8504]],
  [7602078, [49.08938, -123.08241]],
  [496348, [55.93583, 37.54889]],
  [7799991, [30.03, 31.47]],
  [1270576, [25.33268, 82.46637]],
  [1527534, [40.52828, 72.7985]],
  [3448742, [-28.21171, -49.1632]],
  [1165569, [31.5709, 73.48531]],
  [10920963, [33.99722, -6.74047]],
  [12129605, [11.55965, 9.66407]],
  [608668, [51.23333, 51.36667]],
  [179330, [-1.03326, 37.06933]],
  [293918, [32.08707, 34.88747]],
  [2218970, [32.7571, 12.72764]],
  [1282898, [28.26689, 83.96851]],
  [1256320, [27.61206, 75.13996]],
  [2746301, [51.55551, 5.0913]],
  [579492, [52.02782, 47.8007]],
  [580922, [44.9892, 41.1234]],
  [1175088, [31.97526, 74.22304]],
  [6545349, [25.77769, 88.89169]],
  [712165, [49.80939, 30.11209]],
  [2650839, [52.5, -2.08333]],
  [6141256, [52.13238, -106.66892]],
  [3099230, [50.29761, 18.67658]],
  [1529569, [44.00782, 87.30461]],
  [5516233, [35.222, -101.8313]],
  [1275120, [28.40392, 77.85773]],
  [3439214, [-25.3552, -57.44545]],
  [3114472, [42.81687, -1.64323]],
  [1259166, [25.77895, 87.47422]],
  [1804850, [23.69767, 103.30372]],
  [1735902, [2.3, 111.81667]],
  [751868, [41.18355, 28.7402]],
  [3654533, [-3.25861, -79.96053]],
  [4119403, [34.74648, -92.28959]],
  [1269935, [15.26954, 76.3871]],
  [3121960, [40.28419, -3.79415]],
  [1800657, [39.93819, 116.09307]],
  [5145476, [41.08144, -81.51901]],
  [3870294, [-35.4264, -71.65542]],
  [3530594, [22.27228, -97.83623]],
  [4341513, [32.52515, -93.75018]],
  [1584071, [11.94646, 108.44193]],
  [140463, [32.86628, 59.22114]],
  [2282006, [4.74851, -6.6363]],
  [1651944, [17.96667, 102.6]],
  [2657832, [57.14369, -2.09814]],
  [745028, [40.76499, 29.92928]],
  [2984114, [49.26526, 4.02853]],
  [2462881, [27.1418, -13.18797]],
  [3474574, [-10.16745, -48.32766]],
  [6317872, [-6.0675, -49.90222]],
  [1843702, [37.27917, 127.4425]],
  [2146142, [-19.26639, 146.80569]],
  [1257540, [28.58498, 78.56959]],
  [3472343, [-22.73917, -47.33139]],
  [563464, [51.48389, 46.10528]],
  [1271715, [23.21667, 72.68333]],
  [1262395, [23.83776, 80.39405]],
  [1856199, [37.45, 138.85]],
  [1841988, [37.5986, 127.1394]],
  [1850692, [35.72526, 139.5383]],
  [4994358, [42.96336, -85.66809]],
  [1640344, [-3.9778, 122.51507]],
  [3392242, [-9.39861, -40.50083]],
  [2279755, [6.82055, -5.27674]],
  [2892518, [51.31667, 9.5]],
  [1261913, [28.67957, 77.06799]],
  [496285, [64.5635, 39.8302]],
  [4076598, [30.69436, -88.04305]],
  [2639996, [50.79899, -1.09125]],
  [2328765, [6.01962, 6.91729]],
  [1632276, [-6.42139, 106.73306]],
  [3464688, [-20.14355, -44.89065]],
  [6947639, [41.09307, 28.80203]],
  [1811619, [30.4, 114.83333]],
  [3395473, [-3.87667, -38.62556]],
  [1861310, [34.78427, 135.40126]],
  [1849372, [34.89044, 135.80325]],
  [1264637, [16.18747, 81.13888]],
  [2043837, [41.40167, 128.17778]],
  [5780993, [40.76078, -111.89105]],
  [1267480, [25.53852, 87.57044]],
  [1624863, [0.91667, 104.45833]],
  [1192366, [24.80418, 88.94875]],
  [2641673, [54.97328, -1.61396]],
  [598098, [55.7068, 21.13912]],
  [3080985, [50.32492, 18.78576]],
  [140380, [37.47473, 57.32903]],
  [3639898, [10.474, -66.54241]],
  [1806696, [22.81899, 113.67306]],
  [2481007, [35.18994, -0.63085]],
  [1610780, [7.00836, 100.47668]],
  [2037086, [40.85158, 122.74754]],
  [1851348, [34.88333, 136.58333]],
  [2437798, [13.80716, 8.9881]],
  [1645895, [-6.26583, 106.55639]],
  [3689169, [7.06528, -73.85472]],
  [462444, [55.17111, 59.65083]],
  [1697018, [11.00639, 124.6075]],
  [1275899, [28.79304, 76.13968]],
  [1783633, [35.40056, 116.96556]],
  [2965140, [51.89797, -8.47061]],
  [99454, [33.34913, 43.78599]],
  [2337207, [7.46478, 5.42333]],
  [632453, [60.29414, 25.04099]],
  [3363094, [-33.73378, 18.97523]],
  [4174715, [30.43826, -84.28073]],
  [1166652, [28.3091, 70.12652]],
  [359173, [29.07441, 31.09785]],
  [11184422, [-15.61791, -47.64874]],
  [5513343, [36.21108, -115.07306]],
  [484972, [53.1585, 48.4681]],
  [3101950, [50.34802, 18.93282]],
  [207570, [-7.00906, 23.45278]],
  [1695743, [11.25111, 125.00639]],
  [1861107, [34.48333, 135.43333]],
  [3389353, [-2.44306, -54.70833]],
  [1791748, [30.81601, 108.37407]],
  [916095, [-14.4469, 28.44644]],
  [1028434, [-17.87861, 36.88833]],
  [3837702, [-28.46957, -65.78524]],
  [2521886, [36.83814, -2.45974]],
  [3468031, [-12.6975, -38.32417]],
  [1638868, [-8.4964, 119.8877]],
  [1647003, [-6.48167, 106.85417]],
  [1812521, [22.94593, 115.64204]],
  [6956647, [23.0072, -82.4017]],
  [1817240, [36.54696, 104.17023]],
  [3450404, [-22.75361, -47.41361]],
  [1804578, [37.18073, 119.94217]],
  [6545348, [52.24424, 21.08545]],
  [3451138, [-32.035, -52.09861]],
  [2542051, [33.68607, -7.38298]],
  [2636503, [51.35, -0.2]],
  [1512979, [40.52861, 70.9425]],
  [2122104, [53.06393, 158.62751]],
  [3530870, [17.5506, -99.50578]],
  [3165185, [45.64953, 13.77678]],
  [3468376, [-20.84889, -41.11278]],
  [11903636, [48.2333, 16.46002]],
  [2314705, [-5.04098, 18.81619]],
  [3544091, [22.41667, -83.69667]],
  [1696710, [7.8257, 123.437]],
  [1336143, [24.00644, 89.2372]],
  [3564124, [22.14957, -80.44662]],
  [295530, [31.25181, 34.7913]],
  [4276873, [38.98223, -94.67079]],
  [1259064, [26.2309, 81.23315]],
  [952865, [-26.25, 28.4]],
  [349158, [30.55258, 31.00904]],
  [2112708, [36.6, 140.65]],
  [1069166, [-19.86586, 47.03333]],
  [2216885, [32.75222, 12.72778]],
  [1636930, [-7.6298, 111.5239]],
  [1270351, [29.94791, 78.16025]],
  [3118594, [40.32718, -3.7635]],
  [380174, [11.7891, 34.3592]],
  [3899361, [-18.4746, -70.29792]],
  [3003796, [49.4938, 0.10767]],
  [2636389, [51.55797, -1.78116]],
  [7279754, [24.19973, 82.67535]],
  [6122091, [43.87111, -79.43725]],
  [3110044, [43.31283, -1.97499]],
  [2911240, [51.68033, 7.82089]],
  [4634946, [35.96064, -83.92074]],
  [56399, [0.06968, 42.74497]],
  [3521168, [20.53315, -97.45946]],
  [1234633, [6.773, 79.8816]],
  [305268, [39.42417, 29.98333]],
  [2874225, [49.98419, 8.2791]],
  [1699802, [14.73694, 120.96083]],
  [3181554, [45.53558, 10.21472]],
  [4956184, [42.26259, -71.80229]],
  [12157007, [-12.05048, -76.97335]],
  [1785462, [32.12722, 112.75417]],
  [9827976, [23.73625, 90.41426]],
  [1268773, [21.51966, 70.45981]],
  [884979, [-18.9707, 32.67086]],
  [2312895, [0.04865, 18.26034]],
  [1631648, [-6.89193, 109.38263]],
  [1797353, [24.91389, 118.58583]],
  [3837056, [-33.29501, -66.33563]],
  [2660646, [46.20222, 6.14569]],
  [1682812, [15.48017, 120.59794]],
  [1182092, [30.16667, 72.65]],
  [4676740, [25.90175, -97.49748]],
  [2038584, [41.93853, 126.41965]],
  [1255560, [27.7, 74.46667]],
  [1785453, [34.86472, 117.55417]],
  [2129376, [42.975, 144.37472]],
  [8555643, [49.03894, 2.07805]],
  [1272051, [16.71311, 81.10437]],
  [1282931, [27.67658, 85.31417]],
  [3654410, [-0.96212, -80.71271]],
  [1638063, [-6.81167, 107.6175]],
  [6544104, [41.37263, 2.1546]],
  [1275778, [21.04365, 75.78506]],
  [1608048, [13.91301, 100.49883]],
  [1267031, [21.82427, 76.35086]],
  [3109718, [43.46472, -3.80444]],
  [2792413, [50.63373, 5.56749]],
  [3644918, [10.16245, -66.88248]],
  [2111220, [35.71667, 140.23333]],
  [1504826, [56.4185, 61.9329]],
  [2503769, [36.47004, 2.8277]],
  [5393049, [34.39166, -118.54259]],
  [1283582, [26.45505, 87.27007]],
  [1014073, [-26.36094, 27.39767]],
  [1277799, [27.57429, 81.59474]],
  [5894171, [44.40011, -79.66634]],
  [1308522, [22.10856, 95.13583]],
  [3169921, [43.8805, 11.09699]],
  [1630634, [-7.7543, 113.2159]],
  [2038650, [46.40202, 125.31454]],
  [2842647, [49.23262, 7.00982]],
  [1783934, [34.45, 115.65]],
  [2755251, [53.21917, 6.56667]],
  [3165926, [40.46438, 17.24707]],
  [1734759, [2.196, 102.2405]],
  [1274784, [22.86225, 88.36796]],
  [160196, [-6.17221, 35.73947]],
  [2652053, [51.11303, -0.18312]],
  [3451234, [-22.41139, -47.56139]],
  [1336144, [24.24984, 89.91655]],
  [2313002, [-5.83861, 13.46306]],
  [2519752, [39.98567, -0.04935]],
  [468809, [55.60686, 37.51991]],
  [610529, [47.11667, 51.88333]],
  [6087029, [45.33619, -75.7225]],
  [1832847, [37.83311, 127.06169]],
  [1255349, [22.72706, 71.64856]],
  [112214, [35.3242, 51.6457]],
  [3685095, [4.83916, -75.66727]],
  [3454857, [-28.26278, -52.40667]],
  [1790396, [29.84347, 114.32201]],
  [1706889, [13.14125, 123.74073]],
  [1529376, [41.76055, 86.15231]],
  [508101, [55.42419, 37.55472]],
  [1253084, [18.11692, 83.41148]],
  [5224151, [41.82399, -71.41283]],
  [2337765, [8.01714, 4.90421]],
  [1786067, [30.421, 112.8919]],
  [1815059, [25.8, 113.03333]],
  [3127461, [42.34106, -3.70184]],
  [989921, [-26.85213, 26.66672]],
  [1606250, [13.17372, 100.93111]],
  [2476897, [35.37103, 1.31699]],
  [2271772, [38.75382, -9.23083]],
  [2646057, [52.05917, 1.15545]],
  [1860437, [35.69423, 139.55495]],
  [2323390, [8.66762, 3.39393]],
  [2498611, [36.16525, 1.33452]],
  [4155966, [26.12231, -80.14338]],
  [5115843, [40.65371, -73.93042]],
  [1865294, [34.95828, 137.08054]],
  [5512909, [36.10803, -115.245]],
  [613607, [42.26791, 42.69459]],
  [3746181, [23.16076, -82.32798]],
  [1805540, [30.35028, 112.19028]],
  [1259312, [14.7502, 78.54813]],
  [1295765, [20.14624, 92.89835]],
  [172955, [35.95283, 39.00788]],
  [1309793, [20.87776, 95.85844]],
  [2229152, [4.7182, 9.7351]],
  [1253286, [12.9184, 79.13255]],
  [1269910, [22.90877, 88.39674]],
  [1278985, [9.49004, 76.3264]],
  [4612862, [35.04563, -85.30968]],
  [125185, [34.30158, 48.82166]],
  [3103402, [49.82245, 19.04686]],
  [2119441, [46.95407, 142.73603]],
  [2759879, [52.37025, 5.21413]],
  [3190261, [43.50891, 16.43915]],
  [1278708, [28.90314, 78.46984]],
  [6119109, [50.45008, -104.6178]],
  [1784580, [32.28034, 119.16999]],
  [2246452, [16.01793, -16.48962]],
  [5317058, [33.41477, -111.90931]],
  [1789137, [29.81667, 113.46667]],
  [5378771, [33.19587, -117.37948]],
  [3511550, [18.45539, -69.9454]],
  [2110683, [36.2, 140.1]],
  [116667, [35.0213, 50.3566]],
  [2633948, [53.54296, -2.63706]],
  [5351515, [33.77391, -117.94145]],
  [126972, [38.5503, 44.9521]],
  [925475, [-11.46556, 34.02071]],
  [300614, [37.75503, 39.31667]],
  [5385955, [34.1064, -117.59311]],
  [4149962, [26.56285, -81.94953]],
  [2036401, [44.1525, 125.83278]],
  [2130057, [40.59306, 140.4725]],
  [1698829, [13.61917, 123.18139]],
  [3465284, [-23.60389, -46.91917]],
  [2127733, [42.63694, 141.60333]],
  [1255062, [12.9246, 80.12707]],
  [1632937, [-6.34278, 106.73833]],
  [2410048, [7.96472, -11.73833]],
  [1260137, [32.27484, 75.65287]],
  [1863431, [35.37111, 139.22361]],
  [1816234, [33.87722, 115.77028]],
  [1985663, [-6.23639, 106.50833]],
  [1275926, [26.56671, 78.78728]],
  [3629706, [10.47306, -68.0125]],
  [1849498, [33.94306, 131.25111]],
  [704508, [48.72305, 37.55629]],
  [1256237, [31.10442, 77.16662]],
  [2651817, [51.38333, -0.1]],
  [1309611, [12.43954, 98.60028]],
  [5115985, [40.66677, -73.88236]],
  [281124, [31.34018, 34.30627]],
  [1105776, [-25.98953, 28.12843]],
  [3530937, [19.41687, -98.90202]],
  [8740017, [15.4167, 75.6167]],
  [1803791, [23.29549, 113.82465]],
  [1629131, [-7.33194, 110.49278]],
  [717582, [48.10306, 20.77806]],
  [311073, [37.76444, 30.55222]],
  [1791636, [34.50355, 109.50891]],
  [2250805, [14.15197, -16.07259]],
  [2634853, [52.58528, -1.98396]],
  [2905891, [51.5388, 7.22572]],
  [532535, [55.67738, 37.76005]],
  [2643097, [53.13333, -1.2]],
  [763166, [53.77995, 20.49416]],
  [3462980, [-23.28167, -46.74528]],
  [1862599, [35.67306, 139.40028]],
  [294071, [32.33291, 34.85992]],
  [1799846, [26.645, 118.17361]],
  [2347209, [9.08044, 6.0099]],
  [5231851, [43.54997, -96.70033]],
  [786712, [42.21389, 20.73972]],
  [2037620, [41.81444, 123.57583]],
  [685948, [46.56718, 26.91384]],
  [2640729, [51.75222, -1.25596]],
  [1607508, [13.65855, 100.53362]],
  [1716198, [16.04313, 120.33325]],
  [5308480, [33.5806, -112.23738]],
  [5379439, [34.06334, -117.65089]],
  [1261653, [20.94237, 72.92467]],
  [2867838, [51.43218, 6.87967]],
  [1734393, [5.36499, 100.56177]],
  [1259184, [19.79825, 85.82494]],
  [1344377, [22.06046, 88.10975]],
  [4431410, [32.29876, -90.18481]],
  [1518262, [50.05494, 72.96464]],
  [1176515, [28.28187, 68.43761]],
  [1267076, [17.24767, 80.14368]],
  [4563243, [18.38078, -65.95739]],
  [204405, [-3.39534, 29.13779]],
  [3652941, [-1.05458, -80.45445]],
  [1259009, [25.61281, 88.12449]],
  [1851032, [36.75, 137.01667]],
  [1269723, [25.00447, 88.14573]],
  [3471859, [-21.20889, -50.43278]],
  [1810845, [32.9, 115.81667]],
  [1732811, [2.03046, 103.31689]],
  [2522258, [38.99424, -1.85643]],
  [1804586, [36.97583, 120.71361]],
  [1269135, [25.75356, 82.68689]],
  [3530599, [18.64592, -91.82991]],
  [2523630, [38.11047, 15.66129]],
  [1242833, [9.66845, 80.00742]],
  [686254, [46.18333, 21.31667]],
  [342884, [11.59364, 37.39077]],
  [630429, [53.1327, 26.0139]],
  [3531200, [19.26174, -98.89775]],
  [2972328, [43.12442, 5.92836]],
  [281129, [31.5272, 34.48347]],
  [3471766, [-21.79444, -48.17556]],
  [2503701, [36.07321, 4.76108]],
  [3037656, [47.47156, -0.55202]],
  [6544103, [41.41849, 2.1677]],
  [255683, [38.24444, 21.73444]],
  [2498954, [36.19591, 1.25537]],
  [3463422, [-23.54083, -46.36861]],
  [1514210, [41.46889, 69.58222]],
  [3658192, [-2.17579, -79.85519]],
  [2348773, [6.21269, 7.07199]],
  [472761, [47.51361, 42.15139]],
  [2758401, [51.58656, 4.77596]],
  [2544248, [32.88108, -6.9063]],
  [670609, [44.85, 24.86667]],
  [5357527, [34.09834, -118.32674]],
  [2338385, [7.81514, 5.06716]],
  [1498894, [55.045, 60.10833]],
  [3436043, [-34.76531, -58.21278]],
  [2286304, [9.45803, -5.62961]],
  [2864695, [52.47719, 13.43126]],
  [1064890, [-21.45267, 47.08569]],
  [1630997, [-6.705, 108.47278]],
  [359280, [30.45977, 31.1842]],
  [518970, [47.42096, 40.09185]],
  [5346111, [38.4088, -121.37162]],
  [4409896, [37.21533, -93.29824]],
  [4168139, [26.00315, -80.22394]],
  [3407327, [-9.7525, -36.66111]],
  [1810979, [29.70997, 107.39391]],
  [601734, [41.83625, 59.96661]],
  [1271912, [25.92774, 80.81266]],
  [2856883, [52.27264, 8.0498]],
  [2555745, [32.33725, -6.34983]],
  [6252065, [41.44163, 2.17727]],
  [3631412, [10.1182, -66.77513]],
  [1810295, [21.93924, 110.84607]],
  [1860672, [35.30889, 139.55028]],
  [2034312, [46.08333, 122.08333]],
  [109323, [26.27944, 50.20833]],
  [294751, [32.01034, 34.77918]],
  [1795928, [22.78199, 115.3475]],
  [6092122, [43.45011, -79.68292]],
  [5292387, [33.68393, -112.13488]],
  [3666645, [4.08466, -76.19536]],
  [2634739, [53.39254, -2.58024]],
  [139817, [28.96887, 50.83657]],
  [1261927, [15.47799, 78.4836]],
  [1853303, [33.23333, 130.3]],
  [3657990, [0.9592, -79.65397]],
  [1169605, [30.07258, 71.19379]],
  [1806096, [36.28389, 120.00333]],
  [1253952, [13.33467, 74.74617]],
  [1650227, [-7.04528, 107.58778]],
  [715429, [46.253, 20.14824]],
  [1264773, [28.75143, 77.29023]],
  [1627549, [-6.11528, 106.15417]],
  [3458930, [-27.81611, -50.32611]],
  [4169171, [27.29393, -80.35033]],
  [2661604, [47.55839, 7.57327]],
  [1255969, [27.56192, 80.68265]],
  [3186573, [44.20169, 17.90397]],
  [5911592, [43.38621, -79.83713]],
  [2831580, [51.17343, 7.0845]],
  [1514019, [40.38421, 71.78432]],
  [157738, [-4.87694, 29.62667]],
  [1169692, [31.80258, 74.25772]],
  [5339631, [33.87529, -117.56644]],
  [1256451, [25.42378, 77.66223]],
  [285629, [29.33278, 48.02861]],
  [3068160, [49.74747, 13.37759]],
  [2337490, [7.84855, 4.32981]],
  [523064, [43.22597, 44.77323]],
  [2464915, [35.82539, 10.63699]],
  [2505329, [36.75587, 5.08433]],
  [3470636, [-22.54417, -44.17139]],
  [3456223, [-10.855, -37.12611]],
  [1276321, [13.84846, 75.70502]],
  [2637627, [51.50949, -0.59541]],
  [1562798, [18.67337, 105.69232]],
  [2392204, [9.33716, 2.63031]],
  [255274, [37.94203, 23.64619]],
  [1279335, [15.62788, 77.27495]],
  [2655095, [50.72048, -1.8795]],
  [268064, [33.55751, 35.37148]],
  [2441291, [13.5, 7.10174]],
  [1849796, [34.73333, 136.51667]],
  [5725846, [44.05207, -123.08675]],
  [2640354, [52.57364, -0.24777]],
  [1253237, [20.9077, 70.36786]],
  [1792520, [39.90395, 116.66183]],
  [2875376, [49.48121, 8.44641]],
  [6947641, [41.02072, 28.58502]],
  [322830, [37.84501, 27.83963]],
  [3682631, [6.17591, -75.59174]],
  [379303, [13.45262, 22.44725]],
  [1818116, [23.44895, 116.68092]],
  [4710178, [33.19762, -96.61527]],
  [1257542, [21.46527, 83.97573]],
  [2779469, [48.25, 16.4]],
  [2878234, [51.0303, 6.98432]],
  [2479536, [36.87617, 6.90921]],
  [3848950, [-29.41105, -66.85067]],
  [1271850, [15.42977, 75.62971]],
  [2316702, [-5.85098, 13.05364]],
  [125446, [36.7631, 45.7222]],
  [3526485, [18.88139, -99.17778]],
  [3464460, [-22.22111, -54.80556]],
  [2322794, [9.18059, 7.17939]],
  [3465196, [-28.6775, -49.36972]],
  [1135689, [36.72895, 68.857]],
  [3583446, [13.48333, -88.18333]],
  [2323675, [5.89405, 5.67666]],
  [1253747, [26.54706, 80.48781]],
  [3120635, [40.47444, -3.6411]],
  [1275163, [28.03811, 79.12668]],
  [3893629, [-29.95332, -71.33947]],
  [1268907, [29.31577, 76.31502]],
  [1582173, [11.73559, 108.3733]],
  [5577147, [40.58526, -105.08442]],
  [1264543, [22.68944, 88.44594]],
  [5364940, [34.69804, -118.13674]],
  [1493467, [51.51473, 81.20613]],
  [1907146, [35.85295, 139.41212]],
  [3691674, [-4.90389, -80.68528]],
  [370737, [9.53342, 31.66049]],
  [1571058, [10.92889, 108.10208]],
  [1577995, [10.65993, 107.77206]],
  [2803201, [50.83619, 4.31454]],
  [523812, [55.91163, 37.73076]],
  [1859675, [34.81667, 135.41667]],
  [3530049, [19.3692, -99.29089]],
  [285066, [31.52935, 35.0938]],
  [1790254, [30.92689, 113.92221]],
  [3584399, [13.72397, -89.188]],
  [1269280, [23.70468, 87.07872]],
  [3988214, [20.57196, -101.19154]],
  [3466296, [-27.09639, -52.61833]],
  [1293960, [20.78919, 97.03776]],
  [76184, [14.54274, 44.40514]],
  [3874960, [-41.4693, -72.94237]],
  [1529279, [43.80194, 81.08722]],
  [311111, [36.58718, 36.17347]],
  [1853483, [36.3, 139.8]],
  [499292, [53.38365, 55.90773]],
  [2243940, [12.56801, -16.27326]],
  [4459467, [35.79154, -78.78112]],
  [1732745, [1.53741, 103.65779]],
  [1865005, [36.33333, 139.45]],
  [288764, [23.57769, 58.39982]],
  [7483743, [24.36537, 88.83431]],
  [1784554, [30.29556, 111.50472]],
  [1638284, [-7.8353, 112.6947]],
  [3538803, [23.09544, -82.32669]],
  [3903320, [-21.53549, -64.72956]],
  [2857458, [53.14118, 8.21467]],
  [549479, [55.78363, 37.47137]],
  [576432, [55.88333, 37.6]],
  [1839652, [37.15222, 127.07056]],
  [3173331, [44.64783, 10.92539]],
  [2750053, [51.8425, 5.85278]],
  [7284820, [26.84766, 89.37558]],
  [1632033, [-7.6453, 112.9075]],
  [1273802, [11.75617, 79.76693]],
  [3014728, [45.17869, 5.71479]],
  [91597, [34.1959, 43.88568]],
  [2653941, [52.2, 0.11667]],
  [759734, [50.04132, 21.99901]],
  [5317071, [33.41421, -111.94348]],
  [5380698, [34.57943, -118.11646]],
  [2498766, [36.06386, 4.62744]],
  [1806535, [30.8703, 120.0933]],
  [3470583, [-12.15278, -44.99]],
  [5355933, [37.66882, -122.0808]],
  [1261039, [25.99023, 79.45334]],
  [1575627, [10.38639, 105.43518]],
  [3746183, [23.13833, -82.36417]],
  [1269937, [31.53723, 75.91269]],
  [2651123, [53.52285, -1.13116]],
  [3519537, [16.73176, -92.64126]],
  [560465, [55.82299, 37.81306]],
  [3387296, [-3.68611, -40.34972]],
  [1213614, [3.5765, 98.6151]],
  [8504953, [59.90839, 30.28484]],
  [5964700, [46.49, -80.99001]],
  [5391295, [36.67774, -121.6555]],
  [6544488, [40.43893, -3.61537]],
  [1214191, [3.5679, 98.956]],
  [1260692, [12.96796, 80.15025]],
  [2014006, [43.80291, 131.94578]],
  [2035644, [41.09822, 120.74792]],
  [617239, [46.84274, 29.62909]],
  [153209, [-3.35, 37.33333]],
  [1165108, [27.95558, 68.63823]],
  [940316, [-26.31905, 27.6486]],
  [6822096, [36.39659, 140.53479]],
  [1857550, [35.48333, 133.05]],
  [2479215, [36.28639, 7.95111]],
  [2325590, [7.77104, 4.55698]],
  [1831142, [10.60932, 103.52958]],
  [3046526, [46.0725, 18.23083]],
  [727523, [43.84872, 25.9534]],
  [1732687, [1.8548, 102.9325]],
  [3518135, [19.66658, -99.09477]],
  [2548880, [34.0701, -4.95473]],
  [1856184, [35.8563, 139.90266]],
  [2112312, [36.38333, 140.53333]],
  [2309527, [3.75578, 8.78166]],
  [1858428, [36.13497, 139.39004]],
  [2294700, [5.6698, -0.01657]],
  [2033413, [47.72143, 128.87529]],
  [3460845, [-26.90778, -48.66194]],
  [6621166, [33.25333, 126.56181]],
  [1270407, [29.58182, 74.32938]],
  [783920, [44.8458, 20.40116]],
  [2334327, [9.08297, 3.85196]],
  [3461408, [-14.79364, -39.03949]],
  [1712051, [14.8361, 120.97844]],
  [1841603, [35.84278, 129.21167]],
  [934154, [-20.16194, 57.49889]],
  [1268159, [12.83515, 79.70006]],
  [3108118, [40.45975, -3.6975]],
  [1919014, [29.99152, 120.89845]],
  [2038342, [41.79194, 120.77917]],
  [1809879, [31.6199, 113.9978]],
  [976361, [-25.77507, 29.46482]],
  [1062663, [-15.71667, 46.31667]],
  [532615, [55.67719, 37.89322]],
  [217562, [0.14164, 29.29117]],
  [3884373, [-29.90453, -71.24894]],
  [1804153, [29.56227, 103.76386]],
  [216449, [-6.74504, 23.95328]],
  [4692559, [33.15067, -96.82361]],
  [2033168, [46.06771, 125.98263]],
  [295514, [32.08074, 34.8338]],
  [3660689, [-1.24908, -78.61675]],
  [2500017, [34.67279, 3.263]],
  [1632228, [-6.17028, 106.53028]],
  [543460, [56.35722, 41.31917]],
  [298033, [37.22349, 39.75519]],
  [1849892, [35.5, 134.23333]],
  [4619947, [35.06535, -85.24912]],
  [1788450, [38.40917, 112.73333]],
  [146384, [34.68406, 33.03794]],
  [336014, [12.6, 37.46667]],
  [4717782, [29.69106, -95.2091]],
  [1279064, [13.0025, 80.20611]],
  [3174659, [43.54427, 10.32615]],
  [2633352, [53.95763, -1.08271]],
  [1789273, [23.15486, 112.89161]],
  [1270711, [24.64691, 77.3113]],
  [1177384, [32.07095, 73.68802]],
  [2756071, [52.21833, 6.89583]],
  [3472177, [-23.00667, -44.31806]],
  [1277835, [28.69287, 76.93555]],
  [1816265, [36.48333, 117.83333]],
  [1814786, [22.16667, 111.78333]],
  [1276100, [21.69482, 72.9805]],
  [2766824, [47.79941, 13.04399]],
  [3456166, [-22.28194, -42.53111]],
  [2451478, [13.44032, -6.25947]],
  [1263220, [22.42114, 87.32257]],
  [5384170, [34.05529, -117.75228]],
  [701404, [46.84735, 35.38196]],
  [3943789, [-13.40985, -76.13235]],
  [2172797, [-16.92366, 145.76613]],
  [1271976, [26.77549, 82.15018]],
  [1273581, [25.63705, 85.04794]],
  [1803422, [28.85535, 121.1447]],
  [1812728, [38.51306, 114.99556]],
  [3450909, [-16.47083, -54.63556]],
  [98530, [31.33198, 45.2944]],
  [298299, [38.67351, 29.4058]],
  [1513886, [40.12341, 67.82842]],
  [4566880, [18.01031, -66.62398]],
  [5143307, [40.8501, -73.93541]],
  [5427946, [39.70471, -105.08137]],
  [1791673, [37.50914, 122.11356]],
  [97990, [33.75403, 44.60518]],
  [1815302, [23.46132, 116.77007]],
  [1869446, [38.75417, 125.645]],
  [1256287, [24.82733, 92.79787]],
  [324496, [38.37255, 34.02537]],
  [1586296, [10.60857, 106.67135]],
  [6693228, [-34.61032, -58.39766]],
  [1812961, [31.13019, 104.38198]],
  [3118150, [42.46667, -2.45]],
  [1853677, [36.3, 139.36667]],
  [2035980, [46.53168, 125.1038]],
  [1262771, [26.49892, 77.99534]],
  [487150, [55.81838, 37.41224]],
  [1588275, [11.54798, 107.80772]],
  [667268, [45.8, 24.15]],
  [4003757, [25.64724, -100.09582]],
  [5400075, [37.36883, -122.03635]],
  [3640465, [10.22609, -67.877]],
  [5881791, [49.05798, -122.25257]],
  [1737486, [3.16667, 113.03333]],
  [3460748, [-23.71694, -46.84917]],
  [5346827, [33.11921, -117.08642]],
  [1173491, [33.58196, 71.44929]],
  [1254241, [26.16638, 75.78824]],
  [1797945, [23.7, 113.03333]],
  [3531023, [18.51413, -88.30381]],
  [117392, [36.24992, 46.2735]],
  [3351663, [-12.57626, 13.40547]],
  [3641351, [8.88902, -64.2527]],
  [1626560, [-7.03306, 107.51833]],
  [3461879, [-25.39048, -51.46541]],
  [1810458, [36.38333, 119.75278]],
  [1258831, [21.09687, 81.0289]],
  [1802238, [27.73444, 111.99444]],
  [2511401, [28.4853, -16.32014]],
  [1854703, [35.35, 136.61667]],
  [7279570, [35.75459, 139.46852]],
  [7302856, [17.49313, 78.30196]],
  [1807143, [28.6501, 121.26591]],
  [1831797, [13.10271, 103.19822]],
  [3895088, [-36.60664, -72.10344]],
  [1253133, [19.45591, 72.81136]],
  [3392998, [-5.91556, -35.26278]],
  [1729085, [10.53333, 122.83333]],
  [1630333, [-7.0868, 110.9158]],
  [3992619, [28.70007, -100.52353]],
  [307084, [37.18836, 40.57723]],
  [5107464, [40.77205, -73.93014]],
  [1512473, [41.55339, 60.62057]],
  [579464, [55.80945, 37.95806]],
  [2640101, [50.71429, -1.98458]],
  [2648404, [51.86568, -2.2431]],
  [942470, [-27.76952, 30.79165]],
  [1645457, [-8.55861, 125.57361]],
  [2490098, [35.39664, 0.14027]],
  [7302828, [17.44781, 78.52633]],
  [462745, [55.7, 37.85]],
  [1163198, [33.99047, 72.2863]],
  [1256639, [23.24723, 88.43302]],
  [3521342, [20.6274, -87.07987]],
  [3021372, [47.31667, 5.01667]],
  [6544494, [40.41831, -3.70275]],
  [1635283, [-5.11306, 105.30667]],
  [2654264, [53.8, -2.23333]],
  [1854162, [35.78389, 139.24306]],
  [2525473, [39.23054, 9.11917]],
  [5109790, [40.63399, -73.99681]],
  [2666199, [59.85882, 17.63889]],
  [4613868, [36.52977, -87.35945]],
  [2646458, [53.64904, -1.78416]],
  [706524, [45.35675, 36.47542]],
  [2852217, [52.53878, 13.42443]],
  [124721, [29.8742, 52.8025]],
  [2037534, [43.37383, 128.23789]],
  [2516548, [37.26638, -6.94004]],
  [6544487, [40.40021, -3.69618]],
  [919009, [-12.52897, 27.88382]],
  [108512, [30.97531, 41.03808]],
  [1785781, [22.63333, 110.15]],
  [5403022, [33.83585, -118.34063]],
  [616635, [40.7942, 43.84528]],
  [1814934, [30.66134, 117.47783]],
  [1856584, [34.73333, 135.56667]],
  [2521420, [38.87789, -6.97061]],
  [4907959, [42.27113, -89.094]],
  [3642833, [10.30589, -67.63212]],
  [1637158, [-3.2945, 102.8614]],
  [2990363, [43.83665, 4.35788]],
  [1277100, [22.76602, 88.36336]],
  [1633118, [-2.20833, 113.91667]],
  [3402383, [-4.85889, -43.35611]],
  [1580410, [20.95045, 107.07336]],
  [1651112, [-6.8017, 108.6311]],
  [4756955, [37.03737, -76.33161]],
  [3345439, [52.67659, -2.44926]],
  [2036876, [46.40124, 124.88322]],
  [3696417, [-9.93062, -76.24223]],
  [6544102, [41.40104, 2.1394]],
  [4898015, [41.52519, -88.0834]],
  [5102466, [40.91677, -74.17181]],
  [3110876, [41.43541, 2.18982]],
  [2650752, [56.46913, -2.97489]],
  [2488835, [36.26417, 2.75393]],
  [546230, [55.07944, 38.77833]],
  [118994, [30.4067, 55.9939]],
  [3173529, [45.49167, 12.24538]],
  [285704, [29.33389, 48.07611]],
  [5282804, [41.17923, -73.18945]],
  [1609776, [16.44671, 102.833]],
  [2550078, [33.25682, -8.50882]],
  [2884161, [52.49973, 13.40338]],
  [789128, [44.01667, 20.91667]],
  [610612, [43.64806, 51.17222]],
  [3456290, [-22.8075, -43.41389]],
  [1272013, [11.3428, 77.72741]],
  [3133880, [63.43049, 10.39506]],
  [4903279, [41.78586, -88.14729]],
  [290340, [26.22787, 50.58565]],
  [1586896, [12.66747, 108.03775]],
  [214974, [-5.94749, 29.19471]],
  [2019528, [42.81384, 132.87348]],
  [665004, [46.54245, 24.55747]],
  [233346, [2.77457, 32.29899]],
  [3038354, [43.5283, 5.44973]],
  [1278860, [30.36099, 76.79782]],
  [1586350, [11.92144, 109.15913]],
  [3547398, [23.04111, -81.5775]],
  [8533870, [48.77186, 2.01891]],
  [3453303, [-21.78778, -46.56139]],
  [2655524, [53.75, -2.48333]],
  [3171457, [44.79935, 10.32618]],
  [3086800, [50.2584, 18.85632]],
  [325303, [38.75667, 30.54333]],
  [890422, [-19.45, 29.81667]],
  [1797318, [25.48333, 103.78333]],
  [3493175, [19.79344, -70.6884]],
  [1808857, [33.07507, 107.02214]],
  [6544492, [40.43404, -3.70379]],
  [2615876, [55.39594, 10.38831]],
  [1265859, [23.40576, 88.49073]],
  [1788816, [37.90278, 115.20361]],
  [3395503, [-5.38146, -49.13232]],
  [967476, [-26.98023, 26.67272]],
  [1257804, [25.87498, 86.59611]],
  [4221552, [32.08354, -81.09983]],
  [1732721, [1.4726, 103.878]],
  [1646698, [-6.41972, 107.45583]],
  [1276720, [31.80921, 75.20294]],
  [1793700, [22.25135, 112.7799]],
  [149658, [-5.01622, 32.82663]],
  [2852458, [52.39886, 13.06566]],
  [3435261, [-31.39296, -58.02089]],
  [3488465, [17.99107, -76.95742]],
  [1175864, [32.93448, 73.73102]],
  [1514879, [40.28256, 69.62216]],
  [2656194, [51.56844, 0.45782]],
  [4710826, [32.7668, -96.59916]],
  [2451185, [11.31755, -5.66654]],
  [1796556, [18.24306, 109.505]],
  [12217184, [11.81554, 108.21444]],
  [2038679, [45.54545, 126.97703]],
  [258576, [39.63689, 22.41761]],
  [2229752, [4.6363, 9.4469]],
  [563523, [55.78959, 38.44671]],
  [1805733, [38.50062, 102.19379]],
  [1643837, [0.5375, 123.0625]],
  [5140405, [43.04812, -76.14742]],
  [216281, [-1.67409, 29.22845]],
  [528293, [44.60778, 40.10583]],
  [2294665, [5.58365, -0.10722]],
  [2475744, [36.71182, 4.04591]],
  [6418220, [55.61252, 37.72639]],
  [576279, [55.58635, 37.67781]],
  [755330, [52.2401, 20.98869]],
  [354502, [31.11174, 30.93991]],
  [630376, [54.2279, 28.505]],
  [2128574, [43.18944, 141.00222]],
  [1645133, [1.66711, 101.44316]],
  [1849186, [35.67056, 139.88861]],
  [3649833, [9.55451, -69.19564]],
  [6137270, [48.41675, -71.06573]],
  [1701053, [8.0034, 124.28395]],
  [3458329, [-16.2525, -47.95028]],
  [726848, [42.43278, 25.64194]],
  [2907911, [49.40768, 8.69079]],
  [2407790, [7.87687, -11.19025]],
  [3404545, [-8.28333, -35.03333]],
  [3037854, [49.9, 2.3]],
  [3897347, [-22.45667, -68.92371]],
  [3458266, [-22.38484, -41.78324]],
  [2302357, [5.10535, -1.2466]],
  [537832, [55.7, 37.8]],
  [517121, [55.7, 37.75]],
  [1275947, [16.54078, 81.52322]],
  [503550, [44.04861, 43.05944]],
  [2642607, [54.57623, -1.23483]],
  [3086586, [50.09713, 18.54179]],
  [1213547, [2.96667, 99.8]],
  [352951, [27.73264, 30.84129]],
  [4333190, [29.97854, -90.16396]],
  [3188582, [44.53842, 18.66709]],
  [1174167, [28.64739, 70.65694]],
  [1337240, [24.59025, 88.27444]],
  [1805528, [29.10678, 119.64421]],
  [2855745, [51.71905, 8.75439]],
  [990930, [-28.73226, 24.76232]],
  [3564178, [21.84, -78.76194]],
  [6418146, [55.59072, 37.59519]],
  [1805844, [33.26338, 115.36108]],
  [1818223, [22.45, 114.03333]],
  [2529317, [34.21, -4.01]],
  [1005646, [-26.53333, 29.06667]],
  [1307835, [21.46002, 95.3884]],
  [2759661, [51.98, 5.91111]],
  [463355, [55.744, 38.01684]],
  [3979802, [19.9855, -102.28387]],
  [2972191, [47.39484, 0.70398]],
  [3591415, [14.50189, -90.56196]],
  [1277508, [25.22099, 88.77732]],
  [3461563, [-20.02194, -44.05889]],
  [1848277, [35.43333, 133.33333]],
  [2655237, [53.58333, -2.43333]],
  [6317464, [-12.89444, -38.32722]],
  [3907584, [-19.58361, -65.75306]],
  [6544490, [40.38866, -3.70035]],
  [2998286, [45.83362, 1.24759]],
  [3191648, [45.32673, 14.44241]],
  [3455070, [-25.51626, -48.52537]],
  [1141269, [33.55391, 68.42096]],
  [5379513, [33.78779, -117.85311]],
  [130802, [33.6374, 46.4227]],
  [2037222, [43.50075, 124.81979]],
  [3860443, [-45.86413, -67.49656]],
  [5351247, [33.87029, -117.92534]],
  [4703223, [31.11712, -97.7278]],
  [1497337, [69.3535, 88.2027]],
  [970566, [-26.43138, 28.47713]],
  [3633622, [10.34447, -67.04325]],
  [1268593, [29.80153, 76.39959]],
  [4509884, [39.75895, -84.19161]],
  [582432, [54.90442, 52.3154]],
  [2938913, [49.87167, 8.65027]],
  [1215957, [37.22417, 67.27833]],
  [3108288, [41.11667, 1.25]],
  [4709796, [26.20341, -98.23001]],
  [973111, [-29.81292, 30.63646]],
  [2744114, [52.45313, 4.81356]],
  [1270164, [23.99241, 85.36162]],
  [11468429, [35.05192, 136.66958]],
  [1253200, [23.52604, 77.81092]],
  [1632353, [-4.0135, 119.6255]],
  [3125239, [40.46206, -3.6766]],
  [5927690, [49.2846, -122.78217]],
  [2034996, [40.0025, 119.74889]],
  [255524, [38.01539, 23.69187]],
  [2759821, [52.155, 5.3875]],
  [11695689, [35.60004, 126.91699]],
  [5786882, [47.61038, -122.20068]],
  [554233, [55.91417, 37.82556]],
  [1180809, [26.73033, 67.7769]],
  [1178231, [31.14926, 72.68323]],
  [2675408, [59.42804, 17.95093]],
  [1270498, [29.22254, 79.5286]],
  [2341355, [7.47734, 4.35351]],
  [1822214, [13.36179, 103.86056]],
  [2284647, [7.41251, -7.55383]],
  [1529651, [47.84864, 88.13361]],
  [1270568, [22.84202, 88.65606]],
  [1265683, [10.96209, 79.39124]],
  [1627253, [-7.4478, 112.7183]],
  [412800, [29.25722, 48.05722]],
  [2659994, [46.516, 6.63282]],
  [3613533, [15.61444, -87.95302]],
  [2636882, [53.40979, -2.15761]],
  [7284824, [47.47603, 19.03605]],
  [2036776, [42.96333, 126.74778]],
  [1621613, [-6.711, 108.5037]],
  [2654710, [50.82838, -0.13947]],
  [546105, [59.75069, 30.58856]],
  [3436077, [-34.5627, -58.45829]],
  [3518692, [20.38886, -99.99577]],
  [1259395, [21.64219, 69.60929]],
  [2295458, [4.92678, -1.75773]],
  [2037335, [45.18333, 124.81667]],
  [3024635, [45.77969, 3.08682]],
  [1274056, [14.22262, 76.40038]],
  [4333177, [29.98409, -90.15285]],
  [1815427, [31.6, 117.86667]],
  [2343784, [7.90292, 4.31419]],
  [1254657, [29.97323, 76.83214]],
  [1254327, [12.22662, 79.07461]],
  [1278685, [22.55251, 72.9552]],
  [3393001, [-2.90472, -41.77667]],
  [3723779, [18.23427, -72.53539]],
  [2296606, [6.20228, -1.66796]],
  [3460535, [-23.26417, -47.29917]],
  [358172, [31.08898, 31.59478]],
  [1856367, [35.70611, 139.55944]],
  [1805857, [22.81027, 115.83058]],
  [3402591, [-1.29389, -47.92639]],
  [4013516, [19.24997, -103.72714]],
  [1019330, [-32.84721, 27.44218]],
  [1233369, [7.2083, 79.8358]],
  [935264, [-20.88231, 55.4504]],
  [261745, [35.32787, 25.14341]],
  [4164601, [25.98731, -80.23227]],
  [7303248, [30.84918, 120.92583]],
  [1529484, [42.83393, 93.50601]],
  [516215, [55.67798, 37.27773]],
  [3176885, [41.45845, 15.55188]],
  [1268715, [22.95998, 88.42849]],
  [3589885, [14.71889, -90.64417]],
  [2342490, [11.52351, 7.31174]],
  [1293625, [14.0823, 98.19151]],
  [2338711, [8.83784, 3.75628]],
  [2759706, [52.21, 5.96944]],
  [1794806, [39.23333, 106.76944]],
  [685826, [47.65729, 23.56808]],
  [1709968, [9.9839, 122.81423]],
  [4762894, [37.02987, -76.34522]],
  [3449324, [-23.62306, -46.55111]],
  [5405693, [34.18667, -118.44897]],
  [5784607, [40.69161, -112.00105]],
  [1264407, [16.74385, 77.98597]],
  [1810240, [23.36085, 103.15372]],
  [339219, [11.13333, 39.63333]],
  [3118514, [41.61674, 0.62218]],
  [1735093, [5.48032, 100.49849]],
  [3877949, [-40.57395, -73.13348]],
  [3452073, [-22.71611, -43.55528]],
  [1185191, [23.9028, 89.11943]],
  [212902, [-2.94373, 25.92237]],
  [2634491, [52.51868, -1.9945]],
  [1586357, [21.01667, 107.3]],
  [2346615, [4.73614, 6.86236]],
  [1795055, [22.9464, 113.35769]],
  [1299154, [18.82464, 95.22216]],
  [368277, [13.18087, 32.73999]],
  [1258109, [12.95629, 78.27539]],
  [267008, [33.27333, 35.19389]],
  [2427455, [8.57437, 16.07722]],
  [3699088, [-7.16378, -78.50027]],
  [1325211, [17.64944, 95.45705]],
  [1897007, [37.54, 127.20556]],
  [2082600, [-2.53371, 140.71813]],
  [776029, [52.29242, 20.93531]],
  [1274304, [22.05697, 78.93958]],
  [1263814, [12.5223, 76.89746]],
  [3687230, [4.74639, -75.91167]],
  [3728474, [19.75938, -72.19815]],
  [1649593, [-4.12891, 104.16695]],
  [2497411, [33.35608, 6.86319]],
  [1848004, [35.4879, 139.39101]],
  [2514169, [36.51543, -4.88583]],
  [2747351, [51.69917, 5.30417]],
  [1699296, [14.76011, 121.19993]],
  [89113, [30.75545, 20.22625]],
  [522377, [44.6333, 41.9444]],
  [1689056, [14.6982, 121.1236]],
  [758470, [52.22904, 21.01644]],
  [4276614, [38.8814, -94.81913]],
  [1852663, [35.23333, 137.1]],
  [2338269, [7.91283, 4.66741]],
  [4013728, [29.32322, -100.95217]],
  [3722286, [18.5111, -72.63343]],
  [1651887, [-6.9375, 109.1325]],
  [2647878, [53.56539, -0.07553]],
  [2353099, [6.32485, 8.11368]],
  [5014051, [42.49044, -83.01304]],
  [1814093, [25.58474, 100.21229]],
  [745169, [40.07806, 29.51333]],
  [2130404, [43.10806, 141.55056]],
  [1566319, [21.59422, 105.84817]],
  [4575352, [34.00071, -81.03481]],
  [1626932, [-8.112, 115.08818]],
  [2805615, [49.79391, 9.95121]],
  [963230, [-26.1844, 27.70203]],
  [1270525, [25.68544, 85.20981]],
  [510808, [56.90528, 59.94361]],
  [1270996, [27.13181, 81.95332]],
  [3654215, [-2.13404, -79.59415]],
  [1513243, [40.47237, 71.72463]],
  [1275812, [23.25397, 69.66928]],
  [5441492, [39.86804, -104.97192]],
  [2647356, [50.85519, 0.57292]],
  [3868707, [-39.81422, -73.24589]],
  [1270079, [13.82807, 77.49143]],
  [3169522, [44.69825, 10.63125]],
  [2326899, [4.74215, 7.08368]],
  [1283581, [27.01709, 84.8808]],
  [2646914, [51.62907, -0.74934]],
  [4679195, [32.95373, -96.89028]],
  [355635, [24.97916, 32.87722]],
  [3655673, [0.35171, -78.12233]],
  [5526337, [31.99735, -102.07791]],
  [1798473, [29.71667, 113.88333]],
  [1850034, [34.50065, 135.60211]],
  [1864132, [34.86667, 138.26667]],
  [124085, [36.96667, 46.10961]],
  [548114, [43.91333, 42.72083]],
  [2787149, [50.86935, 4.37737]],
  [2753801, [52.3025, 4.68889]],
  [2416969, [7.75624, -8.8179]],
  [1260728, [10.77319, 76.65366]],
  [1789065, [32.68, 109.01722]],
  [4574324, [32.77657, -79.93092]],
  [2775220, [47.26266, 11.39454]],
  [1648580, [-8.0983, 112.1681]],
  [4739526, [31.54933, -97.14667]],
  [3564436, [23.10809, -82.3866]],
  [2475687, [34.87833, -1.315]],
  [1860735, [35.41667, 136.86667]],
  [3590979, [14.83472, -91.51806]],
  [2033225, [48.00945, 122.73651]],
  [1699054, [7.8275, 123.4782]],
  [5011148, [42.58031, -83.0302]],
  [12166273, [10.30989, 105.73855]],
  [6155721, [43.17126, -79.24267]],
  [1854083, [33.03333, 130.45]],
  [1271107, [22.77547, 73.61488]],
  [135423, [36.41819, 54.97628]],
  [1813344, [39.04194, 106.39583]],
  [1722930, [15.08262, 120.66288]],
  [3629576, [11.69152, -70.19918]],
  [2113164, [35.86667, 140.01667]],
  [1266486, [26.59006, 74.85397]],
  [1860871, [34.7381, 135.57442]],
  [1636022, [-3.4109, 114.8642]],
  [2968254, [45.76601, 4.8795]],
  [132938, [37.25004, 55.16721]],
  [1258470, [23.6164, 87.13061]],
  [2146268, [-27.56056, 151.95386]],
  [1808336, [23.43077, 115.82991]],
  [1160939, [27.20245, 60.68476]],
  [4685907, [33.21484, -97.13307]],
  [1314759, [22.9359, 97.7498]],
  [1856775, [31.73333, 131.06667]],
  [518557, [54.0105, 38.2846]],
  [3448031, [-29.83333, -51.15]],
  [1857568, [34.56667, 135.55]],
  [623549, [52.1229, 26.0951]],
  [1276634, [26.10119, 74.32028]],
  [1813325, [31.21592, 107.50092]],
  [174018, [36.37051, 37.5157]],
  [1861436, [36.31667, 139.2]],
  [1279403, [30.14453, 74.19552]],
  [3100796, [50.33394, 19.20479]],
  [594739, [55.93333, 23.31667]],
  [1262951, [30.81383, 75.16878]],
  [2219701, [32.75718, 12.37633]],
  [8504948, [59.96567, 30.31154]],
  [4850751, [42.00833, -91.64407]],
  [1791056, [29.26778, 120.22528]],
  [3675657, [11.37837, -72.2395]],
  [3682426, [1.61438, -75.60623]],
  [4839366, [41.30815, -72.92816]],
  [1805935, [22.87932, 116.07318]],
  [3682028, [4.30079, -74.80754]],
  [3874096, [-33.04752, -71.44249]],
  [3608248, [15.75971, -86.78221]],
  [1794035, [31.03595, 121.2146]],
  [3460102, [-26.48611, -49.06667]],
  [4156404, [29.65163, -82.32483]],
  [367644, [13.56907, 33.56718]],
  [3617723, [11.97444, -86.09417]],
  [5406567, [36.33023, -119.29206]],
  [2483668, [35.73734, 0.55599]],
  [3996663, [19.11695, -104.34214]],
  [7910932, [27.82511, 99.70779]],
  [502971, [55.7, 37.5]],
  [1187530, [23.65, 90.61667]],
  [3082914, [50.13717, 18.96641]],
  [12157008, [-5.18591, -80.66927]],
  [2212775, [27.03766, 14.42832]],
  [691999, [48.94832, 38.49166]],
  [2487134, [35.93115, 0.08918]],
  [3042430, [47.51758, 19.10549]],
  [1273309, [24.90247, 84.18217]],
  [1791325, [39.49944, 106.71167]],
  [1214882, [2.9845, 99.6158]],
  [1841598, [35.82333, 128.73778]],
  [64536, [3.11383, 43.6498]],
  [1171123, [32.58704, 73.49123]],
  [1513131, [40.08444, 65.37917]],
  [738743, [40.31389, 36.55444]],
  [2388873, [4.25671, 18.41583]],
  [1273193, [26.50167, 83.77936]],
  [1864416, [34.71378, 135.62033]],
  [4151909, [26.27119, -80.2706]],
  [6146143, [45.40008, -71.89908]],
  [2485801, [31.94932, 5.32502]],
  [3183539, [41.76337, 12.33078]],
  [5402405, [34.17056, -118.83759]],
  [1846986, [36.56636, 128.72275]],
  [1026014, [-16.15639, 33.58667]],
  [3893656, [-27.36679, -70.3314]],
  [1633037, [-2.9925, 120.19694]],
  [2541479, [35.16813, -2.93352]],
  [3645854, [10.24247, -66.85723]],
  [115770, [32.32612, 50.8572]],
  [1857046, [34.82691, 135.47057]],
  [1804208, [26.40238, 112.85908]],
  [1721168, [10.9465, 123.288]],
  [3979844, [22.76843, -102.58141]],
  [5097598, [40.66399, -74.2107]],
  [295548, [32.02379, 34.75185]],
  [1185111, [22.70817, 89.07185]],
  [3630932, [10.17389, -67.54194]],
  [4843564, [41.05343, -73.53873]],
  [3457000, [-22.3677, -46.94552]],
  [361546, [31.13159, 33.79844]],
  [169389, [35.93062, 36.63393]],
  [90708, [36.80851, 43.03935]],
  [5339111, [37.97798, -122.03107]],
  [553287, [50.09833, 45.41601]],
  [643492, [65.01236, 25.46816]],
  [1270239, [13.00715, 76.0962]],
  [518976, [56.11095, 47.47755]],
  [3033123, [47.24878, 6.01815]],
  [5316428, [33.63059, -112.33322]],
  [2498392, [36.15281, 5.69016]],
  [1266945, [22.71861, 88.37806]],
  [102527, [29.96974, 40.20641]],
  [333772, [7.67344, 36.83441]],
  [379149, [14.2459, 32.9891]],
  [1990589, [-6.09889, 106.63806]],
  [3052009, [47.68333, 17.63512]],
  [355026, [26.33721, 31.89295]],
  [1270484, [22.93218, 88.41859]],
  [496527, [54.91578, 37.41114]],
  [2210554, [31.20892, 16.58866]],
  [293788, [32.08227, 34.81065]],
  [1907299, [35.80472, 139.60194]],
  [1260434, [18.98878, 73.11013]],
  [555129, [55.76667, 37.83333]],
  [515027, [55.61667, 37.68333]],
  [1732869, [2.0442, 102.5689]],
  [2289887, [5.83739, -5.35723]],
  [1622636, [-7.13972, 110.405]],
  [5551123, [33.49838, -112.13432]],
  [1788081, [30.9525, 118.75528]],
  [1254309, [22.74252, 88.37326]],
  [3836846, [-33.33425, -60.2108]],
  [1858067, [35.01667, 135.96667]],
  [3090048, [50.67211, 17.92533]],
  [4330145, [30.22409, -92.01984]],
  [3099759, [54.1522, 19.40884]],
  [2482572, [34.83033, 0.15171]],
  [1185115, [24.45771, 89.70802]],
  [3459943, [-13.85875, -40.08512]],
  [3088825, [52.54682, 19.70638]],
  [3082707, [50.77141, 16.28432]],
  [584649, [40.61832, 47.15014]],
  [363885, [7.70111, 27.98972]],
  [1024552, [-25.05194, 33.64417]],
  [2676209, [59.31278, 18.07577]],
  [1807689, [27.56337, 110.00404]],
  [2350249, [5.78917, 7.83829]],
  [4280539, [39.04833, -95.67804]],
  [2332515, [8.4939, 8.51532]],
  [1885823, [31.76667, 104.71667]],
  [1163965, [25.4605, 68.71745]],
  [1150515, [9.14011, 99.33311]],
  [215771, [2.77391, 27.61603]],
  [3540667, [21.92972, -79.4425]],
  [1273800, [14.47995, 78.82346]],
  [1514581, [41.01667, 70.14361]],
  [5799625, [47.38093, -122.23484]],
  [524294, [55.575, 42.0426]],
  [1180436, [32.32422, 74.35039]],
  [1630058, [-6.3591, 106.2494]],
  [1270216, [27.59551, 78.05201]],
  [1639900, [-7.70583, 110.60639]],
  [550478, [43.2509, 46.58766]],
  [3104748, [40.35, -3.7]],
  [5396003, [34.26945, -118.78148]],
  [1789647, [27.89642, 102.26341]],
  [1168555, [30.34314, 73.38944]],
  [1813812, [22.7984, 114.46716]],
  [1332083, [30.55083, 73.39083]],
  [3667873, [5.71434, -72.93391]],
  [1265157, [24.69007, 78.41915]],
  [747712, [41.67719, 26.55597]],
  [1642414, [-7.54595, 112.23307]],
  [877401, [-10.68333, 35.65]],
  [1266305, [13.13768, 78.12999]],
  [6325521, [46.80326, -71.17793]],
  [3620381, [12.62937, -87.13105]],
  [2219960, [27.05, 14.4]],
  [3437863, [-25.34682, -57.60647]],
  [1801582, [31.18013, 115.02213]],
  [281102, [31.29722, 34.24357]],
  [1735168, [3.15, 101.76667]],
  [3460728, [-23.59167, -48.05306]],
  [3454783, [-18.57889, -46.51806]],
  [1632197, [-7.1026, 107.7641]],
  [4644312, [35.84562, -86.39027]],
  [6544495, [40.41317, -3.68307]],
  [8436486, [40.64548, -74.01241]],
  [1632654, [-2.12914, 106.11377]],
  [1806881, [23.03845, 116.28988]],
  [2341656, [12.87398, 11.04057]],
  [3728097, [18.19331, -73.74601]],
  [3168673, [40.67545, 14.79328]],
  [2634677, [51.65531, -0.39602]],
  [1789703, [34.80222, 117.11167]],
  [112646, [35.27401, 59.21949]],
  [1163967, [25.76818, 68.66196]],
  [250799, [32.33326, 35.75279]],
  [1626542, [-0.87956, 131.26104]],
  [1708522, [6.50306, 124.84694]],
  [748208, [40.75694, 29.81472]],
  [1894616, [26.33583, 127.80139]],
  [1809062, [23.19346, 116.61219]],
  [2638703, [51.36667, 1.41667]],
  [624079, [54.5081, 30.4172]],
  [1796421, [36.93833, 114.50583]],
  [5990579, [49.88307, -119.48568]],
  [3469092, [-22.9527, -46.54418]],
  [2395049, [7.17826, 2.0667]],
  [1864750, [33.27945, 131.49751]],
  [483551, [55.62047, 37.49338]],
  [3513090, [12.1084, -68.93354]],
  [96961, [36.6532, 44.54407]],
  [618605, [47.76314, 27.92932]],
  [1263834, [24.07184, 75.06986]],
  [1963770, [-6.83778, 107.47278]],
  [1793900, [30.50802, 105.57332]],
  [1270990, [21.46026, 80.19205]],
  [3827414, [19.35985, -99.35016]],
  [116402, [35.57691, 53.39205]],
  [693468, [48.84136, 37.58948]],
  [3493146, [19.30099, -70.25259]],
  [2033242, [44.8, 126.53333]],
  [3584257, [13.67694, -89.27972]],
  [3483849, [21.99631, -99.01093]],
  [1162004, [27.52948, 68.75915]],
  [1859951, [34.50896, 135.7929]],
  [3652350, [-1.67098, -78.64712]],
  [1522203, [53.28333, 69.4]],
  [3098722, [52.73679, 15.22878]],
  [3386361, [-5.09417, -42.83667]],
  [1169027, [33.14782, 73.75187]],
  [2448085, [16.97333, 7.99111]],
  [757065, [52.29185, 21.04845]],
  [5363748, [34.05779, -118.30091]],
  [1804252, [25.128, 113.35041]],
  [3448632, [-25.5302, -49.20836]],
  [124862, [38.4329, 45.7749]],
  [1258916, [9.45296, 77.55335]],
  [2989317, [47.90289, 1.90389]],
  [1804591, [36.19278, 117.65694]],
  [958724, [-25.66756, 27.24208]],
  [4835797, [41.76371, -72.68509]],
  [1519843, [52.9729, 63.11677]],
  [3446606, [-22.4167, -42.97822]],
  [2994160, [49.11911, 6.17269]],
  [2036403, [41.06667, 122.95]],
  [1273467, [27.03333, 88.26667]],
  [2038438, [46.55861, 131.42444]],
  [3493240, [18.61501, -68.70798]],
  [965289, [-23.90449, 29.46885]],
  [1292288, [16.91867, 97.37001]],
  [7284842, [47.54157, 19.04501]],
  [964349, [-26.71667, 27.1]],
  [1647149, [-7.3257, 108.3534]],
  [1637090, [-8.1335, 113.2248]],
  [2495662, [36.46214, 7.42608]],
  [1711982, [14.83413, 120.73271]],
  [6992326, [30.67995, 76.72211]],
  [3445679, [-29.75472, -57.08833]],
  [1260777, [24.17128, 72.43827]],
  [1643078, [-6.32639, 108.32]],
  [1085510, [-17.89, 31.1475]],
  [2503661, [36.74871, 3.19249]],
  [2288829, [6.13193, -5.9506]],
  [3452640, [-16.44972, -39.06472]],
  [2806654, [52.42452, 10.7815]],
  [1855425, [33.96047, 133.30522]],
  [2036536, [43.72145, 127.33401]],
  [536164, [55.6254, 37.65032]],
  [2518794, [37.28287, -5.92088]],
  [1631992, [-6.7559, 111.038]],
  [1272243, [22.63341, 88.42286]],
  [3472766, [-12.13556, -38.41917]],
  [1270370, [27.39491, 80.13165]],
  [4005937, [25.6604, -100.40651]],
  [1807112, [30.45, 114.8]],
  [5137849, [40.59122, -73.94458]],
  [1605245, [15.23844, 104.84866]],
  [1259163, [23.33062, 86.36303]],
  [1859393, [35.38329, 139.93254]],
  [943882, [-28.10391, 26.86593]],
  [1015621, [-25.63473, 27.78022]],
  [6947640, [40.982, 28.6399]],
  [2849647, [51.61379, 7.19738]],
  [3455478, [-27.64528, -48.66778]],
  [1788268, [29.71667, 112.4]],
  [1784178, [36.81667, 117.81667]],
  [1280957, [39.81121, 98.28618]],
  [2751283, [50.84833, 5.68889]],
  [5107129, [42.97839, -78.79976]],
  [1574023, [10.36004, 106.35996]],
  [738927, [40.9781, 27.51101]],
  [5406222, [34.53611, -117.29116]],
  [2624886, [57.048, 9.9187]],
  [3470858, [-21.22583, -43.77361]],
  [2654200, [52.80728, -1.64263]],
  [1181073, [29.79713, 72.85772]],
  [3587923, [14.48285, -90.53425]],
  [1272648, [27.47989, 94.90837]],
  [2918632, [51.53443, 9.93228]],
  [3185728, [41.32355, 19.45469]],
  [473972, [55.72393, 37.81952]],
  [542634, [55.75, 37.55]],
  [1113217, [31.0306, 61.4949]],
  [1260637, [28.14469, 77.32546]],
  [1855095, [32.58333, 131.66667]],
  [1262067, [17.05439, 79.26707]],
  [615532, [41.64228, 41.63392]],
  [3117164, [41.54211, 2.4445]],
  [4669635, [32.44874, -99.73314]],
  [1276320, [22.82449, 88.33841]],
  [2661552, [46.94809, 7.44744]],
  [3137115, [58.97005, 5.73332]],
  [1253315, [22.69021, 73.56299]],
  [1631905, [-0.2159, 100.6334]],
  [1023441, [-26.26786, 28.12225]],
  [1864105, [35.21667, 138.61667]],
  [3121245, [41.40237, 2.15641]],
  [1274220, [13.32231, 75.774]],
  [1524325, [51.72371, 75.32287]],
  [1855078, [35.94897, 139.86793]],
  [2742032, [41.55032, -8.42005]],
  [2035669, [47.13333, 129.27426]],
  [1259005, [21.89764, 83.3966]],
  [5405380, [38.10409, -122.25664]],
  [4839745, [41.13815, -73.54346]],
  [152451, [-1.5, 33.8]],
  [3468403, [-29.95111, -51.09389]],
  [1848774, [34.86877, 138.31952]],
  [81302, [32.08963, 23.95385]],
  [461740, [55.65608, 37.56846]],
  [5327684, [37.87159, -122.27275]],
  [2033824, [43.96667, 116.03333]],
  [1270670, [15.17112, 77.36244]],
  [4006163, [23.18126, -102.87136]],
  [3522307, [18.85195, -97.09957]],
  [1151933, [8.43333, 99.96667]],
  [2907669, [49.13995, 9.22054]],
  [2895992, [48.76508, 11.42372]],
  [1849845, [34.81667, 137.4]],
  [2037411, [40.45361, 124.07167]],
  [905395, [-12.54982, 28.24071]],
  [2820256, [48.39841, 9.99155]],
  [1735150, [3.3213, 101.5767]],
  [309527, [37.18111, 33.215]],
  [3081741, [52.64817, 19.0678]],
  [5178127, [40.60843, -75.49018]],
  [3438115, [-25.3386, -57.52167]],
  [3171180, [43.1122, 12.38878]],
  [1271675, [26.47249, 76.71744]],
  [278913, [33.37889, 35.48389]],
  [1650319, [-7.59939, 112.8186]],
  [1185056, [34.1463, 73.21168]],
  [490971, [55.63711, 37.38115]],
  [1784953, [37.36497, 120.40997]],
  [10263232, [28.50254, 77.23117]],
  [515024, [55.80672, 38.96178]],
  [8504621, [11.27543, 25.14026]],
  [4257227, [37.97476, -87.55585]],
  [2945756, [51.52392, 6.9285]],
  [3389321, [-7.11389, -34.97806]],
  [1039536, [-23.85972, 35.34722]],
  [2940187, [52.51667, 13.28333]],
  [2473449, [35.6781, 10.09633]],
  [1176106, [31.3332, 73.41868]],
  [3109981, [41.45152, 2.2081]],
  [2751773, [52.15833, 4.49306]],
  [6169141, [46.34515, -72.5477]],
  [7290243, [53.48462, 10.22904]],
  [3172629, [45.58005, 9.27246]],
  [2330028, [7.38299, 4.26031]],
  [3652567, [-1.02863, -79.46352]],
  [303873, [37.91631, 28.32225]],
  [230166, [2.2499, 32.89985]],
  [2853969, [48.88436, 8.69892]],
  [2756669, [51.81, 4.67361]],
  [3460005, [-22.29639, -48.55778]],
  [2857807, [50.10061, 8.76647]],
  [1255927, [26.22096, 84.35609]],
  [2537406, [33.00103, -7.61662]],
  [1041190, [-14.3525, 35.65056]],
  [3413829, [64.13548, -21.89541]],
  [1273587, [23.83312, 79.4419]],
  [1907301, [35.815, 139.6853]],
  [1280737, [29.65, 91.1]],
  [2507972, [35.45139, 2.90583]],
  [1858964, [35.70222, 139.47556]],
  [728203, [43.41667, 24.61667]],
  [3169361, [44.05755, 12.56528]],
  [1609899, [14.06467, 100.64578]],
  [1279344, [19.67203, 78.5359]],
  [5059163, [46.87719, -96.7898]],
  [3526798, [18.34536, -99.5413]],
  [1627610, [-6.11889, 106.575]],
  [3080165, [51.93548, 15.50643]],
  [3465059, [-23.895, -46.42528]],
  [932505, [-29.31667, 27.48333]],
  [1255647, [18.2989, 83.89751]],
  [187968, [-3.21799, 40.11692]],
  [1253744, [17.40577, 78.55911]],
  [2650497, [50.76871, 0.28453]],
  [2317397, [-3.31687, 17.38063]],
  [1230089, [6.8905, 79.9015]],
  [3107784, [40.45535, -3.46973]],
  [4672989, [30.08605, -94.10185]],
  [2347954, [11.52134, 13.68952]],
  [2337659, [7.84036, 4.48557]],
  [4013720, [28.19013, -105.47012]],
  [1269065, [21.75482, 70.62347]],
  [1802940, [23.31072, 116.16869]],
  [1262775, [22.81731, 70.8377]],
  [3688256, [3.90089, -76.29783]],
  [481453, [55.65625, 37.48496]],
  [1608534, [13.8196, 100.04427]],
  [2924573, [52.51559, 13.45482]],
  [2364104, [8.98333, 1.13333]],
  [757026, [50.01381, 20.98698]],
  [3654667, [-3.99313, -79.20422]],
  [2649808, [50.7236, -3.52751]],
  [2336589, [8.89367, 11.3596]],
  [2315728, [3.25651, 19.77234]],
  [1512165, [56.2694, 90.4993]],
  [2034754, [40.12175, 116.64783]],
  [2639093, [53.43012, -1.35678]],
  [1861749, [34.68333, 135.7]],
  [3980194, [19.26722, -103.73778]],
  [3486270, [9.42958, -64.46428]],
  [1213500, [3.3285, 99.1625]],
  [3718962, [18.43117, -72.86521]],
  [3666608, [5.53528, -73.36778]],
  [2944368, [53.53615, 8.59298]],
  [3874787, [-53.15483, -70.91129]],
  [1309937, [22.03501, 96.45683]],
  [4391812, [39.09112, -94.41551]],
  [1640581, [-6.97038, 109.64794]],
  [2112576, [38.41667, 141.3]],
  [2035966, [42.77139, 129.42333]],
  [1267016, [30.70547, 76.22196]],
  [3647444, [8.92416, -67.42929]],
  [2848273, [51.17983, 7.1925]],
  [2262963, [38.5244, -8.8882]],
  [792078, [43.89139, 20.34972]],
  [4984247, [42.27756, -83.74088]],
  [2223763, [4.9547, 9.9404]],
  [2419533, [10.05692, -12.86576]],
  [1275218, [22.16917, 71.66671]],
  [1258126, [22.72394, 88.34563]],
  [2491889, [35.43583, 7.14333]],
  [6317953, [-25.44472, -49.1925]],
  [1862302, [34.05, 131.56667]],
  [741100, [40.97782, 37.89047]],
  [2042893, [41.15472, 126.28944]],
  [5345743, [34.06862, -118.02757]],
  [2800931, [51.20892, 3.22424]],
  [6318694, [-12.78444, -38.40389]],
  [1278083, [26.06832, 83.18358]],
  [3171168, [42.4584, 14.20283]],
  [3445487, [-21.55139, -45.43028]],
  [1518542, [45.01556, 78.37389]],
  [2516395, [37.76922, -3.79028]],
  [3931276, [-15.8422, -70.0199]],
  [6544099, [40.43547, -3.7317]],
  [2653261, [51.90006, -2.07972]],
  [1252758, [13.10073, 77.59632]],
  [5120034, [40.80788, -73.94542]],
  [1807301, [23.11037, 113.4418]],
  [716935, [47.95539, 21.71671]],
  [2033536, [49.28333, 120.73333]],
  [569154, [44.22333, 42.05778]],
  [2522013, [36.13326, -5.45051]],
  [1270801, [16.43547, 80.99555]],
  [1714956, [6.74972, 125.35722]],
  [1863209, [34.88333, 136.93333]],
  [6318696, [-11.86417, -55.5025]],
  [484912, [55.73333, 37.66667]],
  [4724129, [30.50826, -97.6789]],
  [520068, [55.86647, 38.4438]],
  [2836788, [52.46667, 13.35]],
  [4499379, [34.22573, -77.94471]],
  [1717641, [14.48369, 120.89878]],
  [6332428, [40.79472, -73.9425]],
  [1816336, [37.36667, 118.01667]],
  [2743856, [52.0575, 4.49306]],
  [1238992, [6.88297, 79.90708]],
  [2686657, [59.27412, 15.2066]],
  [5967629, [43.54594, -80.25599]],
  [467978, [52.62366, 38.50169]],
  [3114256, [40.23604, -3.76752]],
  [1649881, [-6.4846, 110.7083]],
  [511510, [55.64528, 37.33583]],
  [1277780, [22.78498, 88.32586]],
  [2327143, [5.82917, 7.35056]],
  [1804169, [27.68806, 111.42944]],
  [1791536, [37.26366, 122.05228]],
  [5412199, [39.80276, -105.08748]],
  [1055429, [-23.35, 43.66667]],
  [2472706, [37.27442, 9.87391]],
  [5780026, [40.23384, -111.65853]],
  [3452525, [-22.23, -45.93639]],
  [1276736, [26.78817, 82.71617]],
  [1680932, [17.61577, 121.72285]],
  [4998830, [42.73253, -84.55553]],
  [1791544, [19.6157, 110.74894]],
  [690548, [48.6242, 22.2947]],
  [1147290, [36.75635, 66.8972]],
  [1800498, [36.71667, 117.5]],
  [684039, [47.75, 26.66667]],
  [1218667, [37.59378, 61.83031]],
  [1567788, [9.59995, 105.97193]],
  [2191562, [-45.87416, 170.50361]],
  [1277599, [21.49266, 86.93348]],
  [3463690, [-16.3775, -39.58028]],
  [1569684, [13.98333, 108.0]],
  [5343858, [33.94001, -118.13257]],
  [3182164, [45.69601, 9.66721]],
  [1628453, [-6.40278, 106.77444]],
  [1272842, [14.41435, 77.72035]],
  [300822, [37.9293, 41.94134]],
  [315373, [39.73919, 39.49015]],
  [2419992, [10.38542, -9.30568]],
  [516264, [55.68432, 37.44654]],
  [3427408, [-34.67907, -58.47263]],
  [1281019, [37.1075, 79.93548]],
  [2491191, [33.8, 2.86514]],
  [1264111, [30.5309, 75.87949]],
  [3995019, [27.07028, -109.44372]],
  [2327879, [8.14911, 4.72074]],
  [1489530, [58.19807, 68.25457]],
  [1252942, [20.73933, 78.59784]],
  [1798713, [22.99376, 114.71311]],
  [3348078, [-12.38333, 16.93333]],
  [2035399, [45.12902, 124.82769]],
  [1801615, [24.68413, 113.59839]],
  [3460170, [-23.5275, -46.9025]],
  [1714201, [9.30722, 123.30261]],
  [7284830, [47.52978, 19.08068]],
  [2862375, [50.96545, 6.95314]],
  [1856977, [35.11667, 138.91667]],
  [5334223, [33.15809, -117.35059]],
  [3101619, [50.30582, 18.9742]],
  [909863, [-13.13667, 28.41661]],
  [3374333, [14.93152, -23.51254]],
  [5339840, [33.64113, -117.91867]],
  [1268936, [28.12559, 75.39797]],
  [4164167, [25.94204, -80.2456]],
  [2129537, [43.80306, 143.89083]],
  [5443910, [39.83665, -105.0372]],
  [4005143, [27.91928, -110.89755]],
  [2653225, [53.25, -1.41667]],
  [4903976, [40.71754, -89.58426]],
  [4151316, [27.96585, -82.8001]],
  [495344, [55.92497, 37.97218]],
  [5347335, [38.24936, -122.03997]],
  [324190, [36.54375, 31.99982]],
  [3557846, [23.12518, -82.30067]],
  [1162813, [30.0445, 72.3556]],
  [2034497, [45.33333, 122.78333]],
  [1257055, [17.68589, 73.99333]],
  [2982652, [49.44313, 1.09932]],
  [1274767, [28.45178, 78.78277]],
  [1497917, [61.09979, 72.60349]],
  [2847736, [48.49144, 9.20427]],
  [5110918, [40.69427, -73.91875]],
  [1795857, [27.34089, 117.4831]],
  [1635111, [-7.4664, 112.4338]],
  [667873, [47.79926, 22.86255]],
  [1566166, [19.8, 105.76667]],
  [1175156, [30.72708, 72.64607]],
  [3640226, [9.04183, -69.74206]],
  [5119167, [40.5976, -73.96514]],
  [1203344, [23.60612, 89.84064]],
  [3587345, [13.80722, -89.17917]],
  [1262710, [26.64862, 84.91656]],
  [1274553, [23.63556, 86.16712]],
  [625324, [52.0495, 29.2456]],
  [1259297, [10.38128, 78.82141]],
  [4890864, [42.03725, -88.28119]],
  [1258178, [28.199, 76.6183]],
  [1259385, [11.66613, 92.74635]],
  [3523183, [18.00011, -94.5569]],
  [2343093, [4.80293, 8.25341]],
  [2923544, [49.47593, 10.98856]],
  [1816790, [29.82739, 106.43645]],
  [5401395, [33.49364, -117.14836]],
  [5784549, [40.60967, -111.9391]],
  [1586443, [9.17682, 105.15242]],
  [3451329, [-23.71056, -46.41333]],
  [170592, [33.57175, 36.4027]],
  [159071, [-7.76667, 35.7]],
  [2743477, [52.5125, 6.09444]],
  [518659, [53.0959, 49.9462]],
  [119115, [37.106, 58.50955]],
  [1255344, [17.14054, 79.62045]],
  [1241622, [7.2906, 80.6336]],
  [1277324, [23.04553, 88.83084]],
  [5359488, [33.96168, -118.35313]],
  [702972, [48.90485, 38.44207]],
  [3465644, [-20.66028, -43.78611]],
  [1274337, [24.9177, 79.58871]],
  [460413, [55.88333, 26.53333]],
  [3451668, [-22.46889, -44.44667]],
  [2653266, [51.73575, 0.46958]],
  [124620, [31.9364, 49.3039]],
  [7302861, [22.86416, 88.63701]],
  [1636884, [-7.47056, 110.21778]],
  [2991214, [47.75205, 7.32866]],
  [934765, [-20.23325, 57.46609]],
  [2227613, [5.62611, 10.25421]],
  [3471697, [-25.59306, -49.41028]],
  [1279390, [21.25665, 77.51006]],
  [1261669, [23.4067, 88.36861]],
  [2328684, [6.85783, 7.39577]],
  [2328952, [12.87695, 10.45536]],
  [1812228, [32.85231, 120.30947]],
  [1276856, [18.23454, 75.69275]],
  [3471335, [-23.11694, -46.55028]],
  [3445446, [-23.21139, -46.82833]],
  [1862636, [35.25, 136.25]],
  [1811729, [22.18659, 112.30424]],
  [738618, [40.3875, 36.08111]],
  [141679, [37.47318, 49.45785]],
  [4722625, [32.94818, -96.72972]],
  [2339631, [12.45347, 10.04115]],
  [1783940, [29.71877, 120.24233]],
  [2987914, [42.69764, 2.89541]],
  [4942618, [42.63342, -71.31617]],
  [4385018, [39.09556, -94.35523]],
  [700051, [47.56826, 34.39126]],
  [5729485, [45.49818, -122.43148]],
  [1285173, [20.46504, 94.8712]],
  [5324200, [38.00492, -121.80579]],
  [934995, [-26.49884, 31.38004]],
  [6615440, [41.64928, -0.90757]],
  [712451, [46.75578, 36.78815]],
  [1255491, [26.25788, 82.07269]],
  [2208032, [-37.68611, 176.16667]],
  [4471025, [35.95569, -80.00532]],
  [1859405, [36.4, 139.33333]],
  [1639362, [-6.13139, 106.37972]],
  [618577, [46.83156, 29.47769]],
  [971534, [-25.47448, 30.97033]],
  [1646034, [-6.48556, 106.88194]],
  [3399058, [-8.88202, -36.50216]],
  [2468369, [33.88146, 10.0982]],
  [1803367, [36.84032, 115.71183]],
  [1028079, [-25.44278, 31.99528]],
  [6947756, [51.2372, -2.6266]],
  [1496503, [63.19309, 75.43728]],
  [933945, [-20.29806, 57.47833]],
  [99135, [32.05114, 44.44017]],
  [2208485, [32.46739, 14.56874]],
  [578740, [47.13975, 39.75181]],
  [1803352, [36.42472, 114.20472]],
  [2037069, [47.4565, 126.95368]],
  [1278815, [12.79163, 78.71644]],
  [3050434, [46.90618, 19.69128]],
  [1538637, [56.60056, 84.88639]],
  [1043893, [-13.31278, 35.24056]],
  [5375911, [33.55391, -117.21392]],
  [5416541, [39.57916, -104.87692]],
  [1795184, [23.11444, 113.84722]],
  [2393693, [11.13417, 2.93861]],
  [3471715, [-22.87278, -42.34306]],
  [2034440, [46.98043, 128.04497]],
  [3466692, [-21.13778, -48.97278]],
  [2037685, [45.36088, 130.78679]],
  [580724, [55.39485, 43.83992]],
  [965528, [-23.94299, 31.14107]],
  [2036973, [50.24413, 127.49016]],
  [5435464, [38.25445, -104.60914]],
  [1784841, [27.31667, 103.71667]],
  [1269939, [22.74747, 77.72736]],
  [2338403, [6.97198, 3.99938]],
  [496638, [56.3, 38.13333]],
  [3691148, [-3.56694, -80.45153]],
  [910111, [-17.84194, 25.85425]],
  [2396518, [-0.71933, 8.78151]],
  [3836669, [-34.61772, -68.33007]],
  [2036671, [45.98333, 126.6]],
  [3617708, [12.92559, -85.91747]],
  [1858910, [36.40263, 136.45088]],
  [1500665, [54.6567, 86.1737]],
  [1786546, [31.16611, 112.58306]],
  [3462964, [-23.32167, -46.72694]],
  [1861164, [35.96474, 139.69644]],
  [4718097, [29.56357, -95.28605]],
  [2037712, [44.53333, 125.7]],
  [4845193, [41.55815, -73.0515]],
  [3031137, [48.83545, 2.24128]],
  [3096880, [51.76109, 18.09102]],
  [2544001, [35.00044, -5.90378]],
  [1028918, [-12.97395, 40.51775]],
  [6076211, [46.09454, -64.7965]],
  [1256949, [26.02301, 76.34408]],
  [2319668, [6.7, 6.33333]],
  [1283460, [26.81436, 87.27972]],
  [1264621, [13.5503, 78.50288]],
  [158151, [-9.23333, 33.61667]],
  [5407933, [34.06862, -117.93895]],
  [5503766, [36.02525, -115.24194]],
  [2453348, [14.4843, -4.18296]],
  [1147540, [36.13068, 68.70829]],
  [3529986, [19.67052, -99.17989]],
  [1226260, [8.5778, 81.2289]],
  [2651621, [51.55, 0.16667]],
  [4589387, [32.85462, -79.97481]],
  [286282, [24.34745, 56.70937]],
  [2035610, [48.48333, 124.83333]],
  [1500973, [51.71081, 94.45338]],
  [3468615, [-22.88717, -42.02622]],
  [515879, [54.48147, 53.47103]],
  [1255264, [16.81467, 81.52717]],
  [1650077, [-8.2325, 114.35755]],
  [2347059, [12.45389, 4.1975]],
  [1798760, [35.53917, 106.68611]],
  [1688253, [14.2814, 121.4161]],
  [2062338, [-32.27682, 115.72978]],
  [5793933, [47.97898, -122.20208]],
  [378699, [12.7, 28.43333]],
  [378231, [17.70217, 33.98638]],
  [1815251, [31.91102, 120.26302]],
  [1591474, [9.29414, 105.72776]],
  [4682464, [30.62798, -96.33441]],
  [4167499, [28.03446, -80.58866]],
  [1269388, [26.51667, 88.73333]],
  [2036595, [49.45, 117.7]],
  [1851193, [35.31667, 137.13333]],
  [2870221, [51.45342, 6.6326]],
  [4169014, [26.23786, -80.12477]],
  [3114965, [42.33669, -7.86407]],
  [3925033, [-10.88528, -61.95167]],
  [2656192, [51.26249, -1.08708]],
  [751077, [40.35222, 27.97667]],
  [3906194, [-17.39799, -66.03825]],
  [2643179, [51.26667, 0.51667]],
  [89824, [30.0362, 47.91951]],
  [1735076, [5.3991, 100.36382]],
  [1795842, [22.77019, 112.95776]],
  [668872, [45.1, 24.36667]],
  [3130583, [40.54746, -3.64197]],
  [3471758, [-22.35694, -47.38417]],
  [3095049, [54.19438, 16.17222]],
  [3453406, [-23.52806, -46.34472]],
  [1337248, [25.01881, 90.01751]],
  [516436, [55.09681, 36.61006]],
  [3384987, [-8.11806, -35.29139]],
  [150006, [-3.66393, 33.42118]],
  [5574991, [40.01499, -105.27055]],
  [2886946, [50.35357, 7.57883]],
  [2832495, [50.87481, 8.02431]],
  [5377995, [33.90224, -118.08173]],
  [3164419, [45.54672, 11.5475]],
  [3111933, [41.15612, 1.10687]],
  [1861864, [35.51965, 137.82074]],
  [215976, [-4.33111, 20.58638]],
  [1560037, [18.66667, 105.66667]],
  [789225, [42.88333, 20.86667]],
  [2636486, [52.56667, -1.81667]],
  [2558470, [33.82404, -6.06627]],
  [563514, [46.30778, 44.25583]],
  [1290596, [18.94291, 96.43408]],
  [2656046, [52.13459, -0.46632]],
  [3644417, [8.54665, -71.24087]],
  [361179, [29.9, 31.25]],
  [3646190, [10.60545, -67.03238]],
  [1803886, [24.92271, 102.48496]],
  [1607017, [12.68095, 101.25798]],
  [1864031, [36.2, 139.28333]],
  [569273, [55.66473, 37.56135]],
  [2740637, [40.20564, -8.41955]],
  [4531405, [36.0526, -95.79082]],
  [5341430, [37.70577, -122.46192]],
  [2694762, [58.41086, 15.62157]],
  [1646678, [-6.26111, 107.15278]],
  [2035970, [47.33072, 123.17816]],
  [1897118, [37.6525, 127.3075]],
  [2324857, [9.98433, 10.95229]],
  [2790471, [50.4669, 4.86746]],
  [1271662, [15.4313, 76.52933]],
  [688105, [45.20091, 33.36655]],
  [517836, [51.20301, 58.32665]],
  [2160517, [-41.43876, 147.13467]],
  [3450269, [-29.7175, -52.42583]],
  [3093692, [51.21006, 16.1619]],
  [295620, [31.66926, 34.57149]],
  [566532, [42.06779, 48.28987]],
  [1784820, [32.93917, 119.83417]],
  [1266607, [28.25382, 77.85535]],
  [1700360, [6.95508, 126.21655]],
  [3461859, [-22.81639, -45.1925]],
  [1651226, [-6.64528, 108.41028]],
  [2508287, [35.79639, 7.39278]],
  [2348595, [11.67478, 10.19069]],
  [1671566, [23.91566, 120.66387]],
  [1817990, [36.43417, 119.1925]],
  [1733953, [5.02298, 118.32897]],
  [1259411, [10.76695, 75.92523]],
  [1683013, [14.6753, 120.9389]],
  [3632929, [10.29532, -67.7177]],
  [1185263, [24.0524, 90.9764]],
  [1793036, [35.08357, 117.19071]],
  [2505653, [36.66655, 3.09606]],
  [2990999, [48.68439, 6.18496]],
  [4221333, [33.92427, -84.37854]],
  [5331835, [34.18084, -118.30897]],
  [963516, [-31.89756, 26.87533]],
  [1580830, [20.81717, 105.33759]],
  [697889, [48.53214, 35.86878]],
  [5254962, [44.51916, -88.01983]],
  [105299, [16.88917, 42.55111]],
  [3515715, [19.51194, -98.88293]],
  [12157013, [-12.08911, -76.99876]],
  [3458498, [-19.39111, -40.07222]],
  [3407357, [-7.19111, -48.20722]],
  [1276895, [30.37451, 75.5487]],
  [5404794, [34.1389, -118.35341]],
  [3116156, [40.40742, -3.64935]],
  [1807544, [39.74025, 116.32693]],
  [4004867, [26.93022, -105.6664]],
  [141584, [35.9975, 45.8853]],
  [2641022, [53.54051, -2.1183]],
  [1665196, [23.70944, 120.54333]],
  [2895044, [50.92878, 11.5899]],
  [4741752, [33.91371, -98.49339]],
  [2921232, [50.88029, 12.08187]],
  [1277976, [19.15516, 73.26553]],
  [2769359, [48.21667, 16.3]],
  [99446, [29.97421, 48.47309]],
  [3194828, [43.34333, 17.80806]],
  [4161438, [28.03947, -81.9498]],
  [1516589, [47.78333, 67.76667]],
  [1848445, [32.50439, 130.59952]],
  [3427833, [-37.32167, -59.13316]],
  [7281020, [-33.4443, -70.72552]],
  [1798490, [39.39528, 121.96694]],
  [2706767, [56.04673, 12.69437]],
  [1173378, [30.46907, 70.96699]],
  [339666, [8.75225, 38.97846]],
  [2366152, [9.55111, 1.18611]],
  [688148, [48.23331, 38.21137]],
  [4706057, [33.04623, -96.99417]],
  [2293549, [6.72972, -3.49639]],
  [1861212, [34.16297, 132.22]],
  [1430991, [34.07677, 74.7057]],
  [554787, [55.78677, 37.80165]],
  [1503277, [53.99, 86.6621]],
  [986846, [-27.65036, 27.23488]],
  [2036418, [44.31667, 126.8]],
  [2649997, [51.65147, -0.08497]],
  [380173, [17.59898, 33.97205]],
  [2633709, [51.31903, -0.55893]],
  [2258261, [-4.19834, 12.66664]],
  [1258044, [29.86632, 77.89118]],
  [1277939, [27.09918, 84.09003]],
  [2873074, [52.54525, 13.56983]],
  [2328811, [6.15038, 6.83042]],
  [1269934, [12.73647, 77.83264]],
  [4738214, [32.35126, -95.30106]],
  [1282027, [4.17521, 73.50916]],
  [5345529, [32.79477, -116.96253]],
  [1141857, [33.59744, 69.22592]],
  [607610, [43.34116, 52.86192]],
  [358821, [31.21452, 31.35798]],
  [1273892, [28.30415, 74.96718]],
  [1262209, [23.45834, 75.41759]],
  [4148757, [27.9378, -82.28592]],
  [1631393, [-3.43447, 104.23056]],
  [1861449, [35.39932, 139.31019]],
  [2264268, [38.75657, -9.25451]],
  [1855189, [34.86667, 137.05]],
  [1562693, [10.25369, 105.9722]],
  [8504958, [60.01375, 30.39471]],
  [298806, [38.49533, 27.6997]],
  [3595803, [14.3009, -90.78581]],
  [476077, [56.34, 30.54517]],
  [1267579, [29.21399, 78.95693]],
  [1276609, [25.41853, 86.13389]],
  [2338287, [7.49748, 5.23041]],
  [2904789, [52.15077, 9.95112]],
  [1214073, [2.1, 99.83333]],
  [575505, [55.81353, 37.71617]],
  [108648, [31.33176, 37.34282]],
  [3488981, [17.95032, -76.88215]],
  [3545064, [20.2113, -75.99362]],
  [503977, [56.01722, 37.86667]],
  [1173687, [32.29667, 72.3525]],
  [2332504, [8.85299, 5.41641]],
  [1215412, [3.7755, 98.6832]],
  [1691444, [11.58528, 122.75111]],
  [670889, [46.91667, 26.33333]],
  [2929567, [49.59099, 11.00783]],
  [3817746, [19.61387, -98.9364]],
  [4853423, [41.52364, -90.57764]],
  [2638785, [53.45, -2.73333]],
  [5097529, [40.51872, -74.4121]],
  [3347762, [-14.91717, 13.4925]],
  [2425791, [9.14555, 18.38331]],
  [1272805, [26.69286, 77.87968]],
  [1162456, [32.44324, 74.12]],
  [3892870, [-34.98279, -71.23943]],
  [3509382, [19.22207, -70.52956]],
  [1185121, [24.17687, 89.5988]],
  [3515062, [20.08355, -98.36288]],
  [776251, [52.2546, 20.90844]],
  [5731371, [45.52289, -122.98983]],
  [6544106, [41.38022, 2.17319]],
  [678817, [44.62693, 22.65288]],
  [1263494, [15.38914, 73.81491]],
  [1805270, [28.31925, 109.73346]],
  [2027456, [43.3595, 132.18887]],
  [3469989, [-21.28861, -50.34]],
  [479411, [63.56705, 53.68348]],
  [1861602, [35.25, 136.78333]],
  [1271883, [30.92574, 74.61311]],
  [1707404, [18.1978, 120.5957]],
  [2212771, [32.79335, 12.48845]],
  [358108, [31.13259, 30.64784]],
  [2219936, [32.85286, 12.0612]],
  [571741, [55.63755, 37.76438]],
  [495136, [55.8, 37.45]],
  [1019760, [-26.45794, 29.46553]],
  [1692520, [14.27324, 121.07643]],
  [1626916, [0.90925, 108.98463]],
  [3518723, [18.0883, -96.12535]],
  [1270994, [21.96074, 70.80255]],
  [2510253, [37.97872, -0.68222]],
  [6074377, [43.51681, -79.88294]],
  [1648186, [0.1324, 117.4854]],
  [5937615, [49.14399, -122.9068]],
  [121380, [39.6482, 47.9174]],
  [5475352, [32.31232, -106.77834]],
  [1180281, [31.83129, 70.9017]],
  [622428, [52.7876, 27.5415]],
  [3044774, [47.18995, 18.41034]],
  [3471374, [-22.66167, -50.41222]],
  [3675595, [10.85953, -74.77386]],
  [4926563, [41.68338, -86.25001]],
  [1504682, [56.20167, 95.7175]],
  [3037044, [48.94788, 2.24744]],
  [2765028, [48.18333, 16.43333]],
  [741385, [40.03815, 28.40866]],
  [1256826, [22.08503, 79.55037]],
  [5935277, [44.67134, -63.57719]],
  [625625, [54.3167, 26.854]],
  [1269605, [30.16719, 77.30367]],
  [3069011, [49.59552, 17.25175]],
  [1263012, [16.8722, 79.56247]],
  [3470451, [-20.55722, -48.56778]],
  [2298330, [5.6658, -0.16307]],
  [1181636, [32.93286, 72.85394]],
  [2188164, [-41.21667, 174.91667]],
  [3465944, [-19.53944, -40.63056]],
  [2648657, [51.38914, 0.54863]],
  [3446621, [-17.8575, -41.50528]],
  [1850152, [34.05, 131.81667]],
  [1892823, [37.54389, 129.10694]],
  [616530, [40.80456, 44.4939]],
  [588335, [58.38062, 26.72509]],
  [964315, [-24.19436, 29.00974]],
  [2842150, [52.15705, 10.4154]],
  [1680197, [15.97611, 120.57111]],
  [1498920, [53.69417, 88.06028]],
  [3473964, [-30.11389, -51.325]],
  [1710141, [6.05222, 121.00222]],
  [517161, [55.7, 37.58333]],
  [508751, [55.73333, 37.76667]],
  [1797551, [21.97296, 108.62612]],
  [3461888, [-20.67182, -40.50196]],
  [3465476, [-19.51861, -42.62889]],
  [783263, [41.1125, 20.08222]],
  [5406602, [33.20004, -117.24254]],
  [624784, [55.5318, 28.5987]],
  [5577592, [40.42331, -104.70913]],
  [4152820, [26.06287, -80.2331]],
  [2267827, [32.66568, -16.92547]],
  [2484620, [35.87541, 7.11353]],
  [491023, [59.6316, 56.7685]],
  [3610613, [15.4, -87.8]],
  [3460899, [-22.85222, -43.77528]],
  [1261931, [21.36671, 74.24051]],
  [362485, [30.72508, 31.67148]],
  [561347, [58.1393, 52.658]],
  [2292852, [5.49462, -4.05183]],
  [2111684, [35.78333, 140.31667]],
  [261414, [37.95, 23.7]],
  [3708306, [9.05, -79.45]],
  [2176187, [-36.75818, 144.28024]],
  [1643761, [-7.60111, 110.81861]],
  [1861091, [34.41667, 135.31667]],
  [1269653, [22.61477, 77.76222]],
  [5336477, [37.7966, -122.40858]],
  [1214369, [1.37952, 99.27146]],
  [350789, [30.17922, 31.2056]],
  [1253367, [15.39585, 73.81568]],
  [5530022, [31.46377, -100.43704]],
  [1853140, [38.91667, 139.855]],
  [1858836, [35.33165, 136.87042]],
  [1864572, [33.49631, 130.5156]],
  [1803334, [39.71183, 118.44954]],
  [2244616, [14.63333, -16.23333]],
  [2013952, [58.00056, 102.66194]],
  [5808189, [47.48288, -122.21707]],
  [737022, [41.45139, 31.79305]],
  [126409, [33.53335, 47.60999]],
  [1242110, [7.40902, 81.83472]],
  [1259827, [31.22452, 75.77387]],
  [2821164, [49.75565, 6.63935]],
  [3494242, [18.58182, -68.40431]],
  [2510542, [27.99243, -15.41915]],
  [6690870, [51.5529, 0.129]],
  [1626100, [-6.85, 107.91667]],
  [293253, [32.05043, 34.75224]],
  [483826, [55.70033, 37.74271]],
  [1785036, [38.93417, 100.45167]],
  [3189595, [46.1, 19.66667]],
  [2210394, [32.88167, 13.35056]],
  [3451205, [-22.52694, -41.945]],
  [1871871, [39.24639, 125.87194]],
  [11670045, [19.5773, 41.96357]],
  [7280711, [19.49156, -99.12475]],
  [7279599, [19.15096, 72.99625]],
  [3569370, [23.15794, -82.27837]],
  [1802171, [30.00222, 101.9569]],
  [2486690, [35.70583, 4.54194]],
  [4782167, [37.27097, -79.94143]],
  [5258393, [42.58474, -87.82119]],
  [3440714, [-31.38333, -57.96667]],
  [1849563, [38.72167, 139.82167]],
  [1611269, [12.60961, 102.10447]],
  [1812256, [39.81609, 109.97763]],
  [706950, [48.67882, 26.58516]],
  [4989133, [42.58698, -82.91992]],
  [1278715, [21.59983, 71.21169]],
  [1256529, [27.108, 78.58661]],
  [463835, [55.84376, 48.51784]],
  [107797, [26.28864, 50.11396]],
  [167357, [36.52815, 37.95495]],
  [3460960, [-19.61917, -43.22694]],
  [3098218, [53.48411, 18.75366]],
  [2487772, [34.15429, 3.50309]],
  [517963, [47.76037, 39.93335]],
  [1861795, [34.82208, 135.4298]],
  [5188843, [42.12922, -80.08506]],
  [361661, [26.5622, 31.74566]],
  [2035801, [42.52722, 125.67528]],
  [3444866, [-23.54667, -47.43778]],
  [360716, [31.18287, 32.03108]],
  [2454268, [12.39173, -5.46421]],
  [265488, [38.08333, 23.73333]],
  [6166142, [48.38202, -89.25018]],
  [935221, [-21.00961, 55.27134]],
  [1256913, [23.2, 77.08333]],
  [141736, [29.106, 58.357]],
  [304854, [36.78667, 31.44306]],
  [3447651, [-21.13778, -47.99028]],
  [6324733, [47.56494, -52.70931]],
  [888710, [-18.92809, 29.81486]],
  [2633521, [50.81795, -0.37538]],
  [1258474, [14.62239, 75.62951]],
  [1336136, [22.68759, 90.64403]],
  [3691582, [-4.57722, -81.27194]],
  [4780011, [36.82098, -76.36883]],
  [3181913, [46.49067, 11.33982]],
  [1269562, [18.79473, 78.91661]],
  [4018400, [19.08864, -102.35704]],
  [3449948, [-23.44417, -46.91778]],
  [1803245, [28.46042, 119.91029]],
  [3471196, [-31.33139, -54.10694]],
  [746881, [40.91698, 38.38741]],
  [498687, [56.47633, 53.79782]],
  [649360, [60.98267, 25.66151]],
  [2803560, [50.72724, 12.48839]],
  [2334008, [10.40319, 5.4708]],
  [3454847, [-20.71889, -46.60972]],
  [1647866, [-0.30907, 100.37055]],
  [2894003, [49.443, 7.77161]],
  [4670249, [29.71106, -95.59633]],
  [1252416, [27.46609, 89.64191]],
  [1724489, [14.79833, 120.92611]],
  [1276325, [21.05447, 86.5156]],
  [471430, [57.04865, 53.98717]],
  [4173838, [28.47688, -82.52546]],
  [3085450, [54.46405, 17.02872]],
  [1797181, [38.69889, 116.09361]],
  [3565432, [23.03661, -81.20596]],
  [3374036, [13.10732, -59.62021]],
  [5106834, [42.65258, -73.75623]],
  [5339066, [33.89585, -118.22007]],
  [3109453, [43.29639, -2.98813]],
  [1492663, [59.60334, 60.5787]],
  [274874, [33.40729, 35.48169]],
  [1788638, [31.04704, 113.14098]],
  [7284831, [47.56182, 19.08909]],
  [144410, [29.2464, 52.7793]],
  [3183072, [41.23117, 16.29797]],
  [4094455, [33.20984, -87.56917]],
  [4705692, [29.50745, -95.09493]],
  [4992982, [43.01253, -83.68746]],
  [108927, [26.56542, 50.0089]],
  [1812057, [22.37303, 110.94746]],
  [1253993, [11.4134, 76.69521]],
  [2548526, [28.98696, -10.05738]],
  [4002224, [21.35666, -101.93768]],
  [2038080, [45.33333, 131.06667]],
  [1292037, [19.32076, 95.18272]],
  [1803616, [27.66667, 113.5]],
  [4670300, [33.10317, -96.67055]],
  [579460, [51.5502, 43.1667]],
  [1645154, [-6.9, 109.08333]],
  [134721, [28.9383, 53.6482]],
  [1262634, [25.28307, 83.11968]],
  [626081, [53.88333, 25.29972]],
  [170905, [32.61889, 36.10213]],
  [2177091, [-37.56622, 143.84957]],
  [2895669, [51.37547, 7.70281]],
  [463343, [52.331, 35.3711]],
  [3445859, [-21.12, -42.94278]],
  [3455729, [-22.97889, -49.87056]],
  [2043835, [41.39542, 128.18752]],
  [3516035, [18.85254, -99.22537]],
  [3071961, [50.76711, 15.05619]],
  [3165072, [46.0693, 13.23715]],
  [1839726, [36.78361, 127.00417]],
  [2473247, [36.86012, 10.19337]],
  [2771706, [48.16667, 16.33333]],
  [1808212, [29.99228, 106.26461]],
  [1270077, [20.54875, 78.83978]],
  [2639272, [53.61766, -2.1552]],
  [3530367, [16.2471, -92.13515]],
  [3446038, [-16.64944, -49.48889]],
  [3471798, [-23.41944, -51.42444]],
  [3723593, [18.65, -74.11667]],
  [229268, [-0.60467, 30.64851]],
  [744562, [41.20488, 32.62768]],
  [1788508, [27.80429, 114.93335]],
  [1624725, [3.31332, 117.59152]],
  [6176823, [43.4668, -80.51639]],
  [2523083, [37.07542, 15.28664]],
  [290332, [26.25722, 50.61194]],
  [980921, [-25.49768, 28.10065]],
  [1298987, [19.7381, 96.20742]],
  [1253166, [11.93975, 79.49244]],
  [1861464, [32.84111, 130.04306]],
  [1278931, [21.03983, 75.05887]],
  [2333604, [8.87952, 7.22756]],
  [1164216, [34.12018, 72.46982]],
  [1517945, [43.29733, 68.25175]],
  [2756987, [52.255, 6.16389]],
  [3868192, [-33.04222, -71.37333]],
  [2111855, [35.42583, 140.29608]],
  [1861450, [34.48333, 136.7]],
  [1614295, [12.93333, 100.88333]],
  [1277936, [16.18673, 75.69614]],
  [1171965, [29.5339, 71.63244]],
  [1254780, [11.74811, 75.4929]],
  [462755, [55.59528, 38.12028]],
  [1261848, [16.23488, 80.04927]],
  [1860635, [35.0, 135.58333]],
  [665010, [45.05, 23.28333]],
  [3598529, [14.70289, -90.49983]],
  [5373763, [33.60002, -117.672]],
  [3165771, [42.56335, 12.64329]],
  [2791018, [50.8499, 4.31248]],
  [1811542, [39.68699, 115.99658]],
  [1312609, [20.14956, 94.93246]],
  [1262216, [27.20201, 73.73394]],
  [5405228, [38.35658, -121.98774]],
  [66093, [30.66824, 51.58796]],
  [7116866, [-33.67571, -65.45783]],
  [1912209, [37.58972, 127.22028]],
  [5405878, [34.27834, -119.29317]],
  [1341204, [33.99829, 71.99834]],
  [217695, [1.55941, 30.25224]],
  [1261631, [24.8867, 85.54364]],
  [5425043, [39.55388, -104.96943]],
  [3117814, [43.00992, -7.55602]],
  [1853209, [35.95694, 139.38889]],
  [4540737, [34.60869, -98.39033]],
  [7701354, [23.7298, 90.3854]],
  [2834282, [53.62937, 11.41316]],
  [750516, [40.73583, 31.60611]],
  [877747, [-10.26667, 40.18333]],
  [5713376, [45.48706, -122.80371]],
  [3471830, [-18.64722, -48.18722]],
  [3097333, [50.20528, 19.27498]],
  [1560349, [21.72288, 104.9113]],
  [3465342, [-19.00917, -57.65333]],
  [3619194, [13.09185, -86.35384]],
  [5397603, [33.95474, -118.21202]],
  [1269557, [25.21368, 84.9871]],
  [1274868, [20.45781, 75.01596]],
  [727079, [42.68583, 26.32917]],
  [273140, [33.98083, 35.61778]],
  [318675, [37.02472, 35.8175]],
  [2637546, [52.41426, -1.78094]],
  [2299522, [6.09408, -0.25913]],
  [2129101, [42.31722, 140.98806]],
  [3121456, [41.98311, 2.82493]],
  [128447, [35.23831, 58.46558]],
  [3437842, [-25.16611, -57.48562]],
  [1136469, [33.33951, 69.92041]],
  [1275019, [25.57548, 83.98043]],
  [2232444, [5.44397, 10.05332]],
  [5512862, [39.53491, -119.75269]],
  [3464073, [-27.63461, -52.2754]],
  [3077916, [48.97447, 14.47434]],
  [1713014, [14.38694, 120.88167]],
  [2318044, [9.20839, 12.48146]],
  [502965, [55.56694, 38.23028]],
  [2300182, [7.5758, -2.78516]],
  [1625929, [-2.0561, 101.3913]],
  [1257794, [25.24425, 87.63481]],
  [1802177, [23.17233, 112.28298]],
  [3097391, [49.95542, 18.57479]],
  [1705536, [14.17025, 121.24181]],
  [2982681, [50.69421, 3.17456]],
  [1260546, [17.67924, 75.33098]],
  [1805408, [32.08982, 121.07355]],
  [3520271, [25.98729, -98.09414]],
  [361291, [27.25738, 33.81291]],
  [1270090, [26.73411, 77.03519]],
  [1786760, [31.36059, 119.82016]],
  [1811929, [23.24212, 111.5284]],
  [217745, [2.18771, 22.46827]],
  [585514, [40.76395, 47.05953]],
  [1795632, [29.95763, 122.29802]],
  [7647007, [-41.14557, -71.30822]],
  [3746184, [23.13028, -82.35306]],
  [1181439, [34.14822, 71.7406]],
  [4931429, [42.08343, -71.01838]],
  [2123628, [59.5638, 150.80347]],
  [2038274, [45.75279, 130.57211]],
  [1789998, [30.16746, 120.25883]],
  [1811720, [30.3, 109.48333]],
  [3074967, [50.20923, 15.83277]],
  [2644737, [52.2852, -1.52]],
  [3195506, [46.55472, 15.64667]],
  [4990510, [42.32226, -83.17631]],
  [5794245, [47.32232, -122.31262]],
  [3460132, [-22.64306, -43.65333]],
  [4394870, [38.91084, -94.38217]],
  [3109642, [42.88052, -8.54569]],
  [2757345, [52.00667, 4.35556]],
  [149129, [-3.49194, 31.96389]],
  [89570, [37.14871, 42.68591]],
  [250774, [29.52667, 35.00778]],
  [2621942, [55.67938, 12.53463]],
  [986083, [-27.83333, 26.75]],
  [2346995, [10.61285, 12.19458]],
  [2326302, [7.6, 4.18333]],
  [8505053, [55.8801, 37.55758]],
  [513471, [60.03947, 30.31128]],
  [2639192, [51.57515, 0.18582]],
  [518879, [55.75378, 37.81885]],
  [1266928, [21.82292, 75.61394]],
  [4945121, [41.63526, -70.92701]],
  [6822146, [33.53567, 130.47861]],
  [5811729, [47.67323, -117.23937]],
  [1860098, [36.55, 139.73333]],
  [164947, [35.01127, 37.05324]],
  [1260290, [18.85057, 76.53163]],
  [2759899, [52.63167, 4.74861]],
  [1710544, [13.4324, 123.4115]],
  [726418, [43.56667, 27.83333]],
  [1266489, [26.10224, 87.95534]],
  [2034141, [44.91428, 127.15001]],
  [2656173, [51.3751, -2.36172]],
  [788709, [42.99806, 21.94611]],
  [3445162, [-19.69194, -43.92333]],
  [723819, [48.99839, 21.23393]],
  [6163012, [45.70004, -73.64732]],
  [5117549, [40.85927, -73.89847]],
  [3467736, [-25.45955, -49.53014]],
  [3437665, [-25.3949, -57.53574]],
  [136014, [30.3586, 50.7981]],
  [4999837, [42.36837, -83.35271]],
  [1264292, [27.22857, 79.02882]],
  [3682516, [4.81367, -74.35453]],
  [1267084, [20.70738, 76.56827]],
  [128321, [29.61919, 51.6535]],
  [4219934, [34.02316, -84.36159]],
  [2110498, [37.91, 140.11667]],
  [1786378, [29.35376, 105.89392]],
  [5779334, [40.2969, -111.69465]],
  [2647461, [51.77655, 0.11158]],
  [144616, [38.4774, 47.0699]],
  [307515, [39.14583, 34.16389]],
  [1789289, [24.98773, 118.3858]],
  [3361025, [-33.93462, 18.86676]],
  [1262260, [10.76377, 79.84313]],
  [1496511, [66.08333, 76.63333]],
  [1674199, [24.757, 121.753]],
  [791646, [42.38028, 20.43083]],
  [5322053, [32.72532, -114.6244]],
  [1786455, [35.78472, 118.62806]],
  [2808473, [52.48333, 13.31667]],
  [3063548, [50.6607, 14.03227]],
  [1567723, [13.4556, 109.22348]],
  [1274213, [16.08987, 80.16705]],
  [2270503, [38.76698, -9.29793]],
  [1270800, [12.94601, 78.87377]],
  [2556272, [33.26553, -7.58754]],
  [4274277, [38.97167, -95.23525]],
  [1264206, [27.04361, 74.72445]],
  [1176368, [32.64118, 74.20561]],
  [2112227, [39.28333, 141.11667]],
  [1538636, [57.24389, 60.08389]],
  [4736476, [30.15799, -95.48938]],
  [2702979, [57.78145, 14.15618]],
  [5143620, [42.68313, -73.77845]],
  [3646487, [10.17283, -70.081]],
  [1283318, [26.72882, 85.92628]],
  [2688368, [58.59419, 16.1826]],
  [3625542, [9.31778, -70.60361]],
  [1786112, [23.73333, 114.68333]],
  [5816605, [46.60207, -120.5059]],
  [1529626, [45.69298, 85.13942]],
  [1277214, [23.54109, 74.4425]],
  [3980190, [25.81201, -100.59874]],
  [3176746, [44.22177, 12.04144]],
  [4948247, [42.25288, -71.00227]],
  [333373, [11.08155, 39.74339]],
  [3446692, [-23.35556, -47.85694]],
  [1604870, [6.53995, 101.28128]],
  [1714674, [8.56697, 123.33471]],
  [1277044, [29.10199, 77.26334]],
  [1258744, [12.72181, 77.28149]],
  [140918, [36.69235, 53.55262]],
  [1562820, [21.32274, 105.40198]],
  [3833883, [-43.24895, -65.30505]],
  [2934486, [50.80434, 6.49299]],
  [1626183, [-6.76028, 108.48306]],
  [5117378, [40.65205, -73.95903]],
  [2037930, [45.5, 124.3]],
  [3172189, [45.44694, 8.62118]],
  [5356576, [34.42639, -117.30088]],
  [2332079, [10.38584, 8.57286]],
  [5334519, [33.83141, -118.28202]],
  [3182340, [41.31429, 16.28165]],
  [4148411, [26.35869, -80.0831]],
  [3171058, [45.05242, 9.69342]],
  [7284836, [47.44417, 19.17595]],
  [5393212, [34.01945, -118.49119]],
  [6545347, [52.25443, 21.03472]],
  [763442, [52.22096, 20.98526]],
  [1268293, [22.98333, 88.48333]],
  [2038154, [40.21612, 116.23471]],
  [2520611, [39.47649, -6.37224]],
  [1246294, [6.0461, 80.2103]],
  [952192, [-26.84493, 26.76829]],
  [2754652, [50.88365, 5.98154]],
  [1696899, [8.1481, 123.8405]],
  [233114, [0.43902, 33.20317]],
  [2139521, [-22.27407, 166.44884]],
  [1255254, [14.90832, 78.01031]],
  [1621395, [-7.35889, 109.90306]],
  [751335, [41.56778, 35.90694]],
  [2130452, [42.81944, 141.65222]],
  [3893532, [-37.03386, -73.14019]],
  [2318921, [7.87139, 9.77786]],
  [542374, [55.82036, 37.33017]],
  [5392368, [33.14337, -117.16614]],
  [2792482, [50.87959, 4.70093]],
  [504003, [59.71417, 30.39642]],
  [3546791, [20.65776, -74.95075]],
  [1803841, [24.78186, 112.3712]],
  [3177090, [44.83804, 11.62057]],
  [5330413, [34.0339, -118.20535]],
  [740483, [41.02083, 40.52194]],
  [2504622, [36.52389, 2.86131]],
  [2338669, [7.43383, 3.28788]],
  [2641157, [52.52323, -1.46523]],
  [346030, [30.7142, 31.24425]],
  [1267911, [31.38011, 75.38105]],
  [1860765, [34.45, 135.35]],
  [1633056, [-6.70694, 108.42417]],
  [8299617, [53.36797, -1.84536]],
  [3392887, [-7.02444, -37.28]],
  [4168782, [26.13421, -80.23184]],
  [1734798, [2.9516, 101.843]],
  [664518, [45.1787, 28.80501]],
  [1857766, [35.45, 135.33333]],
  [4942807, [42.46676, -70.94949]],
  [3832694, [-32.40751, -63.24016]],
  [1150954, [13.53671, 99.81712]],
  [1632978, [-7.1568, 113.4746]],
  [3670745, [11.54444, -72.90722]],
  [2745641, [51.37, 6.16806]],
  [99369, [30.58481, 47.76114]],
  [2928751, [48.73961, 9.30473]],
  [7280528, [31.9314, -4.42663]],
  [2651513, [54.52429, -1.55039]],
  [1632823, [-6.3084, 106.1067]],
  [1283467, [28.70137, 80.58975]],
  [1812621, [36.77667, 121.15972]],
  [1272513, [24.25612, 72.17928]],
  [7260806, [38.6025, -121.37854]],
  [1632334, [-0.61898, 100.11997]],
  [1639215, [-6.8048, 110.8405]],
  [1277557, [19.84696, 79.34578]],
  [1907307, [35.35043, 139.87029]],
  [1266366, [25.67467, 94.11099]],
  [5579276, [40.16721, -105.10193]],
  [3685084, [5.8245, -73.03408]],
  [309647, [39.71467, 43.04015]],
  [1813828, [32.54278, 111.50861]],
  [3981984, [20.81692, -102.76347]],
  [1276389, [21.90056, 77.90229]],
  [315530, [39.02587, 43.35964]],
  [1267885, [10.06615, 78.76784]],
  [2657970, [47.50564, 8.72413]],
  [2514891, [37.67119, -1.7017]],
  [571170, [54.5378, 52.7985]],
  [157403, [-6.83333, 36.98333]],
  [1647298, [-6.70611, 106.82139]],
  [5392952, [34.42083, -119.69819]],
  [1720034, [14.91639, 120.76583]],
  [1863627, [26.26265, 127.76147]],
  [705135, [51.24032, 33.20263]],
  [1784310, [24.51667, 103.76667]],
  [1283562, [27.70055, 83.44836]],
  [3446445, [-19.58106, -42.64953]],
  [2637343, [53.64581, -3.01008]],
  [1806960, [21.63333, 110.58333]],
  [124778, [35.51829, 46.18298]],
  [736083, [40.5825, 22.95028]],
  [2850174, [51.29724, 6.84929]],
  [5570160, [40.58654, -122.39168]],
  [2972284, [50.72391, 3.16117]],
  [1802476, [24.67193, 102.1613]],
  [1571067, [11.56432, 108.98858]],
  [1786855, [37.1566, 122.43762]],
  [2751792, [53.20139, 5.80859]],
  [2036075, [41.24, 119.40111]],
  [2873263, [51.65671, 7.09038]],
  [4207400, [32.84069, -83.6324]],
  [2790869, [50.45413, 3.95229]],
  [705104, [48.5277, 37.7069]],
  [1277643, [20.70419, 83.49029]],
  [3665542, [5.02208, -74.00481]],
  [3456500, [-21.13056, -42.36639]],
  [1811764, [26.26667, 107.51667]],
  [3467978, [-23.27583, -51.27833]],
  [1798821, [36.78444, 119.94639]],
  [912764, [-10.21289, 31.18084]],
  [3405940, [-7.125, -34.93222]],
  [143921, [33.404, 49.69179]],
  [303827, [37.96583, 34.67935]],
  [1565022, [10.9804, 106.6519]],
  [530088, [55.88333, 37.71667]],
  [536398, [55.76667, 37.7]],
  [1853992, [34.41667, 133.2]],
  [741855, [41.40385, 27.35918]],
  [1307741, [25.38327, 97.39637]],
  [1327659, [20.89921, 94.81784]],
  [1785777, [38.29181, 109.73753]],
  [3470073, [-29.17139, -51.51917]],
  [3467081, [-23.62028, -45.41306]],
  [1308937, [22.91766, 96.50982]],
  [1897122, [37.63667, 127.21417]],
  [78428, [15.6594, 43.94385]],
  [5600685, [43.61211, -116.39151]],
  [3159016, [59.74389, 10.20449]],
  [5392263, [37.72493, -122.15608]],
  [1337249, [24.43944, 90.78291]],
  [2992090, [48.86415, 2.44322]],
  [2343299, [7.43636, 5.45925]],
  [1808879, [36.77083, 119.21083]],
  [1273403, [25.67312, 78.45908]],
  [2774326, [46.62472, 14.30528]],
  [1816269, [23.89972, 106.61333]],
  [4469160, [35.61266, -77.36635]],
  [1792592, [27.71722, 109.18528]],
  [347634, [26.76866, 31.50198]],
  [737961, [40.76704, 29.78275]],
  [2653228, [53.1905, -2.89189]],
  [537737, [53.11675, 46.60037]],
  [1789462, [31.68635, 113.46585]],
  [3460791, [-24.18306, -46.78889]],
  [1253472, [10.32691, 76.95116]],
  [1253437, [12.68162, 78.62014]],
  [3124408, [40.42378, -3.56129]],
  [1811829, [30.67, 112.68952]],
  [548395, [58.55386, 50.03986]],
  [1510350, [54.7551, 83.0967]],
  [2636940, [51.90224, -0.20256]],
  [335035, [9.31387, 42.11815]],
  [941966, [-24.88333, 28.28333]],
  [3429594, [-29.15, -59.65]],
  [1276191, [21.16817, 79.64885]],
  [2704620, [59.23705, 17.98192]],
  [1794140, [35.64889, 117.27583]],
  [1737919, [4.18333, 114.31667]],
  [4535740, [35.65283, -97.4781]],
  [2962943, [52.66472, -8.62306]],
  [2634549, [51.55242, -0.29686]],
  [10570, [36.1893, 50.0643]],
  [1258099, [18.43687, 73.11964]],
  [1810668, [27.4375, 111.97306]],
  [5989117, [45.3001, -75.91606]],
  [3570675, [14.60365, -61.07418]],
  [3183089, [43.5942, 13.50337]],
  [2337148, [6.38186, 8.03736]],
  [933778, [-21.17, 27.50778]],
  [1854093, [32.92139, 129.95389]],
  [5601933, [43.54072, -116.56346]],
  [1262578, [30.47426, 74.5166]],
  [1229989, [9.81667, 80.23333]],
  [3035681, [43.94834, 4.80892]],
  [561887, [59.57639, 30.12833]],
  [2648182, [51.47566, 0.32521]],
  [1849519, [35.05215, 133.99885]],
  [3449936, [-30.89083, -55.53278]],
  [3625207, [10.08081, -69.1242]],
  [1814870, [29.31506, 120.07676]],
  [1303406, [17.95363, 96.72247]],
  [1797658, [24.81978, 118.57415]],
  [203112, [-2.59667, 29.73944]],
  [1170425, [32.57756, 71.52847]],
  [3183719, [40.4686, 19.48318]],
  [1264735, [25.1765, 86.0947]],
  [3472518, [-25.32472, -49.31]],
  [1276901, [25.74572, 71.39211]],
  [1262453, [28.78069, 77.49865]],
  [163345, [34.88902, 35.88659]],
  [2037375, [47.26, 132.03222]],
  [256429, [37.96667, 23.65]],
  [1254432, [27.48905, 95.35992]],
  [286647, [24.17222, 56.88861]],
  [776103, [52.32127, 20.97204]],
  [1807687, [23.91952, 112.17629]],
  [1861084, [35.36667, 132.76667]],
  [1841775, [34.97528, 127.58917]],
  [3720824, [18.44599, -73.08957]],
  [1263694, [11.12018, 76.11996]],
  [1734815, [2.53718, 101.80571]],
  [1844174, [36.6009, 126.665]],
  [1264359, [25.2905, 79.87533]],
  [149703, [-7.96667, 31.61667]],
  [1308415, [16.25624, 97.7246]],
  [1802068, [31.23357, 117.28057]],
  [1808744, [33.7722, 120.26176]],
  [650224, [62.89238, 27.67703]],
  [3625515, [9.21554, -66.00734]],
  [1151254, [7.89059, 98.3981]],
  [2647428, [53.99078, -1.5373]],
  [6414184, [22.68347, 91.79058]],
  [541406, [55.58766, 37.64282]],
  [1214658, [5.1801, 97.1507]],
  [1646893, [-6.78139, 106.7825]],
  [1804162, [26.4111, 111.59559]],
  [2010985, [-7.55194, 110.73778]],
  [1296736, [22.56925, 95.69818]],
  [302043, [38.48258, 28.14774]],
  [2510693, [39.96348, -4.83076]],
  [2647400, [54.68554, -1.21028]],
  [3682274, [4.33646, -74.36378]],
  [1701872, [16.07, 120.4025]],
  [4945283, [42.33704, -71.20922]],
  [4504476, [39.95373, -74.19792]],
  [3401545, [-7.23417, -39.40944]],
  [3427213, [-34.09814, -59.02858]],
  [4936159, [41.70149, -71.15505]],
  [1185209, [24.38044, 91.41299]],
  [282926, [31.89385, 35.01504]],
  [3068582, [50.04075, 15.77659]],
  [2034221, [43.32179, 129.76342]],
  [7284843, [47.51984, 19.02218]],
  [3839479, [-31.25033, -61.4867]],
  [4255466, [39.97837, -86.11804]],
  [1344069, [21.77985, 87.74892]],
  [2911007, [50.13423, 8.91418]],
  [3445578, [-22.97056, -46.99583]],
  [3910027, [-17.33866, -63.2505]],
  [3943423, [-11.94306, -76.70944]],
  [1864985, [34.72807, 135.30264]],
  [1181611, [30.91769, 66.45259]],
  [2447938, [17.0187, 8.0168]],
  [4453066, [35.60095, -82.55402]],
  [1861171, [34.7, 137.85]],
  [711390, [50.51809, 30.80671]],
  [1858794, [36.06868, 139.51684]],
  [3175081, [44.103, 9.82375]],
  [4915734, [42.36363, -87.84479]],
  [4153146, [28.90054, -81.26367]],
  [1175021, [28.24574, 69.17974]],
  [5355828, [33.9164, -118.35257]],
  [665024, [44.92543, 25.4567]],
  [109953, [20.01288, 41.46767]],
  [3388376, [-8.00222, -35.01833]],
  [3456147, [-19.98556, -43.84667]],
  [1639925, [-6.70944, 108.44]],
  [1654379, [15.12022, 105.79898]],
  [3686279, [11.00703, -74.24765]],
  [3468879, [-27.09795, -48.91281]],
  [2338876, [6.81491, 3.19518]],
  [3184081, [42.06828, 19.51258]],
  [1259440, [10.65825, 77.0085]],
  [1273265, [29.69505, 77.67964]],
  [4788158, [36.72836, -76.58496]],
  [4734825, [29.61968, -95.63495]],
  [3193935, [45.55111, 18.69389]],
  [1856293, [34.61667, 136.08333]],
  [306112, [37.45517, 35.81573]],
  [1261470, [8.39854, 77.08586]],
  [1255024, [26.54953, 82.65841]],
  [1177042, [29.69221, 72.54566]],
  [2407656, [8.64387, -10.9714]],
  [498525, [54.93583, 43.32352]],
  [1730225, [15.1505, 120.7697]],
  [5090046, [42.76537, -71.46757]],
  [1785566, [34.16278, 113.46389]],
  [119161, [35.1664, 47.80564]],
  [3530757, [19.06406, -98.30352]],
  [296562, [39.82, 34.80444]],
  [172374, [35.83758, 38.54828]],
  [2232283, [2.9, 11.15]],
  [6138517, [45.27076, -66.05616]],
  [6104111, [43.90012, -79.13289]],
  [161616, [39.51075, 54.36713]],
  [466885, [46.7055, 38.2739]],
  [749780, [40.15552, 26.41271]],
  [5907990, [43.1334, -80.26636]],
  [3628053, [9.91152, -67.35381]],
  [1636816, [-6.83611, 108.22778]],
  [570427, [52.7807, 52.2635]],
  [2518207, [36.59389, -6.23298]],
  [2406407, [8.88605, -12.04417]],
  [2817724, [51.33537, 7.04348]],
  [3470691, [-22.47, -43.82556]],
  [373141, [11.01111, 29.71833]],
  [1797535, [20.00583, 110.35417]],
  [3841490, [-33.88995, -60.57357]],
  [202217, [-2.07444, 29.75667]],
  [2875392, [48.89731, 9.19161]],
  [1790471, [27.73333, 112.53333]],
  [1812981, [30.54485, 119.9599]],
  [5359446, [33.7207, -116.21677]],
  [5487811, [35.23338, -106.66447]],
  [7839240, [35.33676, -106.59296]],
  [1277084, [25.1, 76.51667]],
  [5781061, [40.59161, -111.8841]],
  [302819, [39.22493, 42.85693]],
  [5409059, [33.97918, -118.03284]],
  [1783683, [30.12108, 104.64811]],
  [5111412, [40.64372, -73.90069]],
  [1184370, [30.29058, 73.06574]],
  [2358946, [12.25263, -2.36272]],
  [3097257, [50.89973, 15.72899]],
  [3906791, [-17.39228, -66.27838]],
  [262036, [37.86289, 23.75802]],
  [727233, [43.27064, 26.92286]],
  [2338630, [7.6685, 5.12627]],
  [5799841, [47.68149, -122.20874]],
  [2035746, [45.55, 131.88333]],
  [2693678, [55.70584, 13.19321]],
  [3472638, [-29.78306, -55.79194]],
  [2035601, [49.17414, 125.21774]],
  [1730713, [14.5266, 121.1536]],
  [3468215, [-23.36417, -46.74056]],
  [5372205, [33.72835, -117.14642]],
  [2643734, [54.99721, -7.30917]],
  [1272997, [20.70718, 81.54874]],
  [5376890, [33.61891, -117.92895]],
  [1254385, [11.38016, 77.89444]],
  [3629965, [10.95771, -63.86971]],
  [5403191, [37.73987, -121.42618]],
  [380129, [14.0012, 32.3116]],
  [5337561, [38.70712, -121.28106]],
  [2239862, [-9.54015, 16.34096]],
  [3634922, [10.22677, -67.33122]],
  [5713587, [44.05817, -121.31531]],
  [1274106, [15.82385, 80.35219]],
  [147288, [39.93214, 48.36892]],
  [2457163, [16.27167, -0.04472]],
  [558146, [51.28167, 37.5458]],
  [2392897, [6.63869, 1.71674]],
  [2343985, [6.63105, 8.05814]],
  [1268988, [23.74079, 86.41456]],
  [8858085, [20.51806, -103.38056]],
  [3696378, [-9.52779, -77.52778]],
  [1279094, [21.0963, 77.0588]],
  [1182829, [31.2024, 73.94857]],
  [1601579, [7.20411, 100.54508]],
  [981827, [-23.04385, 29.90319]],
  [4987990, [42.30865, -83.48216]],
  [1258803, [30.47856, 76.59284]],
  [1264588, [15.27501, 73.95786]],
  [4563008, [18.23412, -66.0485]],
  [4161785, [26.62535, -81.6248]],
  [5119347, [41.03287, -73.84291]],
  [1794825, [35.17111, 118.82889]],
  [1250161, [7.7102, 81.6924]],
  [3036572, [48.91667, 2.28333]],
  [2990970, [48.89198, 2.20675]],
  [3666640, [1.79112, -78.79275]],
  [569742, [56.76864, 54.11484]],
  [2110480, [35.65, 140.16667]],
  [3532592, [18.90815, -98.43613]],
  [201521, [-1.49984, 29.63497]],
  [1257198, [28.44062, 74.491]],
  [2795011, [50.83333, 4.36667]],
  [3406317, [-5.50556, -45.24333]],
  [1263247, [11.10354, 79.655]],
  [358600, [29.14816, 31.12733]],
  [1648451, [-7.1502, 111.8817]],
  [1784929, [39.76538, 118.41191]],
  [792578, [41.03143, 21.33474]],
  [285815, [29.2775, 47.95861]],
  [3124569, [41.35, 2.08333]],
  [1680007, [10.90154, 123.0705]],
  [1863293, [34.8, 137.78333]],
  [3650472, [0.81187, -77.71727]],
  [2339150, [7.1127, 5.1159]],
  [3398352, [-7.83417, -34.90639]],
  [3689798, [7.88299, -76.62587]],
  [5018739, [44.8408, -93.29828]],
  [1684497, [9.65556, 123.85219]],
  [1670310, [23.96639, 120.96952]],
  [4356050, [39.17316, -77.27165]],
  [3672328, [6.98789, -73.04953]],
  [2312888, [-5.25837, 14.85838]],
  [1863482, [36.14074, 139.46011]],
  [3118848, [40.49292, -3.87371]],
  [5096699, [40.85843, -74.16376]],
  [3058531, [48.30763, 18.08453]],
  [3842670, [-36.89272, -60.32254]],
  [1280281, [31.1304, 97.17982]],
  [1863183, [35.85194, 139.31806]],
  [1514402, [40.22083, 69.26972]],
  [712441, [49.89928, 28.60235]],
  [2005237, [-7.82694, 110.32917]],
  [1268205, [21.21615, 79.1973]],
  [1859908, [37.36667, 138.55]],
  [2513076, [38.08483, -0.94401]],
  [1800088, [23.72695, 111.79723]],
  [2653883, [52.69045, -2.03085]],
  [5024719, [46.78327, -92.10658]],
  [4887158, [40.11642, -88.24338]],
  [140097, [29.2699, 51.2188]],
  [7910079, [6.86, 37.76159]],
  [251833, [39.36103, 22.94248]],
  [2324767, [11.71391, 11.08108]],
  [535330, [55.89783, 37.5868]],
  [579870, [55.86931, 37.72966]],
  [787595, [43.13667, 20.51222]],
  [3056508, [49.22315, 18.73941]],
  [918702, [-13.63333, 32.65]],
  [2986495, [46.58261, 0.34348]],
  [2334652, [8.84651, 7.87354]],
  [2014022, [52.75194, 103.64528]],
  [3165456, [40.78931, 14.36806]],
  [700646, [48.44248, 22.718]],
  [328689, [7.2, 38.6]],
  [1260393, [20.31641, 86.6085]],
  [2331158, [6.49611, 3.38778]],
  [2926271, [54.78431, 9.43961]],
  [2111258, [35.9, 140.18333]],
  [2036933, [42.53974, 128.99722]],
  [4903360, [41.90003, -87.6345]],
  [3838859, [-51.62261, -69.21813]],
  [3982213, [18.91678, -103.87786]],
  [2647138, [51.75368, -0.44975]],
  [5336537, [34.01223, -117.68894]],
  [2037075, [44.57149, 129.38539]],
  [1261012, [18.18158, 76.03889]],
  [8504964, [60.00276, 30.33051]],
  [1800107, [36.74833, 118.05583]],
  [1184249, [33.76671, 72.35977]],
  [5779206, [41.223, -111.97383]],
  [693942, [51.86296, 33.4698]],
  [2969679, [48.80359, 2.13424]],
  [1809263, [34.36591, 107.35904]],
  [8057551, [24.42588, 54.605]],
  [101554, [26.5733, 50.04028]],
  [1278340, [9.5096, 78.09588]],
  [3523908, [19.36357, -98.97707]],
  [356000, [30.9128, 30.29019]],
  [3392734, [-9.40611, -38.21472]],
  [3460523, [-18.97428, -49.46212]],
  [88903, [32.49257, 20.82909]],
  [1812988, [37.80822, 120.75908]],
  [2217362, [32.17222, 13.02028]],
  [468250, [55.38283, 39.03233]],
  [3023141, [48.89672, 2.25666]],
  [5786899, [48.75955, -122.48822]],
  [1797333, [35.59667, 116.99111]],
  [457954, [56.50474, 21.01085]],
  [2129870, [43.20028, 141.75972]],
  [4401242, [38.81061, -90.69985]],
  [655194, [62.24147, 25.72088]],
  [3853354, [-34.58382, -60.94332]],
  [11428685, [33.31405, 38.70342]],
  [3674470, [7.97917, -75.4202]],
  [1655199, [17.41027, 104.83068]],
  [3724696, [19.44755, -72.68928]],
  [2540689, [32.8627, -6.57359]],
  [6085772, [49.16638, -123.94003]],
  [3463859, [-29.86139, -51.17917]],
  [1276829, [25.47509, 85.96813]],
  [4067994, [33.40539, -86.81138]],
  [3022530, [48.79266, 2.46569]],
  [6544881, [52.3075, 4.97222]],
  [256601, [38.03333, 23.7]],
  [2920789, [52.55035, 13.39139]],
  [1337245, [23.17097, 90.20935]],
  [3528756, [22.74304, -98.9739]],
  [1283339, [27.42839, 85.03219]],
  [1280757, [39.83333, 97.73333]],
  [2939811, [51.75769, 14.32888]],
  [3515011, [20.95777, -97.40805]],
  [1695804, [7.30806, 125.68417]],
  [2343252, [6.58412, 3.98336]],
  [3447998, [-23.44361, -51.87389]],
  [2508737, [34.11279, 2.10228]],
  [2342192, [12.37299, 14.2069]],
  [3815415, [19.31905, -98.19982]],
  [324698, [38.91852, 27.84006]],
  [4832353, [41.39482, -73.45401]],
  [1626099, [-7.01152, 113.86042]],
  [2230876, [9.9333, 13.94671]],
  [1240935, [7.16992, 79.88837]],
  [1832384, [36.82167, 128.63083]],
  [1275679, [29.373, 78.13636]],
  [1700868, [14.43385, 120.48569]],
  [2638867, [51.75, -0.33333]],
  [4833505, [41.10565, -73.39845]],
  [4688275, [26.30174, -98.16334]],
  [4174402, [26.13397, -80.1131]],
  [3460733, [-21.205, -41.88778]],
  [692975, [48.56818, 38.64352]],
  [2808720, [53.52998, 8.11253]],
  [1886762, [29.98869, 122.20488]],
  [1651555, [-7.26333, 110.3975]],
  [2327827, [7.59383, 6.21798]],
  [1278688, [17.69134, 83.00395]],
  [965241, [-27.00706, 30.81323]],
  [1813088, [33.19973, 120.4578]],
  [4004885, [19.43612, -100.35733]],
  [2639557, [52.3065, -1.94569]],
  [1606147, [7.19882, 100.5951]],
  [3902377, [-14.83333, -64.9]],
  [965401, [-28.52423, 28.81582]],
  [3129135, [43.55473, -5.92483]],
  [749274, [41.28629, 27.99939]],
  [1805334, [27.43265, 120.60625]],
  [2518494, [36.77629, -2.81456]],
  [5105496, [40.21705, -74.74294]],
  [1485724, [55.72306, 84.88611]],
  [934570, [-20.31628, 57.52594]],
  [2231504, [5.50803, 10.6325]],
  [535729, [60.00448, 30.27523]],
  [1185224, [23.0144, 91.3966]],
  [1833466, [35.84509, 127.14752]],
  [1262109, [29.61194, 78.34274]],
  [1860728, [34.76667, 138.01667]],
  [1724933, [10.7719, 122.9817]],
  [1273687, [22.83283, 74.25986]],
  [1790392, [32.42806, 119.56048]],
  [3391360, [-0.05833, -51.18167]],
  [5356277, [33.74761, -116.97307]],
  [1258686, [23.6303, 85.52156]],
  [1863528, [35.31859, 138.94343]],
  [3994489, [20.3553, -102.77358]],
  [2497849, [36.26333, 6.69361]],
  [7615262, [-23.56675, -46.66439]],
  [2637329, [54.99859, -1.4323]],
  [1019704, [-28.23078, 28.30707]],
  [2643736, [54.9981, -7.30934]],
  [352354, [30.46597, 30.93199]],
  [2754064, [52.22333, 5.17639]],
  [2036081, [46.56694, 131.14528]],
  [202905, [-1.70278, 29.25639]],
  [1799574, [31.78394, 111.82752]],
  [294778, [32.16627, 34.82536]],
  [1680116, [7.90639, 125.09417]],
  [3673662, [8.23773, -73.35604]],
  [2392308, [6.36307, 2.08506]],
  [5552450, [33.34171, -111.98403]],
  [2820860, [48.52266, 9.05222]],
  [1912205, [35.40611, 129.16861]],
  [1267394, [14.9163, 79.99449]],
  [6331909, [34.02893, -84.19858]],
  [4000821, [20.34239, -102.0305]],
  [2110579, [35.65, 140.31667]],
  [2067119, [-32.5269, 115.7217]],
  [3402000, [-4.45528, -43.88556]],
  [5012639, [42.60559, -83.14993]],
  [1700917, [14.75778, 120.94833]],
  [5331575, [33.86751, -117.99812]],
  [1787837, [20.32917, 110.16712]],
  [2338497, [5.85475, 6.85944]],
  [602150, [63.82842, 20.25972]],
  [2112319, [35.89767, 140.49943]],
  [690688, [48.7501, 30.21944]],
  [1636125, [-6.97083, 107.5675]],
  [1644605, [-5.3166, 119.3661]],
  [8411054, [27.68056, 85.3875]],
  [1808747, [36.53333, 114.11111]],
  [8125829, [35.02954, 135.75666]],
  [710554, [50.39105, 24.23514]],
  [1799348, [35.76417, 116.79139]],
  [1069129, [-12.31732, 49.29188]],
  [2785123, [50.80225, 4.33943]],
  [1805379, [29.60487, 111.87012]],
  [2634308, [51.34603, -2.97665]],
  [752015, [40.65333, 35.83306]],
  [4167538, [29.58497, -81.20784]],
  [1786060, [38.71528, 112.7575]],
  [3489460, [18.47116, -77.91883]],
  [789107, [43.72583, 20.68944]],
  [4876523, [42.49999, -96.40031]],
  [3901178, [-22.01643, -63.67753]],
  [3075921, [49.77984, 18.43688]],
  [1181163, [30.5301, 72.69155]],
  [98622, [31.40906, 46.1727]],
  [1685755, [12.97389, 123.99333]],
  [2988358, [43.31117, -0.35583]],
  [2511716, [36.76419, -2.61475]],
  [3114531, [42.00955, -4.52406]],
  [1785716, [34.31139, 117.95028]],
  [3130380, [43.34927, -3.0094]],
  [1815585, [36.5575, 116.72722]],
  [3471691, [-19.59333, -46.94056]],
  [1882056, [34.8825, 128.62667]],
  [1789118, [36.7975, 118.29444]],
  [1269247, [24.47001, 88.07659]],
  [3895138, [-36.9256, -73.02841]],
  [304081, [38.73163, 41.48482]],
  [1852964, [36.31667, 139.58333]],
  [1511494, [56.081, 86.0285]],
  [2530048, [34.40731, -2.89732]],
  [1786676, [34.73806, 111.88389]],
  [1280037, [38.41667, 77.24056]],
  [5364514, [33.64697, -117.68922]],
  [6318107, [-8.02861, -50.03139]],
  [1272892, [22.59373, 75.29774]],
  [5372253, [37.30216, -120.48297]],
  [3112989, [40.43293, -3.81338]],
  [161290, [-6.44222, 38.90422]],
  [1732945, [4.25, 103.41667]],
  [2035002, [45.21406, 127.97426]],
  [1270417, [29.10239, 75.96253]],
  [3598572, [14.66111, -90.81944]],
  [1801722, [38.8, 121.26667]],
  [1489246, [54.0979, 61.5773]],
  [3061186, [48.73946, 19.15349]],
  [248370, [31.71599, 35.79392]],
  [1860112, [31.38333, 130.85]],
  [12047417, [25.14479, 56.24764]],
  [303798, [37.00972, 37.79417]],
  [307864, [36.71611, 37.115]],
  [3024266, [48.91882, 2.25404]],
  [1185183, [23.24018, 91.12143]],
  [4707814, [32.5007, -94.74049]],
  [6544101, [41.38712, 2.13007]],
  [623317, [55.4879, 28.7856]],
  [7284826, [47.48919, 19.07012]],
  [747155, [41.02778, 37.50139]],
  [2395915, [7.18286, 1.99119]],
  [3626219, [9.91861, -68.30472]],
  [580054, [47.10779, 39.41648]],
  [1850311, [36.38333, 139.73333]],
  [4677008, [30.67436, -96.36996]],
  [2337352, [7.87377, 5.07691]],
  [1850693, [35.72922, 139.53813]],
  [1857665, [34.28333, 133.78333]],
  [1794947, [23.00107, 113.077]],
  [2656065, [51.40878, -0.02526]],
  [5014681, [42.3242, -83.40021]],
  [2473457, [35.16758, 8.83651]],
  [3178229, [45.80819, 9.0832]],
  [2636276, [52.63399, -1.69587]],
  [1607532, [14.35167, 100.57739]],
  [3408404, [-7.91167, -34.90278]],
  [3711668, [8.42729, -82.43085]],
  [3679277, [5.44783, -74.66311]],
  [12165736, [-12.05605, -77.05295]],
  [3840300, [-26.78522, -60.43876]],
  [3460834, [-22.42556, -45.45278]],
  [103369, [20.00054, 42.6052]],
  [2293268, [5.92801, -4.21319]],
  [2817220, [48.06226, 8.49358]],
  [3174530, [43.84369, 10.50447]],
  [1793364, [22.03177, 111.93537]],
  [108435, [25.86944, 43.4973]],
  [5225507, [41.7001, -71.41617]],
  [2659811, [47.05048, 8.30635]],
  [288789, [23.67872, 57.88605]],
  [1621884, [-4.5386, 120.3279]],
  [3435810, [-34.16327, -58.95919]],
  [117656, [38.1973, 44.7653]],
  [3113209, [42.431, -8.64435]],
  [611847, [43.00697, 40.9893]],
  [173811, [36.50237, 40.74772]],
  [1840886, [36.59458, 128.19946]],
  [1268673, [14.11168, 78.15982]],
  [1272720, [21.73359, 70.45004]],
  [1174042, [32.81612, 73.88697]],
  [1258291, [14.05723, 78.75056]],
  [1785964, [29.385, 106.51944]],
  [1525798, [46.84806, 74.995]],
  [4565119, [18.35745, -66.111]],
  [1848499, [35.82255, 139.83905]],
  [4992523, [42.48531, -83.37716]],
  [1267090, [22.31744, 72.61916]],
  [1188569, [24.75127, 89.83126]],
  [7310164, [33.1911, -111.528]],
  [684657, [47.13316, 24.50011]],
  [4588165, [32.79407, -79.86259]],
  [3471291, [-23.09861, -48.92583]],
  [2885679, [47.66033, 9.17582]],
  [1850559, [36.25, 139.53333]],
  [3095321, [52.22338, 18.25121]],
  [2468353, [34.425, 8.78417]],
  [668954, [45.30083, 21.88917]],
  [3458662, [-22.18556, -47.39028]],
  [6545310, [52.52003, 13.40489]],
  [2332357, [7.46791, 4.06594]],
  [2806142, [49.63278, 8.35916]],
  [5221659, [41.77982, -71.43728]],
  [1538634, [55.75556, 60.70278]],
  [466990, [44.04444, 42.86056]],
  [2967849, [48.78716, 2.40332]],
  [4161580, [27.90979, -82.78842]],
  [318253, [37.33024, 42.18484]],
  [1925936, [35.24972, 128.52]],
  [1261288, [19.09685, 78.34407]],
  [934131, [-20.26381, 57.4791]],
  [923295, [-15.38596, 35.3188]],
  [1811440, [35.26056, 117.9675]],
  [2392601, [10.30416, 1.37962]],
  [3169561, [44.41344, 12.20121]],
  [1795941, [35.07528, 117.45778]],
  [1269092, [18.8563, 82.5716]],
  [2754394, [52.26583, 6.79306]],
  [1852607, [37.95, 139.33333]],
  [1863693, [34.83333, 137.23333]],
  [2482886, [31.92427, 5.35018]],
  [547523, [56.33333, 36.73333]],
  [294514, [32.175, 34.90694]],
  [1861095, [34.5, 135.4]],
  [3121007, [43.35689, -3.01146]],
  [7279734, [30.5819, 74.83298]],
  [2555467, [34.92, -2.32]],
  [2110729, [35.9, 140.08333]],
  [3174953, [40.35481, 18.17244]],
  [4786714, [36.71709, -76.59023]],
  [2235196, [5.15705, 10.1771]],
  [2367886, [7.53333, 1.13333]],
  [5404119, [33.74585, -117.82617]],
  [2504099, [36.13516, 2.91085]],
  [321082, [38.88472, 40.49389]],
  [713174, [48.59441, 37.99983]],
  [12042052, [24.30978, 54.62944]],
  [3430443, [-38.5545, -58.73961]],
  [315795, [38.20591, 37.1983]],
  [1253956, [32.92431, 75.13573]],
  [5375480, [37.38605, -122.08385]],
  [5376095, [38.29714, -122.28553]],
  [1268601, [29.39541, 77.2054]],
  [2439376, [14.8888, 5.2692]],
  [3165243, [46.06787, 11.12108]],
  [1857334, [34.4, 133.08333]],
  [1277661, [21.8156, 80.18845]],
  [140889, [37.3404, 46.0561]],
  [1792516, [28.1368, 106.822]],
  [4951257, [42.3876, -71.0995]],
  [1816890, [33.22917, 119.30917]],
  [3176059, [40.92849, 14.20197]],
  [1640585, [-8.06667, 111.91667]],
  [2221030, [10.34107, 15.23288]],
  [2037823, [40.63732, 122.50251]],
  [7284832, [47.56263, 19.11681]],
  [2228079, [3.51667, 11.5]],
  [2035511, [42.94222, 126.05611]],
  [2864475, [54.07477, 9.98195]],
  [1627459, [-6.31694, 106.66417]],
  [3347019, [-15.19611, 12.15222]],
  [2878018, [52.4333, 13.30762]],
  [3088972, [51.40547, 19.70321]],
  [2227402, [6.51667, 14.3]],
  [1486910, [67.49884, 64.05253]],
  [3803449, [10.46541, -66.82951]],
  [2517367, [38.96667, -0.18333]],
  [2110629, [35.96667, 140.13333]],
  [1263622, [29.98844, 75.40167]],
  [32909, [35.6803, 51.0193]],
  [1279715, [29.25, 88.88333]],
  [8340760, [21.18697, 106.16076]],
  [562820, [55.74819, 37.47969]],
  [293807, [32.1836, 34.87386]],
  [2320829, [5.48956, 6.00407]],
  [2935530, [51.66166, 6.96514]],
  [2638324, [53.57905, -0.65437]],
  [2636876, [54.56848, -1.3187]],
  [1278667, [33.73068, 75.15418]],
  [3664464, [-7.62759, -72.67756]],
  [4771075, [37.41375, -79.14225]],
  [5740099, [42.32652, -122.87559]],
  [2470656, [36.81825, 10.3052]],
  [783814, [45.38361, 20.38194]],
  [4153071, [26.31841, -80.09977]],
  [3598655, [14.94333, -91.11116]],
  [1213855, [1.74016, 98.78117]],
  [730435, [41.93415, 25.55557]],
  [4013706, [25.53718, -103.52456]],
  [1793533, [32.16667, 120.01361]],
  [1708824, [7.00833, 125.08944]],
  [1644178, [-8.36667, 114.15]],
  [2031405, [49.03333, 104.08333]],
  [2759798, [52.30083, 4.86389]],
  [2347303, [5.55874, 7.63359]],
  [540761, [45.4375, 40.57556]],
  [3460522, [-18.41917, -49.21528]],
  [2327223, [7.81667, 4.91667]],
  [3457708, [-22.91944, -42.81861]],
  [3044310, [47.23088, 16.62155]],
  [2335713, [9.58126, 8.2926]],
  [3181355, [45.61128, 8.84914]],
  [5383777, [37.66243, -121.87468]],
  [1697497, [14.9109, 121.0493]],
  [1268977, [21.85531, 84.00698]],
  [1793385, [39.74326, 118.45099]],
  [1492517, [56.0852, 63.6335]],
  [8558534, [43.76672, -79.39909]],
  [3595416, [15.31918, -91.47241]],
  [1275194, [21.81667, 83.91667]],
  [709611, [49.34991, 23.50561]],
  [3174741, [41.73212, 12.27654]],
  [3178957, [44.1391, 12.24315]],
  [2875457, [51.21977, 7.6273]],
  [289011, [23.39083, 57.42444]],
  [140951, [30.5959, 50.2417]],
  [2470173, [33.50398, 11.11215]],
  [698625, [48.67466, 33.11761]],
  [3110718, [41.47063, 2.08611]],
  [1265938, [17.55106, 80.61779]],
  [1644349, [-7.39083, 112.72667]],
  [1794794, [36.88, 118.7375]],
  [4884442, [41.9317, -87.76867]],
  [233730, [-0.59167, 30.62861]],
  [5019335, [45.09413, -93.35634]],
  [2420562, [8.56744, -10.1336]],
  [1797438, [37.30553, 120.82747]],
  [2338242, [5.96669, 8.70632]],
  [1278471, [13.08449, 79.67053]],
  [1859952, [34.58333, 135.61667]],
  [1788402, [25.11667, 114.3]],
  [1630798, [-7.8685, 111.462]],
  [2351740, [6.10045, 7.061]],
  [1185116, [24.88352, 90.72898]],
  [3445847, [-23.43389, -45.07111]],
  [1830194, [13.65805, 102.56365]],
  [666767, [44.43333, 24.36667]],
  [2634910, [53.68331, -1.49768]],
  [2525059, [38.88247, 16.60086]],
  [1172035, [30.96128, 70.93904]],
  [3944797, [-10.66748, -76.25668]],
  [5799610, [46.21125, -119.13723]],
  [2873759, [50.80904, 8.77069]],
  [2244991, [13.77073, -13.66734]],
  [1221194, [37.91459, 69.78454]],
  [87205, [32.76704, 22.63669]],
  [3805673, [7.88782, -67.47236]],
  [1794971, [39.23417, 106.34389]],
  [3061370, [49.22645, 17.67065]],
  [2799478, [51.22134, 4.46595]],
  [352913, [27.3126, 30.96987]],
  [1298482, [21.8787, 95.97965]],
  [1266436, [26.32539, 89.44508]],
  [2034340, [42.96611, 129.8425]],
  [3446098, [-20.78765, -51.70338]],
  [3433658, [-33.00937, -58.51722]],
  [1272473, [13.29452, 77.53777]],
  [6362987, [35.88919, -5.32042]],
  [946058, [-31.58893, 28.78443]],
  [1024696, [-19.60944, 34.74306]],
  [4566385, [18.20107, -67.13962]],
  [470546, [60.70763, 28.75283]],
  [5322737, [37.77099, -122.26087]],
  [2521582, [28.09962, -16.68102]],
  [3448879, [-21.13556, -44.26167]],
  [3181528, [40.63215, 17.93607]],
  [2510279, [39.43705, -0.46546]],
  [2653775, [54.8951, -2.9382]],
  [4175538, [28.01057, -82.57732]],
  [5327422, [33.88168, -118.11701]],
  [3049646, [47.48427, 19.13913]],
  [1267457, [23.64558, 88.13258]],
  [2455518, [14.44693, -11.44448]],
  [2033301, [47.85306, 128.83565]],
  [502018, [55.76111, 37.8575]],
  [1733440, [5.9258, 116.06094]],
  [1860063, [33.4425, 129.96972]],
  [5336545, [33.9938, -117.75888]],
  [1619434, [13.805, 100.47283]],
  [2813472, [52.54734, 13.35594]],
  [2345096, [11.5541, 11.406]],
  [99350, [32.17419, 46.04345]],
  [1798634, [35.50056, 117.63083]],
  [7284841, [47.47997, 19.25388]],
  [3456998, [-22.43194, -46.95778]],
  [1861835, [33.63654, 130.68678]],
  [1225142, [9.81667, 80.16667]],
  [1269291, [24.92606, 86.22531]],
  [1803374, [30.4225, 120.29722]],
  [8310138, [37.93149, 23.76779]],
  [266045, [33.84675, 35.90203]],
  [1716287, [14.1122, 122.9553]],
  [6332439, [28.5641, -81.2114]],
  [2294206, [10.06069, -2.50192]],
  [1270349, [14.51288, 75.80716]],
  [371712, [8.30278, 27.98]],
  [582846, [55.63, 37.8]],
  [1263936, [18.87074, 79.42863]],
  [2940231, [51.55657, 7.31155]],
  [2524245, [37.7992, 12.4367]],
  [1854803, [35.01756, 136.94947]],
  [5377654, [34.17223, -118.37897]],
  [1268707, [9.07277, 77.34152]],
  [1964032, [-6.82, 107.25722]],
  [2036066, [45.28606, 130.26151]],
  [5268249, [42.72613, -87.78285]],
  [3680539, [0.82501, -77.63966]],
  [2747930, [51.53083, 4.46528]],
  [1273626, [24.03971, 84.0658]],
  [2413753, [13.27136, -16.64944]],
  [318137, [37.57444, 43.74083]],
  [1734098, [5.3378, 116.1602]],
  [2648773, [54.96209, -1.60168]],
  [1816373, [27.30193, 105.28627]],
  [1807339, [30.19235, 116.02496]],
  [2328617, [9.46374, 12.03062]],
  [5373327, [37.42827, -121.90662]],
  [3165201, [45.66673, 12.2416]],
  [3449350, [-26.25028, -49.37861]],
  [3097872, [52.79886, 18.26387]],
  [738349, [41.13139, 37.2825]],
  [1898494, [31.1933, 120.71758]],
  [2947022, [48.15221, 11.61585]],
  [1805267, [33.73333, 115.4]],
  [2803448, [50.93604, 4.0355]],
  [3092931, [51.40089, 16.20149]],
  [2791537, [51.02574, 4.47762]],
  [114584, [32.04972, 48.84843]],
  [2644411, [54.52337, -6.03527]],
  [743952, [40.59825, 43.08548]],
  [1167622, [27.58753, 68.00066]],
  [1803364, [36.51556, 118.53972]],
  [1271949, [30.67399, 74.75579]],
  [1907300, [35.78944, 139.62333]],
  [127403, [33.63889, 50.08003]],
  [2034761, [44.41667, 126.95]],
  [1730413, [14.95333, 120.77]],
  [3460584, [-20.07528, -44.57639]],
  [1277525, [27.42949, 82.18545]],
  [6138610, [45.50008, -73.66585]],
  [2198148, [-18.14161, 178.44149]],
  [1863018, [34.35, 132.33333]],
  [3996387, [23.64824, -100.64334]],
  [678015, [45.7, 27.18333]],
  [2519513, [36.41976, -6.14367]],
  [1787031, [35.55278, 116.82861]],
  [1277085, [34.209, 74.34285]],
  [4019260, [31.33071, -109.54876]],
  [3171173, [43.90921, 12.9164]],
  [3526323, [20.93482, -89.55871]],
  [3530103, [20.50038, -86.94272]],
  [1644932, [-8.8432, 121.6623]],
  [3628503, [9.66124, -68.58268]],
  [1692565, [14.90167, 120.84917]],
  [1805833, [37.02444, 111.9125]],
  [2491323, [35.88889, 2.74905]],
  [3492985, [18.20854, -71.10077]],
  [4920607, [41.59337, -87.34643]],
  [3877918, [-30.60106, -71.19901]],
  [1693136, [13.2923, 123.4855]],
  [5211303, [41.40916, -75.6649]],
  [3448519, [-18.72011, -39.85891]],
  [3532881, [19.04611, -98.045]],
  [2422457, [9.70643, -13.38465]],
  [471656, [55.31733, 38.65264]],
  [260204, [37.9625, 23.61972]],
  [5325866, [34.08529, -117.9609]],
  [1272689, [24.68127, 87.9535]],
  [2036653, [42.8675, 130.35806]],
  [957487, [-26.81358, 27.81695]],
  [3170647, [43.70853, 10.4036]],
  [5785965, [47.30732, -122.22845]],
  [688533, [44.50218, 34.16624]],
  [5921356, [49.16638, -121.95257]],
  [1792359, [29.71139, 118.3125]],
  [1263275, [29.10288, 77.92199]],
  [1849561, [33.24528, 131.69165]],
  [11054704, [47.48938, 19.07292]],
  [2920236, [51.57077, 6.98593]],
  [1519691, [45.41322, 79.91713]],
  [1267648, [10.95771, 78.08095]],
  [3164699, [45.82058, 8.82511]],
  [358048, [31.41648, 31.81332]],
  [3543299, [21.19517, -76.60358]],
  [3714637, [8.95187, -79.66011]],
  [3006787, [46.16308, -1.15222]],
  [1265605, [21.34222, 71.30633]],
  [4257494, [39.95559, -86.01387]],
  [4407010, [39.76861, -94.84663]],
  [640999, [61.48333, 21.78333]],
  [3628423, [10.33991, -68.74247]],
  [975436, [-25.85, 25.63333]],
  [2748413, [52.505, 4.95972]],
  [1803948, [39.73598, 116.13295]],
  [3027105, [48.81642, 2.49366]],
  [1263664, [20.25334, 74.43755]],
  [1268011, [27.05524, 79.9188]],
  [2960316, [49.61167, 6.13]],
  [2793508, [50.48657, 4.18785]],
  [935214, [-21.3393, 55.47811]],
  [787237, [44.87177, 20.64167]],
  [1813658, [29.65628, 121.4064]],
  [3712076, [9.35451, -79.90011]],
  [1264138, [30.21121, 74.4818]],
  [2982235, [48.8765, 2.18967]],
  [3625341, [10.03863, -67.48938]],
  [1712531, [14.83333, 120.88333]],
  [2955471, [51.38333, 8.08333]],
  [3520064, [16.17535, -95.19424]],
  [1262200, [29.44433, 78.43646]],
  [3117533, [41.72815, 1.82399]],
  [3460064, [-17.87939, -51.72166]],
  [3448902, [-21.96917, -46.79806]],
  [4718721, [26.1948, -98.18362]],
  [3458425, [-22.73083, -45.12472]],
  [1278023, [11.59776, 75.58142]],
  [229278, [1.08209, 34.17503]],
  [2847645, [52.28509, 7.44055]],
  [1269578, [19.08136, 82.02131]],
  [5404915, [34.09751, -117.64839]],
  [1809532, [23.09639, 109.60917]],
  [2749234, [51.765, 5.51806]],
  [2038854, [40.02472, 128.15972]],
  [12157025, [-16.43085, -71.52921]],
  [3037456, [43.58579, 7.10831]],
  [1787437, [39.1375, 116.99972]],
  [5349705, [38.67796, -121.17606]],
  [3448257, [-23.76, -45.40972]],
  [3182884, [43.46276, 11.88068]],
  [1268775, [24.4349, 85.52951]],
  [7284839, [47.43047, 19.07098]],
  [3385935, [-3.76585, -49.67923]],
  [4672731, [29.7355, -94.97743]],
  [2112899, [38.57167, 140.95556]],
  [3464304, [-23.83222, -46.81139]],
  [886763, [-20.06373, 30.82766]],
  [3660418, [-1.80217, -79.53443]],
  [788886, [42.13222, 21.71444]],
  [304382, [37.41908, 41.33909]],
  [2092740, [-6.72333, 146.99611]],
  [1235846, [5.94851, 80.53528]],
  [122915, [34.19088, 48.37446]],
  [1623180, [-6.8976, 112.0649]],
  [6119518, [45.74222, -73.45008]],
  [1606790, [17.16116, 104.14725]],
  [1170486, [30.44067, 72.35679]],
  [2640677, [55.83173, -4.43254]],
  [934985, [-26.31667, 31.13333]],
  [1797793, [30.45113, 115.25593]],
  [2817311, [51.25435, 6.39441]],
  [2026643, [48.79284, 132.92386]],
  [5392593, [37.77993, -121.97802]],
  [4501018, [39.92595, -75.11962]],
  [2655009, [51.41363, -0.75054]],
  [1266179, [22.70508, 88.34446]],
  [4330236, [30.21309, -93.2044]],
  [3448063, [-29.63806, -51.00694]],
  [4997787, [42.29171, -85.58723]],
  [3046619, [47.9126, 19.97709]],
  [472732, [55.65, 37.61667]],
  [2185018, [-40.35636, 175.61113]],
  [1258338, [16.99154, 73.31022]],
  [1640765, [-7.16667, 112.6333]],
  [728378, [42.2, 24.33333]],
  [2391893, [8.03424, 2.4866]],
  [334609, [7.54978, 37.85374]],
  [4883555, [42.08836, -87.98063]],
  [3110040, [40.55555, -3.62733]],
  [1133453, [35.92139, 64.78361]],
  [1803266, [34.37803, 109.20892]],
  [3655131, [-2.233, -80.91039]],
  [5138950, [40.6701, -73.81902]],
  [1270072, [19.71464, 77.14238]],
  [3613528, [13.30028, -87.19083]],
  [2491911, [36.26104, 2.22015]],
  [1641184, [-6.8487, 108.6422]],
  [294946, [32.44192, 34.9039]],
  [1794479, [36.85917, 120.52694]],
  [553399, [48.31779, 40.25948]],
  [1219762, [37.61852, 62.16715]],
  [2641674, [53.0, -2.23333]],
  [3455161, [-19.86028, -44.60833]],
  [1852472, [34.81667, 138.18333]],
  [1510469, [54.4165, 86.2976]],
  [1850872, [35.90393, 136.16687]],
  [1624668, [-6.93333, 109.16667]],
  [5014130, [42.69303, -83.41181]],
  [311665, [39.92371, 44.045]],
  [1840982, [34.99014, 126.47899]],
  [1528249, [40.93333, 73.0]],
  [1163054, [26.00122, 63.04849]],
  [1275388, [10.01171, 77.34976]],
  [1792087, [35.87861, 119.97528]],
  [1267195, [18.5635, 73.85205]],
  [6690602, [51.47475, -0.15547]],
  [569591, [55.1477, 37.47728]],
  [3204222, [44.81694, 15.87083]],
  [97417, [35.53356, 44.8343]],
  [1250164, [6.8964, 79.9181]],
  [1649824, [-7.87, 112.52833]],
  [1272701, [22.99167, 71.46793]],
  [2652002, [53.09787, -2.44161]],
  [3089033, [53.15145, 16.73782]],
  [4891382, [42.04114, -87.69006]],
  [303831, [38.625, 34.71222]],
  [3518407, [19.28431, -98.43885]],
  [2653305, [51.37891, 0.52786]],
  [699942, [51.04801, 31.88688]],
  [715126, [47.18333, 20.2]],
  [2328090, [7.7899, 5.7117]],
  [5370164, [37.79743, -121.21605]],
  [2747596, [51.91917, 4.38889]],
  [2848756, [52.56667, 13.33333]],
  [1165638, [27.84726, 67.90679]],
  [3467717, [-24.04309, -52.37929]],
  [4160711, [25.67927, -80.31727]],
  [1738294, [1.6, 103.81667]],
  [2505915, [36.73944, 7.10528]],
  [3091232, [50.20745, 19.16668]],
  [788975, [43.58, 21.33389]],
  [1844191, [37.6918, 127.8857]],
  [1831167, [12.25, 104.66667]],
  [1788869, [27.74278, 111.29556]],
  [1260667, [17.60184, 80.70509]],
  [2338640, [6.01798, 7.02027]],
  [5579368, [40.39776, -105.07498]],
  [5112375, [42.90339, -78.75475]],
  [2646504, [50.83088, -0.1672]],
  [5123443, [40.87871, -73.90514]],
  [191220, [1.01572, 35.00622]],
  [3437547, [-22.54722, -55.73333]],
  [3671116, [5.69188, -76.65835]],
  [2365560, [6.9, 0.63333]],
  [1854371, [36.0, 139.55722]],
  [2951825, [49.94782, 11.57893]],
  [2463447, [23.68477, -15.95798]],
  [1584661, [10.97333, 106.49325]],
  [2746932, [51.845, 4.32917]],
  [3438735, [-27.33056, -55.86667]],
  [5382146, [33.78252, -117.22865]],
  [2707953, [59.16775, 18.14478]],
  [2472479, [36.75452, 10.22167]],
  [250336, [32.55873, 36.00816]],
  [2712414, [60.67452, 17.14174]],
  [1270791, [14.15093, 79.8521]],
  [2906809, [52.53319, 13.6088]],
  [3526357, [16.43603, -95.01975]],
  [1806840, [31.81111, 121.655]],
  [333287, [15.77792, 38.45107]],
  [300797, [37.24379, 42.46345]],
  [1816751, [34.56861, 105.89333]],
  [2533191, [34.22149, -5.70775]],
  [2821029, [50.80901, 7.14968]],
  [2656719, [51.81665, -0.81458]],
  [1182787, [32.26576, 72.89809]],
  [1278553, [21.63236, 72.99001]],
  [2754447, [51.48167, 5.66111]],
  [2650405, [55.76412, -4.17669]],
  [1787601, [36.11056, 115.77528]],
  [1254534, [24.74327, 78.83061]],
  [1625958, [-1.85442, 106.12215]],
  [1857276, [34.8, 134.98333]],
  [3468436, [-30.03917, -52.89389]],
  [4910713, [42.03336, -88.08341]],
  [6903078, [35.54145, 128.49506]],
  [3854985, [-33.03016, -60.64045]],
  [1179760, [30.66984, 73.65306]],
  [1052944, [-16.2325, 39.90861]],
  [89055, [32.76272, 21.75506]],
  [1511330, [57.00993, 61.45776]],
  [4467732, [35.26208, -81.1873]],
  [5404555, [37.59577, -122.01913]],
  [321031, [37.84514, 40.65931]],
  [2349529, [7.70483, 5.04054]],
  [1795919, [34.79528, 116.08167]],
  [3029162, [50.95194, 1.85635]],
  [2920512, [50.58727, 8.67554]],
  [1799897, [36.18478, 118.15486]],
  [1255616, [9.51272, 77.63369]],
  [4885265, [41.69864, -88.0684]],
  [2031964, [49.48667, 105.92278]],
  [6087701, [44.05011, -79.46631]],
  [64013, [11.28421, 49.18158]],
  [1255947, [9.44999, 77.79797]],
  [3123493, [43.48451, -8.23293]],
  [3983058, [20.94356, -101.42703]],
  [4862034, [41.66113, -91.53017]],
  [2159220, [-21.15345, 149.16554]],
  [1259680, [28.71271, 77.656]],
  [1835895, [36.78167, 126.45222]],
  [260172, [38.02369, 23.80068]],
  [2509769, [36.78107, -4.10266]],
  [245785, [13.82916, 20.8324]],
  [1793889, [33.89067, 116.77473]],
  [5777107, [41.06022, -111.97105]],
  [5244080, [44.26193, -88.41538]],
  [4711801, [29.61857, -95.53772]],
  [2033675, [41.99083, 122.82528]],
  [2338810, [7.50251, 5.06258]],
  [1278862, [18.73312, 76.38616]],
  [2248698, [14.79032, -15.90803]],
  [5009586, [42.67087, -83.03298]],
  [8858086, [19.6325, -99.13861]],
  [3032833, [43.34122, 3.21402]],
  [11838435, [46.89028, -71.37222]],
  [3836620, [-23.13705, -64.32426]],
  [1809610, [32.26604, 111.63476]],
  [1790379, [38.985, 117.38278]],
  [952747, [-26.93366, 29.24152]],
  [2519402, [38.98626, -3.92907]],
  [3906466, [-11.00654, -66.06312]],
  [4155995, [26.62168, -81.84059]],
  [1861528, [35.37995, 136.94295]],
  [1276574, [25.92058, 81.99629]],
  [762863, [50.92936, 21.38525]],
  [4148677, [26.52535, -80.06643]],
  [3449521, [-12.96889, -39.26139]],
  [2947421, [51.83879, 6.61531]],
  [1275391, [18.66208, 77.88581]],
  [1785655, [33.44743, 112.71422]],
  [4116834, [35.8423, -90.70428]],
  [2544333, [32.93492, -5.66167]],
  [2794055, [50.82803, 3.26487]],
  [2524653, [37.07381, 14.24038]],
  [1259083, [16.47567, 75.1106]],
  [3170621, [43.93064, 10.92365]],
  [3861416, [-31.48333, -68.53333]],
  [4911951, [41.84364, -87.71255]],
  [786735, [41.34514, 21.55504]],
  [2325161, [5.95753, 6.85305]],
  [2745467, [51.9125, 4.34167]],
  [2513882, [36.59575, -4.63728]],
  [2546917, [33.52666, -5.11019]],
  [2288115, [5.2118, -3.73884]],
  [2350886, [6.70902, 7.31826]],
  [1788406, [28.28857, 117.21329]],
  [354365, [30.8248, 30.81805]],
  [1267819, [26.49831, 77.02755]],
  [1804442, [29.21588, 119.47156]],
  [4900358, [41.92337, -87.69922]],
  [11184442, [21.33726, 71.3035]],
  [289317, [24.25088, 55.79312]],
  [1733782, [5.28028, 115.2475]],
  [2037252, [50.78333, 121.51667]],
  [1643776, [-7.15389, 112.65611]],
  [2325249, [8.05, 4.76667]],
  [1256755, [27.6431, 79.9402]],
  [6118158, [52.26682, -113.802]],
  [1801983, [29.38481, 104.02862]],
  [1184055, [24.656, 68.837]],
  [5768233, [44.08054, -103.23101]],
  [214614, [-8.73508, 24.99798]],
  [1274714, [29.13489, 78.27187]],
  [24851, [34.0734, 47.9725]],
  [122698, [36.9553, 45.388]],
  [2336237, [12.22336, 4.37971]],
  [4229476, [32.61574, -83.62664]],
  [1271067, [16.16901, 74.82393]],
  [2513947, [35.29369, -2.93833]],
  [5007402, [42.65837, -83.14993]],
  [6138625, [45.58773, -73.59501]],
  [6692280, [51.50519, -0.02085]],
  [1256329, [28.45226, 77.70004]],
  [1858445, [36.06739, 139.67498]],
  [12157030, [-12.08398, -75.20263]],
  [3690465, [8.30844, -73.6166]],
  [2327494, [5.61455, 7.81191]],
  [153176, [-6.34379, 31.06951]],
  [1804979, [32.31737, 121.18552]],
  [1253113, [9.58509, 77.95787]],
  [3511233, [18.93687, -70.40923]],
  [1047660, [-14.80306, 36.53722]],
  [321580, [36.86278, 31.05556]],
  [4236895, [39.84031, -88.9548]],
  [3441243, [-32.3171, -58.08072]],
  [299582, [38.49221, 42.28269]],
  [3855065, [-39.03333, -67.58333]],
  [1709003, [14.44426, 120.90164]],
  [510291, [59.88333, 29.9]],
  [1732741, [1.6006, 103.6419]],
  [5010636, [42.47337, -83.22187]],
  [2638978, [52.37092, -1.26417]],
  [1852736, [31.81667, 130.3]],
  [2478216, [22.785, 5.52278]],
  [3086024, [50.32738, 19.02901]],
  [2112823, [39.38333, 141.11667]],
  [310855, [37.78552, 38.6237]],
  [618806, [52.8926, 30.024]],
  [256575, [37.94504, 23.71416]],
  [1185206, [25.10147, 89.02734]],
  [3649017, [9.58144, -69.23851]],
  [1273369, [26.89, 76.33584]],
  [1642726, [-6.47472, 108.31528]],
  [610824, [42.5088, 41.87088]],
  [12212406, [3.9284, 41.87997]],
  [1842859, [35.43333, 126.7]],
  [7290013, [24.94824, 114.06572]],
  [585103, [40.39667, 49.97361]],
  [3111294, [41.49226, 2.03305]],
  [1574507, [21.52471, 107.96619]],
  [1728523, [14.67611, 120.53611]],
  [3493081, [18.80588, -71.22991]],
  [1253468, [20.61013, 72.93428]],
  [3089684, [51.65501, 17.80686]],
  [5546220, [37.10415, -113.58412]],
  [1168412, [27.85619, 69.11111]],
  [3455051, [-23.07306, -52.46528]],
  [3179866, [41.07262, 14.33231]],
  [567311, [59.84167, 30.25583]],
  [1278827, [23.11892, 83.19537]],
  [1609395, [13.6533, 100.25972]],
  [4839292, [41.66121, -72.77954]],
  [1812427, [33.99972, 119.83083]],
  [1498693, [53.71028, 91.6875]],
  [3459796, [-19.81, -43.17361]],
  [3156529, [59.2181, 10.9298]],
  [2638671, [53.48771, -2.29042]],
  [1279945, [39.74318, 98.51736]],
  [2759887, [52.35667, 6.6625]],
  [12157128, [-16.37057, -71.52724]],
  [1172904, [25.36566, 68.30831]],
  [1163952, [25.12384, 68.53677]],
  [561627, [44.15194, 43.46972]],
  [468082, [55.76127, 52.06493]],
  [4623560, [35.92506, -86.86889]],
  [523426, [55.38752, 36.73307]],
  [1800430, [24.40417, 103.44278]],
  [3457509, [-21.60333, -48.36583]],
  [2829109, [52.45606, 13.332]],
  [2037391, [40.43944, 113.15361]],
  [1842616, [36.45556, 127.12472]],
  [1177073, [29.61206, 73.13802]],
  [3406910, [-4.29167, -44.79167]],
  [1711146, [17.14854, 121.88924]],
  [3468562, [-23.10083, -45.70694]],
  [3833062, [-33.74556, -61.96885]],
  [2377457, [20.94188, -17.03842]],
  [1260730, [19.69693, 72.76543]],
  [265243, [38.05, 23.8]],
  [8504959, [59.96824, 30.36415]],
  [5404024, [37.49466, -120.84659]],
  [1272873, [10.73828, 77.53223]],
  [2507877, [35.37675, 5.90001]],
  [1037125, [-13.12556, 38.99972]],
  [4735966, [31.09823, -97.34278]],
  [4005270, [21.01858, -101.2591]],
  [785238, [42.35861, 20.825]],
  [4916079, [41.99975, -87.69284]],
  [2622447, [55.47028, 8.45187]],
  [410096, [21.07268, 40.31842]],
  [5324363, [34.50083, -117.18588]],
  [1691150, [10.94472, 123.42417]],
  [6141190, [42.97866, -82.40407]],
  [1842754, [34.85028, 128.58861]],
  [2229411, [4.02356, 9.20607]],
  [2501404, [35.27306, 7.75194]],
  [1639094, [-6.97583, 108.48306]],
  [3471393, [-23.39611, -46.32083]],
  [286987, [22.93333, 57.53333]],
  [1718722, [15.33117, 120.5898]],
  [1920772, [35.91222, 116.31167]],
  [3465090, [-22.57316, -44.97108]],
  [3438995, [-25.20791, -57.53202]],
  [146400, [34.92291, 33.6233]],
  [2639842, [51.33678, -0.11201]],
  [5369367, [33.93029, -118.21146]],
  [5278052, [43.01168, -88.23148]],
  [3454763, [-18.94389, -46.9925]],
  [2755420, [52.01667, 4.70833]],
  [3446065, [-22.11667, -43.20917]],
  [1832828, [35.34199, 129.03358]],
  [1016698, [-27.3887, 26.61701]],
  [1816176, [30.58333, 114.03333]],
  [2647793, [51.23536, -0.57427]],
  [2352250, [5.89258, 7.93534]],
  [3470264, [-20.94944, -48.47917]],
  [4428667, [30.36742, -89.09282]],
  [515083, [43.3195, 45.04911]],
  [3599735, [14.4774, -90.63489]],
  [3176477, [40.83333, 14.2]],
  [1274129, [13.40051, 78.05172]],
  [707308, [45.35058, 28.83751]],
  [2503847, [36.71499, 3.05002]],
  [2637891, [52.71009, -2.75208]],
  [2744118, [52.43854, 4.82643]],
  [2720501, [57.72101, 12.9401]],
  [694382, [48.05657, 38.43826]],
  [2501541, [36.16286, 6.16651]],
  [1848439, [34.87009, 135.7027]],
  [2349558, [7.35156, 4.18335]],
  [6138495, [45.30713, -73.26259]],
  [170892, [33.45835, 36.23256]],
  [3586814, [13.72171, -89.16874]],
  [5223869, [41.87871, -71.38256]],
  [1259154, [19.91274, 77.57838]],
  [1733438, [5.90702, 116.10146]],
  [1264555, [26.35367, 86.07169]],
  [1508054, [53.82361, 91.28417]],
  [4161625, [26.14036, -80.21338]],
  [1861290, [34.96667, 139.08333]],
  [8224580, [51.47403, -0.06969]],
  [4593142, [34.92487, -81.02508]],
  [2473493, [35.77799, 10.82617]],
  [3449310, [-11.01472, -37.20639]],
  [1137168, [36.68304, 69.11279]],
  [3639747, [10.08344, -67.77799]],
  [1626498, [-7.42639, 111.02222]],
  [1256039, [18.38865, 78.81048]],
  [113491, [36.07057, 49.69571]],
  [2517595, [36.53998, -4.62473]],
  [1685880, [17.6528, 121.691]],
  [2342628, [7.71361, 3.91722]],
  [4369596, [38.99067, -77.02609]],
  [2656284, [53.55, -1.48333]],
  [2862026, [53.6859, 9.98041]],
  [3665657, [3.58234, -76.49146]],
  [3472603, [-21.42917, -45.94722]],
  [8410807, [26.7919, 86.699]],
  [7279741, [20.37175, 72.90493]],
  [749748, [40.59995, 33.6153]],
  [304797, [37.31309, 40.74357]],
  [1488253, [56.1124, 94.5985]],
  [4450687, [30.40409, -89.0942]],
  [966166, [-26.9033, 27.45727]],
  [733191, [42.01667, 23.1]],
  [263986, [37.93333, 23.73333]],
  [1859586, [36.11667, 139.6]],
  [738025, [40.65501, 29.27693]],
  [3020686, [51.0344, 2.37681]],
  [2038139, [42.77884, 124.09545]],
  [1848087, [33.72873, 130.983]],
  [1723066, [14.79278, 120.87889]],
  [2875115, [53.2509, 10.41409]],
  [3186952, [44.11578, 15.22514]],
  [362004, [27.04514, 31.31845]],
  [3980187, [20.53996, -100.43817]],
  [4691585, [33.01457, -97.09696]],
  [3084840, [53.33672, 15.0499]],
  [3461444, [-28.38778, -53.91472]],
  [1818016, [31.2575, 113.67833]],
  [4005219, [25.56745, -108.46756]],
  [5688025, [46.80833, -100.78374]],
  [286245, [22.56667, 59.52889]],
  [3986984, [21.01843, -101.85515]],
  [4751839, [38.84039, -77.42888]],
  [2529649, [30.47028, -8.87695]],
  [559029, [60.03587, 30.40518]],
  [3178671, [45.55823, 9.21495]],
  [1185092, [26.03097, 88.46989]],
  [5102443, [40.85677, -74.12848]],
  [431748, [-2.8451, 30.3414]],
  [1809486, [23.39336, 110.07437]],
  [746958, [40.43094, 29.15969]],
  [1808872, [27.06372, 119.6528]],
  [1850396, [34.58333, 135.83333]],
  [4170156, [27.86614, -82.32648]],
  [1268259, [18.32001, 78.34177]],
  [5386754, [34.05557, -117.18254]],
  [2521088, [38.53816, -0.13098]],
  [2325733, [7.55, 3.43333]],
  [5666639, [46.87215, -113.994]],
  [5385941, [38.58907, -121.30273]],
  [2940213, [52.62264, 10.08047]],
  [1275346, [23.66278, 87.69695]],
  [1258546, [23.17623, 88.56667]],
  [2643490, [52.47523, 1.75167]],
  [2044050, [40.02528, 127.95583]],
  [3036386, [48.91667, 2.38333]],
  [2870310, [52.52635, 13.33903]],
  [3407882, [-3.20333, -52.20639]],
  [1271891, [30.40207, 74.02836]],
  [2036283, [40.72861, 124.78472]],
  [2173323, [-24.86621, 152.3479]],
  [2732544, [41.12401, -8.61241]],
  [2301217, [7.38558, -1.35617]],
  [2648272, [50.79509, -1.12902]],
  [1798548, [28.99242, 116.66754]],
  [1786357, [27.45493, 112.16943]],
  [1693239, [14.88722, 120.85722]],
  [1502603, [55.11722, 61.62823]],
  [2343822, [6.65915, 7.75961]],
  [2676176, [59.19554, 17.62525]],
  [2037240, [41.11667, 123.45]],
  [3455168, [-17.22222, -46.87472]],
  [1276378, [11.17151, 75.80611]],
  [601084, [54.39635, 24.04142]],
  [2751738, [52.50833, 5.475]],
  [1805757, [36.38972, 120.46222]],
  [2331005, [11.78935, 7.62061]],
  [3402721, [-7.85083, -35.25472]],
  [2964180, [53.27245, -9.05095]],
  [2549263, [31.5125, -9.77]],
  [160961, [-1.33167, 31.81222]],
  [1070661, [-19.65, 47.31667]],
  [6053154, [49.69999, -112.81856]],
  [3374462, [16.89014, -24.98042]],
  [1273724, [28.55257, 77.55403]],
  [203104, [-1.5763, 30.0675]],
  [8436165, [60.16562, 29.908]],
  [7422816, [33.51278, 130.52389]],
  [2936871, [51.56227, 6.7434]],
  [2658822, [47.42391, 9.37477]],
  [4714131, [29.703, -98.12445]],
  [3089578, [51.66446, 19.35473]],
  [3044082, [47.58494, 18.39325]],
  [1700980, [8.83333, 125.11667]],
  [1278152, [26.46517, 79.50918]],
  [6534232, [38.96255, 16.30938]],
  [472278, [52.04541, 47.37993]],
  [2296969, [5.60105, -0.07713]],
  [4501198, [39.93484, -75.03073]],
  [2038541, [47.60605, 126.08481]],
  [577881, [53.96306, 58.39806]],
  [3563504, [20.29879, -76.24511]],
  [1168226, [31.02021, 73.85333]],
  [555980, [53.45446, 56.04149]],
  [743882, [41.37805, 33.77528]],
  [1633308, [-4.02506, 103.24694]],
  [3398331, [-6.35944, -39.29861]],
  [1806218, [32.50611, 120.14278]],
  [2034918, [40.70583, 110.28556]],
  [1857844, [33.55916, 130.20148]],
  [5294810, [35.19807, -111.65127]],
  [2295021, [7.33991, -2.32676]],
  [1715804, [10.52083, 124.02722]],
  [3099112, [52.53481, 17.58259]],
  [2759875, [52.12917, 4.65546]],
  [310892, [37.37389, 36.09611]],
  [3433715, [-29.13995, -59.26343]],
  [1585660, [10.7, 105.11667]],
  [148565, [39.93778, 48.929]],
  [956907, [-27.18871, 25.32931]],
  [584243, [43.42896, 39.92391]],
  [316411, [39.54694, 44.08417]],
  [1789799, [30.53629, 120.68638]],
  [1528121, [42.49068, 78.39362]],
  [2522165, [37.33791, -5.83951]],
  [569955, [52.9771, 49.7086]],
  [1277183, [15.90422, 80.46743]],
  [1294041, [20.0065, 95.54531]],
  [7997918, [26.60675, 87.1478]],
  [4924006, [40.19338, -85.38636]],
  [211734, [2.15127, 21.51672]],
  [1259994, [12.0935, 75.20249]],
  [2952984, [49.89873, 10.90067]],
  [99169, [33.80809, 44.53343]],
  [3028808, [43.55135, 7.01275]],
  [2528659, [33.89469, -6.30649]],
  [3073699, [50.14734, 14.10285]],
  [322673, [39.31927, 26.69341]],
  [6065686, [49.21939, -122.60193]],
  [5410438, [34.16834, -118.60592]],
  [6618486, [54.91525, 23.96831]],
  [1521368, [45.61667, 63.31667]],
  [2637490, [51.50896, -0.3713]],
  [3099654, [53.14821, 18.17036]],
  [5373497, [32.9156, -117.14392]],
  [1167507, [31.75511, 72.91403]],
  [12166275, [10.25874, 105.646]],
  [2300379, [6.60084, 0.4713]],
  [935582, [-21.2766, 55.51766]],
  [4178003, [26.10037, -80.39977]],
  [265533, [37.98333, 23.68333]],
  [1800764, [33.26611, 116.56605]],
  [2034228, [46.83283, 126.47768]],
  [145034, [36.1468, 49.218]],
  [1255121, [12.04161, 75.35927]],
  [1635342, [-7.52, 110.22639]],
  [3444864, [-20.42278, -49.97278]],
  [3563559, [22.50419, -83.51442]],
  [1732846, [2.5148, 102.8158]],
  [3985344, [20.91528, -100.74389]],
  [2994393, [44.84247, -0.64512]],
  [3986172, [23.05888, -109.69771]],
  [3057124, [48.37741, 17.58723]],
  [1648266, [-7.91346, 113.82145]],
  [1175446, [29.6243, 71.91437]],
  [2481246, [35.88548, 3.77236]],
  [3588258, [14.91167, -91.36111]],
  [136987, [30.7949, 50.56457]],
  [2334756, [12.59371, 6.58648]],
  [2294727, [7.59054, -1.93947]],
  [3442057, [-34.7302, -56.21915]],
  [2290582, [5.32556, -4.37685]],
  [139223, [36.655, 51.4204]],
  [343663, [6.03333, 37.55]],
  [3393106, [-2.96667, -47.48333]],
  [1160571, [28.22107, 61.21582]],
  [1563281, [13.09546, 109.32094]],
  [5909629, [45.45008, -73.46583]],
  [1850708, [33.73333, 135.36667]],
  [1256475, [21.34821, 74.88035]],
  [3883167, [-35.84667, -71.59308]],
  [4355585, [39.41427, -77.41054]],
  [3980180, [26.92814, -101.45212]],
  [3832791, [-31.42414, -64.49778]],
  [3458494, [-21.67861, -49.7425]],
  [1295395, [16.76887, 96.24503]],
  [5383465, [38.02798, -121.88468]],
  [3460441, [-21.25472, -48.32222]],
  [1177682, [33.25411, 73.30433]],
  [2698691, [59.33183, 18.04118]],
  [1172993, [30.20379, 66.98723]],
  [355449, [25.29336, 32.55402]],
  [1180983, [31.75, 73.8]],
  [4905211, [42.1103, -88.03424]],
  [1771304, [3.03333, 101.75]],
  [2491050, [36.56471, 3.15434]],
  [1276752, [23.85153, 77.93652]],
  [2235194, [4.75, 11.23333]],
  [3689718, [7.08471, -70.75908]],
  [1846912, [37.01083, 127.27028]],
  [1805884, [35.54278, 118.455]],
  [663118, [46.63333, 27.73333]],
  [2796491, [50.93106, 5.33781]],
  [757718, [54.11175, 22.93087]],
  [2637142, [52.80521, -2.11636]],
  [4715292, [32.8343, -97.2289]],
  [1863451, [35.12861, 136.0976]],
  [4160983, [28.30468, -81.41667]],
  [2036069, [41.80694, 126.90778]],
  [675918, [45.75, 22.9]],
  [3706567, [9.09211, -79.53895]],
  [10295350, [40.86511, 14.26291]],
  [677106, [43.88664, 25.9627]],
  [1163272, [30.97127, 72.48275]],
  [1793230, [26.03033, 119.59739]],
  [1268820, [26.75751, 94.20306]],
  [1634131, [-7.6051, 111.9035]],
  [2789786, [51.21551, 2.927]],
  [2786578, [51.16509, 4.1437]],
  [2033934, [41.29711, 124.12092]],
  [1217474, [38.26746, 67.89886]],
  [1704067, [15.2151, 120.6596]],
  [1328121, [16.29415, 95.39742]],
  [3557378, [22.83727, -82.02641]],
  [2639022, [51.13321, 0.26256]],
  [5406990, [37.90631, -122.06496]],
  [1853081, [36.21667, 138.48333]],
  [1862912, [34.88333, 136.98333]],
  [2753638, [52.6425, 5.05972]],
  [1706609, [13.2997, 123.4386]],
  [2221053, [6.38333, 10.06667]],
  [3698105, [-5.0925, -80.1625]],
  [2487452, [36.45028, 6.26444]],
  [480089, [54.60666, 53.7097]],
  [1256207, [15.76983, 76.75581]],
  [2110959, [37.28333, 140.38333]],
  [2343270, [6.17146, 7.00525]],
  [1002851, [-25.61692, 27.99471]],
  [362973, [27.26881, 31.15232]],
  [3113236, [42.54664, -6.59619]],
  [5989045, [50.66648, -120.3192]],
  [2503878, [34.74488, 8.06024]],
  [2339156, [7.11345, 6.73866]],
  [3445133, [-20.75389, -42.88194]],
  [1276988, [21.33348, 83.61905]],
  [988698, [-34.03627, 23.04713]],
  [2479247, [35.18568, 1.49612]],
  [3448221, [-20.91694, -46.99139]],
  [5127835, [40.9126, -73.83708]],
  [1252908, [20.11128, 77.133]],
  [1253095, [23.69855, 72.5521]],
  [2036959, [41.68917, 122.11278]],
  [4682991, [30.31188, -95.45605]],
  [572525, [51.36713, 42.08494]],
  [2328185, [4.71131, 6.79084]],
  [1856569, [35.06667, 135.98333]],
  [4059102, [31.22323, -85.39049]],
  [490172, [59.8996, 29.08574]],
  [709161, [45.03199, 35.38153]],
  [7302847, [17.58152, 80.67651]],
  [2955272, [49.97704, 9.15214]],
  [3670502, [10.63072, -74.92214]],
  [3099213, [51.66361, 16.0845]],
  [1267227, [21.30328, 70.24861]],
  [1793419, [32.68833, 112.8275]],
  [3686561, [4.9825, -75.60361]],
  [7284833, [47.51482, 19.17028]],
  [1849592, [35.64547, 136.0558]],
  [5377985, [34.22834, -118.53675]],
  [3985710, [22.89088, -109.91238]],
  [1683340, [14.08627, 121.14975]],
  [1268015, [12.30814, 75.10632]],
  [2508102, [35.97108, 6.87374]],
  [3107112, [40.32605, -3.51089]],
  [360526, [27.44023, 30.81712]],
  [5036493, [45.07246, -93.45579]],
  [361029, [31.18065, 31.86497]],
  [1795166, [24.44647, 117.81216]],
  [1275481, [28.29253, 79.80472]],
  [1800519, [32.78017, 117.96378]],
  [4937230, [42.27926, -71.41617]],
  [3929631, [-12.09655, -77.04258]],
  [601594, [40.02216, 52.95517]],
  [1169278, [32.10197, 74.87303]],
  [285811, [29.0825, 48.13028]],
  [1254953, [16.75438, 81.68143]],
  [3361934, [-33.01167, 17.9442]],
  [704422, [48.28198, 37.17585]],
  [565614, [55.94958, 37.50183]],
  [2075432, [-33.32711, 115.64137]],
  [2475612, [34.72224, 5.37845]],
  [211647, [-3.52105, 23.6005]],
  [300399, [37.7482, 27.40614]],
  [5386785, [33.84918, -118.38841]],
  [1857470, [36.51667, 136.56667]],
  [125897, [37.19548, 50.15263]],
  [1277527, [25.83242, 72.24]],
  [3531862, [25.58896, -100.00156]],
  [3117667, [40.47353, -3.87182]],
  [1529363, [41.71707, 82.93064]],
  [4317639, [32.51599, -93.73212]],
  [2866135, [53.56414, 13.27532]],
  [1717911, [11.77528, 124.88611]],
  [3568312, [22.81667, -82.75944]],
  [3980605, [20.3914, -101.19222]],
  [774558, [51.14312, 23.4716]],
  [691374, [48.03876, 38.59685]],
  [2503874, [35.72, -0.545]],
  [692105, [48.08964, 39.65243]],
  [1262463, [10.08818, 77.06239]],
  [565197, [55.75, 37.56667]],
  [3531865, [25.58333, -99.98333]],
  [3589452, [14.57047, -90.49925]],
  [1274821, [11.92312, 76.93949]],
  [1260040, [10.42358, 79.31949]],
  [5410902, [33.88863, -117.81311]],
  [751949, [41.19111, 40.9875]],
  [2038198, [44.7598, 129.67826]],
  [571306, [44.78389, 44.16583]],
  [1800521, [32.45861, 114.04861]],
  [1793286, [34.43278, 116.59111]],
  [3460598, [-23.00583, -46.83889]],
  [1021086, [-25.78842, 31.05319]],
  [1720402, [12.0668, 124.5962]],
  [2026895, [50.91644, 128.47726]],
  [3070291, [50.50301, 13.63617]],
  [2520425, [38.21896, -2.98069]],
  [146054, [35.14867, 33.33384]],
  [3435264, [-32.48463, -58.23217]],
  [3518387, [19.26757, -99.53214]],
  [197745, [-0.45275, 39.64601]],
  [5053358, [44.92386, -92.95938]],
  [3183178, [40.82664, 16.54952]],
  [2037658, [42.64031, 125.51176]],
  [668732, [46.92119, 26.92646]],
  [684802, [46.23175, 27.66907]],
  [679452, [45.88333, 22.9]],
  [1503772, [61.00417, 69.00194]],
  [358269, [27.55642, 30.81057]],
  [1850742, [34.51745, 133.94574]],
  [5251436, [44.81135, -91.49849]],
  [2650096, [53.27875, -2.90134]],
  [1606418, [14.53333, 100.91667]],
  [1505453, [56.11281, 69.49015]],
  [2033128, [45.84955, 123.2973]],
  [2745673, [52.46, 4.65]],
  [4372599, [38.62456, -76.93914]],
  [3050616, [46.36667, 17.8]],
  [2785341, [50.60715, 3.38932]],
  [360923, [25.45101, 30.54653]],
  [138025, [36.1679, 54.34292]],
  [2034691, [42.1859, 127.47895]],
  [2756429, [52.03333, 5.65833]],
  [5341704, [38.54491, -121.74052]],
  [1685577, [14.8787, 120.23194]],
  [1849539, [35.16667, 136.71667]],
  [1191368, [23.21824, 90.35076]],
  [4356188, [39.16261, -76.62469]],
  [2208791, [32.06329, 12.52859]],
  [12070064, [40.85734, 14.2228]],
  [2351979, [6.25375, 6.1942]],
  [5333180, [34.21639, -119.0376]],
  [1269507, [26.91763, 70.90387]],
  [347236, [30.35487, 31.20105]],
  [1801850, [24.40583, 109.60861]],
  [1724767, [8.21528, 126.31639]],
  [693457, [49.23295, 31.88427]],
  [6534228, [41.99362, 12.72238]],
  [2236967, [-6.1349, 12.36894]],
  [360754, [31.15823, 31.936]],
  [1278446, [26.14934, 87.51323]],
  [4355843, [39.14344, -77.20137]],
  [1164987, [31.91023, 70.87757]],
  [3574810, [10.51667, -61.41667]],
  [1791464, [28.37524, 121.38416]],
  [3389609, [-3.66667, -45.38]],
  [3979673, [17.64344, -101.55212]],
  [8299614, [53.68456, -2.2769]],
  [1797120, [25.7294, 119.37469]],
  [1270375, [22.34414, 77.09536]],
  [1855757, [33.59811, 131.1883]],
  [1816026, [30.2297, 115.43346]],
  [2715953, [59.36661, 16.5077]],
  [2289983, [6.64678, -4.70519]],
  [1710519, [6.70407, 121.97117]],
  [1257436, [19.56784, 74.21154]],
  [1218239, [37.38338, 60.50545]],
  [228853, [0.35333, 32.75528]],
  [2035453, [42.03722, 119.28889]],
  [5397765, [37.65466, -122.40775]],
  [232066, [0.18333, 30.08333]],
  [1165635, [25.92539, 68.6228]],
  [3445993, [-28.46667, -49.00694]],
  [540103, [56.14733, 44.19787]],
  [2876865, [51.67369, 8.34482]],
  [1269217, [23.63783, 75.12711]],
  [756867, [51.53131, 20.00855]],
  [1852003, [36.1, 137.96667]],
  [2249222, [15.61867, -16.22436]],
  [303700, [38.2278, 27.96955]],
  [1811305, [30.08268, 113.33346]],
  [256614, [38.0357, 23.75733]],
  [2384770, [3.86781, 17.98923]],
  [2159045, [-32.73308, 151.5574]],
  [295432, [31.73072, 34.99293]],
  [4329753, [29.99409, -90.24174]],
  [1635660, [-7.73333, 110.36667]],
  [3517517, [17.02958, -96.73518]],
  [2959927, [48.83777, 10.0933]],
  [5122477, [40.75566, -73.88541]],
  [1864549, [35.0, 137.03333]],
  [2977921, [47.27506, -2.2179]],
  [761168, [49.78498, 22.76728]],
  [6091314, [45.47675, -73.61432]],
  [1275117, [20.52933, 76.18457]],
  [590031, [59.37722, 28.19028]],
  [4367175, [39.084, -77.15276]],
  [2036033, [42.28473, 125.74544]],
  [4899911, [41.9217, -87.64783]],
  [1263103, [11.29971, 76.93485]],
  [5411015, [39.14045, -121.61691]],
  [1278573, [23.11316, 70.02671]],
  [582750, [54.50503, 37.0674]],
  [5380748, [37.44188, -122.14302]],
  [1789427, [33.78333, 114.51667]],
  [2539134, [30.39467, -9.20897]],
  [1079048, [-25.17838, 46.08722]],
  [2863840, [50.4336, 7.47057]],
  [5288661, [32.32341, -110.9951]],
  [3512128, [18.41538, -70.03317]],
  [5802570, [48.05176, -122.17708]],
  [2820087, [51.53795, 7.68969]],
  [1248749, [7.86, 80.65167]],
  [1150007, [7.55633, 99.61141]],
  [3512067, [18.27964, -70.33185]],
  [102318, [26.48345, 50.04849]],
  [8504950, [60.06964, 30.3487]],
  [824003, [40.41894, 49.96693]],
  [1714766, [14.87827, 120.45221]],
  [7302806, [19.05577, 79.493]],
  [2507646, [36.4575, 4.53494]],
  [5781770, [40.56217, -111.92966]],
  [1274641, [12.65143, 77.20672]],
  [1850892, [36.13333, 137.25]],
  [1258366, [28.08137, 74.61854]],
  [2523665, [39.22935, 9.25004]],
  [2473499, [36.67446, 10.15633]],
  [294421, [31.9467, 34.8903]],
  [1815184, [35.57944, 118.83278]],
  [2035182, [42.08167, 126.60028]],
  [1236854, [6.848, 79.9265]],
  [914959, [-12.84151, 28.09479]],
  [134518, [28.8438, 52.5707]],
  [5265838, [44.02471, -88.54261]],
  [3991043, [25.53928, -100.94742]],
  [1653316, [16.5703, 104.7622]],
  [2305677, [6.11982, 1.19012]],
  [1165744, [34.21599, 71.5548]],
  [4124112, [34.76954, -92.26709]],
  [1804451, [27.68833, 111.66417]],
  [4159050, [25.46872, -80.47756]],
  [758445, [50.58286, 22.05334]],
  [1170951, [34.33023, 73.19679]],
  [1626312, [-7.45806, 109.28806]],
  [5108193, [40.76844, -73.77708]],
  [6180961, [49.02049, -122.79507]],
  [3106054, [40.4, -3.6]],
  [2649258, [51.08169, 1.16734]],
  [1265863, [12.51921, 78.21382]],
  [2853292, [50.4973, 12.13782]],
  [707099, [49.02398, 24.37206]],
  [704617, [51.21526, 24.70867]],
  [1258128, [30.10778, 78.29255]],
  [539147, [57.4368, 56.9593]],
  [1270845, [26.69984, 89.02565]],
  [547475, [52.76019, 32.23935]],
  [2110774, [35.55, 140.36667]],
  [2019309, [56.65836, 124.72499]],
  [2036685, [42.6225, 126.26139]],
  [6141439, [46.51677, -84.33325]],
  [5095445, [40.66871, -74.11431]],
  [6690877, [51.46593, -0.10652]],
  [2646517, [51.46839, -0.36092]],
  [1853338, [35.94647, 136.18498]],
  [5024825, [44.80413, -93.16689]],
  [536162, [54.60256, 52.46087]],
  [2759145, [51.495, 4.29167]],
  [4153132, [26.46146, -80.07282]],
  [1274928, [22.55038, 85.80249]],
  [2271977, [38.79764, -9.3437]],
  [2266977, [38.83091, -9.16845]],
  [461698, [60.01667, 30.35]],
  [3717588, [19.10819, -72.69379]],
  [2042645, [42.25559, 130.28308]],
  [725993, [43.08124, 25.62904]],
  [7302829, [18.96506, 79.47475]],
  [731549, [42.87472, 25.33417]],
  [12157038, [-12.06982, -77.04495]],
  [2038482, [44.91738, 130.52]],
  [320392, [37.72028, 30.29083]],
  [2036713, [41.26472, 125.36667]],
  [1797543, [28.84061, 112.37399]],
  [172082, [36.58662, 37.04628]],
  [2708365, [56.67446, 12.85676]],
  [2005057, [-7.79556, 110.32639]],
  [2036753, [46.2207, 130.525]],
  [1260671, [10.45034, 77.5209]],
  [686578, [46.06667, 23.58333]],
  [558082, [48.06212, 39.9355]],
  [2511619, [39.68333, -0.26667]],
  [1628884, [-2.5315, 112.9496]],
  [1261772, [28.04444, 76.10833]],
  [1787331, [31.865, 120.53889]],
  [753866, [50.72314, 23.25196]],
  [1790100, [32.03244, 118.854]],
  [4633419, [36.31344, -82.35347]],
  [2243271, [-5.55, 12.2]],
  [1272847, [12.1277, 78.15794]],
  [1720561, [13.4117, 121.1803]],
  [1816753, [39.82222, 119.51806]],
  [4755158, [38.63706, -77.31109]],
  [300796, [38.13708, 41.00817]],
  [1164970, [29.88092, 71.29344]],
  [1500607, [58.23583, 92.48278]],
  [4679867, [30.5052, -97.82029]],
  [300371, [39.18554, 27.60945]],
  [169304, [35.36211, 35.92759]],
  [12156822, [43.63388, -79.3772]],
  [1855410, [37.80024, 139.1224]],
  [1638775, [-3.78514, 103.54279]],
  [6241325, [-38.13874, 176.24516]],
  [3105184, [41.22392, 1.72511]],
  [1800675, [35.70694, 117.92639]],
  [5130572, [40.83899, -73.86041]],
  [982899, [-26.152, 26.15968]],
  [4671524, [29.99883, -95.1766]],
  [5044407, [45.5608, -94.16249]],
  [4354265, [39.26733, -76.79831]],
  [1260771, [18.77257, 84.41012]],
  [1790601, [33.84703, 113.4778]],
  [1860748, [35.96536, 140.64474]],
  [5364329, [33.52253, -117.70755]],
  [1276783, [17.87445, 76.94972]],
  [4406831, [38.78394, -90.48123]],
  [4696233, [26.19063, -97.6961]],
  [1494573, [56.44222, 60.18778]],
  [3454818, [-26.22861, -52.67056]],
  [3171366, [45.19205, 9.15917]],
  [3179661, [40.70211, 14.48685]],
  [2111836, [36.43333, 140.01667]],
  [2633485, [53.04664, -2.99132]],
  [1274430, [12.69184, 79.97661]],
  [2037820, [43.30667, 128.51139]],
  [1689832, [15.0809, 120.941]],
  [2473540, [36.87818, 10.32466]],
  [3096372, [50.34984, 18.22606]],
  [1810920, [37.49583, 121.25806]],
  [1817858, [24.41667, 111.51667]],
  [1299237, [16.28543, 95.67882]],
  [1273992, [21.24578, 75.29946]],
  [3454690, [-22.76111, -47.15417]],
  [1728772, [9.59111, 123.12278]],
  [2517816, [36.42764, -5.14589]],
  [1260607, [15.49574, 73.82624]],
  [261779, [39.66486, 20.85189]],
  [3687952, [6.09106, -75.63569]],
  [6113365, [53.9166, -122.75301]],
  [2033423, [41.3975, 119.64083]],
  [1807645, [32.95893, 117.16566]],
  [5391791, [33.42697, -117.61199]],
  [1254744, [8.96003, 77.31525]],
  [3876685, [-33.60627, -70.87649]],
  [5101170, [40.39428, -74.11709]],
  [65785, [2.1381, 45.1212]],
  [2510281, [36.62035, -4.49976]],
  [1606386, [12.66644, 100.90073]],
  [4937232, [42.29732, -71.43701]],
  [1852502, [35.83333, 139.58333]],
  [1813892, [33.63333, 115.18333]],
  [3459035, [-29.46694, -51.96139]],
  [3024297, [48.08078, 7.35584]],
  [622794, [52.3617, 30.3916]],
  [2110793, [38.35361, 140.36972]],
  [2635650, [50.46198, -3.52522]],
  [2234865, [5.14079, 10.52535]],
  [2663536, [56.87767, 14.80906]],
  [1816406, [34.84861, 118.04472]],
  [1252822, [16.77007, 77.13755]],
  [229380, [-0.33379, 31.73409]],
  [527717, [52.96467, 55.93277]],
  [3515044, [19.685, -99.12806]],
  [539283, [52.76667, 55.78333]],
  [1271670, [25.40138, 88.52978]],
  [1258740, [9.37158, 78.83077]],
  [2905560, [51.59638, 7.14387]],
  [5136454, [42.81424, -73.93957]],
  [1269564, [30.78783, 75.47391]],
  [1735077, [5.38333, 100.38333]],
  [2034226, [42.05972, 126.91583]],
  [1176997, [30.45097, 73.69371]],
  [3988392, [32.36044, -117.04645]],
  [3465108, [-28.64397, -53.60633]],
  [1623080, [-8.0657, 111.9025]],
  [2758012, [51.92917, 4.57778]],
  [1279003, [26.4835, 89.52286]],
  [722437, [46.68333, 21.1]],
  [1172318, [32.70138, 73.95746]],
  [492094, [45.2558, 38.1256]],
  [1806097, [26.66167, 119.52278]],
  [8285535, [40.36695, -3.60146]],
  [1907123, [35.97113, 139.6491]],
  [2537545, [33.83186, -4.828]],
  [1811260, [39.83333, 118.11667]],
  [1185181, [25.91719, 89.44595]],
  [1266162, [19.88239, 74.47605]],
  [3407407, [-3.90139, -38.39111]],
  [5415035, [39.92054, -105.08665]],
  [4846834, [42.03471, -93.61994]],
  [5130561, [40.6701, -73.98597]],
  [4279247, [39.04167, -94.72024]],
  [3628966, [10.3258, -72.31343]],
  [1804645, [27.66583, 120.56583]],
  [1429434, [35.31292, 69.51519]],
  [1220747, [37.83667, 68.7775]],
  [1121381, [34.73525, 66.97379]],
  [345149, [14.277, 39.462]],
  [704901, [50.95937, 28.63855]],
  [3437918, [-25.39258, -57.35421]],
  [4106458, [35.0887, -92.4421]],
  [1514192, [41.00329, 71.23791]],
  [1791779, [18.79931, 110.3841]],
  [3665913, [7.83389, -72.47417]],
  [3397893, [-3.14306, -58.44417]],
  [2526488, [32.24634, -8.52941]],
  [697593, [48.04478, 30.84784]],
  [1275321, [26.47703, 90.55815]],
  [2906121, [52.11457, 8.67343]],
  [1803560, [19.90778, 109.68583]],
  [3435010, [-34.49459, -58.62729]],
  [4906500, [41.95781, -87.76506]],
  [2643186, [51.52279, -0.71986]],
  [4911600, [42.03336, -87.73339]],
  [1264071, [20.88555, 76.19932]],
  [1567069, [10.53589, 106.41366]],
  [294098, [32.70056, 35.29722]],
  [2645418, [51.45278, -2.50833]],
  [3461724, [-11.72917, -49.06861]],
  [3173775, [44.03541, 10.13927]],
  [2917540, [51.09102, 6.5827]],
  [833262, [42.01528, 21.44111]],
  [3397967, [-4.27611, -55.98361]],
  [147429, [39.20889, 45.41222]],
  [4152872, [29.21081, -81.02283]],
  [1267853, [20.48273, 77.48857]],
  [1266849, [29.27844, 77.73302]],
  [2812482, [50.9803, 11.32903]],
  [1925943, [35.80167, 128.50083]],
  [2035196, [40.54139, 110.51083]],
  [325103, [38.7489, 42.48007]],
  [7259621, [42.56891, -83.38356]],
  [4174738, [26.21286, -80.24977]],
  [1255955, [23.90806, 87.52773]],
  [1262319, [30.37577, 76.15292]],
  [2498543, [33.68318, 1.01927]],
  [3440781, [-30.90534, -55.55076]],
  [5177568, [41.09978, -80.64952]],
  [2636177, [51.01494, -3.10293]],
  [1684016, [15.5883, 120.9192]],
  [3645981, [10.49581, -66.85367]],
  [3449696, [-28.29917, -54.26306]],
  [5367565, [38.1302, -121.27245]],
  [2515812, [36.16809, -5.34777]],
  [1787901, [28.45433, 104.71498]],
  [3686675, [4.85876, -74.05866]],
  [1239047, [6.9269, 79.9095]],
  [2469140, [35.72917, 10.58082]],
  [1256377, [18.10483, 78.84858]],
  [3840092, [-42.7692, -65.03851]],
  [1171502, [29.80123, 72.17398]],
  [2128787, [40.27178, 140.55756]],
  [1634718, [-8.43333, 114.33333]],
  [350203, [31.39951, 30.41718]],
  [2480368, [34.387, 4.98785]],
  [1815656, [39.70417, 119.15056]],
  [1276023, [19.90717, 83.16697]],
  [1266666, [18.78562, 73.34589]],
  [1267635, [14.81361, 74.12972]],
  [324490, [38.3575, 31.41639]],
  [3988258, [20.05859, -102.71575]],
  [3393008, [-2.62833, -56.73583]],
  [1820906, [4.89035, 114.94006]],
  [1804874, [23.3695, 104.27721]],
  [6941099, [28.05, 81.61667]],
  [1785698, [31.06251, 113.76545]],
  [483029, [45.85472, 40.12528]],
  [2634686, [50.88067, -1.0304]],
  [1861699, [36.71667, 139.68333]],
  [501215, [51.1209, 38.5116]],
  [1165388, [32.26361, 75.16008]],
  [1178456, [28.00437, 69.31569]],
  [2961284, [53.2859, -6.37344]],
  [1123004, [36.73605, 69.53451]],
  [1873172, [37.93472, 125.36194]],
  [480716, [44.1053, 39.0802]],
  [2891524, [50.86991, 6.69691]],
  [2467246, [35.78333, 10.8]],
  [5382496, [33.98307, -118.09673]],
  [5369568, [36.96134, -120.06072]],
  [1267360, [9.18173, 76.50093]],
  [2035325, [46.68469, 126.10595]],
  [8199378, [31.93221, 35.04416]],
  [2320920, [5.93576, 7.0793]],
  [3183299, [44.90924, 8.61007]],
  [5257754, [42.68279, -89.01872]],
  [4881346, [41.57721, -93.71133]],
  [3436728, [-25.3676, -57.59274]],
  [583350, [56.39516, 38.71216]],
  [1855580, [34.19933, 134.60932]],
  [1164896, [29.54299, 67.87726]],
  [2513759, [38.05456, -1.20763]],
  [1275462, [23.0738, 87.31991]],
  [2512862, [39.50263, -0.44079]],
  [256075, [37.92812, 23.70105]],
  [907111, [-15.85601, 27.748]],
  [506043, [55.65928, 37.60404]],
  [706466, [48.04243, 38.14728]],
  [1693077, [6.22167, 125.06398]],
  [1263504, [15.73534, 79.26848]],
  [1276757, [19.32872, 77.15746]],
  [2643266, [53.26023, -2.12564]],
  [3464891, [-18.75639, -44.43083]],
  [1277814, [28.77416, 79.4974]],
  [5374322, [34.00946, -118.10535]],
  [1265579, [10.64667, 76.06695]],
  [1623096, [-7.4737, 112.6505]],
  [3460974, [-10.685, -37.42528]],
  [2655262, [50.78206, -0.67978]],
  [202326, [-2.4846, 28.9075]],
  [3628550, [10.38853, -66.95179]],
  [1259056, [24.44318, 77.19768]],
  [1736278, [6.4414, 100.19862]],
  [2971053, [44.9256, 4.90956]],
  [359576, [30.12303, 31.13571]],
  [293768, [31.92923, 34.86563]],
  [2641520, [54.65983, -5.90858]],
  [2524907, [39.2989, 16.25307]],
  [2984701, [47.99597, -4.09795]],
  [1563241, [21.03433, 106.77049]],
  [3149318, [58.14671, 7.9956]],
  [361329, [28.82306, 30.89903]],
  [1176241, [29.64235, 70.59518]],
  [1586151, [10.46017, 105.63294]],
  [1732742, [1.6561, 103.6032]],
  [2923822, [50.55162, 9.67518]],
  [1787646, [39.36389, 117.06028]],
  [3468570, [-26.77528, -51.015]],
  [1270965, [26.46734, 84.44041]],
  [766027, [53.17806, 22.05935]],
  [172503, [36.07696, 37.37251]],
  [1153081, [14.00412, 99.54832]],
  [1048364, [-24.53333, 32.98333]],
  [3455671, [-20.39484, -43.50517]],
  [3073789, [49.854, 18.54169]],
  [3468100, [-17.74431, -48.62789]],
  [2645753, [52.39836, -0.72571]],
  [1852347, [36.3, 139.98333]],
  [2797670, [50.965, 5.50082]],
  [2043484, [40.96417, 129.32778]],
  [5161262, [41.45282, -82.18237]],
  [1254046, [25.51421, 90.20239]],
  [2511150, [27.91174, -15.54071]],
  [1817701, [22.11321, 107.23592]],
  [3461013, [-11.30417, -41.85583]],
  [4285268, [36.99032, -86.4436]],
  [1608232, [17.87847, 102.742]],
  [5117388, [40.62122, -73.93486]],
  [4353765, [39.25066, -76.52052]],
  [1849904, [33.36667, 130.51667]],
  [2935825, [51.09683, 6.83167]],
  [464625, [53.20356, 45.19227]],
  [3188434, [43.85861, 19.84878]],
  [2654394, [53.16667, -3.08333]],
  [1818004, [23.01279, 104.45081]],
  [3093524, [51.84034, 16.57494]],
  [2950438, [50.95572, 6.63986]],
  [3466696, [-18.16583, -47.94639]],
  [5025219, [44.85469, -93.47079]],
  [3105935, [41.31405, 2.01427]],
  [1736302, [6.26812, 100.42167]],
  [5101879, [40.80427, -74.01208]],
  [291580, [23.65416, 53.70522]],
  [3446138, [-21.69694, -45.25333]],
  [8310183, [37.98468, 23.64711]],
  [2647984, [52.60831, 1.73052]],
  [195272, [0.28422, 34.75229]],
  [2507943, [32.75, -0.58333]],
  [3123329, [41.32784, 2.09472]],
  [1258797, [25.07145, 73.8798]],
  [1799908, [31.35333, 106.06309]],
  [7261268, [33.96772, -118.24438]],
  [4954380, [42.37649, -71.23561]],
  [1283621, [27.5, 83.45]],
  [1864099, [36.24624, 139.07204]],
  [2922586, [52.41371, 9.5899]],
  [1735459, [4.02219, 101.02083]],
  [827329, [55.9686, 43.0912]],
  [5821086, [41.13998, -104.82025]],
  [2496232, [35.06544, 1.04945]],
  [137956, [28.75194, 54.54444]],
  [356806, [31.20365, 30.54908]],
  [1789693, [34.91611, 118.63889]],
  [1792585, [27.32734, 120.21399]],
  [1255004, [17.24849, 77.57698]],
  [662334, [47.2, 23.05]],
  [3445597, [-13.37028, -39.07306]],
  [3924679, [-12.74056, -60.14583]],
  [305359, [37.86014, 27.25713]],
  [10630431, [10.0614, 76.32631]],
  [3080004, [50.04523, 18.70062]],
  [1643981, [-7.76972, 110.29389]],
  [4128894, [36.33202, -94.11854]],
  [2503147, [36.54178, 3.081]],
  [1255361, [29.3215, 73.89979]],
  [1263659, [10.66626, 79.45064]],
  [6071618, [50.03928, -110.67661]],
  [3880107, [-33.68909, -71.21528]],
  [579514, [56.48989, 43.60114]],
  [3622247, [9.99074, -83.03596]],
  [3578959, [16.273, -61.50507]],
  [1527199, [42.84194, 75.30149]],
  [1816256, [38.06667, 116.5666]],
  [1255714, [34.28671, 74.47228]],
  [3140321, [58.85244, 5.73521]],
  [4150066, [25.94065, -80.2456]],
  [1896953, [37.29167, 127.50778]],
  [2494610, [36.41205, 6.59603]],
  [3558771, [21.52536, -78.22579]],
  [585170, [41.19194, 47.17056]],
  [2659836, [46.01008, 8.96004]],
  [695274, [49.01229, 38.37967]],
  [2038446, [46.33167, 132.21111]],
  [2328790, [5.75917, 7.10384]],
  [2112656, [38.91667, 141.13333]],
  [3633444, [10.06077, -72.55212]],
  [5346646, [33.03699, -117.29198]],
  [2348892, [7.06756, 6.2636]],
  [1000543, [-32.25215, 24.53075]],
  [3103709, [51.36883, 19.35671]],
  [1166265, [32.47835, 74.35338]],
  [2638960, [53.34174, -2.73124]],
  [300352, [39.81012, 35.18596]],
  [1278454, [12.66771, 79.28529]],
  [1698030, [14.06694, 120.63181]],
  [1274987, [11.86752, 75.35763]],
  [5116093, [40.72927, -73.98736]],
  [696108, [50.59323, 32.38761]],
  [356989, [30.72816, 31.79697]],
  [1528182, [42.81423, 73.84813]],
  [2656955, [51.14648, 0.87376]],
  [3183455, [40.92298, 14.30935]],
  [4939085, [42.7762, -71.07728]],
  [118367, [35.4846, 51.0829]],
  [3106868, [40.19081, -3.67887]],
  [290594, [25.56473, 55.55517]],
  [557775, [55.61113, 40.65186]],
  [4160610, [26.93422, -80.09421]],
  [570479, [42.819, 47.1192]],
  [756092, [52.19656, 21.17752]],
  [1733697, [4.48178, 118.61119]],
  [1217180, [39.72746, 64.55466]],
  [3466698, [-21.38917, -42.69667]],
  [748870, [40.23861, 33.03222]],
  [1502061, [59.7666, 60.2086]],
  [2470384, [33.87576, 10.85745]],
  [578120, [54.11667, 54.11667]],
  [2464701, [32.92967, 10.45177]],
  [3345283, [51.62202, -3.45544]],
  [2036338, [44.80913, 123.08108]],
  [5052361, [45.15969, -93.34967]],
  [1272694, [26.01856, 89.98564]],
  [1259263, [9.17489, 77.39799]],
  [3020839, [48.92578, 2.44533]],
  [1798733, [23.54218, 110.38946]],
  [1840179, [38.37881, 128.4676]],
  [4166232, [25.89009, -80.18671]],
  [360928, [30.21035, 31.36812]],
  [2990189, [48.84979, 2.56266]],
  [1004109, [-26.48862, 27.49387]],
  [1256593, [25.66472, 76.69616]],
  [1258207, [24.21641, 83.0358]],
  [3053281, [47.39489, 18.91361]],
  [6543862, [50.61669, 3.16664]],
  [1281368, [29.96178, 90.71875]],
  [3981791, [20.80051, -103.46993]],
  [1813016, [34.45528, 113.02806]],
  [1624877, [-2.73353, 107.63477]],
  [2033147, [45.68333, 125.31667]],
  [2130054, [42.97583, 141.56722]],
  [2567529, [39.64167, -0.23889]],
  [1785980, [34.92889, 116.46528]],
  [2303125, [7.4534, -2.58404]],
  [4166274, [27.04422, -82.23593]],
  [922806, [-11.76097, 27.25135]],
  [3006414, [43.10322, 5.87816]],
  [5403783, [36.20773, -119.34734]],
  [616743, [48.63013, 37.55259]],
  [702760, [48.88937, 36.31755]],
  [1907148, [35.73667, 139.32361]],
  [3670730, [6.15515, -75.37371]],
  [2082727, [-2.5964, 140.6324]],
  [499717, [56.26241, 34.32817]],
  [5022025, [45.11997, -93.28773]],
  [2759633, [52.99667, 6.5625]],
  [1272780, [20.65744, 85.59693]],
  [621741, [53.0274, 27.5597]],
  [567990, [55.36311, 50.64244]],
  [2998975, [48.89389, 2.28864]],
  [1650298, [-7.0455, 112.7351]],
  [3939285, [-11.495, -77.20778]],
  [3687964, [4.52949, -75.64091]],
  [1681766, [10.215, 123.03528]],
  [5778352, [40.68689, -111.87549]],
  [2034791, [43.52417, 125.67361]],
  [2035593, [47.51667, 122.88333]],
  [5018651, [45.1608, -93.23495]],
  [1273745, [29.94906, 74.73832]],
  [1785710, [21.99102, 100.73409]],
  [1861641, [35.82756, 137.95378]],
  [1256388, [26.98427, 94.63784]],
  [3589289, [14.33505, -91.02339]],
  [1856243, [35.38333, 136.26667]],
  [3125897, [41.27794, 1.97033]],
  [483019, [59.64511, 33.52937]],
  [3541440, [22.80667, -80.07556]],
  [1699572, [10.24498, 123.7964]],
  [3448011, [-22.92, -42.51028]],
  [1798632, [36.28306, 116.44528]],
  [352733, [31.3529, 27.23725]],
  [1835447, [36.34931, 126.59772]],
  [3445942, [-21.93472, -50.51361]],
  [3042929, [47.09327, 17.91149]],
  [2289049, [9.5928, -5.19449]],
  [1797715, [27.38083, 112.02056]],
  [3053176, [47.50207, 19.07218]],
  [785756, [44.66436, 20.92763]],
  [2473747, [33.89152, 9.79629]],
  [1784647, [29.9695, 121.68753]],
  [2036619, [41.94175, 123.50266]],
  [510225, [59.89565, 29.80145]],
  [121110, [36.701, 45.1413]],
  [227812, [0.44166, 33.17917]],
  [537281, [44.63417, 40.73556]],
  [3463432, [-20.28389, -50.24639]],
  [2530241, [28.43799, -11.10321]],
  [1806651, [32.35473, 116.2939]],
  [1898359, [31.25597, 120.51857]],
  [3042638, [46.84, 16.84389]],
  [2293342, [6.10694, -3.86194]],
  [1800101, [22.8525, 110.82333]],
  [3932145, [-13.71029, -76.20538]],
  [1862198, [36.2378, 139.19023]],
  [1147242, [34.82156, 67.82734]],
  [1813206, [30.08333, 114.95]],
  [2367568, [9.25025, 0.78213]],
  [3496331, [19.39352, -70.52598]],
  [1505429, [54.6366, 83.3045]],
  [2389086, [4.26116, 15.79216]],
  [3447854, [-16.70806, -49.09306]],
  [459279, [56.65, 23.71278]],
  [502011, [56.80097, 59.93028]],
  [534595, [56.00972, 37.48194]],
  [1786587, [32.62945, 116.27013]],
  [3519907, [18.4487, -95.21327]],
  [1831173, [11.99339, 105.4635]],
  [2638419, [54.27966, -0.40443]],
  [1264154, [11.04199, 76.08154]],
  [893697, [-17.36667, 30.2]],
  [360542, [30.61618, 31.73514]],
  [1258352, [25.59474, 79.5666]],
  [2151437, [-23.38032, 150.50595]],
  [12047416, [24.39268, 54.70779]],
  [2469473, [33.35495, 10.50548]],
  [1196292, [22.9443, 90.83005]],
  [9610008, [54.93133, 23.93243]],
  [1798480, [22.75867, 108.48111]],
  [1269328, [16.50461, 75.29146]],
  [2810678, [51.6669, 6.62037]],
  [470444, [55.3175, 42.17444]],
  [4012721, [20.48362, -100.96237]],
  [252664, [39.55493, 21.76837]],
  [128905, [34.7956, 46.9355]],
  [3027422, [45.56628, 5.92079]],
  [1788522, [32.51861, 112.35222]],
  [7284835, [47.45293, 19.14943]],
  [1256047, [14.62072, 74.83554]],
  [565955, [56.34485, 37.52041]],
  [3460699, [-22.43611, -46.82167]],
  [713259, [48.11503, 39.09128]],
  [1849414, [34.75856, 136.13108]],
  [493160, [52.71806, 58.66583]],
  [2036055, [43.30472, 124.32778]],
  [2038665, [50.56667, 123.71667]],
  [5011908, [42.24087, -83.26965]],
  [2522098, [38.70545, -0.47432]],
  [3382160, [4.93333, -52.33333]],
  [1176800, [30.74168, 73.82327]],
  [1790413, [28.8547, 120.73168]],
  [781988, [40.9025, 20.6525]],
  [1702934, [8.1575, 125.12778]],
  [1253080, [11.5183, 79.32411]],
  [2701680, [59.3793, 13.50357]],
  [5019767, [44.76774, -93.27772]],
  [1862373, [33.3213, 130.94098]],
  [5374406, [34.06251, -118.12285]],
  [2633954, [53.3618, -2.73406]],
  [3582677, [17.49952, -88.19756]],
  [3049795, [47.4515, 19.14017]],
  [3012649, [48.82104, 2.27718]],
  [2037611, [44.06219, 131.12075]],
  [1793899, [33.8963, 117.93307]],
  [2891621, [47.72674, 10.31389]],
  [1850207, [35.35, 137.18333]],
  [5334928, [37.6941, -122.08635]],
  [2523650, [36.92574, 14.72443]],
  [8858087, [19.26028, -99.59083]],
  [2657540, [51.24827, -0.76389]],
  [2835482, [48.79947, 9.79809]],
  [1253150, [16.0531, 79.73964]],
  [5099724, [40.73232, -74.23487]],
  [2831948, [48.7, 9.01667]],
  [251948, [37.96105, 23.753]],
  [2990611, [48.8846, 2.26965]],
  [1805563, [37.99806, 114.01556]],
  [2111859, [39.13333, 141.13333]],
  [2745774, [52.02863, 5.55891]],
  [705392, [48.52496, 25.03712]],
  [3182714, [44.90162, 8.20751]],
  [2112077, [36.96667, 140.05]],
  [5388319, [38.79073, -121.23578]],
  [1626758, [-6.9816, 109.1407]],
  [59611, [6.76972, 47.43083]],
  [581357, [67.56414, 33.4031]],
  [362277, [27.93043, 30.83826]],
  [1805798, [29.28993, 106.25001]],
  [2475860, [35.60722, 1.81081]],
  [360773, [26.4775, 31.80394]],
  [5142056, [43.1009, -75.23266]],
  [2357043, [13.58278, -2.42158]],
  [4942939, [42.4251, -71.06616]],
  [5376200, [32.67811, -117.0992]],
  [1497173, [53.3917, 83.9363]],
  [2654187, [53.6, -2.3]],
  [3188402, [44.27513, 19.89821]],
  [3665688, [5.33775, -72.39586]],
  [2015833, [51.37525, 128.14097]],
  [618800, [54.0985, 28.3331]],
  [2719111, [59.34, 17.94]],
  [499161, [46.4747, 41.54114]],
  [2656333, [51.53333, 0.08333]],
  [486968, [54.90083, 38.07083]],
  [3185670, [40.71667, 19.56667]],
  [764849, [50.28709, 21.4239]],
  [8436473, [40.70789, -74.00857]],
  [2290964, [6.99041, -5.7442]],
  [1254420, [13.2563, 76.47768]],
  [1257800, [28.07227, 78.75082]],
  [3120304, [43.33904, -1.78938]],
  [1251081, [8.31223, 80.41306]],
  [3083440, [50.44548, 18.86147]],
  [3162657, [59.83333, 10.43721]],
  [3175786, [42.76296, 11.10941]],
  [307657, [36.49939, 36.35755]],
  [1787144, [34.65918, 109.22921]],
  [1719274, [13.93111, 121.42333]],
  [2034615, [52.33333, 124.73333]],
  [1266154, [15.34522, 76.15478]],
  [4348599, [38.98067, -77.10026]],
  [149027, [-3.28333, 31.52583]],
  [1256087, [30.64321, 76.38421]],
  [6089125, [42.8334, -80.38297]],
  [1273751, [25.88572, 78.33221]],
  [2037494, [41.77639, 126.03194]],
  [1268246, [9.73647, 77.2847]],
  [4265737, [39.4667, -87.41391]],
  [3834601, [-22.51637, -63.80131]],
  [4504621, [39.48623, -75.02573]],
  [4177834, [26.02065, -80.18394]],
  [504935, [43.75741, 44.0297]],
  [1792916, [30.98664, 103.93933]],
  [2971549, [48.30073, 4.08524]],
  [1175560, [30.40472, 71.86269]],
  [1640185, [-7.58333, 112.1]],
  [1788206, [35.16278, 112.865]],
  [3190586, [45.16028, 18.01556]],
  [2786824, [50.58362, 5.50115]],
  [493231, [56.84865, 41.38833]],
  [495394, [54.00513, 37.52194]],
  [1617111, [13.97738, 100.77776]],
  [667303, [45.86667, 25.78333]],
  [5110077, [40.78121, -73.24623]],
  [1278466, [22.88333, 87.78333]],
  [1270099, [23.59893, 72.96602]],
  [5034059, [44.64969, -93.24272]],
  [2035707, [42.11667, 118.78333]],
  [564719, [56.73333, 37.16667]],
  [2296564, [5.92665, -0.98577]],
  [5808079, [47.67399, -122.12151]],
  [2331939, [7.79688, 6.74048]],
  [5333913, [34.20112, -118.59814]],
  [5341145, [37.323, -122.03218]],
  [1816924, [31.55037, 105.99381]],
  [5782476, [40.66772, -111.93883]],
  [1851125, [34.51667, 135.43333]],
  [1848188, [35.89232, 139.84184]],
  [1265331, [27.65312, 74.39993]],
  [2881485, [48.52961, 12.16179]],
  [4542975, [35.33951, -97.4867]],
  [5351549, [33.88835, -118.30896]],
  [1802550, [37.65181, 120.33063]],
  [5382232, [38.23242, -122.63665]],
  [314967, [36.64038, 29.12758]],
  [5179995, [40.10455, -74.95128]],
  [2647074, [52.05684, -2.71482]],
  [3453639, [-21.99611, -47.42583]],
  [7302628, [-34.91119, 138.70735]],
  [2656396, [54.66079, -5.66802]],
  [5423573, [39.06387, -108.55065]],
  [3084093, [50.84378, 16.48859]],
  [1813851, [31.56055, 118.48147]],
  [2362909, [10.63333, -4.76667]],
  [5820705, [42.86663, -106.31308]],
  [2513477, [36.75066, -3.5179]],
  [1275230, [22.40788, 72.89817]],
  [3370352, [-33.56668, 18.48335]],
  [3068927, [49.93866, 17.90257]],
  [4724194, [32.9029, -96.56388]],
  [1266124, [18.82154, 78.71186]],
  [1266509, [29.50671, 78.20613]],
  [1791347, [29.60077, 118.17495]],
  [1785572, [34.06153, 108.62905]],
  [1797038, [33.00111, 114.35417]],
  [3445764, [-16.3575, -46.90611]],
  [1253079, [27.58105, 77.69662]],
  [1650064, [-2.58333, 115.38333]],
  [2581754, [36.44766, 10.7557]],
  [1225018, [8.7514, 80.4971]],
  [1801455, [30.42139, 111.75333]],
  [2844988, [47.85637, 12.12247]],
  [1270171, [14.79354, 75.40448]],
  [2636616, [51.75, -2.2]],
  [1793089, [34.85, 117.33333]],
  [2643044, [51.38132, 1.38617]],
  [3083426, [54.09242, 18.77787]],
  [400769, [8.47738, 47.35971]],
  [5363990, [32.76783, -117.02308]],
  [4168459, [28.55778, -81.4534]],
  [3523513, [20.07082, -97.06078]],
  [3466988, [-19.78972, -42.13917]],
  [285787, [29.36972, 47.97833]],
  [11523810, [-37.81501, 144.96657]],
  [1847968, [35.29483, 139.57812]],
  [512023, [55.78187, 38.65025]],
  [1859094, [33.73333, 130.46667]],
  [1255396, [26.11527, 86.59509]],
  [2236568, [-7.60874, 15.06131]],
  [3815453, [19.12123, -98.16572]],
  [514284, [55.82957, 37.61604]],
  [7280718, [19.38119, -99.13685]],
  [10792382, [2.99361, 101.57255]],
  [485630, [55.85, 37.63333]],
  [5386039, [32.95949, -117.11531]],
  [2653265, [51.48755, -0.16936]],
  [5113481, [40.57788, -73.99403]],
  [1790848, [30.74536, 120.4851]],
  [2377539, [16.61702, -7.25649]],
  [3433522, [-34.5904, -58.62904]],
  [1512339, [41.11202, 69.0471]],
  [1783920, [31.10757, 121.05696]],
  [11838960, [34.18991, -118.44953]],
  [1271175, [24.18622, 86.30875]],
  [6618856, [43.25347, -2.9161]],
  [3121663, [42.35664, -3.67321]],
  [5108815, [40.60177, -73.99403]],
  [2488722, [36.6204, 3.22248]],
  [4838524, [41.53815, -72.80704]],
  [1258290, [14.69971, 76.85241]],
  [1683319, [14.4968, 121.2846]],
  [2643567, [52.76667, -1.2]],
  [1697376, [14.7098, 120.9362]],
  [4004887, [30.71825, -112.15822]],
  [339708, [10.35, 37.73333]],
  [2281606, [6.62103, -5.91144]],
  [5006166, [42.63892, -83.29105]],
  [1735022, [3.4506, 102.4176]],
  [1843841, [35.06125, 126.98746]],
  [2634552, [51.80174, -0.20691]],
  [1806327, [25.48694, 119.19834]],
  [952734, [-29.32816, 31.28954]],
  [90026, [34.88808, 44.63256]],
  [1285899, [20.43189, 96.13875]],
  [4169156, [29.13832, -80.99561]],
  [2485582, [35.96124, 0.91896]],
  [1648084, [-7.53306, 110.59583]],
  [4835654, [41.39593, -72.89677]],
  [3037423, [48.75329, 2.29668]],
  [1261181, [27.23517, 94.10357]],
  [1804609, [23.7, 109.26667]],
  [1799832, [27.92333, 118.53333]],
  [2945358, [52.41667, 12.55]],
  [1728584, [14.81667, 120.86667]],
  [353802, [24.47669, 32.94626]],
  [4156091, [25.77288, -80.34783]],
  [1254274, [29.71332, 75.90441]],
  [2503181, [36.57413, 2.91214]],
  [2842884, [49.98955, 8.42251]],
  [3460730, [-15.24889, -40.24778]],
  [5010978, [42.49698, -82.88881]],
  [264194, [38.01667, 23.83333]],
  [1217007, [39.0375, 65.585]],
  [135298, [37.07645, 57.51009]],
  [3457025, [-21.46778, -47.00472]],
  [2287298, [8.13728, -5.10095]],
  [5655240, [47.50024, -111.30081]],
  [2343641, [6.743, 6.14029]],
  [142872, [34.78241, 48.12012]],
  [3449340, [-28.66056, -56.00444]],
  [1856068, [26.61502, 127.98543]],
  [2757220, [52.95988, 4.75933]],
  [4460162, [35.9132, -79.05584]],
  [2885657, [52.44254, 13.58228]],
  [3177838, [45.13325, 10.02129]],
  [3173287, [41.20036, 16.59905]],
  [2111049, [38.31667, 141.03333]],
  [1626921, [-4.1279, 120.0297]],
  [1271644, [29.78004, 77.26346]],
  [281141, [31.41834, 34.34933]],
  [5942845, [45.88336, -72.48241]],
  [3583102, [13.71889, -89.72417]],
  [1791428, [35.7275, 116.49611]],
  [2639447, [51.65896, -3.44885]],
  [1587976, [10.24147, 106.37585]],
  [1265911, [9.58692, 76.52132]],
  [2691459, [57.6554, 12.01378]],
  [5358736, [33.98168, -118.22507]],
  [3466978, [-28.28389, -52.78639]],
  [3076127, [49.68333, 18.35]],
  [3006767, [46.66974, -1.42757]],
  [1263797, [16.43083, 80.56815]],
  [1216982, [39.90546, 66.26556]],
  [2881085, [51.10821, 6.94831]],
  [262135, [38.01667, 23.75]],
  [1261852, [16.43425, 81.69845]],
  [4151455, [26.25175, -80.17894]],
  [648900, [61.05871, 28.18871]],
  [707688, [46.29914, 30.65529]],
  [4023117, [21.15611, -100.9325]],
  [2037311, [47.91472, 123.50306]],
  [2857798, [48.47377, 7.94495]],
  [3345440, [54.44709, -6.387]],
  [1638562, [-7.11667, 112.41667]],
  [2186280, [-41.27078, 173.28404]],
  [1808106, [27.11, 109.99556]],
  [1257431, [17.62477, 78.08669]],
  [493702, [45.12833, 42.02556]],
  [3670419, [8.94617, -75.44275]],
  [5596475, [43.46658, -112.03414]],
  [543704, [61.25745, 46.64963]],
  [3682292, [10.52066, -74.18504]],
  [1266416, [16.9985, 79.9656]],
  [3512208, [18.45319, -70.7349]],
  [12157056, [-13.52493, -71.96531]],
  [2521570, [28.96302, -13.54769]],
  [260133, [38.46354, 23.60284]],
  [601417, [43.06874, 58.90372]],
  [1253888, [12.80569, 74.86058]],
  [145449, [31.1608, 52.6506]],
  [1702096, [16.0438, 120.4861]],
  [1809003, [34.58167, 119.12889]],
  [4924198, [40.04559, -86.0086]],
  [3641099, [8.6135, -71.65702]],
  [3192224, [44.86833, 13.84806]],
  [1786720, [35.81139, 114.31667]],
  [3835793, [-31.66274, -60.7653]],
  [4207783, [33.9526, -84.54993]],
  [1487281, [56.97047, 60.58219]],
  [172408, [32.70896, 36.56951]],
  [1490256, [69.4865, 88.3972]],
  [2036241, [40.04068, 124.33525]],
  [4303436, [37.77422, -87.11333]],
  [8096217, [33.96358, -117.56418]],
  [973709, [-34.18307, 22.14605]],
  [5007804, [42.48948, -83.14465]],
  [215527, [-6.13791, 24.48179]],
  [1567148, [15.57364, 108.47403]],
  [1263752, [21.12268, 70.11484]],
  [1271049, [28.07837, 80.47054]],
  [111421, [30.81271, 56.56399]],
  [255377, [38.04187, 23.68494]],
  [2826287, [54.30911, 13.0818]],
  [1926020, [33.22375, 132.56001]],
  [1262958, [23.46253, 73.29857]],
  [1159362, [35.244, 60.6225]],
  [3629614, [11.65806, -70.215]],
  [1650670, [-7.61278, 110.78389]],
  [527012, [50.06, 43.2379]],
  [490996, [56.18333, 36.98333]],
  [2111325, [36.86667, 140.03333]],
  [2762372, [46.61028, 13.85583]],
  [3446652, [-24.32389, -50.61556]],
  [3461973, [-14.22333, -42.78139]],
  [2826595, [50.77368, 6.22595]],
  [1727400, [6.42389, 124.77833]],
  [2521139, [36.5961, -4.57267]],
  [1254710, [26.63333, 92.8]],
  [143860, [35.65884, 51.05775]],
  [143073, [32.31001, 54.01747]],
  [1926054, [33.91667, 133.18333]],
  [2249782, [12.8939, -14.94125]],
  [4854529, [42.50056, -90.66457]],
  [2634873, [53.42324, -3.06497]],
  [1153090, [16.48344, 99.52153]],
  [3613321, [14.45139, -87.6375]],
  [1254589, [10.77269, 79.6368]],
  [1793774, [36.95389, 118.10472]],
  [3109402, [41.49109, 2.14079]],
  [4931482, [42.33176, -71.12116]],
  [5004062, [42.48059, -83.47549]],
  [2644319, [50.81137, -0.54078]],
  [1278173, [11.59414, 78.60143]],
  [1863173, [36.16667, 139.53333]],
  [2522876, [38.0176, 12.53617]],
  [4889772, [42.03336, -87.8834]],
  [1769612, [3.21667, 101.31667]],
  [2911271, [52.10397, 9.35623]],
  [3180423, [44.07926, 10.09789]],
  [3675975, [7.83793, -72.5037]],
  [1650119, [-7.88806, 110.32889]],
  [2418362, [11.31823, -12.28332]],
  [2619771, [55.86066, 9.85034]],
  [1254868, [31.45191, 74.92777]],
  [4604183, [35.20453, -89.87398]],
  [790701, [42.62833, 20.89389]],
  [1253944, [10.58806, 77.24779]],
  [5410430, [38.67852, -121.7733]],
  [1264793, [18.75275, 73.40575]],
  [6087844, [49.20678, -122.91092]],
  [1864637, [35.99028, 139.07639]],
  [5777224, [40.39162, -111.85077]],
  [1811114, [27.81117, 114.66805]],
  [2491335, [35.21222, 2.31889]],
  [2805059, [52.43333, 13.25]],
  [1859990, [34.50597, 133.50391]],
  [5144336, [41.03399, -73.76291]],
  [2664740, [59.34571, 18.04985]],
  [477494, [53.98179, 38.17118]],
  [3179806, [40.90751, 14.293]],
  [2871983, [51.44337, 7.77825]],
  [1621655, [-6.9713, 110.0666]],
  [1814919, [29.78062, 104.85224]],
  [2033739, [43.95611, 125.46611]],
  [3836772, [-24.23127, -64.86614]],
  [1003953, [-32.77477, 26.63376]],
  [5324477, [34.13973, -118.03534]],
  [4781530, [38.96872, -77.3411]],
  [2924585, [47.65689, 9.47554]],
  [1256750, [21.54538, 74.47106]],
  [2654755, [51.50583, -3.57722]],
  [1611407, [15.81047, 102.02881]],
  [1261613, [27.85161, 75.27384]],
  [1792692, [31.09407, 105.08731]],
  [1605215, [17.62557, 100.09421]],
  [734330, [41.08499, 23.54757]],
  [1798082, [26.76837, 112.10814]],
  [356945, [31.32977, 31.71507]],
  [3167022, [44.30905, 8.47715]],
  [1261553, [8.60267, 77.00139]],
  [3057140, [48.89452, 18.04436]],
  [3471848, [-19.82028, -40.27333]],
  [782756, [40.61861, 20.78083]],
  [3670218, [12.58317, -81.70636]],
  [11549784, [41.38309, 2.149]],
  [743166, [41.73508, 27.22521]],
  [4166673, [29.1872, -82.14009]],
  [863675, [41.94889, 21.50278]],
  [3194494, [42.7731, 18.94446]],
  [5112861, [43.1859, -76.17243]],
  [1812754, [35.07436, 115.56582]],
  [1630935, [-3.4324, 119.3435]],
  [3353383, [-17.93333, 19.76667]],
  [3634184, [10.49389, -66.82863]],
  [11979238, [33.44001, -112.05805]],
  [359212, [28.49456, 30.80526]],
  [1320944, [16.70728, 96.00168]],
  [285726, [29.31167, 48.07417]],
  [2669772, [59.4439, 18.06872]],
  [2997577, [47.74817, -3.37177]],
  [4172086, [28.80055, -81.27312]],
  [1804892, [35.39472, 116.08833]],
  [3665199, [-9.91333, -63.04083]],
  [1261369, [24.62166, 74.67999]],
  [1239593, [6.9329, 79.8848]],
  [1268680, [23.29908, 72.33362]],
  [2739693, [41.36806, -8.19396]],
  [1840379, [35.41, 127.38583]],
  [2919054, [48.70354, 9.65209]],
  [3080251, [51.85561, 19.40623]],
  [3687025, [7.98654, -75.19349]],
  [3833367, [-54.81084, -68.31591]],
  [4349159, [38.94278, -76.73028]],
  [1734576, [4.95, 100.63333]],
  [1930074, [27.40556, 111.98694]],
  [6111706, [49.26637, -122.76932]],
  [472234, [55.86638, 48.3594]],
  [4922388, [40.48643, -86.1336]],
  [2976043, [48.99739, 2.37821]],
  [1642684, [-6.73361, 108.26278]],
  [4019827, [20.03085, -100.72194]],
  [3164376, [45.31407, 8.85437]],
  [1319573, [9.98238, 98.55034]],
  [2987805, [44.80565, -0.6324]],
  [1861677, [33.27362, 129.87877]],
  [3668454, [4.86806, -75.62139]],
  [1256104, [13.74155, 76.9043]],
  [2479966, [36.60637, 3.08783]],
  [1650815, [-6.7674, 110.8541]],
  [5106160, [40.92538, -74.27654]],
  [2754861, [52.57583, 6.61944]],
  [1329239, [19.36838, 95.21512]],
  [3012621, [48.81568, 2.38487]],
  [1858972, [31.73333, 130.76667]],
  [1685117, [13.35861, 123.73361]],
  [1274243, [13.43512, 77.72787]],
  [3170921, [40.85907, 14.17314]],
  [1277022, [21.12297, 73.11151]],
  [6695754, [62.26537, 74.47906]],
  [699035, [50.72576, 24.16265]],
  [1268990, [22.45384, 86.99497]],
  [3019265, [49.02414, 1.15082]],
  [1626899, [-7.8924, 112.6658]],
  [2655198, [53.46667, -3.01667]],
  [339734, [9.67954, 39.53262]],
  [5393429, [32.83838, -116.97392]],
  [1817952, [29.4, 112.15]],
  [1609032, [14.79808, 100.65397]],
  [2918987, [51.15518, 14.98853]],
  [747764, [40.83889, 31.16389]],
  [6155033, [53.63344, -113.63533]],
  [98629, [31.96257, 44.60075]],
  [3463605, [-29.225, -51.34778]],
  [131962, [34.2721, 47.5861]],
  [3462996, [-26.08111, -53.055]],
  [2670781, [62.39129, 17.3063]],
  [3643031, [10.42411, -66.82581]],
  [3174921, [45.59788, 8.91506]],
  [2970072, [45.69706, 4.88593]],
  [3027883, [49.03645, 2.07613]],
  [1257551, [25.86222, 85.77953]],
  [174448, [34.45226, 40.91854]],
  [1155139, [13.81629, 99.87739]],
  [540251, [44.92934, 37.99117]],
  [1807308, [30.88453, 114.37789]],
  [2034995, [45.71131, 128.58029]],
  [2776488, [48.23333, 16.26667]],
  [2548489, [34.22568, -3.35361]],
  [776175, [52.03238, 23.11652]],
  [1270947, [11.45496, 77.4422]],
  [2033449, [43.29194, 126.00944]],
  [3392167, [-7.07694, -41.46694]],
  [99344, [32.54671, 44.22765]],
  [1270251, [28.72249, 78.28436]],
  [1851606, [34.67534, 133.75091]],
  [3024597, [48.90018, 2.30952]],
  [1809483, [33.66972, 118.18889]],
  [1185166, [24.48888, 91.77075]],
  [4167545, [28.07807, -82.76371]],
  [1687534, [14.1079, 121.14136]],
  [723846, [49.06144, 20.29798]],
  [1563926, [9.94719, 106.34225]],
  [1522751, [43.51672, 68.50463]],
  [2649692, [50.85162, -1.17929]],
  [2642189, [53.74013, -1.59877]],
  [3458645, [-22.59861, -48.80028]],
  [2653232, [51.7002, -0.03026]],
  [1269551, [28.40549, 78.10588]],
  [1256569, [29.32704, 78.57429]],
  [2507901, [35.29749, -1.14037]],
  [1267439, [23.79752, 86.29834]],
  [1309289, [20.18059, 94.87595]],
  [1260792, [16.5167, 81.73]],
  [1784253, [33.70389, 118.67917]],
  [96205, [35.17778, 45.98611]],
  [919544, [-12.36475, 27.82286]],
  [2036237, [46.94985, 128.86849]],
  [1180942, [30.96621, 73.97908]],
  [3698658, [-5.26667, -80.68333]],
  [2129909, [43.23972, 141.35389]],
  [58933, [8.40207, 48.48284]],
  [1252960, [20.05507, 78.95313]],
  [1262092, [26.6827, 88.22001]],
  [4542765, [35.44951, -97.3967]],
  [2540850, [30.91894, -6.89341]],
  [9958118, [39.9512, -75.15923]],
  [2472774, [36.72564, 9.18169]],
  [1254335, [9.3816, 76.57489]],
  [3436725, [-25.09306, -57.52361]],
  [3045190, [47.68501, 16.59049]],
  [3839982, [-38.87588, -62.07359]],
  [321426, [39.12074, 27.18052]],
  [1861280, [26.12647, 127.66918]],
  [1259408, [16.06547, 80.55203]],
  [3405006, [-1.05361, -46.76556]],
  [3407216, [-8.41889, -37.05389]],
  [1214965, [3.1001, 98.4908]],
  [5397841, [33.95015, -118.03917]],
  [1811200, [34.70388, 116.58717]],
  [4913723, [41.57337, -87.78449]],
  [323828, [36.07508, 32.83691]],
  [2034651, [47.24805, 127.09535]],
  [4718711, [30.43937, -97.62]],
  [250582, [32.34289, 36.20804]],
  [1790451, [28.68208, 112.87864]],
  [1863082, [34.31667, 135.61667]],
  [2855598, [52.56926, 13.40186]],
  [1272525, [25.84341, 93.43116]],
  [1255983, [26.59357, 85.4906]],
  [463082, [53.39972, 49.49528]],
  [1853190, [34.32278, 133.8356]],
  [886990, [-18.18527, 31.55193]],
  [1274315, [27.14872, 79.50078]],
  [932614, [-29.82299, 27.23744]],
  [2645724, [52.38819, -2.25]],
  [1216187, [39.05778, 66.83417]],
  [1254481, [27.96282, 79.73827]],
  [3855075, [-35.65662, -63.75682]],
  [632978, [63.096, 21.61577]],
  [5059429, [47.92526, -97.03285]],
  [2756136, [52.77917, 6.90694]],
  [1607512, [14.72526, 100.79536]],
  [1166146, [31.71667, 73.38333]],
  [491250, [55.80202, 37.67159]],
  [3435356, [-34.57365, -58.44924]],
  [491280, [55.8, 37.51667]],
  [302355, [36.26791, 36.56747]],
  [2038529, [43.58333, 127.56667]],
  [5350207, [33.70918, -117.95367]],
  [1177107, [33.99783, 72.93493]],
  [501283, [53.95278, 32.86389]],
  [5394409, [34.23639, -118.48472]],
  [1269784, [15.95923, 76.11351]],
  [4004198, [19.95232, -102.30781]],
  [281145, [31.5464, 34.49514]],
  [1630366, [-7.38806, 109.36389]],
  [5342992, [34.02862, -117.81034]],
  [230893, [3.27833, 32.88667]],
  [349156, [30.31269, 31.32018]],
  [1278026, [22.11186, 85.38684]],
  [2909230, [51.39894, 7.18557]],
  [3882434, [-32.83369, -70.59827]],
  [3129136, [40.65724, -4.69951]],
  [3803651, [10.4883, -66.78608]],
  [1709632, [11.70611, 122.36444]],
  [2303287, [11.0616, -0.24169]],
  [333795, [9.35, 42.8]],
  [1855066, [33.74051, 130.72263]],
  [11549820, [41.43693, 2.19022]],
  [1805515, [23.22806, 110.82611]],
  [2652381, [52.49637, -0.68939]],
  [2186313, [-39.4926, 176.91233]],
  [4904365, [41.71087, -87.75811]],
  [5105608, [40.6976, -74.2632]],
  [3445630, [-28.51222, -50.93389]],
  [4846960, [41.72971, -93.60577]],
  [3991347, [31.31716, -113.53799]],
  [284375, [31.7, 35.11667]],
  [125188, [35.6659, 50.9767]],
  [1816028, [26.85, 104.23333]],
  [1263101, [11.78796, 77.8008]],
  [4933002, [42.1487, -72.60787]],
  [2483936, [35.9441, 5.03107]],
  [1265828, [27.14745, 74.85655]],
  [2491134, [36.56463, 3.5933]],
  [2525448, [37.49025, 14.06216]],
  [1632358, [-7.7679, 112.198]],
  [2651500, [51.44657, 0.21423]],
  [3468158, [-23.35611, -46.87694]],
  [2653558, [54.5735, -5.88472]],
  [2823538, [52.46667, 13.4]],
  [3109256, [40.94808, -4.11839]],
  [721239, [47.90265, 20.37329]],
  [2651286, [53.69076, -1.62907]],
  [1813775, [35.56389, 114.50583]],
  [1839873, [36.3012, 127.568]],
  [1786867, [22.1975, 109.94194]],
  [3591062, [15.72778, -88.59444]],
  [1260448, [11.77662, 79.55269]],
  [2783476, [50.84389, 4.42912]],
  [3628489, [9.00098, -71.92683]],
  [1706188, [16.02182, 120.23194]],
  [3460174, [-15.8025, -43.30889]],
  [572154, [58.38778, 33.91546]],
  [2644204, [55.90288, -3.52261]],
  [2111831, [35.93333, 140.0]],
  [2904795, [51.16818, 6.93093]],
  [1253783, [20.82318, 71.03795]],
  [7284823, [47.49192, 19.01493]],
  [4897543, [41.95336, -87.73645]],
  [2386012, [6.98961, 19.18744]],
  [316284, [36.83917, 36.23025]],
  [7302844, [16.07114, 80.54944]],
  [1266267, [12.15449, 77.11051]],
  [3445014, [-23.02972, -46.97528]],
  [1256620, [20.7932, 76.69921]],
  [3170027, [40.64175, 15.80794]],
  [1815667, [28.7, 115.81667]],
  [8504946, [59.98594, 30.30338]],
  [3463174, [-20.46444, -45.42639]],
  [226234, [1.71464, 33.61113]],
  [2468579, [36.45606, 10.73763]],
  [3574116, [10.27979, -61.4459]],
  [1806591, [31.53429, 119.49]],
  [1130490, [35.94458, 68.71512]],
  [4884597, [41.85059, -87.79367]],
  [1169372, [31.4501, 73.70653]],
  [1253736, [21.74015, 70.28256]],
  [1253918, [28.00311, 79.00821]],
  [360615, [30.19327, 31.13703]],
  [2108502, [-9.43333, 159.95]],
  [3185672, [40.72389, 19.55611]],
  [2636769, [52.45608, -2.14317]],
  [1348747, [22.60291, 88.27751]],
  [1636507, [-1.0163, 103.59331]],
  [2798139, [50.81678, 4.32775]],
  [1273766, [22.18333, 73.43333]],
  [1626754, [-7.71556, 110.35556]],
  [1810553, [34.0175, 119.18861]],
  [3682573, [4.14924, -74.88429]],
  [3526992, [20.17376, -98.05491]],
  [2300372, [7.15181, 0.47362]],
  [784227, [42.55139, 21.90028]],
  [548442, [59.44712, 32.02049]],
  [746666, [40.71501, 29.81824]],
  [2037913, [41.16528, 121.36667]],
  [3694112, [-5.08917, -81.11444]],
  [4223379, [33.88399, -84.51438]],
  [4990512, [42.33698, -83.27326]],
  [400666, [36.43556, 28.22199]],
  [1261086, [24.41863, 82.98797]],
  [2841648, [50.77538, 7.197]],
  [1271079, [29.13777, 76.70247]],
  [5384471, [36.06523, -119.01677]],
  [5102713, [40.49927, -74.39904]],
  [1632861, [-7.91306, 110.29361]],
  [4153471, [25.81954, -80.35533]],
  [1789897, [34.76, 112.97139]],
  [4628735, [36.30477, -86.62]],
  [2787889, [50.94653, 3.12269]],
  [1269154, [20.15975, 85.70742]],
  [563379, [61.81281, 50.72834]],
  [1082243, [-13.2, 49.05]],
  [194160, [1.23889, 35.11194]],
  [11609988, [23.78789, 90.16472]],
  [1262039, [11.22126, 78.16524]],
  [3466174, [-23.66333, -52.605]],
  [1576633, [21.85264, 106.76101]],
  [1797575, [33.25306, 119.91333]],
  [200067, [0.5635, 34.56055]],
  [1271819, [16.23504, 77.79556]],
  [534838, [50.98405, 39.51545]],
  [6332422, [40.81, -73.9625]],
  [699553, [50.59412, 27.6165]],
  [2221504, [4.075, 9.36005]],
  [1260707, [21.52519, 71.82309]],
  [1265310, [27.70827, 80.90256]],
  [1261227, [27.56155, 73.47141]],
  [1718306, [14.3132, 121.0576]],
  [1732891, [3.35, 101.25]],
  [320879, [38.71111, 31.04861]],
  [1261739, [29.59903, 76.11927]],
  [1921372, [39.02861, 121.695]],
  [2038158, [44.26532, 124.00045]],
  [1153557, [10.4957, 99.17971]],
  [2527645, [35.23619, -3.95453]],
  [4488762, [35.93821, -77.79053]],
  [3397909, [-3.49444, -39.57861]],
  [5720727, [44.56457, -123.26204]],
  [2615006, [56.4607, 10.03639]],
  [2928963, [50.81854, 6.27184]],
  [2363534, [6.42611, 1.21333]],
  [772621, [53.82824, 22.36469]],
  [3170116, [40.81563, 14.33716]],
  [3453150, [-22.53611, -55.72556]],
  [364706, [12.9061, 31.2158]],
  [4228147, [30.83334, -83.28032]],
  [1850108, [26.18583, 127.68192]],
  [1269665, [26.26541, 88.18982]],
  [737054, [40.30306, 35.88639]],
  [1267904, [17.28937, 74.18183]],
  [5355180, [36.32745, -119.64568]],
  [1770351, [2.2152, 102.2851]],
  [3463900, [-11.26833, -37.43833]],
  [1705440, [14.9405, 120.6011]],
  [1125444, [36.66757, 65.7529]],
  [1837706, [36.41528, 128.16056]],
  [1861244, [34.25, 135.31667]],
  [2268406, [38.56667, -7.9]],
  [1609610, [12.77972, 101.64831]],
  [3083988, [50.29636, 18.91726]],
  [5416329, [39.37221, -104.85609]],
  [693381, [48.02612, 38.77225]],
  [1803365, [30.41587, 103.46089]],
  [4258313, [39.61366, -86.10665]],
  [235039, [3.02013, 30.91105]],
  [1513604, [41.38555, 60.36408]],
  [172349, [33.61033, 36.3107]],
  [1651461, [-2.41773, 115.24941]],
  [3925212, [-11.43861, -61.44722]],
  [3994616, [30.41552, -107.91166]],
  [4887463, [41.77503, -87.69644]],
  [747538, [41.01766, 28.97438]],
  [5120478, [40.70621, -73.61874]],
  [5378044, [38.10742, -122.5697]],
  [358970, [30.93976, 30.81338]],
  [3686922, [8.88479, -75.79052]],
  [470676, [55.21039, 34.29508]],
  [3441894, [-34.9, -54.95]],
  [1648918, [-7.73379, 113.69785]],
  [617486, [47.76817, 29.01]],
  [3897774, [-33.73257, -70.74281]],
  [5810301, [47.75565, -122.34152]],
  [3449099, [-30.33639, -54.32]],
  [1818051, [27.31944, 110.10306]],
  [1865309, [33.91667, 134.65]],
  [1843082, [37.83101, 127.51059]],
  [2033667, [48.28701, 129.52337]],
  [64460, [4.73583, 45.20361]],
  [3543961, [22.31184, -79.6544]],
  [2977295, [49.84889, 3.28757]],
  [2378736, [16.15027, -13.5037]],
  [761228, [52.17072, 20.81214]],
  [320557, [39.90778, 30.03667]],
  [2618528, [55.4904, 9.47216]],
  [4720039, [29.88519, -93.94233]],
  [6694821, [36.56922, 136.91162]],
  [2111018, [35.8, 140.06667]],
  [1799352, [28.25831, 112.56048]],
  [5177773, [40.12067, -75.11795]],
  [4917592, [40.10532, -85.68025]],
  [1621678, [-7.74306, 110.57944]],
  [1269422, [26.1451, 79.3366]],
  [3993179, [19.51594, -101.60887]],
  [3107775, [43.34943, -4.04785]],
  [3393400, [-3.98417, -38.62028]],
  [1267758, [24.86919, 92.35543]],
  [1852849, [36.07254, 139.72615]],
  [2959223, [51.76338, 7.8887]],
  [4174744, [25.75871, -80.39839]],
  [2187454, [-36.96807, 174.79875]],
  [2647639, [52.44859, -2.04938]],
  [1497951, [56.0104, 90.4011]],
  [1185127, [23.1006, 90.84989]],
  [2653877, [51.27904, 1.07992]],
  [2229761, [2.93725, 9.90765]],
  [2225991, [11.04611, 14.14011]],
  [547560, [55.36352, 37.52984]],
  [1262852, [25.39662, 85.9219]],
  [6690862, [51.36217, -0.09421]],
  [4371582, [39.4015, -76.60191]],
  [997140, [-27.28115, 27.9709]],
  [730496, [42.61667, 25.4]],
  [1698548, [14.31812, 120.76609]],
  [2038421, [46.07556, 127.3984]],
  [1275582, [24.18351, 78.20289]],
  [1252956, [16.36738, 78.06889]],
  [4945588, [42.18343, -72.59953]],
  [2518505, [38.47783, -0.79157]],
  [2499055, [36.68482, 7.75111]],
  [1586288, [10.41115, 106.95474]],
  [4914557, [41.9659, -87.65262]],
  [144794, [36.06667, 50.55]],
  [3445153, [-20.39028, -40.49611]],
  [4172131, [27.33643, -82.53065]],
  [3191376, [44.74667, 19.69]],
  [1185293, [23.15509, 89.49515]],
  [3948642, [-13.63389, -72.88139]],
  [1610469, [16.43281, 103.50658]],
  [6853140, [57.6198, 63.0784]],
  [1257565, [17.05675, 82.17639]],
  [2527089, [29.69742, -9.73162]],
  [500019, [59.8393, 30.49873]],
  [6146279, [53.51684, -113.3187]],
  [1647179, [-7.1475, 111.5906]],
  [478724, [59.83889, 30.17528]],
  [3124794, [40.63506, -4.00486]],
  [3448351, [-22.83917, -42.10278]],
  [1855753, [35.48333, 137.5]],
  [225284, [11.15583, 42.7125]],
  [6825277, [24.81878, 103.33237]],
  [507338, [55.80797, 37.45814]],
  [11762608, [34.80059, 126.69669]],
  [465057, [55.73333, 37.63333]],
  [1510205, [55.6, 86.2]],
  [3618929, [13.09103, -86.00234]],
  [711660, [50.35269, 30.95501]],
  [1278580, [21.16516, 77.3091]],
  [349715, [30.9616, 31.24069]],
  [1272733, [22.72732, 72.44128]],
  [2483757, [36.73587, 3.34018]],
  [2036734, [43.55833, 128.02389]],
  [943960, [-27.20841, 26.94855]],
  [6138501, [45.78036, -74.00365]],
  [5114418, [40.67705, -73.89125]],
  [3404117, [-6.45833, -37.09778]],
  [1254346, [10.91368, 75.92118]],
  [1506271, [51.96056, 85.91892]],
  [787487, [41.11722, 20.80194]],
  [3988333, [27.85591, -101.11738]],
  [2953358, [52.0862, 8.74434]],
  [3173721, [40.66599, 16.60463]],
  [2928396, [50.66057, 6.78722]],
  [3167731, [41.68564, 15.38148]],
  [4890701, [41.98337, -87.66395]],
  [109380, [28.43905, 48.49132]],
  [5356868, [34.12834, -117.20865]],
  [1803551, [22.70722, 110.34917]],
  [3447785, [-22.74389, -43.7075]],
  [7303786, [48.41648, -71.24884]],
  [3404558, [-6.98111, -34.83389]],
  [921815, [-12.78234, 45.22878]],
  [2872504, [51.25268, 6.68807]],
  [3439317, [-25.47104, -56.01603]],
  [2035836, [49.6, 117.43333]],
  [7302815, [5.28883, 115.26924]],
  [4903024, [42.06642, -87.93729]],
  [2806914, [52.16442, 10.54095]],
  [1851368, [36.03799, 138.11308]],
  [2263827, [38.76613, -9.32804]],
  [2036106, [41.50611, 122.72417]],
  [2897216, [50.87079, 6.86761]],
  [349114, [31.19688, 31.5243]],
  [2656235, [51.39979, -3.2838]],
  [2503755, [36.54222, 3.95306]],
  [2990355, [46.32313, -0.45877]],
  [1271346, [22.66244, 87.73399]],
  [1684681, [6.6925, 124.67639]],
  [2265467, [38.79269, -9.1838]],
  [5338783, [34.0739, -117.31365]],
  [3058780, [49.06651, 18.92399]],
  [650946, [60.4664, 26.94582]],
  [11979227, [33.47937, -112.07823]],
  [3457952, [-20.25806, -42.03361]],
  [664460, [46.56667, 23.78333]],
  [3033791, [47.64218, 6.85385]],
  [3940002, [-11.10667, -77.605]],
  [173480, [35.01982, 40.45154]],
  [1256854, [21.68562, 75.09622]],
  [577893, [44.77127, 39.87879]],
  [3684917, [9.00114, -73.97581]],
  [5501344, [39.1638, -119.7674]],
  [3934608, [-17.19832, -70.93567]],
  [3515358, [19.02867, -98.27698]],
  [3861344, [-34.89566, -60.01667]],
  [1862612, [36.85609, 136.9885]],
  [1254343, [9.48333, 77.83333]],
  [3590219, [14.9449, -91.4431]],
  [3454235, [-21.41972, -50.0775]],
  [110325, [24.50772, 44.39237]],
  [1214724, [4.4683, 97.9683]],
  [1799552, [29.35955, 112.40243]],
  [5604045, [42.8713, -112.44553]],
  [4148708, [27.49893, -82.57482]],
  [2302821, [10.78556, -0.85139]],
  [3682281, [4.71638, -74.21195]],
  [1185138, [22.57965, 89.97521]],
  [725905, [43.99159, 22.88236]],
  [4908033, [42.00864, -87.66672]],
  [4169130, [26.97617, -82.09064]],
  [4903780, [40.5142, -88.99063]],
  [3038334, [41.91886, 8.73812]],
  [3556437, [22.52694, -81.12861]],
  [288899, [22.97886, 57.3047]],
  [2021618, [50.0979, 118.0369]],
  [1037390, [-15.03466, 40.73447]],
  [1256382, [23.91783, 72.37212]],
  [1272670, [30.36846, 75.86791]],
  [4733624, [30.07994, -95.41716]],
  [3437444, [-25.56384, -54.61097]],
  [4145805, [25.81454, -80.22394]],
  [1854384, [36.05659, 138.0451]],
  [2776421, [48.18623, 16.2965]],
  [2222230, [2.93333, 11.98333]],
  [5808276, [46.28569, -119.28446]],
  [1256369, [24.4038, 81.87954]],
  [2953504, [48.7606, 8.23975]],
  [2650396, [50.96667, -1.35]],
  [3518221, [19.28428, -99.73394]],
  [4689550, [32.83707, -97.08195]],
  [539061, [59.85278, 30.35667]],
  [358115, [26.12242, 32.46749]],
  [3429790, [-26.40843, -54.69463]],
  [2742611, [40.64427, -8.64554]],
  [4377664, [39.01695, -94.28161]],
  [4154047, [30.42881, -87.17997]],
  [261604, [37.03913, 22.11265]],
  [459201, [56.968, 23.77038]],
  [3456826, [-29.68861, -51.46111]],
  [3179075, [41.26523, 15.89559]],
  [3129857, [40.03108, -3.60246]],
  [5354819, [33.99307, -117.96868]],
  [735861, [40.93959, 24.40687]],
  [1851357, [36.65, 138.31667]],
  [2500904, [34.89638, 3.48543]],
  [3545841, [21.54585, -77.26504]],
  [2970777, [47.65688, -2.76205]],
  [2834498, [50.04937, 10.22175]],
  [1252840, [26.09717, 94.25817]],
  [2392505, [9.94009, 3.21075]],
  [472072, [55.88333, 37.58333]],
  [5130334, [40.67677, -73.84375]],
  [2864054, [49.35009, 8.13886]],
  [3165322, [41.27733, 16.41011]],
  [2975921, [48.9482, 2.19169]],
  [5964215, [45.40008, -72.73243]],
  [2996568, [48.81171, 2.43945]],
  [1268341, [23.21944, 88.36295]],
  [3436199, [-36.77698, -59.85854]],
  [482965, [45.61694, 38.94528]],
  [496278, [69.06889, 33.41622]],
  [1277091, [18.15174, 74.57767]],
  [260114, [35.51124, 24.02921]],
  [3902949, [-17.33801, -66.21579]],
  [565381, [55.4413, 37.75367]],
  [6640032, [40.70935, -73.81529]],
  [140521, [35.8668, 47.60506]],
  [120972, [35.308, 51.7267]],
  [356933, [26.05501, 32.16274]],
  [5335006, [33.77974, -116.46529]],
  [2290462, [7.25957, -8.15498]],
  [470252, [57.59125, 34.56453]],
  [707292, [49.20875, 37.24849]],
  [5153207, [41.36838, -82.10765]],
  [2851746, [50.99965, 6.80632]],
  [1587919, [20.07806, 105.86028]],
  [8858088, [25.62944, -100.18778]],
  [762909, [53.08621, 21.57566]],
  [758390, [51.0374, 21.07126]],
  [2453662, [13.68427, -6.07352]],
  [2473744, [36.4, 10.61667]],
  [1591527, [21.27307, 106.1946]],
  [933099, [-21.97895, 27.84296]],
  [4916288, [41.86614, -88.10701]],
  [5417737, [39.80832, -104.93387]],
  [2657697, [51.50901, -0.2762]],
  [3625710, [8.0162, -62.40561]],
  [3672110, [1.85371, -76.05071]],
  [1145352, [35.01361, 69.17139]],
  [3671315, [3.23114, -76.41668]],
  [1268111, [15.21542, 79.9039]],
  [3032213, [47.59432, 1.32912]],
  [3182007, [41.24264, 16.50104]],
  [1255449, [30.12883, 75.79943]],
  [5099133, [40.74399, -74.03236]],
  [5407529, [36.91023, -121.75689]],
  [7302810, [17.36687, 78.5242]],
  [3530531, [19.45229, -96.96148]],
  [1257001, [16.39381, 80.15221]],
  [582182, [44.89497, 37.31623]],
  [695379, [48.08331, 39.37764]],
  [3183541, [40.94477, 14.3714]],
  [3177363, [40.80783, 14.35012]],
  [367308, [16.6915, 33.4341]],
  [709276, [47.49048, 34.66199]],
  [2548818, [35.84906, -5.35747]],
  [5301388, [34.4839, -114.32245]],
  [3521922, [20.44655, -97.32494]],
  [1726339, [18.0554, 120.56489]],
  [1270032, [13.94455, 76.61723]],
  [571159, [53.6554, 52.442]],
  [1274862, [14.318, 76.65165]],
  [3619267, [12.66348, -87.16663]],
  [304612, [38.60754, 27.06938]],
  [6183590, [43.76657, -79.22773]],
  [2654938, [51.87819, 0.55292]],
  [3938415, [-17.63185, -71.34108]],
  [3029974, [45.1589, 1.53326]],
  [1263427, [25.35417, 85.03195]],
  [3036938, [43.67681, 4.63031]],
  [4832294, [42.40843, -71.01199]],
  [128831, [34.5043, 47.9653]],
  [1606033, [14.47418, 100.12218]],
  [1261110, [16.78854, 80.84593]],
  [3838793, [-32.17301, -64.11405]],
  [655808, [62.60118, 29.76316]],
  [3598119, [15.47083, -90.37083]],
  [8334622, [52.56681, 13.51255]],
  [1513966, [40.48972, 68.78417]],
  [5106292, [40.78788, -74.01431]],
  [3398115, [-8.39889, -35.06389]],
  [1168307, [32.26286, 74.66327]],
  [3431057, [-34.50316, -58.70596]],
  [1256693, [23.42637, 76.27775]],
  [1259124, [12.75975, 75.20169]],
  [5411046, [34.03363, -117.04309]],
  [3467684, [-22.73944, -45.59139]],
  [2482090, [36.12868, 7.53376]],
  [1267616, [12.49838, 74.98959]],
  [3026204, [46.81248, 1.69362]],
  [2410763, [0.33654, 6.72732]],
  [735914, [40.26956, 22.50608]],
  [1628899, [-7.1872, 113.2394]],
  [1262678, [26.08866, 83.29088]],
  [5352214, [37.00578, -121.56828]],
  [1856456, [34.96545, 135.70415]],
  [562321, [55.73321, 38.46458]],
  [1259811, [17.99113, 74.43177]],
  [4168930, [28.14029, -81.45841]],
  [4168228, [30.42131, -87.21691]],
  [1636308, [-0.86291, 134.06402]],
  [1257459, [27.06989, 80.51497]],
  [3691348, [-9.29532, -75.99574]],
  [3468014, [-30.85111, -51.81222]],
  [739634, [41.07393, 28.24644]],
  [3025053, [47.05893, -0.87974]],
  [3080526, [50.48766, 19.41679]],
  [172946, [34.92667, 36.73241]],
  [1257851, [14.16498, 75.02901]],
  [6395804, [34.8262, 126.10863]],
  [107781, [26.29945, 44.81542]],
  [2913761, [51.02608, 7.56473]],
  [1276832, [22.36544, 88.4325]],
  [3520235, [21.93115, -99.99488]],
  [2511032, [38.3964, -0.5255]],
  [1624987, [-3.93728, 103.80465]],
  [2749450, [51.645, 4.85972]],
  [1283368, [28.2058, 81.34532]],
  [3567869, [20.96116, -75.722]],
  [1841149, [35.49333, 128.74889]],
  [146970, [39.8177, 46.7528]],
  [3641275, [9.78709, -69.79294]],
  [3017253, [43.43325, 6.73555]],
  [1566346, [20.45, 106.34002]],
  [1863023, [35.83314, 139.7425]],
  [3527023, [17.80787, -97.77956]],
  [673634, [46.16667, 24.35]],
  [1264839, [23.43305, 84.67992]],
  [3180445, [44.78237, 10.8777]],
  [3567669, [20.16635, -76.94291]],
  [4634662, [36.54843, -82.56182]],
  [155101, [-8.85, 34.83333]],
  [2494029, [31.68041, 6.07286]],
  [3058000, [48.77446, 18.6275]],
  [3175537, [44.35916, 11.7132]],
  [736928, [40.84995, 25.87644]],
  [1273232, [19.94404, 73.83441]],
  [2525628, [38.07892, 13.51237]],
  [3430708, [-34.65145, -59.43068]],
  [2815330, [48.83241, 9.31641]],
  [3401548, [-5.17833, -40.6775]],
  [3617459, [11.68758, -84.45616]],
  [4167519, [26.82339, -80.13865]],
  [2988621, [48.89437, 2.40935]],
  [534701, [52.42534, 37.60689]],
  [3174092, [41.63065, 15.91876]],
  [1150275, [13.9, 99.76667]],
  [1694290, [14.11667, 120.96667]],
  [2500282, [33.53388, 5.99306]],
  [160833, [-3.04877, 31.88542]],
  [1220253, [39.9142, 69.00328]],
  [1275406, [18.57366, 83.35925]],
  [1274237, [20.35046, 76.25774]],
  [3182640, [40.97259, 14.20745]],
  [1706361, [14.56194, 120.59833]],
  [11044505, [19.82189, -101.78928]],
  [2861934, [52.43081, 7.06833]],
  [964406, [-30.74137, 30.45499]],
  [325336, [38.79911, 42.73159]],
  [1266217, [25.99451, 79.15127]],
  [1858498, [34.0, 131.86667]],
  [4838652, [41.22232, -73.0565]],
  [134217, [29.5791, 50.517]],
  [300640, [37.51393, 42.45432]],
  [1732738, [1.7381, 103.8999]],
  [5342485, [35.76884, -119.24705]],
  [2917788, [54.09311, 13.38786]],
  [290247, [26.11528, 50.50694]],
  [1785805, [29.34936, 110.54407]],
  [8581467, [44.5647, 27.3633]],
  [1259508, [17.1168, 82.25284]],
  [468866, [55.06667, 32.69639]],
  [4472370, [35.41069, -80.84285]],
  [1278935, [16.57868, 82.00609]],
  [542327, [58.0796, 55.7552]],
  [315468, [38.26899, 39.75446]],
  [5102578, [40.50677, -74.26542]],
  [5395244, [34.15112, -118.44925]],
  [2939658, [53.86828, 8.69902]],
  [1521370, [50.34413, 83.51287]],
  [1254054, [17.35905, 82.54607]],
  [2809889, [50.56109, 8.50495]],
  [140046, [31.96523, 51.2873]],
  [1256343, [21.71134, 71.96179]],
  [1272606, [18.54829, 77.57695]],
  [69500, [14.1951, 43.31518]],
  [4446675, [34.98898, -90.01259]],
  [7279597, [22.10194, 85.37752]],
  [2271680, [38.62961, -9.11557]],
  [1624058, [-4.87898, 105.21818]],
  [3633341, [10.5945, -66.95624]],
  [3646169, [10.48666, -66.73799]],
  [4763231, [38.44957, -78.86892]],
  [906054, [-15.24835, 23.12741]],
  [3629710, [5.66049, -67.58343]],
  [1275435, [27.49581, 80.99618]],
  [2204506, [-17.61686, 177.45049]],
  [1291193, [16.75998, 96.52498]],
  [5383527, [33.87224, -117.87034]],
  [1266073, [27.79449, 77.4368]],
  [4274356, [38.95362, -94.73357]],
  [2794914, [50.87309, 4.33419]],
  [4685524, [32.58986, -96.85695]],
  [3116553, [41.54026, 2.21306]],
  [1822768, [12.53878, 103.9192]],
  [3663529, [-3.29972, -60.62056]],
  [3133895, [69.6489, 18.95508]],
  [2993002, [44.01759, 1.3542]],
  [5811456, [47.14121, -122.27012]],
  [1317397, [16.44502, 95.72373]],
  [3026613, [49.7685, 4.72487]],
  [3190966, [45.83111, 16.11639]],
  [3038261, [43.9298, 2.148]],
  [115781, [30.1165, 55.1186]],
  [3472248, [-20.89611, -51.37944]],
  [3470709, [-15.89, -52.25667]],
  [2649911, [51.36969, -0.36693]],
  [4919987, [41.68199, -85.97667]],
  [5957776, [45.94541, -66.66558]],
  [2950344, [51.61633, 7.64451]],
  [1907125, [35.87266, 139.51369]],
  [1257951, [30.96896, 76.52695]],
  [5258957, [43.80136, -91.23958]],
  [2112571, [36.18333, 140.26667]],
  [1527592, [41.42866, 75.99111]],
  [1273574, [15.26667, 74.61667]],
  [4904381, [41.88503, -87.7845]],
  [2490183, [34.86158, -1.33935]],
  [114593, [32.1942, 48.2436]],
  [3170206, [40.8542, 14.33038]],
  [2508228, [36.26405, 1.9679]],
  [4386802, [38.78922, -90.32261]],
  [562319, [55.96056, 38.04556]],
  [5809402, [47.64177, -122.0804]],
  [1702442, [6.27917, 125.28167]],
  [6354957, [-25.28762, 152.76936]],
  [3516225, [18.55462, -99.60592]],
  [1485439, [53.7074, 84.9493]],
  [1117652, [7.88481, 98.40008]],
  [299545, [39.54237, 29.49866]],
  [4018348, [20.70519, -102.34635]],
  [733618, [42.01667, 24.86667]],
  [2511440, [27.92481, -15.57329]],
  [3140084, [59.28391, 11.10962]],
  [1185120, [22.51409, 91.45491]],
  [3209584, [53.47778, -3.03333]],
  [4896075, [42.04281, -88.0798]],
  [1496476, [62.14056, 65.39361]],
  [2639506, [51.23736, -0.20582]],
  [1127110, [36.21544, 65.93249]],
  [2973385, [43.23407, 0.07139]],
  [1260222, [18.78392, 83.42569]],
  [4883012, [41.96836, -87.72339]],
  [3017910, [48.85442, 2.48268]],
  [2282827, [6.38309, -5.41759]],
  [548652, [56.86667, 37.35]],
  [2790595, [50.74497, 3.20639]],
  [285728, [29.14583, 48.09472]],
  [1821935, [11.48333, 104.95]],
  [3120514, [42.13615, -0.4087]],
  [3077244, [50.78215, 14.21478]],
  [3359638, [-22.9575, 14.50528]],
  [884141, [-17.88333, 30.7]],
  [3716667, [18.65297, -72.09391]],
  [5352439, [34.13612, -117.86534]],
  [258620, [38.9, 22.43333]],
  [1336133, [22.07639, 92.04955]],
  [583589, [55.85, 37.48333]],
  [3815324, [19.31451, -97.9252]],
  [3470003, [-27.49417, -48.65556]],
  [199989, [0.46005, 34.11169]],
  [3470324, [-20.89111, -47.585]],
  [2650657, [51.88571, -0.52288]],
  [3451152, [-27.21417, -49.64306]],
  [1278432, [12.90569, 79.31897]],
  [711369, [48.511, 38.67222]],
  [1640296, [-8.1303, 112.5727]],
  [5133271, [40.72677, -73.74152]],
  [3582883, [13.35, -88.45]],
  [4184530, [33.85844, -84.3402]],
  [1177446, [25.12163, 62.32541]],
  [3019256, [48.6328, 2.44049]],
  [1269027, [24.59633, 76.16499]],
  [3563317, [22.98212, -80.58556]],
  [124193, [32.25014, 54.01658]],
  [71334, [16.94021, 43.76393]],
  [2953436, [50.22683, 8.61816]],
  [3440034, [-31.71694, -55.98111]],
  [2808559, [51.26371, 6.54734]],
  [2512177, [38.68712, -4.10734]],
  [358095, [30.75438, 31.44016]],
  [1259841, [22.47681, 72.79995]],
  [4392768, [37.08423, -94.51328]],
  [1648568, [-6.9698, 111.4186]],
  [3073803, [50.23271, 12.87117]],
  [1253807, [20.85396, 79.32466]],
  [741045, [40.48917, 29.30889]],
  [4535961, [36.39559, -97.87839]],
  [2520493, [39.5657, 2.50621]],
  [1276084, [21.735, 81.94711]],
  [1268383, [25.63442, 88.32665]],
  [1261727, [26.30473, 74.73364]],
  [2338401, [7.68287, 4.81769]],
  [2111884, [39.64691, 141.94057]],
  [473778, [55.55239, 37.70967]],
  [3654870, [-0.93521, -78.61554]],
  [2477255, [36.11653, 6.35434]],
  [4148533, [26.33981, -81.7787]],
  [5122331, [43.2134, -77.57972]],
  [2925535, [52.34714, 14.55062]],
  [5587698, [43.66294, -116.68736]],
  [3181995, [41.11006, 16.69086]],
  [3453546, [-17.345, -44.94194]],
  [1863713, [35.3108, 139.81877]],
  [2642214, [54.06835, -2.86108]],
  [1269819, [11.58624, 77.83891]],
  [3703227, [9.06667, -79.43333]],
  [1271083, [26.43278, 78.44205]],
  [6690794, [41.40408, 2.17332]],
  [120931, [34.46109, 45.86264]],
  [2651715, [55.94685, -3.99051]],
  [1851273, [33.63333, 130.8]],
  [4985153, [42.3173, -85.17816]],
  [1608900, [16.18483, 103.30067]],
  [1267187, [25.57898, 85.04564]],
  [1848096, [36.3, 139.88333]],
  [359710, [30.54323, 31.78504]],
  [3628952, [7.70131, -72.35569]],
  [2639545, [51.24048, -0.17044]],
  [1295301, [20.4475, 99.88083]],
  [6822217, [35.48199, 137.02166]],
  [1310362, [16.73148, 95.65441]],
  [113508, [36.4009, 47.1133]],
  [3625929, [9.05806, -62.05]],
  [2930596, [53.36745, 7.20778]],
  [1078446, [-17.5876, 48.22391]],
  [347612, [30.6798, 30.94364]],
  [3129636, [40.30076, -3.43722]],
  [1907309, [35.14, 139.61917]],
  [3792383, [10.03672, -73.23558]],
  [2646557, [51.06314, -0.32757]],
  [5288636, [32.8795, -111.75735]],
  [3461519, [-28.71333, -49.3]],
  [4912013, [41.76198, -87.57783]],
  [5127670, [40.80899, -73.92291]],
  [4175179, [28.93408, -81.95994]],
  [5069297, [40.92501, -98.34201]],
  [1274119, [17.53339, 73.50935]],
  [3405738, [-8.23333, -35.79694]],
  [621754, [53.0869, 25.3163]],
  [2470088, [36.50114, 8.78024]],
  [4694568, [32.93429, -97.07807]],
  [3024783, [48.80299, 2.26692]],
  [1699323, [7.81528, 126.05444]],
  [2863941, [48.39279, 10.01112]],
  [4843811, [41.18454, -73.13317]],
  [1185148, [22.29543, 91.979]],
  [4998018, [42.86947, -85.64475]],
  [3927758, [-11.41899, -75.68992]],
  [2014927, [54.56358, 100.58143]],
  [2877088, [52.52143, 7.31845]],
  [3164603, [45.43713, 12.33265]],
  [2807748, [53.6, 10.0]],
  [3438819, [-25.44444, -56.44028]],
  [2271071, [38.66314, -9.0724]],
  [7160204, [41.22374, -73.06164]],
  [3027487, [48.95393, 4.36724]],
  [3515942, [19.74423, -99.18113]],
  [5756758, [45.43123, -122.77149]],
  [4833403, [41.78232, -72.61203]],
  [2483649, [35.06196, -1.43362]],
  [3064288, [50.6404, 13.82451]],
  [5102720, [40.63371, -74.40737]],
  [609123, [46.95307, 54.01978]],
  [6559559, [49.00835, 33.62926]],
  [4769125, [39.11566, -77.5636]],
  [2503852, [35.635, 3.225]],
  [2929671, [50.81481, 6.79387]],
  [2509509, [39.9383, -0.10087]],
  [3387786, [-7.99194, -38.29833]],
  [294117, [33.00892, 35.09814]],
  [2610613, [55.70927, 9.5357]],
  [355648, [29.35896, 30.68061]],
  [3398450, [-4.1, -38.48333]],
  [2596934, [7.96111, -6.67306]],
  [709248, [50.0767, 29.9177]],
  [4151871, [25.72149, -80.26838]],
  [2866110, [51.13434, 6.63857]],
  [1645976, [-6.9053, 109.5347]],
  [301010, [37.41926, 31.84527]],
  [555111, [55.97111, 37.92083]],
  [182701, [-0.42013, 36.94759]],
  [1256269, [20.30303, 75.65284]],
  [2637126, [51.43092, -0.50606]],
  [1264647, [16.47635, 79.43533]],
  [128008, [37.61837, 48.52928]],
  [3441702, [-32.37028, -54.1675]],
  [163808, [34.5624, 38.28402]],
  [3450964, [-23.30972, -51.36917]],
  [3556268, [21.16139, -76.47919]],
  [729794, [41.65, 25.36667]],
  [790295, [41.79601, 20.90819]],
  [1851883, [36.01839, 139.66034]],
  [160892, [-2.02153, 33.87109]],
  [1254069, [27.2146, 78.23683]],
  [753142, [52.26896, 20.98644]],
  [3533426, [17.94979, -94.91386]],
  [1514387, [41.69111, 60.7525]],
  [11549783, [41.40315, 2.15687]],
  [1263661, [10.99223, 76.46418]],
  [2184397, [-41.13333, 174.85]],
  [1261008, [10.7735, 76.37758]],
  [6230919, [-35.73167, 174.32391]],
  [1581364, [20.97136, 105.77876]],
  [5287262, [33.37032, -112.58378]],
  [2522713, [36.95151, 14.52788]],
  [3700563, [9.08939, -79.3831]],
  [2112232, [36.78671, 140.74901]],
  [1732711, [1.4866, 103.3896]],
  [4155529, [25.76232, -80.31616]],
  [3436124, [-27.48132, -58.93925]],
  [3047679, [46.45347, 16.99104]],
  [2656168, [53.70291, -1.6337]],
  [5288786, [32.29785, -110.9187]],
  [766555, [52.40149, 20.92664]],
  [3832260, [-26.81667, -65.31667]],
  [2990919, [43.18396, 3.00141]],
  [1271151, [26.17668, 90.62634]],
  [1268189, [12.54654, 77.42005]],
  [1864155, [34.5735, 133.23513]],
  [561667, [44.5622, 38.0848]],
  [99062, [33.97861, 44.93694]],
  [2291580, [5.35581, -3.88537]],
  [627145, [52.2138, 24.3564]],
  [2978640, [48.64738, -2.00877]],
  [207596, [-4.85187, 21.5595]],
  [1264551, [24.27419, 86.63929]],
  [1641333, [-7.64889, 110.625]],
  [2510073, [37.18516, -5.78093]],
  [121795, [37.0397, 45.0983]],
  [741609, [40.87333, 35.46306]],
  [1607737, [16.41904, 101.16056]],
  [1166827, [27.69203, 68.89503]],
  [532657, [55.58271, 37.90516]],
  [4796512, [17.72751, -64.74698]],
  [3139075, [59.20962, 9.60897]],
  [1854530, [35.85091, 139.51998]],
  [2896817, [52.27964, 7.71457]],
  [2634578, [52.30273, -0.69446]],
  [3395395, [-9.71028, -35.895]],
  [1264570, [25.92127, 86.79271]],
  [548602, [59.37331, 28.61343]],
  [547840, [43.84712, 46.71445]],
  [2855328, [48.5665, 13.43122]],
  [1274571, [28.59166, 76.27161]],
  [2652974, [51.78967, 1.15597]],
  [1640902, [-7.3817, 108.2082]],
  [3448300, [-23.52917, -47.13528]],
  [1162105, [31.34082, 69.4493]],
  [41210, [36.20777, 49.19527]],
  [1272225, [25.55265, 84.15149]],
  [1715542, [8.6561, 123.4227]],
  [3666570, [8.09263, -76.72822]],
  [743818, [41.09258, 28.33172]],
  [791580, [42.54018, 20.28793]],
  [7284838, [47.42698, 19.04016]],
  [216404, [4.279, 21.00284]],
  [3664539, [-4.085, -63.14139]],
  [3012937, [43.12038, 6.12857]],
  [1316703, [21.6056, 96.13508]],
  [5788516, [47.47038, -122.34679]],
  [749704, [41.19889, 36.72194]],
  [757033, [50.57304, 21.67937]],
  [2294938, [6.73156, -1.6137]],
  [1153269, [12.57065, 99.95876]],
  [1264414, [21.10743, 82.0948]],
  [3176391, [45.66019, 8.79164]],
  [2881062, [52.44758, 9.73741]],
  [12156878, [43.7706, -79.40148]],
  [3689570, [10.25444, -75.34389]],
  [2834265, [51.44387, 7.5675]],
  [6089426, [46.3168, -79.46633]],
  [2650732, [56.07156, -3.45887]],
  [5777544, [41.73549, -111.83439]],
  [1185252, [23.10787, 91.97007]],
  [1514891, [40.29414, 70.43122]],
  [248382, [30.19624, 35.73405]],
  [2830582, [49.32083, 8.43111]],
  [2223734, [4.51667, 12.03333]],
  [6177869, [42.98342, -79.24958]],
  [6138374, [45.63076, -72.95699]],
  [2287790, [6.49224, -6.58558]],
  [298935, [38.1519, 27.36223]],
  [2294962, [5.53711, -0.69984]],
  [4951473, [42.50982, -70.94949]],
  [1279394, [24.48012, 72.78186]],
  [3077685, [50.46048, 13.41779]],
  [1261910, [12.11764, 76.68397]],
  [1854026, [34.85, 134.93333]],
  [289199, [23.97144, 57.09313]],
  [2787413, [50.82763, 4.34389]],
  [1257587, [18.51716, 83.20548]],
  [567434, [58.3013, 57.8131]],
  [2479161, [36.14766, 3.69123]],
  [1879613, [39.61778, 125.66472]],
  [5323163, [33.56504, -117.72712]],
  [2655351, [51.99334, -0.73471]],
  [3491941, [18.48278, -69.88914]],
  [4692883, [29.30135, -94.7977]],
  [2645889, [53.86791, -1.90664]],
  [5384690, [32.96282, -117.03586]],
  [5025264, [44.88969, -93.34995]],
  [217570, [2.78582, 24.72997]],
  [2487620, [32.26667, 3.63333]],
  [1728336, [13.93714, 120.73224]],
  [3074199, [49.3961, 15.59124]],
  [3446753, [-21.40611, -48.50472]],
  [1275848, [17.51544, 78.88563]],
  [2151716, [-37.71667, 145.0]],
  [1536289, [56.085, 60.73139]],
  [3053438, [46.96479, 18.93974]],
  [2039623, [40.19944, 124.53167]],
  [334227, [14.10307, 38.28289]],
  [2170089, [-37.6, 144.95]],
  [2907851, [48.67798, 10.15162]],
  [1850523, [34.98333, 139.86667]],
  [1642692, [-7.88333, 111.11667]],
  [1278498, [28.27402, 79.16521]],
  [3616035, [12.19732, -86.09706]],
  [543254, [55.7, 37.66667]],
  [3521305, [19.43332, -99.19919]],
  [1302849, [16.88953, 97.63482]],
  [601339, [42.40881, 59.44544]],
  [518657, [55.71667, 37.78333]],
  [7290255, [52.49376, 13.44469]],
  [2266249, [39.46203, -8.7118]],
  [535270, [55.85, 37.56667]],
  [2312393, [-5.92753, 12.37148]],
  [3828262, [12.15889, -86.34417]],
  [6354984, [41.01295, 28.88593]],
  [477377, [55.9, 37.55]],
  [7303471, [35.01481, 135.67755]],
  [280155, [33.5175, 35.36889]],
  [92430, [36.27093, 43.37758]],
  [1512086, [54.8523, 83.106]],
  [1161983, [33.85782, 73.76043]],
  [543344, [55.86667, 37.61667]],
  [1154689, [9.53567, 99.93567]],
  [612287, [41.56271, 44.9787]],
  [521500, [55.68333, 37.48333]],
  [2647261, [51.51579, -0.4234]],
  [6640202, [-3.56182, 36.97895]],
  [3618908, [12.10629, -85.36452]],
  [476368, [55.88441, 37.69055]],
  [473127, [55.85, 37.58333]],
  [6354985, [41.00711, 28.88795]],
  [6418787, [55.80961, 37.78739]],
  [6697380, [2.93527, 101.69112]],
  [497271, [55.68333, 37.55]],
  [8504947, [60.0122, 30.20897]],
  [518740, [55.86667, 37.5]],
  [113659, [33.59586, 56.92437]],
  [1267189, [25.5022, 86.46708]],
  [1721636, [15.2488, 120.8548]],
  [5335663, [33.85835, -118.06479]],
  [1273002, [29.30883, 78.51083]],
  [2735083, [41.17872, -8.55953]],
  [933366, [-19.98333, 23.41667]],
  [1492893, [53.0875, 91.39972]],
  [5006917, [42.38337, -83.2966]],
  [3405812, [-8.33556, -36.42417]],
  [3174021, [40.8971, 14.18824]],
  [2873606, [52.4378, 13.38109]],
  [7315245, [21.28906, -157.71734]],
  [3985621, [21.29771, -100.51736]],
  [2756767, [51.965, 6.28889]],
  [1735339, [2.89435, 112.09145]],
  [2640681, [50.43565, -3.56789]],
  [553216, [55.50962, 47.49127]],
  [3170147, [45.95689, 12.66051]],
  [2335843, [7.82719, 6.07502]],
  [741304, [40.59167, 36.95167]],
  [2283016, [9.50511, -7.56433]],
  [1257196, [29.14551, 77.61433]],
  [1272640, [27.40096, 74.57537]],
  [1120985, [30.95962, 61.86037]],
  [1514258, [41.06924, 71.82372]],
  [418851, [32.5553, 51.50973]],
  [5140221, [40.73982, -73.93542]],
  [713749, [50.31036, 34.89879]],
  [1851504, [36.03107, 139.72636]],
  [3405792, [-1.36139, -48.24472]],
  [2752923, [50.86583, 6.0625]],
  [3881276, [-37.08994, -73.1577]],
  [760924, [51.41655, 21.96939]],
  [2491913, [36.64997, 3.3308]],
  [2445488, [13.049, 3.1937]],
  [1611439, [13.6882, 101.07156]],
  [1185247, [25.55613, 89.67097]],
  [4562193, [39.92678, -75.15712]],
  [4562237, [39.91678, -75.15546]],
  [4890119, [41.80892, -88.01117]],
  [158160, [-2.99983, 31.93261]],
  [5325423, [34.13362, -117.90756]],
  [1269392, [25.34558, 72.61559]],
  [308024, [39.67306, 33.61361]],
  [1214800, [3.7278, 98.6738]],
  [1177397, [32.64043, 74.56898]],
  [710035, [48.30523, 37.26091]],
  [261743, [38.05282, 23.76523]],
  [3028641, [43.21649, 2.34863]],
  [4333669, [32.50931, -92.1193]],
  [2644100, [51.68195, -4.16191]],
  [1699205, [14.51849, 121.23778]],
  [1258868, [28.64201, 75.38612]],
  [1276977, [25.48339, 85.70928]],
  [1850185, [34.88333, 136.85]],
  [5434006, [39.5186, -104.76136]],
  [1277950, [22.74431, 88.78655]],
  [159492, [-8.13333, 36.68333]],
  [3081677, [50.00377, 18.47205]],
  [5364007, [33.91724, -118.01201]],
  [2953386, [52.20699, 8.80365]],
  [309415, [37.71596, 33.55064]],
  [3532792, [19.41333, -98.14358]],
  [1256431, [16.521, 76.75738]],
  [32723, [35.23455, 52.30942]],
  [2878044, [52.39844, 13.40637]],
  [2525790, [37.60886, 15.16577]],
  [3037598, [45.64997, 0.15345]],
  [2645298, [56.11683, -3.15999]],
  [5897884, [44.16682, -77.38277]],
  [5690532, [48.23251, -101.29627]],
  [2152819, [-37.91482, 144.75088]],
  [3456138, [-22.7775, -47.29583]],
  [5711099, [45.49428, -122.86705]],
  [1185160, [25.96817, 89.69153]],
  [325579, [7.93333, 38.71667]],
  [759141, [51.11311, 20.87162]],
  [1268799, [22.77668, 86.18351]],
  [3556078, [20.90748, -77.28194]],
  [2295065, [6.04089, -0.45004]],
  [304013, [36.64389, 33.43885]],
  [3679065, [6.15769, -75.64317]],
  [498418, [55.0425, 59.04]],
  [2619528, [55.65719, 12.47364]],
  [1153807, [12.8, 99.96667]],
  [173193, [34.02403, 36.72848]],
  [938457, [-27.1974, 25.98311]],
  [704403, [48.29235, 39.73921]],
  [5007989, [43.41947, -83.95081]],
  [1266452, [10.30067, 76.33763]],
  [3390907, [-4.97139, -39.01528]],
  [4562635, [18.47245, -66.71573]],
  [500299, [54.06387, 44.9509]],
  [1170294, [28.02136, 69.54914]],
  [1006834, [-26.14095, 28.15247]],
  [5341256, [33.81696, -118.03729]],
  [3724337, [18.4259, -72.76995]],
  [2780741, [47.41427, 9.74195]],
  [3846915, [-23.80644, -64.78757]],
  [1005029, [-28.87097, 31.89961]],
  [5778755, [40.66689, -111.88799]],
  [2902768, [50.31297, 11.91261]],
  [2476403, [29.26388, 0.23098]],
  [3037543, [45.90878, 6.12565]],
  [2127878, [41.28944, 141.21694]],
  [1779790, [3.52229, 101.90866]],
  [3087497, [51.06713, 19.44477]],
  [110619, [16.96887, 42.83251]],
  [3685949, [6.34633, -75.50888]],
  [2186239, [-39.06667, 174.08333]],
  [1131316, [34.58787, 68.95091]],
  [3173180, [44.9994, 7.68236]],
  [418521, [32.68325, 51.60158]],
  [3452775, [-21.85389, -47.47917]],
  [5589173, [47.67768, -116.78047]],
  [5095779, [40.80677, -74.18542]],
  [1644522, [-7.76667, 112.01667]],
  [2654579, [52.33574, -2.05983]],
  [1256363, [13.38896, 77.86444]],
  [2131612, [40.80444, 140.44139]],
  [2924599, [52.50575, 13.50812]],
  [3888214, [-32.78333, -71.21667]],
  [3471039, [-26.99056, -48.63472]],
  [3164433, [43.86693, 10.2502]],
  [5192726, [40.2737, -76.88442]],
  [3447690, [-11.66417, -39.0075]],
  [3461550, [-21.75778, -48.82889]],
  [2887835, [51.78826, 6.13865]],
  [1853237, [32.95, 131.9]],
  [10629856, [35.44032, 138.79586]],
  [1273708, [19.96778, 72.71263]],
  [2475475, [33.10527, 6.05796]],
  [784873, [43.61694, 21.0025]],
  [759123, [51.95485, 20.15837]],
  [3573890, [10.66668, -61.51889]],
  [1183460, [32.98527, 70.60403]],
  [1645343, [-8.5365, 118.4634]],
  [375495, [3.86512, 32.48212]],
  [1608057, [14.70802, 101.41614]],
  [5389126, [33.97612, -117.90534]],
  [1487277, [58.04874, 60.55949]],
  [5340175, [34.09001, -117.89034]],
  [2753106, [52.555, 5.91111]],
  [168325, [35.44208, 36.65095]],
  [4552215, [36.11561, -97.05837]],
  [293845, [32.81149, 35.11323]],
  [787157, [42.65913, 20.28828]],
  [1835515, [37.1759, 128.9889]],
  [1258386, [11.46009, 78.18635]],
  [2637802, [51.34128, 0.73282]],
  [3832899, [-40.81345, -62.99668]],
  [3182179, [41.1307, 14.77816]],
  [5940956, [45.49452, -73.82419]],
  [2376898, [16.51378, -15.80503]],
  [1495974, [53.6239, 87.3598]],
  [3514868, [25.67207, -97.81313]],
  [1513092, [41.55, 61.01667]],
  [1626936, [-7.3515, 108.111]],
  [1259228, [13.36672, 78.57186]],
  [4614748, [35.04204, -89.66453]],
  [4597919, [33.0185, -80.17565]],
  [122397, [36.65079, 53.29905]],
  [1259400, [13.04888, 80.11488]],
  [1622318, [-9.6567, 120.2641]],
  [4369928, [39.53316, -76.33746]],
  [2849802, [47.78198, 9.61062]],
  [2637476, [51.55295, 0.55962]],
  [5928065, [45.01809, -74.72815]],
  [3465671, [-20.50525, -43.8588]],
  [5272893, [43.75083, -87.71453]],
  [2454955, [13.5728, -8.0339]],
  [3650273, [-1.04376, -79.63837]],
  [3469540, [-23.28333, -47.67222]],
  [3445746, [-26.23, -51.08639]],
  [4192375, [33.94621, -84.33465]],
  [2925259, [50.91485, 6.8118]],
  [3716044, [19.0505, -72.46585]],
  [2524881, [39.08077, 17.12764]],
  [2930821, [53.75396, 9.65339]],
  [1499053, [61.0296, 76.1136]],
  [1692872, [14.5833, 120.9667]],
  [2516479, [38.90883, 1.43296]],
  [3985865, [21.24893, -102.33036]],
  [935268, [-20.96333, 55.65031]],
  [2744911, [51.25167, 5.70694]],
  [1317375, [17.30858, 97.01124]],
  [2656406, [52.0632, -1.34222]],
  [1255046, [22.30083, 87.92593]],
  [1276371, [25.04049, 83.60749]],
  [4809537, [38.41925, -82.44515]],
  [1310460, [16.52834, 97.6157]],
  [2661513, [47.13713, 7.24608]],
  [3792392, [9.79029, -74.78244]],
  [5303752, [33.05811, -112.04764]],
  [3399506, [-6.76694, -43.0225]],
  [2667303, [58.28365, 12.28864]],
  [695464, [50.75104, 33.47471]],
  [3454954, [-29.62861, -50.83472]],
  [1267486, [32.36941, 75.52539]],
  [481985, [57.0436, 34.96221]],
  [1679980, [17.57472, 120.38694]],
  [1185141, [26.33338, 88.55777]],
  [2946111, [50.76313, 6.99089]],
  [4679803, [32.58847, -96.95612]],
  [1261922, [31.38966, 76.37574]],
  [5097402, [40.42788, -74.41598]],
  [3465508, [-23.18111, -50.64667]],
  [3447839, [-10.46139, -40.18944]],
  [4991640, [42.73698, -84.48387]],
  [3834813, [-26.73201, -65.25921]],
  [3190342, [45.77417, 19.11222]],
  [3164630, [41.68573, 12.77753]],
  [3686636, [7.66638, -76.68106]],
  [2744248, [52.085, 4.88333]],
  [3586977, [13.71667, -88.93333]],
  [3123673, [41.38042, 2.1686]],
  [2747203, [50.99833, 5.86944]],
  [1261415, [29.32416, 78.38724]],
  [4146166, [28.67617, -81.51186]],
  [1650095, [-7.51417, 109.29417]],
  [1626649, [-0.8006, 100.6571]],
  [3567834, [20.34711, -74.49624]],
  [1854979, [36.53333, 136.61667]],
  [1858301, [35.43333, 133.81667]],
  [2316259, [-4.54437, 18.60364]],
  [2798578, [50.83272, 4.38835]],
  [5126180, [40.72316, -73.91264]],
  [3094170, [52.23064, 19.36409]],
  [1852595, [36.48333, 139.0]],
  [2634493, [52.92979, -1.12537]],
  [2642414, [51.40322, -0.16831]],
  [1501141, [55.44753, 78.32181]],
  [1205481, [22.22647, 90.71275]],
  [3496831, [19.55186, -71.07813]],
  [1632974, [-7.01833, 107.60389]],
  [729730, [42.28389, 22.69111]],
  [785482, [41.74583, 22.19583]],
  [490377, [59.83528, 30.14667]],
  [3031815, [48.9018, 2.48931]],
  [1695097, [15.6689, 120.5806]],
  [2113115, [36.03333, 140.2]],
  [2556464, [33.43443, -5.22126]],
  [1734781, [2.8136, 101.50185]],
  [2642233, [51.39822, -0.19837]],
  [3436003, [-34.63333, -58.41667]],
  [471101, [60.01512, 30.67314]],
  [733840, [41.13488, 24.888]],
  [7828758, [15.21233, 145.7545]],
  [77408, [15.05898, 43.28731]],
  [3084826, [53.96396, 18.52638]],
  [2013923, [56.7938, 105.7672]],
  [3842190, [-24.25647, -65.21163]],
  [3453078, [-20.41639, -42.90861]],
  [304184, [37.21807, 28.3665]],
  [5006250, [42.20115, -85.58]],
  [2651654, [51.65446, -3.02281]],
  [1647936, [-7.4281, 112.7234]],
  [324172, [38.35083, 28.51718]],
  [3652743, [-0.46645, -76.98719]],
  [1265354, [27.82294, 75.02754]],
  [5106298, [40.79871, -74.23904]],
  [3179337, [40.69954, 14.70773]],
  [4772354, [38.93428, -77.17748]],
  [3113082, [43.32099, -3.02064]],
  [3182650, [40.91494, 14.79103]],
  [2437732, [13.79599, 5.25026]],
  [1317402, [16.07686, 97.56388]],
  [1666596, [22.46515, 120.44927]],
  [3400804, [-8.35917, -35.22361]],
  [3005269, [48.93872, 2.46138]],
  [6173865, [50.0261, -126.36157]],
  [359953, [30.59683, 30.89931]],
  [142358, [36.69929, 52.65011]],
  [3446847, [-29.65056, -50.78056]],
  [2968705, [48.7939, 2.35992]],
  [2831708, [51.57558, 8.10619]],
  [1255654, [28.09617, 74.00868]],
  [11121204, [22.91273, -109.92655]],
  [3517742, [19.30661, -98.18773]],
  [202065, [-2.06028, 29.34778]],
  [1185149, [25.66369, 88.93093]],
  [286402, [24.7426, 56.46698]],
  [1861207, [35.28333, 136.86667]],
  [294999, [32.07225, 34.81253]],
  [6090785, [49.31636, -123.06934]],
  [1283329, [26.66371, 87.27403]],
  [2473634, [36.17424, 8.70486]],
  [1570549, [11.28333, 106.13333]],
  [1258294, [26.97982, 84.85065]],
  [5335650, [37.59493, -120.95771]],
  [3684666, [9.7174, -75.12023]],
  [3387202, [-6.75917, -38.22806]],
  [1328218, [24.25256, 97.23357]],
  [1256029, [15.63, 76.89217]],
  [1177278, [25.81459, 68.42198]],
  [2850257, [48.85851, 8.20965]],
  [2643697, [52.89855, -1.27136]],
  [3461763, [-21.30528, -46.71278]],
  [3462378, [-15.3175, -49.1175]],
  [8428480, [32.04984, 34.95382]],
  [1263824, [22.83282, 69.35237]],
  [1263684, [19.15988, 76.20832]],
  [3465105, [-12.67, -39.10194]],
  [1228730, [6.6858, 80.4036]],
  [3801276, [18.44771, -96.35912]],
  [1127768, [36.26468, 68.01551]],
  [354981, [26.67418, 31.49686]],
  [4929771, [41.70011, -70.29947]],
  [1167031, [27.80227, 68.28902]],
  [1252919, [21.47101, 78.26965]],
  [2929600, [51.22235, 6.90831]],
  [1252958, [22.61198, 70.94379]],
  [4278890, [38.84028, -97.61142]],
  [7931933, [-0.27088, 36.37917]],
  [767470, [49.68866, 21.77058]],
  [3456370, [-26.89889, -48.65417]],
  [1637001, [-0.9516, 122.7875]],
  [1273909, [24.33353, 93.66999]],
  [3465320, [-22.64583, -47.19611]],
  [3403208, [-1.19583, -47.18083]],
  [2470588, [36.72866, 10.34163]],
  [1625908, [0.7, 108.9]],
  [286293, [23.31667, 58.01667]],
  [7257992, [39.50506, -76.31977]],
  [5852275, [21.39734, -157.97516]],
  [2813187, [52.55632, 13.46649]],
  [538836, [44.88, 40.59861]],
  [1540356, [62.09611, 77.475]],
  [5153420, [41.5931, -81.52679]],
  [932698, [-28.87185, 28.04501]],
  [2621215, [55.58333, 12.3]],
  [1272237, [24.26778, 87.24855]],
  [1651591, [-3.33984, 128.91975]],
  [1255134, [18.73502, 73.67561]],
  [2487882, [35.63106, 5.91186]],
  [1269873, [12.30359, 76.29275]],
  [3460344, [-11.18143, -40.51372]],
  [3460826, [-17.03917, -39.53111]],
  [4736134, [29.38385, -94.9027]],
  [5278159, [43.04946, -88.00759]],
  [597231, [54.55991, 23.35412]],
  [3172269, [45.00031, 7.65305]],
  [1635815, [-8.6199, 122.2111]],
  [1250615, [6.9802, 81.0577]],
  [1734439, [5.72743, 100.50876]],
  [12157064, [-9.51656, -77.53045]],
  [3014856, [43.65783, 6.92537]],
  [2754659, [52.67144, 4.84862]],
  [2367990, [6.22798, 1.5919]],
  [739251, [40.83129, 35.64788]],
  [1259503, [29.58349, 80.20947]],
  [1269256, [17.72602, 79.15236]],
  [915883, [-15.76911, 28.18136]],
  [3178587, [42.09325, 11.79674]],
  [578155, [48.18585, 40.77424]],
  [5349613, [38.49602, -121.40884]],
  [1278190, [28.02964, 78.28571]],
  [1261754, [22.94936, 79.18357]],
  [118191, [37.13696, 50.29174]],
  [6930414, [48.78363, 9.21032]],
  [2934662, [51.83149, 7.28075]],
  [3624955, [10.01625, -84.21163]],
  [3467796, [-20.89722, -45.27722]],
  [1700712, [12.37169, 123.62494]],
  [719965, [46.41667, 20.33333]],
  [142496, [33.6095, 48.9307]],
  [2225728, [4.0913, 9.3144]],
  [5610810, [42.56297, -114.46087]],
  [1262562, [13.16352, 78.39346]],
  [2016764, [52.21164, 104.0915]],
  [293842, [31.60998, 34.76422]],
  [1717926, [12.4989, 124.6377]],
  [4893886, [42.06975, -87.78784]],
  [2925550, [49.53414, 8.35357]],
  [1262663, [16.33354, 75.28305]],
  [2641224, [53.25882, -2.52025]],
  [933088, [-22.38754, 26.71077]],
  [1270820, [9.67826, 77.24951]],
  [558312, [52.49657, 39.93593]],
  [1578500, [14.35451, 108.00759]],
  [5221931, [41.81371, -71.37005]],
  [2905455, [50.86874, 6.09317]],
  [3859552, [-38.93424, -69.23052]],
  [1655559, [19.88601, 102.13503]],
  [3067421, [49.47188, 17.11184]],
  [1215839, [39.41902, 67.26118]],
  [5380668, [33.8303, -116.54529]],
  [773380, [50.05146, 21.41141]],
  [739914, [40.28833, 38.42361]],
  [12157065, [-16.32369, -71.58151]],
  [1276810, [22.03232, 74.89982]],
  [5392323, [35.28275, -120.65962]],
  [259824, [38.07438, 23.81106]],
  [1719329, [15.0956, 120.8267]],
  [3067580, [49.45511, 17.4509]],
  [3460966, [-12.5275, -40.30694]],
  [2233410, [4.15342, 9.24231]],
  [620391, [53.1561, 24.4513]],
  [2748076, [52.03634, 4.32501]],
  [1151063, [12.38487, 99.90157]],
  [1519244, [52.93592, 70.18895]],
  [2189529, [-46.4, 168.35]],
  [1161724, [25.2919, 60.643]],
  [756320, [52.19517, 20.88419]],
  [3090436, [50.47379, 17.33437]],
  [3028263, [43.60527, 2.24088]],
  [524699, [56.44469, 52.22763]],
  [1259243, [9.01956, 76.92261]],
  [2301639, [5.65003, -0.2361]],
  [4943828, [42.7262, -71.19089]],
  [3027484, [46.78112, 4.85372]],
  [98685, [31.52845, 45.20377]],
  [3589977, [14.546, -90.41288]],
  [5373628, [37.75993, -122.41914]],
  [186827, [0.04626, 37.65587]],
  [299137, [38.08877, 27.73508]],
  [1852901, [35.06667, 135.21667]],
  [201463, [-1.9487, 30.4347]],
  [2111173, [35.88333, 140.5]],
  [2289549, [6.74202, -7.34918]],
  [660561, [60.39233, 25.66507]],
  [2656708, [55.46273, -4.63393]],
  [2299625, [8.05627, -1.73058]],
  [3555907, [22.80375, -81.19127]],
  [8521440, [55.62737, 37.85803]],
  [612366, [42.14272, 41.67384]],
  [1176035, [29.51827, 70.84474]],
  [3833859, [-38.37394, -60.27978]],
  [3164039, [42.41937, 12.1056]],
  [1270723, [23.7975, 85.82523]],
  [3489577, [18.04168, -77.50714]],
  [1630723, [-1.3959, 120.7524]],
  [4152093, [25.94815, -80.317]],
  [3182272, [40.60861, 14.98209]],
  [2464168, [36.83408, 10.04057]],
  [2419472, [9.1848, -10.09987]],
  [4357116, [39.33261, -76.69275]],
  [56335, [2.78087, 45.50048]],
  [2176031, [-38.03333, 145.35]],
  [1277765, [15.8137, 74.85895]],
  [1256418, [23.40673, 76.7098]],
  [3036784, [50.29301, 2.78186]],
  [11549789, [41.3975, 2.14301]],
  [305742, [39.09513, 33.07989]],
  [338998, [6.41667, 38.31667]],
  [3031133, [50.72571, 1.61392]],
  [2875881, [47.61497, 7.66457]],
  [1510203, [56.9083, 60.8019]],
  [2635785, [52.52956, -2.06773]],
  [6822108, [35.95633, 140.32356]],
  [2027749, [50.23685, 136.88136]],
  [1697023, [14.62056, 120.58167]],
  [3448640, [-21.59556, -46.88861]],
  [121861, [30.76277, 49.70226]],
  [3109481, [43.32842, -3.03248]],
  [3515384, [17.54528, -98.57599]],
  [701855, [47.63088, 34.6245]],
  [3515504, [21.14268, -88.15101]],
  [4259671, [38.27757, -85.73718]],
  [784372, [42.32139, 21.35833]],
  [5392090, [33.78391, -116.95864]],
  [3025622, [48.88109, 2.59295]],
  [2175411, [-33.76667, 150.91667]],
  [1269502, [16.77639, 74.55361]],
  [138742, [36.64546, 59.12123]],
  [3029227, [43.66352, 7.1479]],
  [6822219, [35.73691, 136.95852]],
  [5162645, [41.66616, -81.33955]],
  [265560, [38.62139, 21.40778]],
  [2979590, [47.21154, -1.651]],
  [2646088, [57.47908, -4.22398]],
  [3186084, [40.70583, 19.95222]],
  [347542, [29.47639, 30.96119]],
  [3122826, [41.37732, 2.08809]],
  [1274536, [24.20645, 84.87085]],
  [250637, [32.01071, 35.89802]],
  [3345300, [45.49167, 15.55]],
  [1260959, [20.66727, 75.35305]],
  [1260168, [17.53334, 78.2645]],
  [3082473, [54.60568, 18.23559]],
  [4429295, [31.32712, -89.29034]],
  [3589646, [14.77943, -90.45318]],
  [1213713, [2.2874, 97.7884]],
  [1260454, [24.72094, 80.18772]],
  [1259813, [27.13102, 72.36826]],
  [5774001, [40.52467, -111.86382]],
  [1252738, [20.0424, 74.48944]],
  [2111749, [36.05, 140.16667]],
  [3449720, [-12.54667, -38.71194]],
  [2492345, [36.92917, 6.58556]],
  [917748, [-16.80889, 26.9875]],
  [1855907, [33.81688, 130.70962]],
  [3458632, [-21.53194, -42.64306]],
  [1537939, [52.35062, 71.88161]],
  [151479, [-9.34917, 34.77167]],
  [2263352, [38.70194, -9.34083]],
  [1256698, [25.13994, 85.84096]],
  [4743275, [33.01512, -96.53888]],
  [3869657, [-36.61756, -72.95593]],
  [300075, [36.97612, 38.42533]],
  [614455, [41.98422, 44.11578]],
  [1606030, [14.88181, 103.49364]],
  [2958595, [48.21644, 9.02596]],
  [2498183, [36.80377, 7.73684]],
  [1270759, [28.58938, 77.79318]],
  [5364271, [38.42102, -121.42384]],
  [2918752, [50.94823, 10.70193]],
  [2231319, [5.88333, 14.55]],
  [1149698, [13.11189, 99.94467]],
  [538908, [51.6601, 35.6521]],
  [4752031, [38.02931, -78.47668]],
  [7963596, [27.58466, 85.52122]],
  [3174945, [45.85589, 9.39704]],
  [315515, [36.60498, 34.30836]],
  [646005, [61.68857, 27.27227]],
  [1256832, [29.20904, 78.58837]],
  [616062, [40.16557, 44.29462]],
  [3021000, [50.37069, 3.07922]],
  [3571971, [26.53333, -78.66667]],
  [3178388, [45.07873, 7.56735]],
  [1290374, [16.71047, 95.92866]],
  [2872079, [52.20197, 8.33826]],
  [3178131, [41.15171, 16.41143]],
  [1252699, [17.68138, 77.60743]],
  [1849831, [35.54008, 134.82038]],
  [1159716, [36.5449, 61.1577]],
  [6128081, [43.80406, -79.16571]],
  [5903510, [45.66678, -73.88249]],
  [3404817, [-1.68222, -50.48028]],
  [774208, [52.88141, 20.61996]],
  [1267675, [26.88251, 88.27729]],
  [3177700, [44.39071, 7.54828]],
  [2756139, [52.71083, 5.74861]],
  [5800112, [47.03426, -122.82319]],
  [1263761, [29.79094, 77.87836]],
  [2504581, [35.30099, -1.38226]],
  [5429032, [39.61332, -105.01665]],
  [1271688, [18.96962, 76.74946]],
  [1966336, [14.6627, 121.0328]],
  [1261234, [29.18292, 74.77064]],
  [2645605, [55.61171, -4.49581]],
  [2218840, [31.74554, 13.98354]],
  [2958141, [50.87673, 6.16399]],
  [1498087, [65.53333, 72.51667]],
  [1611026, [16.5443, 102.09924]],
  [874045, [43.54437, 44.59592]],
  [1272207, [27.73308, 78.94119]],
  [3511336, [18.45, -69.6]],
  [3946820, [-10.75, -77.76667]],
  [2947444, [48.68212, 9.01171]],
  [2656379, [51.32233, -0.20685]],
  [1261901, [27.86459, 81.50036]],
  [4506008, [39.70923, -84.06327]],
  [1858858, [36.31667, 138.43333]],
  [2469264, [37.15368, 9.78594]],
  [1645875, [-3.4703, 102.5207]],
  [202068, [-2.1597, 30.5427]],
  [1606939, [6.63883, 100.42342]],
  [3171737, [45.56899, 9.16483]],
  [1735268, [4.46916, 101.04107]],
  [3468425, [-22.4625, -42.65306]],
  [2917138, [52.21099, 7.02238]],
  [3020020, [48.9535, 2.31514]],
  [4474040, [35.48736, -80.62173]],
  [2641843, [51.66317, -3.80443]],
  [2847690, [51.84967, 8.30017]],
  [1276249, [19.11285, 77.96336]],
  [192067, [-3.63045, 39.84992]],
  [511794, [65.14717, 57.22439]],
  [321025, [38.40115, 42.10784]],
  [2645456, [52.75172, 0.39516]],
  [3949231, [-16.44083, -71.55333]],
  [3876664, [-36.74075, -72.99528]],
  [2801471, [51.20957, 4.43539]],
  [1183090, [34.6178, 71.97247]],
  [2633858, [51.06513, -1.3187]],
  [591260, [59.39861, 27.27306]],
  [88562, [24.20487, 23.2857]],
  [5109177, [42.09869, -75.91797]],
  [3169231, [45.07073, 7.51465]],
  [2523866, [37.56675, 14.90254]],
  [1272411, [21.3236, 74.56804]],
  [1021396, [-26.66331, 28.59016]],
  [136702, [32.6941, 47.2679]],
  [2345521, [11.74697, 11.96083]],
  [499453, [55.11087, 33.23292]],
  [3172729, [42.5114, 14.14507]],
  [1253091, [17.27343, 74.53792]],
  [2476301, [27.67111, -8.14743]],
  [502793, [52.65599, 41.88461]],
  [3607511, [15.43333, -87.91667]],
  [2524205, [37.65535, 12.58986]],
  [2864058, [52.50462, 9.45871]],
  [1172915, [31.17068, 74.10126]],
  [3456944, [-24.09306, -46.62083]],
  [2893438, [51.59231, 7.6638]],
  [2968054, [48.8486, 2.43769]],
  [1264363, [27.29191, 81.11775]],
  [3645671, [10.15951, -68.89453]],
  [2153953, [-38.07018, 145.47411]],
  [1175453, [31.36709, 74.36899]],
  [1896348, [33.955, 131.95]],
  [5120442, [40.76496, -73.9909]],
  [102585, [26.6497, 49.95522]],
  [3168550, [42.9568, 13.87676]],
  [2656241, [54.11094, -3.22758]],
  [1849237, [36.8, 137.4]],
  [8436084, [41.74179, -87.65322]],
  [2277060, [6.99543, -9.47122]],
  [3595237, [14.63472, -89.98889]],
  [3461017, [-25.46722, -50.65111]],
  [10792588, [9.68444, 76.33558]],
  [699839, [46.75966, 33.35591]],
  [361213, [31.31146, 31.14766]],
  [3180172, [40.90969, 14.34205]],
  [1169620, [30.89222, 73.49889]],
  [1723257, [8.50833, 124.75944]],
  [3894426, [-45.57524, -72.06619]],
  [3178283, [45.53004, 9.27795]],
  [2633373, [50.94159, -2.63211]],
  [7174365, [47.60444, -122.03768]],
  [2112758, [38.43889, 140.40056]],
  [178443, [1.7471, 40.05732]],
  [5324105, [38.70824, -121.32995]],
  [1261567, [13.90742, 79.89465]],
  [3567612, [22.98226, -82.54719]],
  [4702828, [32.93457, -97.25168]],
  [1863310, [34.88333, 132.08333]],
  [2995387, [43.40735, 5.05526]],
  [1270474, [22.50321, 73.47242]],
  [2878695, [48.8, 9.01667]],
  [3451704, [-24.4875, -47.84361]],
  [2831924, [47.75935, 8.8403]],
  [3386449, [-3.73222, -40.99167]],
  [1220855, [38.55632, 69.01354]],
  [2152659, [-31.43084, 152.90894]],
  [1266960, [30.74632, 76.64689]],
  [472722, [59.9258, 32.33819]],
  [1210565, [22.49518, 90.72391]],
  [1177064, [30.42388, 72.69737]],
  [873901, [59.84389, 30.45111]],
  [1850745, [32.94716, 130.57446]],
  [1229293, [8.0362, 79.8283]],
  [317844, [37.5732, 32.77446]],
  [3166601, [43.83193, 11.19924]],
  [1276328, [17.66846, 80.88887]],
  [3598122, [14.70413, -91.86426]],
  [168661, [35.61376, 36.56449]],
  [260183, [38.01135, 23.66597]],
  [1258584, [30.256, 75.24116]],
  [4418478, [30.39603, -88.88531]],
  [2829901, [53.59337, 9.47629]],
  [735640, [41.11917, 25.40535]],
  [1185199, [23.40964, 89.13801]],
  [2745123, [51.6825, 5.07083]],
  [295721, [32.92814, 35.07647]],
  [3164565, [45.32163, 8.41989]],
  [2642593, [53.55, -2.2]],
  [295277, [29.55805, 34.94821]],
  [2832561, [53.66667, 10.01667]],
  [4452808, [35.73265, -78.85029]],
  [2349431, [12.74482, 4.52514]],
  [3169984, [40.84394, 14.0952]],
  [2647317, [50.8567, -0.98559]],
  [3110962, [43.29568, -5.68416]],
  [7521348, [29.60018, 32.31671]],
  [4928096, [40.42587, -86.90807]],
  [1185100, [23.59311, 90.14251]],
  [2524123, [37.51803, 15.00913]],
  [1854905, [36.63333, 139.05]],
  [110250, [23.9065, 42.91724]],
  [2653646, [51.36829, -0.16755]],
  [785380, [41.4375, 22.64333]],
  [122289, [29.1988, 54.3278]],
  [468390, [53.14806, 38.09924]],
  [185702, [0.33474, 34.48796]],
  [178522, [-3.39605, 38.55609]],
  [1256558, [9.68581, 76.33996]],
  [3168309, [40.83261, 14.34162]],
  [1256064, [24.10313, 77.69055]],
  [3459342, [-10.91722, -37.65]],
  [1273374, [18.46515, 74.58375]],
  [7886881, [41.47849, 20.55945]],
  [2906376, [50.77555, 7.28308]],
  [3183005, [41.45263, 12.62157]],
  [2941694, [52.19837, 8.58644]],
  [4175437, [28.61222, -80.80755]],
  [4017957, [19.76822, -104.36664]],
  [3623076, [10.63504, -85.43772]],
  [1260296, [18.78113, 84.08836]],
  [702723, [50.01413, 32.99975]],
  [3588698, [14.77222, -91.18333]],
  [3701117, [8.1, -80.98333]],
  [5178195, [40.51868, -78.39474]],
  [1261957, [15.85668, 78.26569]],
  [2361477, [12.46338, -3.46075]],
  [3031009, [46.20574, 5.2258]],
  [1848382, [35.11626, 136.19768]],
  [553152, [46.0849, 38.9596]],
  [2508119, [35.74381, -0.7693]],
  [1267115, [26.77268, 83.07179]],
  [3166465, [40.8455, 14.19262]],
  [1855670, [37.05, 136.96667]],
  [1274043, [23.85681, 86.90324]],
  [3493283, [19.37762, -70.41762]],
  [2872347, [51.46667, 6.76667]],
  [3686513, [5.61637, -73.81748]],
  [1854807, [32.81667, 129.88333]],
  [2753468, [52.29917, 5.24167]],
  [1260134, [23.6658, 86.43166]],
  [2745392, [51.4425, 3.57361]],
  [355420, [29.2376, 30.78944]],
  [2807465, [53.89314, 11.45286]],
  [5045021, [44.9483, -93.34801]],
  [2646867, [52.5389, -1.37613]],
  [3394453, [-8.11861, -35.09222]],
  [3457736, [-20.37778, -43.41611]],
  [352628, [28.41866, 30.77861]],
  [4834040, [41.97621, -72.59176]],
  [3403697, [-2.24444, -49.49583]],
  [298316, [38.32292, 26.76403]],
  [1272201, [23.84306, 73.71466]],
  [495112, [50.40967, 36.9136]],
  [2748172, [51.8725, 4.60278]],
  [568595, [54.63345, 21.81557]],
  [1263852, [22.59879, 80.37115]],
  [2802031, [51.21187, 4.25633]],
  [2521710, [37.01938, -4.56123]],
  [2542227, [32.6852, -4.74512]],
  [2503229, [36.72735, 3.40995]],
  [1055433, [-25.03249, 46.98329]],
  [2127896, [43.55278, 141.90639]],
  [3386396, [-7.50528, -35.31833]],
  [2267095, [39.74362, -8.80705]],
  [752627, [41.02121, 39.57146]],
  [1271874, [26.30253, 87.26556]],
  [3172244, [40.7454, 14.64542]],
  [3531013, [16.7077, -93.01184]],
  [233275, [0.60917, 33.46861]],
  [1248158, [5.92825, 80.5888]],
  [1308052, [16.68911, 98.50893]],
  [11101593, [22.28783, 113.94243]],
  [12188754, [40.73542, 8.56771]],
  [11524139, [35.83458, 138.39606]],
  [2208425, [32.9312, 12.08199]],
  [6945426, [47.2469, 26.72291]],
  [4790207, [37.59015, -77.55638]],
  [94220, [36.08289, 44.62873]],
  [1270287, [14.78766, 75.98863]],
  [1687164, [13.9624, 121.5265]],
  [2748000, [51.19417, 5.9875]],
  [1269655, [27.08694, 93.60987]],
  [4366001, [39.01817, -77.20859]],
  [3032179, [48.90982, 2.45012]],
  [4833320, [41.52005, -81.55624]],
  [2992292, [46.34015, 2.60254]],
  [734301, [40.64944, 22.95083]],
  [3166988, [43.75423, 11.18794]],
  [1270722, [23.04268, 84.54429]],
  [4043909, [13.51777, 144.8391]],
  [3661944, [-3.36841, -64.72054]],
  [1261068, [20.09473, 73.92816]],
  [1694775, [7.37035, 124.26973]],
  [295740, [32.60907, 35.2892]],
  [1516438, [49.72654, 84.27318]],
  [5104404, [40.45927, -74.36098]],
  [133037, [33.4537, 50.28836]],
  [3868633, [-28.57617, -70.75938]],
  [1842939, [35.80167, 126.88889]],
  [324106, [38.79975, 26.97203]],
  [3074603, [50.72431, 15.17108]],
  [1697046, [14.8006, 120.5371]],
  [1163724, [30.70358, 70.65054]],
  [6332309, [25.5783, -80.3377]],
  [3391908, [-4.27333, -41.77694]],
  [3653403, [-3.32561, -79.80697]],
  [1634680, [-7.58111, 110.29278]],
  [692087, [49.06287, 33.2458]],
  [3899629, [-37.79519, -72.71636]],
  [1719053, [10.38697, 123.2227]],
  [2562055, [33.8527, -7.03171]],
  [3036433, [43.29276, 5.57067]],
  [1275930, [17.89017, 83.45203]],
  [5098706, [40.88593, -74.04347]],
  [3680387, [3.26074, -76.53499]],
  [736364, [41.15283, 24.1473]],
  [2971041, [50.35909, 3.52506]],
  [3460620, [-24.1125, -49.33167]],
  [1861416, [24.34478, 124.15717]],
  [3612907, [14.03333, -86.58333]],
  [3163948, [40.84282, 14.23075]],
  [3461588, [-23.26917, -51.04806]],
  [4126226, [34.22843, -92.0032]],
  [3455785, [-20.72028, -47.88667]],
  [1215395, [3.19468, 98.50889]],
  [1267939, [23.02302, 73.07113]],
  [2620425, [56.13615, 8.97662]],
  [8858089, [19.3025, -98.86306]],
  [1282666, [28.52823, 81.11798]],
  [3172287, [41.45794, 12.66393]],
  [3489523, [17.96454, -77.24515]],
  [2246544, [16.4625, -15.70083]],
  [3659139, [-0.69819, -80.09361]],
  [1606239, [15.11481, 104.32938]],
  [99548, [32.90941, 45.06359]],
  [2638664, [51.06931, -1.79569]],
  [2956656, [49.44287, 11.86267]],
  [1020641, [-32.35671, 22.58295]],
  [933685, [-24.96675, 25.33273]],
  [1650434, [-7.95, 110.28333]],
  [5143992, [42.85006, -78.79975]],
  [2640132, [53.69107, -1.31269]],
  [2973675, [48.87143, 2.22929]],
  [2522129, [39.15, -0.43333]],
  [3377408, [6.00809, -58.30714]],
  [5173237, [41.3145, -81.83569]],
  [2736041, [41.76719, -8.58393]],
  [580222, [59.87167, 30.26583]],
  [2994144, [48.81381, 2.235]],
  [2929622, [51.07947, 6.31531]],
  [2300721, [5.57692, -0.31038]],
  [743439, [40.85583, 26.63028]],
  [5338166, [33.6803, -116.17389]],
  [1283190, [27.67872, 85.2775]],
  [3671772, [3.42111, -76.24472]],
  [5205377, [40.50118, -79.83922]],
  [2899449, [49.32637, 7.33867]],
  [2826099, [48.88126, 12.57385]],
  [2504110, [32.82648, 3.76689]],
  [1504871, [53.7884, 81.3423]],
  [1152188, [16.71667, 98.56667]],
  [6178582, [49.28333, -123.13333]],
  [583798, [48.27955, 46.17217]],
  [1310120, [23.64254, 94.40478]],
  [2756644, [53.11254, 6.0989]],
  [2485618, [36.18503, 1.53299]],
  [2297141, [5.80893, -0.35026]],
  [3080414, [51.59915, 18.93974]],
  [7289614, [51.47924, 11.91605]],
  [3566603, [22.07874, -79.49726]],
  [3176515, [41.63976, 13.34109]],
  [4101260, [36.37285, -94.20882]],
  [1254570, [24.63881, 93.99639]],
  [4156018, [27.44671, -80.32561]],
  [3403687, [-2.90222, -40.84111]],
  [933471, [-23.10407, 26.81421]],
  [8299576, [40.60079, -74.64815]],
  [733905, [40.52437, 22.20242]],
  [2518878, [38.84078, 0.10574]],
  [1842800, [36.81083, 127.79472]],
  [3458132, [-23.54583, -47.18333]],
  [1262783, [26.2264, 78.22482]],
  [1263148, [23.17647, 88.09749]],
  [3178796, [42.34827, 14.16494]],
  [1023309, [-30.69366, 26.71141]],
  [3085978, [51.59584, 18.73023]],
  [2791301, [51.24623, 4.44903]],
  [3542502, [23.125, -82.33194]],
  [2525755, [37.9779, 12.96473]],
  [1261532, [11.77554, 79.67016]],
  [1142170, [37.11664, 70.58002]],
  [1278593, [20.84089, 85.10192]],
  [294577, [32.91708, 35.30501]],
  [3095795, [54.17565, 15.58342]],
  [3012647, [43.51345, 4.98747]],
  [3620680, [12.01366, -83.76353]],
  [3586833, [13.72702, -89.18161]],
  [2420884, [10.36676, -13.58253]],
  [1860122, [34.1276, 133.64598]],
  [3461316, [-26.89778, -49.23167]],
  [1263230, [18.04531, 78.26078]],
  [1201753, [23.25191, 90.85508]],
  [1268325, [26.11667, 79.73333]],
  [542000, [59.73833, 30.08944]],
  [4166638, [26.17231, -80.13199]],
  [2972811, [49.35994, 6.16044]],
  [1163582, [24.74745, 67.92353]],
  [1270000, [27.89196, 77.36744]],
  [2467815, [36.76946, 10.27468]],
  [3407378, [-4.56167, -37.76972]],
  [1640138, [-6.9383, 108.891]],
  [2614481, [55.64152, 12.08035]],
  [4929399, [41.94454, -71.28561]],
  [321062, [37.02577, 37.97841]],
  [1849788, [37.6645, 138.92518]],
  [3694564, [-6.03416, -76.97168]],
  [3832778, [-33.22778, -60.3297]],
  [1822906, [10.98081, 104.77828]],
  [1278354, [13.31446, 76.25704]],
  [2996148, [48.99048, 1.7167]],
  [3637623, [10.13008, -71.25946]],
  [744537, [40.21323, 28.3612]],
  [2294034, [5.35113, -0.62313]],
  [1855476, [31.6, 131.36667]],
  [3024066, [49.41794, 2.82606]],
  [4369190, [39.13705, -76.6983]],
  [2296458, [5.53449, -0.41679]],
  [4747845, [37.22957, -80.41394]],
  [4695912, [32.79957, -97.26918]],
  [670938, [45.41667, 23.36667]],
  [1261446, [16.90572, 81.67222]],
  [281577, [32.31156, 35.0269]],
  [5367788, [34.63915, -120.45794]],
  [2943320, [50.82928, 6.90499]],
  [541404, [47.89221, 40.07037]],
  [361478, [26.99306, 31.41605]],
  [687196, [48.34518, 33.49743]],
  [1269042, [28.6063, 76.6565]],
  [3166576, [45.13925, 7.77008]],
  [1166073, [32.90495, 73.75518]],
  [301116, [38.93925, 33.5386]],
  [3163392, [62.47225, 6.15492]],
  [4177727, [28.23973, -82.32787]],
  [12047644, [51.12056, 17.03761]],
  [478044, [45.21077, 39.68914]],
  [1498129, [53.709, 87.8014]],
  [3689235, [10.79408, -74.9164]],
  [863061, [65.99389, 57.52806]],
  [3116789, [43.25, -5.76667]],
  [1253403, [21.01767, 75.91042]],
  [4879890, [41.62666, -93.71217]],
  [2532394, [34.68664, -6.00272]],
  [3461194, [-16.35778, -49.49611]],
  [949224, [-22.94564, 30.48497]],
  [3456848, [-18.72472, -47.49861]],
  [1629749, [-6.7036, 111.3416]],
  [1724956, [16.03232, 120.26904]],
  [500886, [52.26041, 43.78745]],
  [12188755, [45.42178, 11.88755]],
  [1511954, [57.85158, 61.69627]],
  [8633918, [2.63918, -76.53055]],
  [2785141, [51.32254, 4.94471]],
  [6690786, [41.39606, 2.16688]],
  [12156843, [43.79572, -79.31408]],
  [2990474, [46.98956, 3.159]],
  [554599, [58.7002, 59.4839]],
  [3398904, [-7.56056, -35.0025]],
  [4166233, [25.93315, -80.16255]],
  [2652696, [55.86216, -4.02469]],
  [12156826, [43.63347, -79.54332]],
  [5345609, [32.792, -115.56305]],
  [2055166, [54.1088, 102.1648]],
  [3058060, [49.12153, 18.42169]],
  [2927268, [48.80912, 9.27697]],
  [1134720, [31.59382, 64.37161]],
  [5133640, [40.72649, -73.85264]],
  [2879367, [52.37193, 9.97919]],
  [335288, [8.98333, 37.85]],
  [2636484, [53.12542, -1.26135]],
  [2861982, [51.5018, 10.7957]],
  [2508813, [27.87429, -0.29388]],
  [1634614, [-3.35989, 135.50074]],
  [3534749, [20.60009, -76.13364]],
  [1269323, [14.84677, 78.38314]],
  [2881885, [48.34042, 7.86886]],
  [1269179, [22.03709, 71.20794]],
  [2931574, [50.9807, 10.31522]],
  [1840211, [36.20389, 127.08472]],
  [1267283, [20.50166, 86.42227]],
  [3026467, [48.44685, 1.48925]],
  [2498590, [36.22393, 1.67187]],
  [2648208, [52.91149, -0.64184]],
  [10104154, [34.07808, -118.26066]],
  [2044091, [42.44113, 129.74601]],
  [3460186, [-20.26889, -50.54583]],
  [4363843, [39.04455, -77.11887]],
  [480122, [57.88529, 39.5406]],
  [6943537, [41.43806, 2.2195]],
  [1863418, [34.4, 131.41667]],
  [2234663, [4.43333, 14.36667]],
  [2642705, [51.74794, -3.37779]],
  [1271142, [22.8774, 88.75479]],
  [3430104, [-29.71251, -57.08771]],
  [4900373, [41.88003, -88.00784]],
  [6064969, [43.80938, -79.22344]],
  [2648026, [53.39234, -2.63994]],
  [1252745, [17.59064, 80.32146]],
  [5771826, [40.88939, -111.88077]],
  [1175082, [33.74698, 73.51229]],
  [1263120, [26.64859, 74.03414]],
  [1644360, [-6.735, 110.8444]],
  [2513811, [39.47523, -0.41825]],
  [3398570, [-6.85472, -35.49]],
  [3446077, [-21.36667, -45.5125]],
  [2214603, [25.91552, 13.91839]],
  [3695754, [-6.70111, -79.90611]],
  [1259444, [17.24754, 81.64372]],
  [219414, [1.23909, 23.61598]],
  [4166222, [26.2173, -80.22588]],
  [3124765, [40.65909, -3.76762]],
  [7302825, [16.28584, 81.25464]],
  [2545017, [34.31, -2.16]],
  [3542744, [22.37266, -80.15046]],
  [56166, [0.48829, 42.78535]],
  [1699805, [15.0646, 120.7198]],
  [1607978, [6.86814, 101.25009]],
  [2647948, [55.94838, -4.76121]],
  [3070544, [50.41135, 14.90318]],
  [1513714, [41.24944, 71.54738]],
  [2898304, [51.43787, 14.23549]],
  [2656915, [53.48876, -2.0989]],
  [2925192, [50.91089, 13.33881]],
  [1271113, [24.827, 87.2125]],
  [1265961, [27.70207, 76.19911]],
  [750317, [40.93805, 38.23148]],
  [512382, [45.02366, 39.10436]],
  [1260543, [21.59556, 78.52628]],
  [2644660, [53.49642, -2.51973]],
  [4677551, [32.54208, -97.32085]],
  [496519, [60.1444, 30.20165]],
  [3693645, [-6.71778, -79.76972]],
  [4166776, [28.56917, -81.54396]],
  [2415703, [11.42282, -9.16852]],
  [1141540, [31.82089, 64.57005]],
  [2853574, [49.20145, 7.60529]],
  [604490, [65.58415, 22.15465]],
  [5307540, [32.39091, -110.96649]],
  [1591449, [21.18608, 106.07631]],
  [1142264, [32.37451, 62.11638]],
  [2918840, [51.90425, 10.42766]],
  [3336891, [48.65698, 9.22049]],
  [75337, [15.69425, 43.60582]],
  [2644726, [51.29652, -0.3338]],
  [2111248, [38.3725, 140.2725]],
  [6318968, [-7.09829, -49.9435]],
  [8224216, [51.97938, -0.22664]],
  [1690666, [7.07444, 125.70833]],
  [1607779, [7.61786, 100.07792]],
  [3165475, [40.75337, 14.45251]],
  [1693618, [13.04603, 121.46205]],
  [656913, [60.63333, 24.86667]],
  [3991226, [21.0315, -101.87833]],
  [2802249, [51.19021, 4.43264]],
  [4744870, [39.04372, -77.48749]],
  [3832811, [-27.57383, -60.71526]],
  [2824948, [50.60911, 10.69401]],
  [2231482, [6.25, 10.26667]],
  [3113415, [40.24147, -3.69999]],
  [233070, [-1.24857, 29.98993]],
  [132961, [34.35287, 58.68365]],
  [4180531, [33.47097, -81.97484]],
  [3461370, [-28.24, -48.67028]],
  [3471772, [-28.93575, -49.49538]],
  [355939, [30.6713, 31.58801]],
  [3128832, [40.47366, -3.57777]],
  [3127958, [40.405, -3.87835]],
  [5641727, [45.67965, -111.03856]],
  [3427453, [-34.5108, -58.68059]],
  [933331, [-24.62694, 25.86556]],
  [762788, [52.10577, 21.26129]],
  [1268138, [29.32104, 77.27101]],
  [943032, [-27.36541, 29.88175]],
  [1860785, [34.15166, 135.21398]],
  [2641731, [53.06667, -0.81667]],
  [5314328, [31.55454, -110.30369]],
  [3004871, [43.57662, 7.01912]],
  [5117949, [40.6576, -73.58318]],
  [3122453, [42.26645, 2.96163]],
  [2812174, [49.54887, 8.66697]],
  [673636, [44.25, 28.28333]],
  [3336893, [50.02627, 8.88588]],
  [1731744, [6.10179, 125.29048]],
  [525138, [53.44354, 41.81065]],
  [490068, [55.08392, 21.87851]],
  [4947459, [42.45008, -73.24538]],
  [3433787, [-34.45256, -58.64312]],
  [5855927, [19.72991, -155.09073]],
  [2633551, [53.30182, -1.12404]],
  [3515505, [19.83721, -98.97607]],
  [674531, [45.68861, 21.90306]],
  [1257749, [13.00334, 80.19614]],
  [1257751, [23.94826, 87.68045]],
  [1254080, [21.38333, 79.73333]],
  [2953416, [49.8414, 7.86713]],
  [5143630, [40.71816, -73.35429]],
  [4889553, [41.92947, -88.75036]],
  [2467959, [36.84757, 11.09386]],
  [1840862, [37.85944, 126.785]],
  [2522437, [28.12271, -16.726]],
  [3995343, [20.12571, -101.19208]],
  [1152468, [18.58054, 99.00745]],
  [304355, [37.31639, 27.78389]],
  [5391749, [37.63049, -122.41108]],
  [294760, [32.15934, 34.8932]],
  [1853354, [35.65, 138.5]],
  [1856878, [37.53333, 138.93333]],
  [114049, [34.78187, 47.59945]],
  [4392388, [38.5767, -92.17352]],
  [4145941, [28.66111, -81.36562]],
  [1863978, [33.76627, 130.47461]],
  [3601311, [14.6, -87.83333]],
  [1082639, [-17.83333, 48.41667]],
  [1259892, [12.9343, 78.7189]],
  [1499350, [56.21389, 87.74722]],
  [542199, [56.60585, 57.76686]],
  [3459251, [-20.0225, -45.54361]],
  [2112444, [39.27694, 141.86801]],
  [1516601, [52.19019, 61.19894]],
  [2417988, [8.54351, -9.47099]],
  [1605069, [15.19319, 104.8628]],
  [2413920, [13.47806, -16.68194]],
  [2754669, [52.95929, 5.91854]],
  [4727326, [29.55217, -98.26973]],
  [4004024, [22.64971, -102.99032]],
  [1842966, [35.24417, 129.21389]],
  [2799645, [51.02869, 4.10106]],
  [1640354, [-8.28333, 113.36667]],
  [1276954, [25.28814, 86.02055]],
  [2869791, [51.09162, 6.89217]],
  [3182043, [45.56304, 8.05796]],
  [3457772, [-24.55611, -54.05667]],
  [540771, [59.99541, 29.76668]],
  [1631733, [-6.9875, 106.55139]],
  [1268403, [27.07059, 88.47529]],
  [2920478, [52.47774, 10.5511]],
  [301911, [38.46472, 30.26946]],
  [1258756, [16.83636, 82.02871]],
  [2219235, [26.59034, 12.77511]],
  [2293801, [9.44272, -0.00991]],
  [2400555, [-1.63333, 13.58357]],
  [1258201, [16.0184, 80.82958]],
  [5374764, [37.1305, -121.65439]],
  [3522164, [17.50953, -91.98248]],
  [5787829, [47.76232, -122.2054]],
  [3526657, [18.60157, -98.46152]],
  [5253352, [43.775, -88.43883]],
  [3172087, [40.92337, 9.49802]],
  [2455558, [12.74409, -8.07257]],
  [3110642, [41.38333, 2.05]],
  [360612, [30.61927, 31.46165]],
  [99039, [32.77872, 44.29005]],
  [464101, [56.64051, 43.39446]],
  [4504118, [39.71734, -74.96933]],
  [5104405, [40.46538, -74.33043]],
  [1257762, [19.45512, 76.44073]],
  [921772, [-11.70216, 43.25506]],
  [5467328, [36.72806, -108.21869]],
  [3166548, [43.31822, 11.33064]],
  [1275920, [24.99944, 72.27141]],
  [3166830, [40.9, 14.26667]],
  [2967421, [50.70118, 3.21812]],
  [2654186, [52.2463, 0.71111]],
  [5363943, [32.84727, -117.2742]],
  [2129211, [40.68682, 141.38969]],
  [1694498, [11.10778, 122.64194]],
  [1632566, [-7.72528, 114.09954]],
  [1277320, [12.99116, 78.17804]],
  [1691804, [6.95194, 121.96361]],
  [5920433, [45.38338, -73.74919]],
  [2943560, [49.12426, 8.59804]],
  [1255953, [9.84701, 78.48358]],
  [142679, [37.26318, 49.94325]],
  [5323525, [34.18973, -118.13118]],
  [1606050, [6.02977, 101.96586]],
  [1492401, [55.54028, 89.20083]],
  [3029096, [45.79462, 4.8464]],
  [3166711, [45.65002, 9.20548]],
  [1640972, [-4.9333, 122.5167]],
  [2645313, [53.48138, -2.89215]],
  [2340091, [12.6269, 9.38807]],
  [2634864, [54.99111, -1.53397]],
  [5386035, [33.74446, -118.38702]],
  [3514321, [18.88188, -99.06715]],
  [3540885, [22.89018, -82.50099]],
  [2843729, [48.47629, 8.93528]],
  [3403353, [-4.35889, -39.31167]],
  [3448825, [-20.58139, -47.85472]],
  [3016702, [44.55858, 6.07868]],
  [350370, [30.56482, 31.15777]],
  [1855863, [36.75, 138.36667]],
  [1289828, [16.60333, 95.18278]],
  [1254356, [9.88151, 78.07306]],
  [8533869, [49.36672, -123.16652]],
  [5377640, [38.68574, -121.37217]],
  [1256432, [10.76181, 76.27078]],
  [3524903, [17.91177, -94.09646]],
  [2639524, [50.23315, -5.22434]],
  [2231564, [5.4685, 9.8818]],
  [4902476, [41.5067, -90.51513]],
  [233476, [0.66174, 30.2748]],
  [6697039, [43.2397, -2.8858]],
  [1185260, [23.01472, 89.43936]],
  [544370, [61.31433, 47.16914]],
  [1275655, [25.21073, 84.25508]],
  [3566429, [20.73775, -76.32574]],
  [1856667, [35.36667, 137.25]],
  [6822126, [36.90123, 137.44955]],
  [8334624, [52.54608, 13.5013]],
  [1605467, [12.95772, 99.90555]],
  [1858902, [34.0, 134.58333]],
  [2228005, [4.5016, 9.5671]],
  [5120521, [43.05923, -77.61222]],
  [2973495, [44.80849, -0.58915]],
  [517269, [52.5396, 31.9275]],
  [2925034, [48.40351, 11.74876]],
  [2829962, [52.53661, 13.15057]],
  [1850746, [36.3, 139.11667]],
  [4723406, [32.93123, -96.45971]],
  [1717053, [7.67306, 126.08889]],
  [1255860, [25.92493, 73.66633]],
  [940424, [-27.8549, 26.36583]],
  [2813040, [49.67682, 12.15613]],
  [2430506, [9.30859, 15.80658]],
  [2508157, [35.67003, 3.88153]],
  [1266616, [20.18268, 85.61629]],
  [146617, [35.12489, 33.94135]],
  [1269168, [29.27919, 78.82798]],
  [4006806, [27.96166, -110.81411]],
  [1254675, [22.57422, 71.19942]],
  [534341, [59.90612, 29.77253]],
  [2892080, [47.88238, 10.62192]],
  [2110744, [38.39306, 140.88611]],
  [2634579, [51.46246, 0.10759]],
  [2130741, [44.02127, 144.26971]],
  [1861223, [36.05, 139.9]],
  [90150, [34.61581, 43.67861]],
  [169372, [33.53719, 36.36635]],
  [3530617, [16.68393, -93.71814]],
  [2696334, [59.36667, 18.13333]],
  [1840536, [35.0292, 126.7175]],
  [2858738, [50.20731, 8.57747]],
  [3445126, [-27.00833, -51.15167]],
  [1268896, [19.61186, 76.6874]],
  [2866333, [51.31667, 6.28333]],
  [2112527, [38.10472, 140.85944]],
  [3038224, [44.12489, 4.08082]],
  [1266794, [28.86586, 77.2841]],
  [5388564, [38.33964, -122.7011]],
  [5920288, [46.23459, -63.1256]],
  [1649595, [-7.3, 109.21667]],
  [1272532, [26.13526, 89.46129]],
  [337405, [8.25, 34.58333]],
  [1639524, [-4.82505, 104.8817]],
  [98459, [32.92556, 44.77583]],
  [3396277, [-7.87472, -35.45028]],
  [1865375, [33.41804, 130.65413]],
  [4914570, [40.11059, -88.20727]],
  [1262117, [29.39743, 79.44686]],
  [2202064, [-17.80309, 177.41617]],
  [711350, [50.54345, 30.21201]],
  [2657324, [51.21135, -1.49393]],
  [7302832, [19.66781, 78.5289]],
  [5439752, [39.58721, -104.95276]],
  [4003662, [20.64337, -100.99286]],
  [1308204, [18.28651, 95.32014]],
  [3861445, [-29.16195, -67.4974]],
  [6354895, [48.44879, -68.52396]],
  [65170, [2.34464, 42.27644]],
  [1256322, [27.68859, 78.37985]],
  [1185251, [22.33774, 90.73708]],
  [3533826, [22.32691, -79.23567]],
  [3651438, [-2.22622, -80.85873]],
  [729559, [43.13333, 24.71667]],
  [3175860, [40.81748, 16.41915]],
  [5309858, [34.61002, -112.31572]],
  [1518518, [43.30235, 77.23811]],
  [587078, [40.4444, 49.80566]],
  [1260674, [13.2, 78.74725]],
  [3723440, [18.44773, -72.28398]],
  [1735018, [3.4854, 102.3484]],
  [3505855, [19.58469, -70.98489]],
  [1519948, [43.86681, 77.06304]],
  [11549773, [41.38939, 2.15517]],
  [5213681, [40.79339, -77.86]],
  [1269477, [20.84852, 86.33729]],
  [1838069, [37.44056, 129.17083]],
  [1722267, [14.95806, 120.91778]],
  [5099967, [40.76843, -74.14542]],
  [2658377, [46.75118, 7.62166]],
  [1274984, [22.31399, 88.66508]],
  [1267175, [27.52698, 80.75461]],
  [554894, [42.56955, 47.86447]],
  [3652257, [0.32779, -79.47407]],
  [7284828, [47.50369, 19.06583]],
  [2894375, [51.22929, 6.61883]],
  [2037393, [47.57549, 130.82306]],
  [5345679, [38.68574, -121.08217]],
  [2465840, [35.03823, 9.48494]],
  [3454231, [-10.29028, -36.58639]],
  [3466704, [-24.78927, -50.01225]],
  [98112, [34.01485, 44.14574]],
  [3516843, [16.32463, -95.24104]],
  [2805761, [52.42377, 9.43585]],
  [1162959, [25.36329, 69.74184]],
  [1268020, [20.25684, 75.13786]],
  [2461874, [26.73841, -11.67194]],
  [1278208, [16.72613, 75.06421]],
  [3058472, [47.98544, 18.16195]],
  [465543, [55.3195, 52.06942]],
  [2298264, [7.06273, -1.4001]],
  [5038108, [46.87386, -96.76951]],
  [1526193, [50.24915, 66.92027]],
  [8341919, [51.3509, 25.84738]],
  [196185, [0.67028, 35.50806]],
  [1798636, [37.2025, 112.17806]],
  [3698390, [-7.22436, -79.42476]],
  [1275762, [23.9205, 76.91074]],
  [3468893, [-14.20361, -41.66528]],
  [5145067, [40.74538, -73.90541]],
  [2933882, [52.83492, 13.81951]],
  [2951881, [51.18035, 14.43494]],
  [2493918, [34.95139, -1.36806]],
  [1185204, [23.11134, 89.09061]],
  [2720383, [60.4858, 15.43714]],
  [2636663, [53.45, -2.31667]],
  [5330582, [33.91668, -117.90006]],
  [2878943, [52.02786, 8.89901]],
  [1216787, [39.12158, 66.88605]],
  [789518, [45.82972, 20.46528]],
  [353219, [29.81667, 31.05]],
  [3451124, [-26.25444, -49.51833]],
  [878281, [-9.99709, 39.71649]],
  [3443758, [-30.4, -56.46667]],
  [1614465, [13.59072, 100.10748]],
  [2939951, [50.25937, 10.96384]],
  [5780557, [40.52189, -111.9391]],
  [2473517, [34.32081, 8.40157]],
  [3178784, [45.21857, 12.27774]],
  [7577022, [40.60092, -3.70806]],
  [3392251, [-8.35778, -36.69639]],
  [1267461, [21.27388, 78.5858]],
  [3456873, [-21.26111, -48.49639]],
  [321337, [37.69278, 37.86111]],
  [3522484, [16.90639, -92.09374]],
  [4503136, [39.934, -74.891]],
  [12157070, [-12.05354, -77.09802]],
  [1278335, [20.99585, 78.22914]],
  [3690654, [-5.90181, -76.12234]],
  [3188383, [46.30444, 16.33778]],
  [1272657, [22.19101, 88.19047]],
  [4736388, [33.08901, -96.88639]],
  [2020812, [45.47885, 133.42825]],
  [2949012, [48.94407, 9.11755]],
  [1277882, [28.94485, 77.21865]],
  [4771401, [38.75095, -77.47527]],
  [103035, [22.79856, 39.03493]],
  [709354, [48.39869, 37.84787]],
  [1277038, [25.21855, 85.7332]],
  [3182749, [42.85351, 13.57395]],
  [1264912, [22.56507, 71.81076]],
  [786827, [44.62133, 21.18782]],
  [709334, [45.71168, 34.39274]],
  [2113077, [35.71667, 140.65]],
  [1645428, [-7.57897, 112.23109]],
  [3177300, [44.29007, 11.87948]],
  [1275647, [26.18067, 73.7055]],
  [2651048, [51.12598, 1.31257]],
  [1255437, [22.11667, 84.03333]],
  [4955190, [42.12509, -72.74954]],
  [3393264, [-8.68333, -35.59167]],
  [1850818, [34.85, 136.91667]],
  [2614030, [56.1697, 9.54508]],
  [2798554, [50.87441, 4.39905]],
  [544896, [45.46899, 39.45136]],
  [1263594, [15.99126, 77.05034]],
  [1271892, [25.50958, 85.30504]],
  [1505526, [57.67052, 63.071]],
  [478544, [50.8047, 42.0138]],
  [3509207, [19.05272, -70.14939]],
  [2977356, [45.69651, 4.94385]],
  [2477528, [35.48171, 6.26074]],
  [3628267, [10.26061, -67.79348]],
  [6151350, [46.04178, -73.11358]],
  [2982757, [48.87017, 2.4991]],
  [1500997, [55.714, 60.5528]],
  [561731, [51.4666, 58.4552]],
  [2417833, [10.37546, -12.09148]],
  [1183224, [30.57759, 73.83912]],
  [2881646, [49.19844, 8.11692]],
  [694216, [50.18118, 27.06517]],
  [1269350, [22.05236, 72.80074]],
  [3449427, [-21.45667, -43.5525]],
  [3939470, [-12.78261, -74.97266]],
  [4273299, [38.06084, -97.92977]],
  [4941873, [42.52509, -71.75979]],
  [1273128, [9.94704, 78.8233]],
  [1619602, [13.91783, 100.42403]],
  [4350635, [39.27205, -76.73192]],
  [3393409, [-4.1725, -38.46056]],
  [293539, [32.80583, 35.16895]],
  [2127515, [45.40944, 141.67389]],
  [1272596, [20.1035, 77.71846]],
  [2657402, [53.38752, -2.34848]],
  [5120656, [40.76843, -73.52513]],
  [3547976, [22.15021, -79.97867]],
  [3629419, [9.92866, -69.6201]],
  [1252925, [20.22885, 79.00277]],
  [3569024, [20.81914, -77.57958]],
  [3598465, [14.8, -89.54583]],
  [2907201, [51.06358, 6.0998]],
  [3583178, [13.63333, -88.8]],
  [6696686, [60.74985, 72.85824]],
  [3460242, [-13.53056, -39.97083]],
  [3472311, [-22.70111, -46.76444]],
  [4885955, [42.15141, -87.95979]],
  [1269979, [17.77074, 77.12519]],
  [1809159, [39.73054, 118.44085]],
  [3625066, [9.35029, -65.32452]],
  [478581, [43.13053, 45.53791]],
  [697592, [48.62988, 38.54806]],
  [5225809, [42.00288, -71.51478]],
  [3184862, [40.94194, 19.705]],
  [5964347, [55.16667, -118.80271]],
  [3530385, [18.26316, -93.22397]],
  [117793, [36.6793, 46.5669]],
  [8030162, [34.19731, -118.64398]],
  [281292, [31.4459, 35.09443]],
  [1271847, [22.9235, 78.7849]],
  [210379, [-4.97503, 23.44391]],
  [1851813, [37.76354, 139.02199]],
  [2783615, [51.16734, 4.39513]],
  [2976341, [43.64229, 5.09478]],
  [532459, [53.86639, 34.44778]],
  [3427687, [-34.47513, -58.75367]],
  [1694861, [14.83111, 120.78917]],
  [3203099, [44.05722, 17.45083]],
  [5793427, [47.81065, -122.37736]],
  [504250, [52.01333, 48.8025]],
  [2341294, [12.89439, 11.92649]],
  [1855540, [28.36667, 129.48333]],
  [2268339, [37.01869, -7.92716]],
  [2652622, [51.522, -3.55531]],
  [2641690, [54.9876, -1.74415]],
  [1640044, [0.9, 104.63333]],
  [8315400, [51.55237, -0.12497]],
  [6693470, [53.7426, -2.997]],
  [1274102, [28.23937, 75.64035]],
  [5303705, [32.43674, -111.22538]],
  [3109493, [41.37426, 2.13826]],
  [2394545, [6.79911, 1.78073]],
  [597188, [56.31667, 22.33333]],
  [1223648, [7.0281, 79.9014]],
  [463824, [44.40694, 43.88056]],
  [3462580, [-22.21056, -49.65611]],
  [362882, [30.91018, 30.17438]],
  [4842898, [41.31649, -73.09316]],
  [673441, [46.35, 25.8]],
  [1700753, [14.896, 120.7092]],
  [2654730, [51.12837, -3.00356]],
  [2833564, [53.4, 9.96667]],
  [2499104, [36.53628, 3.8334]],
  [1707123, [16.455, 120.5875]],
  [4850699, [42.52776, -92.44547]],
  [5336054, [34.25723, -118.6012]],
  [1274837, [22.03532, 82.64234]],
  [344420, [14.12109, 38.72337]],
  [1261696, [25.38807, 87.09906]],
  [2880498, [52.43623, 13.3459]],
  [2299349, [6.61667, -1.21667]],
  [1273390, [25.03473, 84.40095]],
  [1269936, [13.0707, 77.79814]],
  [1061412, [-16.16864, 49.76622]],
  [3170102, [43.30491, 13.72068]],
  [4893365, [41.79503, -87.69616]],
  [2544720, [32.5977, -6.26844]],
  [2635062, [53.44852, -2.35419]],
  [1256340, [23.4871, 80.10404]],
  [102985, [26.70791, 50.06194]],
  [4930505, [42.55843, -70.88005]],
  [351434, [26.04949, 32.24142]],
  [1269570, [16.8938, 80.09807]],
  [752967, [52.0488, 20.44599]],
  [3385742, [-9.16278, -36.03194]],
  [2515692, [28.39076, -16.52309]],
  [1266128, [18.81199, 82.71048]],
  [294097, [32.70816, 35.32469]],
  [1272674, [26.58904, 89.00732]],
  [2885734, [50.68376, 7.18675]],
  [7260219, [28.07389, -82.43902]],
  [3453535, [-25.44227, -49.06795]],
  [1710770, [14.19528, 120.87694]],
  [4683217, [32.95457, -97.01501]],
  [2476396, [36.8, 4.26667]],
  [3436714, [-25.74946, -56.43518]],
  [1708226, [10.4242, 122.9212]],
  [665355, [45.84973, 27.43441]],
  [1165789, [26.42495, 67.86126]],
  [5153924, [41.04422, -83.64993]],
  [3113331, [40.03116, -6.08845]],
  [1856881, [36.01667, 139.98333]],
  [563705, [55.62945, 37.85654]],
  [2482211, [34.63703, -1.33143]],
  [3089658, [50.03437, 19.21037]],
  [2633708, [51.4112, -0.83565]],
  [3448616, [-22.11639, -45.05444]],
  [2979783, [48.89643, 2.0904]],
  [2111065, [38.75861, 140.30083]],
  [1850217, [37.13333, 138.76667]],
  [2871992, [47.98372, 10.18527]],
  [2262957, [38.44451, -9.10149]],
  [2816630, [49.25162, 6.85873]],
  [229139, [0.4175, 32.02278]],
  [2524119, [36.85868, 14.75966]],
  [1005125, [-26.53333, 29.98333]],
  [5333689, [37.28717, -121.94996]],
  [3180733, [43.84171, 10.57271]],
  [1702649, [6.415, 125.61167]],
  [1586185, [22.66568, 106.25786]],
  [3556168, [20.36785, -76.42741]],
  [3182957, [41.59452, 12.65419]],
  [2477462, [33.11667, 6.08333]],
  [2522152, [37.96939, -1.21714]],
  [1513983, [40.10223, 64.68226]],
  [173322, [34.5078, 36.58029]],
  [4768351, [38.68789, -77.29776]],
  [4749950, [38.79345, -77.27165]],
  [1276103, [26.75231, 79.2218]],
  [5036420, [44.15906, -94.00915]],
  [293286, [32.51725, 35.15349]],
  [3452779, [-23.21472, -47.52389]],
  [576317, [55.42111, 55.54278]],
  [3034640, [42.70278, 9.45]],
  [426272, [-3.42708, 29.92463]],
  [791149, [42.00778, 21.35306]],
  [3389822, [-0.61361, -47.35611]],
  [4903466, [41.80753, -87.65644]],
  [4288809, [39.08367, -84.50855]],
  [5127536, [40.84982, -73.91986]],
  [3110627, [40.42386, -3.53261]],
  [4215114, [33.9701, -84.22159]],
  [1258952, [23.33033, 77.7811]],
  [5492450, [35.01005, -106.67808]],
  [4167178, [29.28581, -81.05589]],
  [3840860, [-31.68333, -68.58333]],
  [1268834, [12.57025, 78.57318]],
  [2635427, [51.31889, -2.20861]],
  [4150118, [28.06752, -82.52093]],
  [387958, [29.29583, 48.07861]],
  [2128558, [42.99167, 143.20028]],
  [4013723, [25.0321, -111.66256]],
  [2802170, [51.04954, 5.22606]],
  [3083955, [53.91053, 14.24712]],
  [1713004, [15.6065, 120.5978]],
  [6545103, [41.37452, 2.16326]],
  [188657, [0.39337, 34.30399]],
  [3388269, [-9.78111, -36.09361]],
  [2639928, [53.42948, -2.80031]],
  [562389, [49.77133, 43.66222]],
  [2946228, [51.84382, 6.85774]],
  [1189056, [24.31988, 90.16943]],
  [1169145, [31.96258, 73.97117]],
  [1832215, [35.99526, 129.35162]],
  [3387604, [-8.59083, -35.11611]],
  [2667094, [59.19858, 17.83317]],
  [524736, [43.74359, 44.65177]],
  [101312, [31.67252, 38.66374]],
  [4597948, [33.92044, -80.34147]],
  [1275050, [21.50976, 83.87259]],
  [3402229, [-3.74167, -43.36028]],
  [2843350, [52.42407, 13.48529]],
  [3668572, [3.00945, -76.48494]],
  [2234794, [6.75, 11.81667]],
  [3684945, [7.60347, -74.80951]],
  [1626895, [-5.1241, 120.253]],
  [616629, [40.49748, 44.7662]],
  [2857129, [52.75577, 13.24197]],
  [1862230, [32.45993, 130.16713]],
  [5219488, [41.24591, -75.88131]],
  [315985, [39.59611, 27.02444]],
  [935616, [-20.93752, 55.29415]],
  [1860176, [34.55808, 133.41796]],
  [746574, [40.1049, 27.65399]],
  [4899966, [41.97587, -87.68922]],
  [3521941, [22.05373, -98.18498]],
  [5364066, [34.02001, -117.94951]],
  [2479183, [36.51833, 2.90528]],
  [1858866, [35.0, 136.51667]],
  [1718436, [10.1061, 123.6402]],
  [2974733, [43.4028, 3.69278]],
  [913613, [-12.09514, 26.42727]],
  [2746331, [51.88667, 5.42917]],
  [2514287, [37.34461, -6.06391]],
  [2311968, [-2.72037, 17.69001]],
  [2119538, [53.18909, 158.38135]],
  [3469437, [-13.255, -43.41806]],
  [1864945, [35.08834, 139.05325]],
  [4939783, [42.20426, -72.6162]],
  [787050, [43.15306, 22.58611]],
  [291696, [25.33132, 56.34199]],
  [1273704, [23.16903, 72.82161]],
  [4728328, [33.63566, -96.60888]],
  [1271493, [24.16002, 83.80755]],
  [2616038, [55.22992, 11.76092]],
  [3037612, [43.48921, -1.51921]],
  [667227, [47.93195, 23.88603]],
  [2985034, [48.88341, 2.23894]],
  [1567621, [21.14053, 105.50686]],
  [4580098, [32.98101, -80.03259]],
  [3690577, [3.98695, -73.75797]],
  [3445350, [-29.60639, -52.19194]],
  [1179223, [29.19344, 72.85525]],
  [2506043, [36.74472, 4.37222]],
  [3646451, [10.34985, -66.98632]],
  [3520274, [18.83036, -97.156]],
  [358274, [27.64148, 30.85012]],
  [3676397, [9.23648, -75.8135]],
  [2738752, [41.44443, -8.29619]],
  [2853658, [53.66732, 9.78936]],
  [5036588, [44.95302, -92.99522]],
  [1725684, [8.75611, 125.7675]],
  [1185095, [20.85829, 92.29773]],
  [4912691, [42.02558, -88.17841]],
  [1854444, [37.3, 138.8]],
  [2514216, [39.56964, 3.20955]],
  [4936812, [42.58342, -71.8023]],
  [3023763, [48.60603, 2.48757]],
  [1278868, [26.70423, 78.22678]],
  [1185210, [22.97372, 90.22376]],
  [2754848, [52.34167, 5.62083]],
  [4581832, [32.19382, -80.73816]],
  [3179977, [43.67557, 10.55494]],
  [162627, [33.96921, 36.65729]],
  [1851155, [34.91667, 136.98333]],
  [145531, [-9.66078, 20.39155]],
  [1185272, [23.043, 89.63094]],
  [1176995, [34.05348, 73.15993]],
  [1608597, [16.54531, 104.72351]],
  [2655777, [50.85023, 0.47095]],
  [5364079, [33.66336, -116.31001]],
  [4212937, [33.8651, -84.33659]],
  [1056151, [-19.16667, 46.73333]],
  [581313, [44.46472, 39.73417]],
  [1268376, [11.7404, 78.959]],
  [4889229, [42.24113, -88.3162]],
  [1629974, [-2.9701, 119.8978]],
  [1271345, [26.15272, 80.16803]],
  [4357141, [39.64176, -77.71999]],
  [5392034, [34.09611, -118.10583]],
  [1844308, [35.91333, 128.82]],
  [1279123, [16.58225, 81.38112]],
  [704679, [47.74988, 29.53074]],
  [6184365, [43.13339, -80.7497]],
  [3471395, [-22.57306, -47.1725]],
  [226110, [0.69299, 34.18085]],
  [2518770, [37.5422, -5.0826]],
  [1214055, [5.21667, 96.28333]],
  [3463865, [-29.64833, -51.17389]],
  [1298911, [18.4813, 96.43742]],
  [1848573, [33.16667, 130.4]],
  [361827, [30.82796, 30.53552]],
  [3866163, [-31.65292, -64.42826]],
  [363619, [4.09444, 30.67639]],
  [363656, [4.57083, 28.39417]],
  [1265886, [17.0162, 81.72934]],
  [4470778, [35.73319, -81.3412]],
  [2935220, [50.01997, 8.69611]],
  [724144, [48.75434, 21.9195]],
  [1527513, [40.76994, 73.30068]],
  [4886662, [41.91253, -88.13479]],
  [4178550, [28.56528, -81.58618]],
  [3177608, [45.61831, 9.20249]],
  [2853572, [50.95843, 13.93702]],
  [1319533, [16.90802, 96.56037]],
  [3153623, [59.41378, 5.268]],
  [2954006, [53.46919, 7.48232]],
  [196742, [-0.52731, 34.45714]],
  [2110257, [1.3278, 172.97696]],
  [2019951, [62.53528, 113.96111]],
  [3469516, [-19.73639, -45.25222]],
  [3456827, [-22.94667, -47.31583]],
  [750598, [40.14192, 29.97932]],
  [1255850, [30.90908, 77.10869]],
  [511359, [56.73934, 38.85626]],
  [1583477, [21.38602, 103.02301]],
  [2378538, [16.62073, -11.40208]],
  [1580142, [20.64637, 106.05112]],
  [1702372, [14.2349, 121.15902]],
  [2179670, [-39.93333, 175.05]],
  [2100633, [-6.22977, 155.56598]],
  [2482939, [36.45774, 6.04267]],
  [247105, [31.87032, 36.00479]],
  [3173995, [41.03064, 14.29868]],
  [2165171, [-37.87811, 145.16476]],
  [927246, [-14.47815, 35.26448]],
  [4013724, [27.67842, -105.17136]],
  [1279227, [18.70622, 76.93731]],
  [1270619, [27.11518, 79.73174]],
  [2861632, [48.62565, 9.34203]],
  [2890473, [48.64683, 9.45378]],
  [2387546, [6.31933, 16.37992]],
  [481964, [59.54, 30.8775]],
  [5100886, [40.31539, -74.24626]],
  [2512169, [36.52819, -6.19011]],
  [1642317, [-6.715, 111.1514]],
  [2290412, [7.0591, -3.9631]],
  [1156257, [17.01717, 99.73283]],
  [118704, [31.26562, 56.80545]],
  [120694, [33.72654, 59.18439]],
  [148942, [-9.10806, 32.93472]],
  [2144095, [-37.9, 144.66667]],
  [3564114, [22.6493, -80.04935]],
  [3085151, [54.4418, 18.56003]],
  [2493956, [36.50361, 7.44278]],
  [770380, [50.01623, 22.67776]],
  [1259879, [10.12268, 77.54372]],
  [1263580, [15.59154, 73.80898]],
  [3660361, [-1.36501, -79.90494]],
  [533690, [58.73722, 29.84528]],
  [2315417, [-1.9275, 18.2881]],
  [1255211, [22.58867, 88.93253]],
  [1285871, [17.04459, 95.63957]],
  [585557, [40.52983, 50.00616]],
  [1256983, [15.76615, 75.11778]],
  [551964, [54.84444, 38.16694]],
  [1174357, [28.06437, 69.70363]],
  [3458147, [-26.11139, -49.80528]],
  [5105262, [40.8976, -74.01597]],
  [1735086, [5.16586, 100.47793]],
  [3992986, [20.43108, -101.72261]],
  [5368361, [34.05223, -118.24368]],
  [2126407, [62.6482, 149.11338]],
  [4132093, [36.18674, -94.12881]],
  [2643318, [51.72598, -2.52605]],
  [4166253, [26.81756, -80.08199]],
  [130091, [36.63619, 57.07284]],
  [3171223, [45.97471, 13.32304]],
  [3522684, [19.65424, -90.3359]],
  [2682834, [57.17467, 13.59498]],
  [3818572, [20.70333, -97.78438]],
  [2181001, [-40.473, 175.50934]],
  [2855632, [49.56667, 6.36667]],
  [3981898, [20.87698, -104.5198]],
  [123295, [37.9041, 55.95596]],
  [3163866, [45.1221, 9.0013]],
  [2123278, [54.6961, 158.62067]],
  [2145929, [-28.15, 153.5]],
  [3590406, [14.78828, -90.19876]],
  [11788280, [51.78353, 0.13381]],
  [666035, [44.56667, 24.78333]],
  [686006, [44.6, 26.78333]],
  [2518882, [37.3262, -3.59568]],
  [8392276, [-6.889, 113.3835]],
  [7911643, [-7.3808, 107.8196]],
  [2890880, [51.26171, 11.08999]],
  [2981568, [46.99194, -1.04316]],
  [2772537, [48.15, 14.0]],
  [767474, [49.44787, 20.33956]],
  [933432, [-22.6155, 27.3294]],
  [6294764, [47.49265, 8.8668]],
  [1907979, [34.89139, 105.71917]],
  [2950979, [50.65, 10.6]],
  [1922095, [27.34184, 119.56373]],
  [3053779, [45.995, 18.91722]],
  [2985934, [43.38734, 4.82609]],
  [299643, [36.3214, 33.88165]],
  [3066765, [49.88825, 15.53144]],
  [1641399, [-7.1894, 111.5567]],
  [2769411, [47.45, 16.05]],
  [2749170, [50.86539, 5.66444]],
  [3557432, [22.04956, -79.46106]],
  [8423533, [-8.2476, 123.7581]],
  [2986802, [48.33595, -2.6822]],
  [8310674, [-33.60944, 150.71674]],
  [1787245, [27.15111, 119.01583]],
  [3516005, [16.96667, -92.41667]],
  [694211, [47.79727, 25.15455]],
  [688440, [49.19271, 26.83437]],
  [3525707, [15.51033, -92.22603]],
  [5302479, [32.13036, -110.87287]],
  [3557347, [22.79613, -82.50667]],
  [3189940, [45.71484, 14.47097]],
  [3354247, [-17.91667, 15.95]],
  [1260909, [26.90403, 83.98087]],
  [1728539, [13.5289, 121.8677]],
  [1254813, [25.49043, 85.94001]],
  [308455, [37.77106, 40.6]],
  [6693821, [45.39556, 9.2668]],
  [1486164, [56.8419, 62.5014]],
  [2803323, [50.41158, 4.58671]],
  [4199185, [33.0682, -83.22349]],
  [1553475, [29.89235, 117.72406]],
  [2949837, [50.54, 8.00667]],
  [9858858, [36.58907, 4.33505]],
  [2771870, [47.29565, 16.39564]],
  [3673397, [5.10112, -73.05123]],
  [2772798, [47.20797, 10.14184]],
  [2956710, [49.74657, 8.11513]],
  [673305, [47.78333, 26.0]],
  [3182113, [44.98689, 9.95604]],
  [767145, [50.42323, 22.73526]],
  [3001546, [48.41649, -1.97148]],
  [3623580, [10.21682, -83.78483]],
  [2720485, [58.56667, 15.28333]],
  [3973956, [22.916, -100.39664]],
  [3020500, [45.89891, -0.95275]],
  [1552658, [31.06429, 118.21579]],
  [1049861, [-24.68667, 33.53056]],
  [1800698, [28.12302, 114.96312]],
  [6619433, [51.12981, 1.15837]],
  [1727995, [14.95472, 120.89694]],
  [2489279, [35.79234, 5.97706]],
  [3625214, [10.96985, -68.54648]],
  [5586437, [43.6135, -116.20345]],
  [4727629, [29.78079, -96.15718]],
  [2781827, [48.69158, 16.86951]],
  [3018078, [50.14192, 3.6475]],
  [5215373, [40.15732, -76.0505]],
  [1628162, [-5.13326, 105.43595]],
  [4450753, [33.60762, -88.65033]],
  [3175789, [45.36805, 7.25801]],
  [1280048, [32.21667, 92.11667]],
  [5322027, [33.59393, -112.30294]],
  [4376423, [38.54116, -90.28011]],
  [9036391, [42.99613, 11.95412]],
  [586031, [40.30446, 49.6236]],
  [3037070, [43.00271, -0.09914]],
  [7510880, [16.09631, 99.48906]],
  [8052333, [-7.0956, 108.2742]],
  [6535858, [44.80624, 7.58512]],
  [3088013, [49.97838, 19.17046]],
  [532138, [57.88501, 43.8049]],
  [2636465, [52.774, -1.55744]],
  [3515408, [20.96912, -98.54149]],
  [1812994, [24.01094, 114.7872]],
  [1736309, [6.12104, 100.36014]],
  [8948760, [39.3075, 16.2177]],
  [572070, [55.20639, 36.48611]],
  [288955, [23.84944, 57.43861]],
  [11903643, [46.61773, 14.30931]],
  [2971275, [46.66286, 4.02452]],
  [719252, [47.25, 20.68333]],
  [2164883, [-17.09959, 145.78038]],
  [8416628, [29.90059, 107.0229]],
  [3005982, [48.19944, -2.5351]],
  [2860505, [50.63333, 11.13333]],
  [1609043, [16.77983, 101.24225]],
  [2841876, [51.67745, 11.56795]],
  [11746596, [48.25014, 16.09785]],
  [7306514, [7.06691, 171.55413]],
  [3935694, [-14.76861, -70.90194]],
  [3065865, [50.36677, 15.3814]],
  [3105684, [42.25111, -4.96708]],
  [3014274, [47.91096, -2.46651]],
  [1802644, [34.95528, 108.31503]],
  [1253182, [17.3381, 77.90441]],
  [8140981, [-6.9816, 112.4469]],
  [2986018, [43.56479, -1.17911]],
  [2024579, [50.67722, 106.18056]],
  [3014717, [43.75844, 5.88351]],
  [5128549, [40.91149, -73.78235]],
  [479123, [54.32824, 48.38657]],
  [3175398, [45.67691, 12.10119]],
  [3466703, [-12.76556, -39.42833]],
  [522803, [56.09349, 37.49934]],
  [4029444, [20.66852, -100.51173]],
  [8348020, [-36.44729, 145.41422]],
  [109059, [25.38333, 49.66667]],
  [1255963, [28.9293, 79.70436]],
  [3185218, [41.40306, 19.51806]],
  [3172012, [45.87149, 9.81935]],
  [2875426, [53.71926, 12.26315]],
  [7741549, [29.80877, 114.53912]],
  [8349187, [-35.1728, 149.11147]],
  [1636438, [3.81284, 126.75135]],
  [2780190, [47.84565, 16.52327]],
  [5119211, [40.55427, -74.15153]],
  [1712883, [10.9357, 122.4932]],
  [526185, [41.42402, 47.84669]],
  [2659700, [46.72709, 8.1872]],
  [2649650, [53.55, -2.4]],
  [1687564, [15.6447, 120.8612]],
  [3129644, [41.06255, -4.72042]],
  [2659904, [47.17458, 7.45775]],
  [11001771, [46.90698, 26.91919]],
  [5209304, [40.79923, -79.31448]],
  [3178218, [45.62291, 9.662]],
  [10770254, [17.50455, 82.56597]],
  [8305443, [-8.0826, 111.9982]],
  [6695559, [-33.93627, 151.14819]],
  [2756419, [51.2475, 3.44306]],
  [1270627, [16.86773, 77.39088]],
  [8199392, [31.93927, 35.13539]],
  [3826962, [19.49512, -99.8422]],
  [2337759, [6.88901, 3.01416]],
  [711871, [47.0275, 30.46069]],
  [3086181, [51.50677, 19.02763]],
  [5405430, [33.21837, -117.0342]],
  [6535602, [44.87402, 10.98087]],
  [5363922, [33.93196, -117.94617]],
  [1855694, [36.75965, 137.36215]],
  [3111589, [42.22726, 3.0419]],
  [2807240, [49.98596, 6.89308]],
  [6946885, [51.93512, -0.53633]],
  [3027011, [45.0694, 5.73151]],
  [3120789, [40.88815, -1.96014]],
  [359900, [29.56472, 31.28111]],
  [3177885, [45.82768, 10.10076]],
  [2983382, [45.75311, 0.36104]],
  [8860707, [19.78444, -99.66139]],
  [3026230, [45.71299, 1.60511]],
  [2980562, [49.2892, 2.19904]],
  [3089791, [54.21256, 18.95142]],
  [1817502, [31.00137, 115.60182]],
  [674805, [44.09783, 25.19643]],
  [4016040, [21.46532, -104.8008]],
  [1630962, [-6.6425, 111.0625]],
  [3008020, [47.602, 2.02837]],
  [2338400, [6.81944, 3.91731]],
  [5262113, [43.79719, -90.07735]],
  [2850242, [54.2813, 10.345]],
  [4742616, [29.82022, -94.38408]],
  [3064677, [49.02858, 17.72205]],
  [8348919, [-25.26583, 152.55212]],
  [3468157, [-24.73611, -48.12278]],
  [251332, [39.66717, 22.39597]],
  [1785000, [36.1245, 112.86101]],
  [2743877, [51.5925, 5.54167]],
  [2791956, [50.09897, 5.73984]],
  [2752367, [52.91551, 5.44834]],
  [6535817, [45.16755, 7.95723]],
  [8348988, [-34.76565, 138.71383]],
  [70979, [15.17177, 44.32442]],
  [146198, [34.89597, 32.96817]],
  [2220309, [27.44707, 13.05173]],
  [5803666, [47.46232, -121.99567]],
  [3203246, [45.3134, 16.9544]],
  [4503195, [39.73928, -75.22407]],
  [2516490, [37.1139, -4.70298]],
  [1702215, [15.58719, 120.39644]],
  [3521297, [20.2253, -99.81537]],
  [5855420, [21.59284, -158.10339]],
  [11747244, [54.0232, -2.10165]],
  [2965664, [52.51583, -7.88556]],
  [1680566, [11.83972, 125.06139]],
  [3842621, [-31.91353, -63.68201]],
  [2719312, [59.35, 18.2]],
  [2623394, [54.8894, 9.67465]],
  [3169814, [44.24478, 8.02149]],
  [3130475, [40.92168, -5.69273]],
  [704705, [50.06849, 34.74697]],
  [2156695, [-37.6, 143.88333]],
  [6535543, [44.7666, 9.05001]],
  [2142507, [-37.65794, 145.37416]],
  [2640111, [51.75182, -4.21718]],
  [2749626, [52.3375, 6.10972]],
  [2638348, [53.49652, -0.67429]],
  [2038490, [41.23968, 124.89026]],
  [2823403, [54.35214, 8.82794]],
  [2772229, [47.10332, 15.68942]],
  [5067896, [40.13722, -97.18059]],
  [7064541, [28.3257, 117.37409]],
  [719656, [47.36667, 20.1]],
  [3120913, [41.77303, -3.68755]],
  [2911077, [50.95421, 11.45428]],
  [3028946, [50.59561, 3.26082]],
  [3062708, [49.57866, 17.95458]],
  [1695065, [12.11907, 119.84861]],
  [4023927, [20.37624, -101.96779]],
  [679549, [47.35537, 23.183]],
  [63571, [2.80257, 44.07805]],
  [2778404, [47.23356, 9.63458]],
  [2933372, [51.41667, 11.28333]],
  [3801609, [16.83706, -96.72948]],
  [8948706, [38.02514, 12.5484]],
  [2641294, [53.81667, -0.61667]],
  [1145799, [33.55267, 68.29828]],
  [3940150, [-12.81139, -75.765]],
  [2971092, [49.37866, 1.00204]],
  [8948982, [43.62652, 11.51844]],
  [262398, [38.02524, 23.78257]],
  [232713, [0.21548, 32.53453]],
  [3182522, [43.75115, 11.32252]],
  [2846040, [52.31152, 9.3564]],
  [5215076, [41.29175, -75.87464]],
  [3190566, [44.24306, 15.44861]],
  [2876117, [51.38333, 14.4]],
  [2970217, [47.3196, 4.90594]],
  [4412820, [38.54922, -90.49262]],
  [1159384, [34.74, 60.7756]],
  [3601496, [15.95, -85.71667]],
  [2993320, [44.07105, 2.09677]],
  [751324, [41.03903, 28.85671]],
  [1807878, [32.6868, 120.25441]],
  [672619, [44.49968, 23.86698]],
  [3185652, [40.65583, 19.5025]],
  [1799104, [30.21558, 113.93477]],
  [1924377, [28.74677, 118.26191]],
  [8322168, [-8.049, 111.717]],
  [611964, [42.09595, 43.08248]],
  [2748868, [51.76, 5.78889]],
  [679138, [47.4502, 26.51326]],
  [8347745, [-34.00439, 150.77253]],
  [2974677, [46.56929, 0.46548]],
  [665664, [45.21667, 27.43333]],
  [1806942, [30.37747, 113.30108]],
  [3030314, [47.30824, 5.183]],
  [3318643, [43.14528, 19.85889]],
  [3823335, [19.42194, -96.41472]],
  [2658057, [47.1122, 7.77798]],
  [8058055, [-6.7797, 111.165]],
  [2771010, [46.70806, 15.77472]],
  [6697318, [50.91299, 0.34332]],
  [8572005, [4.12685, 97.8776]],
  [3697569, [-7.85972, -77.94333]],
  [1281098, [28.66667, 97.51667]],
  [95224, [35.27517, 43.59593]],
  [8347821, [-12.49132, 130.96407]],
  [3089461, [54.20658, 17.66816]],
  [2981393, [47.80561, 2.31274]],
  [3079923, [52.83212, 15.56763]],
  [1164306, [28.49276, 66.25999]],
  [3091141, [51.07592, 17.72284]],
  [677311, [47.03883, 23.78492]],
  [3125526, [42.95466, -9.188]],
  [3837980, [-40.73193, -64.94769]],
  [583039, [43.49368, 43.64776]],
  [2955168, [51.75742, 11.46084]],
  [2981563, [48.04362, -1.462]],
  [3129010, [41.04181, -1.35767]],
  [2919877, [52.23857, 11.68361]],
  [5138660, [43.27173, -76.98914]],
  [734939, [40.0961, 23.78323]],
  [6535361, [45.81678, 9.31154]],
  [2979435, [50.87536, 1.74451]],
  [897045, [-12.1688, 26.38938]],
  [6942842, [-31.28182, -61.41092]],
  [3131449, [62.146, 6.07108]],
  [2978283, [48.38053, 0.54338]],
  [2928047, [50.85559, 13.11718]],
  [3174309, [41.52402, 13.18344]],
  [2745997, [52.18464, 5.93177]],
  [3024886, [46.64599, 0.22833]],
  [2977339, [45.6514, 1.29088]],
  [260362, [35.12271, 25.85804]],
  [2863516, [51.16025, 10.4482]],
  [4332582, [30.4913, -91.51955]],
  [752188, [40.10881, 29.51871]],
  [2927850, [50.73333, 10.36667]],
  [2520320, [38.96251, -0.58443]],
  [2871540, [52.31667, 7.78333]],
  [2274129, [7.36215, -8.71326]],
  [4297184, [37.77172, -86.90416]],
  [1270102, [25.31642, 85.28234]],
  [5272285, [44.17609, -89.11456]],
  [3516509, [18.20995, -93.0743]],
  [2849476, [49.12014, 12.13027]],
  [3018355, [43.53396, 6.3966]],
  [663889, [45.30584, 26.0376]],
  [3174913, [40.30641, 8.91858]],
  [4519591, [39.71396, -82.20848]],
  [2815138, [50.55, 7.41667]],
  [2972156, [48.19397, 1.93484]],
  [3115775, [41.55, 1.11667]],
  [5962440, [51.75012, -68.08213]],
  [689487, [50.38913, 30.3705]],
  [2987332, [45.62288, 0.68955]],
  [3019459, [48.43507, 2.16233]],
  [10113272, [-26.24182, 152.67923]],
  [765927, [51.46026, 22.60952]],
  [2173687, [-21.03494, 149.15726]],
  [3175880, [40.63457, 16.27792]],
  [2784465, [51.2952, 4.78128]],
  [3577944, [13.25636, -61.11954]],
  [294302, [31.90669, 34.87134]],
  [3013154, [50.26306, 3.31358]],
  [8859513, [27.54861, -105.40361]],
  [8858488, [18.24361, -95.12222]],
  [2924656, [50.85754, 10.56507]],
  [8674961, [18.36, -90.85722]],
  [5040822, [46.92218, -95.05863]],
  [3171665, [40.60851, 16.97802]],
  [2323090, [6.53891, 3.3742]],
  [4251879, [38.01533, -89.04257]],
  [1277397, [25.47758, 80.33491]],
  [2034834, [45.35, 126.28333]],
  [2949130, [51.01667, 10.85]],
  [3827038, [19.75472, -99.81667]],
  [2979784, [48.40563, -1.26352]],
  [3461311, [-23.08842, -47.2119]],
  [1645273, [-8.54286, 118.24821]],
  [3089882, [49.68079, 19.78243]],
  [3972248, [22.42199, -102.00725]],
  [3122595, [41.23851, 0.33302]],
  [2068483, [-33.14164, 136.41875]],
  [3173158, [44.4655, 8.11915]],
  [3032033, [48.16688, -2.06436]],
  [5530210, [30.14241, -102.39403]],
  [8571504, [31.62399, 114.38047]],
  [3175248, [44.76282, 12.14005]],
  [2636910, [56.11903, -3.93682]],
  [8949494, [41.70278, 12.61019]],
  [3981871, [19.7853, -101.4798]],
  [5554072, [58.30194, -134.41972]],
  [6535098, [45.79588, 8.73483]],
  [2655864, [50.48233, -4.19034]],
  [3021679, [46.3036, 4.74331]],
  [3009487, [47.89776, 3.23431]],
  [1272653, [28.20849, 78.26173]],
  [2791424, [50.93934, 4.32655]],
  [1940981, [30.02806, 111.84472]],
  [2800012, [50.65978, 5.06329]],
  [2872117, [53.18333, 10.4]],
  [3390288, [-9.47833, -35.85333]],
  [3838874, [-33.13067, -64.34992]],
  [4177897, [25.98454, -80.19894]],
  [691969, [45.72669, 28.60686]],
  [173819, [32.74948, 36.30582]],
  [4477525, [34.61834, -79.01045]],
  [3120934, [42.71725, -1.87798]],
  [2997010, [47.75976, 7.28032]],
  [2962539, [52.97556, -6.815]],
  [675734, [47.6578, 23.26272]],
  [4509177, [39.96118, -82.99879]],
  [1724567, [5.1608, 120.0426]],
  [1816624, [38.70683, 114.85933]],
  [2650191, [52.85027, -1.60452]],
  [2993753, [44.60182, 0.36078]],
  [3184316, [40.88639, 19.51139]],
  [8349348, [-27.41775, 153.07025]],
  [8859753, [19.87056, -99.79167]],
  [2991327, [46.39368, 1.94467]],
  [146138, [34.88613, 32.50712]],
  [3468020, [-19.53139, -54.04389]],
  [3044380, [47.81921, 18.8702]],
  [2641318, [51.82994, -1.83712]],
  [8348815, [-27.51228, 153.03248]],
  [2165200, [-37.7, 144.93333]],
  [3035776, [46.12112, 0.65529]],
  [3036370, [44.1414, 0.56108]],
  [3121877, [40.56466, -5.73379]],
  [4855951, [42.63915, -92.04712]],
  [4355756, [39.65814, -78.92836]],
  [7083070, [-8.1379, 113.6881]],
  [4084324, [30.47408, -87.91916]],
  [2523964, [39.9036, 8.59257]],
  [3995480, [20.30841, -102.20813]],
  [3121333, [41.62347, -2.22493]],
  [8348992, [-34.63087, 138.66816]],
  [4924242, [41.21504, -86.77585]],
  [8413219, [39.10872, 117.23854]],
  [2870962, [53.67526, 8.51646]],
  [1538417, [44.54107, 84.83259]],
  [1804693, [30.70471, 115.55535]],
  [1800906, [30.07801, 121.047]],
  [2033706, [50.38508, 121.69767]],
  [3472440, [-23.12889, -52.31889]],
  [525404, [67.93972, 32.87389]],
  [2641385, [54.43045, -1.56311]],
  [8863277, [19.69988, -99.26472]],
  [3165621, [44.37601, 10.56311]],
  [2839005, [50.03333, 6.83333]],
  [3521086, [18.23237, -88.6676]],
  [2704245, [58.44127, 11.3032]],
  [2020734, [42.8682, 132.67367]],
  [669519, [44.81213, 26.08701]],
  [753130, [50.162, 22.30825]],
  [4133806, [34.44926, -92.65405]],
  [2784967, [50.66801, 3.4866]],
  [2650613, [51.68139, -2.35333]],
  [7330670, [33.64895, 104.43184]],
  [2986444, [43.62975, -0.82991]],
  [672924, [47.33257, 26.28703]],
  [3113582, [43.55913, -5.97558]],
  [3166732, [40.21235, 8.55572]],
  [1805614, [28.10012, 115.42607]],
  [3518327, [19.56984, -99.85962]],
  [2981206, [45.4759, 4.51294]],
  [252616, [36.73718, 24.42765]],
  [1721543, [5.77972, 125.19778]],
  [2518923, [38.96667, -0.15]],
  [1259338, [24.03215, 74.78162]],
  [3122260, [41.94273, -3.73872]],
  [3112366, [42.31971, -3.27029]],
  [8861461, [18.90611, -97.45833]],
  [4959233, [44.79674, -68.76142]],
  [1256523, [25.56892, 91.88313]],
  [1916137, [22.54077, 114.48982]],
  [2067089, [-12.0483, 134.22941]],
  [468483, [55.21988, 56.13532]],
  [2921685, [50.25368, 7.79064]],
  [659184, [61.63913, 23.19531]],
  [2976251, [47.32889, 2.83447]],
  [3532409, [20.58853, -99.58688]],
  [2012557, [56.5768, 104.1217]],
  [3816795, [15.77189, -93.40718]],
  [771729, [50.6672, 21.84013]],
  [2871039, [52.28953, 8.91455]],
  [3193034, [46.21667, 14.58333]],
  [3121956, [40.05288, -4.20718]],
  [8604483, [45.0553, 37.13276]],
  [3181520, [45.94921, 8.7459]],
  [2637279, [53.09593, -1.43998]],
  [2765388, [48.13333, 16.46667]],
  [2944516, [51.41267, 10.4282]],
  [3846080, [-28.46539, -62.10862]],
  [1767021, [2.35, 102.11667]],
  [2649770, [51.1971, 1.2662]],
  [2689192, [55.98333, 14.15]],
  [9957330, [-27.43218, 153.01904]],
  [757376, [50.11915, 20.6361]],
  [2652976, [56.10743, -3.75098]],
  [3972333, [22.4153, -103.17114]],
  [3019649, [43.44119, 3.26346]],
  [3128348, [41.75, 0.91667]],
  [5886735, [73.03752, -85.15057]],
  [8860538, [19.59803, -100.13401]],
  [5422140, [38.73749, -104.78886]],
  [5006221, [44.04613, -82.99411]],
  [1702348, [9.2504, 124.7156]],
  [3101252, [52.69319, 14.70494]],
  [3180600, [41.04598, 16.92168]],
  [3182122, [46.09287, 10.33303]],
  [3713131, [9.22236, -78.89509]],
  [3442568, [-33.11651, -58.31067]],
  [4292072, [39.05173, -84.53411]],
  [1793463, [30.01651, 105.7973]],
  [4275614, [39.01778, -94.6169]],
  [2743067, [41.27644, -7.47489]],
  [3130458, [40.85077, -6.0526]],
  [4159839, [24.9243, -80.62784]],
  [283547, [32.50821, 35.31317]],
  [4014164, [20.41185, -101.74206]],
  [4916891, [42.10808, -87.7359]],
  [3173019, [42.3677, 12.59721]],
  [3037670, [47.33156, 1.24467]],
  [3087603, [53.8663, 15.94485]],
  [744873, [40.15669, 43.13424]],
  [259796, [37.96843, 23.21351]],
  [7261302, [41.11203, -104.78546]],
  [253170, [38.91667, 22.61667]],
  [3633248, [10.36556, -66.13391]],
  [2750870, [51.66417, 5.41944]],
  [687301, [44.97444, 34.83553]],
  [3942093, [-16.05813, -72.48692]],
  [3522836, [19.65474, -96.8732]],
  [6534660, [44.57879, 9.9429]],
  [668976, [47.8759, 23.31758]],
  [696380, [47.92518, 29.93716]],
  [2531480, [34.04965, -4.42162]],
  [2846374, [49.66472, 8.05833]],
  [8858341, [19.56917, -98.89167]],
  [1790647, [39.39606, 113.10841]],
  [2761989, [47.99854, 14.25879]],
  [4140963, [38.89511, -77.03637]],
  [2516730, [37.83333, -6.45]],
  [748032, [40.62647, 30.33557]],
  [3029852, [46.18756, 3.27324]],
  [3178062, [44.47425, 10.09009]],
  [2643678, [51.86667, -2.45]],
  [10104370, [-26.48801, 153.07577]],
  [2764525, [47.97079, 14.26935]],
  [4276017, [37.47446, -97.24393]],
  [697950, [47.97373, 29.80129]],
  [2643915, [51.90861, -4.53056]],
  [5134257, [42.69508, -73.80707]],
  [5364729, [39.23295, -121.20051]],
  [2877078, [50.09419, 7.56456]],
  [3817616, [19.08487, -98.85307]],
  [6643969, [27.70223, 99.12633]],
  [2969320, [43.52366, 1.4423]],
  [5418493, [37.84917, -106.92643]],
  [663831, [44.16699, 25.56437]],
  [3029433, [46.25845, 1.76228]],
  [3169830, [42.21923, 14.14248]],
  [531129, [43.51118, 44.58821]],
  [2639270, [50.40808, -4.83373]],
  [2825620, [50.93333, 14.01667]],
  [3985797, [21.01035, -102.17967]],
  [2519694, [39.26667, -0.56667]],
  [2707609, [57.65436, 12.36318]],
  [2655556, [51.5775, -4.04806]],
  [4146161, [27.77308, -82.40759]],
  [457211, [57.47312, 25.49174]],
  [232235, [1.40096, 34.45038]],
  [2849483, [49.01513, 12.10161]],
  [3178555, [44.37955, 12.23654]],
  [4160171, [27.25449, -80.22977]],
  [5162297, [41.16367, -80.72424]],
  [2640928, [53.38333, -1.36667]],
  [3531172, [16.78416, -92.68959]],
  [3127799, [41.55245, -2.36625]],
  [287830, [23.22573, 56.51572]],
  [2659975, [46.82896, 7.01621]],
  [3036137, [49.61429, 3.60547]],
  [2909308, [49.3, 9.15]],
  [5524979, [35.43644, -100.8057]],
  [3122037, [41.3682, -3.4995]],
  [2806011, [53.65, 8.51667]],
  [3815810, [17.29417, -93.3525]],
  [881345, [-17.31159, 31.57561]],
  [8862178, [19.42251, -99.94769]],
  [1254331, [13.14376, 79.90889]],
  [993014, [-28.77341, 20.6147]],
  [3074226, [49.54777, 17.6249]],
  [2050936, [41.81087, 112.83405]],
  [2781224, [48.22427, 14.02242]],
  [3176820, [41.61166, 13.54945]],
  [2653193, [52.10303, -0.68647]],
  [2331694, [9.10444, 7.21333]],
  [1506834, [56.20111, 95.46111]],
  [157826, [-7.60361, 37.00438]],
  [3022969, [50.45701, 2.94725]],
  [11592277, [55.99886, -3.91306]],
  [3182705, [41.85435, 14.19806]],
  [2788410, [50.6672, 6.11535]],
  [2782242, [47.92296, 16.55794]],
  [3176320, [45.57889, 10.71763]],
  [6295346, [47.39779, 8.4477]],
  [1802835, [39.87028, 117.34667]],
  [2994870, [43.25174, 1.67816]],
  [2175793, [-34.67172, 148.63483]],
  [3115349, [42.03201, -0.287]],
  [2078909, [-31.95575, 115.79807]],
  [3037592, [42.48291, 1.95941]],
  [3164352, [45.49939, 9.37719]],
  [6293096, [47.22923, 8.6722]],
  [8949033, [44.50717, 12.10621]],
  [7035092, [-8.2228, 112.3799]],
  [781487, [41.57694, 20.05139]],
  [3102677, [54.37971, 19.81959]],
  [7284890, [43.27889, 5.41523]],
  [3977202, [21.68211, -102.02208]],
  [4568957, [18.02691, -66.05711]],
  [1167380, [29.10408, 70.32969]],
  [3169991, [44.79925, 8.78364]],
  [12213021, [45.82483, 16.24292]],
  [9036188, [41.87731, 12.23891]],
  [3014745, [43.77128, 1.29282]],
  [3174905, [45.22866, 7.29248]],
  [2971765, [43.44818, 5.68328]],
  [1264489, [18.08333, 73.41667]],
  [3008041, [44.85165, 0.02549]],
  [11096605, [18.9708, -97.7228]],
  [2034638, [41.65917, 123.33917]],
  [3084069, [53.444, 19.10171]],
  [2617812, [56.54856, 8.31019]],
  [2820786, [48.03333, 8.6]],
  [4004690, [19.99136, -101.2761]],
  [3985244, [21.27405, -100.55032]],
  [146112, [35.0625, 33.2625]],
  [8392316, [-6.9891, 113.4064]],
  [665850, [47.63333, 26.25]],
  [143534, [36.46961, 52.35072]],
  [540834, [56.15, 36.88333]],
  [3513765, [12.23333, -68.33333]],
  [2161022, [-15.47362, 141.74567]],
  [1244397, [6.9909, 79.883]],
  [1783988, [35.99472, 119.3975]],
  [1173664, [27.81193, 66.61096]],
  [1985537, [-7.30678, 112.75453]],
  [3075991, [49.83102, 12.57939]],
  [7034999, [-8.1348, 112.4365]],
  [4842958, [42.01343, -72.56425]],
  [3466950, [-14.30472, -43.765]],
  [3441273, [-32.81667, -56.51667]],
  [8858837, [19.40056, -99.45944]],
  [3714776, [8.80773, -82.47073]],
  [2774844, [46.61667, 13.95]],
  [7302859, [25.74903, 93.16998]],
  [2652618, [51.88921, 0.90421]],
  [1265974, [30.94659, 75.1378]],
  [8862971, [21.36667, -98.79861]],
  [2766121, [47.70973, 16.50979]],
  [1283323, [26.64921, 85.80017]],
  [284291, [32.23105, 35.21576]],
  [1918524, [27.94615, 119.886]],
  [1170677, [25.59709, 68.4467]],
  [3533473, [18.56512, -99.56599]],
  [8507666, [40.47657, 96.72098]],
  [2647303, [53.18478, -3.02578]],
  [1274429, [12.30889, 78.79137]],
  [3033669, [45.56963, 6.71378]],
  [3088461, [53.55214, 14.57182]],
  [761087, [51.66329, 20.85179]],
  [1635882, [-8.58333, 116.11667]],
  [694677, [48.14708, 37.30032]],
  [2839090, [51.07641, 14.42834]],
  [4251341, [39.98614, -88.25894]],
  [935106, [-26.23333, 31.03333]],
  [1645658, [-6.9015, 113.8139]],
  [2640861, [52.86195, -3.05497]],
  [1268277, [14.5983, 78.66948]],
  [2521321, [39.98367, -4.23437]],
  [3075654, [48.84893, 17.13244]],
  [2033349, [47.3, 119.75]],
  [1979115, [6.51862, 124.58736]],
  [1258819, [21.86667, 73.5]],
  [4012605, [20.65881, -103.22654]],
  [2657996, [47.46152, 9.04552]],
  [2863111, [49.45899, 7.46955]],
  [8861972, [17.21583, -92.12194]],
  [2986413, [47.82386, -0.89792]],
  [736222, [41.06013, 24.04724]],
  [2749250, [52.20113, 5.93417]],
  [2807862, [52.06629, 10.76879]],
  [2010971, [-7.54972, 110.71639]],
  [3125944, [41.30976, -1.81084]],
  [3093664, [49.72209, 19.26496]],
  [7579556, [-8.0503, 111.8995]],
  [2949750, [50.25, 6.75]],
  [8862441, [25.88639, -109.13444]],
  [4658861, [35.14119, -84.87217]],
  [2779982, [47.68333, 12.18333]],
  [2882660, [53.3847, 11.24824]],
  [3012721, [47.67133, 2.25562]],
  [8861230, [20.97417, -97.44056]],
  [7628253, [7.53868, 149.41428]],
  [3415720, [63.75069, -20.22454]],
  [2937337, [49.63333, 7.18333]],
  [2883749, [47.90432, 10.1572]],
  [2661100, [47.33461, 7.28186]],
  [4011119, [20.77538, -103.69353]],
  [5805687, [47.03787, -122.9007]],
  [3064811, [50.55344, 15.54936]],
  [635844, [61.78691, 28.37299]],
  [672752, [44.55, 27.7]],
  [11549788, [41.38125, 2.11744]],
  [2795294, [50.67846, 5.69109]],
  [2146105, [-38.28994, 146.5462]],
  [3207741, [50.25, 6.73333]],
  [3575656, [15.36667, -61.35]],
  [1728165, [13.1537, 123.6432]],
  [2960672, [49.53556, 6.14694]],
  [2984732, [50.36608, 2.97804]],
  [2751541, [51.8025, 5.46389]],
  [2646719, [53.29541, -1.51761]],
  [3522615, [15.46768, -92.81299]],
  [1270718, [13.40765, 80.10879]],
  [2845721, [48.61667, 11.56667]],
  [2849875, [50.88329, 8.91864]],
  [2247381, [14.51255, -17.00447]],
  [2320746, [10.83122, 5.82494]],
  [3066751, [49.75665, 14.10897]],
  [1796743, [36.89083, 100.99972]],
  [3191287, [44.5038, 18.99818]],
  [8507367, [40.19624, 94.76548]],
  [1160120, [31.54185, 60.03648]],
  [2524197, [39.94467, 9.07355]],
  [2895886, [47.58851, 7.69094]],
  [2986770, [47.7588, -2.37563]],
  [6535524, [44.96175, 8.11433]],
  [3002552, [45.94763, -1.07795]],
  [284515, [31.90613, 35.14254]],
  [4022983, [21.14246, -100.31966]],
  [3515274, [18.342, -99.69161]],
  [3022573, [45.17586, 5.07209]],
  [2373526, [11.2825, -15.25472]],
  [1809139, [33.31056, 117.31056]],
  [2228881, [12.57535, 14.45483]],
  [2035179, [46.11452, 130.10378]],
  [2642639, [52.09152, -1.76623]],
  [3986477, [19.7725, -101.15806]],
  [456530, [56.29444, 26.72459]],
  [3111407, [40.9623, -5.91753]],
  [3373993, [13.28445, -59.64223]],
  [3047689, [47.64093, 18.0813]],
  [866062, [67.68026, 53.15117]],
  [2177023, [-10.89197, 142.38934]],
  [2562734, [36.02528, 14.295]],
  [3115617, [40.42558, -4.91108]],
  [3171012, [45.91969, 10.22526]],
  [3456137, [-14.79722, -57.28806]],
  [3982045, [19.83836, -102.34347]],
  [5388858, [38.42213, -122.72804]],
  [2913571, [51.3457, 12.17175]],
  [2788904, [50.66228, 4.42835]],
  [3129334, [42.69228, -1.83954]],
  [4026473, [18.96883, -100.0557]],
  [3169860, [43.98084, 11.78193]],
  [2972097, [48.41667, 3.45]],
  [8861001, [18.95444, -99.62806]],
  [4822764, [38.36843, -81.69957]],
  [2152815, [-38.28173, 144.61055]],
  [4494228, [35.40628, -76.32909]],
  [2268250, [37.12926, -8.23759]],
  [10103877, [52.18093, 0.09072]],
  [3179642, [44.85451, 9.87326]],
  [1807269, [28.31586, 115.36755]],
  [7874478, [-27.59898, -48.52618]],
  [8403264, [-6.9934, 113.9031]],
  [3977515, [25.88048, -103.29757]],
  [7862602, [-5.30094, 104.98329]],
  [3938586, [-14.05302, -73.56603]],
  [320177, [37.40542, 29.13727]],
  [8859813, [18.86444, -98.94806]],
  [3008754, [49.22553, 1.06774]],
  [3372863, [37.89017, -25.81999]],
  [1271714, [13.00639, 80.25417]],
  [3181766, [44.96632, 9.14835]],
  [4135865, [35.14648, -90.18454]],
  [5859114, [61.52778, -165.58639]],
  [2848766, [49.03092, 12.11329]],
  [2827677, [50.75, 12.05]],
  [3710481, [7.68489, -80.40309]],
  [4366852, [39.69789, -76.06273]],
  [565172, [55.55056, 36.37444]],
  [5258575, [44.27221, -88.339]],
  [1641272, [-6.7377, 111.0434]],
  [632370, [62.31533, 27.873]],
  [3530037, [19.8246, -97.85256]],
  [3605729, [14.95, -87.96667]],
  [2976678, [47.25626, -2.01859]],
  [1710318, [9.90761, 123.60015]],
  [2760749, [47.15844, 10.5897]],
  [1488754, [57.15222, 65.52722]],
  [4202810, [31.60785, -81.88634]],
  [6957219, [-26.481, 153.096]],
  [696323, [47.53166, 31.32706]],
  [6291532, [47.51096, 8.47099]],
  [2033362, [40.55147, 117.66155]],
  [3926776, [-14.16389, -71.47667]],
  [2986765, [46.73754, 0.76592]],
  [1255425, [24.37065, 75.95708]],
  [3081653, [50.95194, 15.92185]],
  [3035914, [49.44454, 3.12492]],
  [2152593, [-37.85114, 144.99318]],
  [2784168, [50.62627, 4.69837]],
  [1553870, [30.44823, 118.0393]],
  [3014608, [48.08311, 2.82951]],
  [1214701, [5.5151, 95.8465]],
  [2652445, [54.8, -6.2]],
  [1221583, [37.40973, 68.18644]],
  [3130819, [41.96212, -1.9934]],
  [3171872, [42.13039, 12.93762]],
  [3045804, [48.03695, 19.5301]],
  [7341410, [15.59858, 45.04013]],
  [5301242, [33.29338, -112.17543]],
  [5106379, [40.92371, -74.51071]],
  [666043, [46.34224, 27.85405]],
  [283408, [32.10188, 35.32188]],
  [4178488, [27.71253, -82.29898]],
  [2703838, [58.4, 13.96667]],
  [34717, [15.85, 44.775]],
  [2989667, [49.02377, 4.05178]],
  [1845519, [36.43351, 129.057]],
  [3948810, [-13.4275, -71.8675]],
  [684980, [47.75, 22.46667]],
  [1900608, [-9.4828, 119.306]],
  [1532302, [43.88333, 87.48333]],
  [11592183, [57.45125, -4.17592]],
  [2978057, [47.02089, -1.79409]],
  [146969, [39.79496, 47.1117]],
  [8333968, [22.59124, 113.06686]],
  [8742384, [20.91417, -97.70667]],
  [1794334, [31.48857, 120.43994]],
  [8348752, [-16.89921, 145.71554]],
  [3068127, [49.34341, 13.13349]],
  [526605, [56.45188, 35.60685]],
  [2919692, [53.38333, 9.0]],
  [1258492, [26.44931, 91.61356]],
  [3805700, [7.64636, -67.6072]],
  [7761481, [-6.6301, 105.9198]],
  [2649644, [51.29774, -2.52913]],
  [2820444, [50.25, 6.93333]],
  [3650855, [-2.33965, -77.46031]],
  [2661492, [47.29086, 8.19741]],
  [118805, [30.89315, 49.40787]],
  [2643929, [51.62111, -2.81833]],
  [1691904, [15.6653, 120.6406]],
  [509113, [45.39531, 39.3842]],
  [6922213, [22.80087, 113.29208]],
  [2784213, [50.5233, 4.53332]],
  [2903540, [50.33333, 7.1]],
  [554318, [65.19873, 31.18999]],
  [293985, [32.33199, 34.98558]],
  [3075958, [50.08049, 16.93641]],
  [3038530, [50.03456, 1.62082]],
  [3405051, [-4.39143, -59.58864]],
  [1816366, [28.31394, 120.55608]],
  [2713707, [56.27298, 15.53415]],
  [712260, [46.83851, 33.42664]],
  [1632998, [-6.28417, 107.81056]],
  [2640840, [51.36262, -0.52752]],
  [2866990, [50.19883, 7.85892]],
  [1800371, [32.28379, 120.55941]],
  [3029241, [49.18585, -0.35912]],
  [2927142, [53.66124, 13.88724]],
  [3665380, [-3.22083, -64.80417]],
  [3515321, [19.95814, -98.91941]],
  [3822143, [16.9716, -98.5572]],
  [2033112, [42.63935, 129.56159]],
  [5101312, [40.379, -74.54654]],
  [2864118, [51.19807, 6.68504]],
  [8858710, [21.07389, -101.47278]],
  [1575864, [11.84476, 106.59073]],
  [3022936, [48.08111, 1.14072]],
  [3010257, [47.44111, -0.9846]],
  [3089286, [53.04354, 15.30447]],
  [3107724, [41.9605, -0.4335]],
  [3011675, [48.77226, -3.37193]],
  [2635744, [53.71434, -2.09701]],
  [7406748, [-8.3525, 114.9211]],
  [8861952, [19.21111, -96.21444]],
  [1970753, [-7.15347, 112.86066]],
  [3025075, [46.11578, -0.34748]],
  [3006215, [42.60742, 3.00153]],
  [4705943, [33.37955, -96.24748]],
  [2982052, [47.24707, 0.54455]],
  [3813244, [21.02, -98.95167]],
  [2835385, [48.87733, 9.34958]],
  [8377939, [-10.4611, 121.8698]],
  [681575, [47.34032, 26.90863]],
  [8861212, [18.21361, -93.19056]],
  [3489475, [18.04, -77.33]],
  [775010, [49.82226, 21.39004]],
  [889215, [-16.51667, 28.8]],
  [679951, [47.55, 23.93333]],
  [4563379, [18.44634, -66.35073]],
  [8948988, [45.28609, 7.93964]],
  [3063466, [49.13686, 13.72911]],
  [231550, [0.91611, 31.77417]],
  [7932342, [53.6052, 10.03988]],
  [3319579, [47.3, 11.63333]],
  [8073829, [-7.0573, 108.6373]],
  [5359577, [36.95134, -121.73384]],
  [1706299, [11.651, 122.3575]],
  [3011243, [43.70093, 5.56732]],
  [1860034, [34.98333, 136.98333]],
  [5138652, [43.23784, -77.06136]],
  [8858776, [19.07611, -101.5825]],
  [3088820, [50.20558, 19.51498]],
  [692741, [47.3508, 30.30898]],
  [3203131, [45.465, 18.32278]],
  [3024208, [48.3166, 7.10981]],
  [7870334, [48.73366, -123.61739]],
  [3183260, [45.73883, 9.61558]],
  [1786449, [27.76544, 112.92683]],
  [2980031, [45.92166, 1.13785]],
  [3695685, [-8.66202, -77.9012]],
  [8025624, [-7.1327, 108.1321]],
  [3464579, [-25.73361, -53.05722]],
  [6247826, [-37.66738, 175.15554]],
  [5154905, [41.157, -83.41687]],
  [3172240, [40.79356, 17.12681]],
  [2641535, [52.66602, -1.58778]],
  [2523920, [38.13205, 13.33561]],
  [3114523, [41.17491, 1.2709]],
  [2919609, [51.178, 14.31951]],
  [3028079, [44.64354, 1.22561]],
  [1636270, [-9.4013, 119.545]],
  [3172939, [42.99005, 13.45688]],
  [3813338, [21.21551, -98.47776]],
  [2975140, [45.60035, -0.91301]],
  [1795226, [36.09723, 120.4891]],
  [2746014, [50.85333, 5.94861]],
  [1786430, [23.69947, 116.64053]],
  [2809468, [53.95815, 9.90297]],
  [7511028, [15.32476, 103.13343]],
  [3180850, [44.72084, 8.29282]],
  [4946863, [42.52787, -70.92866]],
  [2441194, [13.95532, 7.67122]],
  [1684191, [6.85639, 125.4225]],
  [671706, [43.75522, 24.35834]],
  [347591, [31.0539, 31.37787]],
  [3667211, [5.75, -73.0]],
  [3112325, [43.2572, -3.46516]],
  [3175770, [43.1188, 13.60691]],
  [677504, [46.42925, 26.99239]],
  [942511, [-26.95659, 24.7284]],
  [2875117, [50.13333, 6.35]],
  [2813786, [51.10009, 6.15484]],
  [3116473, [41.8, 0.96667]],
  [2986501, [48.92902, 2.04952]],
  [2549356, [31.4353, -4.23258]],
  [1886840, [29.97786, 122.26557]],
  [3127990, [41.6809, -2.85826]],
  [2659852, [46.31301, 9.19663]],
  [2852857, [51.57966, 11.59931]],
  [2640508, [50.36589, -4.52274]],
  [2988573, [48.273, -1.20051]],
  [3124152, [41.60224, -2.42179]],
  [6294994, [47.53652, 8.74155]],
  [302816, [36.6016, 31.85057]],
  [3207488, [50.8, 11.66667]],
  [4974489, [44.0823, -69.05726]],
  [3177588, [43.89267, 11.52182]],
  [2984140, [49.49878, 5.75497]],
  [8074026, [-6.9815, 111.5076]],
  [2981058, [48.66667, -0.88333]],
  [3822871, [20.0624, -88.59606]],
  [3034006, [49.43333, 2.08333]],
  [4441578, [34.21344, -88.6645]],
  [680133, [44.19338, 26.43846]],
  [643640, [60.85, 22.68333]],
  [934624, [-20.48222, 57.46611]],
  [2858476, [49.78333, 7.31667]],
  [3408424, [-1.71806, -48.8825]],
  [3667070, [2.7717, -77.66536]],
  [3460950, [-22.74444, -42.85944]],
  [1630238, [-4.838, 122.7209]],
  [1852984, [37.61667, 138.95]],
  [1683066, [15.80411, 120.41409]],
  [1805164, [23.40844, 101.71028]],
  [4310616, [38.99756, -84.49633]],
  [8859951, [20.59694, -97.4225]],
  [4566103, [18.37245, -65.71655]],
  [2952531, [53.79209, 9.76739]],
  [681341, [44.35288, 23.21032]],
  [8564068, [20.5602, 106.02812]],
  [4723008, [27.29865, -97.815]],
  [7890119, [31.78852, 35.26511]],
  [1215102, [1.2888, 97.6143]],
  [8398669, [31.4056, 109.97781]],
  [2654371, [50.39577, -4.79334]],
  [789311, [41.959, 21.13867]],
  [3035585, [48.21026, 6.57389]],
  [3169448, [45.56965, 7.1187]],
  [2661388, [46.12013, 8.71181]],
  [3012884, [46.20261, 4.08154]],
  [6534373, [44.19253, 8.24935]],
  [3169324, [44.81147, 10.80414]],
  [721338, [48.26246, 22.06339]],
  [2344941, [9.36872, 9.96223]],
  [3005725, [46.07145, 2.08868]],
  [2936292, [53.1408, 11.25017]],
  [792680, [44.80401, 20.46513]],
  [2747891, [51.9225, 4.47917]],
  [3128760, [41.38879, 2.15899]],
  [8183595, [-8.0435, 111.3934]],
  [4899983, [42.19002, -87.9084]],
  [1790497, [30.655, 108.193]],
  [2961204, [52.79667, -6.46333]],
  [309008, [37.55861, 27.83528]],
  [2505530, [31.61667, -2.21667]],
  [2978634, [47.8771, -1.78819]],
  [3818734, [19.15085, -98.49105]],
  [1812696, [35.06661, 101.81256]],
  [4167424, [30.59936, -87.16108]],
  [2865300, [51.85095, 11.70783]],
  [2960556, [49.51616, 6.18858]],
  [678284, [44.68988, 26.396]],
  [5368676, [35.61635, -119.69429]],
  [677195, [46.55, 27.45]],
  [2653423, [50.80893, -2.47583]],
  [4092347, [30.4877, -87.69971]],
  [2993194, [46.21948, 4.5201]],
  [8348497, [-33.78283, 150.79679]],
  [2899291, [52.58333, 6.95]],
  [2863095, [49.44999, 12.3761]],
  [8185057, [-8.2875, 113.4777]],
  [8068780, [-6.6741, 111.5942]],
  [2643720, [52.99314, -0.75803]],
  [2163395, [-34.34792, 150.4955]],
  [3109977, [42.75058, -5.41201]],
  [3101198, [50.01833, 18.90678]],
  [2873290, [48.92566, 10.47078]],
  [1639850, [-8.53333, 115.4]],
  [8862617, [18.60556, -96.57639]],
  [1595679, [16.65, 103.3]],
  [1700197, [15.6571, 120.7921]],
  [2643620, [52.98333, -2.13333]],
  [8860698, [19.69591, -99.85863]],
  [4944154, [42.58203, -72.49259]],
  [7009267, [23.03279, 114.36687]],
  [2659689, [45.87019, 8.9816]],
  [2507912, [36.79333, 3.28694]],
  [8198710, [33.60247, 131.17599]],
  [3014351, [48.94388, 1.73429]],
  [5000306, [43.95528, -86.45258]],
  [2837777, [50.60863, 10.81284]],
  [663465, [45.36395, 27.04197]],
  [5387288, [34.1064, -117.37032]],
  [3010372, [46.20841, 4.76245]],
  [3164094, [45.14111, 11.0317]],
  [2867514, [50.24638, 7.36208]],
  [545267, [45.29917, 42.63667]],
  [8718041, [37.7119, 128.18713]],
  [1728493, [11.47139, 125.51056]],
  [523715, [44.1, 44.6]],
  [2778431, [48.61667, 16.4]],
  [358840, [30.42039, 31.56223]],
  [11611629, [35.76232, 139.31952]],
  [2522772, [39.97227, 16.08521]],
  [4018454, [20.19984, -101.8067]],
  [3024817, [42.76023, 2.95606]],
  [3063409, [48.74069, 16.75499]],
  [610838, [42.19432, 43.77361]],
  [7581189, [47.26045, 10.34419]],
  [2991255, [47.35783, -0.55204]],
  [1797178, [22.83599, 114.80487]],
  [3140636, [59.68129, 10.52749]],
  [2653584, [53.72587, -1.36256]],
  [5023571, [45.03274, -93.36023]],
  [3465624, [-19.93167, -44.05361]],
  [3017456, [46.53333, -0.68333]],
  [4501677, [39.91372, -75.01045]],
  [4529448, [35.57783, -98.96453]],
  [5036295, [45.06969, -92.9516]],
  [2035788, [46.4224, 130.66]],
  [1814743, [28.01228, 114.02735]],
  [6694284, [51.84734, 0.62399]],
  [8199609, [51.70432, -3.31306]],
  [1627148, [-8.5025, 118.62]],
  [3179564, [40.01003, 15.97184]],
  [2912481, [53.43134, 11.19159]],
  [2524530, [39.89258, 9.54582]],
  [4694482, [32.74596, -96.99778]],
  [5218940, [40.32454, -75.99077]],
  [2890335, [50.23019, 6.74526]],
  [4446298, [34.43205, -90.22093]],
  [490747, [44.4074, 42.724]],
  [3115519, [41.09185, -5.59165]],
  [1728792, [16.2867, 120.4004]],
  [504717, [54.1048, 39.6092]],
  [533400, [55.02772, 44.47865]],
  [2883570, [51.96307, 12.7455]],
  [3448906, [-27.27611, -48.84944]],
  [2791507, [50.82444, 4.03674]],
  [8358544, [37.97574, 23.76911]],
  [1626808, [-8.3793, 112.6811]],
  [933974, [-20.1283, 57.53116]],
  [714787, [47.45, 20.46667]],
  [3202781, [46.23092, 15.26044]],
  [8862596, [16.93556, -91.63056]],
  [1277255, [12.33295, 76.86201]],
  [1606019, [17.56292, 102.27989]],
  [3127538, [42.32458, -8.78497]],
  [720857, [48.1, 20.86667]],
  [1914035, [19.66004, 110.84516]],
  [3978908, [26.20618, -105.08133]],
  [2871532, [49.58333, 7.31667]],
  [1918314, [28.62161, 118.45152]],
  [1920369, [24.04882, 112.1545]],
  [704839, [51.76877, 32.24813]],
  [2640399, [53.28374, -4.21784]],
  [475881, [54.35533, 38.26843]],
  [4004635, [19.75093, -102.14372]],
  [8333321, [-7.7278, 113.4811]],
  [3176666, [44.9216, 9.18471]],
  [692818, [49.75522, 27.21229]],
  [3198468, [45.89556, 14.19361]],
  [2511852, [36.71715, -4.27583]],
  [1904091, [29.5136, 106.06062]],
  [6078359, [50.14261, -101.66666]],
  [3130282, [41.19758, 1.11167]],
  [3179836, [45.31683, 9.10383]],
  [3053760, [47.24135, 19.09019]],
  [5075615, [42.5625, -96.70559]],
  [2032631, [43.65595, 105.75577]],
  [1276335, [25.39526, 82.5703]],
  [2847639, [47.56013, 7.78715]],
  [1843137, [37.75266, 128.87239]],
  [2657889, [47.47452, 9.11196]],
  [1789728, [34.4025, 116.02444]],
  [8305523, [-8.5857, 118.5804]],
  [2639110, [51.91667, -2.56667]],
  [1522818, [43.02143, 79.22055]],
  [3081419, [52.11552, 16.11712]],
  [5315313, [32.19952, -110.96842]],
  [7350321, [-8.0161, 113.384]],
  [743645, [40.9231, 30.26597]],
  [2967914, [44.31468, 4.95033]],
  [285760, [28.63917, 47.93056]],
  [293725, [31.89421, 34.81199]],
  [3977429, [25.48834, -102.9438]],
  [2993544, [48.11779, 1.38899]],
  [3130682, [40.3069, -3.00897]],
  [2971609, [50.10498, 3.46997]],
  [2173686, [-24.86667, 152.1]],
  [7636841, [29.39308, 115.9961]],
  [3123686, [40.77633, -0.55344]],
  [764036, [49.50348, 22.15651]],
  [3114275, [40.20472, -4.4305]],
  [8949651, [41.73487, 13.36601]],
  [1735450, [4.2323, 100.6298]],
  [493970, [59.9473, 31.03845]],
  [1553369, [29.73609, 118.28085]],
  [2294768, [5.30383, -1.98956]],
  [3126420, [43.46058, -5.19181]],
  [461064, [56.98639, 24.29917]],
  [2974163, [44.04283, 0.14087]],
  [4146564, [25.89176, -80.12699]],
  [4147247, [28.45834, -81.35924]],
  [2985884, [43.417, 1.17298]],
  [3193736, [45.3725, 18.84778]],
  [320716, [36.69495, 28.04336]],
  [7911708, [-7.4374, 107.8354]],
  [294044, [32.17217, 34.9393]],
  [2661752, [46.6034, 9.42614]],
  [3053495, [47.79052, 19.04125]],
  [2976351, [44.18188, 2.57053]],
  [2703212, [63.3462, 13.46572]],
  [2913537, [49.11663, 10.75971]],
  [3023716, [46.15411, 4.72353]],
  [3108854, [40.91754, -6.79729]],
  [2857741, [48.06667, 9.65]],
  [2156663, [-38.18333, 145.08333]],
  [3012599, [47.2813, 5.18921]],
  [3197210, [45.02111, 15.16528]],
  [3192910, [43.48668, 16.55013]],
  [2780665, [48.539, 16.90264]],
  [2649442, [51.96841, 0.45129]],
  [3095311, [50.72695, 19.00781]],
  [3129148, [41.98349, -3.82553]],
  [2516945, [38.92539, -0.48585]],
  [2870266, [51.50757, 12.81418]],
  [2760637, [46.86074, 14.85482]],
  [7100777, [35.71071, 53.35394]],
  [5107200, [42.30673, -78.01584]],
  [4512145, [39.79756, -84.25355]],
  [259782, [37.25111, 21.67361]],
  [3179510, [41.56027, 14.34637]],
  [3602984, [14.88333, -88.73333]],
  [3183945, [41.38056, 19.53778]],
  [459692, [56.53521, 21.16782]],
  [1795717, [28.43566, 116.66346]],
  [1798457, [28.08194, 110.09556]],
  [1967939, [0.9202, 124.4689]],
  [146748, [34.98213, 34.00183]],
  [8950236, [43.90323, 12.2905]],
  [3037282, [43.4322, -1.55149]],
  [256550, [39.58722, 20.87567]],
  [616399, [40.28607, 44.54449]],
  [4500750, [39.86761, -75.09462]],
  [8863208, [18.71889, -96.60833]],
  [774387, [53.26075, 20.89728]],
  [3165474, [46.12786, 13.42933]],
  [3112957, [41.17245, -3.00728]],
  [3015608, [44.11458, 0.92837]],
  [3483526, [21.48169, -98.59879]],
  [2967887, [45.796, 0.49352]],
  [7927587, [31.7, 78.8]],
  [3177201, [45.74369, 11.54529]],
  [3116348, [41.14027, -4.66414]],
  [8859795, [21.32444, -98.72861]],
  [2861227, [51.2893, 11.017]],
  [2906205, [48.21884, 7.77746]],
  [3166395, [46.1664, 10.35071]],
  [2651857, [53.66217, -2.77523]],
  [8377177, [-7.1986, 113.8946]],
  [2915353, [50.21667, 6.25]],
  [1852383, [34.2152, 135.1501]],
  [1263965, [9.67318, 78.47096]],
  [1914473, [23.83235, 112.10035]],
  [2967136, [49.12372, 6.58829]],
  [1280354, [27.75, 89.16667]],
  [3059282, [48.70519, 18.91834]],
  [2525705, [38.43144, 16.0974]],
  [8859924, [20.14889, -98.21694]],
  [503878, [56.33547, 29.36901]],
  [3215811, [46.16778, 16.30583]],
  [3072314, [49.07429, 14.97018]],
  [587876, [57.77781, 26.0473]],
  [8022649, [-7.4085, 108.1741]],
  [667964, [45.51667, 22.78333]],
  [3172896, [43.23099, 13.63047]],
  [8393767, [-10.0879, 124.1149]],
  [677895, [45.08333, 23.05]],
  [2392009, [6.73618, 2.65866]],
  [3113805, [40.63383, -1.00181]],
  [2804939, [51.33356, 13.33809]],
  [5277149, [44.48218, -89.96402]],
  [1649480, [-7.2716, 107.8197]],
  [2160914, [-21.03333, 148.9]],
  [672035, [45.2006, 25.59882]],
  [2984804, [50.28641, 3.51319]],
  [8045478, [-7.4405, 108.3423]],
  [2517252, [38.98915, -0.46992]],
  [3129444, [42.1076, -5.89441]],
  [1815983, [37.30817, 122.11633]],
  [670057, [47.41188, 26.04077]],
  [2967138, [48.07361, 7.23578]],
  [2997570, [44.07653, 5.00065]],
  [4932757, [42.62786, -72.86982]],
  [3451131, [-19.98083, -49.68194]],
  [1505260, [60.69111, 60.42056]],
  [4024572, [20.82308, -101.37161]],
  [3973865, [22.10316, -101.05309]],
  [282859, [32.18486, 35.53898]],
  [11612549, [33.68407, 133.84369]],
  [3725364, [18.27782, -73.12733]],
  [6535297, [40.92442, 14.47463]],
  [2037439, [49.67309, 126.9544]],
  [670631, [45.96212, 26.80713]],
  [3172834, [41.01168, 14.90034]],
  [2990727, [46.59102, -1.40167]],
  [8219768, [-27.30748, 151.85126]],
  [2707708, [61.9276, 17.21849]],
  [3112368, [41.7443, -6.43302]],
  [3182059, [43.69876, 11.81474]],
  [1170013, [26.66317, 68.00016]],
  [3526511, [20.68669, -97.6004]],
  [3184611, [41.26389, 19.65583]],
  [3028505, [44.23747, 4.59409]],
  [80384, [13.69572, 44.73137]],
  [8861936, [19.22899, -99.6235]],
  [310956, [37.93028, 42.98889]],
  [2515562, [37.48613, -5.98091]],
  [3154341, [60.38778, 5.77056]],
  [8859158, [23.83889, -103.07861]],
  [4137291, [36.22618, -92.68489]],
  [3169769, [44.6263, 10.5642]],
  [1784486, [31.80652, 119.45319]],
  [8402862, [-9.8747, 124.3064]],
  [1507035, [56.83444, 95.22833]],
  [2980938, [45.67001, 4.29667]],
  [4023835, [20.20513, -100.96211]],
  [1175098, [30.42919, 73.04522]],
  [2522052, [39.55, -0.38333]],
  [2751583, [50.98509, 5.81667]],
  [772165, [49.54582, 21.91026]],
  [5015924, [44.90275, -92.78354]],
  [4900801, [42.34724, -89.039]],
  [2160625, [-33.8157, 151.16679]],
  [7258390, [42.47032, -76.46228]],
  [2722586, [59.08883, 16.20978]],
  [2935326, [51.4991, 9.76179]],
  [6293192, [47.30658, 8.60271]],
  [1519327, [49.70885, 72.59212]],
  [3452982, [-29.70167, -51.24194]],
  [1722228, [11.3072, 124.462]],
  [6138638, [45.65068, -72.76348]],
  [652891, [62.49525, 30.25081]],
  [745044, [41.01384, 28.94966]],
  [2656246, [52.91636, -0.69094]],
  [4979945, [44.48952, -68.85698]],
  [4359694, [39.48621, -77.69971]],
  [2037630, [44.11426, 129.21003]],
  [704972, [44.43374, 34.08516]],
  [3698587, [-5.0585, -80.6331]],
  [9222697, [54.10608, 12.15041]],
  [1793505, [32.49069, 119.90812]],
  [792953, [45.27146, 20.82833]],
  [258538, [38.77771, 20.6676]],
  [685419, [46.07514, 25.60029]],
  [8074716, [-6.8253, 111.7411]],
  [2989956, [49.70254, 3.48384]],
  [4024607, [20.93187, -101.27873]],
  [2737088, [40.54264, -8.53856]],
  [4149226, [27.13033, -80.89367]],
  [784759, [42.37056, 21.15528]],
  [757512, [49.42655, 20.02713]],
  [1010586, [-29.58274, 26.66511]],
  [4919820, [41.4942, -87.52171]],
  [2935546, [50.1, 7.75]],
  [2772289, [47.33333, 16.08333]],
  [6643960, [28.56146, 99.74362]],
  [8949341, [44.74837, 10.33045]],
  [682181, [46.75104, 22.02689]],
  [7055112, [-8.3799, 115.4181]],
  [3173967, [46.89225, 11.3493]],
  [3104812, [42.53192, -4.55884]],
  [3104774, [42.37769, -4.67085]],
  [2647119, [50.91234, -3.22807]],
  [3207136, [51.12, 10.57743]],
  [7375451, [-7.053, 111.3096]],
  [689434, [48.24482, 25.18112]],
  [8185049, [-8.3344, 113.4728]],
  [2925561, [51.1, 10.28333]],
  [3144953, [61.51108, 5.71694]],
  [677716, [47.41968, 25.91809]],
  [8018397, [-7.0606, 108.0056]],
  [2966179, [52.59194, -7.18]],
  [2800675, [50.5304, 3.52266]],
  [1846095, [36.85667, 127.44333]],
  [2327607, [7.89635, 7.06229]],
  [3824647, [20.39558, -97.3918]],
  [3167482, [40.84482, 15.36965]],
  [3173428, [44.27409, 12.35172]],
  [3530822, [20.65988, -98.18512]],
  [2974533, [49.12033, 6.35915]],
  [2176553, [-27.12808, 153.05243]],
  [1799869, [22.81667, 108.31667]],
  [2853114, [51.75, 11.68333]],
  [99611, [37.09214, 43.48769]],
  [2978325, [44.16308, 4.08203]],
  [1703315, [11.27292, 122.04661]],
  [8950115, [38.88496, 16.57673]],
  [8219762, [-32.55851, 115.7604]],
  [2657910, [47.20173, 7.56649]],
  [5185052, [40.5434, -78.7878]],
  [3690812, [-9.02239, -77.47465]],
  [1928183, [29.01583, 111.48902]],
  [8861950, [19.17717, -100.23202]],
  [5386082, [33.64086, -117.6031]],
  [2779143, [47.15202, 11.34922]],
  [8054125, [-7.0208, 108.3807]],
  [939676, [-33.29265, 23.48954]],
  [2822073, [51.00619, 6.42477]],
  [4190779, [30.74743, -83.2207]],
  [8949473, [45.63732, 12.60927]],
  [677993, [45.06121, 24.07954]],
  [1278228, [25.28618, 80.57155]],
  [8863186, [20.99917, -100.39694]],
  [2984654, [45.84254, 6.0838]],
  [1496747, [55.0415, 82.9346]],
  [675711, [45.82043, 27.18253]],
  [737119, [40.68815, 29.96738]],
  [1941088, [29.45498, 109.53017]],
  [3193230, [44.82213, 19.79487]],
  [2984741, [47.48368, -3.11986]],
  [3532548, [19.72456, -98.75863]],
  [8859915, [25.80611, -100.6225]],
  [3035286, [49.29605, 2.33645]],
  [3121840, [40.59102, -6.81144]],
  [3726263, [19.52734, -72.24337]],
  [758548, [51.52065, 23.09215]],
  [3169796, [45.70807, 13.73376]],
  [686279, [46.61667, 21.78333]],
  [8861328, [18.02361, -92.97222]],
  [674626, [44.9, 23.7]],
  [2783284, [50.88408, 4.27325]],
  [293755, [32.59784, 35.05656]],
  [2837368, [49.2, 10.18333]],
  [3116785, [41.12155, -4.3641]],
  [11669118, [47.85136, 13.33706]],
  [4838928, [41.71288, -71.88091]],
  [264445, [38.06134, 23.58971]],
  [618863, [53.5974, 24.9828]],
  [3168599, [45.6241, 8.78507]],
  [2638019, [52.94078, 1.20931]],
  [1795083, [30.98639, 112.50087]],
  [3491948, [19.56378, -70.87582]],
  [3014668, [46.25619, 4.84856]],
  [3693042, [-8.39446, -77.49348]],
  [1429499, [35.01468, 69.59934]],
  [2635964, [54.23528, -0.72016]],
  [3996426, [25.52699, -103.2285]],
  [8858430, [25.82528, -100.24611]],
  [3203746, [45.72111, 18.51694]],
  [7085640, [-8.6719, 116.3364]],
  [1787868, [28.34781, 120.48126]],
  [5096693, [40.44205, -74.21681]],
  [2060937, [-32.79554, 134.20928]],
  [2744608, [53.35911, 5.21482]],
  [3427279, [-29.46914, -56.81841]],
  [1726292, [13.2498, 123.5641]],
  [3168695, [40.52668, 16.32037]],
  [4142601, [38.51734, -75.23491]],
  [1861454, [35.65, 138.63333]],
  [2789615, [50.80528, 5.06178]],
  [6385689, [-7.52389, 109.12278]],
  [5390267, [38.50519, -122.47026]],
  [2751980, [52.43083, 4.91528]],
  [3169447, [45.53245, 9.0402]],
  [682252, [45.2, 25.18333]],
  [1812807, [32.56423, 119.66255]],
  [4968747, [43.36175, -70.47672]],
  [2751073, [52.77167, 5.10556]],
  [5773230, [41.55104, -112.11023]],
  [2798766, [50.85847, 3.6366]],
  [8859750, [20.06639, -97.9075]],
  [4889761, [41.3242, -89.30675]],
  [8070140, [-7.4329, 108.7491]],
  [3393065, [-6.21111, -40.69444]],
  [6166739, [48.46686, -81.33312]],
  [3164239, [44.78824, 7.50788]],
  [8948856, [40.3969, 15.52503]],
  [3129643, [41.94723, -2.40033]],
  [1709868, [8.04333, 125.27667]],
  [11153636, [44.58362, 33.64383]],
  [3183684, [41.34444, 19.5475]],
  [3179456, [44.08256, 10.46345]],
  [2761353, [47.80485, 16.23196]],
  [6692916, [45.67389, 10.08064]],
  [4482109, [35.24516, -78.35417]],
  [9972622, [-33.74784, 150.72208]],
  [1608273, [16.07978, 101.80225]],
  [2640559, [51.30472, -2.50028]],
  [2811899, [51.20148, 11.96843]],
  [3827511, [19.98216, -99.49126]],
  [2644495, [56.03336, -3.47713]],
  [2063293, [-32.6298, 115.87351]],
  [554767, [56.62558, 36.22198]],
  [7927603, [31.41153, 95.58418]],
  [3169259, [46.25226, 12.02303]],
  [8080044, [-8.3049, 112.4246]],
  [1726449, [20.44865, 121.97017]],
  [3585636, [13.87694, -89.17306]],
  [2946447, [50.73438, 7.09549]],
  [3823447, [19.31988, -97.00082]],
  [3038658, [43.53511, 3.42787]],
  [1649669, [-8.7465, 115.5069]],
  [3453837, [-22.92389, -45.46167]],
  [1915299, [21.72833, 110.92889]],
  [3012124, [47.71878, -0.39405]],
  [1280150, [29.26733, 89.80928]],
  [4026131, [18.39162, -100.30972]],
  [1704623, [15.67511, 120.48835]],
  [1550651, [28.69795, 107.33962]],
  [2995620, [46.03526, 4.32916]],
  [2342340, [12.40848, 9.52881]],
  [636608, [60.13862, 24.22715]],
  [8348633, [-33.7609, 150.93123]],
  [4495710, [35.70069, -80.88813]],
  [2969806, [49.27914, 2.5241]],
  [257695, [39.35825, 20.43886]],
  [1800361, [29.36581, 115.58012]],
  [2782848, [48.1868, 13.74175]],
  [1732070, [17.70016, 121.8025]],
  [2624929, [55.0708, 14.91978]],
  [1280672, [28.85816, 89.94036]],
  [3080476, [53.93366, 18.32262]],
  [7055459, [-8.2915, 115.279]],
  [1796617, [36.09755, 107.77839]],
  [3019480, [48.63333, -2.83333]],
  [3044941, [46.97565, 19.01178]],
  [2650230, [52.774, -2.40967]],
  [1786731, [36.69667, 118.47972]],
  [2841619, [48.12716, 8.33513]],
  [674244, [46.38333, 21.3]],
  [2968822, [43.58223, 6.27587]],
  [4969612, [46.03867, -67.96002]],
  [313130, [38.62198, 38.54052]],
  [2989108, [43.18393, -0.02662]],
  [662486, [44.61667, 23.1]],
  [2644872, [50.85915, -3.80539]],
  [5931800, [49.49991, -115.76879]],
  [3219289, [46.56755, 12.68933]],
  [1712751, [18.25937, 121.99495]],
  [3130186, [42.21108, -4.4888]],
  [726384, [42.08333, 26.33333]],
  [3102987, [51.26418, 15.5697]],
  [3177213, [45.71218, 11.99023]],
  [603919, [63.7, 20.31667]],
  [2814640, [51.35983, 12.07537]],
  [12168501, [-7.76761, 112.08678]],
  [2928165, [54.35, 10.33333]],
  [10062593, [-42.87157, 147.30164]],
  [2846853, [52.48333, 8.01667]],
  [3175968, [45.74273, 8.43659]],
  [5114221, [41.20843, -73.89125]],
  [1816221, [30.28757, 112.22979]],
  [1793064, [29.19476, 109.95846]],
  [2998714, [50.34173, 3.16782]],
  [8949925, [44.9053, 10.25959]],
  [757707, [53.92704, 20.24265]],
  [1804714, [34.71882, 107.06445]],
  [1636322, [-2.46667, 111.03333]],
  [1263195, [20.1505, 76.56841]],
  [2814612, [48.68416, 12.88035]],
  [2766439, [47.38333, 15.01667]],
  [5145028, [40.63205, -73.71263]],
  [2869168, [50.1, 7.35]],
  [8769194, [26.75176, -109.67447]],
  [673130, [46.31562, 27.49007]],
  [677921, [47.9, 25.88333]],
  [710283, [48.42764, 26.51137]],
  [7455977, [-8.4939, 118.2991]],
  [3172502, [45.28774, 8.99254]],
  [3020097, [45.93584, 6.08302]],
  [3081158, [53.153, 17.26802]],
  [1681846, [17.27611, 121.1625]],
  [3019937, [49.70216, 2.94145]],
  [3933138, [-11.75042, -75.48858]],
  [3693464, [-5.92861, -79.22611]],
  [663349, [46.52528, 25.97668]],
  [5637146, [46.12854, -112.94226]],
  [3107235, [42.88829, -2.13667]],
  [686259, [44.26616, 26.50213]],
  [2849047, [47.76667, 11.63333]],
  [1798561, [28.74363, 120.53571]],
  [6587940, [40.94815, 9.57]],
  [1791034, [32.43545, 119.75534]],
  [3393452, [-7.8825, -40.08167]],
  [8288543, [57.78049, 12.03604]],
  [1830468, [11.61531, 102.9838]],
  [686272, [46.11667, 24.81667]],
  [1692989, [8.452, 123.0222]],
  [2669254, [55.86667, 13.08333]],
  [569295, [60.34842, 59.98507]],
  [7375697, [-8.168, 113.1871]],
  [4023114, [21.18208, -100.95996]],
  [4918309, [41.2956, -86.11639]],
  [3124908, [40.8656, -2.14211]],
  [912226, [-11.88333, 29.78333]],
  [3171736, [45.58687, 10.07975]],
  [3947908, [-14.7, -69.55]],
  [2766184, [48.11924, 13.15716]],
  [1273727, [20.32504, 72.96618]],
  [8025691, [-7.1429, 108.1483]],
  [8950142, [40.73285, 14.7116]],
  [3105358, [42.30962, -5.99656]],
  [1175748, [31.56808, 73.18317]],
  [322312, [39.80017, 33.43701]],
  [6062163, [46.13544, -64.90504]],
  [8348042, [-34.98628, 138.59746]],
  [2658642, [46.78765, 7.53937]],
  [8950160, [45.20061, 7.6621]],
  [2510404, [38.75629, -0.27466]],
  [8950027, [45.1708, 11.75374]],
  [2543549, [35.19321, -6.15572]],
  [2860151, [49.6, 10.13333]],
  [1528053, [40.29513, 72.18627]],
  [8272451, [32.42089, 119.40435]],
  [3063255, [50.36108, 16.03763]],
  [3484000, [22.50986, -99.6562]],
  [1794788, [26.96444, 119.16167]],
  [3824738, [19.85032, -96.75804]],
  [8076371, [-7.3065, 108.8328]],
  [4556694, [39.81204, -77.13721]],
  [1627262, [-8.4842, 115.4452]],
  [6534774, [46.60332, 11.52138]],
  [3021398, [49.072, 5.42248]],
  [2962252, [52.39667, -6.93667]],
  [8322417, [-8.1213, 111.7212]],
  [2648428, [53.39967, -0.49489]],
  [8142547, [-6.9997, 112.5089]],
  [3995699, [21.28496, -100.48658]],
  [1274040, [24.88963, 74.62403]],
  [2911395, [51.74297, 7.18163]],
  [2921123, [49.35, 7.51667]],
  [5030670, [44.92496, -93.46273]],
  [8068185, [-6.718, 111.487]],
  [2835683, [50.66667, 7.55]],
  [497534, [55.51514, 36.97746]],
  [3029026, [50.5097, 2.74082]],
  [3123682, [40.23066, -3.99447]],
  [3009940, [50.42563, 2.4918]],
  [2649868, [53.87848, -0.51314]],
  [3130965, [42.1461, -0.00804]],
  [6941772, [7.89607, 98.29661]],
  [2894771, [48.53333, 12.95]],
  [2796183, [50.73506, 4.1116]],
  [6621154, [40.38683, 18.14873]],
  [6535847, [44.74444, 7.47762]],
  [7055002, [-8.3693, 115.1781]],
  [3006616, [43.76961, -0.42748]],
  [4829843, [32.94401, -85.95385]],
  [2523494, [38.17007, 15.27338]],
  [3172199, [42.65742, 13.89491]],
  [9258769, [51.95278, -2.20181]],
  [2843653, [52.21308, 11.399]],
  [8860691, [20.32972, -101.50917]],
  [2821919, [50.71667, 11.86667]],
  [2932960, [48.00405, 11.79528]],
  [1803109, [31.48447, 112.02225]],
  [1270265, [12.05267, 78.48023]],
  [3938139, [-15.75804, -72.91894]],
  [680368, [46.4793, 24.58234]],
  [1624041, [0.79065, 127.38424]],
  [2823137, [49.26667, 7.58333]],
  [1266746, [21.67124, 74.58535]],
  [3666905, [5.75979, -72.82583]],
  [1789873, [34.04566, 109.28714]],
  [1606851, [6.70131, 101.61675]],
  [8858693, [18.01556, -92.97083]],
  [8863122, [19.17778, -96.93167]],
  [8734356, [37.69834, 127.20579]],
  [7375795, [-8.2233, 113.2211]],
  [2693770, [62.29903, 17.04958]],
  [1646227, [-7.5566, 108.0322]],
  [2806141, [52.1337, 11.19335]],
  [5195978, [40.31065, -75.93938]],
  [5215995, [40.7812, -76.67302]],
  [3017254, [43.61057, 2.14166]],
  [1815570, [21.79621, 110.00514]],
  [2331189, [10.4714, 7.41026]],
  [4291063, [37.304, -82.35098]],
  [3165715, [44.00553, 8.02639]],
  [2128815, [42.91722, 143.20444]],
  [684569, [46.3, 23.13333]],
  [3456977, [-10.5775, -37.34278]],
  [5089108, [43.80951, -72.15592]],
  [8144757, [-8.2384, 111.4641]],
  [3823815, [18.85365, -96.87124]],
  [1832642, [36.11962, 126.95283]],
  [517766, [54.14961, 48.38907]],
  [1273371, [29.11344, 77.70467]],
  [2970455, [45.61329, 5.18371]],
  [4845519, [41.81843, -73.14372]],
  [8379143, [46.64914, 6.45335]],
  [3137469, [67.66564, 12.69784]],
  [6943584, [-27.41376, 152.94703]],
  [3985617, [22.13551, -102.29729]],
  [3879200, [-37.71893, -72.24099]],
  [2172389, [-35.5911, 149.44524]],
  [130524, [36.95012, 56.38005]],
  [2948725, [50.31667, 6.61667]],
  [3945481, [-12.35455, -75.20339]],
  [2982298, [48.54326, -1.81558]],
  [5335131, [35.44275, -120.89213]],
  [3570427, [14.83257, -61.05553]],
  [2658486, [47.05484, 8.58584]],
  [3208438, [52.42958, 7.42653]],
  [3112878, [42.01667, 2.03333]],
  [2766894, [47.26667, 15.98333]],
  [3938385, [-10.95465, -69.58162]],
  [3315404, [53.29528, -6.30889]],
  [3417972, [64.1, -22.01667]],
  [1254320, [13.15823, 80.30181]],
  [3420846, [60.71839, -46.03561]],
  [3195499, [46.00611, 16.11111]],
  [2994155, [49.31476, 6.28277]],
  [681715, [44.11712, 28.33402]],
  [3097335, [50.60857, 19.40761]],
  [3166759, [40.39846, 8.72542]],
  [3513976, [20.14048, -97.57567]],
  [4455999, [35.53372, -82.52846]],
  [4915884, [41.05253, -89.05036]],
  [1265150, [22.19073, 69.96351]],
  [8860521, [25.66222, -103.41222]],
  [3827506, [19.83972, -99.39861]],
  [3012689, [43.17093, -1.23458]],
  [8052261, [-7.059, 108.3638]],
  [7884131, [-7.2634, 107.2709]],
  [5039244, [44.27608, -94.18746]],
  [3657509, [-2.19616, -79.88621]],
  [2649365, [52.96095, 0.02702]],
  [2231506, [5.72662, 10.89865]],
  [407690, [13.78024, 43.86901]],
  [9036399, [45.29151, 11.82402]],
  [2501323, [36.46293, 2.73873]],
  [1800198, [25.39977, 114.36218]],
  [3821692, [17.14348, -99.69637]],
  [295584, [32.0243, 34.80632]],
  [2745784, [51.7775, 5.10833]],
  [8950366, [41.63517, 13.53814]],
  [1913442, [21.90817, 111.06302]],
  [7036755, [-7.013, 112.3859]],
  [8273502, [32.05421, 118.79275]],
  [2661633, [46.55485, 6.3731]],
  [3180918, [40.95242, 14.52991]],
  [781621, [42.04056, 20.43417]],
  [4025444, [19.17836, -101.44599]],
  [3801116, [17.85972, -98.14083]],
  [304009, [38.40624, 41.92018]],
  [3874119, [-32.88341, -71.24882]],
  [2875107, [51.61634, 7.52872]],
  [3518543, [19.14286, -98.23597]],
  [3000135, [45.97817, 6.18107]],
  [63795, [9.52213, 45.53363]],
  [3697540, [-6.36667, -78.85]],
  [1730914, [10.3429, 124.8782]],
  [5132065, [42.03868, -78.34085]],
  [2971482, [45.26582, 1.77233]],
  [4349951, [39.28261, -76.10439]],
  [2981824, [43.48171, 2.49074]],
  [3121068, [40.38824, -1.71803]],
  [1897000, [37.43861, 127.13778]],
  [4994851, [42.12921, -83.14437]],
  [2663979, [55.49108, 13.1121]],
  [2966562, [52.38333, -9.83361]],
  [2272491, [6.06846, -8.13559]],
  [3163961, [45.11197, 10.33298]],
  [3519630, [16.42935, -95.97438]],
  [1255705, [21.27851, 86.68833]],
  [2523972, [38.12515, 15.06068]],
  [3166574, [45.6467, 9.14296]],
  [8860573, [19.11, -98.14722]],
  [2660177, [46.91035, 7.47096]],
  [281979, [32.10447, 35.09198]],
  [295635, [31.73503, 34.77016]],
  [8073766, [-7.0082, 108.6482]],
  [1279105, [20.70957, 76.9981]],
  [8064298, [-7.21, 108.4274]],
  [2661169, [46.84986, 9.53287]],
  [3833391, [-36.50682, -63.86225]],
  [2993117, [45.62801, 4.74778]],
  [1711332, [6.79889, 124.77278]],
  [2951165, [50.52921, 10.49592]],
  [712954, [47.99019, 25.11367]],
  [4019091, [19.67844, -104.3258]],
  [312253, [38.24903, 40.77129]],
  [3200683, [45.51889, 13.70139]],
  [2971646, [45.25311, 2.53876]],
  [3705712, [8.35317, -81.75445]],
  [1808195, [27.12222, 104.72389]],
  [3129127, [41.35, 1.78333]],
  [4024585, [20.86948, -101.35689]],
  [5367785, [37.1055, -122.05274]],
  [297542, [39.79202, 36.20603]],
  [2646265, [50.92684, -2.91009]],
  [866134, [40.02508, 44.45007]],
  [557292, [45.42488, 36.77402]],
  [3815637, [17.1328, -91.72292]],
  [4076784, [32.36681, -86.29997]],
  [4195809, [31.60924, -85.04933]],
  [467527, [63.07337, 40.33285]],
  [1928114, [29.40496, 111.9906]],
  [2289291, [7.3883, -7.37644]],
  [3021803, [47.24413, 6.88121]],
  [2637528, [52.38333, 0.0]],
  [3021034, [49.07392, 3.63819]],
  [3441665, [-34.37589, -55.23771]],
  [2863214, [49.58333, 7.7]],
  [3668087, [4.49154, -74.25957]],
  [3116189, [41.46385, -5.65631]],
  [2761148, [48.16667, 13.98333]],
  [5341531, [37.82159, -121.99996]],
  [2833330, [49.64073, 10.22038]],
  [8859451, [22.16278, -102.09472]],
  [7039104, [-8.4985, 115.1942]],
  [706744, [47.95347, 29.42893]],
  [3178976, [44.9534, 6.79199]],
  [8299851, [56.15138, -3.85466]],
  [2959946, [47.8424, 8.85384]],
  [1719966, [14.64361, 120.58083]],
  [7759827, [-6.7528, 105.7277]],
  [3032497, [43.16498, 2.87046]],
  [2522177, [38.64916, -1.97998]],
  [7578272, [42.26868, 126.91122]],
  [254965, [38.79906, 20.74056]],
  [2979890, [48.41869, 1.3546]],
  [4323131, [29.38883, -90.71398]],
  [3686532, [4.4425, -74.04417]],
  [2995103, [49.55, 0.73333]],
  [8067233, [-7.64, 108.7414]],
  [3041204, [42.5676, 1.59756]],
  [5731035, [45.35318, -119.5578]],
  [2655547, [53.98605, -0.78452]],
  [8185272, [-8.2546, 113.5515]],
  [3613509, [15.41667, -87.98333]],
  [3026154, [46.4065, 3.31212]],
  [6138196, [46.11679, -74.48253]],
  [6534504, [45.58638, 9.63996]],
  [2994461, [50.86142, 2.29671]],
  [2647459, [53.14808, -0.54752]],
  [8647227, [-2.3535, 115.3866]],
  [3127800, [41.83412, -1.53271]],
  [745148, [40.92115, 26.38273]],
  [7350366, [-8.104, 113.42]],
  [1646138, [-7.3461, 108.5163]],
  [1275610, [20.76957, 72.96134]],
  [8950378, [45.50045, 11.67386]],
  [4566035, [18.26857, -65.90878]],
  [2264442, [37.85256, -8.79018]],
  [6692697, [35.36225, 24.26013]],
  [3008857, [47.94166, -1.2309]],
  [1257402, [30.24506, 75.84488]],
  [3980596, [21.81195, -105.1032]],
  [763989, [49.98937, 21.52162]],
  [6945986, [47.654, -70.15268]],
  [3616594, [11.84854, -86.43839]],
  [3181637, [45.26142, 11.11786]],
  [3033260, [47.691, 7.18935]],
  [3405993, [-9.67778, -37.12472]],
  [2986807, [48.00186, -2.09935]],
  [2831493, [48.89582, 10.9956]],
  [6619303, [48.05078, 7.96337]],
  [2145338, [-30.59259, 153.01131]],
  [1646230, [-6.675, 106.1737]],
  [1728318, [9.4744, 123.0623]],
  [11717453, [34.7128, 128.04605]],
  [1925457, [28.56996, 116.26248]],
  [730367, [42.98333, 25.63333]],
  [3029297, [49.2911, -0.1133]],
  [2521373, [38.73726, -0.34324]],
  [1090311, [-12.84639, 45.13611]],
  [2033766, [40.61667, 120.71667]],
  [2832013, [49.98198, 7.52351]],
  [1623822, [0.7823, 120.5264]],
  [2989525, [43.5615, -1.44911]],
  [4252812, [37.89783, -88.93146]],
  [6534560, [45.18587, 10.10517]],
  [4267978, [37.02368, -94.7355]],
  [3824524, [20.54889, -97.47129]],
  [1877046, [38.79861, 125.79194]],
  [1274055, [18.12533, 82.1089]],
  [3021115, [49.01643, 3.27774]],
  [5540831, [37.17526, -113.28995]],
  [1791022, [27.23361, 119.56556]],
  [3062983, [49.47577, 15.47885]],
  [3033470, [47.87531, -4.1058]],
  [2649937, [51.48315, 0.17484]],
  [1504442, [53.60722, 92.86667]],
  [686141, [46.68831, 27.38409]],
  [3821805, [17.96172, -98.98495]],
  [8348516, [-27.5662, 152.77844]],
  [8307510, [-7.0095, 106.5822]],
  [1713853, [9.2761, 123.6468]],
  [702421, [51.22601, 24.03727]],
  [2978991, [44.69227, 4.71009]],
  [388065, [29.295, 47.93306]],
  [2034449, [40.05756, 116.57455]],
  [4682742, [26.24869, -97.73388]],
  [763764, [50.86432, 21.09049]],
  [2998888, [49.92601, 3.31225]],
  [2511447, [29.00093, -13.613]],
  [2814195, [48.51667, 9.15]],
  [297416, [37.20293, 40.75375]],
  [2963499, [53.36944, -7.50333]],
  [2905878, [49.53247, 11.15551]],
  [2834548, [50.82502, 10.33788]],
  [3005786, [48.19123, 6.70533]],
  [3022153, [47.06353, -1.25463]],
  [3101004, [52.51157, 19.08694]],
  [3471665, [-22.23056, -43.10556]],
  [1694955, [8.08333, 124.13333]],
  [3526405, [21.15481, -97.76832]],
  [5490385, [35.51476, -106.36586]],
  [3175273, [44.21765, 11.17891]],
  [3038099, [46.33547, 6.4635]],
  [8948957, [44.355, 11.41499]],
  [670982, [47.33738, 22.29607]],
  [3180246, [41.23373, 15.00752]],
  [11009043, [-8.54389, 115.17]],
  [1817934, [26.5769, 116.67586]],
  [5530932, [31.65456, -106.30331]],
  [2978701, [46.95, 4.9]],
  [11458564, [14.18689, 104.35533]],
  [2995058, [47.89159, -1.99248]],
  [3125001, [42.67596, -1.89115]],
  [3182683, [46.51173, 11.44127]],
  [2657445, [57.69596, -4.2551]],
  [4482264, [36.4457, -78.19833]],
  [4133971, [35.73063, -91.19846]],
  [3024801, [49.44264, 2.84628]],
  [4741231, [32.74485, -97.41363]],
  [6535637, [40.84551, 14.37513]],
  [3575170, [17.40605, -62.83562]],
  [3129692, [43.03414, -2.50456]],
  [3081895, [51.47032, 16.6139]],
  [2660863, [46.81885, 8.65052]],
  [2512070, [38.8212, -0.05171]],
  [785082, [42.00973, 20.97155]],
  [3532219, [18.07861, -93.91833]],
  [3532774, [21.62159, -99.01984]],
  [720292, [47.66667, 21.51667]],
  [1849429, [36.40265, 138.28161]],
  [8323183, [-8.1808, 111.8413]],
  [790744, [41.14166, 22.50141]],
  [313267, [37.13706, 27.58186]],
  [2783454, [50.97907, 3.44027]],
  [3182679, [43.98281, 7.92342]],
  [673022, [45.86667, 25.96667]],
  [1646867, [-6.79997, 106.10983]],
  [2980934, [46.27502, 4.97246]],
  [1919751, [23.35354, 115.34974]],
  [3589456, [14.45131, -89.74285]],
  [611902, [41.62046, 45.92198]],
  [8199386, [31.86145, 35.16861]],
  [3523745, [16.90393, -96.5584]],
  [3033487, [47.00114, 2.74648]],
  [1488774, [56.53939, 89.31221]],
  [8986857, [46.00002, 14.5573]],
  [2985653, [42.61755, 2.42177]],
  [4551673, [36.99452, -95.62026]],
  [5122520, [40.69149, -73.80569]],
  [2542715, [33.89352, -5.54727]],
  [1608857, [6.60975, 101.66686]],
  [4014403, [19.33711, -103.6539]],
  [1909649, [34.49045, 107.8232]],
  [2786375, [50.29514, 4.48209]],
  [2784135, [50.86641, 5.54425]],
  [2839670, [52.23206, 11.81382]],
  [3177476, [45.96121, 10.91201]],
  [321191, [37.67735, 31.72458]],
  [653880, [63.96667, 25.76667]],
  [1798805, [31.01006, 118.32175]],
  [8861148, [17.90049, -91.81813]],
  [2986660, [48.6761, -2.92795]],
  [8059689, [-7.152, 108.5098]],
  [2960217, [49.50917, 6.05611]],
  [2776583, [46.95583, 15.58806]],
  [9036370, [44.72397, 8.1178]],
  [3714771, [7.53123, -80.62094]],
  [3082390, [51.43855, 18.94421]],
  [6534551, [45.27358, 10.19927]],
  [2974155, [49.52374, -1.59169]],
  [5881401, [-14.34722, -170.76722]],
  [696008, [51.33745, 33.87066]],
  [1260082, [20.70833, 83.13263]],
  [673063, [44.57433, 27.21406]],
  [2846286, [53.37555, 12.60372]],
  [2224827, [7.32765, 13.58472]],
  [1800128, [38.06194, 114.63651]],
  [8028851, [-7.1189, 108.1996]],
  [8140905, [-6.8843, 112.4529]],
  [1786021, [34.99528, 113.77472]],
  [2034600, [46.86135, 124.442]],
  [2938588, [52.05145, 12.16824]],
  [3172929, [43.88916, 12.61145]],
  [3130417, [41.42059, -2.33189]],
  [1634856, [-2.8594, 103.3039]],
  [3035916, [45.17605, 5.54271]],
  [3028985, [50.61305, 1.99492]],
  [1489474, [54.9824, 82.7363]],
  [2653595, [54.77723, -6.56227]],
  [1724641, [14.88333, 121.03333]],
  [5007525, [42.80281, -83.01299]],
  [8861219, [19.06194, -97.0475]],
  [8861732, [25.85917, -108.91]],
  [6645603, [22.57518, 113.11904]],
  [7636899, [29.50108, 116.20625]],
  [1641858, [-8.2572, 112.4341]],
  [2982997, [45.87201, -0.80525]],
  [3516984, [19.88365, -99.26046]],
  [8420667, [-8.1904, 124.7748]],
  [2854685, [48.51667, 11.03333]],
  [2411863, [13.5, -14.01667]],
  [5123840, [40.8351, -73.13122]],
  [3469136, [-22.88583, -48.445]],
  [2642766, [53.59305, -1.84861]],
  [2519753, [39.86667, -0.45]],
  [2792117, [50.55371, 4.7272]],
  [679881, [46.33896, 24.31177]],
  [1214355, [2.1524, 98.4745]],
  [687404, [45.93503, 33.89163]],
  [4802417, [38.48871, -81.34817]],
  [3190906, [45.10889, 18.46444]],
  [3080156, [50.12091, 19.92156]],
  [1787923, [33.40545, 106.05763]],
  [322051, [38.73707, 29.75194]],
  [2938304, [53.62967, 11.76639]],
  [3128382, [40.38641, -5.76341]],
  [935255, [-20.89686, 55.55056]],
  [8423484, [-8.2059, 123.8183]],
  [1538308, [48.16, 99.87556]],
  [1928437, [28.17167, 110.26083]],
  [8860870, [18.38, -95.27583]],
  [2646329, [53.4115, -2.83935]],
  [3004304, [48.03299, -3.49048]],
  [3180495, [40.34404, 18.04195]],
  [10063050, [-28.09975, 153.42463]],
  [1528091, [40.7046, 72.86666]],
  [2524008, [38.54915, 15.93584]],
  [5778244, [40.61106, -111.89994]],
  [6534475, [46.02488, 9.21274]],
  [8859618, [22.22361, -101.0625]],
  [3534363, [21.25817, -78.14979]],
  [2142316, [-23.12683, 150.74406]],
  [2522157, [39.90315, -5.10588]],
  [2755711, [52.99454, 6.89821]],
  [4006947, [20.58211, -100.6712]],
  [2947813, [54.28211, 10.6582]],
  [6295299, [47.40477, 8.39168]],
  [3092820, [53.58634, 16.39177]],
  [3561408, [20.0485, -75.94579]],
  [8054325, [-7.1185, 108.4608]],
  [11564392, [46.82811, 32.2202]],
  [1175010, [27.05918, 68.21022]],
  [2926304, [49.10306, 9.21083]],
  [3169056, [45.52081, 9.7544]],
  [2806453, [51.42185, 10.73815]],
  [3132051, [58.09991, 6.58696]],
  [3175442, [43.25864, 11.37866]],
  [7582376, [-8.2464, 115.5631]],
  [3027792, [48.98913, -0.39512]],
  [626548, [54.3118, 26.2916]],
  [8858480, [19.36889, -99.77861]],
  [7262897, [61.51262, -149.60012]],
  [682404, [45.02088, 26.32722]],
  [11612555, [34.8045, 135.76681]],
  [2650094, [52.48084, 1.48839]],
  [2752986, [52.02917, 6.29444]],
  [3114780, [43.10198, -7.61497]],
  [1784919, [33.18774, 112.84527]],
  [3016641, [48.95587, 1.26523]],
  [5380437, [36.61774, -121.91662]],
  [762774, [50.88798, 21.66658]],
  [3007818, [48.09003, -4.06187]],
  [2073633, [-32.33383, 117.87181]],
  [5755474, [43.39012, -123.31258]],
  [4012112, [20.02043, -101.69534]],
  [1599641, [17.16261, 102.57272]],
  [3128351, [40.04603, -3.05517]],
  [2997768, [47.25973, 5.28694]],
  [2991352, [48.5176, -3.23975]],
  [671218, [46.11667, 21.58333]],
  [1681346, [10.68417, 122.43222]],
  [2772942, [47.73621, 16.21985]],
  [7670890, [50.90395, -1.06744]],
  [3518627, [16.3022, -97.31729]],
  [3079490, [49.41736, 17.50134]],
  [746252, [40.78567, 37.58969]],
  [5018285, [45.33246, -93.74608]],
  [7406483, [-8.0205, 113.8262]],
  [2967467, [50.71108, 2.34483]],
  [671621, [45.20351, 25.90345]],
  [2967468, [49.77517, 4.68175]],
  [525365, [52.0834, 40.77]],
  [2995610, [47.29088, 5.77277]],
  [4500688, [39.90929, -74.15486]],
  [3123515, [43.14017, -2.00102]],
  [3044156, [47.402, 19.63047]],
  [258458, [37.68269, 22.29586]],
  [543508, [67.56616, 30.47576]],
  [2745005, [52.88301, 5.40218]],
  [2013441, [51.30024, 117.88762]],
  [3986990, [26.8632, -105.84838]],
  [7581895, [-7.8378, 114.0712]],
  [2653554, [55.15, -6.78333]],
  [3064325, [50.06493, 16.61791]],
  [665242, [45.96667, 27.36667]],
  [2748657, [51.87152, 4.46901]],
  [168355, [33.18378, 35.89158]],
  [2803884, [48.08433, 11.82446]],
  [6535806, [45.45356, 7.85632]],
  [8391970, [-6.9789, 113.3261]],
  [6291558, [47.48012, 8.48576]],
  [2621101, [56.06558, 12.28512]],
  [3999099, [22.22604, -101.22458]],
  [8348460, [-33.89743, 150.93446]],
  [3078303, [50.01168, 16.619]],
  [2640185, [53.93335, -0.78106]],
  [8859706, [19.88389, -97.42333]],
  [2909222, [54.53333, 9.03333]],
  [4859491, [41.67721, -94.5033]],
  [3018389, [43.36856, 6.22154]],
  [2998805, [44.62881, 0.1994]],
  [106281, [27.52188, 41.69073]],
  [1264331, [25.18305, 93.11342]],
  [3615556, [14.9, -87.95]],
  [3130249, [41.6657, 0.55564]],
  [769364, [51.88597, 20.24703]],
  [2867361, [53.49244, 10.56404]],
  [11704282, [-37.54087, 143.86648]],
  [2795568, [50.68178, 5.45385]],
  [1649683, [-7.3672, 107.1502]],
  [1277820, [24.10473, 88.25155]],
  [3171953, [45.6749, 9.69083]],
  [8348953, [-26.72273, 153.12688]],
  [3998075, [23.68273, -109.69953]],
  [6295496, [47.3605, 8.53127]],
  [8860227, [16.72056, -93.2575]],
  [2408250, [8.2789, -10.573]],
  [776415, [51.55786, 22.13625]],
  [3986971, [19.55601, -102.39844]],
  [2917868, [50.39024, 8.4569]],
  [4114930, [36.48535, -93.73214]],
  [8025918, [-7.1981, 108.1573]],
  [3977237, [21.62506, -103.63939]],
  [2824787, [47.66033, 10.34991]],
  [2643030, [52.62428, -1.40174]],
  [1150735, [15.15553, 98.45361]],
  [1646492, [-6.93889, 107.75278]],
  [1217362, [39.71611, 66.66417]],
  [8074749, [-6.868, 111.7156]],
  [2895284, [50.74509, 12.85414]],
  [3084807, [52.63265, 19.9855]],
  [665182, [47.1, 26.53333]],
  [1523512, [43.52786, 68.58287]],
  [3471840, [-15.8975, -52.25083]],
  [5273439, [43.33361, -88.28621]],
  [3010384, [47.929, -1.94098]],
  [7576812, [50.67753, 8.86425]],
  [1792778, [31.04032, 114.84676]],
  [3166598, [45.53329, 9.22585]],
  [7035928, [-7.1814, 111.6234]],
  [1764160, [5.32699, 100.27348]],
  [2992492, [45.9554, 4.93789]],
  [4168630, [27.8428, -82.69954]],
  [1573517, [20.43389, 106.17729]],
  [3084353, [50.38725, 19.08038]],
  [2657693, [52.61388, -2.69468]],
  [1260314, [25.50789, 78.75954]],
  [592091, [58.99004, 23.24441]],
  [439218, [36.92389, 40.05667]],
  [2754339, [51.77167, 5.64167]],
  [3599181, [14.82017, -89.37224]],
  [611682, [42.17979, 42.98301]],
  [1806245, [39.5225, 117.7025]],
  [2981382, [48.06922, -0.82612]],
  [3104133, [52.87659, 18.69345]],
  [3067443, [49.8059, 16.11621]],
  [3693782, [-3.32879, -71.85505]],
  [295743, [32.68046, 35.57776]],
  [2753034, [52.94595, 6.01261]],
  [3203611, [46.33808, 13.55245]],
  [6295372, [47.37243, 8.4559]],
  [3170359, [45.30945, 11.95474]],
  [3164620, [45.1571, 7.0107]],
  [2968574, [44.55699, 4.50215]],
  [2639715, [51.28862, -2.46003]],
  [734336, [41.13382, 24.99408]],
  [2657933, [47.47633, 8.08506]],
  [2834081, [49.44222, 9.33417]],
  [3124283, [42.90357, -5.14374]],
  [8571752, [5.12223, 97.5623]],
  [3117461, [42.44518, 1.78777]],
  [7413305, [35.56068, 102.19891]],
  [486983, [58.7225, 50.14389]],
  [2262189, [39.30569, -8.62876]],
  [3050967, [46.29861, 19.32583]],
  [672341, [46.23046, 27.29119]],
  [2997929, [49.78333, 2.16667]],
  [2786810, [50.98849, 3.92657]],
  [2972137, [47.05577, -0.76789]],
  [3069377, [50.2415, 15.49082]],
  [647383, [61.36293, 24.66781]],
  [682085, [46.98333, 24.18333]],
  [315697, [36.73583, 29.91775]],
  [3017918, [49.09346, -0.35294]],
  [1273865, [11.00555, 76.96612]],
  [689106, [48.7109, 23.1851]],
  [5337896, [38.95406, -122.72082]],
  [3181685, [44.82373, 8.68008]],
  [6534531, [45.73609, 10.47337]],
  [3036289, [50.41457, 3.05396]],
  [2809628, [51.46667, 12.2]],
  [695685, [48.33902, 26.07342]],
  [1538232, [40.13782, 65.37545]],
  [2790756, [50.63427, 4.61061]],
  [6559670, [48.8759, -0.40221]],
  [1853195, [34.58333, 135.46667]],
  [2645599, [55.63801, -4.5273]],
  [3530264, [16.60774, -98.97821]],
  [2304548, [5.79473, 0.89728]],
  [1809417, [30.51941, 111.42664]],
  [8860621, [17.26806, -98.01056]],
  [1647601, [-1.72139, 114.8397]],
  [8861270, [20.73028, -100.66972]],
  [500589, [55.97278, 36.53417]],
  [8860640, [20.02917, -98.7875]],
  [1274256, [11.39933, 79.69144]],
  [4852832, [41.26194, -95.86083]],
  [2839316, [49.36667, 7.13333]],
  [296639, [37.89764, 40.00086]],
  [11549772, [41.40269, 2.1162]],
  [3194499, [45.14028, 19.03556]],
  [8859671, [18.42528, -93.15083]],
  [5948094, [44.5834, -79.86636]],
  [1164069, [32.92766, 72.41594]],
  [5146248, [41.772, -80.85453]],
  [3743400, [-6.03713, -79.13638]],
  [7579700, [-8.109, 111.9764]],
  [685932, [45.8, 23.01667]],
  [3460901, [-19.80194, -40.85556]],
  [4781708, [37.55376, -77.46026]],
  [2452830, [15.9322, -3.9906]],
  [1276486, [13.16558, 75.86519]],
  [5367440, [37.68187, -121.76801]],
  [3161622, [62.82496, 10.01177]],
  [1728748, [7.27611, 124.26417]],
  [5180859, [40.4412, -75.88687]],
  [2073571, [-33.85036, 115.10379]],
  [2511366, [37.80626, -0.83736]],
  [1702718, [13.99611, 121.83577]],
  [2052477, [40.04694, 111.78222]],
  [1264688, [22.89247, 92.74218]],
  [3107867, [41.21009, -1.338]],
  [3192159, [44.86389, 18.95528]],
  [2982856, [43.97552, 2.99103]],
  [2148033, [-30.82079, 152.99329]],
  [3006053, [44.30653, 0.6058]],
  [685196, [46.7952, 23.13225]],
  [3170342, [41.66931, 12.50124]],
  [976764, [-26.55987, 28.0195]],
  [1893340, [21.60705, 111.34675]],
  [1636347, [-7.4881, 108.6982]],
  [2972868, [46.52336, 3.58179]],
  [6113406, [54.31507, -130.32098]],
  [3818890, [18.79243, -98.19482]],
  [3702748, [8.46507, -82.16439]],
  [2661241, [46.51217, 9.85794]],
  [681747, [44.6, 25.85]],
  [1688119, [7.99111, 126.03]],
  [2159291, [-37.93333, 145.3]],
  [2997546, [48.98948, 6.08648]],
  [1258581, [24.17737, 87.78275]],
  [3825170, [17.80889, -95.2175]],
  [1266302, [25.21928, 77.61167]],
  [2991896, [45.89815, 4.70051]],
  [2990516, [45.87615, 4.84099]],
  [8949277, [39.09939, 16.81287]],
  [2988794, [48.01835, -2.17042]],
  [2524090, [38.51477, 16.3194]],
  [3705768, [7.95634, -80.64061]],
  [4688520, [27.35224, -99.49254]],
  [1257482, [30.30182, 76.45786]],
  [2021613, [44.62807, 135.35262]],
  [7761581, [-6.72938, 105.91149]],
  [295511, [32.2615, 34.90196]],
  [6535147, [45.61648, 9.46375]],
  [672981, [47.26249, 24.29698]],
  [736192, [41.02557, 23.77574]],
  [2771783, [48.09643, 14.8006]],
  [694532, [47.92128, 29.56542]],
  [3169824, [40.33335, 15.06739]],
  [2939754, [51.05288, 10.2475]],
  [3584251, [13.64861, -89.26528]],
  [5202535, [41.16953, -75.87742]],
  [758435, [49.9855, 20.35123]],
  [2643276, [50.75826, -2.07806]],
  [2921188, [49.66667, 7.86667]],
  [1787667, [35.4922, 112.40428]],
  [498956, [51.184, 43.7081]],
  [2657634, [54.86667, -6.36667]],
  [284563, [32.00058, 35.20466]],
  [4174921, [26.28425, -80.12227]],
  [8526296, [38.78407, 76.20033]],
  [3823641, [19.17237, -96.30053]],
  [3128308, [42.61771, -6.41545]],
  [2920161, [51.44224, 10.15205]],
  [3007854, [46.64185, -1.80629]],
  [5316890, [32.25174, -110.73731]],
  [4166738, [26.52702, -80.04837]],
  [2886281, [53.75748, 9.69772]],
  [1797988, [31.93662, 121.34913]],
  [6291103, [47.4191, 8.62078]],
  [2743795, [51.235, 3.90417]],
  [1708368, [7.8654, 123.5123]],
  [1152377, [15.751, 99.78925]],
  [7302830, [14.47516, 78.8354]],
  [8410128, [-9.9337, 124.3777]],
  [680585, [45.01756, 22.89543]],
  [2947636, [53.8, 9.41667]],
  [2876554, [50.60556, 7.86597]],
  [3598513, [15.04147, -91.02594]],
  [6534264, [40.96441, 14.26854]],
  [52867, [1.78784, 44.52999]],
  [6293730, [47.34726, 8.58629]],
  [2813539, [51.10839, 10.5106]],
  [2970838, [44.3849, 4.99125]],
  [3592609, [14.53417, -91.50333]],
  [4023492, [21.32361, -100.54861]],
  [2867714, [48.13743, 11.57549]],
  [485698, [45.33528, 42.85472]],
  [2778711, [46.75, 14.91667]],
  [11478187, [11.88548, 105.42782]],
  [2497060, [35.64779, -0.62397]],
  [468657, [51.0333, 59.8742]],
  [2847029, [50.05, 7.58333]],
  [10630003, [34.20442, 117.28386]],
  [2644129, [51.73422, -2.68908]],
  [6824473, [24.62101, 102.75216]],
  [3171027, [44.48504, 8.30805]],
  [701247, [47.10757, 32.20426]],
  [3046693, [47.54946, 17.75535]],
  [3037673, [48.85642, 1.43981]],
  [1723726, [6.79583, 124.91083]],
  [1796728, [29.75909, 120.84786]],
  [2635463, [54.71414, -1.42611]],
  [3104261, [40.72817, -0.16667]],
  [1636670, [-8.99167, 125.21972]],
  [3207679, [50.48225, 6.98804]],
  [7055404, [-8.4903, 115.3891]],
  [3115657, [41.8383, -3.00418]],
  [3183546, [40.79386, 15.93808]],
  [3018950, [44.44041, 0.24868]],
  [365742, [4.41177, 32.57046]],
  [4354234, [39.60678, -75.83327]],
  [3020968, [48.86641, 3.16269]],
  [6137525, [46.50011, -75.98264]],
  [439216, [36.95, 40.14]],
  [1274890, [22.67611, 85.62892]],
  [1812454, [19.57696, 110.8562]],
  [8348155, [-23.392, 150.49624]],
  [8348701, [-24.88126, 113.65365]],
  [4471064, [35.40264, -81.2023]],
  [3518734, [18.74497, -98.02451]],
  [1280051, [36.94895, 98.37463]],
  [539284, [58.10887, 49.91614]],
  [127029, [33.77512, 55.08329]],
  [2649076, [51.06955, -4.1366]],
  [12156788, [47.01121, 32.05534]],
  [2973393, [43.80583, 4.66028]],
  [3181293, [46.0896, 11.06847]],
  [2521273, [39.04607, -2.20152]],
  [3032983, [48.30521, 5.25322]],
  [4974775, [45.99644, -68.44614]],
  [3034234, [46.60156, 3.67314]],
  [489021, [57.26833, 41.87873]],
  [9024919, [25.53246, -108.43992]],
  [2769053, [48.00692, 13.083]],
  [2656184, [52.07821, -0.0539]],
  [3115492, [41.52639, -2.39866]],
  [2769347, [48.42393, 15.21924]],
  [2971619, [45.64115, 0.05559]],
  [3165453, [42.24324, 13.9333]],
  [4711221, [26.15694, -98.02011]],
  [3083677, [50.82567, 15.52274]],
  [2934167, [53.7, 10.56667]],
  [2640064, [57.41427, -1.84596]],
  [561274, [56.65, 38.7]],
  [302525, [39.57715, 32.14132]],
  [2990680, [49.73315, 1.43956]],
  [5286841, [33.67975, -113.94466]],
  [3019481, [45.10115, 4.72536]],
  [3102658, [52.3539, 18.7298]],
  [2124984, [60.20925, 151.09388]],
  [7848422, [-7.60046, 107.77038]],
  [2172999, [-23.95, 151.21667]],
  [2933772, [50.38888, 8.88605]],
  [3813216, [21.11498, -99.00323]],
  [685322, [45.47218, 23.16271]],
  [2766598, [48.6, 15.53333]],
  [2342883, [6.44094, 8.08432]],
  [2968214, [46.16227, 4.68069]],
  [2762704, [47.45392, 16.37684]],
  [1179496, [31.83439, 71.43639]],
  [1153467, [17.95286, 98.68458]],
  [2025325, [44.83808, 133.04332]],
  [2851782, [48.15, 11.35]],
  [3064745, [49.95247, 15.16651]],
  [1814208, [39.52038, 112.81684]],
  [8425183, [-8.4516, 123.017]],
  [3021955, [49.04304, 6.15878]],
  [7089402, [-8.7073, 121.2809]],
  [3622537, [9.52012, -84.32249]],
  [7316034, [30.161, -97.23473]],
  [4255063, [38.87894, -86.04192]],
  [543939, [58.90966, 53.26907]],
  [2911964, [50.74162, 8.20778]],
  [3031930, [45.77003, 1.33124]],
  [3377265, [6.57633, -57.80486]],
  [3025065, [48.68772, 2.01816]],
  [3522010, [22.48124, -99.84922]],
  [669573, [44.7421, 24.57876]],
  [2997619, [50.45786, 2.79215]],
  [2874282, [49.30528, 8.13167]],
  [504400, [55.50874, 31.84819]],
  [1804120, [21.64673, 110.28172]],
  [1646008, [-7.2795, 108.6079]],
  [1797465, [29.34701, 117.89964]],
  [8347824, [-34.97607, 138.61034]],
  [3931255, [-17.1719, -71.7924]],
  [8347728, [-36.39986, 144.94964]],
  [2634206, [52.94292, 1.14095]],
  [2989736, [49.17291, 6.91472]],
  [3083958, [49.65802, 19.25406]],
  [3151574, [61.22348, 6.08471]],
  [3522451, [16.79151, -96.67455]],
  [8349003, [-34.75705, 138.60819]],
  [361435, [26.23648, 32.00387]],
  [3450832, [-12.28389, -40.49389]],
  [8347693, [-32.94906, 151.71009]],
  [2029156, [50.23139, 106.20778]],
  [2327521, [5.71044, 6.80936]],
  [4375379, [39.0181, -91.89712]],
  [735235, [40.51685, 22.39599]],
  [8538237, [-7.6445, 108.6451]],
  [2646040, [51.54921, -2.46087]],
  [2192521, [-40.9, 176.21667]],
  [2980906, [46.17249, 4.21447]],
  [301158, [37.07932, 40.64246]],
  [3401148, [-1.82833, -44.86833]],
  [3170370, [45.57976, 8.00592]],
  [3199742, [45.31306, 17.81528]],
  [2639845, [51.4839, 0.24247]],
  [3099074, [51.6446, 18.17259]],
  [2971692, [49.2854, 1.82129]],
  [3024693, [48.04805, -4.64717]],
  [2037842, [41.55406, 125.60401]],
  [479596, [44.1932, 41.5529]],
  [7920946, [-7.2187, 107.9308]],
  [2828994, [53.61028, 10.05917]],
  [710909, [48.06296, 30.71724]],
  [8053832, [-7.3352, 108.4184]],
  [8594715, [21.58992, 103.42179]],
  [2635871, [54.11547, -0.3895]],
  [4503736, [39.92872, -74.94378]],
  [1865290, [36.33011, 138.89585]],
  [3033600, [50.09486, 1.5403]],
  [2749992, [53.22135, 6.00523]],
  [8310778, [-21.00476, 149.15273]],
  [3613900, [14.8, -88.2]],
  [3031918, [48.52849, 2.58943]],
  [3690828, [-5.18114, -79.75055]],
  [4026710, [19.63445, -100.13596]],
  [2978017, [49.91952, 4.13278]],
  [3124142, [43.56217, -6.14589]],
  [4543762, [35.22257, -97.43948]],
  [2751180, [50.92906, 5.92738]],
  [4003733, [19.37771, -102.07924]],
  [8555444, [41.44587, 113.30316]],
  [3165845, [43.39458, 11.7853]],
  [1608718, [13.81407, 100.73135]],
  [2911308, [49.19, 8.54056]],
  [3518377, [19.2113, -99.58469]],
  [7688385, [-7.51218, 108.84042]],
  [3188089, [43.45, 16.15]],
  [1713030, [11.24639, 125.53944]],
  [8860942, [21.9275, -100.92389]],
  [691497, [48.86519, 25.00385]],
  [4026130, [18.38474, -100.28747]],
  [3087829, [50.51921, 16.77818]],
  [8553954, [41.62665, 112.27221]],
  [3515696, [19.8173, -97.35992]],
  [3862293, [-30.57952, -65.72696]],
  [2990146, [45.91984, 6.02212]],
  [3702921, [8.87551, -82.77789]],
  [5718492, [45.52484, -122.81093]],
  [4498603, [34.64045, -78.4839]],
  [953781, [-26.26781, 27.85849]],
  [3112146, [40.81685, -3.59837]],
  [2937936, [51.93855, 8.87318]],
  [3125301, [41.43301, -1.73568]],
  [2851231, [54.71667, 9.9]],
  [2862164, [50.13333, 7.58333]],
  [3042259, [54.38256, -4.38921]],
  [1928081, [29.83438, 111.8456]],
  [257721, [37.72674, 26.68964]],
  [256268, [38.4929, 22.97962]],
  [3669729, [8.24976, -74.72079]],
  [2766757, [47.1275, 10.26372]],
  [736937, [40.78916, 24.03504]],
  [6535201, [45.66148, 10.12396]],
  [2981178, [43.72806, 4.07998]],
  [3175445, [41.59603, 14.23399]],
  [3179208, [41.01969, 16.92741]],
  [2790219, [50.7404, 5.14204]],
  [295439, [31.44749, 34.76235]],
  [2976836, [48.78063, 0.65739]],
  [733165, [43.06795, 27.86744]],
  [595689, [55.91139, 21.84417]],
  [3343551, [45.94194, 13.66779]],
  [4548606, [36.95451, -94.78801]],
  [8428532, [32.15856, 34.97413]],
  [3028244, [49.51667, 2.36667]],
  [3016337, [44.8587, -0.25739]],
  [2036662, [40.75243, 120.83552]],
  [2784033, [50.75158, 2.94635]],
  [7610554, [-9.6484, 120.2634]],
  [6534303, [44.62854, 8.05684]],
  [685490, [46.3, 27.8]],
  [2152286, [-35.35493, 149.232]],
  [8070803, [-6.7754, 111.4178]],
  [5048361, [45.10774, -93.238]],
  [3167869, [43.96251, 11.32426]],
  [555933, [57.1907, 39.36004]],
  [454310, [57.39485, 21.56121]],
  [2650726, [54.93333, -6.91667]],
  [1260249, [26.07354, 81.49207]],
  [2998386, [46.75152, 2.17381]],
  [3998695, [19.70278, -102.41361]],
  [1692192, [14.6488, 121.0509]],
  [3981362, [19.43365, -103.51657]],
  [7383835, [-8.4416, 118.5794]],
  [8861512, [17.46028, -91.36917]],
  [12075733, [-2.75985, 132.38052]],
  [6087892, [43.10012, -79.06627]],
  [6884965, [36.397, 127.73201]],
  [637247, [62.14025, 25.76948]],
  [4179574, [34.07538, -84.29409]],
  [1553382, [29.81659, 118.43748]],
  [639888, [65.91667, 26.53333]],
  [1861400, [34.06752, 134.44208]],
  [1815105, [35.56667, 106.15]],
  [4347553, [39.07955, -77.07303]],
  [5766162, [43.83388, -101.50987]],
  [2969720, [45.65521, 6.31796]],
  [668660, [44.21482, 27.44848]],
  [2756888, [52.33964, 4.96256]],
  [3123175, [41.75653, -4.10357]],
  [6535583, [45.8245, 11.27249]],
  [7928384, [13.17535, 44.27461]],
  [282457, [32.18966, 34.97063]],
  [3106682, [40.9525, 0.02575]],
  [4177671, [28.69861, -81.42563]],
  [684564, [45.97943, 23.38543]],
  [3192541, [44.07667, 15.20722]],
  [2269041, [38.64004, -9.1508]],
  [7070567, [29.0852, 121.59013]],
  [3198029, [45.21972, 18.15028]],
  [283449, [32.21431, 35.17081]],
  [2333312, [11.77232, 8.42631]],
  [2867112, [48.53644, 11.8073]],
  [3050785, [46.23623, 17.62014]],
  [786096, [42.49861, 20.37889]],
  [2511304, [37.38758, -6.20346]],
  [1170667, [25.0429, 68.65591]],
  [2938985, [49.62868, 7.94355]],
  [3196606, [45.27904, 14.71906]],
  [5205088, [40.81675, -76.04715]],
  [4150880, [28.53639, -81.01756]],
  [691318, [48.07514, 30.41559]],
  [5751253, [42.61068, -122.81254]],
  [2648049, [51.90308, 1.18807]],
  [680430, [45.85, 26.18333]],
  [672943, [47.03333, 27.53333]],
  [2970444, [44.76667, 5.03333]],
  [1784969, [25.59784, 103.79613]],
  [4014296, [19.84654, -102.11716]],
  [2656916, [52.04139, -2.00549]],
  [746180, [40.83444, 29.4]],
  [8949082, [46.43609, 10.35713]],
  [2033512, [40.13857, 116.12856]],
  [3174692, [45.48316, 9.4103]],
  [2525552, [37.70352, 13.26051]],
  [3130259, [41.26505, -2.67064]],
  [3471674, [-21.35861, -46.14556]],
  [3519456, [18.46092, -97.29827]],
  [2973597, [44.72051, -0.4057]],
  [2807068, [53.69282, 10.13117]],
  [3672255, [4.3335, -75.70463]],
  [5387844, [37.73604, -120.93549]],
  [678222, [47.53333, 23.76667]],
  [3995943, [24.29421, -103.4509]],
  [1152765, [18.82958, 97.93347]],
  [2839207, [51.23333, 11.2]],
  [2659878, [46.92127, 8.99799]],
  [1646622, [-6.754, 105.9539]],
  [4008638, [22.18812, -105.32598]],
  [4155669, [25.44789, -80.47922]],
  [5355782, [33.8314, -118.07284]],
  [2995059, [48.99614, 2.06155]],
  [8948943, [45.72298, 9.25526]],
  [3519978, [18.91778, -98.39694]],
  [4888015, [41.84559, -87.75394]],
  [3033339, [50.25245, 3.53201]],
  [8512517, [47.54101, 125.79828]],
  [3177890, [43.85477, 7.94106]],
  [4004886, [31.30862, -110.94217]],
  [3026237, [48.56064, -1.93]],
  [6324534, [38.03381, 23.82962]],
  [3121102, [41.87109, -1.72794]],
  [9973118, [-34.99705, 138.52155]],
  [3526487, [19.55644, -99.60833]],
  [7510987, [15.00244, 103.59154]],
  [2970014, [46.09813, 6.68156]],
  [3007552, [48.51692, -3.03079]],
  [788571, [42.59778, 20.29139]],
  [2782434, [47.5912, 14.36372]],
  [2866102, [49.73088, 9.67161]],
  [8859149, [32.16611, -114.96167]],
  [1697310, [13.53, 123.0588]],
  [2346399, [9.88224, 9.68058]],
  [293092, [31.80462, 34.9321]],
  [3446400, [-11.18389, -37.99833]],
  [8348333, [-31.81255, 115.8458]],
  [3087285, [51.19026, 19.85375]],
  [3067004, [49.2713, 17.33555]],
  [2328814, [4.73478, 7.74897]],
  [2033367, [46.34077, 129.54419]],
  [4035413, [-13.83333, -171.76666]],
  [1624470, [-7.0463, 107.2224]],
  [934533, [-20.06972, 57.64194]],
  [1888247, [17.33628, 103.47547]],
  [2983536, [45.81667, 4.9]],
  [7612650, [-8.2891, 120.4559]],
  [681496, [47.2, 23.78333]],
  [3472520, [-16.18361, -40.69444]],
  [1792811, [24.16447, 115.4911]],
  [260604, [36.82791, 22.77732]],
  [6292273, [47.27632, 8.75851]],
  [2511423, [38.84399, -3.24148]],
  [4407294, [37.98144, -90.04178]],
  [7755832, [-6.60997, 105.69692]],
  [9972473, [-37.82033, 145.31599]],
  [3027073, [48.02489, 3.66995]],
  [8860809, [32.47694, -116.79583]],
  [3972951, [23.27096, -102.91213]],
  [8949050, [45.85274, 11.899]],
  [1904174, [29.13696, 105.86988]],
  [2979320, [47.74177, 0.05676]],
  [3518223, [16.27835, -96.63106]],
  [3049430, [47.01096, 16.60596]],
  [2524020, [38.12215, 14.78702]],
  [4558004, [39.89095, -75.2838]],
  [3524193, [26.06217, -99.19556]],
  [1714824, [12.1923, 123.8592]],
  [2986962, [46.998, 2.45428]],
  [1263942, [8.14776, 77.30552]],
  [1715212, [9.86944, 125.96972]],
  [1712519, [13.7373, 123.868]],
  [3071884, [50.49816, 15.2985]],
  [4500748, [39.26873, -74.86656]],
  [785537, [41.18647, 22.7203]],
  [300808, [36.37778, 33.93444]],
  [2982154, [50.85922, 2.15838]],
  [2974892, [48.34581, 7.5525]],
  [664175, [47.16667, 26.51667]],
  [3763237, [-6.531, -76.49134]],
  [8860302, [19.32528, -100.09]],
  [4024792, [20.05099, -100.679]],
  [3471208, [-21.95889, -44.89]],
  [2891831, [49.63425, 6.8239]],
  [576590, [52.982, 49.4333]],
  [7036087, [-7.1441, 111.6856]],
  [2796561, [50.31097, 4.54435]],
  [3038169, [45.22907, 2.93449]],
  [7846153, [-7.0358, 106.5633]],
  [1634460, [-8.7765, 120.131]],
  [1500629, [55.28222, 65.3175]],
  [3514404, [18.50472, -97.07944]],
  [8724613, [20.34387, -97.62223]],
  [3191937, [43.80639, 15.85472]],
  [3600301, [15.43333, -84.4]],
  [3003349, [49.30144, 1.98705]],
  [2820687, [54.6, 9.68333]],
  [12217195, [11.79164, 108.15629]],
  [3972199, [22.25274, -101.75757]],
  [3213759, [56.29167, 12.8]],
  [1784337, [26.37595, 103.30639]],
  [2915381, [53.81526, 11.33577]],
  [3080668, [50.47897, 19.86088]],
  [3001051, [47.07764, 6.63002]],
  [9972978, [-12.35863, 130.89352]],
  [265107, [39.63531, 20.86578]],
  [3016371, [43.72834, 4.34846]],
  [2972583, [48.75585, 1.05388]],
  [8348792, [-23.70915, 133.86596]],
  [3666193, [5.96278, -75.73806]],
  [1702544, [9.056, 122.9851]],
  [1275814, [16.90742, 74.59954]],
  [7612869, [-8.4791, 121.0306]],
  [99103, [32.66138, 44.40854]],
  [1783813, [28.05261, 114.16055]],
  [8858366, [21.95944, -102.27417]],
  [3125741, [42.32393, -6.13455]],
  [4024776, [19.95662, -100.73917]],
  [2894620, [50.05, 6.23333]],
  [3345150, [51.92833, -4.63333]],
  [3938571, [-13.72253, -75.8866]],
  [3195984, [46.39361, 16.32833]],
  [2349951, [10.96245, 8.39233]],
  [3017160, [50.41853, 2.60026]],
  [2763390, [46.67028, 13.85861]],
  [2773528, [47.78552, 16.41495]],
  [94824, [32.61603, 44.02488]],
  [3003663, [49.09345, -1.30714]],
  [2525093, [37.78686, 14.03906]],
  [3925498, [-13.32139, -72.08222]],
  [2992686, [47.87272, -2.20971]],
  [1548566, [32.74486, 120.16667]],
  [7373487, [33.85074, 105.67641]],
  [173311, [34.82202, 36.69613]],
  [2338469, [5.58225, 7.09896]],
  [2948222, [53.08312, 9.99772]],
  [3123170, [41.01809, -4.15784]],
  [2725307, [57.20627, 12.21293]],
  [6691235, [53.59245, -2.21941]],
  [2801949, [49.94085, 5.01591]],
  [2920043, [50.0076, 9.1499]],
  [2931501, [48.01404, 10.2087]],
  [3220127, [46.39411, 12.86645]],
  [6691255, [53.44118, -2.09827]],
  [1224061, [6.7989, 81.4808]],
  [5130780, [41.05899, -74.02181]],
  [352344, [31.12624, 31.64313]],
  [168668, [34.89469, 36.49582]],
  [1626134, [-8.15856, 112.48292]],
  [2523304, [37.77042, 12.87744]],
  [5544619, [37.8422, -112.828]],
  [8858697, [16.93944, -92.47778]],
  [2633757, [51.82583, -4.87]],
  [3666163, [5.36753, -73.52075]],
  [3984700, [19.16097, -102.54059]],
  [2523237, [38.70749, 16.40862]],
  [1132034, [34.73414, 66.95292]],
  [2639563, [54.61657, -1.05999]],
  [2987187, [48.05487, 3.14856]],
  [3107509, [40.96442, -4.0359]],
  [2804764, [48.79128, 13.25968]],
  [1643445, [-7.4648, 107.5953]],
  [3177560, [44.74904, 11.88884]],
  [3149577, [64.86549, 11.60465]],
  [3170730, [43.04299, 13.04769]],
  [2976300, [44.434, 0.11205]],
  [3112671, [41.27899, 1.29713]],
  [2899069, [51.49784, 7.1762]],
  [8948906, [39.39158, 16.23998]],
  [7458779, [-8.7552, 120.7106]],
  [3037068, [42.54714, 3.02253]],
  [2984530, [48.62448, 1.68339]],
  [3003359, [48.74485, 1.95594]],
  [2788224, [50.89908, 4.27694]],
  [3115704, [40.72905, -4.01696]],
  [3021646, [44.66499, 1.3157]],
  [1802809, [39.55625, 116.44757]],
  [3491556, [18.2717, -76.76523]],
  [701250, [47.26808, 30.72311]],
  [2786380, [50.27538, 4.84926]],
  [1259102, [33.63828, 75.14261]],
  [1527719, [40.25684, 72.12793]],
  [2658880, [46.89419, 6.77251]],
  [688679, [47.94407, 38.05804]],
  [8076087, [-6.9537, 111.8276]],
  [8539255, [-33.65857, 150.81079]],
  [2614432, [54.93639, 10.71019]],
  [3196928, [42.62028, 18.19139]],
  [1705349, [10.4726, 122.519]],
  [3125758, [42.28463, -8.10627]],
  [8958906, [46.15627, 9.58171]],
  [8056466, [-6.59639, 111.06612]],
  [8310211, [-8.177, 113.6188]],
  [3458093, [-10.65778, -37.30472]],
  [1681887, [13.65, 123.48333]],
  [617180, [47.21079, 27.80047]],
  [8858314, [28.24167, -105.44361]],
  [3464739, [-23.68611, -46.62278]],
  [2989565, [47.49113, 7.39172]],
  [2916923, [51.11667, 11.31667]],
  [2766361, [46.74048, 15.64217]],
  [2057751, [-31.76163, 116.3799]],
  [3099544, [51.34471, 18.25756]],
  [8859067, [19.67806, -100.57639]],
  [4969398, [44.10035, -70.21478]],
  [8347945, [-32.13339, 115.80637]],
  [3182854, [41.16024, 15.10625]],
  [5186171, [40.64229, -79.85089]],
  [11549778, [41.41109, 2.2026]],
  [5972360, [45.60009, -74.61595]],
  [134894, [26.57583, 59.63972]],
  [949880, [-25.99636, 28.2268]],
  [4023070, [21.14746, -100.68473]],
  [2918181, [53.41414, 14.3351]],
  [589580, [58.38588, 24.49711]],
  [3520906, [19.17333, -97.39203]],
  [5341789, [37.03578, -121.86246]],
  [2966668, [54.8, -7.78333]],
  [11592119, [55.88523, -3.77479]],
  [2912621, [51.36081, 7.47168]],
  [6535488, [44.61004, 7.99944]],
  [292913, [24.19167, 55.76056]],
  [3165684, [45.36927, 9.82766]],
  [3036715, [49.09377, 6.26989]],
  [4029614, [20.46806, -100.36861]],
  [3035389, [48.55252, -1.69927]],
  [1917996, [24.87388, 116.81178]],
  [3104720, [42.32051, -2.8137]],
  [6544066, [44.06191, 9.92792]],
  [2857547, [52.12084, 10.56284]],
  [2996267, [48.2384, 2.34287]],
  [8070870, [-6.7996, 111.4646]],
  [6535270, [45.42236, 7.67981]],
  [282284, [32.23829, 35.18297]],
  [6534627, [44.98578, 12.03092]],
  [2659529, [46.92827, 7.11715]],
  [603323, [65.81089, 23.24496]],
  [1681259, [10.33167, 125.56194]],
  [1858283, [36.13333, 139.7]],
  [3179097, [45.11814, 10.78877]],
  [665089, [47.23333, 26.55]],
  [2980430, [43.55, -0.23333]],
  [673438, [45.88333, 23.8]],
  [2794525, [50.94757, 5.24832]],
  [8403611, [30.84349, 108.36642]],
  [3801688, [16.91242, -96.07815]],
  [1802682, [25.16152, 101.29418]],
  [3520264, [18.28197, -95.317]],
  [3824613, [20.21462, -97.06012]],
  [2750600, [51.54, 5.70833]],
  [2633963, [51.96103, 0.23749]],
  [721022, [48.18333, 20.31667]],
  [2171766, [-32.96828, 151.69318]],
  [1801174, [26.51972, 110.41194]],
  [8080438, [-6.9959, 112.3654]],
  [8524307, [40.6538, 101.39987]],
  [680239, [45.92605, 25.20199]],
  [792841, [41.7484, 21.75971]],
  [6535797, [45.03856, 9.04995]],
  [3108654, [40.5417, -4.84548]],
  [1790037, [39.38474, 112.48135]],
  [2713447, [62.54607, 12.5428]],
  [1275426, [26.60664, 80.27098]],
  [4348240, [39.53594, -76.34829]],
  [665985, [44.91676, 25.95109]],
  [5381110, [33.88946, -118.15979]],
  [2818170, [48.26428, 10.44594]],
  [2789997, [50.60868, 4.89213]],
  [7414875, [-8.7229, 120.4146]],
  [4435318, [31.24379, -90.45315]],
  [7364784, [14.27179, 44.78699]],
  [3177542, [43.94961, 12.46828]],
  [2991831, [49.44487, 5.81027]],
  [2260401, [-2.54472, 14.75333]],
  [2640129, [52.64826, -2.89035]],
  [2262867, [38.91703, -9.02656]],
  [2942559, [49.92097, 7.27999]],
  [2975895, [45.82462, 4.87453]],
  [684179, [46.98333, 22.63333]],
  [3099993, [52.44691, 16.40602]],
  [2890322, [49.28333, 7.23333]],
  [2994189, [46.35036, 6.29099]],
  [4603347, [35.44119, -89.77814]],
  [3462371, [-18.0125, -49.35472]],
  [4085336, [33.95482, -86.04192]],
  [8348147, [-34.85287, 138.6975]],
  [1636498, [-6.3102, 105.9886]],
  [3016822, [43.25472, 1.47118]],
  [8861088, [17.07667, -92.31167]],
  [363298, [39.78917, 20.12194]],
  [3173613, [41.10312, 15.05235]],
  [2955732, [49.93, 8.03333]],
  [3109790, [40.50845, -5.40483]],
  [2778396, [47.51332, 12.33164]],
  [2915252, [51.21299, 11.29578]],
  [3592519, [14.63077, -90.60711]],
  [3800931, [17.38579, -97.26481]],
  [2970090, [45.79406, -0.55862]],
  [3044004, [47.54791, 18.82816]],
  [2472833, [35.50056, 11.06056]],
  [2633481, [51.72906, 0.42938]],
  [3688840, [6.11284, -75.98378]],
  [675925, [44.52612, 24.97714]],
  [1570901, [18.51335, 105.42411]],
  [8949535, [38.06821, 15.46991]],
  [2519137, [39.0701, -0.57449]],
  [2644701, [53.75254, -1.34355]],
  [3105681, [42.51672, -5.76737]],
  [509451, [60.25095, 47.78425]],
  [2824967, [52.79337, 8.83611]],
  [3187169, [43.90828, 16.3983]],
  [704335, [46.62258, 30.77421]],
  [2974434, [48.52829, 2.75459]],
  [1281736, [36.46667, 81.96667]],
  [752379, [40.14725, 28.1068]],
  [4500920, [39.41012, -74.36459]],
  [320211, [38.03472, 27.55944]],
  [2128867, [40.20838, 140.0274]],
  [3011467, [48.97132, 7.04412]],
  [714714, [47.48333, 20.73333]],
  [8529535, [28.29289, 109.42036]],
  [2829218, [53.63333, 7.66667]],
  [1638265, [-9.5252, 119.5923]],
  [3516429, [20.44916, -88.83068]],
  [250258, [32.03917, 35.72722]],
  [3013121, [50.31439, 3.78453]],
  [3436718, [-26.01614, -57.85589]],
  [2953534, [48.06231, 9.61244]],
  [6535599, [45.90802, 13.51654]],
  [1702615, [7.31556, 124.63806]],
  [8949745, [41.73507, 12.96163]],
  [7780700, [-6.1548, 105.8734]],
  [2850490, [52.29126, 13.41946]],
  [2980075, [48.60378, -4.36728]],
  [2791226, [50.60492, 3.84848]],
  [2693002, [57.57667, 14.4617]],
  [823981, [41.75259, 46.27052]],
  [3516488, [21.1565, -88.31405]],
  [8862384, [17.29806, -92.4775]],
  [2642689, [52.52332, 0.55402]],
  [1919667, [23.55462, 116.23655]],
  [2792003, [50.37876, 4.89871]],
  [4479602, [36.1893, -81.23759]],
  [2338325, [7.52591, 5.75342]],
  [8949721, [44.01429, 12.40715]],
  [7861157, [-7.3548, 107.037]],
  [2410380, [7.99344, -11.71468]],
  [666431, [44.85823, 21.99544]],
  [1522447, [42.49442, 70.25478]],
  [8949946, [44.43234, 11.93994]],
  [4026487, [18.99214, -100.3707]],
  [169866, [36.21176, 36.52078]],
  [3022997, [43.03282, 2.23367]],
  [3615730, [14.74189, -83.9717]],
  [2661265, [46.18096, 6.13921]],
  [3015642, [48.41161, -0.81287]],
  [3018330, [50.75507, 2.64513]],
  [5733154, [45.34097, -118.04466]],
  [2980783, [43.34266, 6.12493]],
  [1721616, [12.90583, 123.73139]],
  [2977916, [43.6439, 4.1197]],
  [4233572, [39.09282, -89.80398]],
  [3058786, [48.24903, 17.064]],
  [7035890, [-7.1544, 111.5476]],
  [3497350, [19.89131, -70.96204]],
  [3164184, [45.17049, 9.04078]],
  [7302838, [14.54519, 77.10552]],
  [6535090, [45.83597, 8.69683]],
  [4744468, [38.83039, -77.19637]],
  [1523605, [51.95349, 76.02723]],
  [2265300, [39.56399, -8.46835]],
  [2867138, [50.33034, 11.70463]],
  [1958033, [-7.7761, 114.0615]],
  [2949827, [54.18333, 10.86667]],
  [2991228, [48.02551, 7.08243]],
  [6691252, [53.43981, -2.21572]],
  [7873866, [13.46857, 144.78214]],
  [2761698, [48.11351, 15.53295]],
  [3514395, [20.87574, -98.42386]],
  [1607028, [7.13478, 100.25647]],
  [2517955, [39.9257, -4.35095]],
  [3170443, [45.6878, 13.78861]],
  [2645486, [50.28451, -3.77638]],
  [1467349, [36.61922, 69.62805]],
  [3817947, [19.76972, -99.96917]],
  [3191993, [45.93028, 16.3225]],
  [3182958, [41.78629, 15.44394]],
  [3691474, [-6.50139, -76.36556]],
  [3191329, [46.825, 16.29806]],
  [630779, [66.30893, 23.67734]],
  [2642534, [51.71278, -5.0341]],
  [2770918, [47.71134, 16.09787]],
  [2652382, [54.97365, -2.01798]],
  [4407106, [38.5942, -92.33713]],
  [1695185, [13.0398, 121.44792]],
  [3200791, [46.39715, 15.07903]],
  [3114732, [43.14458, -7.29212]],
  [2872126, [48.54586, 10.85179]],
  [8859519, [16.38893, -95.20203]],
  [1635968, [-8.622, 116.4773]],
  [2980182, [47.00755, -0.21093]],
  [3194407, [43.46056, 19.81139]],
  [632552, [63.66667, 28.8]],
  [2774105, [47.84151, 16.50413]],
  [1256052, [29.53489, 75.02898]],
  [2843436, [49.76502, 10.34011]],
  [597989, [55.8888, 21.24448]],
  [2961342, [53.314, -6.61373]],
  [3034140, [48.22747, 0.12923]],
  [6618531, [-17.68587, -65.13912]],
  [2266464, [38.79443, -9.34284]],
  [8861691, [21.46889, -98.89583]],
  [2869749, [51.0, 12.35]],
  [4481427, [35.95739, -75.62406]],
  [4029546, [20.52577, -100.13848]],
  [3670644, [4.41256, -76.15457]],
  [5190808, [41.25424, -77.04608]],
  [677290, [46.93333, 27.01667]],
  [2998165, [47.34322, -1.16536]],
  [679545, [44.83093, 24.35648]],
  [3817581, [19.1, -99.65806]],
  [2781161, [47.14972, 9.8]],
  [8949430, [38.06309, 12.59434]],
  [692039, [51.48211, 23.85904]],
  [2970037, [45.68649, 6.4095]],
  [8199624, [41.555, 13.61803]],
  [2790597, [50.65322, 3.44468]],
  [8416817, [37.60931, 68.58094]],
  [2660299, [47.5001, 8.06275]],
  [2658058, [47.33339, 8.29791]],
  [5104576, [40.194, -74.04875]],
  [265292, [39.18222, 22.75944]],
  [4058027, [32.83124, -85.76357]],
  [3524733, [17.00794, -99.45786]],
  [4925304, [40.76087, -87.15085]],
  [3416839, [64.92427, -23.26313]],
  [2769618, [47.13333, 11.51667]],
  [4557477, [39.89954, -76.64025]],
  [5108191, [40.73843, -73.05067]],
  [3520994, [15.87037, -97.07726]],
  [3104178, [50.13191, 18.33292]],
  [1045114, [-23.865, 35.38333]],
  [2180471, [-37.76667, 175.76667]],
  [6535840, [44.54784, 8.18314]],
  [8299618, [51.02299, 0.91504]],
  [554297, [52.9493, 39.8269]],
  [4014875, [20.52353, -100.8157]],
  [764892, [51.95633, 20.19665]],
  [5264381, [42.9764, -88.10842]],
  [4340196, [30.1252, -91.83345]],
  [2979917, [47.35, -0.28333]],
  [3018684, [49.68673, -1.46284]],
  [1273848, [15.63522, 73.82426]],
  [10103866, [-32.92466, 151.69364]],
  [1144166, [34.99249, 71.41935]],
  [2511940, [38.58725, -0.31157]],
  [4024301, [20.74701, -101.30223]],
  [3183355, [45.68762, 9.76651]],
  [3130097, [40.46527, -3.26838]],
  [7035381, [-8.1213, 112.2828]],
  [2998934, [48.35699, 2.51838]],
  [2797205, [50.33083, 4.0755]],
  [678978, [44.65, 24.26667]],
  [2978179, [48.79395, 2.49323]],
  [2641555, [54.61842, -1.5719]],
  [6091451, [46.0168, -75.08259]],
  [2616450, [55.93405, 12.50485]],
  [3030856, [50.60559, 1.93385]],
  [2980799, [43.73203, 3.97809]],
  [8642860, [49.45937, 8.40377]],
  [1633328, [-8.7766, 122.0455]],
  [1632948, [-4.2254, 120.1484]],
  [6295081, [47.49764, 8.71272]],
  [3007339, [46.6745, -0.14888]],
  [2862403, [50.1, 6.48333]],
  [3173157, [44.73489, 11.68883]],
  [2995054, [42.49014, 2.80752]],
  [5671807, [48.77475, -104.56246]],
  [2823214, [47.97302, 10.95766]],
  [6534886, [45.15465, 8.16143]],
  [763608, [51.63901, 22.10183]],
  [2786324, [50.286, 5.51153]],
  [2974349, [45.75518, 1.27488]],
  [4996274, [42.79197, -83.62773]],
  [2900079, [54.4, 9.85]],
  [2774087, [47.14954, 14.84875]],
  [3013525, [50.41359, 2.96485]],
  [1229901, [7.93965, 81.00274]],
  [2660775, [47.11897, 9.13239]],
  [2790736, [50.94743, 4.09825]],
  [3183147, [43.81618, 10.67668]],
  [678248, [46.75, 26.88333]],
  [3117221, [41.76536, 2.65324]],
  [3598031, [14.11282, -89.91471]],
  [2900066, [51.49154, 10.39358]],
  [1713706, [10.8195, 123.0412]],
  [7409633, [-7.7447, 114.0098]],
  [704277, [49.91447, 24.6134]],
  [2901572, [47.8, 11.0]],
  [2966208, [54.225, -9.99083]],
  [5392400, [34.1214, -118.10646]],
  [3171905, [42.22344, 14.28085]],
  [2984773, [49.48738, -1.66702]],
  [2174307, [-23.94829, 151.35336]],
  [3018155, [49.14756, 6.89482]],
  [296173, [37.57362, 44.28716]],
  [3110570, [42.06667, 2.95]],
  [534717, [50.449, 38.2959]],
  [5200639, [40.84201, -79.89394]],
  [3037602, [45.42715, 1.13715]],
  [3443697, [-30.71905, -57.32596]],
  [2419622, [9.26667, -9.01667]],
  [3030283, [48.1452, -1.89864]],
  [4566346, [18.39078, -66.216]],
  [3086706, [54.57092, 18.38802]],
  [2611316, [56.09519, 10.13605]],
  [3118240, [41.63333, 2.4]],
  [3107726, [40.42709, -1.53242]],
  [301975, [36.08012, 35.97603]],
  [5131073, [42.95756, -77.05747]],
  [3456763, [-24.06222, -53.00694]],
  [3122167, [40.92208, -0.26483]],
  [1605957, [6.25947, 102.05461]],
  [7858332, [-7.0991, 106.9221]],
  [5191703, [41.00256, -78.11445]],
  [3075919, [49.6069, 15.57937]],
  [3019363, [49.30623, 1.61139]],
  [293595, [32.88943, 35.24093]],
  [3177370, [45.24231, 10.97135]],
  [1800065, [37.35806, 115.37444]],
  [2776624, [46.98333, 15.4]],
  [2663452, [58.16667, 12.98333]],
  [3705298, [7.93466, -80.53797]],
  [2967482, [50.68276, 3.04784]],
  [3126674, [41.44453, -2.23792]],
  [2791255, [50.95136, 3.28804]],
  [1721995, [13.1829, 123.6777]],
  [3094370, [51.68288, 19.4028]],
  [3430988, [-34.57028, -59.105]],
  [3158668, [60.4675, 7.07193]],
  [581080, [51.93261, 43.49779]],
  [1809597, [35.91152, 111.31924]],
  [690756, [49.69512, 28.13298]],
  [2655898, [51.89641, -0.11015]],
  [7690166, [28.00128, 114.43209]],
  [4166195, [26.66729, -81.88009]],
  [3005066, [44.86488, -0.59864]],
  [3682458, [4.29005, -74.81612]],
  [3504175, [19.63552, -70.44664]],
  [1277636, [20.66612, 76.77386]],
  [3065456, [49.3796, 14.49507]],
  [8408369, [-7.1532, 114.3304]],
  [3026267, [46.41723, 0.31381]],
  [2756133, [52.81167, 7.04722]],
  [2469744, [35.81363, 9.36434]],
  [3445451, [-15.64667, -56.1325]],
  [1254444, [12.234, 79.65551]],
  [7315221, [39.62385, -105.4178]],
  [1783731, [23.64709, 115.1774]],
  [794964, [59.35083, 24.61418]],
  [4177887, [26.71534, -80.05337]],
  [11903640, [47.06924, 15.45784]],
  [2862065, [54.25, 9.15]],
  [782685, [42.19694, 20.41333]],
  [1626142, [-8.2807, 112.6896]],
  [8656112, [16.1691, 107.71793]],
  [4912783, [41.76142, -88.44369]],
  [4106276, [36.12535, -90.93929]],
  [671243, [46.01667, 23.9]],
  [682403, [45.01667, 26.31667]],
  [303750, [37.07028, 41.21465]],
  [2789705, [50.81729, 3.89293]],
  [684930, [46.90199, 21.7879]],
  [8044868, [-7.7359, 108.2792]],
  [2234803, [5.44836, 10.31355]],
  [1280362, [38.95303, 75.42783]],
  [2979454, [49.25302, 0.1738]],
  [2995933, [50.1211, 3.17321]],
  [8058399, [-6.7284, 111.0598]],
  [2646994, [53.51133, -0.52082]],
  [4011565, [32.50131, -115.06382]],
  [2837724, [49.66667, 7.2]],
  [6692745, [24.49023, 46.26871]],
  [3095396, [52.3387, 16.81063]],
  [3126515, [41.1263, -5.18351]],
  [3169025, [45.34521, 11.93732]],
  [2994837, [50.47109, 2.71544]],
  [4719261, [30.16438, -94.32158]],
  [3034996, [49.06879, 0.82212]],
  [3007685, [48.25002, -1.82319]],
  [2041702, [42.06056, 129.71167]],
  [7643650, [29.28984, 115.81463]],
  [2948355, [50.18333, 6.63333]],
  [5165067, [40.47283, -83.73243]],
  [2657359, [51.87087, -1.12129]],
  [2648484, [52.6466, -1.19493]],
  [663949, [47.1145, 22.72988]],
  [11468289, [46.37536, 12.12719]],
  [2412163, [13.3, -15.83333]],
  [2932119, [50.35, 12.16667]],
  [2993419, [47.8952, 0.19929]],
  [2511250, [36.48839, -4.99123]],
  [3530254, [17.46335, -98.71362]],
  [1493004, [55.61006, 73.49716]],
  [1631240, [-8.2068, 113.5822]],
  [256582, [39.08889, 20.74333]],
  [2183854, [-37.8, 174.88333]],
  [3110612, [40.76912, -5.11523]],
  [2785822, [50.31204, 4.49676]],
  [1815666, [30.17549, 110.86804]],
  [3096765, [53.96849, 14.77262]],
  [2634360, [55.74972, -3.35607]],
  [8725113, [12.5045, 108.33288]],
  [679271, [46.12431, 25.59347]],
  [2656731, [52.98912, -1.28354]],
  [6534691, [44.7524, 8.89575]],
  [8578249, [37.07174, 101.45522]],
  [2802334, [50.77686, 3.27933]],
  [2810000, [51.51582, 11.28696]],
  [865360, [40.37372, 22.39738]],
  [698852, [49.90444, 24.30523]],
  [690745, [49.23385, 23.65111]],
  [1726385, [15.8524, 120.3976]],
  [1267226, [25.29275, 75.93948]],
  [1280114, [31.25887, 94.49558]],
  [6534468, [45.61958, 9.41905]],
  [7086778, [-8.572, 116.6058]],
  [2784992, [50.76171, 5.37455]],
  [1643569, [-4.9753, 105.21396]],
  [3460991, [-20.34528, -41.64111]],
  [1802426, [30.21469, 106.45743]],
  [3034939, [43.61899, 6.54957]],
  [1261806, [30.16173, 78.28712]],
  [7083099, [-8.1532, 113.6721]],
  [3993233, [25.6823, -103.51019]],
  [1258698, [9.2885, 79.31271]],
  [675407, [43.83333, 24.65]],
  [2162446, [-34.64745, 150.77464]],
  [3861329, [-39.28941, -65.6606]],
  [786014, [41.43999, 22.79536]],
  [8348137, [-38.18802, 144.39207]],
  [3019796, [48.55282, 3.65632]],
  [8570046, [30.42449, 115.78172]],
  [2899138, [49.7, 7.53333]],
  [3182772, [45.70205, 7.16267]],
  [2509472, [39.12646, -1.45712]],
  [1790475, [29.28744, 119.53194]],
  [1017780, [-26.21197, 28.25958]],
  [668877, [44.6292, 28.45536]],
  [4146494, [30.5988, -87.03223]],
  [3140390, [59.13118, 10.21665]],
  [1641301, [-8.45, 115.61667]],
  [317075, [38.98295, 39.30017]],
  [2522760, [37.90646, 13.48486]],
  [2663957, [56.41667, 12.75]],
  [5017822, [47.47356, -94.88028]],
  [676429, [47.27813, 22.98324]],
  [3019634, [43.19446, -0.70881]],
  [2746735, [51.455, 5.61251]],
  [3166535, [40.28709, 8.89199]],
  [1274284, [24.38145, 74.7012]],
  [192710, [-1.17139, 36.83556]],
  [4026676, [19.72167, -100.04238]],
  [3110834, [41.34357, 2.03659]],
  [2908571, [51.56994, 11.07955]],
  [2917819, [48.75205, 8.00515]],
  [5191719, [40.61175, -76.71858]],
  [8480061, [28.7764, -97.08232]],
  [6535001, [45.36589, 11.4271]],
  [1648744, [-8.2343, 112.336]],
  [601931, [67.67589, 21.64204]],
  [1434016, [32.79331, 69.0866]],
  [2967547, [47.06481, 6.21528]],
  [2965402, [51.62306, -8.87056]],
  [5377100, [35.04275, -120.476]],
  [313331, [38.5758, 42.01558]],
  [2033866, [42.73722, 124.72222]],
  [2987045, [47.26136, 5.96466]],
  [256745, [38.82814, 22.44807]],
  [8199695, [45.31552, 9.56162]],
  [71834, [15.42409, 51.67836]],
  [3180957, [44.53658, 8.69896]],
  [1709322, [6.00111, 121.23444]],
  [2864705, [53.86548, 11.68512]],
  [3180952, [45.86389, 13.39667]],
  [6534465, [45.94368, 9.07574]],
  [2778455, [47.67487, 15.93893]],
  [304204, [38.51624, 26.62487]],
  [7376142, [-8.4083, 115.3124]],
  [2922124, [50.42482, 9.1984]],
  [3013045, [48.56664, 1.61833]],
  [6690830, [51.37947, -0.31281]],
  [2036519, [45.21667, 131.08333]],
  [5200220, [40.90127, -75.99106]],
  [261614, [37.91033, 23.72361]],
  [11549422, [52.80785, -2.00024]],
  [3170657, [45.29665, 12.03683]],
  [694062, [46.53345, 30.3522]],
  [3061754, [49.22155, 16.29415]],
  [1692795, [13.99679, 120.65409]],
  [3115028, [42.52435, -5.5816]],
  [894239, [-18.13021, 30.14074]],
  [6385813, [-7.59444, 109.115]],
  [4000530, [28.84761, -107.02152]],
  [3701889, [8.96666, -79.76105]],
  [4169033, [29.09637, -80.937]],
  [683469, [47.08333, 24.51667]],
  [3181711, [40.89167, 9.04352]],
  [5777956, [41.25161, -112.0255]],
  [4161510, [27.54309, -80.40227]],
  [3514689, [17.13059, -96.75025]],
  [7658348, [27.64521, 114.83941]],
  [2771766, [48.39319, 15.57793]],
  [3572897, [24.6224, -75.67151]],
  [2704398, [57.48815, 15.84357]],
  [2988290, [46.21388, 0.48389]],
  [3130133, [43.32634, -2.51349]],
  [2163558, [-17.37362, 145.38453]],
  [2542147, [33.04893, -3.98947]],
  [2805497, [49.08083, 9.46056]],
  [2735088, [40.95775, -8.57818]],
  [3171533, [44.83344, 7.58592]],
  [681544, [45.98333, 27.58333]],
  [7039066, [-8.4879, 115.1696]],
  [2766779, [48.47929, 13.73737]],
  [2882265, [49.53772, 7.40472]],
  [2869244, [51.15922, 13.68021]],
  [3020102, [43.30045, 5.52193]],
  [1552274, [30.87929, 118.66986]],
  [8534430, [26.34618, 116.10998]],
  [585220, [41.4275, 48.4302]],
  [2990951, [45.41351, 0.94426]],
  [3388145, [-9.01528, -42.69944]],
  [1622293, [-0.2379, 130.5072]],
  [4715696, [26.26702, -98.1214]],
  [2762392, [47.36667, 12.73333]],
  [6534824, [45.60327, 8.15042]],
  [3645213, [11.4045, -69.67344]],
  [8861366, [19.92556, -97.79417]],
  [3181236, [46.04606, 10.98402]],
  [2813531, [48.89229, 10.61331]],
  [668261, [46.05, 21.28333]],
  [663447, [45.87686, 26.79873]],
  [3192490, [45.86667, 16.91667]],
  [2520453, [39.7747, 2.96506]],
  [2984952, [43.57222, 2.01113]],
  [3521108, [21.28306, -89.66123]],
  [473943, [58.66398, 37.2604]],
  [2011457, [-7.59083, 110.70444]],
  [290269, [26.06861, 50.50389]],
  [3179559, [44.40883, 7.21192]],
  [3468802, [-23.7975, -48.59278]],
  [3175433, [43.88076, 7.63993]],
  [259537, [37.97061, 22.78018]],
  [2686674, [60.22407, 17.70138]],
  [3109804, [41.5159, 2.12457]],
  [2990850, [48.2969, 7.00693]],
  [2974135, [45.92398, -1.009]],
  [2463621, [39.75594, 19.78492]],
  [8416212, [29.50361, 107.05947]],
  [594067, [55.16163, 26.00177]],
  [4526284, [39.91312, -82.41099]],
  [2789883, [50.71396, 5.28133]],
  [4025404, [19.37976, -101.66556]],
  [3192467, [43.60631, 16.22912]],
  [3170688, [43.58986, 13.49413]],
  [1629965, [-3.8445, 119.8173]],
  [2969543, [46.1743, 6.25852]],
  [3129169, [42.21661, -2.00525]],
  [2636520, [51.39878, -0.62944]],
  [2998354, [50.08501, 2.82232]],
  [8953028, [45.80549, 7.56806]],
  [2975310, [48.20053, 0.02545]],
  [6534825, [45.64057, 8.11412]],
  [151920, [-2.13333, 33.05]],
  [2980494, [47.15552, 2.34267]],
  [3177313, [42.87267, 12.0154]],
  [2657676, [51.37135, -0.49353]],
  [3439437, [-26.75291, -55.76828]],
  [2803091, [50.9757, 3.19736]],
  [6603657, [-6.99306, 106.67472]],
  [4326575, [29.91465, -90.05396]],
  [2865660, [52.30259, 11.46261]],
  [7456731, [-8.7076, 118.7298]],
  [2871284, [49.67569, 9.00373]],
  [3503735, [18.5, -69.93333]],
  [2814024, [54.08333, 9.46667]],
  [3182398, [44.2415, 9.94571]],
  [3756907, [7.29889, -72.49472]],
  [2993605, [45.3314, 5.55432]],
  [5101376, [40.84121, -74.04514]],
  [2262544, [38.67222, -9.23268]],
  [1686862, [7.69611, 122.86306]],
  [4363873, [39.00067, -77.07276]],
  [668069, [44.50141, 26.83665]],
  [2794194, [51.09047, 2.9783]],
  [3005001, [46.80654, 4.46953]],
  [3202523, [44.96092, 14.41246]],
  [1716924, [10.3766, 123.9573]],
  [524681, [52.71611, 56.62444]],
  [8433508, [-8.5469, 122.8418]],
  [1714733, [10.9995, 122.6711]],
  [8061051, [-6.8369, 111.2911]],
  [3406545, [-7.5325, -46.03556]],
  [3126701, [40.70811, -0.91489]],
  [2976903, [45.46667, 4.93333]],
  [2636071, [51.99244, -2.1601]],
  [3103782, [49.86211, 19.61746]],
  [4362001, [38.88761, -76.5119]],
  [1928221, [28.98214, 109.66636]],
  [2781653, [47.14256, 16.17784]],
  [669255, [44.15, 25.13333]],
  [8347793, [-27.57357, 153.33017]],
  [1552123, [28.57833, 105.74667]],
  [1910930, [35.36668, 111.14808]],
  [2647195, [54.99692, -1.79386]],
  [3153299, [59.69973, 11.29813]],
  [2359042, [12.82506, -1.06737]],
  [8858359, [21.32111, -101.97333]],
  [3104717, [42.16041, -4.05024]],
  [3074133, [50.87445, 14.57508]],
  [2329150, [4.53673, 6.40332]],
  [3466046, [-26.39583, -52.47083]],
  [616375, [40.14172, 45.27087]],
  [2322269, [8.55336, 3.44654]],
  [1267794, [22.91533, 79.06378]],
  [759839, [53.9377, 21.54642]],
  [5675755, [47.31993, -114.094]],
  [2512316, [39.07632, -1.50699]],
  [620692, [53.5263, 26.3125]],
  [1809362, [32.14879, 119.92653]],
  [8572047, [5.2402, 96.2018]],
  [4677526, [34.09787, -98.57061]],
  [3682018, [7.0682, -73.16981]],
  [174710, [39.76389, 45.33239]],
  [7035372, [-8.1134, 112.2983]],
  [1694396, [10.237, 122.8993]],
  [2871225, [50.22574, 7.83196]],
  [2795717, [50.69635, 5.34159]],
  [3066703, [50.53532, 15.25941]],
  [11703834, [48.48046, 16.47323]],
  [3193710, [43.91222, 15.50889]],
  [3036470, [47.6, 6.51667]],
  [485230, [58.0327, 56.7713]],
  [3176412, [44.33554, 7.40896]],
  [2019429, [50.38278, 106.10556]],
  [2938859, [50.05, 6.13333]],
  [5383431, [35.14275, -120.64128]],
  [3591181, [16.33111, -89.41694]],
  [3515389, [20.2312, -97.80213]],
  [1650801, [2.15668, 100.80763]],
  [3102673, [51.49902, 18.44982]],
  [2806105, [49.84861, 8.12417]],
  [1276589, [27.88832, 76.28108]],
  [1711655, [8.37222, 126.33417]],
  [663105, [46.96667, 24.76667]],
  [3063104, [49.73983, 16.41327]],
  [636301, [63.66667, 27.51667]],
  [6639665, [48.15001, -69.71667]],
  [2751615, [51.44219, 5.43405]],
  [3115252, [41.6, 1.65]],
  [3846616, [-27.71439, -67.13349]],
  [5113790, [42.60118, -76.18048]],
  [2510699, [39.71715, -6.28146]],
  [8549034, [46.21659, 83.43329]],
  [3128787, [41.6784, 1.01731]],
  [3187230, [43.20583, 17.37111]],
  [1265646, [14.05455, 75.39992]],
  [2953350, [51.68516, 12.73483]],
  [1793391, [37.22376, 120.98649]],
  [3004444, [48.36732, -1.21809]],
  [5136465, [42.54897, -74.82099]],
  [6824445, [24.85775, 102.4221]],
  [2636409, [52.30155, -0.00476]],
  [3167128, [45.66067, 10.1969]],
  [1240372, [9.3961, 80.3982]],
  [2991271, [48.3773, 6.90047]],
  [1801086, [31.75374, 110.93214]],
  [264888, [38.08333, 23.7]],
  [8376736, [-7.0089, 113.5144]],
  [4719860, [32.93151, -95.87135]],
  [3598462, [14.0838, -90.38547]],
  [2160582, [-38.16667, 145.16667]],
  [3054600, [47.88352, 19.54381]],
  [2650688, [56.31201, -3.58638]],
  [7315404, [38.34507, -77.50352]],
  [2988292, [43.5445, 2.35406]],
  [2776721, [48.5757, 14.03984]],
  [3588469, [14.98901, -89.71936]],
  [188025, [0.63513, 34.28165]],
  [1965670, [-8.15927, 112.71529]],
  [1680276, [8.53556, 126.22865]],
  [6389296, [-5.81931, -77.38819]],
  [1278294, [23.01754, 76.72208]],
  [3038782, [43.4499, 3.29488]],
  [3520011, [20.88354, -89.88836]],
  [2920205, [52.08474, 7.99944]],
  [683658, [46.6509, 27.67669]],
  [3840076, [-50.0191, -68.52321]],
  [2850067, [50.95275, 11.94248]],
  [3139733, [68.64373, 15.51157]],
  [3169342, [42.05875, 12.99661]],
  [2802910, [50.66921, 4.90381]],
  [3030171, [48.25693, 4.09531]],
  [3953762, [-14.05417, -71.38583]],
  [3204822, [45.9879, 15.94331]],
  [2953421, [50.91667, 11.86667]],
  [622621, [53.2351, 26.6494]],
  [3678363, [1.48016, -75.43664]],
  [3009485, [49.17453, 3.12885]],
  [8347893, [-37.75309, 145.03848]],
  [620214, [52.5591, 31.1794]],
  [8085629, [39.19143, -83.49133]],
  [251959, [38.16716, 23.33659]],
  [1810496, [28.0246, 114.7061]],
  [9887758, [38.2088, 127.95028]],
  [3838638, [-32.89846, -60.90681]],
  [170654, [33.5102, 36.29128]],
  [2990638, [47.51191, 0.91047]],
  [7334424, [-8.5985, 115.1455]],
  [1814577, [39.19306, 117.25583]],
  [2335614, [9.60776, 8.39043]],
  [11875607, [23.60582, 91.34355]],
  [2997248, [42.54609, 9.41865]],
  [5460322, [33.54536, -105.5722]],
  [134613, [34.0185, 58.17222]],
  [2787547, [51.04039, 3.39416]],
  [2910667, [53.9885, 9.86813]],
  [7630131, [45.40209, 127.43068]],
  [2920939, [50.75, 12.71667]],
  [3615358, [15.33528, -88.55306]],
  [2984759, [48.46391, -2.08663]],
  [3129676, [41.34706, -2.84618]],
  [1214447, [0.68842, 99.36194]],
  [633679, [60.45148, 22.26869]],
  [8348473, [-27.93768, 153.36302]],
  [3108648, [41.25846, -3.63729]],
  [2524690, [39.91388, 9.1856]],
  [3670207, [9.14475, -75.50877]],
  [3827117, [19.87583, -99.68889]],
  [1489167, [54.132, 86.4098]],
  [4557713, [39.8998, -79.69782]],
  [1254396, [13.68835, 75.24548]],
  [4998506, [42.89031, -84.35136]],
  [700590, [45.46586, 34.54316]],
  [3073781, [49.29541, 17.78566]],
  [9972942, [-33.8185, 150.96344]],
  [6295490, [47.41127, 8.5648]],
  [1647387, [-7.0336, 107.1323]],
  [361457, [30.43046, 31.03679]],
  [2033209, [41.9075, 124.0744]],
  [536186, [42.96895, 47.35202]],
  [2523386, [39.34164, 16.31304]],
  [619645, [55.4255, 28.157]],
  [3515722, [19.13253, -99.50163]],
  [294558, [31.87702, 34.82108]],
  [7258153, [41.62711, -80.31008]],
  [2169220, [-27.19354, 153.02631]],
  [2975663, [43.3993, -0.94011]],
  [1787046, [29.56975, 114.86279]],
  [2980438, [48.24649, 7.18385]],
  [5011137, [45.41525, -87.60762]],
  [5787893, [47.48927, -122.31512]],
  [1711277, [11.2142, 123.1655]],
  [8860792, [19.10111, -101.57528]],
  [4018438, [24.27684, -104.01873]],
  [7766979, [-6.5827, 106.1676]],
  [2874239, [50.09987, 11.37664]],
  [5731070, [45.84041, -119.28946]],
  [8861835, [18.74389, -99.25083]],
  [3518129, [17.27708, -96.88661]],
  [4023912, [20.29765, -101.8926]],
  [2906670, [54.28333, 10.6]],
  [7896869, [32.38051, 35.50838]],
  [4086552, [33.15984, -85.28745]],
  [765043, [50.28627, 21.81138]],
  [1864009, [35.3, 135.11667]],
  [8397844, [28.93847, 120.1889]],
  [2647292, [55.83333, -4.38333]],
  [6534412, [45.74708, 9.00644]],
  [3031118, [47.38118, 6.00104]],
  [7333626, [-8.5403, 115.0526]],
  [3035403, [48.86667, 2.41667]],
  [2906104, [49.09639, 8.09167]],
  [7766904, [-6.5294, 106.1719]],
  [3678415, [5.40194, -75.88472]],
  [2649518, [53.71058, -1.27948]],
  [3101415, [49.6022, 19.14102]],
  [4976383, [44.38785, -69.96672]],
  [2270084, [39.02362, -8.97692]],
  [3106286, [42.18808, -5.11477]],
  [5095791, [40.65399, -75.08656]],
  [2989241, [46.2727, 6.09982]],
  [3031323, [44.09621, 1.01375]],
  [3723841, [19.15, -72.01667]],
  [4627863, [35.93396, -84.55244]],
  [2882342, [49.0775, 8.84556]],
  [2033627, [40.44821, 120.17149]],
  [2825158, [54.72611, 8.43853]],
  [7260270, [33.6525, -85.8742]],
  [2928392, [49.25, 7.96667]],
  [1185207, [24.12858, 89.06573]],
  [1809711, [32.90051, 110.36785]],
  [7083634, [-7.258, 112.7581]],
  [1693401, [15.04741, 120.51891]],
  [2975625, [45.55109, 3.37147]],
  [6840832, [-7.20784, 113.49788]],
  [2510349, [39.01785, -3.75105]],
  [2735116, [40.66596, -7.87781]],
  [3945676, [-14.11944, -73.31806]],
  [2655583, [54.65554, -1.67706]],
  [3193785, [45.83967, 15.22528]],
  [4218165, [33.74538, -84.13158]],
  [8409940, [-10.0482, 124.4076]],
  [3406996, [-9.50194, -36.02278]],
  [509649, [53.23627, 42.20323]],
  [2110556, [38.23333, 140.36667]],
  [1922364, [26.35974, 117.2901]],
  [508656, [52.9336, 33.4464]],
  [1713287, [8.53153, 126.24117]],
  [3973746, [22.23948, -100.26083]],
  [6929622, [32.6148, 101.52323]],
  [137929, [32.98741, 50.4108]],
  [1702860, [9.88487, 123.40641]],
  [1809096, [31.98667, 121.6875]],
  [2795024, [51.10328, 4.72855]],
  [1265242, [27.94822, 80.77935]],
  [8862488, [20.60139, -103.78389]],
  [3017576, [44.24029, 0.64516]],
  [1276393, [26.80229, 84.50311]],
  [3122426, [42.90492, -9.26289]],
  [8858787, [17.52106, -99.19331]],
  [3836564, [-24.19457, -65.29712]],
  [8859507, [20.95028, -101.29972]],
  [3028425, [48.15819, -4.1392]],
  [578931, [53.65533, 47.11229]],
  [677701, [44.21667, 25.35]],
  [3398691, [-8.20111, -35.56472]],
  [8067470, [-7.0277, 108.5577]],
  [1252770, [20.39324, 78.13201]],
  [8948884, [45.75091, 7.35372]],
  [3109319, [41.19422, -4.06884]],
  [676787, [47.81667, 26.06667]],
  [3165432, [43.08566, 11.54839]],
  [8450630, [33.67482, 96.69652]],
  [3647549, [10.18634, -67.45935]],
  [1267680, [23.94891, 78.86584]],
  [3576414, [13.71667, -60.95]],
  [2668247, [58.70739, 14.12597]],
  [678075, [48.00286, 27.03221]],
  [8433144, [-8.1603, 122.97]],
  [3518295, [17.309, -97.48392]],
  [3521348, [17.00556, -92.74264]],
  [750525, [40.5153, 26.75664]],
  [2413570, [13.76667, -14.91667]],
  [3181551, [45.07807, 9.13119]],
  [3072734, [49.85601, 14.55736]],
  [3164317, [46.07974, 10.72069]],
  [4443769, [32.90652, -90.87816]],
  [2285449, [5.84752, -5.682]],
  [5701361, [40.64213, -116.93427]],
  [1718033, [15.9757, 120.4953]],
  [2862675, [51.80499, 9.11302]],
  [2123805, [49.28619, 142.86975]],
  [2642959, [52.70464, 1.63636]],
  [771683, [52.90083, 21.55578]],
  [2891749, [48.12159, 10.12811]],
  [3072565, [48.87596, 16.07697]],
  [2960019, [49.80306, 6.0]],
  [3101788, [50.10825, 19.19552]],
  [579571, [43.68972, 43.65472]],
  [601608, [42.32773, 59.15442]],
  [2792856, [51.11667, 5.25]],
  [1704703, [15.22303, 120.57117]],
  [2971333, [43.49382, -1.29059]],
  [3575975, [17.05037, -61.80091]],
  [3167010, [40.7602, 14.53723]],
  [2922711, [51.1894, 10.94332]],
  [2119641, [47.84895, 142.51602]],
  [5029851, [44.50719, -96.42421]],
  [5145423, [40.21868, -80.87287]],
  [2725003, [59.0, 15.05]],
  [1272074, [9.27033, 77.82494]],
  [2646003, [51.53622, -0.10304]],
  [311877, [37.64004, 33.31862]],
  [2955259, [48.19845, 12.35003]],
  [8052869, [-7.6003, 108.4134]],
  [3018672, [50.83002, 1.75994]],
  [2655232, [54.09632, -2.80017]],
  [3199933, [44.41752, 16.8169]],
  [7894308, [31.401, 93.601]],
  [1941833, [26.05958, 119.53502]],
  [3106862, [40.1, -1.76667]],
  [282108, [32.27562, 35.19873]],
  [6535342, [45.56706, 8.13832]],
  [3022692, [46.18799, 6.29903]],
  [4025016, [20.04464, -101.60734]],
  [671768, [47.0458, 21.91833]],
  [3802542, [17.90056, -93.77222]],
  [2161984, [-37.89815, 145.38226]],
  [7035760, [-7.184, 111.3876]],
  [1133270, [34.74999, 69.11899]],
  [3024438, [45.83328, 1.00989]],
  [1640939, [-6.6421, 105.6979]],
  [2818025, [48.15, 9.61667]],
  [174769, [39.4367, 46.31787]],
  [8328226, [31.34802, 120.54222]],
  [2684636, [55.54014, 13.09605]],
  [3014261, [48.36464, 2.10061]],
  [257951, [38.61667, 23.75]],
  [1537749, [53.27889, 91.84028]],
  [5336269, [39.72849, -121.83748]],
  [3970972, [28.84028, -111.4775]],
  [252655, [39.10114, 23.07669]],
  [785427, [41.54046, 21.00563]],
  [2986613, [48.25538, -2.23876]],
  [120936, [36.11787, 53.05531]],
  [3705562, [8.49674, -82.42417]],
  [5332975, [39.2335, -123.20389]],
  [2990415, [47.98563, 7.39761]],
  [122869, [32.86006, 53.08749]],
  [8750880, [2.05023, 100.6323]],
  [2770913, [47.23414, 11.37342]],
  [2768079, [48.39411, 16.65819]],
  [3932087, [-13.97222, -71.41]],
  [2766778, [47.85383, 15.56969]],
  [7330781, [33.36245, 104.6173]],
  [672602, [45.03333, 29.16667]],
  [3089680, [52.30444, 19.16565]],
  [8026543, [31.64547, 94.27432]],
  [5293183, [32.14119, -111.02843]],
  [7578271, [42.65086, 126.89333]],
  [3495098, [19.10515, -71.69498]],
  [1701342, [13.03643, 123.37074]],
  [1728103, [7.91667, 124.20028]],
  [3037638, [45.21134, -0.6368]],
  [1258731, [23.80637, 83.69981]],
  [3998067, [18.77802, -100.42778]],
  [2635412, [50.26526, -5.05436]],
  [764880, [50.9368, 20.55096]],
  [6975347, [44.9949, 34.04167]],
  [1925253, [28.65338, 115.87306]],
  [3169624, [45.34921, 11.03786]],
  [2036281, [40.17361, 122.70417]],
  [1680132, [20.35045, 121.93777]],
  [256189, [35.10896, 25.80903]],
  [4992612, [42.79781, -83.70495]],
  [315410, [38.82043, 35.44011]],
  [1795196, [35.07474, 109.08495]],
  [2310533, [1.03225, 10.64882]],
  [686812, [45.05418, 34.32152]],
  [355392, [31.5084, 31.84106]],
  [669940, [44.97389, 22.76444]],
  [227843, [1.43139, 33.77722]],
  [1684466, [11.1257, 124.4028]],
  [3815455, [19.15489, -98.10492]],
  [7368003, [34.97379, 107.52179]],
  [1785545, [30.05, 121.14944]],
  [7426525, [-8.6687, 121.9878]],
  [2641233, [52.82121, 1.38746]],
  [1605223, [14.36278, 100.67203]],
  [3667579, [5.26598, -76.56487]],
  [3167345, [45.18744, 11.71169]],
  [1685875, [16.51918, 121.18124]],
  [4787296, [38.29735, -78.44001]],
  [1788730, [29.23992, 102.37208]],
  [1950548, [26.68806, 110.54222]],
  [7916210, [-7.2794, 107.8936]],
  [3033488, [48.37062, 7.5937]],
  [3182512, [40.83103, 15.07173]],
  [1806914, [28.16222, 117.55018]],
  [8384408, [-7.0001, 113.9732]],
  [2843497, [49.49757, 11.24751]],
  [4554351, [34.00233, -95.09384]],
  [3109954, [42.35446, -5.30929]],
  [461835, [55.61667, 37.76667]],
  [2791544, [50.51097, 4.67374]],
  [1784621, [28.70154, 107.87146]],
  [603292, [65.35, 21.2]],
  [7283587, [10.60826, 76.1762]],
  [3934599, [-14.10982, -73.87378]],
  [8068693, [-6.6918, 111.5439]],
  [2869783, [54.35, 10.18333]],
  [2540810, [34.79584, -5.57849]],
  [2792337, [50.66189, 5.3124]],
  [283106, [31.44052, 35.00001]],
  [3823893, [18.24476, -94.98789]],
  [8576739, [37.35879, 120.45686]],
  [3220295, [46.11803, 12.60961]],
  [173856, [35.59824, 36.03006]],
  [3345317, [51.66778, -3.2075]],
  [1690934, [8.85917, 124.78917]],
  [3009472, [45.56667, 2.16667]],
  [8348873, [-24.87606, 152.37518]],
  [3650267, [-1.44158, -79.45943]],
  [2659755, [46.65644, 7.05948]],
  [3187056, [45.87778, 17.23222]],
  [1264816, [18.59921, 73.92701]],
  [723358, [48.62858, 21.71954]],
  [4989005, [42.53337, -83.14632]],
  [8304648, [17.96209, 97.93288]],
  [3020188, [48.96667, 2.31667]],
  [1607708, [16.82481, 100.25858]],
  [2175819, [-24.39589, 150.51504]],
  [5003401, [43.11669, -83.96997]],
  [717656, [46.4, 20.91667]],
  [5273110, [42.57334, -90.23096]],
  [1790885, [31.9925, 116.24722]],
  [2509457, [39.53333, -0.36667]],
  [3182965, [45.73499, 8.98103]],
  [2874832, [51.98943, 10.2693]],
  [8075218, [-6.9569, 111.7191]],
  [4533683, [36.57897, -94.97107]],
  [8862108, [21.73694, -105.00611]],
  [3079635, [50.51261, 16.05137]],
  [1999581, [34.67333, 108.64911]],
  [2522159, [38.75038, -0.04013]],
  [637284, [67.2925, 28.15806]],
  [1918784, [29.29889, 120.63139]],
  [6535330, [37.63455, 14.94698]],
  [293038, [32.19423, 34.91957]],
  [8741099, [45.76318, 16.19398]],
  [1551265, [26.99167, 106.34167]],
  [2985394, [49.16271, 1.44401]],
  [2034599, [46.38909, 123.41371]],
  [5294698, [35.83667, -110.38152]],
  [2769409, [48.14524, 16.70089]],
  [1648388, [-6.5843, 106.1662]],
  [4229824, [32.68347, -84.73826]],
  [2994798, [48.96014, 2.87885]],
  [8631807, [18.45059, 2.48795]],
  [3205523, [47.73333, 11.18333]],
  [2978636, [47.35121, -2.17962]],
  [2984087, [48.42867, 0.77185]],
  [7333225, [-7.0357, 111.3814]],
  [763028, [53.80967, 21.94811]],
  [2168041, [-33.68333, 151.01667]],
  [2522930, [38.94069, 16.51046]],
  [3451071, [-11.48472, -37.93278]],
  [3047619, [47.7928, 18.95984]],
  [3339127, [46.45194, 15.91222]],
  [2983440, [48.6511, 2.41406]],
  [2781520, [48.25628, 13.04343]],
  [3827190, [19.58709, -99.66671]],
  [3075044, [49.82526, 14.0456]],
  [1550448, [28.11139, 108.41139]],
  [1800869, [28.43695, 116.81448]],
  [1789720, [39.72424, 115.73628]],
  [2699768, [59.87597, 14.99436]],
  [1848243, [36.11567, 139.19429]],
  [10116056, [-36.08144, 146.92991]],
  [6535770, [45.44997, 9.03744]],
  [686479, [45.26298, 26.86402]],
  [307211, [37.58105, 29.26639]],
  [3657594, [-1.60789, -78.63105]],
  [677015, [46.36527, 25.0303]],
  [7259871, [25.49373, -80.39132]],
  [2016443, [53.98473, 123.9403]],
  [7158730, [38.83106, -94.59774]],
  [2872649, [50.32797, 7.22277]],
  [663529, [45.19053, 25.01261]],
  [3532335, [19.36276, -96.97631]],
  [3165226, [45.69409, 9.84173]],
  [6534377, [44.33763, 7.37393]],
  [2179532, [-36.28333, 174.51667]],
  [2787837, [50.76565, 3.26317]],
  [1182815, [31.62685, 71.06471]],
  [2934766, [53.76436, 13.78216]],
  [2037509, [43.14232, 127.14257]],
  [673571, [46.23333, 25.45]],
  [3929740, [-14.32141, -69.4654]],
  [2806127, [48.56667, 8.56667]],
  [11745889, [-42.94888, 147.13341]],
  [1850091, [36.25411, 138.89813]],
  [5116508, [40.70094, -73.71291]],
  [1595207, [15.21006, 100.83076]],
  [3083140, [52.02113, 15.7362]],
  [2647349, [51.76338, -0.22419]],
  [6544038, [43.91255, 10.57228]],
  [5039949, [45.03136, -92.79298]],
  [3666577, [10.32944, -75.41137]],
  [3108520, [41.4, 1.83333]],
  [2993999, [49.0, 1.88333]],
  [8859731, [17.98722, -95.91056]],
  [3712698, [8.29122, -81.79701]],
  [6534638, [46.19021, 11.25988]],
  [672001, [44.96773, 23.81827]],
  [282485, [32.06241, 35.05321]],
  [3030941, [47.41423, 6.77952]],
  [758467, [54.21416, 21.52282]],
  [1271780, [28.8457, 78.2396]],
  [1692174, [17.7159, 121.81135]],
  [3983443, [24.11845, -104.71321]],
  [2970274, [45.56236, 4.2626]],
  [3398350, [-1.12889, -47.62]],
  [2811295, [49.76667, 8.0]],
  [5866719, [63.03417, -163.55333]],
  [8858769, [19.80917, -96.91611]],
  [2842434, [54.52556, 13.55387]],
  [3827001, [19.62083, -99.90972]],
  [1649344, [-4.50263, 104.51926]],
  [662419, [45.77388, 26.90981]],
  [1859100, [35.66667, 138.56667]],
  [3004348, [49.20403, -1.16515]],
  [4845419, [41.27065, -72.94705]],
  [3164334, [44.36364, 7.47208]],
  [4686085, [30.29771, -93.7435]],
  [3004185, [43.2818, 1.06585]],
  [541235, [54.88626, 37.11955]],
  [2634759, [51.30953, -0.05794]],
  [3182961, [46.1522, 10.14884]],
  [2231851, [4.1, 11.9]],
  [7290052, [47.45729, 13.20531]],
  [3121268, [41.54583, -1.64915]],
  [2617443, [57.37047, 9.71466]],
  [3817912, [20.02319, -99.67923]],
  [2746456, [51.64333, 4.75417]],
  [9036145, [43.30651, 11.79171]],
  [777019, [70.37048, 31.11066]],
  [2529656, [34.67452, -5.27331]],
  [3022085, [49.07238, 3.92726]],
  [2820533, [50.09184, 10.26861]],
  [3005696, [50.62818, 2.77076]],
  [677013, [44.23334, 22.96231]],
  [7375327, [-7.0006, 111.261]],
  [703042, [48.26107, 23.38336]],
  [1167798, [25.51122, 69.37803]],
  [3850920, [-31.30391, -60.65894]],
  [2874137, [49.71667, 6.98333]],
  [1540853, [54.92917, 60.7625]],
  [1349041, [22.10833, 88.07889]],
  [148413, [39.15459, 45.44282]],
  [8861451, [19.935, -97.80972]],
  [6291134, [47.504, 8.59477]],
  [5119981, [40.60344, -73.66457]],
  [3155152, [68.11832, 13.55339]],
  [3460225, [-10.26389, -40.19583]],
  [293067, [32.56645, 34.95628]],
  [3709361, [8.06684, -78.36436]],
  [3483804, [22.0175, -98.46471]],
  [4826431, [37.30122, -81.69094]],
  [5338122, [36.82523, -119.70292]],
  [8312261, [40.52934, 141.30553]],
  [3074788, [49.45002, 17.19309]],
  [5341114, [34.02112, -118.39647]],
  [1799179, [29.26259, 119.46114]],
  [4928788, [42.06954, -72.61481]],
  [7845121, [-7.2557, 106.6142]],
  [8384138, [-10.7127, 123.1642]],
  [2170430, [-34.64095, 148.02838]],
  [4882920, [41.9317, -87.98896]],
  [8614682, [-35.00555, 150.69582]],
  [2159131, [-37.97132, 146.98366]],
  [668781, [44.66667, 25.03333]],
  [5218361, [41.27673, -78.49169]],
  [5192840, [41.38341, -76.02465]],
  [8561471, [29.18482, 89.14003]],
  [2818495, [48.20288, 9.64355]],
  [4296229, [37.17344, -82.63099]],
  [5719397, [46.10122, -123.20679]],
  [3400989, [-9.3925, -37.10056]],
  [699862, [45.4996, 34.24298]],
  [2952964, [53.96386, 13.37414]],
  [1437312, [32.28991, 61.52194]],
  [2842143, [52.067, 9.5872]],
  [1803769, [38.19548, 114.33988]],
  [2647446, [52.24246, -0.98937]],
  [3688849, [5.99458, -76.7812]],
  [2147551, [-34.71667, 150.08333]],
  [5346649, [34.15917, -118.50119]],
  [3036509, [44.67546, 1.87662]],
  [1848852, [35.40304, 134.77118]],
  [2726005, [57.83895, 14.81016]],
  [3128812, [42.03858, -3.35669]],
  [2975674, [43.94208, 3.94935]],
  [8862287, [18.83056, -96.72778]],
  [8393733, [-10.1212, 124.0983]],
  [8861352, [18.44417, -95.29028]],
  [5184181, [40.63673, -79.1442]],
  [3174297, [45.78139, 10.61597]],
  [1618473, [13.6535, 102.08825]],
  [7648466, [36.76526, -4.29707]],
  [3173083, [42.98964, 13.6082]],
  [5112020, [40.80038, -72.78982]],
  [3107180, [42.51943, -1.13022]],
  [2408585, [7.93974, -11.14132]],
  [6713640, [3.6876, 96.8896]],
  [2697766, [57.16667, 14.58333]],
  [3671091, [5.33957, -75.73018]],
  [3108595, [40.92149, -2.39211]],
  [662401, [46.36667, 27.28333]],
  [3030085, [48.45648, 2.13712]],
  [5211712, [41.25922, -80.47201]],
  [1259429, [15.40341, 74.01519]],
  [3025832, [49.12194, 1.69148]],
  [6294832, [47.49898, 8.81522]],
  [8950178, [41.10948, 14.78012]],
  [3035316, [47.70335, -2.07199]],
  [1634900, [-7.4217, 107.1546]],
  [3032433, [43.25979, 0.64294]],
  [8425045, [-8.3381, 123.098]],
  [2037998, [41.6175, 125.69083]],
  [1863945, [34.75, 137.91667]],
  [1816093, [30.29093, 119.99093]],
  [3707961, [8.88028, -79.78333]],
  [8860733, [17.34278, -91.72611]],
  [1274020, [17.82884, 82.93526]],
  [102651, [17.1495, 42.62537]],
  [3233832, [48.00833, 13.71667]],
  [3354367, [-18.36463, 16.58146]],
  [765328, [52.6922, 22.02836]],
  [456185, [56.3428, 26.79995]],
  [1259756, [20.48101, 84.23063]],
  [487464, [54.18594, 32.64925]],
  [676396, [45.83333, 25.58333]],
  [8860211, [19.95861, -97.97833]],
  [2525422, [37.75374, 13.26875]],
  [4054555, [34.15204, -85.67885]],
  [3102910, [49.96469, 19.77556]],
  [2755495, [53.13917, 6.62917]],
  [678423, [44.13539, 24.45901]],
  [3125292, [42.0665, 2.90743]],
  [6534461, [46.07058, 8.79873]],
  [8859703, [25.88278, -109.07361]],
  [2766372, [48.02116, 14.43685]],
  [293207, [32.03317, 34.89091]],
  [1783701, [31.41281, 108.31465]],
  [671896, [46.2, 26.61667]],
  [1799118, [27.34056, 109.91833]],
  [2250501, [14.10877, -16.719]],
  [1919753, [23.35044, 115.50987]],
  [3178536, [45.05181, 7.30063]],
  [2522950, [39.46594, 16.94623]],
  [2970627, [47.15686, 2.38963]],
  [8861189, [18.94056, -99.00889]],
  [4003797, [24.20679, -104.66596]],
  [551847, [42.88165, 47.63919]],
  [791548, [41.25728, 20.7218]],
  [3450873, [-30.25833, -54.91417]],
  [2910685, [53.46057, 9.98388]],
  [2824393, [48.84972, 9.83824]],
  [295772, [31.80592, 35.1093]],
  [499585, [42.85262, 44.0065]],
  [5391710, [34.10834, -117.28977]],
  [666676, [45.5, 27.05]],
  [2762378, [47.66667, 13.13333]],
  [2980437, [50.78451, 2.5068]],
  [3946110, [-11.82833, -76.61639]],
  [2843728, [48.70233, 12.02717]],
  [1806736, [32.78044, 110.25557]],
  [1508802, [55.5748, 62.082]],
  [1259855, [11.27564, 77.58794]],
  [2820203, [54.4153, 8.92392]],
  [3071059, [50.33247, 14.52958]],
  [2638047, [52.63802, -1.84147]],
  [2743528, [51.88333, 4.22083]],
  [1685203, [6.39035, 126.14558]],
  [3051477, [46.79031, 17.18408]],
  [1591522, [22.53911, 104.29305]],
  [3187657, [45.56861, 18.61389]],
  [3091054, [51.68868, 16.36817]],
  [3193420, [45.4375, 16.29]],
  [751838, [40.95271, 39.92674]],
  [3116482, [43.33333, -8.01667]],
  [2653707, [52.5599, -3.53545]],
  [3175228, [44.95495, 7.66864]],
  [1726630, [17.03725, 121.10384]],
  [5652721, [48.4825, -108.76544]],
  [2849355, [54.06667, 9.58333]],
  [2707217, [56.28468, 13.93547]],
  [1796368, [21.78618, 111.21532]],
  [3182477, [44.49668, 10.60083]],
  [3029503, [43.63641, 2.31818]],
  [8859719, [19.26111, -97.21472]],
  [2967835, [49.62083, 3.56119]],
  [2517338, [39.41287, -5.62821]],
  [4305805, [37.01756, -88.53143]],
  [2976839, [46.89381, -1.41786]],
  [3025850, [45.63365, 4.63932]],
  [4676266, [32.99512, -97.5428]],
  [3824907, [18.51679, -96.72085]],
  [3030756, [48.92047, 2.93003]],
  [2918858, [50.99305, 11.87701]],
  [2857464, [54.06667, 9.51667]],
  [2980517, [43.27927, 1.13906]],
  [681030, [45.91375, 25.23129]],
  [6078636, [53.80014, -113.65203]],
  [865729, [46.75432, 33.41665]],
  [8067908, [-6.9742, 111.3225]],
  [2976443, [43.10224, 0.958]],
  [3217504, [45.99833, 13.24583]],
  [2865376, [48.31588, 11.66316]],
  [1263898, [16.86254, 81.92921]],
  [1606489, [14.55083, 100.84436]],
  [3821702, [16.99355, -100.00328]],
  [2991746, [45.70965, -1.02909]],
  [1106763, [-20.20722, 57.43]],
  [3164684, [46.20736, 8.25267]],
  [3121925, [41.17603, -4.9343]],
  [5377199, [33.93113, -117.54866]],
  [5689618, [46.00139, -102.63682]],
  [2878351, [50.55129, 8.35836]],
  [2802816, [51.40504, 4.89226]],
  [2747792, [52.08833, 6.45]],
  [785603, [45.72538, 20.69008]],
  [2911230, [53.61667, 9.36667]],
  [3182140, [44.38031, 6.96885]],
  [3031017, [46.03371, 4.29904]],
  [3372498, [38.65, -28.13333]],
  [8950081, [45.71765, 9.43286]],
  [3694920, [-7.26799, -79.40773]],
  [2798501, [50.41814, 4.62104]],
  [666565, [46.26667, 21.3]],
  [3022138, [49.4265, 1.82662]],
  [2522265, [39.55, -2.08333]],
  [6691035, [53.39948, -6.18886]],
  [4295084, [38.09368, -85.74552]],
  [2716115, [60.8, 15.78333]],
  [6534579, [45.03796, 9.20225]],
  [2027667, [52.53667, 103.88639]],
  [2659406, [47.31334, 7.76836]],
  [8064200, [-7.1824, 108.4317]],
  [779350, [69.47187, 25.51122]],
  [3183495, [41.86624, 14.74733]],
  [8948776, [40.7739, 14.71696]],
  [1153062, [9.58522, 98.59611]],
  [3180838, [42.46592, 11.75294]],
  [4147145, [27.28394, -82.48065]],
  [9036183, [44.9061, 8.76087]],
  [2760855, [48.334, 16.21016]],
  [3629653, [10.05896, -65.03698]],
  [2794031, [50.8752, 5.24005]],
  [1701750, [11.4175, 122.6382]],
  [3101053, [52.14404, 16.76408]],
  [4887492, [41.70142, -87.77922]],
  [2129005, [40.71069, 140.59048]],
  [2798542, [50.32037, 4.91261]],
  [7258576, [41.85038, -74.07379]],
  [2983197, [49.5044, -1.59536]],
  [7333806, [-8.6026, 115.1053]],
  [7932386, [53.60639, 10.11972]],
  [2521555, [39.48511, -6.58401]],
  [675262, [45.2436, 24.35163]],
  [1580262, [17.06836, 107.0052]],
  [3126835, [42.22445, 2.1686]],
  [3698641, [-7.574, -78.07002]],
  [6534720, [44.22933, 8.36505]],
  [2414926, [10.9, -13.03333]],
  [2661586, [46.69896, 7.79428]],
  [8949541, [45.67303, 9.59292]],
  [2713516, [59.46667, 15.36667]],
  [3001698, [46.88701, -1.32179]],
  [673773, [46.65, 23.13333]],
  [3165511, [45.50415, 11.55194]],
  [1810235, [30.03589, 107.11612]],
  [2648873, [53.98353, -2.10459]],
  [2147357, [-37.83634, 144.65952]],
  [3171673, [41.24243, 14.86497]],
  [3119687, [43.22019, -9.0063]],
  [2808315, [48.85, 8.83333]],
  [2637543, [51.87404, -5.19683]],
  [8861430, [21.52333, -98.87389]],
  [457065, [56.8162, 24.61401]],
  [3197378, [46.23887, 14.35561]],
  [8018434, [-7.0763, 108.0948]],
  [3482946, [22.34278, -98.70639]],
  [2335333, [11.85172, 3.65478]],
  [2521147, [36.92493, -2.45941]],
  [2822076, [48.72619, 13.38221]],
  [8349303, [-35.41249, 149.12825]],
  [2078894, [-32.03823, 115.7676]],
  [2646786, [52.82476, -0.01133]],
  [590939, [58.24806, 22.50389]],
  [11919609, [48.1561, 13.56825]],
  [1330335, [31.30568, 72.32594]],
  [3172946, [40.79809, 14.05023]],
  [4256832, [39.07004, -87.2614]],
  [8862956, [17.72222, -98.66806]],
  [5722917, [44.80845, -124.06317]],
  [262941, [39.39764, 21.59911]],
  [2941221, [51.68379, 12.40085]],
  [671027, [46.71843, 22.07731]],
  [3458049, [-23.5475, -51.67083]],
  [2781162, [47.98343, 12.91786]],
  [7302318, [45.6717, 11.80043]],
  [3531227, [20.17222, -89.01654]],
  [6174462, [43.21682, -79.13288]],
  [2926317, [52.79392, 11.38869]],
  [3031032, [45.95369, 1.75574]],
  [2906059, [49.73333, 6.81667]],
  [7458895, [-8.8488, 120.69886]],
  [7260623, [34.68387, -86.64764]],
  [5806298, [46.23958, -119.10057]],
  [1575703, [9.68141, 105.57226]],
  [3855115, [-23.21667, -62.3]],
  [1506394, [55.02472, 85.94361]],
  [5106262, [40.24206, -74.30126]],
  [7766459, [-6.3929, 105.918]],
  [3200069, [46.35917, 16.15444]],
  [3152548, [62.7805, 7.49277]],
  [1810486, [23.28306, 111.94167]],
  [702782, [49.8391, 38.74098]],
  [3615683, [13.62679, -85.8254]],
  [3181211, [46.36686, 10.94037]],
  [2778036, [48.10846, 14.8439]],
  [8862014, [18.46492, -97.06535]],
  [1280485, [29.04701, 93.08795]],
  [4740629, [26.15952, -97.99084]],
  [7071799, [29.97986, 120.57124]],
  [677813, [45.9852, 27.73284]],
  [3937001, [-12.08333, -76.95]],
  [1789935, [25.7704, 115.3299]],
  [3549463, [22.72969, -80.72081]],
  [4575815, [35.01679, -81.80399]],
  [680607, [44.52268, 25.81903]],
  [1172451, [31.558, 74.35071]],
  [3697805, [-9.83333, -77.75]],
  [2037081, [44.32559, 129.23352]],
  [4435161, [33.54012, -89.12423]],
  [703559, [48.43988, 23.25816]],
  [3280502, [43.64306, 16.57028]],
  [563601, [44.3211, 38.7073]],
  [674568, [47.53996, 23.3355]],
  [8384524, [-10.6245, 123.1965]],
  [3675692, [9.24202, -74.75467]],
  [5656170, [45.73248, -107.61203]],
  [6373926, [-7.55889, 109.17778]],
  [3130552, [41.72593, -3.30471]],
  [94298, [34.68963, 44.96057]],
  [2738159, [40.9842, -8.55142]],
  [3109131, [40.51295, -6.02495]],
  [481424, [52.52585, 32.01526]],
  [2660776, [46.67303, 9.68592]],
  [3178785, [43.63739, 11.31495]],
  [2812085, [49.51306, 8.15389]],
  [3166135, [42.30103, 12.56337]],
  [2291136, [8.0402, -2.80003]],
  [4702965, [28.81915, -97.84861]],
  [1634235, [-8.6767, 122.2144]],
  [683399, [47.08116, 24.53691]],
  [2887194, [49.98333, 10.55]],
  [522981, [47.26873, 39.3488]],
  [3527232, [18.21727, -93.03235]],
  [263858, [37.72602, 24.01289]],
  [1092529, [-11.85461, 43.44607]],
  [793093, [43.75306, 20.09556]],
  [707220, [45.73609, 28.80772]],
  [2919073, [50.91302, 12.59651]],
  [4927194, [40.12948, -86.60667]],
  [3061448, [49.20371, 14.51043]],
  [349717, [28.31214, 30.71007]],
  [2831837, [47.79678, 9.09737]],
  [2658592, [46.7891, 8.67325]],
  [6294291, [47.40229, 8.6228]],
  [3437898, [-27.05598, -55.7413]],
  [3822067, [17.58214, -98.67529]],
  [318695, [37.38608, 40.56896]],
  [725578, [42.48333, 26.5]],
  [5115614, [40.62149, -74.00958]],
  [3106130, [40.92728, -5.34838]],
  [3518267, [17.24533, -98.23553]],
  [766810, [50.06871, 22.22912]],
  [2836543, [52.13802, 10.96745]],
  [1106621, [-20.14889, 57.59083]],
  [6534598, [45.69989, 10.76058]],
  [7409616, [-7.699, 114.0296]],
  [662809, [46.01317, 23.74696]],
  [2986111, [48.31848, 4.09447]],
  [8949954, [45.40008, 9.34269]],
  [3076587, [49.44049, 12.92976]],
  [1804106, [25.70441, 116.72162]],
  [6293083, [47.28495, 8.56954]],
  [292878, [25.11641, 56.34141]],
  [4029368, [20.88238, -100.01782]],
  [3107764, [40.85308, -1.09782]],
  [1795331, [30.98516, 121.11446]],
  [1799982, [23.12579, 111.81003]],
  [3181899, [40.09836, 8.65534]],
  [3035801, [43.54203, 1.56589]],
  [2957351, [50.03333, 7.46667]],
  [8950299, [45.64954, 10.01799]],
  [5436363, [39.5347, -107.78312]],
  [1646093, [-6.5995, 105.7152]],
  [1106813, [-20.10556, 57.53528]],
  [6535324, [37.93086, 15.26739]],
  [3056459, [48.57442, 19.15324]],
  [2996951, [47.22799, 1.47402]],
  [5204895, [40.24369, -78.84891]],
  [3089727, [54.27575, 16.67081]],
  [2774550, [48.02541, 14.76468]],
  [7670883, [52.6565, 0.02378]],
  [2937201, [53.5502, 12.06676]],
  [524473, [45.77251, 47.65398]],
  [702730, [50.3219, 31.99673]],
  [8949113, [45.49261, 9.07779]],
  [3037897, [49.07713, 4.17284]],
  [6293099, [47.23943, 8.65741]],
  [2993076, [45.61719, 6.27756]],
  [2340132, [12.64231, 4.35545]],
  [467856, [59.49173, 29.78153]],
  [2644710, [53.8773, -0.45729]],
  [3018659, [47.49436, 7.31372]],
  [2501873, [36.63709, 3.69474]],
  [1586524, [8.94231, 105.01411]],
  [294410, [32.55549, 34.91638]],
  [630245, [53.9994, 29.7141]],
  [1788247, [27.9775, 110.29472]],
  [2862348, [49.19425, 12.26741]],
  [2924595, [54.15, 8.95]],
  [4749117, [38.61317, -78.79891]],
  [3011217, [44.55351, 4.86457]],
  [921897, [-12.18333, 44.48333]],
  [781962, [40.94194, 20.29861]],
  [666048, [44.68333, 23.63333]],
  [3113429, [40.30351, -6.33181]],
  [1521401, [53.63589, 72.34079]],
  [4174201, [27.19755, -80.25283]],
  [1802367, [28.87072, 109.44293]],
  [2521913, [37.0021, -5.02178]],
  [3164027, [42.12703, 13.8167]],
  [4262451, [38.84283, -86.9914]],
  [4904937, [41.63031, -87.85394]],
  [12070753, [50.84213, -114.00493]],
  [3066483, [49.93183, 17.27177]],
  [3525306, [19.45141, -96.93598]],
  [3122718, [42.77641, -2.20638]],
  [3181364, [44.9794, 10.04331]],
  [2726416, [59.39853, 13.61369]],
  [909887, [-15.61667, 30.41667]],
  [7258338, [41.02208, -80.35682]],
  [6534959, [45.82839, 10.04506]],
  [2646827, [52.1239, 0.89397]],
  [3177712, [42.30692, 13.9349]],
  [2814762, [49.61667, 6.8]],
  [5112215, [44.98643, -73.44653]],
  [734559, [40.83112, 24.02483]],
  [2977240, [44.3924, 4.50188]],
  [11549798, [41.41243, 2.15437]],
  [5383526, [34.43333, -117.64672]],
  [3983820, [20.74122, -100.44843]],
  [3532513, [18.74174, -99.80275]],
  [679973, [47.75546, 23.06041]],
  [2189947, [-40.39146, 175.3153]],
  [3110430, [42.33685, -3.79397]],
  [8863036, [24.96056, -100.71556]],
  [2650615, [51.19871, -1.77185]],
  [3177505, [45.18469, 10.10362]],
  [667649, [46.63541, 26.48761]],
  [2637127, [54.58102, -1.80708]],
  [4565712, [18.05246, -66.50656]],
  [6377843, [-6.50287, -78.4801]],
  [491158, [45.25833, 40.67972]],
  [2123621, [53.25851, 140.18269]],
  [2638995, [52.89254, -1.14953]],
  [7873874, [51.90553, 4.48706]],
  [8417474, [-9.8448, 124.5481]],
  [2986965, [49.10288, 2.58549]],
  [331259, [4.05, 38.3]],
  [8348551, [-26.61807, 153.07781]],
  [1695887, [12.5642, 124.9276]],
  [2723742, [59.15, 14.63333]],
  [1214449, [-1.11961, 98.99217]],
  [4996545, [45.28445, -85.07895]],
  [1854018, [34.00139, 131.18361]],
  [6535558, [46.14858, 8.29742]],
  [2969181, [49.83382, 1.71985]],
  [1608251, [17.36083, 103.10533]],
  [8433807, [-8.7827, 122.0353]],
  [3531872, [16.7219, -98.37196]],
  [4754966, [38.47318, -77.99666]],
  [6113355, [44.00012, -77.24946]],
  [2792397, [51.13128, 4.57041]],
  [2319133, [5.51737, 5.75006]],
  [1261145, [28.10296, 77.00144]],
  [677407, [46.05, 27.5]],
  [3011361, [45.01475, -0.64038]],
  [2999248, [44.87896, -0.9909]],
  [1817260, [30.49599, 109.63812]],
  [2970669, [43.04572, 1.62813]],
  [708380, [48.669, 25.5033]],
  [678658, [44.32293, 25.99901]],
  [626610, [54.2438, 27.0758]],
  [8860112, [18.065, -93.94222]],
  [2361373, [12.07305, 1.78838]],
  [12156354, [48.14655, 26.27382]],
  [1811504, [29.58294, 115.56419]],
  [1940872, [31.07222, 113.65812]],
  [692487, [48.15938, 25.72144]],
  [1724461, [14.9229, 120.47982]],
  [1862415, [34.4, 132.45]],
  [3122407, [41.23074, 0.55008]],
  [3110497, [43.33333, -3.1]],
  [1732050, [8.055, 125.13417]],
  [2035322, [47.75, 122.83333]],
  [2647354, [54.78333, -1.41667]],
  [8563527, [43.90845, 81.33299]],
  [8948932, [44.47816, 8.92784]],
  [2638587, [57.69722, -2.0634]],
  [1793358, [25.86603, 119.14927]],
  [3854493, [-37.66776, -63.53021]],
  [250235, [32.14844, 35.70535]],
  [677909, [44.61452, 27.60444]],
  [1562548, [21.30891, 105.60489]],
  [8862095, [20.53694, -103.36389]],
  [2765331, [47.08333, 10.46667]],
  [2942938, [49.92167, 8.07972]],
  [683123, [45.15, 26.83333]],
  [3001336, [45.82164, 6.72865]],
  [3175669, [45.01165, 10.34894]],
  [1908027, [32.05904, 108.39583]],
  [1253545, [19.92672, 74.7275]],
  [3108228, [41.95249, -3.53514]],
  [2646953, [51.44775, 0.55558]],
  [2319258, [13.03054, 5.10433]],
  [12070173, [47.45314, -64.98185]],
  [3454061, [-24.32, -46.99833]],
  [3539560, [22.96139, -82.15111]],
  [262039, [38.95342, 22.96681]],
  [1800475, [28.84622, 103.54575]],
  [675471, [44.56667, 28.71667]],
  [3115213, [40.7375, -1.49875]],
  [2498667, [35.86691, 7.88673]],
  [1710213, [10.78333, 122.5]],
  [1253219, [10.56207, 76.91305]],
  [3008057, [45.31297, 3.4245]],
  [2658305, [46.94168, 7.87996]],
  [7426738, [-8.6542, 122.0433]],
  [3093181, [51.28844, 16.34748]],
  [751931, [41.35121, 41.30456]],
  [6252010, [47.34152, 8.47149]],
  [2872796, [47.89358, 10.67127]],
  [3037818, [45.9726, 4.3303]],
  [4904447, [41.85003, -87.96451]],
  [5987650, [46.0164, -73.4236]],
  [6683363, [23.47302, 116.28075]],
  [2747884, [52.93659, 5.89447]],
  [3169014, [45.80792, 12.99545]],
  [8743021, [12.28355, 108.9047]],
  [1646215, [-6.6075, 105.864]],
  [1684828, [11.48333, 122.81667]],
  [470653, [54.905, 58.35611]],
  [678633, [44.99096, 29.21972]],
  [7874487, [-27.78146, -48.52653]],
  [754329, [50.13334, 20.88615]],
  [8028740, [-7.0168, 108.242]],
  [2022369, [50.2981, 103.3097]],
  [668600, [44.45025, 26.01122]],
  [3181193, [44.24326, 9.83671]],
  [8058305, [-6.6739, 111.091]],
  [4025025, [20.24583, -101.49361]],
  [1262083, [31.04168, 76.72285]],
  [2826986, [53.47313, 8.70907]],
  [2019945, [52.8575, 103.17194]],
  [1260716, [25.77276, 73.32335]],
  [3692736, [-8.95028, -78.62278]],
  [2746431, [50.86833, 5.88889]],
  [2973457, [45.63973, 4.72203]],
  [2952398, [53.16981, 7.75012]],
  [7690197, [28.53008, 114.76113]],
  [2636928, [50.88744, -0.32787]],
  [2516773, [37.36396, -4.84979]],
  [1808320, [30.70693, 111.97266]],
  [2858231, [54.66667, 8.83333]],
  [2789745, [51.3, 4.73333]],
  [1491633, [52.30447, 85.0785]],
  [3172947, [42.97262, 13.58972]],
  [3118602, [42.32032, -2.719]],
  [3802806, [17.86604, -92.48814]],
  [2861358, [50.85, 9.46667]],
  [3074305, [50.6277, 15.13653]],
  [4005242, [20.38259, -101.4114]],
  [6118417, [51.01678, -93.82736]],
  [790674, [42.46045, 21.46986]],
  [2959660, [48.97171, 12.93511]],
  [3172586, [42.15002, 12.50306]],
  [4026719, [19.61412, -100.0308]],
  [738168, [40.24472, 30.02]],
  [2641269, [53.19305, -0.72869]],
  [7413762, [35.52407, 102.96966]],
  [2642608, [53.38722, -0.36202]],
  [3826873, [19.16142, -99.80515]],
  [4012319, [20.4281, -103.36189]],
  [179634, [-3.6835, 39.85687]],
  [3172521, [45.34497, 10.01043]],
  [662501, [47.79953, 25.74939]],
  [6534908, [45.88468, 8.70853]],
  [2910720, [49.49372, 11.47119]],
  [3190536, [46.51028, 15.08056]],
  [3116939, [42.26016, -8.214]],
  [7628452, [6.69281, 159.76283]],
  [8751007, [2.04139, 101.697]],
  [282439, [32.12568, 35.10061]],
  [5790464, [46.86842, -124.11184]],
  [3711618, [8.56667, -82.41407]],
  [1801195, [36.46448, 106.60656]],
  [2776900, [48.7, 16.36667]],
  [9972858, [-33.78763, 150.96897]],
  [3165189, [46.16058, 13.21566]],
  [707756, [46.58442, 30.54823]],
  [2987314, [45.70359, 4.82424]],
  [3035173, [44.43616, 3.20046]],
  [4897016, [42.22697, -87.9798]],
  [72746, [14.27533, 47.58414]],
  [3203639, [45.40139, 15.28833]],
  [665225, [44.29191, 23.50559]],
  [1488950, [52.1449, 93.91887]],
  [1277442, [24.89298, 78.15105]],
  [2976433, [46.80845, -1.42611]],
  [4179094, [34.3687, -84.93411]],
  [5037790, [44.94107, -93.4419]],
  [8949441, [44.55446, 11.35183]],
  [2754864, [53.21333, 5.94137]],
  [1569857, [13.19682, 108.69043]],
  [3118958, [41.2046, 1.27996]],
  [3622561, [9.82005, -84.93513]],
  [3516066, [21.20312, -89.26945]],
  [7302133, [53.5765, -1.37688]],
  [662580, [45.13693, 24.25051]],
  [8675153, [56.20837, 37.54741]],
  [542571, [50.80083, 35.65833]],
  [2335709, [10.38304, 11.09567]],
  [669514, [44.07675, 26.19114]],
  [2743800, [53.16333, 6.86111]],
  [1909653, [34.54222, 107.4511]],
  [6172408, [60.04958, -128.90613]],
  [3452925, [-30.03283, -51.23019]],
  [2635535, [53.22526, -3.4203]],
  [3181769, [41.51462, 12.85383]],
  [2640004, [55.19592, -6.6493]],
  [2977814, [48.32824, -1.42562]],
  [146793, [35.06586, 32.49226]],
  [11746598, [46.62263, 14.31036]],
  [4088628, [30.82074, -88.07056]],
  [2664454, [59.61617, 16.55276]],
  [3078114, [50.07127, 15.07203]],
  [5279422, [43.62748, -89.77096]],
  [7848781, [-7.3389, 106.9756]],
  [7290162, [-8.80047, 115.23341]],
  [8861025, [21.84111, -103.77389]],
  [8219739, [-25.18457, 152.61263]],
  [2980120, [47.84132, 1.11442]],
  [2987389, [41.49439, 9.05633]],
  [4005297, [25.4587, -108.07732]],
  [4501551, [39.95567, -74.0743]],
  [2934032, [51.08333, 10.3]],
  [1277723, [22.48275, 88.18176]],
  [681209, [44.24769, 26.88409]],
  [284362, [32.1426, 35.28774]],
  [3066680, [50.30476, 15.16962]],
  [11592463, [46.99653, 15.2213]],
  [3734953, [-7.76359, -74.92893]],
  [3010222, [47.67098, 1.23891]],
  [2319642, [5.90016, 6.84312]],
  [1792738, [29.04075, 121.33864]],
  [8424061, [-8.5068, 123.5418]],
  [1255301, [23.94667, 76.08825]],
  [4853325, [41.68443, -93.96106]],
  [2884141, [51.35, 10.25]],
  [3181708, [44.6036, 10.63675]],
  [1919741, [23.38866, 115.993]],
  [2799422, [50.55936, 4.85922]],
  [1649169, [1.46667, 102.13333]],
  [2783694, [50.89863, 5.22446]],
  [2898608, [53.18333, 9.23333]],
  [2897844, [52.28333, 8.66667]],
  [2993878, [50.85466, 2.24917]],
  [3024569, [45.41382, 4.79077]],
  [2761261, [47.65, 14.98333]],
  [3123913, [42.32729, -6.09573]],
  [2967410, [50.38717, 3.1149]],
  [2877683, [50.58333, 12.25]],
  [3645586, [10.81231, -69.53734]],
  [666964, [46.45, 24.35]],
  [2337324, [6.49418, 4.87036]],
  [677459, [46.71393, 23.68853]],
  [4568451, [18.35467, -66.00739]],
  [11802110, [46.86763, 30.07243]],
  [2017215, [63.92301, 127.47135]],
  [673088, [47.23333, 23.13333]],
  [2638976, [51.57344, -0.42341]],
  [716864, [46.9, 20.4]],
  [5138071, [42.9559, -77.22081]],
  [3115737, [40.66488, -4.96577]],
  [289739, [26.12933, 51.2009]],
  [3712809, [8.74082, -79.85299]],
  [2807876, [50.57333, 8.01167]],
  [1529195, [44.3023, 86.03694]],
  [2653095, [51.5728, -1.15356]],
  [3168941, [41.90116, 14.34919]],
  [8392817, [-6.9271, 113.5165]],
  [8859294, [20.08361, -97.49361]],
  [2752228, [51.41583, 6.0]],
  [2976697, [46.64107, 4.37107]],
  [4474274, [36.03072, -75.67601]],
  [1805361, [27.27169, 115.11867]],
  [6535140, [46.24505, 10.2467]],
  [4540677, [35.94505, -97.25531]],
  [6374022, [-7.5975, 109.19889]],
  [8022814, [-7.4546, 108.2099]],
  [2759644, [51.19333, 6.04861]],
  [6621522, [52.22291, 5.98145]],
  [1915319, [21.8569, 110.19038]],
  [2036510, [40.70947, 123.00781]],
  [3800361, [16.45722, -98.07694]],
  [1724038, [11.2404, 122.6976]],
  [3186082, [42.01528, 19.48167]],
  [4018831, [28.83669, -105.91423]],
  [768987, [49.451, 20.30179]],
  [2974882, [46.49951, 4.97905]],
  [3170482, [42.5033, 12.88524]],
  [2645161, [51.86674, -0.18394]],
  [2528650, [31.62777, -6.94093]],
  [2935645, [51.41072, 11.74594]],
  [2661357, [47.57282, 8.56281]],
  [3405796, [-5.45, -42.36667]],
  [3369100, [-32.18173, 18.89217]],
  [1789945, [26.19028, 107.5125]],
  [3606320, [14.06667, -87.28333]],
  [2691602, [60.5, 14.96667]],
  [7315173, [34.78188, -114.47775]],
  [2996727, [47.2516, 6.80309]],
  [8948941, [44.5711, 11.30386]],
  [3146125, [63.28555, 10.27806]],
  [665636, [46.91667, 27.68333]],
  [1817584, [30.40522, 111.80041]],
  [523750, [55.72545, 52.41122]],
  [8348427, [-26.42989, 153.09785]],
  [3165275, [45.43399, 8.7364]],
  [3119341, [43.21986, -3.4392]],
  [3079834, [50.61866, 16.10857]],
  [8199379, [31.87111, 34.99081]],
  [945945, [-28.44776, 21.25612]],
  [5514359, [39.51824, -119.98881]],
  [1796793, [32.05083, 121.66417]],
  [2658478, [46.95835, 7.52508]],
  [3179745, [45.01396, 9.12528]],
  [6355084, [43.92312, 8.10482]],
  [5246565, [45.04357, -92.04101]],
  [717530, [48.21102, 21.15052]],
  [6825492, [38.02496, 138.35931]],
  [2940259, [53.35691, 13.24328]],
  [3527806, [20.01801, -98.63623]],
  [2224863, [4.23343, 10.61532]],
  [4050356, [34.22737, -80.68925]],
  [8347959, [-33.77645, 150.98051]],
  [8862221, [17.92056, -99.34889]],
  [2990957, [48.9294, 2.89594]],
  [742273, [40.88236, 27.45919]],
  [3714538, [9.14176, -81.89425]],
  [241395, [-4.66667, 55.5]],
  [4844273, [41.87149, -72.36869]],
  [3699188, [-3.90583, -70.51639]],
  [8860254, [18.16139, -92.79083]],
  [2968195, [47.57458, 0.31322]],
  [4024899, [20.31461, -101.6448]],
  [2850484, [50.66132, 11.56912]],
  [6534499, [45.69598, 9.73495]],
  [9642545, [47.86065, 30.1147]],
  [1253330, [10.37208, 79.85095]],
  [3463698, [-10.5075, -39.01583]],
  [2647143, [50.10319, -5.27045]],
  [3112985, [40.36463, -3.31699]],
  [4338298, [29.72743, -90.59898]],
  [1799384, [29.28917, 121.42472]],
  [3167104, [44.11178, 9.9622]],
  [1687186, [5.40333, 125.46361]],
  [2639343, [50.89264, 0.05472]],
  [3982887, [23.63466, -103.64244]],
  [3172510, [45.19356, 8.52094]],
  [3944138, [-14.02694, -71.45278]],
  [3177568, [45.84444, 13.53908]],
  [2152658, [-37.83961, 144.94228]],
  [3190089, [43.7475, 15.80583]],
  [323777, [36.90812, 30.69556]],
  [2647123, [52.69714, 1.69181]],
  [2468245, [36.86108, 10.33161]],
  [667023, [45.13333, 26.35]],
  [1619198, [13.84341, 100.36251]],
  [8725909, [20.05472, -96.86278]],
  [2840021, [50.54023, 12.91215]],
  [2206372, [-40.75833, 175.15]],
  [711130, [48.00356, 25.28075]],
  [1622728, [-4.0597, 121.60556]],
  [3179410, [42.65076, 12.20305]],
  [2970119, [48.23786, 4.46905]],
  [2809509, [54.62, 13.28914]],
  [2859826, [48.23279, 9.57235]],
  [3104218, [43.29469, -2.25341]],
  [2809372, [47.7479, 7.76753]],
  [2772867, [46.55611, 13.92889]],
  [10860498, [41.83444, 1.83721]],
  [3670812, [4.28075, -74.76469]],
  [3018336, [50.3979, 3.06038]],
  [3066385, [49.87164, 14.89674]],
  [2785527, [50.65819, 4.81755]],
  [673964, [46.18333, 27.91667]],
  [589709, [58.88556, 25.55722]],
  [2991380, [48.41466, 3.22833]],
  [5203221, [40.10176, -76.08523]],
  [2983942, [46.30098, 4.88317]],
  [698510, [47.1724, 31.76498]],
  [4984016, [42.25754, -83.21104]],
  [343137, [7.06205, 38.47635]],
  [3518510, [15.60136, -92.62909]],
  [4911802, [41.63364, -88.68119]],
  [3116345, [41.05955, -3.52993]],
  [360048, [30.29735, 30.97641]],
  [320437, [38.045, 28.83056]],
  [7569909, [-8.8194, 121.0186]],
  [6697276, [48.58229, 14.12777]],
  [2975092, [50.70248, 2.03178]],
  [11549801, [41.42889, 2.15308]],
  [3708288, [9.17797, -79.25616]],
  [2977377, [43.51328, 3.51419]],
  [4351228, [38.34624, -76.41773]],
  [11238229, [55.6591, 37.52291]],
  [2878944, [52.46557, 8.37621]],
  [664891, [44.65, 25.53333]],
  [8950214, [44.82543, 10.99744]],
  [533793, [56.0378, 51.40001]],
  [8435800, [45.34801, 25.16925]],
  [4968499, [44.53286, -67.59833]],
  [8863281, [19.11528, -97.10611]],
  [2876842, [50.31667, 6.6]],
  [3710920, [9.41468, -82.52244]],
  [7781691, [-6.2081, 106.0093]],
  [2876127, [50.31667, 8.05]],
  [3688923, [6.60508, -75.66619]],
  [5768244, [44.06249, -103.14629]],
  [537508, [42.96839, 47.48301]],
  [3204797, [46.37694, 15.8025]],
  [2523578, [39.57622, 16.63447]],
  [1620321, [15.01353, 102.41289]],
  [4003703, [19.45915, -100.49483]],
  [935055, [-26.88333, 31.48333]],
  [678510, [44.27356, 23.82029]],
  [1508150, [51.56889, 85.5625]],
  [11592441, [55.59253, -2.42871]],
  [3122024, [42.42054, -3.48507]],
  [4413872, [38.59255, -90.35734]],
  [4154023, [30.61519, -87.02163]],
  [6144195, [48.39302, -77.23951]],
  [1650232, [-8.19, 114.9675]],
  [2775531, [47.55, 9.75]],
  [4930511, [42.55343, -70.85366]],
  [5274644, [44.52358, -89.57456]],
  [7645381, [5.2842, 153.648]],
  [3182513, [45.18667, 11.88433]],
  [3167752, [41.23537, 14.49832]],
  [8348306, [-37.7198, 144.77732]],
  [3526464, [20.23852, -97.27786]],
  [8859255, [18.935, -98.7525]],
  [3181835, [45.21326, 9.49946]],
  [3801262, [18.20107, -96.48325]],
  [2823956, [54.1, 10.03333]],
  [5113348, [42.71786, -73.83346]],
  [3979638, [19.54808, -102.34747]],
  [734843, [41.30506, 24.89438]],
  [732770, [42.50606, 27.46781]],
  [2779570, [47.15279, 11.82212]],
  [2796903, [50.83283, 5.55428]],
  [2812099, [50.11667, 7.8]],
  [1665443, [23.95671, 120.57608]],
  [3065469, [50.03911, 15.70262]],
  [1253750, [23.80366, 72.39101]],
  [3019080, [50.31743, 3.51945]],
  [2771109, [47.37746, 13.12926]],
  [307187, [37.30361, 30.44417]],
  [3521836, [20.31619, -97.27216]],
  [9973204, [-34.84869, 138.49103]],
  [6294614, [47.38713, 8.66657]],
  [2983568, [44.40874, 2.28971]],
  [320448, [39.08656, 42.27158]],
  [3063762, [50.4406, 13.45738]],
  [2883211, [53.94441, 11.53386]],
  [3110678, [41.57436, -3.20418]],
  [1882918, [28.35058, 120.4084]],
  [4163984, [28.08529, -80.66644]],
  [11919604, [48.00934, 15.58085]],
  [568464, [48.06242, 46.10911]],
  [1685434, [15.45725, 120.39553]],
  [2946923, [52.36667, 8.31667]],
  [1817075, [32.30932, 120.40691]],
  [1785111, [22.89096, 114.0716]],
  [782806, [41.50694, 20.08667]],
  [1552729, [31.28495, 118.06213]],
  [8950355, [38.0042, 13.90223]],
  [783384, [40.62917, 20.25111]],
  [4479759, [35.17959, -80.64729]],
  [3177669, [40.14847, 18.31605]],
  [3036145, [48.93814, 2.49402]],
  [2921437, [54.46667, 9.61667]],
  [4317985, [30.14715, -91.96123]],
  [8521963, [40.18851, 29.10974]],
  [616855, [40.97951, 45.25033]],
  [1152473, [18.29232, 99.49277]],
  [3001402, [48.82206, 1.98677]],
  [5408211, [38.58046, -121.53023]],
  [3111900, [42.94882, -5.19892]],
  [3178672, [42.89059, 11.39196]],
  [1791079, [33.0635, 105.44253]],
  [1504075, [57.7004, 93.2809]],
  [3101128, [50.72858, 17.89819]],
  [2952767, [52.61953, 8.79999]],
  [2782861, [47.51667, 14.13333]],
  [385038, [26.13, 50.555]],
  [3079931, [49.50387, 18.97622]],
  [1624100, [-6.3557, 105.8561]],
  [3099529, [51.72554, 19.71359]],
  [3118512, [42.48318, -1.97184]],
  [3173441, [44.52748, 8.91412]],
  [1787618, [29.48413, 114.36302]],
  [1268677, [26.16779, 82.37028]],
  [550280, [55.89704, 37.42969]],
  [3112154, [42.28337, -8.6096]],
  [1258637, [29.3925, 79.1283]],
  [1920248, [38.18917, 114.27472]],
  [678999, [45.78333, 27.46667]],
  [3167777, [43.81725, 7.7772]],
  [6535326, [37.56565, 15.00319]],
  [3392018, [-3.8325, -45.1525]],
  [2856014, [53.1099, 9.14408]],
  [3163882, [45.57831, 12.2686]],
  [1691911, [16.78416, 121.53504]],
  [7281931, [50.36386, -119.34997]],
  [1725804, [9.3649, 122.804]],
  [294029, [31.82542, 34.68282]],
  [2646590, [51.52358, 0.40491]],
  [3194802, [45.33667, 13.82861]],
  [6534490, [45.60238, 9.45495]],
  [6355013, [28.92313, -13.66579]],
  [8948911, [45.87532, 9.29559]],
  [3175171, [45.49703, 8.42224]],
  [2648872, [56.12474, -4.08319]],
  [678343, [46.10431, 23.29511]],
  [2343267, [4.65643, 8.25206]],
  [254442, [39.05261, 22.95271]],
  [6534577, [46.14421, 11.21958]],
  [1695598, [15.6885, 120.6201]],
  [9958108, [-27.72414, 153.09002]],
  [682253, [47.59881, 23.73945]],
  [2764230, [48.12267, 15.81379]],
  [3939340, [-14.33987, -72.89456]],
  [4535904, [34.62286, -97.39641]],
  [2939942, [50.85098, 8.78092]],
  [2744084, [51.53956, 3.6581]],
  [3209142, [51.02896, 14.72389]],
  [2348419, [12.77256, 9.01525]],
  [2633809, [50.85364, -2.8892]],
  [8219759, [-35.07254, 147.35748]],
  [8362953, [-4.79945, 104.48323]],
  [2776943, [47.56231, 13.64912]],
  [1879487, [38.39917, 125.61556]],
  [736051, [41.14653, 23.90667]],
  [3936650, [-14.03583, -75.70028]],
  [2776783, [47.18333, 15.91667]],
  [2774534, [47.48306, 9.76667]],
  [3404020, [-6.89028, -38.55528]],
  [4062861, [34.44425, -85.71969]],
  [2656799, [56.29158, -3.23428]],
  [661576, [63.0, 23.81667]],
  [319323, [38.15162, 41.20256]],
  [2991929, [45.61166, 6.84637]],
  [3115307, [40.07395, -3.85118]],
  [1700379, [15.5166, 120.7098]],
  [6825084, [24.50132, 103.41338]],
  [675556, [44.11667, 27.8]],
  [2510883, [38.38983, -2.5819]],
  [2971043, [43.88222, 0.38108]],
  [668104, [46.45, 26.73333]],
  [8860789, [18.41406, -97.02039]],
  [8860344, [19.06639, -96.82361]],
  [2977308, [47.88177, 1.8695]],
  [8862684, [16.81278, -92.72611]],
  [680374, [46.18219, 25.43276]],
  [3315315, [53.39807, -6.26693]],
  [3203631, [45.05028, 18.75556]],
  [3518456, [19.42377, -96.96442]],
  [3981039, [21.39329, -104.7918]],
  [580881, [56.38333, 38.48333]],
  [1804819, [28.88122, 116.43113]],
  [1154094, [16.67441, 99.26903]],
  [678590, [44.03973, 28.64685]],
  [4915725, [42.25891, -88.13925]],
  [8860902, [17.29639, -93.31083]],
  [2779631, [46.97389, 15.50111]],
  [3049719, [46.4725, 19.97972]],
  [2482159, [36.54722, 4.68611]],
  [7406769, [-8.2675, 114.9506]],
  [8949410, [45.65414, 7.68414]],
  [2772672, [46.83528, 13.43028]],
  [3666575, [10.27169, -75.44222]],
  [3827024, [19.80028, -99.94778]],
  [682478, [44.73333, 28.71667]],
  [3187920, [46.0, 15.85]],
  [522942, [56.092, 54.2661]],
  [3530833, [18.2014, -98.26426]],
  [2126025, [62.34198, 150.75745]],
  [3015711, [45.34428, 5.97896]],
  [2434584, [10.48473, 16.71076]],
  [2745872, [52.18, 4.43194]],
  [3018676, [47.83475, 2.11113]],
  [694809, [48.08834, 29.44918]],
  [3813991, [20.46469, -99.20132]],
  [750296, [40.03686, 30.62675]],
  [73365, [15.64453, 44.47337]],
  [8347875, [-35.1599, 147.35875]],
  [1506073, [54.28333, 85.93333]],
  [734988, [40.38119, 23.92374]],
  [8348237, [-19.28741, 146.80043]],
  [3864328, [-27.82913, -64.06098]],
  [7931226, [29.29389, 90.98708]],
  [5012895, [43.71002, -82.93161]],
  [3152862, [59.10351, 9.56195]],
  [682028, [45.9, 24.6]],
  [6619766, [51.27488, -2.76256]],
  [3026210, [47.93333, 2.93333]],
  [2971616, [49.47998, 2.04485]],
  [1497393, [58.07756, 60.7202]],
  [4914704, [42.39863, -88.05258]],
  [3608860, [15.06667, -88.21667]],
  [2769491, [46.93639, 15.86472]],
  [4565018, [18.34023, -66.42767]],
  [2768898, [48.11511, 14.22644]],
  [2639397, [53.81361, -2.53244]],
  [2844265, [49.24762, 11.09111]],
  [4551448, [35.08729, -97.33503]],
  [4553433, [36.15398, -95.99277]],
  [2621471, [56.12196, 12.31056]],
  [668320, [47.63333, 24.43333]],
  [2647785, [54.53478, -1.05606]],
  [3172961, [43.31619, 13.63762]],
  [461650, [57.07045, 24.33713]],
  [3078132, [50.13302, 15.77323]],
  [2661637, [45.84638, 9.00724]],
  [3517973, [16.95501, -95.84563]],
  [4050006, [39.09284, -84.61272]],
  [1645917, [-8.1004, 113.8928]],
  [5034299, [44.38941, -93.73023]],
  [3402360, [-5.63444, -35.42556]],
  [700796, [48.99494, 29.80992]],
  [2806310, [52.19098, 10.93878]],
  [4032543, [-21.17735, -175.1172]],
  [2161251, [-33.84867, 151.2162]],
  [3672813, [4.30722, -74.30056]],
  [3078683, [49.55763, 13.95063]],
  [3522450, [20.42614, -99.09551]],
  [3011330, [48.94612, 0.66536]],
  [3046768, [46.6221, 18.85569]],
  [3518726, [19.80561, -98.85209]],
  [3215569, [44.70667, 12.23611]],
  [4855891, [41.59082, -94.87554]],
  [3460774, [-21.66917, -42.07611]],
  [5243081, [44.49144, -73.18568]],
  [710565, [50.12994, 25.25576]],
  [3037956, [44.92737, -0.49141]],
  [685273, [44.8483, 26.04031]],
  [6050610, [45.56995, -73.692]],
  [3131144, [40.54908, -0.80758]],
  [2773698, [48.35, 16.33333]],
  [707898, [47.66685, 36.25576]],
  [2989785, [45.26302, 1.40826]],
  [2304931, [6.04024, -0.80876]],
  [747489, [40.66889, 36.5675]],
  [2852591, [50.9418, 14.12687]],
  [1812463, [21.03658, 110.49542]],
  [5605321, [43.67241, -111.91497]],
  [2836438, [54.18333, 10.75]],
  [2967506, [48.88628, 7.78828]],
  [464018, [52.7264, 32.2334]],
  [293262, [31.7974, 34.82083]],
  [2843810, [49.71667, 7.26667]],
  [5874015, [66.25621, -166.07229]],
  [8419180, [-9.7259, 124.7223]],
  [4574835, [34.86151, -82.43151]],
  [8949604, [43.8216, 13.05263]],
  [2842656, [50.93284, 12.42096]],
  [3440577, [-32.92254, -54.94447]],
  [1679423, [9.1025, 123.1996]],
  [684870, [47.05, 25.51667]],
  [2643895, [52.78086, -3.08875]],
  [1919795, [23.2373, 116.31728]],
  [1280432, [36.37056, 96.40821]],
  [680932, [45.06762, 24.31161]],
  [2943488, [49.43174, 7.35998]],
  [3021336, [43.93127, 4.29673]],
  [514679, [60.20503, 30.49667]],
  [1784414, [27.41139, 109.93194]],
  [2530188, [35.1299, -2.9279]],
  [3522437, [18.50258, -88.51836]],
  [3691754, [-6.59341, -79.93464]],
  [4211428, [33.84427, -84.12936]],
  [3459901, [-20.02069, -40.68145]],
  [2988913, [49.87373, 0.9597]],
  [3612138, [14.13333, -87.11667]],
  [5595881, [43.61766, -116.93376]],
  [2639482, [51.71193, -3.69745]],
  [1726064, [9.7808, 124.1484]],
  [2799007, [51.15662, 4.44504]],
  [8861060, [20.14472, -98.19167]],
  [125205, [39.28992, 44.46035]],
  [6535713, [45.14667, 9.85457]],
  [3530434, [25.47929, -97.98443]],
  [1819050, [22.38333, 114.26667]],
  [3172406, [45.81799, 13.12751]],
  [7083460, [-8.1798, 113.7766]],
  [3685184, [4.42692, -74.97548]],
  [665521, [46.86667, 26.13333]],
  [495515, [55.56711, 39.42129]],
  [2503468, [36.46298, 2.81464]],
  [3666436, [6.89944, -76.17417]],
  [1728196, [15.1624, 120.5954]],
  [759814, [50.5292, 21.20636]],
  [8444338, [-8.8173, 119.9648]],
  [8861258, [16.94972, -93.45778]],
  [2775185, [46.75694, 13.02528]],
  [1263331, [11.93018, 75.57152]],
  [2653342, [51.21073, 0.79466]],
  [2263418, [32.72246, -16.81991]],
  [2665691, [60.65, 17.03333]],
  [4167127, [26.29286, -81.58842]],
  [2890420, [52.94236, 9.31811]],
  [3068797, [49.5351, 18.39164]],
  [4161733, [28.85165, -82.4876]],
  [1501795, [57.34306, 83.92611]],
  [3026532, [45.34398, 5.52799]],
  [2525593, [37.3785, 14.2027]],
  [3663475, [-1.82403, -65.35883]],
  [2523020, [38.62825, 15.90572]],
  [3695386, [-6.42444, -79.24167]],
  [2820491, [51.05, 11.13333]],
  [1915121, [20.52686, 109.89556]],
  [2845268, [50.71341, 9.2201]],
  [8348350, [-38.13427, 144.32406]],
  [2789409, [51.04691, 3.9466]],
  [8068196, [-6.7303, 111.466]],
  [3824639, [20.18776, -97.26254]],
  [3454358, [-19.61806, -44.04306]],
  [1809939, [19.81766, 109.47686]],
  [2809303, [50.33333, 6.88333]],
  [2885723, [50.64979, 11.48809]],
  [352679, [30.36056, 31.3776]],
  [2802865, [51.02887, 4.98067]],
  [3175715, [44.98188, 11.80178]],
  [166742, [35.2479, 36.5725]],
  [3465767, [-12.50583, -38.99861]],
  [2657969, [47.35751, 8.59105]],
  [3112338, [42.08364, -7.83503]],
  [8348975, [-19.20097, 146.58875]],
  [2515108, [39.77703, -4.06448]],
  [5241337, [42.94647, -73.21066]],
  [3138304, [59.78074, 10.49701]],
  [2802731, [50.92115, 3.92993]],
  [8950341, [41.62218, 12.6071]],
  [1803782, [30.3, 108.85]],
  [2648999, [52.93818, -2.0721]],
  [5466118, [35.57365, -105.6639]],
  [551047, [59.9456, 56.2959]],
  [3470825, [-22.07444, -48.74028]],
  [4548780, [35.12288, -95.37109]],
  [5203263, [40.21924, -79.60948]],
  [682287, [45.96667, 22.96667]],
  [6290376, [47.28277, 8.45409]],
  [1277949, [14.7451, 79.06288]],
  [566243, [43.15806, 44.15694]],
  [7408299, [33.13931, 106.65909]],
  [3174520, [43.27259, 11.74599]],
  [674544, [45.91667, 23.9]],
  [663953, [46.00357, 20.39865]],
  [3003668, [45.46387, 1.72873]],
  [2769300, [46.97917, 14.98417]],
  [3006219, [46.13312, 6.43109]],
  [1794747, [31.51663, 121.02524]],
  [2207258, [-27.48099, 153.04401]],
  [8054161, [-7.0366, 108.4159]],
  [1785706, [30.65331, 118.22409]],
  [3674597, [9.24194, -74.42667]],
  [3647651, [10.39907, -71.45206]],
  [2960510, [49.82778, 5.96722]],
  [2996126, [46.36125, 4.9058]],
  [8858611, [18.99472, -97.91167]],
  [8349386, [-16.92598, 145.76643]],
  [624758, [53.6104, 27.0733]],
  [1921802, [25.73305, 119.35866]],
  [8349004, [-33.90073, 150.95078]],
  [8986880, [46.50247, 15.62126]],
  [2516483, [39.32538, -5.91833]],
  [330764, [8.3, 35.58333]],
  [2763797, [47.94518, 14.3587]],
  [3764110, [8.93647, -78.90917]],
  [675401, [44.17819, 22.95649]],
  [3447597, [-20.93194, -54.96139]],
  [8174678, [-8.3536, 112.8452]],
  [2524370, [38.70357, 16.12117]],
  [3166976, [40.21525, 8.58692]],
  [681468, [46.5222, 26.1197]],
  [3032898, [48.07556, -4.51189]],
  [1785587, [24.16407, 113.66502]],
  [3114985, [42.2183, 2.90705]],
  [735960, [40.18745, 22.48171]],
  [1518203, [45.05053, 64.98395]],
  [2759431, [52.84218, 5.79597]],
  [3116203, [41.98611, -5.07208]],
  [3174425, [46.74623, 11.76098]],
  [3522617, [26.56219, -99.22863]],
  [3519953, [19.58104, -99.8686]],
  [8333983, [22.62447, 114.03763]],
  [1169663, [30.85944, 69.82208]],
  [710552, [47.61906, 34.53778]],
  [2644427, [54.04639, -1.2492]],
  [672540, [45.1, 26.48333]],
  [668362, [44.50185, 25.8882]],
  [3049751, [46.28009, 19.48954]],
  [1609879, [13.73086, 100.50903]],
  [3172115, [45.6455, 10.38309]],
  [2755003, [52.38084, 4.63683]],
  [2661086, [46.92525, 6.6327]],
  [2984284, [48.84721, 3.23232]],
  [515161, [58.2751, 34.11731]],
  [671483, [46.2493, 22.09438]],
  [775905, [49.96364, 20.12129]],
  [3200117, [46.31167, 16.16806]],
  [636947, [63.08333, 27.66667]],
  [3169742, [40.85106, 17.1219]],
  [4528259, [39.21311, -84.59939]],
  [1076833, [-22.9, 47.36667]],
  [8528799, [29.30898, 107.02885]],
  [8075892, [-6.825, 111.7907]],
  [2510815, [37.39176, -2.38828]],
  [8402427, [-9.9544, 124.1255]],
  [2517202, [37.47845, -3.32119]],
  [4152639, [26.53813, -81.89925]],
  [3028354, [43.66456, -0.84381]],
  [553427, [47.66853, 40.2051]],
  [2784242, [51.10597, 4.45482]],
  [703620, [45.06893, 33.93912]],
  [949282, [-28.41098, 26.70107]],
  [8058317, [-6.6793, 111.0853]],
  [574711, [53.2584, 49.3025]],
  [678290, [45.13333, 25.41667]],
  [491895, [55.86667, 37.58333]],
  [3838158, [-30.63353, -67.46821]],
  [3128477, [40.20981, -3.92331]],
  [3016321, [48.93333, 2.3]],
  [3167562, [41.02611, 14.46515]],
  [2748392, [52.25917, 5.60694]],
  [7581718, [-8.3534, 114.6267]],
  [2014078, [54.05303, 122.88561]],
  [2649516, [54.68333, -1.55]],
  [2172621, [-42.66478, 147.42319]],
  [3457854, [-13.44111, -40.43083]],
  [3187275, [45.07583, 14.6725]],
  [6111882, [44.01682, -78.39953]],
  [2932882, [48.65882, 8.94124]],
  [549266, [51.1183, 40.2904]],
  [2649205, [56.34596, -3.47841]],
  [7870927, [44.24832, -76.95069]],
  [3986194, [21.09345, -100.94789]],
  [8949695, [42.60668, 14.05761]],
  [1941094, [29.74001, 110.50272]],
  [3728964, [18.73582, -72.41929]],
  [679772, [44.36416, 22.72098]],
  [3022207, [46.22507, 4.87913]],
  [2799343, [50.23437, 5.62287]],
  [668995, [46.78333, 24.71667]],
  [2713129, [62.91275, 15.2319]],
  [6535479, [44.78624, 8.06424]],
  [1794592, [29.40031, 105.83781]],
  [3035418, [43.06497, 0.14876]],
  [1808433, [35.57757, 111.22371]],
  [3199194, [46.21139, 15.69083]],
  [5186172, [40.97561, -78.52502]],
  [2523266, [38.26314, 15.85669]],
  [2867616, [49.00083, 9.20778]],
  [1855207, [34.71562, 135.33199]],
  [2515003, [38.45, -6.05]],
  [3175670, [45.58358, 10.15717]],
  [677745, [46.84043, 23.8483]],
  [7779893, [-6.2607, 106.0028]],
  [3123181, [41.01442, -6.09268]],
  [2747713, [51.74583, 5.63194]],
  [5862837, [60.9425, -149.16639]],
  [679058, [47.14585, 27.26735]],
  [2473572, [35.50472, 11.06222]],
  [684783, [45.71667, 22.05]],
  [311104, [37.025, 36.63056]],
  [3028509, [45.12977, -0.62046]],
  [5115960, [40.71399, -73.55902]],
  [1261045, [15.50357, 80.04454]],
  [3053623, [46.42178, 18.23953]],
  [2966356, [51.68025, -9.45254]],
  [6143367, [49.47512, -123.75903]],
  [4016229, [20.87112, -100.16976]],
  [3931427, [-17.15778, -70.35222]],
  [2816534, [52.13865, 11.09877]],
  [553092, [43.22824, 44.63322]],
  [2988326, [43.8487, 2.43167]],
  [2925226, [53.87584, 10.19693]],
  [8074677, [-6.7689, 111.6431]],
  [766795, [49.86538, 20.29149]],
  [593303, [56.19162, 22.69528]],
  [1135980, [32.68567, 68.23801]],
  [1814033, [33.50364, 113.90295]],
  [3181461, [42.30368, 14.18377]],
  [3195839, [45.13443, 15.85217]],
  [671721, [45.36826, 27.02301]],
  [3451709, [-22.22139, -51.30278]],
  [1704793, [8.89389, 125.03083]],
  [2640781, [52.60946, 0.23333]],
  [1486321, [66.8654, 70.83784]],
  [7510984, [18.02106, 103.07664]],
  [2518607, [37.94085, -4.49696]],
  [729125, [42.16955, 27.84541]],
  [7086414, [-8.6966, 116.5697]],
  [3815662, [17.12041, -92.03203]],
  [3121519, [41.30605, 2.00123]],
  [1730749, [14.9285, 121.03]],
  [8860408, [18.43454, -97.04311]],
  [3099378, [50.08826, 19.01896]],
  [3109522, [42.48219, -2.88923]],
  [3195460, [46.39557, 15.92831]],
  [2819738, [49.21111, 9.20194]],
  [4013556, [22.0545, -105.1156]],
  [4014401, [27.11645, -109.39909]],
  [4032420, [-18.6506, -173.98404]],
  [3095967, [51.03821, 19.62313]],
  [2422488, [9.535, -13.68778]],
  [2758460, [51.59083, 5.32917]],
  [1799086, [39.80472, 117.16611]],
  [675547, [44.85, 24.23333]],
  [8858417, [19.31167, -99.54111]],
  [1576233, [10.36161, 105.51924]],
  [686167, [46.47641, 22.75741]],
  [11919757, [43.28606, 5.36]],
  [4338122, [31.65295, -93.20211]],
  [3194558, [46.28778, 16.28333]],
  [5409777, [39.52433, -122.19359]],
  [6293330, [47.31783, 8.58303]],
  [1832830, [37.48972, 127.49056]],
  [2614135, [55.42648, 10.44265]],
  [3105511, [41.86361, -4.86063]],
  [297517, [38.89748, 41.30613]],
  [2412217, [13.53333, -15.33333]],
  [8074569, [-6.7357, 111.6303]],
  [2655673, [52.10658, 0.90916]],
  [1254727, [16.49379, 75.04667]],
  [3193676, [46.39508, 16.59514]],
  [3677767, [6.13162, -72.33841]],
  [2947463, [49.06667, 7.86667]],
  [2791901, [50.4882, 3.96564]],
  [1796367, [39.1979, 112.48882]],
  [2789671, [50.67998, 4.79785]],
  [700607, [49.52143, 22.9367]],
  [2911556, [47.95197, 12.27525]],
  [4895016, [42.0978, -88.53036]],
  [3870306, [-33.66386, -70.92734]],
  [681971, [45.50806, 21.12861]],
  [5427698, [37.27501, -105.9603]],
  [2469274, [36.81006, 10.09557]],
  [1693652, [13.83479, 121.32118]],
  [3034509, [49.22996, -0.18675]],
  [763545, [50.30124, 20.80759]],
  [678348, [46.11667, 21.15]],
  [1730259, [9.17008, 118.1872]],
  [586631, [40.57176, 49.70509]],
  [7335632, [-8.8279, 116.4079]],
  [2744360, [53.1125, 6.77917]],
  [2620431, [55.72366, 12.43998]],
  [8859903, [25.83222, -100.38417]],
  [7757658, [30.65739, 115.01764]],
  [4722062, [33.35818, -94.25436]],
  [7315395, [40.74144, -111.48775]],
  [1156739, [13.20468, 99.97959]],
  [3972459, [22.73453, -102.20469]],
  [169897, [33.55869, 36.36515]],
  [1263324, [11.32147, 79.20245]],
  [3692298, [-4.51146, -73.90424]],
  [759426, [53.55244, 23.44954]],
  [3866237, [-32.19296, -64.25559]],
  [3455775, [-23.5325, -46.79167]],
  [314578, [36.9625, 31.73306]],
  [1572368, [20.28296, 106.44347]],
  [8861808, [19.76361, -97.5375]],
  [1838722, [35.72806, 126.73194]],
  [1262740, [21.3403, 78.01258]],
  [3101592, [50.78076, 17.81227]],
  [3127662, [42.49702, -6.15734]],
  [2986175, [50.21434, 1.71055]],
  [1812101, [37.46271, 118.49165]],
  [668879, [45.51322, 27.44843]],
  [8348864, [-27.52784, 152.9619]],
  [2826294, [50.28333, 10.23333]],
  [6534765, [45.59186, 7.21]],
  [681313, [45.24495, 21.83467]],
  [2771472, [47.53333, 15.51667]],
  [8860544, [19.67944, -100.14889]],
  [1626185, [-8.49317, 117.42024]],
  [3523656, [21.51898, -97.64732]],
  [3018794, [49.75787, 0.37457]],
  [671603, [45.53333, 22.36667]],
  [3183516, [42.74259, 11.86827]],
  [8950394, [45.6032, 11.83563]],
  [3112014, [40.48976, -3.01367]],
  [2791053, [50.69379, 3.43052]],
  [2335648, [11.64589, 7.69068]],
  [703713, [51.37345, 31.64661]],
  [1788249, [35.23111, 113.43806]],
  [672911, [47.68333, 25.25]],
  [4244462, [37.15117, -88.732]],
  [3022003, [44.98807, 3.88416]],
  [2960806, [49.73114, 5.88241]],
  [5741683, [45.3554, -121.98064]],
  [3016627, [43.97861, 1.06791]],
  [79455, [16.16406, 44.77692]],
  [8861902, [20.18583, -100.11944]],
  [1528717, [41.0376, 72.74586]],
  [2791534, [50.96589, 5.69144]],
  [1789504, [35.73556, 107.63528]],
  [3131683, [58.63759, 6.09133]],
  [2915223, [53.84632, 10.24307]],
  [3127889, [42.64725, -8.88459]],
  [256342, [37.98642, 23.79347]],
  [1797264, [28.95944, 118.86861]],
  [676167, [44.53333, 24.78333]],
  [6693668, [51.30143, 0.44855]],
  [2172132, [-37.58489, 141.40297]],
  [3014166, [47.92992, 7.29492]],
  [8860840, [20.23888, -98.02676]],
  [2964532, [53.29067, -6.25714]],
  [489226, [59.98834, 30.24296]],
  [4678005, [33.06567, -96.22776]],
  [4029369, [20.86689, -100.04955]],
  [1799880, [32.59484, 120.27691]],
  [3183015, [42.06406, 13.41018]],
  [2885910, [51.26451, 13.9054]],
  [3527760, [19.46286, -98.42434]],
  [3004114, [46.88523, -1.59876]],
  [3173533, [44.91831, 12.23121]],
  [3517231, [19.24811, -98.40486]],
  [514734, [51.20487, 58.56685]],
  [5364499, [33.66808, -117.32726]],
  [2795067, [50.96048, 3.9501]],
  [1808977, [30.65, 113.76667]],
  [3833112, [-35.4323, -60.17271]],
  [3075433, [49.84012, 13.35558]],
  [518907, [45.77373, 38.67227]],
  [6139416, [50.6998, -119.30237]],
  [702049, [46.157, 29.0848]],
  [2656447, [56.37694, -2.9294]],
  [2799642, [50.79731, 4.02512]],
  [2525499, [38.15623, 14.82799]],
  [504327, [43.41338, 43.79066]],
  [8860234, [20.65194, -100.41667]],
  [1800411, [39.10499, 113.01797]],
  [2643461, [51.07781, -0.51872]],
  [4916118, [41.89381, -87.67493]],
  [2824687, [48.87855, 12.35129]],
  [6424298, [42.34541, 1.82631]],
  [2389853, [4.36122, 18.55496]],
  [8384351, [-7.0484, 113.9421]],
  [7753827, [-6.9569, 106.428]],
  [2766564, [47.27396, 14.86085]],
  [2986389, [46.60817, -0.23354]],
  [2751429, [53.33167, 6.74722]],
  [3827596, [19.65985, -99.12493]],
  [3108104, [40.24652, -3.31461]],
  [606834, [65.67624, 21.00162]],
  [2814294, [48.49549, 11.93641]],
  [2523204, [38.04796, 15.46769]],
  [3026610, [46.16202, 4.17228]],
  [1609722, [6.78758, 100.07778]],
  [2718130, [58.71667, 12.35]],
  [6285792, [6.48088, 80.12708]],
  [1697764, [7.5, 125.75]],
  [4024359, [20.57843, -101.52843]],
  [8383983, [-10.8026, 123.135]],
  [2153930, [-19.20104, 146.77341]],
  [6534978, [45.14817, 9.48156]],
  [3391889, [-7.47056, -34.80861]],
  [3981345, [25.60084, -109.0523]],
  [3179263, [45.93333, 12.5]],
  [708052, [49.97186, 27.21687]],
  [2742904, [40.27161, -8.5209]],
  [3120636, [40.95, 0.31667]],
  [2635801, [51.32444, -2.47917]],
  [2855467, [48.93383, 10.97431]],
  [109481, [16.57946, 42.9124]],
  [2935677, [50.27031, 7.78982]],
  [5768099, [43.90971, -100.05873]],
  [1073665, [-18.85, 47.71667]],
  [2935365, [53.70792, 11.2251]],
  [8405267, [30.3935, 107.73633]],
  [7284840, [47.43674, 19.10093]],
  [114105, [36.40352, 58.03894]],
  [2563081, [36.02639, 14.285]],
  [2177482, [-34.30516, 150.93335]],
  [2980220, [44.04527, 5.77994]],
  [2616599, [54.83038, 11.13567]],
  [2773863, [47.33306, 9.6]],
  [702563, [48.40507, 39.22675]],
  [3114909, [42.81637, -1.74786]],
  [2321926, [8.11733, 12.04611]],
  [2570857, [7.55091, -11.6075]],
  [5011985, [42.09338, -84.98581]],
  [1711084, [8.2289, 124.24344]],
  [6690989, [51.52718, -0.06109]],
  [7274677, [53.60697, 9.90538]],
  [2851493, [50.68333, 11.7]],
  [2810188, [53.25682, 7.92737]],
  [3091135, [49.64657, 19.87916]],
  [1636481, [-6.8593, 111.8028]],
  [3027242, [48.39794, 2.79785]],
  [3182348, [44.6482, 11.538]],
  [2509937, [39.56667, -2.01667]],
  [8605040, [34.5317, -93.06377]],
  [5176490, [41.02644, -81.9332]],
  [568797, [57.73639, 59.87194]],
  [2987423, [50.51691, 3.01584]],
  [3111459, [42.65193, -7.95519]],
  [1913877, [19.91391, 110.35576]],
  [677363, [47.13645, 24.57352]],
  [3516070, [20.60303, -88.2649]],
  [2871437, [51.04315, 12.29935]],
  [2661205, [46.47455, 7.13155]],
  [2023943, [51.19861, 136.66216]],
  [733474, [41.61667, 25.01667]],
  [3037521, [50.53192, 2.61785]],
  [6325572, [46.69823, -71.58275]],
  [1910198, [33.96654, 113.16555]],
  [3519259, [16.10079, -96.22181]],
  [2647416, [55.16667, -1.85]],
  [151363, [-1.14389, 31.39472]],
  [8858306, [19.24861, -98.43833]],
  [1700665, [15.5363, 119.9502]],
  [2798973, [51.1326, 3.02562]],
  [3121145, [41.60797, 2.28773]],
  [2659321, [47.51407, 7.55786]],
  [3181250, [40.28086, 9.62971]],
  [3169093, [45.19912, 10.62527]],
  [2635557, [52.90212, -3.92289]],
  [2920433, [48.10755, 11.2936]],
  [2756239, [51.39656, 5.48624]],
  [680529, [47.6257, 26.77827]],
  [3031097, [47.77481, 7.05872]],
  [3186954, [46.08083, 15.91111]],
  [3802392, [17.98519, -93.13806]],
  [3067735, [48.93443, 16.62487]],
  [3823417, [19.47717, -96.87842]],
  [2171722, [-20.07671, 146.26353]],
  [2208333, [-37.13832, 175.54011]],
  [1816110, [30.83414, 114.59293]],
  [2977892, [49.87857, 1.22486]],
  [8860466, [18.8175, -96.98278]],
  [3065187, [49.95738, 18.03741]],
  [3026271, [46.58041, -1.23643]],
  [2802391, [50.94911, 4.36908]],
  [2895875, [49.86667, 7.63333]],
  [7267949, [14.96823, 145.61998]],
  [2508184, [34.34381, 3.22475]],
  [8858503, [26.2225, -109.06167]],
  [2807526, [49.76667, 7.16667]],
  [2779598, [48.03046, 14.1891]],
  [527388, [43.25147, 45.90716]],
  [8859042, [19.37038, -99.35162]],
  [1910179, [33.84529, 113.68135]],
  [9973220, [-34.84975, 138.47949]],
  [524640, [53.27657, 36.57334]],
  [4397065, [37.30589, -89.97038]],
  [1708942, [14.10722, 120.82917]],
  [3013641, [44.73599, -0.37202]],
  [788906, [41.09562, 21.32454]],
  [7279595, [22.60197, 75.69649]],
  [1708485, [6.46001, 124.08767]],
  [3802056, [18.33849, -93.40283]],
  [693001, [47.98159, 23.91041]],
  [7315290, [38.73565, -119.50079]],
  [3817596, [19.1652, -99.99895]],
  [686487, [44.68333, 26.7]],
  [1220862, [37.76627, 68.80126]],
  [3980714, [21.94742, -105.18005]],
  [295492, [31.55764, 34.64664]],
  [2647992, [52.19346, 0.26698]],
  [672547, [47.3998, 22.99918]],
  [8860030, [23.85167, -104.17806]],
  [5205849, [40.13038, -75.51491]],
  [8442322, [49.66759, 124.55274]],
  [4547675, [36.66784, -96.33723]],
  [2979010, [49.50503, 2.43285]],
  [11906873, [35.3627, 126.4462]],
  [3024745, [43.86244, 3.90522]],
  [2653086, [53.65, -2.61667]],
  [2653192, [50.83673, -0.78003]],
  [1925046, [28.67109, 116.07842]],
  [2980876, [49.45924, 1.36355]],
  [2866070, [48.73218, 11.18709]],
  [4023209, [21.02297, -101.45775]],
  [7783006, [-6.511, 106.0243]],
  [1011031, [-26.1466, 28.68322]],
  [112656, [36.81626, 50.87376]],
  [1802249, [22.35268, 106.84526]],
  [4032384, [-21.33333, -174.95]],
  [2033135, [43.50639, 123.50639]],
  [664605, [47.9245, 23.37648]],
  [5327319, [33.96529, -118.15146]],
  [1784461, [29.68033, 109.27062]],
  [4318541, [30.08742, -91.9054]],
  [704202, [48.13954, 38.93715]],
  [3014114, [43.5717, -0.92975]],
  [8348375, [-32.00314, 115.94621]],
  [3186686, [46.16667, 14.41667]],
  [6823991, [24.00174, 102.40046]],
  [3090764, [51.80333, 15.71702]],
  [2996122, [48.5886, 6.6008]],
  [1800334, [29.57334, 106.83928]],
  [747340, [41.0435, 28.87619]],
  [8377334, [-10.6212, 121.5749]],
  [2987863, [48.81411, -3.4437]],
  [2744891, [53.14574, 5.74327]],
  [8993916, [11.77521, 108.7848]],
  [4017437, [28.43806, -105.61167]],
  [323218, [38.03833, 32.60083]],
  [3171367, [45.06665, 7.83943]],
  [6535570, [46.20721, 11.26488]],
  [3202630, [42.62889, 18.17111]],
  [8480058, [39.68843, -84.69347]],
  [3019871, [42.44157, 2.03307]],
  [3821734, [16.77646, -99.63037]],
  [3010410, [48.70861, -0.58318]],
  [2513140, [37.41802, -6.15603]],
  [1271717, [23.08333, 70.13333]],
  [2863574, [50.53051, 7.42099]],
  [1549999, [34.41667, 119.775]],
  [1730232, [8.88781, 126.3112]],
  [728330, [42.6, 23.03333]],
  [73861, [15.98792, 43.95362]],
  [684751, [45.05, 24.26667]],
  [3816674, [16.34611, -93.41667]],
  [7760366, [55.8041, 48.9709]],
  [5278420, [43.01668, -88.00703]],
  [8452595, [32.49941, 95.2858]],
  [5926511, [47.49989, -52.99806]],
  [742617, [40.65435, 29.16289]],
  [3992769, [25.32466, -103.48588]],
  [1609141, [14.16572, 100.30789]],
  [3026698, [45.90786, 2.99976]],
  [5317867, [32.41035, -111.01732]],
  [665760, [46.70638, 22.11338]],
  [2036286, [40.50728, 120.12139]],
  [686270, [45.86667, 23.85]],
  [7781005, [-6.3623, 105.9128]],
  [3818607, [20.17205, -98.0315]],
  [3169135, [41.55132, 13.66711]],
  [8863050, [17.27833, -91.75806]],
  [703646, [47.98522, 37.2821]],
  [8861083, [20.50083, -103.49417]],
  [672838, [44.46667, 24.11667]],
  [3017882, [46.11667, -0.13333]],
  [1919478, [24.06933, 115.39326]],
  [8604596, [-34.05515, 18.47617]],
  [2909386, [51.69051, 10.85373]],
  [2999040, [48.56591, 2.32676]],
  [734718, [40.45376, 21.14216]],
  [2263547, [39.6796, -8.68679]],
  [5143986, [42.64341, -73.60873]],
  [3695248, [-6.38511, -77.54636]],
  [3667547, [9.31206, -74.58544]],
  [3901504, [-17.4, -63.83333]],
  [730837, [41.52672, 26.1249]],
  [2510271, [37.74293, -0.95396]],
  [3033955, [45.25913, 4.09874]],
  [783086, [41.14556, 20.24]],
  [3608503, [14.66667, -86.21944]],
  [3453605, [-21.99861, -49.45722]],
  [2944879, [54.61868, 8.96441]],
  [712212, [46.90641, 31.47411]],
  [3181120, [45.75324, 12.17165]],
  [1692214, [7.73028, 125.09889]],
  [2924388, [50.90377, 7.80961]],
  [3069085, [49.78955, 18.08895]],
  [2960833, [49.51056, 6.25722]],
  [765683, [49.96712, 21.9124]],
  [3690818, [-8.6179, -77.86362]],
  [256872, [38.44373, 23.65365]],
  [3181800, [44.35525, 12.2543]],
  [3116462, [40.58192, -5.84312]],
  [4858555, [42.7358, -93.92078]],
  [682590, [45.81465, 23.21173]],
  [1622886, [-6.8736, 111.9768]],
  [1646726, [-7.5501, 108.1275]],
  [685606, [46.94384, 22.57206]],
  [5338866, [39.21433, -122.00942]],
  [686474, [44.25693, 24.53895]],
  [2996492, [49.5271, 1.04292]],
  [2831389, [49.70361, 10.02605]],
  [2698767, [59.1277, 15.14341]],
  [8860439, [19.56028, -100.14722]],
  [2035754, [43.10694, 128.92167]],
  [3389860, [-8.07417, -39.11917]],
  [8349200, [-33.84721, 151.11638]],
  [2938197, [49.14772, 10.42309]],
  [3120027, [40.48908, -6.11107]],
  [7580215, [47.14644, 9.77736]],
  [628332, [54.0601, 29.9184]],
  [4024524, [20.95324, -101.68143]],
  [512247, [59.1139, 46.14142]],
  [3624468, [10.43105, -85.09825]],
  [4146354, [30.33441, -81.3987]],
  [744093, [41.07096, 30.78543]],
  [2934560, [48.21242, 8.50619]],
  [681721, [44.1, 28.28333]],
  [1150203, [10.66461, 99.17281]],
  [1149107, [34.8965, 69.72049]],
  [3209418, [47.68885, 9.06355]],
  [3010558, [44.00961, 3.1584]],
  [3174574, [46.47954, 12.45984]],
  [2973136, [45.9444, 4.52876]],
  [8859684, [19.75222, -101.19139]],
  [713716, [48.46893, 38.81669]],
  [4711725, [26.21591, -98.32529]],
  [3928867, [-11.13333, -77.1946]],
  [2774770, [47.33333, 11.85]],
  [93455, [36.84069, 44.30229]],
  [667030, [46.96667, 24.36667]],
  [3030958, [48.16719, -1.06727]],
  [2524199, [38.08969, 15.13535]],
  [671805, [47.57626, 26.73244]],
  [2920074, [53.91206, 11.73658]],
  [3191249, [45.35583, 18.37806]],
  [3167304, [44.7589, 10.44737]],
  [3520356, [19.10279, -98.13013]],
  [1215468, [2.0106, 98.3982]],
  [710491, [49.38806, 24.72647]],
  [3030276, [49.63357, 2.29509]],
  [3123834, [40.95978, -5.74349]],
  [2936528, [50.25, 6.78333]],
  [2517355, [39.81667, -1.36667]],
  [3029898, [48.21719, 1.16539]],
  [8348574, [-33.90034, 151.05563]],
  [3163876, [46.28572, 11.56496]],
  [3816528, [16.30457, -92.12425]],
  [3431272, [-27.73501, -57.9185]],
  [2000820, [34.86605, 108.99294]],
  [2661336, [46.83119, 6.55403]],
  [2996411, [45.81509, 3.18603]],
  [2953464, [52.31667, 8.33333]],
  [1922458, [26.68917, 116.75459]],
  [1706173, [8.18806, 124.60972]],
  [9036141, [43.53869, 11.03236]],
  [680345, [44.75995, 22.77316]],
  [4930231, [42.27704, -72.40092]],
  [2763401, [48.02357, 16.63266]],
  [4013712, [19.6927, -100.55445]],
  [2992650, [48.90924, 2.13754]],
  [2036597, [50.41667, 124.11667]],
  [2519497, [38.79595, -4.86628]],
  [3128352, [41.56025, 1.01602]],
  [2939969, [52.84754, 8.045]],
  [1552709, [31.31484, 118.39781]],
  [2653060, [55.9028, -4.10759]],
  [3034737, [49.43364, 5.75852]],
  [8949318, [45.22342, 12.1899]],
  [3030675, [47.3375, -1.11143]],
  [7845455, [-7.3563, 106.5424]],
  [7084713, [-8.3073, 115.3953]],
  [1641177, [-8.227, 113.5204]],
  [1267588, [27.80882, 78.64579]],
  [2750310, [52.95113, 6.06342]],
  [3725170, [19.66273, -71.83798]],
  [572665, [56.35808, 44.07477]],
  [2517812, [39.71667, -0.35]],
  [1256428, [12.85737, 76.48886]],
  [3520118, [21.16544, -89.35583]],
  [2171594, [-28.25456, 153.53712]],
  [4500991, [39.04567, -74.85156]],
  [1271923, [31.86431, 74.95665]],
  [3177825, [43.57304, 10.56431]],
  [8401734, [-6.9635, 113.8011]],
  [2161996, [-37.87011, 147.96664]],
  [2654786, [51.25219, -2.95744]],
  [11704379, [-34.87776, 138.62206]],
  [4111410, [35.38592, -94.39855]],
  [6696932, [34.80447, 136.54645]],
  [3129980, [40.74336, -1.73697]],
  [612890, [41.84514, 44.71875]],
  [3640049, [10.47027, -66.61934]],
  [684567, [47.7, 26.86667]],
  [2992102, [43.08579, 0.56839]],
  [694647, [48.20782, 25.6713]],
  [4023464, [21.26539, -100.69223]],
  [5194821, [41.00314, -75.50824]],
  [256298, [39.89553, 22.64197]],
  [3128388, [42.45415, -1.62101]],
  [579503, [44.7767, 41.7842]],
  [8860098, [16.80694, -92.62139]],
  [2264427, [39.60191, -8.81839]],
  [1711997, [11.33333, 123.9]],
  [2766632, [46.90917, 15.33639]],
  [2036306, [49.26767, 125.76046]],
  [260598, [35.28634, 25.05612]],
  [2772001, [47.42815, 16.52678]],
  [1125362, [35.03975, 69.00685]],
  [2802108, [50.69533, 5.09077]],
  [3176101, [41.27992, 15.04374]],
  [3963701, [-10.48962, -76.37232]],
  [2145447, [-31.71081, 152.4658]],
  [1548681, [32.24917, 119.9075]],
  [3180980, [42.01013, 14.03941]],
  [2013406, [56.4088, 103.1106]],
  [2634155, [54.94561, -1.67635]],
  [1642996, [0.6446, 122.8614]],
  [3167924, [41.05808, 15.20006]],
  [3185963, [41.47417, 19.65167]],
  [2774823, [47.39245, 14.90407]],
  [2806923, [47.8203, 9.79491]],
  [5398521, [35.08886, -118.64259]],
  [3098470, [51.50596, 18.11929]],
  [3666670, [9.18662, -75.55473]],
  [2782070, [47.60998, 13.78243]],
  [3173805, [40.90892, 15.82946]],
  [1634593, [-6.8954, 106.4651]],
  [3115376, [43.48917, -3.52306]],
  [3609673, [15.85, -85.58333]],
  [667209, [45.60795, 21.61075]],
  [1150700, [18.71417, 99.03528]],
  [590552, [59.57639, 25.72139]],
  [563354, [57.631, 53.0251]],
  [3801980, [16.36937, -94.66863]],
  [5915359, [49.09736, -66.68514]],
  [2790723, [50.95862, 3.77753]],
  [265624, [39.86791, 21.4655]],
  [241448, [-4.71667, 55.48333]],
  [285127, [32.36074, 35.1959]],
  [5166516, [41.557, -83.62716]],
  [1723733, [10.25302, 123.93909]],
  [671663, [46.53839, 26.83946]],
  [1278094, [26.79909, 82.2047]],
  [3219941, [46.54, 16.28861]],
  [2776872, [48.85, 15.85]],
  [2920959, [49.90025, 10.34832]],
  [3031005, [47.08333, 2.4]],
  [3028824, [47.46718, 1.06051]],
  [3023349, [48.88148, -1.52744]],
  [5036088, [43.50524, -92.94408]],
  [3001966, [48.87948, 0.81134]],
  [3072234, [48.93329, 14.61886]],
  [6670408, [-9.84731, -76.00081]],
  [3180743, [42.45342, 11.42175]],
  [3106902, [40.39114, -0.60593]],
  [3072265, [50.01058, 12.62474]],
  [8861530, [19.55639, -96.96694]],
  [7511001, [15.47156, 104.07153]],
  [720780, [46.31667, 20.5]],
  [3166365, [45.28819, 9.85862]],
  [1502387, [53.36167, 84.58222]],
  [3012066, [49.43324, 0.81918]],
  [2932379, [50.43333, 6.81667]],
  [2976692, [49.96667, 2.2]],
  [703597, [45.93167, 30.29605]],
  [11919760, [43.25884, 5.41729]],
  [252841, [38.58417, 22.66765]],
  [3398622, [-8.41667, -35.66667]],
  [2895664, [52.47093, 9.84179]],
  [2967997, [47.81909, -0.23925]],
  [3078788, [49.75712, 18.08276]],
  [3066995, [49.36769, 17.91499]],
  [3404722, [-5.83722, -43.83361]],
  [8200212, [21.4213, 105.13374]],
  [8020004, [53.6238, 27.8977]],
  [2520659, [38.61508, -4.18452]],
  [670388, [44.9625, 22.35864]],
  [2945942, [54.81667, 8.86667]],
  [2659988, [46.25893, 8.83756]],
  [527181, [60.5642, 29.86232]],
  [3584714, [13.55, -88.35]],
  [2512434, [37.25759, -3.23375]],
  [2908575, [48.27531, 9.4776]],
  [2205972, [-34.04583, 151.14312]],
  [684359, [47.20843, 27.43376]],
  [7333664, [-8.5488, 115.0629]],
  [3818755, [19.13421, -97.83266]],
  [2823605, [49.04114, 12.98784]],
  [1496124, [56.92501, 65.5394]],
  [9036165, [44.69865, 7.93486]],
  [667420, [45.76667, 24.2]],
  [4847385, [41.4036, -95.01388]],
  [3165314, [41.95742, 13.5327]],
  [2517722, [37.1715, -2.84011]],
  [3693863, [-4.56667, -74.43333]],
  [1627708, [-1.4772, 103.50765]],
  [3015596, [48.16948, -3.97276]],
  [3527879, [18.84065, -99.18463]],
  [1814740, [28.73278, 112.55056]],
  [3014233, [45.46614, -0.24696]],
  [2691326, [59.93395, 16.96353]],
  [1513555, [40.43553, 71.76721]],
  [3526924, [20.72603, -89.16072]],
  [3214685, [45.47833, 18.15556]],
  [2873343, [52.66857, 9.14219]],
  [2920632, [52.03623, 10.49366]],
  [606249, [63.88333, 20.31667]],
  [3085134, [53.38922, 17.68713]],
  [2161613, [-36.77128, 144.30298]],
  [2646620, [52.53333, 1.73333]],
  [5722960, [43.99151, -121.35836]],
  [4410585, [39.10973, -94.44467]],
  [2760485, [47.50772, 10.47971]],
  [3818622, [20.00366, -97.67584]],
  [2661233, [47.05878, 6.9004]],
  [348112, [31.27133, 30.78617]],
  [8571840, [5.0174, 96.77214]],
  [8949835, [41.75603, 13.32072]],
  [667119, [44.61417, 22.70722]],
  [616835, [40.71959, 43.82727]],
  [4256184, [38.21201, -86.12192]],
  [3816566, [16.22576, -92.64318]],
  [771394, [50.16238, 22.46292]],
  [1490796, [60.7333, 77.5889]],
  [123941, [27.13104, 57.08716]],
  [2970071, [49.36583, 3.39321]],
  [686057, [46.43333, 24.1]],
  [5014852, [41.79811, -85.64332]],
  [2033777, [49.14925, 127.97453]],
  [6290556, [47.26574, 8.43013]],
  [3074049, [50.78191, 15.23146]],
  [2944431, [54.4212, 9.6335]],
  [4355780, [38.32206, -75.6202]],
  [2034758, [40.39278, 110.02694]],
  [5881410, [-14.32278, -170.73389]],
  [571913, [42.84147, 45.62746]],
  [3994912, [21.36435, -102.8459]],
  [8949247, [40.73216, 14.76035]],
  [2518147, [37.21773, -7.12206]],
  [734791, [40.90495, 24.35083]],
  [3522293, [17.40664, -93.33643]],
  [8859242, [18.45104, -97.02991]],
  [2971874, [48.94956, 2.5684]],
  [3184935, [41.78361, 19.64361]],
  [1785416, [37.04915, 121.88788]],
  [3700103, [9.58263, -79.4069]],
  [2789999, [50.55702, 4.98466]],
  [1700213, [8.13611, 124.16417]],
  [7511010, [17.78536, 104.09072]],
  [7012544, [42.57495, 123.16525]],
  [8554948, [41.79737, 112.94771]],
  [2771918, [47.93333, 14.6]],
  [787123, [45.20813, 20.38197]],
  [2856374, [54.10825, 9.57436]],
  [1794720, [29.61861, 106.91833]],
  [1716599, [11.65778, 124.5425]],
  [2797779, [51.16557, 4.98917]],
  [470549, [57.22716, 29.1663]],
  [2751888, [52.95836, 5.72173]],
  [562627, [57.48135, 33.70211]],
  [2520874, [39.06041, -0.67466]],
  [675185, [46.86389, 25.52774]],
  [3174994, [40.78619, 15.30791]],
  [2989646, [49.33309, 1.09413]],
  [2988742, [43.52974, 3.93043]],
  [2658572, [47.46408, 7.80888]],
  [6697288, [47.50154, 8.23122]],
  [2634569, [51.20794, -2.64896]],
  [2990277, [48.9656, 3.32178]],
  [1626670, [-6.89, 113.4347]],
  [1260387, [9.54633, 78.5907]],
  [676256, [47.20303, 26.87755]],
  [2878863, [53.3, 9.51667]],
  [1806673, [35.26556, 113.65056]],
  [5212582, [41.81117, -78.44474]],
  [2031832, [44.42446, 105.32078]],
  [5296266, [33.43532, -112.35821]],
  [8024185, [-7.3299, 108.2274]],
  [3168206, [43.55269, 11.70497]],
  [2280316, [6.55799, -5.01769]],
  [3171738, [45.67879, 9.44491]],
  [4343659, [29.87743, -90.03202]],
  [2792332, [50.71222, 5.03654]],
  [706749, [49.45548, 35.13493]],
  [3996647, [25.8338, -103.8461]],
  [616916, [40.03963, 44.54179]],
  [2733311, [40.13115, -7.81019]],
  [2714670, [59.77554, 16.92106]],
  [2939623, [48.26, 11.43402]],
  [1863495, [26.35937, 127.86735]],
  [2521456, [37.21329, -7.40807]],
  [738858, [41.02931, 28.54978]],
  [6534447, [46.04742, 9.22882]],
  [10057018, [-31.94141, 115.83117]],
  [5265702, [43.88441, -91.23514]],
  [5388467, [38.03298, -122.26691]],
  [2032571, [47.72732, 108.11062]],
  [1709498, [5.94646, 121.30757]],
  [3996336, [19.9161, -103.02027]],
  [2496049, [32.49094, 3.67347]],
  [2824593, [47.86667, 12.7]],
  [3446087, [-27.45556, -53.93194]],
  [3069150, [50.23341, 14.41078]],
  [109131, [25.86012, 44.22228]],
  [3167215, [42.86886, 12.26853]],
  [2794105, [51.00233, 3.2104]],
  [667098, [44.38333, 23.8]],
  [8859417, [20.645, -100.42083]],
  [3514453, [15.1278, -92.43388]],
  [2986690, [48.45354, -4.14282]],
  [3815942, [16.59944, -93.35083]],
  [7764812, [-6.56391, 105.85495]],
  [3616572, [13.3054, -86.18641]],
  [1553308, [29.68878, 118.63997]],
  [3522303, [20.58338, -99.20874]],
  [3697458, [-5.45, -80.76667]],
  [3984440, [20.74661, -103.64281]],
  [678840, [47.12722, 25.86527]],
  [3019516, [48.18708, 2.32968]],
  [3800659, [16.93835, -97.83186]],
  [685220, [47.45243, 22.44896]],
  [5781070, [40.58106, -111.85077]],
  [5116004, [40.76704, -72.99622]],
  [4038473, [13.46256, 144.69331]],
  [1787351, [37.8575, 113.56333]],
  [521966, [53.1257, 47.2041]],
  [8950137, [44.46156, 7.59267]],
  [3103644, [50.38587, 17.66035]],
  [2636132, [50.54581, -3.49671]],
  [4565049, [18.45356, -66.56628]],
  [3519458, [19.9138, -97.79498]],
  [2911287, [53.56667, 10.08333]],
  [3446500, [-27.24139, -48.63361]],
  [2037370, [46.72167, 131.13944]],
  [1512236, [53.71556, 91.42917]],
  [4034438, [-17.56352, -149.86905]],
  [7931040, [13.81436, 45.39229]],
  [2509031, [36.41657, 1.19616]],
  [3693992, [-7.2, -75.3]],
  [10103838, [-32.92371, 151.72849]],
  [981158, [-25.09598, 30.44393]],
  [8667844, [-0.88718, 131.23644]],
  [690908, [49.80286, 23.23624]],
  [2947090, [49.27082, 12.30146]],
  [696999, [44.8354, 33.96159]],
  [1255667, [20.59606, 75.61765]],
  [2783274, [50.98318, 4.46079]],
  [3085479, [51.23924, 18.04255]],
  [666928, [47.06615, 21.85352]],
  [5218450, [41.74868, -77.30053]],
  [2814685, [50.63333, 10.4]],
  [3102434, [50.12278, 19.82311]],
  [8860979, [17.27306, -92.45028]],
  [2761614, [47.56667, 14.21667]],
  [3165420, [40.46762, 17.73864]],
  [3107779, [40.82764, -3.53683]],
  [2986725, [47.98375, -4.27186]],
  [3053147, [47.44794, 18.75328]],
  [2679142, [56.72199, 13.13211]],
  [3218751, [45.82389, 12.05417]],
  [4252512, [38.63505, -90.09289]],
  [2900253, [54.04161, 9.34808]],
  [3023063, [47.51033, 1.45583]],
  [872144, [45.29462, 38.11384]],
  [2026160, [62.6706, 131.16344]],
  [3065624, [49.01964, 17.07893]],
  [616199, [40.83221, 44.26731]],
  [3182244, [44.36394, 7.64464]],
  [6619957, [50.93831, -0.02105]],
  [8949499, [40.19951, 15.17424]],
  [3439902, [-34.26555, -57.61866]],
  [3038707, [50.07279, 2.53201]],
  [2944800, [48.03279, 7.58294]],
  [5098691, [40.16983, -74.67155]],
  [2764624, [48.18333, 15.63333]],
  [2658726, [47.74818, 8.48213]],
  [1635527, [-2.1488, 114.8819]],
  [172256, [36.89095, 38.35347]],
  [1810892, [31.47748, 115.09361]],
  [4260711, [38.67699, -86.91417]],
  [8861753, [18.45028, -97.12861]],
  [2998027, [49.02454, 3.02178]],
  [2839222, [49.63333, 6.78333]],
  [2510272, [39.3831, -6.24941]],
  [750615, [41.03556, 36.23556]],
  [11611615, [34.21052, 133.6746]],
  [2766155, [48.45294, 13.43722]],
  [2950556, [52.544, 10.84505]],
  [2966919, [52.31167, -7.875]],
  [2805041, [50.47611, 7.92361]],
  [3818814, [18.78414, -97.40738]],
  [1283679, [27.63245, 85.52192]],
  [8948955, [45.71771, 7.25547]],
  [4742527, [30.45909, -97.64917]],
  [672212, [47.11499, 23.98092]],
  [732280, [43.22222, 27.56944]],
  [3164992, [46.41361, 11.30498]],
  [3430340, [-27.48706, -55.11994]],
  [683455, [44.95, 24.85]],
  [2725719, [62.37028, 17.41167]],
  [698939, [48.22045, 25.70484]],
  [1682393, [6.47834, 126.10828]],
  [2986819, [48.44962, -2.38861]],
  [616108, [40.18329, 45.73053]],
  [3109551, [41.74549, 1.80476]],
  [2513978, [28.60906, -17.77801]],
  [3032069, [48.03576, 2.38333]],
  [3374351, [17.15026, -25.02007]],
  [2022331, [51.13186, 108.22515]],
  [3000446, [46.84414, -1.49392]],
  [3517291, [20.22949, -98.99833]],
  [2995913, [45.71058, 4.01219]],
  [3928527, [-13.21454, -74.28924]],
  [4062644, [30.40659, -87.6836]],
  [5281876, [41.72454, -73.23623]],
  [2976774, [49.30546, 3.96565]],
  [6643306, [22.62796, 100.72402]],
  [8860612, [15.14361, -92.45139]],
  [3034109, [43.64713, 1.55517]],
  [8949997, [44.63466, 10.7519]],
  [6559436, [37.17391, -7.53169]],
  [1243867, [6.7159, 80.0626]],
  [4447577, [33.5429, -90.53703]],
  [4014829, [27.29906, -108.055]],
  [8073182, [-6.7559, 111.5945]],
  [3482935, [22.6543, -98.55467]],
  [1912504, [38.83539, 117.05301]],
  [1266937, [25.54277, 79.81235]],
  [1625038, [-8.7737, 115.5831]],
  [8729179, [21.40528, -98.22889]],
  [974932, [-25.27748, 27.21605]],
  [2956253, [47.97464, 11.18279]],
  [3082130, [51.20457, 18.66543]],
  [1694585, [15.8587, 120.37935]],
  [4017638, [26.68706, -108.39417]],
  [4159672, [27.7167, -80.38422]],
  [2763837, [47.29116, 12.96215]],
  [3189308, [45.96667, 16.06667]],
  [3032436, [47.37107, 4.73845]],
  [3220203, [46.28475, 12.79644]],
  [2025579, [51.12853, 133.00827]],
  [670542, [45.03232, 26.24224]],
  [8456727, [22.31812, 103.62619]],
  [4923670, [41.66199, -86.15862]],
  [4945622, [41.64594, -70.61836]],
  [2926667, [53.17188, 9.66969]],
  [693044, [48.88992, 24.06293]],
  [2112802, [35.73333, 140.83333]],
  [5123815, [43.31285, -73.83484]],
  [3064213, [50.2698, 14.55411]],
  [2817965, [47.84256, 12.60606]],
  [4294044, [37.78006, -86.46053]],
  [3818762, [19.11912, -97.7028]],
  [2953341, [53.94313, 10.30215]],
  [2789817, [50.89334, 3.3363]],
  [3530690, [20.24454, -90.24157]],
  [5324666, [36.31578, -119.70846]],
  [8860756, [16.67944, -91.66778]],
  [637292, [61.8699, 28.87999]],
  [2510839, [38.72527, -6.53899]],
  [2814066, [52.45695, 8.84949]],
  [2178224, [-38.464, 144.10394]],
  [2933781, [50.01667, 6.41667]],
  [782582, [40.04667, 20.1475]],
  [3075119, [49.83597, 13.90268]],
  [8862993, [18.01222, -95.21333]],
  [3094402, [52.18409, 19.45619]],
  [3992154, [27.626, -110.4158]],
  [3646382, [10.66516, -63.25387]],
  [9081340, [11.0885, 106.26346]],
  [3014001, [48.60115, 1.65137]],
  [3372730, [37.75271, -25.71848]],
  [750590, [41.54755, 42.78199]],
  [3066733, [49.30438, 14.71857]],
  [2692661, [57.61667, 15.56667]],
  [677982, [47.35, 26.46667]],
  [5143419, [42.38063, -76.87329]],
  [7378350, [-8.5969, 118.7725]],
  [2785809, [50.61726, 4.93072]],
  [8862160, [18.72972, -99.38167]],
  [7890845, [31.48794, 35.11665]],
  [1712704, [6.65413, 126.07268]],
  [2471915, [36.53853, 10.55093]],
  [1269498, [26.20446, 73.93676]],
  [3130405, [41.82832, 0.14823]],
  [3178656, [41.35455, 14.8091]],
  [1138636, [34.78371, 69.15001]],
  [5112976, [41.83009, -73.76235]],
  [2668679, [55.99543, 13.6279]],
  [3171814, [40.67483, 8.59254]],
  [9609489, [43.27652, 5.36519]],
  [3138222, [59.19608, 10.82466]],
  [3802417, [18.11351, -92.854]],
  [11907272, [35.25755, 126.54315]],
  [3715049, [8.50486, -81.46397]],
  [666002, [47.93677, 26.29664]],
  [2898960, [50.67406, 12.88194]],
  [2772821, [46.64019, 14.94733]],
  [8860539, [18.01472, -93.33]],
  [2811713, [51.49405, 14.71515]],
  [3980777, [20.14085, -101.18251]],
  [3108057, [41.03333, 0.73333]],
  [3019474, [49.21259, 5.64022]],
  [2167949, [-33.9209, 151.12506]],
  [1642640, [-1.74302, 105.45527]],
  [1697782, [7.5233, 125.8363]],
  [933162, [-22.79904, 27.19376]],
  [447273, [36.76591, 28.8028]],
  [2749708, [53.25, 5.9]],
  [8070207, [-7.4536, 108.6423]],
  [165533, [33.75623, 36.3883]],
  [1280982, [31.483, 94.38641]],
  [1594371, [14.61241, 108.8899]],
  [8861052, [19.68694, -99.08389]],
  [3468352, [-18.55667, -51.13083]],
  [2981280, [48.51513, -2.76838]],
  [2978673, [49.50887, 1.44856]],
  [6534359, [44.34033, 7.82264]],
  [3515487, [18.23096, -95.94682]],
  [7780413, [-6.3102, 106.0096]],
  [1708983, [16.3592, 120.8867]],
  [3183378, [41.05871, 14.55924]],
  [683920, [47.49673, 26.07182]],
  [4576268, [34.01682, -80.43008]],
  [2984789, [50.11055, 1.68932]],
  [8949846, [40.63239, 14.4929]],
  [1784982, [28.02974, 116.24058]],
  [5108219, [41.50482, -73.96958]],
  [665572, [46.64453, 21.55685]],
  [2807363, [51.44362, 7.35258]],
  [2857316, [49.66667, 6.71667]],
  [3089494, [50.88333, 18.7516]],
  [5474794, [35.56281, -106.13086]],
  [3973866, [22.08943, -101.11523]],
  [11919791, [43.33895, 5.35111]],
  [5133795, [42.85341, -74.98543]],
  [5206933, [40.69648, -76.16883]],
  [703685, [51.04139, 32.01382]],
  [3629844, [8.01909, -71.76595]],
  [2512796, [37.5029, -3.23134]],
  [2033552, [49.34438, 128.07165]],
  [5129196, [43.18562, -76.89246]],
  [7384060, [35.52103, 100.01249]],
  [2411989, [13.43833, -16.67806]],
  [1636536, [3.51225, 125.62302]],
  [2960054, [49.62056, 6.07333]],
  [4174518, [25.87843, -80.1256]],
  [2826290, [53.57498, 11.30356]],
  [2789692, [51.11705, 5.64749]],
  [3077505, [50.5959, 16.0393]],
  [2726285, [60.57081, 17.44895]],
  [6125653, [48.5168, -72.23244]],
  [526480, [44.21028, 43.13528]],
  [2511064, [37.43553, -6.04106]],
  [3523280, [19.86657, -97.39154]],
  [8060272, [-6.7133, 111.1494]],
  [2142777, [-33.18252, 151.48804]],
  [2649622, [51.3148, 0.88856]],
  [497547, [57.3132, 52.1345]],
  [5096289, [40.84038, -74.0907]],
  [3817921, [20.01271, -99.96202]],
  [2962570, [53.91667, -7.86667]],
  [3017770, [50.14026, 3.57281]],
  [684212, [45.07752, 25.86674]],
  [1787899, [31.16189, 113.6338]],
  [9813328, [25.94238, -106.29665]],
  [3173926, [44.48625, 12.28087]],
  [3130240, [41.14286, 1.37269]],
  [1799209, [29.79221, 116.86406]],
  [2856500, [53.22698, 8.79528]],
  [3068543, [49.07953, 17.64434]],
  [2996315, [43.25, 2.45]],
  [3815427, [19.24123, -98.33352]],
  [3614328, [14.0, -88.38333]],
  [5133858, [40.7001, -73.90569]],
  [3179095, [45.08685, 8.31817]],
  [2523613, [39.99361, 8.54127]],
  [284294, [31.8966, 35.17048]],
  [2989372, [48.92162, 1.9779]],
  [1731502, [12.22906, 121.22811]],
  [1608409, [6.42639, 101.82308]],
  [3141548, [67.10021, 15.39086]],
  [2921179, [51.33333, 9.98333]],
  [3110229, [40.78334, -6.12758]],
  [3515744, [20.96316, -89.93384]],
  [3973879, [22.21103, -101.08597]],
  [1278974, [29.59713, 79.65911]],
  [1928134, [29.28912, 111.58562]],
  [1816329, [29.59491, 106.22476]],
  [2227980, [3.78333, 11.38333]],
  [2658773, [46.59732, 9.59816]],
  [3016812, [49.65371, 1.61547]],
  [2510223, [39.71057, -4.22655]],
  [3057552, [48.50478, 17.43067]],
  [3127790, [41.66481, -1.89615]],
  [1639934, [-6.8938, 112.9041]],
  [685841, [47.41667, 26.21667]],
  [3102510, [52.09949, 18.60432]],
  [2750479, [51.76322, 5.17305]],
  [6534234, [45.59777, 9.04634]],
  [3832733, [-29.1431, -62.65248]],
  [1068670, [-14.54329, 48.74981]],
  [3393115, [-3.41, -39.03056]],
  [680921, [44.26297, 26.09374]],
  [2934529, [49.41586, 7.30548]],
  [680663, [45.00788, 24.19188]],
  [2744483, [51.97417, 5.34167]],
  [2736391, [41.18252, -8.2545]],
  [3835348, [-30.63761, -65.38692]],
  [2986066, [48.57089, -2.81714]],
  [2512858, [37.02222, -2.95343]],
  [2919029, [52.56832, 14.54847]],
  [2945729, [54.33333, 9.83333]],
  [8949632, [45.20141, 11.69002]],
  [2234339, [5.6, 14.08333]],
  [5008971, [42.92948, -85.21307]],
  [2967806, [44.55678, 2.21649]],
  [3519059, [17.81021, -97.84488]],
  [8508209, [47.12748, 126.00979]],
  [974670, [-27.98299, 30.71769]],
  [678894, [47.01667, 23.4]],
  [4307238, [38.25285, -85.65579]],
  [548440, [54.2862, 40.3572]],
  [3074944, [50.85279, 14.84455]],
  [1802929, [28.05086, 120.89902]],
  [8951266, [44.9467, 7.21474]],
  [1695082, [11.466, 122.7725]],
  [3030023, [48.77986, 6.35462]],
  [1277264, [23.23241, 87.0716]],
  [3174570, [45.06213, 12.18812]],
  [174918, [39.27059, 46.3572]],
  [4563811, [18.07996, -66.35795]],
  [2807440, [50.77915, 7.73466]],
  [2339937, [12.17024, 6.66412]],
  [3654724, [-2.86667, -78.88333]],
  [2168819, [-17.14752, 145.10969]],
  [1810543, [28.92611, 115.23636]],
  [6599420, [32.88972, 108.90444]],
  [2797473, [50.49496, 4.28698]],
  [2750272, [53.07917, 6.96528]],
  [2770464, [47.25483, 9.57802]],
  [3524848, [15.10245, -92.50002]],
  [672240, [47.86667, 26.63333]],
  [5216000, [40.13928, -74.981]],
  [3570423, [14.7761, -61.13564]],
  [11886463, [5.99732, -72.69138]],
  [749279, [40.81164, 32.89358]],
  [5539372, [39.62996, -111.6352]],
  [665385, [46.09606, 23.46783]],
  [6535160, [45.81478, 9.57105]],
  [3014601, [49.13177, 2.05012]],
  [5204622, [40.85006, -78.27057]],
  [670804, [46.7804, 28.03778]],
  [3105604, [40.49806, -5.23005]],
  [2815784, [51.1, 9.35]],
  [3012397, [45.95451, 0.98542]],
  [2984718, [49.46934, 0.52793]],
  [671246, [44.73247, 25.16495]],
  [5433564, [38.21806, -103.75606]],
  [2657908, [47.17242, 8.51745]],
  [2171184, [-38.3286, 143.07484]],
  [676300, [47.25307, 26.79882]],
  [2999119, [45.4861, 4.37586]],
  [543728, [47.63009, 43.14068]],
  [1630206, [-7.1836, 108.4341]],
  [565007, [55.74208, 38.84753]],
  [3101812, [50.10163, 19.24801]],
  [1799252, [34.52352, 118.76495]],
  [2765614, [48.7, 15.9]],
  [4669581, [28.44193, -99.76033]],
  [2978310, [49.60197, 0.21138]],
  [2747229, [51.14333, 6.0]],
  [2709927, [57.8, 15.7]],
  [6138352, [45.93338, -74.0159]],
  [2809259, [52.11456, 12.45534]],
  [1799535, [33.8544, 113.21103]],
  [4491651, [35.72347, -79.46224]],
  [5102427, [40.85788, -74.42599]],
  [2857853, [50.92251, 11.35806]],
  [4688739, [34.02926, -98.91896]],
  [3189545, [44.69104, 19.10994]],
  [2774002, [47.18333, 15.31667]],
  [3019212, [44.66732, 0.39905]],
  [2990298, [48.37908, 0.68138]],
  [2689452, [60.06505, 15.92366]],
  [5364192, [37.39994, -122.1983]],
  [3574194, [10.30618, -61.44671]],
  [672177, [44.8011, 22.86784]],
  [8949173, [42.72112, 13.93732]],
  [8180829, [-8.2864, 112.9791]],
  [1790214, [28.2223, 114.05827]],
  [1683881, [10.24472, 123.84944]],
  [7036711, [-7.1187, 112.4742]],
  [3018097, [47.19918, 6.02507]],
  [2033602, [40.29278, 123.27444]],
  [3105229, [41.98492, -5.4066]],
  [2869222, [50.69806, 7.88444]],
  [2791532, [50.74081, 5.26278]],
  [663988, [45.63864, 27.45716]],
  [2020824, [47.14795, 134.33477]],
  [2973918, [43.75165, -1.32977]],
  [587777, [58.76972, 24.27361]],
  [3823831, [18.7675, -96.94944]],
  [8862579, [16.31917, -97.66306]],
  [1999718, [34.57167, 108.80194]],
  [34886, [16.22816, 43.24895]],
  [2709531, [59.50503, 14.96661]],
  [3727055, [18.56107, -74.42167]],
  [3034411, [47.68928, -0.16883]],
  [3522107, [19.59001, -96.43659]],
  [3459785, [-17.7425, -46.1725]],
  [3021611, [46.93048, 4.83381]],
  [3006795, [44.56188, 5.95541]],
  [174805, [39.83069, 44.66949]],
  [10944629, [34.58887, 73.91306]],
  [3182783, [46.48021, 13.0204]],
  [4336678, [30.02631, -90.71315]],
  [3800289, [16.98939, -95.32442]],
  [1810926, [28.51547, 115.85314]],
  [691929, [47.01709, 32.9996]],
  [5388881, [38.75212, -121.28801]],
  [3127117, [42.28856, 3.27706]],
  [3125933, [40.80255, -0.696]],
  [932997, [-20.38333, 22.95]],
  [4029378, [20.76354, -100.14363]],
  [2853009, [53.65, 10.58333]],
  [3000139, [42.68185, 2.79305]],
  [2534572, [34.02733, -4.88343]],
  [681985, [47.56726, 26.93241]],
  [2992141, [49.02155, -1.31021]],
  [2953422, [53.77134, 11.47165]],
  [3002280, [43.67873, 7.01521]],
  [1526619, [49.77952, 72.96128]],
  [3181348, [45.3411, 10.99915]],
  [1799056, [25.05286, 116.5282]],
  [3181896, [45.68179, 9.55956]],
  [3108656, [42.49686, -1.21514]],
  [3068912, [50.13071, 15.217]],
  [2176325, [-38.33333, 144.26667]],
  [2895643, [48.23333, 11.68333]],
  [3077912, [49.93143, 16.2531]],
  [3177826, [45.35136, 9.57659]],
  [2522934, [39.90284, 18.36501]],
  [3032296, [45.0572, 3.97993]],
  [6114228, [49.67322, -125.02907]],
  [8401699, [-6.8977, 113.7907]],
  [6535152, [45.64598, 9.26275]],
  [707565, [50.52175, 30.25055]],
  [11549821, [41.38697, 2.13472]],
  [3022100, [45.86259, 3.33676]],
  [2817105, [52.1653, 8.85996]],
  [622690, [55.9058, 28.8135]],
  [2272188, [39.29603, -8.65847]],
  [3022096, [47.82277, 5.44233]],
  [1155525, [8.07143, 98.99933]],
  [3515869, [19.19254, -97.43395]],
  [2651980, [53.6424, -1.52916]],
  [3164927, [45.72722, 8.3273]],
  [3687318, [6.08236, -75.33509]],
  [2524321, [38.5773, 14.83598]],
  [1180453, [26.67765, 68.28666]],
  [4004331, [19.96156, -100.92202]],
  [2791759, [50.65969, 4.46689]],
  [8675967, [14.51345, 107.92102]],
  [5441653, [39.97804, -104.87331]],
  [1724272, [8.55833, 124.53]],
  [5529592, [28.65644, -100.42218]],
  [1639000, [-8.892, 116.2776]],
  [3516881, [19.59306, -99.77972]],
  [6534921, [45.64117, 8.90264]],
  [1721842, [10.5869, 122.7002]],
  [865086, [46.35899, 30.64777]],
  [2854906, [53.504, 13.08407]],
  [5121650, [40.85343, -73.41151]],
  [2976717, [49.30324, 2.73724]],
  [8858150, [19.68361, -101.30361]],
  [148141, [39.39917, 47.02835]],
  [1806429, [25.32283, 117.92938]],
  [8424941, [-8.3207, 123.1414]],
  [8862439, [21.31222, -98.80972]],
  [2623628, [55.49472, 11.97584]],
  [3504077, [18.49169, -71.85022]],
  [1540684, [41.44244, 82.86096]],
  [1253901, [29.51124, 75.87823]],
  [3518280, [19.20216, -99.56176]],
  [1270818, [19.34762, 83.78128]],
  [2947172, [48.38886, 8.97703]],
  [1917081, [23.84002, 114.8738]],
  [1691941, [13.8183, 122.7923]],
  [9610017, [14.18871, 121.30009]],
  [80105, [13.52019, 46.71367]],
  [2523656, [37.47529, 14.53521]],
  [2951346, [49.0414, 9.3137]],
  [5574257, [40.19443, -105.52555]],
  [3800664, [16.91722, -97.59722]],
  [3008255, [43.48365, 1.2437]],
  [760404, [49.89983, 22.64171]],
  [3935624, [-14.08333, -70.43333]],
  [2958235, [50.68333, 7.63333]],
  [2958699, [51.44758, 11.62511]],
  [8505282, [46.33255, 123.31245]],
  [2658143, [46.16699, 6.18436]],
  [4718209, [29.62607, -95.73162]],
  [3117704, [41.98131, -4.42775]],
  [3179837, [45.49535, 9.56932]],
  [3101136, [51.59142, 19.68158]],
  [671089, [45.9959, 20.83522]],
  [2982415, [47.4245, 4.10462]],
  [3182210, [46.14262, 12.2156]],
  [2947887, [50.13333, 6.78333]],
  [3061293, [49.93466, 14.41769]],
  [688929, [45.21908, 35.02708]],
  [3013448, [47.44284, 6.88242]],
  [12047630, [51.13704, 16.95752]],
  [2913783, [53.45, 10.5]],
  [1810251, [38.99036, 117.50911]],
  [1514831, [40.04605, 70.54117]],
  [3180133, [40.95653, 16.92075]],
  [3931465, [-11.14705, -74.31021]],
  [8322171, [-8.0525, 111.7078]],
  [5058552, [47.44972, -99.12622]],
  [2161532, [-37.99116, 145.17385]],
  [4683021, [29.51801, -98.31612]],
  [1278178, [16.7, 81.6]],
  [4022669, [25.1798, -100.71147]],
  [1892637, [28.1921, 121.23642]],
  [5100155, [40.8826, -74.38154]],
  [665009, [47.45261, 23.86539]],
  [1725359, [13.86445, 121.48792]],
  [2512186, [28.50038, -13.86272]],
  [11611646, [31.77429, 130.59208]],
  [3118347, [42.24769, 2.81373]],
  [673573, [44.23214, 25.64346]],
  [3034859, [43.61724, 0.44261]],
  [11695064, [48.16488, 13.99753]],
  [8861134, [19.21611, -98.95694]],
  [2832599, [53.73265, 13.38143]],
  [3176660, [44.77431, 10.73475]],
  [8348328, [-20.40655, 118.60069]],
  [4543338, [35.74788, -95.36969]],
  [3012821, [46.93619, 3.26037]],
  [669043, [47.63333, 23.51667]],
  [3500641, [18.45026, -70.61724]],
  [703769, [47.30593, 31.01522]],
  [1732317, [17.35423, 121.60138]],
  [2789096, [50.68333, 4.08333]],
  [4012608, [17.00895, -100.08714]],
  [677410, [45.9, 25.85]],
  [1260577, [17.92449, 73.8008]],
  [8993961, [11.59105, 109.04052]],
  [2979884, [47.19057, 2.42334]],
  [78964, [14.12224, 44.19797]],
  [2783924, [51.26304, 4.78652]],
  [4519005, [39.02757, -83.91965]],
  [1550222, [27.88472, 108.11361]],
  [2856139, [48.73649, 9.74844]],
  [3198582, [44.58772, 19.40967]],
  [1259686, [28.63124, 79.80436]],
  [2981901, [45.01003, 4.39612]],
  [2795551, [50.54165, 3.42605]],
  [2634388, [51.26033, -0.45563]],
  [7302845, [17.50107, 78.45818]],
  [3078309, [50.62867, 13.86419]],
  [4215110, [33.39678, -84.59576]],
  [1322167, [20.46102, 96.8415]],
  [566393, [54.00192, 35.78812]],
  [1686159, [8.3096, 123.8461]],
  [11395799, [40.02945, -5.48192]],
  [1090467, [-12.9025, 45.07611]],
  [1810997, [26.44901, 117.69027]],
  [2975853, [44.40223, -0.78337]],
  [4067534, [33.62149, -85.84413]],
  [3189229, [44.82018, 19.64128]],
  [3824935, [18.6432, -97.01366]],
  [7021130, [45.94097, 34.35262]],
  [9972945, [-33.72206, 150.92597]],
  [3627240, [7.71412, -71.46466]],
  [2160297, [-33.71667, 150.33333]],
  [3179802, [46.26424, 9.86165]],
  [498760, [53.94194, 40.68056]],
  [2504072, [36.70222, 7.84722]],
  [4235766, [39.80365, -87.67364]],
  [11477144, [11.91667, -8.36667]],
  [2970313, [49.01271, 1.96942]],
  [171200, [33.20111, 35.77917]],
  [1270642, [28.4601, 77.02635]],
  [2895791, [49.92417, 7.18874]],
  [2971678, [49.30286, 3.89595]],
  [1723498, [16.16086, 119.9988]],
  [692410, [44.9497, 34.17924]],
  [5334336, [38.61713, -121.32828]],
  [3105311, [42.6797, -0.53769]],
  [3518639, [18.04654, -97.0602]],
  [699377, [47.6629, 30.55507]],
  [2909444, [52.69688, 8.83198]],
  [2515118, [28.43231, -16.46232]],
  [3172422, [45.62057, 12.54177]],
  [676890, [45.11667, 26.06667]],
  [7012542, [42.93106, 123.53337]],
  [3070271, [49.52737, 18.75417]],
  [734310, [41.23499, 23.38899]],
  [2708584, [56.01667, 14.7]],
  [701275, [48.1, 26.1]],
  [2080970, [10.21972, 169.97967]],
  [2977611, [48.28811, -1.98715]],
  [1513038, [40.31181, 67.95667]],
  [3451100, [-15.60972, -42.53972]],
  [2053242, [40.41514, 122.66117]],
  [8394219, [42.38853, 21.72971]],
  [8347854, [-32.71894, 115.63888]],
  [5066355, [40.85973, -99.98734]],
  [7456076, [-9.6355, 119.407]],
  [1631552, [-2.0746, 120.6851]],
  [1625765, [1.15125, 127.43243]],
  [311193, [38.86194, 30.75028]],
  [11808241, [38.51919, -9.0139]],
  [3179218, [40.6462, 17.51661]],
  [7764720, [-6.5224, 105.8246]],
  [8385795, [-6.9964, 112.7812]],
  [8858987, [19.13361, -96.18139]],
  [8561903, [38.39818, 114.78759]],
  [3032914, [49.64607, 2.76933]],
  [2857756, [48.41667, 9.03333]],
  [1277992, [30.6813, 75.29087]],
  [2175818, [-33.5, 150.53333]],
  [2342933, [6.39895, 7.96155]],
  [8701054, [-12.40655, -73.90609]],
  [11704444, [-37.54131, 144.60497]],
  [2750325, [52.02917, 5.08056]],
  [3106672, [41.65518, -4.72372]],
  [3516929, [17.01449, -97.76659]],
  [1708608, [6.94833, 124.44111]],
  [3030280, [47.24067, 5.11443]],
  [2755527, [53.005, 6.76389]],
  [2658489, [47.65933, 8.85964]],
  [3182278, [45.28531, 11.78375]],
  [2745431, [52.10583, 5.01528]],
  [11592451, [55.97277, -3.76788]],
  [1693270, [9.65417, 125.6025]],
  [685112, [47.41049, 23.28904]],
  [2983559, [45.9404, 5.18559]],
  [2897572, [50.83333, 11.98333]],
  [2812204, [47.81009, 9.63863]],
  [3816474, [16.46199, -91.94523]],
  [4168138, [25.98787, -80.17477]],
  [3457484, [-19.98639, -44.42778]],
  [5146675, [41.48477, -81.92208]],
  [3207533, [49.6, 7.61667]],
  [1526273, [51.1801, 71.44598]],
  [2844588, [54.0887, 12.14049]],
  [3158444, [60.98133, 5.07497]],
  [3694725, [-8.17111, -79.00917]],
  [8023690, [-7.3158, 108.1717]],
  [254383, [39.90222, 22.54875]],
  [2876739, [51.63533, 11.90106]],
  [576697, [43.19217, 44.54313]],
  [3388618, [-8.35806, -42.24667]],
  [2957185, [52.41116, 13.5355]],
  [7035710, [-7.2293, 111.4947]],
  [2936210, [49.96667, 10.41667]],
  [3181230, [42.21952, 12.42617]],
  [8260265, [6.8275, -8.3428]],
  [2861814, [51.70662, 9.99997]],
  [3513980, [19.61063, -98.51094]],
  [2330146, [12.2388, 9.71362]],
  [324419, [36.70161, 36.48535]],
  [1785725, [22.92833, 112.03954]],
  [2991444, [43.21322, -0.65537]],
  [2356436, [12.47847, -1.20129]],
  [2905954, [48.59544, 10.26065]],
  [467479, [47.24559, 47.02814]],
  [1521425, [49.98197, 72.60761]],
  [3174341, [43.74264, 10.37513]],
  [124665, [36.29807, 59.60567]],
  [2947466, [49.52417, 8.15111]],
  [5546040, [39.92495, -111.82716]],
  [2653416, [52.09036, -1.28278]],
  [2973962, [43.78867, -0.70946]],
  [679769, [44.73333, 23.75]],
  [3001604, [45.68066, 1.07239]],
  [672456, [46.48333, 24.83333]],
  [3201748, [46.08861, 14.60083]],
  [3018248, [47.95, 3.86667]],
  [6295508, [47.3624, 8.56755]],
  [1807695, [33.43333, 115.03333]],
  [3036097, [44.72237, 3.28385]],
  [9972472, [-37.81232, 145.2681]],
  [11789668, [47.26703, 8.48539]],
  [3068751, [48.97559, 17.46751]],
  [2062259, [-32.11458, 116.0706]],
  [6534510, [45.91519, 9.94486]],
  [8452843, [35.20048, 101.78525]],
  [3105493, [42.31662, -4.54278]],
  [2826861, [53.8922, 10.64713]],
  [2284744, [5.4151, -3.03006]],
  [2642733, [51.36844, 0.36007]],
  [8863175, [18.10583, -98.07083]],
  [3191150, [45.50833, 16.30474]],
  [3802129, [18.43364, -92.8718]],
  [629159, [52.0566, 27.2161]],
  [2965509, [53.15694, -6.96667]],
  [6534431, [44.05963, 8.01495]],
  [11790338, [46.57096, 6.77835]],
  [4246939, [38.78005, -89.59814]],
  [8948916, [45.34622, 9.32168]],
  [8949062, [44.40182, 8.61541]],
  [4347242, [38.97859, -76.49184]],
  [2804158, [50.98333, 10.86667]],
  [1699347, [12.7307, 124.0189]],
  [3062328, [49.14354, 15.87755]],
  [2021980, [53.5336, 119.45269]],
  [1631998, [-8.1517, 113.4536]],
  [11748973, [33.70849, -84.13485]],
  [1268754, [31.1, 77.11667]],
  [789170, [45.23422, 20.02327]],
  [3518739, [18.83469, -98.65909]],
  [3018145, [49.07732, 6.68358]],
  [2735943, [41.14961, -8.61099]],
  [8948877, [40.82398, 14.78476]],
  [2884625, [49.38789, 9.63553]],
  [4645435, [35.53258, -86.08082]],
  [3446682, [-23.02639, -45.55528]],
  [5089449, [43.65757, -71.50035]],
  [3108285, [41.64704, 1.13957]],
  [3166214, [43.42182, 11.18364]],
  [2805095, [50.81667, 12.03333]],
  [264516, [37.90594, 23.75035]],
  [3846175, [-26.4077, -61.41367]],
  [3066446, [50.13596, 14.98633]],
  [3457454, [-20.28389, -42.34111]],
  [2769731, [48.36667, 16.51667]],
  [1649085, [4.22867, 126.79872]],
  [2334018, [10.84686, 4.09835]],
  [5168837, [40.47116, -83.89272]],
  [3522317, [19.37614, -97.62051]],
  [674885, [44.78333, 25.11667]],
  [2976305, [43.63906, -0.48659]],
  [3107546, [43.01667, -8.53333]],
  [2401495, [-0.09207, 11.93846]],
  [2968051, [48.33749, 6.33134]],
  [3125267, [41.85113, -4.40973]],
  [6534747, [45.56679, 10.50977]],
  [2765383, [48.66667, 15.06667]],
  [2021188, [54.32093, 110.30651]],
  [2930539, [50.05, 6.26667]],
  [6535729, [45.97114, 8.06688]],
  [3071381, [50.06029, 16.48207]],
  [3691886, [-9.23073, -77.62572]],
  [4160717, [26.25397, -80.12393]],
  [8330000, [-8.095, 111.8596]],
  [3087027, [50.43195, 19.43293]],
  [1220701, [38.78257, 69.87081]],
  [3179408, [42.53194, 13.87953]],
  [3995038, [21.64841, -105.11539]],
  [744404, [40.73174, 30.83992]],
  [3038680, [50.7276, 2.08989]],
  [3435765, [-27.4549, -55.02122]],
  [2644158, [51.82722, -4.17444]],
  [3069967, [49.09929, 17.58912]],
  [1283095, [28.96399, 80.17715]],
  [7570100, [-8.7733, 121.137]],
  [5288261, [34.56364, -111.85432]],
  [1328421, [16.77919, 94.73212]],
  [2973476, [50.03049, 2.32554]],
  [1153686, [8.42614, 99.50472]],
  [2915362, [54.05, 10.08333]],
  [3099048, [50.49222, 18.01994]],
  [2762525, [47.2, 14.45]],
  [2763901, [47.26667, 10.76667]],
  [4559490, [39.84674, -79.89978]],
  [3114585, [41.16655, -5.88933]],
  [3003603, [48.0021, 0.20251]],
  [7909711, [38.99593, -9.3994]],
  [1277666, [31.06062, 76.30166]],
  [1809664, [21.69052, 111.1733]],
  [2532945, [34.26479, -5.92598]],
  [2962080, [52.64306, -8.86889]],
  [2523298, [39.00618, 8.64236]],
  [1969335, [-2.8166, 120.1763]],
  [2971175, [44.30526, 1.9067]],
  [2798698, [50.29292, 5.55815]],
  [5880828, [-14.27611, -170.66389]],
  [2660086, [47.41484, 8.04979]],
  [2865553, [51.0, 9.21667]],
  [2658781, [47.08246, 8.63565]],
  [2796508, [50.44133, 5.66706]],
  [8390427, [-10.1897, 123.4417]],
  [3801665, [16.9809, -95.82362]],
  [2955389, [53.37182, 10.48793]],
  [673948, [44.7, 26.66667]],
  [3062227, [50.52027, 13.47025]],
  [2035038, [40.19081, 117.15877]],
  [11592334, [47.57728, 12.16968]],
  [8858158, [19.82917, -99.08278]],
  [1622784, [-6.917, 112.5551]],
  [461534, [56.98194, 21.55938]],
  [2972893, [48.76496, 2.3961]],
  [5517424, [36.45336, -100.53737]],
  [1246924, [7.7782, 81.6038]],
  [1516274, [44.61667, 94.91667]],
  [2657297, [54.43333, -6.0]],
  [2992885, [48.99243, 7.31075]],
  [2036427, [41.10778, 121.14167]],
  [4887205, [42.47863, -88.13759]],
  [3029776, [45.7612, 4.4992]],
  [3086654, [51.32444, 18.98506]],
  [497610, [53.36831, 34.10328]],
  [3165783, [46.34151, 11.24228]],
  [4034188, [-22.45133, -151.34191]],
  [2517259, [38.43062, -2.7331]],
  [5404198, [34.13556, -116.05417]],
  [2762375, [46.59194, 14.26917]],
  [1260553, [12.50094, 76.67416]],
  [3022242, [48.24643, -4.48993]],
  [8861969, [19.68722, -97.12583]],
  [3015904, [50.13796, 4.82545]],
  [8347924, [-33.42292, 149.53279]],
  [3204237, [44.07288, 15.28396]],
  [8403882, [30.66911, 108.2805]],
  [2971409, [46.50352, 4.88575]],
  [6545362, [60.08967, 11.47222]],
  [4011421, [32.44587, -115.11313]],
  [545272, [57.83005, 39.58989]],
  [2840403, [54.51408, 13.16655]],
  [2148896, [-37.9475, 144.71667]],
  [2650188, [51.43158, -0.55239]],
  [2892874, [48.22697, 11.47573]],
  [2958518, [53.55, 9.06667]],
  [2734363, [41.14331, -8.50033]],
  [2834257, [51.56528, 12.13279]],
  [2872602, [53.28333, 10.31667]],
  [1263649, [27.29769, 75.9495]],
  [2935095, [49.60333, 8.01056]],
  [1253628, [30.72986, 78.44342]],
  [295921, [38.96722, 27.07222]],
  [3670383, [1.99244, -76.04335]],
  [5257183, [43.34611, -88.60066]],
  [1130132, [34.64921, 70.5914]],
  [3004666, [45.54386, 2.9991]],
  [708717, [49.77149, 28.72976]],
  [1914235, [29.03028, 106.97028]],
  [3112995, [40.77125, -1.50575]],
  [1714575, [17.52268, 121.77421]],
  [3188128, [45.83222, 14.63639]],
  [2061261, [-31.98333, 115.86667]],
  [2791513, [50.88213, 4.58972]],
  [3178605, [46.09019, 13.42861]],
  [8863071, [22.99139, -102.84056]],
  [1215459, [1.48253, 99.06569]],
  [2468349, [36.32045, 9.32424]],
  [2111568, [35.51667, 140.31667]],
  [3130723, [42.66037, -4.31271]],
  [1274961, [15.17255, 73.94194]],
  [3177698, [45.0405, 8.09554]],
  [3043111, [46.81674, 19.75668]],
  [665790, [45.15564, 29.65403]],
  [680425, [47.23109, 25.39529]],
  [3080736, [49.78254, 18.66972]],
  [3106480, [42.3059, -3.25456]],
  [3182041, [45.93526, 10.29232]],
  [2518736, [38.27299, -0.5397]],
  [2660219, [47.42402, 9.08689]],
  [2435837, [8.26681, 16.93898]],
  [261561, [38.23415, 26.04535]],
  [686402, [45.01667, 23.81667]],
  [2650435, [51.12382, -0.0061]],
  [2887847, [51.15, 10.75]],
  [3529778, [16.99578, -96.43231]],
  [3021141, [48.26702, -1.14345]],
  [2636005, [54.53333, -1.3]],
  [10103762, [-33.06052, 151.58408]],
  [1690456, [7.78028, 126.42028]],
  [747311, [40.87331, 39.68829]],
  [684714, [46.75, 27.55]],
  [1724660, [10.0804, 123.4587]],
  [563458, [65.7859, 33.93992]],
  [1647910, [-8.4966, 117.4219]],
  [3514949, [21.12682, -89.26827]],
  [8950232, [45.69013, 12.53849]],
  [3585640, [13.75028, -89.84222]],
  [2995543, [43.31739, 1.24607]],
  [2509986, [39.20588, -6.06622]],
  [6308949, [-13.6826, -73.26376]],
  [3107501, [43.25753, -4.66768]],
  [2987547, [43.38503, 5.57788]],
  [477744, [52.9035, 50.9495]],
  [3514189, [16.95098, -96.74938]],
  [627878, [54.4167, 27.2958]],
  [8999489, [32.35948, 80.4268]],
  [41151, [36.8211, 49.4264]],
  [5101717, [40.48622, -74.45182]],
  [3018296, [43.23095, 3.13745]],
  [662262, [44.75358, 24.24421]],
  [3930146, [-16.46667, -71.5]],
  [2974943, [45.55602, 5.34786]],
  [2968001, [43.74328, 3.70432]],
  [2871718, [49.57167, 8.13556]],
  [1723337, [15.9528, 120.2155]],
  [3019994, [48.33449, 2.78634]],
  [4173467, [27.91697, -82.71288]],
  [2894569, [53.67787, 6.99575]],
  [8950285, [40.90081, 14.12338]],
  [7545130, [-6.71997, -76.59541]],
  [3999703, [19.7171, -104.28836]],
  [2979755, [48.59733, 2.26481]],
  [2979825, [46.07775, -0.78909]],
  [3130564, [40.34582, -3.82487]],
  [8434425, [-8.3401, 121.7409]],
  [2346719, [10.34764, 11.6099]],
  [3121030, [42.2349, 1.87716]],
  [3012449, [48.66697, 6.20269]],
  [583437, [54.8421, 46.5813]],
  [598706, [55.53727, 21.11802]],
  [3023280, [47.52666, 3.54023]],
  [3064845, [50.18311, 13.00567]],
  [282234, [31.93342, 35.29821]],
  [3126117, [40.84115, -3.36849]],
  [2766547, [47.07927, 13.69613]],
  [1791588, [36.35651, 114.93372]],
  [3064352, [48.8361, 16.15558]],
  [355795, [29.84144, 31.30084]],
  [98245, [30.39213, 47.70175]],
  [3004434, [43.64763, 3.9388]],
  [8044844, [-7.6593, 108.2721]],
  [3032067, [46.5328, 6.1375]],
  [6825495, [34.5676, 135.5974]],
  [311711, [36.79859, 28.22635]],
  [2784420, [50.76121, 4.02435]],
  [2346812, [4.4516, 7.17074]],
  [3985469, [19.25265, -100.0832]],
  [3800257, [16.87971, -95.45639]],
  [3464556, [-20.36333, -40.65917]],
  [3179034, [41.94332, 12.98169]],
  [3019297, [49.01439, 1.94425]],
  [3981387, [19.31142, -101.8603]],
  [5317734, [36.32249, -110.96348]],
  [2995722, [47.95799, 2.01462]],
  [2853471, [50.3926, 7.39251]],
  [7426809, [-8.7006, 122.1228]],
  [1311874, [21.97473, 96.08359]],
  [8861979, [17.02167, -96.69806]],
  [2642755, [53.22775, -4.16926]],
  [3017936, [48.55221, 2.95229]],
  [11424654, [13.07943, 103.24454]],
  [3020871, [49.6708, 5.04156]],
  [1716252, [14.0017, 121.3767]],
  [7038950, [-8.436, 115.1257]],
  [3518249, [16.9738, -95.76045]],
  [3504332, [19.75371, -70.82906]],
  [3083288, [52.50327, 18.71246]],
  [2987457, [44.91216, 5.10375]],
  [3099113, [54.74741, 18.37944]],
  [2971861, [47.12333, -0.78745]],
  [1694898, [11.1619, 124.005]],
  [1648759, [-8.46006, 118.72667]],
  [494993, [61.91048, 39.10332]],
  [2205310, [-12.5, 177.05]],
  [2779084, [47.8, 13.3]],
  [2751813, [53.36, 6.37917]],
  [718516, [46.88333, 21.03333]],
  [1788412, [26.33501, 118.74239]],
  [1713226, [15.3072, 120.9464]],
  [6691766, [53.45801, -2.20104]],
  [2807654, [51.4432, 10.33436]],
  [7035942, [-7.1863, 111.5437]],
  [1646953, [-7.0297, 108.1922]],
  [3173134, [46.21225, 10.34012]],
  [1521351, [41.7564, 69.3839]],
  [3461144, [-19.46833, -42.53667]],
  [682018, [47.46974, 27.19626]],
  [4677030, [33.16123, -98.38561]],
  [1266258, [24.22388, 92.67869]],
  [1862471, [35.43333, 132.81667]],
  [5785035, [41.33327, -111.82716]],
  [6535014, [45.40769, 11.4019]],
  [2994351, [47.73673, -3.23358]],
  [3446619, [-20.44222, -54.86028]],
  [1790611, [23.0445, 112.66758]],
  [2953104, [50.1, 6.53333]],
  [3470052, [-23.85444, -46.13861]],
  [2928227, [50.33333, 7.68333]],
  [1259954, [17.07701, 82.13836]],
  [1276013, [21.85523, 70.24791]],
  [2524880, [39.42713, 17.00281]],
  [690189, [46.47758, 30.55763]],
  [1640467, [1.36574, 125.07484]],
  [2815303, [49.9643, 10.12001]],
  [253077, [39.69119, 20.86519]],
  [2851322, [49.47497, 7.42023]],
  [2971799, [45.68742, 5.31843]],
  [2996917, [43.33437, -1.3175]],
  [5685767, [44.66215, -111.10411]],
  [3815723, [17.13936, -92.48287]],
  [2983215, [48.02185, 6.7872]],
  [3177972, [45.15787, 9.37215]],
  [1687687, [15.16222, 120.5675]],
  [5125973, [41.88343, -74.1132]],
  [676860, [44.18624, 26.22807]],
  [3695833, [-4.77926, -80.59285]],
  [2148336, [-27.76667, 153.06667]],
  [3190534, [46.39278, 15.57444]],
  [3008880, [45.80027, -0.95143]],
  [3188222, [45.15611, 18.39306]],
  [770540, [51.57099, 21.58161]],
  [1680505, [10.57396, 123.3933]],
  [254849, [39.3895, 22.99948]],
  [3173077, [45.13531, 10.71879]],
  [689054, [48.41909, 26.48468]],
  [8859933, [19.1375, -97.10861]],
  [3063749, [50.36494, 17.02729]],
  [1958037, [-7.7515, 114.1492]],
  [12156867, [43.68947, -79.3314]],
  [8860830, [20.23806, -99.08083]],
  [4379478, [37.12394, -92.10127]],
  [3092894, [52.51638, 15.25005]],
  [1812168, [28.76139, 106.66111]],
  [2978999, [48.55478, 3.78449]],
  [1515229, [45.33333, 97.98333]],
  [306929, [37.26542, 40.64129]],
  [3116327, [41.17434, -1.73505]],
  [3003782, [48.393, -2.07888]],
  [8413246, [39.08222, 117.32837]],
  [3116736, [40.75192, -4.66583]],
  [5293951, [33.60419, -114.52523]],
  [1610223, [16.32719, 103.29864]],
  [2997456, [47.99545, -0.15372]],
  [625665, [53.9168, 30.3449]],
  [3087584, [50.09195, 18.21928]],
  [3700085, [8.33921, -81.49823]],
  [2987989, [42.00345, 8.92066]],
  [2980098, [50.94473, 2.12433]],
  [3181224, [45.61975, 9.79843]],
  [2899194, [50.98631, 11.22311]],
  [3177753, [45.81999, 10.97315]],
  [5151613, [41.13394, -81.48456]],
  [3035304, [50.60841, 3.25251]],
  [8348145, [-23.89738, 151.27133]],
  [9610004, [54.92911, 23.88599]],
  [3861056, [-38.93392, -67.99032]],
  [7787580, [-6.80823, 106.01761]],
  [3023387, [44.55328, 2.7312]],
  [6292758, [47.25445, 8.61324]],
  [3862607, [-35.62171, -61.36169]],
  [5543307, [38.57332, -109.54984]],
  [3029925, [48.31721, -2.26054]],
  [5445612, [37.98614, -100.98933]],
  [1283217, [27.37604, 87.20767]],
  [669180, [46.42069, 22.02106]],
  [518912, [55.63052, 34.28423]],
  [4149010, [26.12564, -80.19338]],
  [5247945, [43.74191, -90.7793]],
  [3827811, [-3.76016, -73.2497]],
  [7351166, [-8.1584, 115.1246]],
  [2956090, [52.35, 11.73333]],
  [4002874, [21.10649, -104.32143]],
  [2509837, [38.3271, -6.53648]],
  [575193, [56.74712, 38.91642]],
  [2986628, [48.57875, -4.03746]],
  [7086994, [-8.4335, 115.5012]],
  [1174456, [32.26, 72.5]],
  [4797975, [39.5037, -80.16675]],
  [2644386, [53.46993, -2.99809]],
  [3729548, [19.69142, -73.33769]],
  [2976805, [45.94772, 4.21318]],
  [1724350, [14.02074, 120.75595]],
  [3098854, [50.208, 19.62038]],
  [3186959, [46.21408, 15.15954]],
  [5733582, [45.39123, -122.61259]],
  [5124069, [40.85677, -73.46957]],
  [5211230, [40.25621, -75.46379]],
  [8529629, [25.26222, -97.43611]],
  [2942341, [52.60242, 13.43019]],
  [283245, [31.94508, 35.07194]],
  [3014580, [46.01008, 6.16983]],
  [3996931, [21.68471, -105.33091]],
  [3345404, [51.33444, -2.59472]],
  [7870309, [45.92038, 11.02087]],
  [3174508, [43.7731, 12.93995]],
  [672527, [45.5361, 27.55226]],
  [3930944, [-10.18191, -76.80332]],
  [1784055, [39.70083, 122.99111]],
  [2266648, [39.53622, -8.3118]],
  [733796, [40.9567, 24.14806]],
  [8859044, [17.73611, -99.17722]],
  [2981305, [47.22655, 0.77306]],
  [284882, [32.25134, 35.062]],
  [676337, [47.60375, 26.45437]],
  [701669, [45.1133, 36.23992]],
  [2705562, [59.66667, 16.58333]],
  [663642, [45.0256, 25.0123]],
  [2463711, [39.69472, 19.83667]],
  [2841484, [54.30363, 8.64138]],
  [1811762, [29.12443, 118.94559]],
  [2823504, [48.12952, 7.81205]],
  [2996398, [44.04687, 6.12557]],
  [2712091, [63.31667, 18.71667]],
  [3446866, [-11.84722, -40.79139]],
  [1280539, [32.26974, 96.82032]],
  [709776, [47.52886, 29.92651]],
  [2659234, [46.61077, 6.52519]],
  [1810309, [32.78933, 119.44182]],
  [532675, [58.10861, 57.80528]],
  [12156890, [43.68417, -79.29936]],
  [8859414, [19.75635, -97.32161]],
  [1215510, [2.3328, 99.0657]],
  [5386834, [37.48522, -122.23635]],
  [3460825, [-17.85722, -42.85889]],
  [3025862, [46.20727, 0.16412]],
  [293094, [31.70927, 34.94488]],
  [4748050, [37.37847, -79.80698]],
  [3823475, [19.36758, -97.17093]],
  [2513703, [39.54555, -0.39551]],
  [8859555, [20.00295, -97.85408]],
  [2869175, [49.69667, 8.02611]],
  [2912534, [52.43367, 9.32473]],
  [3529884, [18.23947, -93.12767]],
  [725712, [43.21, 23.5625]],
  [5862522, [59.85833, -150.95833]],
  [8949299, [45.77027, 9.29848]],
  [2652207, [56.11194, -3.34426]],
  [2928700, [51.97915, 11.41268]],
  [700835, [45.00354, 34.05634]],
  [2655802, [53.03439, -2.36865]],
  [1269377, [22.19644, 78.59191]],
  [2780452, [47.02156, 15.05266]],
  [1850589, [31.48333, 130.7]],
  [665239, [45.52495, 26.53781]],
  [3816085, [16.98376, -92.93804]],
  [3522215, [18.40855, -99.78759]],
  [2646444, [51.53778, -2.15163]],
  [2511495, [37.4206, -6.11049]],
  [1786531, [36.18866, 106.94127]],
  [2509836, [39.71866, -2.22135]],
  [4815200, [39.46258, -80.21814]],
  [8950217, [39.22837, 16.06131]],
  [1853008, [34.88444, 135.22694]],
  [2661068, [46.95577, 7.01855]],
  [3833412, [-31.23073, -64.31615]],
  [3219325, [45.1661, 12.27355]],
  [2819727, [50.96651, 10.16028]],
  [2929650, [48.57654, 12.17102]],
  [2638009, [53.57236, -2.69316]],
  [1710612, [7.78444, 122.58611]],
  [3415021, [64.16667, -21.7]],
  [5208028, [41.32646, -77.75082]],
  [2029840, [49.145, 105.38833]],
  [2944529, [48.23782, 10.29951]],
  [2033661, [40.83464, 120.41781]],
  [2685903, [60.2, 17.9]],
  [1176206, [31.82098, 73.91815]],
  [956767, [-26.55, 29.16667]],
  [3035296, [44.71238, 4.76403]],
  [2789873, [51.14228, 5.14999]],
  [1703943, [12.41014, 121.08991]],
  [1724094, [16.00022, 120.15904]],
  [4889107, [41.55475, -88.09867]],
  [8861266, [19.39361, -100.17667]],
  [3456102, [-17.89194, -39.37194]],
  [473788, [57.21276, 41.93012]],
  [2950177, [50.04416, 6.33991]],
  [2774988, [47.78333, 12.95]],
  [1792453, [38.89967, 117.14774]],
  [4173431, [29.16582, -81.0045]],
  [5019124, [45.48524, -92.96188]],
  [2862733, [50.06149, 10.1827]],
  [1176022, [32.29016, 72.28182]],
  [2026544, [53.153, 103.7714]],
  [3031567, [43.23572, -0.28233]],
  [1641978, [-5.73805, 105.5916]],
  [502185, [46.5614, 43.6501]],
  [5761937, [44.31123, -124.10484]],
  [928534, [-13.03333, 33.48333]],
  [8862264, [20.59778, -101.37472]],
  [2758587, [51.42333, 3.73472]],
  [3600931, [15.77425, -87.46731]],
  [6824644, [24.87181, 103.05114]],
  [535121, [52.60311, 39.57076]],
  [1786441, [29.23597, 119.96089]],
  [4885597, [41.81892, -87.69894]],
  [8863057, [18.245, -93.115]],
  [566891, [56.24283, 37.51769]],
  [3064483, [50.18131, 14.05027]],
  [2562529, [35.86278, 14.5075]],
  [2187365, [-41.25, 173.1]],
  [1895356, [33.04758, 112.3351]],
  [4358671, [38.53262, -76.78386]],
  [8170724, [-8.2986, 112.6602]],
  [3822745, [20.9897, -87.20224]],
  [2413070, [13.66667, -15.03333]],
  [660065, [63.36667, 23.48333]],
  [2997904, [48.83541, 2.62998]],
  [728928, [42.6, 25.55]],
  [1608239, [14.34062, 100.86733]],
  [2027468, [44.15254, 133.27791]],
  [9972607, [-33.97707, 151.13437]],
  [3167595, [44.33456, 9.21204]],
  [733776, [40.79194, 22.4075]],
  [3017259, [47.54858, -1.12274]],
  [1809543, [32.3646, 120.16336]],
  [8199693, [44.94369, 9.27504]],
  [8379136, [46.51606, 6.33018]],
  [8554977, [41.67494, 112.91109]],
  [588780, [59.32258, 24.54967]],
  [12076672, [49.13631, -121.94244]],
  [2771917, [47.15, 13.75]],
  [8480045, [41.63776, -74.26027]],
  [3033099, [43.36138, 3.4219]],
  [6610785, [52.41748, 85.01768]],
  [669415, [48.11367, 26.83433]],
  [8949829, [43.85741, 10.83345]],
  [6535363, [45.66558, 9.43345]],
  [2971357, [43.48546, -1.33693]],
  [6534928, [45.94018, 9.27124]],
  [2754978, [52.00074, 4.77639]],
  [2548830, [32.50213, -6.68771]],
  [2395127, [9.19916, 2.25855]],
  [8348426, [-32.89368, 151.69009]],
  [2977455, [49.39626, 0.36636]],
  [4218896, [34.91591, -85.10912]],
  [3174245, [46.22369, 13.19483]],
  [677465, [47.01667, 26.81667]],
  [1998309, [34.36606, 109.36771]],
  [2968075, [49.12215, -0.1991]],
  [2783710, [50.50862, 3.53468]],
  [3016319, [44.34993, 3.95023]],
  [1607055, [13.68219, 100.50603]],
  [2822958, [48.28334, 10.46917]],
  [7429873, [-8.7105, 122.1502]],
  [1729604, [9.62639, 123.89417]],
  [4828862, [39.94734, -75.06656]],
  [8862094, [17.82885, -98.94934]],
  [2261698, [39.55271, -8.9979]],
  [8862677, [16.94756, -92.3866]],
  [2525483, [37.08604, 14.88499]],
  [4004834, [25.96007, -100.0152]],
  [2984249, [48.66536, 6.83884]],
  [2780614, [48.33634, 14.20541]],
  [7073463, [5.02309, 7.53895]],
  [2398283, [0.78205, 11.54904]],
  [3037748, [45.24858, 4.8086]],
  [8862702, [19.27083, -99.71194]],
  [2771053, [47.09893, 13.49756]],
  [3521972, [21.29598, -88.26982]],
  [3529508, [20.31647, -88.43064]],
  [3527450, [26.22316, -99.48503]],
  [1703792, [7.10017, 125.11548]],
  [724294, [49.02173, 20.59212]],
  [4093619, [32.91568, -86.71582]],
  [3173519, [40.37139, 16.81122]],
  [300058, [39.91361, 28.15778]],
  [2515064, [38.9497, -2.35419]],
  [1978291, [7.01556, 124.51417]],
  [1700068, [15.6199, 120.377]],
  [313617, [39.79382, 38.67297]],
  [667763, [46.68333, 23.45]],
  [677752, [44.66191, 25.53448]],
  [3500243, [19.31102, -69.5428]],
  [8377709, [-10.5822, 121.8062]],
  [312355, [39.55833, 27.09833]],
  [1517880, [42.48578, 70.29601]],
  [5192391, [40.28204, -75.64324]],
  [251280, [37.78022, 20.89555]],
  [3191369, [44.98333, 15.47806]],
  [2952452, [52.71009, 8.50256]],
  [2762097, [48.23828, 14.22996]],
  [8379137, [46.55798, 6.33551]],
  [2996845, [43.55084, -0.05808]],
  [2786452, [51.17737, 2.93901]],
  [3064104, [49.21492, 15.88166]],
  [2661755, [46.72754, 7.58247]],
  [3194498, [44.85017, 19.82321]],
  [3529609, [18.95508, -98.75198]],
  [2884845, [52.30701, 10.52834]],
  [684561, [45.93575, 27.65063]],
  [665803, [46.90368, 27.25849]],
  [2261694, [38.33458, -8.0044]],
  [4474316, [36.28069, -80.35922]],
  [3666502, [4.70444, -75.74028]],
  [283579, [32.17326, 35.03551]],
  [2921252, [52.56667, 7.08333]],
  [3193424, [45.61278, 18.53528]],
  [3699502, [-4.63983, -79.71491]],
  [3114950, [40.55, -1.65]],
  [8080621, [-8.3418, 112.5618]],
  [2649817, [51.15448, -0.44344]],
  [667950, [44.41667, 26.65]],
  [3826986, [19.65165, -99.98546]],
  [2750947, [52.69583, 6.19444]],
  [8347970, [-26.65712, 152.8641]],
  [703915, [47.95927, 29.20273]],
  [2754681, [52.38723, 6.04016]],
  [4530396, [35.30868, -97.02558]],
  [7570551, [-8.4781, 120.5121]],
  [3097688, [52.75328, 18.11328]],
  [3044760, [46.35014, 18.70905]],
  [3112794, [40.1, -0.58333]],
  [3816076, [16.837, -92.95152]],
  [8019846, [53.7817, 27.4346]],
  [2665093, [63.90676, 19.75712]],
  [2832264, [53.38742, 11.93802]],
  [8034375, [33.07237, 119.445]],
  [3020620, [45.79621, 3.05156]],
  [1668467, [24.88373, 121.29043]],
  [4024732, [20.99111, -100.29317]],
  [726872, [42.26667, 23.11667]],
  [4166105, [25.84621, -80.15394]],
  [4374054, [39.32288, -76.72803]],
  [3179504, [41.14066, 14.70069]],
  [4151440, [28.38612, -80.742]],
  [3201558, [46.15333, 16.28472]],
  [3313832, [53.17417, -7.72556]],
  [3834985, [-29.3847, -63.46907]],
  [3072176, [49.88376, 16.94122]],
  [3128017, [41.82564, 0.20084]],
  [7579544, [-8.0357, 111.9937]],
  [4693929, [31.37767, -97.08278]],
  [3022152, [43.27607, 5.69955]],
  [3043758, [47.12209, 19.93714]],
  [667103, [47.23202, 23.6237]],
  [1859924, [35.86224, 139.97732]],
  [3526733, [18.51204, -99.88755]],
  [3198045, [45.88278, 15.69242]],
  [2523028, [37.76653, 14.35075]],
  [678323, [46.63671, 22.5258]],
  [8860543, [15.82222, -91.92056]],
  [264408, [36.55, 26.35]],
  [2782347, [48.07234, 14.75395]],
  [308203, [37.82388, 34.57145]],
  [301539, [38.07944, 31.36639]],
  [662709, [45.15, 27.28333]],
  [1265983, [24.7526, 80.77751]],
  [3817525, [18.94574, -99.89535]],
  [685745, [44.88389, 22.83333]],
  [2750965, [53.21797, 5.66124]],
  [534245, [52.4666, 34.8163]],
  [669013, [47.21667, 27.5]],
  [664938, [47.95, 23.35]],
  [1686547, [14.21567, 120.97137]],
  [1711287, [10.71565, 122.2657]],
  [3104443, [41.07201, -4.23905]],
  [5092338, [43.48924, -71.5823]],
  [675231, [44.33333, 26.07806]],
  [6292027, [47.27942, 8.86162]],
  [3180176, [45.35238, 9.37088]],
  [2928738, [49.82896, 10.00588]],
  [8390520, [-10.3157, 123.6204]],
  [2346954, [7.75103, 4.2298]],
  [733854, [40.37184, 23.5359]],
  [4003810, [20.50888, -103.17025]],
  [56414, [3.3766, 45.5996]],
  [3171874, [42.71924, 12.11249]],
  [712160, [46.19484, 30.34827]],
  [1810988, [29.37639, 117.24667]],
  [3037504, [47.07726, 4.0994]],
  [3801842, [16.68029, -96.63853]],
  [7306512, [4.58199, 168.69636]],
  [3156862, [61.21581, 10.28498]],
  [3823777, [18.78309, -96.67588]],
  [2636216, [57.12793, -2.8604]],
  [2893524, [50.60619, 10.1564]],
  [7083162, [-8.1848, 113.727]],
  [2887385, [50.16667, 7.2]],
  [3837124, [-30.78913, -60.59189]],
  [1277053, [26.38109, 84.58648]],
  [3063502, [50.07394, 14.7308]],
  [921838, [-12.17139, 44.44167]],
  [670178, [46.31667, 27.56667]],
  [2864549, [49.28028, 11.46278]],
  [2797144, [50.87448, 5.13141]],
  [2512578, [37.30337, -6.30097]],
  [6355010, [39.5247, 2.53922]],
  [8861271, [17.16778, -100.42861]],
  [1862389, [34.66667, 136.46667]],
  [3026588, [48.3722, 6.29117]],
  [2743493, [51.8175, 4.63333]],
  [8948852, [43.25385, 13.68319]],
  [3824441, [20.62724, -98.35042]],
  [1684245, [9.19238, 117.8141]],
  [3168464, [45.81258, 10.36962]],
  [1091159, [-12.23944, 44.50528]],
  [3090759, [49.90746, 19.21646]],
  [712772, [45.76549, 29.47893]],
  [3711223, [7.72571, -80.64349]],
  [3629385, [9.9795, -63.21991]],
  [4340551, [29.94687, -90.32313]],
  [683681, [46.85632, 24.75941]],
  [2295840, [5.43385, -2.14295]],
  [3993519, [19.98518, -101.76096]],
  [439208, [36.90139, 40.13111]],
  [2797209, [50.60765, 5.81108]],
  [2797694, [50.61173, 4.45152]],
  [3180970, [44.68874, 10.84705]],
  [6534319, [44.94004, 7.05241]],
  [4175117, [25.67149, -80.4445]],
  [666351, [45.39065, 24.40389]],
  [715904, [47.4, 21.63333]],
  [3012921, [43.23339, 0.00123]],
  [2946015, [53.83333, 9.43333]],
  [2851320, [49.58444, 8.12639]],
  [4926449, [41.75422, -85.975]],
  [6691003, [53.31467, -6.23148]],
  [3165168, [44.5073, 7.75637]],
  [3174637, [40.14926, 9.21873]],
  [301566, [38.98349, 33.84136]],
  [11694974, [48.16042, 14.00834]],
  [8307061, [28.94645, 115.06842]],
  [2649428, [52.33917, -0.65008]],
  [3762329, [-5.69111, -78.80076]],
  [667172, [44.04341, 23.47511]],
  [7406942, [35.60058, 102.94029]],
  [8861693, [28.11583, -105.34306]],
  [3981704, [22.37209, -103.92618]],
  [3036597, [43.11667, -0.61667]],
  [4237579, [38.6245, -90.15094]],
  [494934, [56.52764, 38.07518]],
  [728321, [42.05, 24.55]],
  [2850420, [54.84568, 8.2933]],
  [2309528, [3.45683, 8.55465]],
  [2522892, [39.45358, 17.03632]],
  [4241302, [39.85365, -89.24203]],
  [2791085, [50.54802, 5.18641]],
  [1264850, [29.40356, 80.08965]],
  [7645768, [8.1435, 140.394]],
  [2649542, [55.65823, -4.44342]],
  [2635111, [53.97907, -1.15204]],
  [3165501, [45.58283, 8.71493]],
  [3027950, [44.85614, -0.53355]],
  [4744791, [37.68403, -78.90113]],
  [578339, [53.79605, 38.25498]],
  [7628535, [33.00452, 106.93466]],
  [7257804, [41.34504, -72.12948]],
  [3994216, [21.86614, -101.59269]],
  [2661750, [46.63565, 8.59388]],
  [7089115, [-8.5675, 120.6568]],
  [2974710, [46.99923, 5.15138]],
  [8949312, [43.63832, 10.64896]],
  [3821847, [17.60598, -99.25143]],
  [2918168, [53.92092, 11.3174]],
  [7601762, [1.32924, 172.97522]],
  [3005401, [44.13353, 0.66026]],
  [6534752, [45.71289, 10.46137]],
  [247015, [32.57102, 35.68984]],
  [1595865, [15.71668, 103.22781]],
  [8949064, [37.90217, 12.88788]],
  [2967477, [50.24732, 2.86945]],
  [3027519, [47.80308, 5.42797]],
  [2350476, [11.67403, 9.14059]],
  [3822009, [16.67698, -99.0162]],
  [1701290, [16.0248, 120.4547]],
  [2978413, [49.05266, -1.1697]],
  [4758495, [37.3021, -78.39194]],
  [7377904, [-8.4152, 117.1311]],
  [665168, [44.88333, 23.4]],
  [2990606, [48.1281, -2.98243]],
  [11592154, [56.40469, -3.0106]],
  [2976679, [47.52438, 1.93276]],
  [2337235, [8.27445, 5.83528]],
  [2766299, [47.7894, 16.11866]],
  [5067037, [41.72111, -96.88281]],
  [685185, [46.73333, 24.8]],
  [687014, [50.98016, 25.71931]],
  [261132, [38.23697, 23.21411]],
  [1815510, [39.44333, 117.07056]],
  [3817987, [19.58472, -99.8875]],
  [3622751, [10.09866, -84.37824]],
  [3087307, [51.60946, 16.85852]],
  [3705691, [8.47696, -80.32499]],
  [5015618, [42.91336, -85.70531]],
  [2158221, [-33.67464, 150.27881]],
  [1497095, [55.63, 60.7919]],
  [3065478, [50.0849, 15.76343]],
  [4952292, [42.10843, -72.07869]],
  [3125534, [41.42482, -3.60577]],
  [3098201, [53.9165, 15.20027]],
  [8862414, [20.60306, -98.78472]],
  [1928277, [28.57652, 109.63586]],
  [3025854, [46.36066, -0.37753]],
  [3176640, [45.8918, 12.32992]],
  [4833276, [42.73008, -73.70123]],
  [2960164, [49.5625, 6.00889]],
  [1511057, [57.01861, 82.07111]],
  [3117726, [42.14464, -3.67571]],
  [5494507, [36.70029, -106.54976]],
  [2512837, [39.6, -0.71667]],
  [616184, [40.06952, 44.07788]],
  [6355015, [39.5111, 2.5353]],
  [3169612, [45.79733, 8.57105]],
  [2997893, [48.14686, 1.2649]],
  [1857594, [34.66667, 131.85]],
  [2155085, [-17.67183, 141.07721]],
  [3074398, [50.39077, 17.00272]],
  [2518591, [37.69314, -6.31503]],
  [6534948, [45.58577, 8.94134]],
  [8309686, [22.82968, 105.49334]],
  [3164331, [46.49904, 12.47111]],
  [8323118, [-8.1438, 111.782]],
  [1266649, [24.07964, 91.59972]],
  [6535477, [44.53874, 8.19394]],
  [1811204, [31.87527, 109.70222]],
  [3163827, [45.7777, 12.11857]],
  [2917014, [51.20419, 12.69848]],
  [2802537, [50.94435, 3.86497]],
  [1634688, [-8.3031, 111.5786]],
  [3515163, [18.70025, -99.18357]],
  [4776024, [37.08339, -76.46965]],
  [8457359, [35.98441, 101.86352]],
  [671118, [44.68472, 26.01389]],
  [3531046, [21.26967, -89.74006]],
  [2789899, [50.67015, 4.21984]],
  [4134132, [35.37981, -90.25787]],
  [8349205, [-33.51983, 150.88631]],
  [2640238, [51.08168, -1.69868]],
  [8058035, [-6.7739, 111.1855]],
  [2034142, [46.74103, 120.30775]],
  [2661567, [46.19278, 9.01703]],
  [3997231, [19.9781, -102.67954]],
  [2711803, [62.04874, 17.25858]],
  [3091894, [50.44504, 19.12934]],
  [5473083, [32.11318, -103.19351]],
  [2641430, [52.25, -0.88333]],
  [7380619, [-8.5513, 121.9042]],
  [2966406, [54.5, -8.18333]],
  [8862458, [19.79694, -97.39417]],
  [4026082, [17.97066, -102.22124]],
  [3167352, [44.69864, 7.93733]],
  [3531628, [20.46998, -89.08197]],
  [4621447, [36.00035, -84.88634]],
  [8581466, [8.08404, -77.53507]],
  [3519490, [19.38628, -99.63748]],
  [3713416, [7.95756, -81.38633]],
  [2919792, [48.5, 10.48333]],
  [1709647, [5.72333, 125.49861]],
  [3179361, [44.94861, 12.33917]],
  [3125560, [42.72526, -5.02568]],
  [685192, [44.79093, 25.81702]],
  [2654143, [50.99003, 0.13441]],
  [11903600, [48.2611, 13.05418]],
  [2787650, [50.52882, 3.58635]],
  [2963025, [54.27194, -8.59333]],
  [3164074, [43.78133, 10.92365]],
  [3351014, [-12.01667, 17.48333]],
  [5854496, [20.89133, -156.50604]],
  [3075914, [50.48753, 16.02932]],
  [3169094, [41.92745, 15.88217]],
  [7741548, [29.71785, 114.34465]],
  [2820099, [49.71667, 7.75]],
  [2989344, [49.89327, 4.01644]],
  [6533368, [-22.00163, 148.04661]],
  [1785533, [35.84278, 104.1125]],
  [5004961, [42.3281, -85.56973]],
  [3529907, [16.06196, -97.18586]],
  [3459869, [-27.17806, -51.50472]],
  [3599788, [14.48585, -90.80437]],
  [3195415, [46.28889, 16.18306]],
  [8861886, [19.15667, -97.03389]],
  [2745189, [53.07417, 6.57778]],
  [8948756, [37.54097, 15.09645]],
  [3117856, [42.03569, -6.90541]],
  [3025942, [49.81699, 2.80064]],
  [3471910, [-23.55083, -51.46083]],
  [1708341, [12.77732, 121.46922]],
  [1817898, [25.46519, 119.84388]],
  [5952370, [49.49996, -115.06871]],
  [3086291, [50.06871, 19.64596]],
  [2827552, [53.5922, 9.9287]],
  [8858991, [14.86596, -92.29714]],
  [3130529, [41.21769, -6.61786]],
  [5491288, [36.78555, -108.68703]],
  [3097480, [51.75142, 15.01419]],
  [2268326, [39.62071, -8.65237]],
  [686658, [45.13333, 25.61667]],
  [667053, [47.6066, 23.69513]],
  [7089361, [-8.8667, 121.2864]],
  [770762, [49.81716, 20.59018]],
  [4165419, [27.8953, -81.97342]],
  [4011703, [20.16188, -102.1934]],
  [2155386, [-33.89835, 151.17754]],
  [5184966, [40.18566, -75.45157]],
  [264421, [37.93955, 22.82223]],
  [2525573, [38.04653, 13.39207]],
  [3077974, [49.51123, 15.17836]],
  [3009534, [46.02033, 0.14661]],
  [7931936, [45.68879, 9.12832]],
  [3164406, [44.78557, 10.262]],
  [3095241, [49.98346, 19.79324]],
  [2291779, [7.67395, -5.58085]],
  [696966, [49.2678, 25.12766]],
  [8403846, [30.857, 108.00592]],
  [3116981, [43.21337, -7.29372]],
  [4024255, [20.57722, -101.15806]],
  [4003675, [20.71277, -100.45568]],
  [5015701, [42.81252, -86.01865]],
  [1633904, [-9.5373, 124.3596]],
  [4379611, [38.76477, -90.31373]],
  [3661980, [-8.16139, -70.76556]],
  [4558020, [39.91481, -77.90472]],
  [1336137, [22.70497, 90.37013]],
  [2894335, [50.38333, 7.73333]],
  [7035536, [-8.0743, 112.3798]],
  [174186, [36.51194, 36.86954]],
  [546606, [55.59769, 37.1695]],
  [1271934, [29.51525, 75.45554]],
  [587384, [40.05015, 47.45937]],
  [2738399, [40.4762, -7.91793]],
  [2660902, [46.82107, 8.40133]],
  [8861549, [18.17778, -100.33333]],
  [5474539, [36.73417, -108.3598]],
  [1921288, [24.97111, 118.16028]],
  [665578, [44.46667, 26.55]],
  [3617448, [13.63208, -86.47516]],
  [9972855, [-33.85693, 151.09124]],
  [545673, [56.7, 36.76667]],
  [3567597, [20.37417, -76.64361]],
  [3021999, [45.70677, 0.84974]],
  [3465330, [-26.42528, -49.24306]],
  [3113317, [40.99274, -0.85441]],
  [2852729, [50.85762, 12.42309]],
  [2895177, [52.65535, 11.89579]],
  [1639523, [0.81217, 124.65546]],
  [2895910, [49.3, 10.16667]],
  [3018377, [44.82746, 0.03626]],
  [8469157, [43.782, 11.16958]],
  [667972, [46.8802, 25.45462]],
  [2909220, [54.55, 9.0]],
  [1708449, [8.94722, 125.35694]],
  [3947019, [-13.15878, -74.22321]],
  [2763061, [47.84467, 13.22762]],
  [1790430, [28.10304, 111.95409]],
  [3064211, [49.34872, 16.42438]],
  [3063611, [48.90082, 16.06351]],
  [3137346, [60.21611, 5.38722]],
  [3113804, [40.15596, -6.68192]],
  [8514020, [30.06931, 113.54975]],
  [3801125, [17.95888, -98.06852]],
  [3061833, [49.4741, 18.04436]],
  [6824989, [24.91265, 102.79156]],
  [2866626, [50.26667, 6.86667]],
  [3030708, [48.82495, 7.48117]],
  [1680092, [10.3349, 123.3259]],
  [2513674, [39.36667, -0.6]],
  [3517458, [18.54915, -99.42467]],
  [2953459, [51.35609, 11.09977]],
  [2656848, [52.57536, -1.54693]],
  [3351380, [-13.73333, 15.06667]],
  [8859452, [32.54194, -116.63611]],
  [3010782, [44.10521, -0.5452]],
  [1791108, [30.73695, 112.20075]],
  [3012339, [48.20172, 6.488]],
  [2785296, [50.69544, 5.72604]],
  [1170219, [24.73701, 69.79707]],
  [8068197, [-6.7328, 111.4675]],
  [4226586, [33.86762, -82.74125]],
  [2981536, [49.32787, -0.38696]],
  [2798649, [50.85547, 3.81444]],
  [9534523, [9.75129, 105.53448]],
  [2154918, [-28.78876, 153.28035]],
  [2645291, [55.38561, -3.99836]],
  [4675577, [33.57733, -96.17831]],
  [3009125, [46.73333, -1.51667]],
  [2975608, [45.29653, 4.69218]],
  [8859362, [22.36528, -101.06556]],
  [3022981, [48.64947, 2.96116]],
  [1703598, [8.1183, 123.4455]],
  [2874039, [51.09083, 9.53889]],
  [2895891, [48.07034, 9.17998]],
  [2819497, [48.57434, 13.66725]],
  [2516865, [37.25759, -3.59691]],
  [2339139, [7.84598, 5.18314]],
  [3696557, [-8.65, -77.08333]],
  [2647924, [52.23442, -0.71411]],
  [1501706, [53.9599, 81.2093]],
  [4018499, [25.36586, -108.16107]],
  [3445713, [-14.52472, -49.14083]],
  [3366880, [-34.4187, 19.23446]],
  [2189272, [-42.41667, 173.68333]],
  [1799477, [28.90483, 109.3245]],
  [2790235, [51.03531, 3.54574]],
  [8863073, [19.30611, -89.63444]],
  [2976180, [42.36993, 9.51443]],
  [2633767, [51.02308, -0.50604]],
  [4010903, [25.88368, -109.29091]],
  [757178, [51.22823, 20.86106]],
  [4717921, [29.82523, -95.99496]],
  [3093156, [50.93079, 18.9909]],
  [3056380, [47.68991, 16.51658]],
  [8421765, [-8.2715, 124.4485]],
  [3028217, [47.80995, -3.34255]],
  [1694252, [13.1773, 123.7301]],
  [2785957, [50.86801, 3.78797]],
  [1525504, [51.52983, 77.47146]],
  [8862808, [17.21583, -93.14306]],
  [5288439, [33.78477, -110.02649]],
  [2770415, [46.62444, 13.64694]],
  [2775743, [48.77195, 15.02857]],
  [4738207, [32.45762, -99.87148]],
  [3194926, [42.96044, 19.5833]],
  [2154616, [-37.9, 145.11667]],
  [164898, [36.13865, 36.45244]],
  [3529628, [19.60328, -88.41566]],
  [672654, [44.63333, 26.98333]],
  [2992945, [44.47807, 2.2298]],
  [516342, [53.125, 48.85306]],
  [2646980, [50.9673, -3.99425]],
  [678771, [44.08995, 25.10771]],
  [2960200, [49.59639, 6.26]],
  [2982383, [50.39247, 2.90396]],
  [1150221, [8.51625, 98.63972]],
  [2991883, [45.29114, 4.99939]],
  [5020281, [44.50691, -92.90548]],
  [173550, [32.90871, 35.63199]],
  [5117575, [40.71621, -73.85014]],
  [2511705, [37.96754, -7.21889]],
  [686571, [45.03333, 23.6]],
  [1488190, [50.9276, 84.761]],
  [2864435, [49.34449, 7.18045]],
  [2525405, [37.35842, 13.84786]],
  [1792768, [25.7335, 115.51137]],
  [2687700, [58.753, 17.00788]],
  [3994482, [19.79654, -102.22169]],
  [2769324, [47.2, 10.9]],
  [1256483, [15.23352, 75.57996]],
  [1816873, [32.10914, 119.87432]],
  [2328655, [5.25704, 7.57569]],
  [751103, [41.5011, 36.06887]],
  [3167465, [45.52031, 12.01862]],
  [1508350, [54.9749, 60.3633]],
  [2747256, [51.1175, 5.89861]],
  [1636912, [-7.0042, 112.2807]],
  [3526646, [21.00952, -99.19134]],
  [8861811, [20.05444, -97.09111]],
  [3821530, [18.38891, -99.12529]],
  [3019355, [49.70669, 0.20523]],
  [5035635, [45.97469, -94.86558]],
  [4889447, [41.75198, -87.97395]],
  [1809112, [39.80382, 113.18501]],
  [3607053, [15.35, -87.06667]],
  [4761127, [37.27402, -80.13393]],
  [677003, [45.0, 22.96667]],
  [8949211, [45.60599, 11.58856]],
  [1816160, [30.56442, 115.40679]],
  [11903707, [47.38075, 15.89004]],
  [3728386, [19.37795, -71.8303]],
  [2319450, [6.78481, 9.06799]],
  [6535200, [45.53218, 10.36917]],
  [2995503, [46.53813, -0.88129]],
  [1631851, [-6.6978, 110.7107]],
  [105343, [21.49012, 39.18624]],
  [7409843, [-8.6378, 116.3891]],
  [3673041, [7.43637, -72.63808]],
  [1134724, [31.71382, 61.62272]],
  [8858596, [28.14028, -105.52667]],
  [5121283, [42.32785, -77.6611]],
  [3321853, [48.26417, 14.26583]],
  [8219779, [-27.77992, 153.36153]],
  [5445780, [37.94058, -101.25489]],
  [10194293, [12.5592, 6.89459]],
  [99738, [32.0643, 45.24743]],
  [2930500, [48.18333, 11.28333]],
  [6535359, [45.74428, 9.37445]],
  [2887691, [50.65, 10.11667]],
  [1790840, [23.48333, 111.31667]],
  [3035065, [44.27655, 2.43184]],
  [4577532, [33.78958, -81.92956]],
  [2979251, [44.0227, 3.36009]],
  [5272873, [44.78221, -88.60899]],
  [4556681, [39.76119, -79.98145]],
  [3073254, [49.59947, 18.1448]],
  [11594661, [47.51813, 15.76006]],
  [7257640, [43.443, -71.59364]],
  [3801261, [18.24285, -96.40165]],
  [6535615, [44.11495, 10.4585]],
  [7005571, [42.66854, 123.75587]],
  [3691491, [-8.74703, -78.52241]],
  [5346551, [37.63826, -120.90215]],
  [8405734, [30.81436, 107.77436]],
  [785058, [41.71556, 21.77556]],
  [3112792, [40.88794, -3.21624]],
  [6373958, [-7.56778, 109.23222]],
  [2349797, [12.11347, 5.92681]],
  [593063, [55.59678, 26.43984]],
  [2660170, [46.97586, 7.1957]],
  [8550086, [20.34803, 105.22881]],
  [2661361, [47.42173, 8.63263]],
  [4012045, [20.46702, -102.14976]],
  [7116073, [-35.50474, 138.7732]],
  [3529982, [18.8106, -98.93525]],
  [3460773, [-14.95083, -49.54944]],
  [1787426, [31.0897, 108.55237]],
  [3012577, [45.55763, 5.91484]],
  [2976586, [46.74091, -1.08667]],
  [4021182, [21.88136, -102.46454]],
  [1816670, [39.9075, 116.39723]],
  [8053041, [-7.412, 108.3918]],
  [2795714, [50.20076, 5.49478]],
  [659735, [60.21948, 24.61684]],
  [3180927, [43.8131, 7.62829]],
  [580420, [51.87185, 45.00775]],
  [7289682, [52.82507, -3.30225]],
  [1626478, [-3.3049, 116.1779]],
  [5342750, [34.59082, -118.15257]],
  [662200, [45.09156, 24.68016]],
  [3623486, [10.00236, -84.11651]],
  [2779504, [46.93861, 13.13444]],
  [3936913, [-16.40069, -73.22239]],
  [2996423, [46.30178, 2.78218]],
  [8859021, [22.22778, -100.96417]],
  [2223162, [4.6, 11.25]],
  [2890795, [48.29564, 7.8251]],
  [7334542, [-8.3847, 114.2778]],
  [1887762, [29.87778, 121.99194]],
  [3653287, [-1.3299, -78.54341]],
  [6822212, [35.16349, 136.73222]],
  [4150727, [28.14862, -82.51454]],
  [8403148, [-6.9072, 113.8825]],
  [11612345, [33.9754, 133.54895]],
  [3007262, [47.13718, -2.19172]],
  [3170829, [43.07873, 11.67671]],
  [2945051, [47.92957, 8.44806]],
  [2996827, [49.07758, 2.30768]],
  [8861573, [20.60667, -100.31944]],
  [8948842, [45.4239, 9.36506]],
  [513883, [53.37596, 51.3452]],
  [3071418, [50.79406, 14.25641]],
  [6535469, [44.45833, 7.09402]],
  [2746314, [51.495, 6.09306]],
  [2763313, [46.81667, 15.81667]],
  [4566477, [18.04024, -67.07685]],
  [1276502, [23.25178, 91.45407]],
  [4024112, [20.49669, -100.51775]],
  [1810030, [32.24898, 118.88408]],
  [4257415, [38.22394, -86.86222]],
  [2858763, [50.07139, 8.85123]],
  [3688775, [6.33031, -72.58505]],
  [1604769, [15.79408, 104.1451]],
  [2192964, [-41.38333, 173.11667]],
  [315498, [37.51333, 34.04672]],
  [3092742, [49.46656, 19.9825]],
  [700747, [48.47438, 26.69628]],
  [1268215, [27.61268, 79.27687]],
  [3037157, [48.79993, 2.33256]],
  [3019985, [48.95476, 1.82233]],
  [484334, [42.94423, 47.49527]],
  [750605, [40.22806, 27.24222]],
  [2907852, [49.01719, 10.74347]],
  [2989953, [49.69949, 4.79562]],
  [1538224, [42.12215, 60.06276]],
  [3204963, [52.33333, 7.18333]],
  [2171465, [-32.58395, 151.77757]],
  [1714070, [6.94972, 124.86083]],
  [2437731, [13.08173, 2.91099]],
  [2637087, [51.52274, 0.43422]],
  [8377666, [-10.5599, 121.8123]],
  [2996264, [49.28231, 5.89612]],
  [3168435, [45.52204, 8.0766]],
  [1275103, [25.43855, 75.63735]],
  [350422, [25.91544, 32.76287]],
  [680822, [47.54213, 26.05344]],
  [1512286, [40.38903, 67.82169]],
  [3078469, [49.79511, 17.62969]],
  [6884448, [36.44445, 127.58033]],
  [3183434, [45.17051, 11.95625]],
  [8860682, [18.20167, -95.65389]],
  [1279429, [29.0, 85.25]],
  [2832867, [51.54129, 10.16263]],
  [3440698, [-34.44016, -55.95861]],
  [1261076, [12.76793, 78.88304]],
  [4778312, [37.32673, -80.73702]],
  [7765882, [-6.5301, 105.9875]],
  [8860885, [21.73222, -104.06667]],
  [7679676, [30.76136, 119.78847]],
  [3991513, [21.82975, -105.17077]],
  [765279, [49.9352, 22.34602]],
  [2813581, [49.65, 6.55]],
  [8456951, [48.7225, 118.78124]],
  [2657926, [46.69862, 10.09268]],
  [675140, [45.80564, 22.02039]],
  [5361384, [33.07866, -116.60196]],
  [2994778, [45.64299, -0.96522]],
  [625931, [54.2796, 28.7649]],
  [2135517, [-16.06536, 167.39714]],
  [8385895, [-6.9625, 112.8642]],
  [1630681, [-7.75, 110.49417]],
  [3182939, [40.98688, 15.4751]],
  [3517666, [16.06864, -96.75391]],
  [2163990, [-38.03333, 145.25]],
  [8858592, [18.55167, -99.58111]],
  [2839581, [50.78736, 7.84128]],
  [1789166, [38.26786, 114.68318]],
  [3365083, [-33.84808, 18.71723]],
  [3107159, [41.35589, -3.77391]],
  [1628004, [-8.7651, 116.482]],
  [2616176, [55.05732, 9.7408]],
  [8859655, [21.17056, -100.93556]],
  [2918365, [48.85768, 13.3974]],
  [5369906, [34.02577, -118.7804]],
  [11875617, [28.21022, 94.98873]],
  [7510921, [13.41658, 101.33458]],
  [783519, [42.32639, 20.07639]],
  [3181763, [44.92889, 9.19402]],
  [3014141, [47.15665, 6.57137]],
  [8862499, [19.68139, -101.27472]],
  [4024939, [20.28847, -101.14459]],
  [683952, [45.65264, 25.99276]],
  [5040439, [45.11941, -93.40245]],
  [3177377, [40.07481, 16.09818]],
  [2973033, [43.68528, 3.92889]],
  [2126018, [51.47543, 140.76986]],
  [8949354, [41.25403, 16.42242]],
  [1646058, [-7.1599, 106.7645]],
  [3722579, [18.83047, -71.93563]],
  [8630153, [36.69818, 137.86185]],
  [8349257, [-27.39385, 153.04698]],
  [7343467, [34.99744, 103.04864]],
  [736759, [40.47734, 21.63352]],
  [2786675, [51.27161, 3.58062]],
  [3339116, [46.42333, 15.18444]],
  [1684759, [8.71331, 117.35046]],
  [1720344, [15.11494, 120.59815]],
  [8948893, [45.0435, 11.74682]],
  [2786387, [50.33699, 5.36705]],
  [1605601, [12.62137, 102.00481]],
  [736029, [40.64189, 22.85734]],
  [3685482, [5.58007, -72.96687]],
  [9972579, [-37.88413, 144.78367]],
  [3017967, [45.30149, 3.76366]],
  [4156746, [28.11474, -81.97369]],
  [2975891, [45.69368, 5.13032]],
  [7375223, [-8.1739, 112.332]],
  [3985604, [32.45612, -114.77186]],
  [2927175, [50.73333, 7.76667]],
  [2906152, [51.39999, 7.43584]],
  [944774, [-30.35101, 21.82498]],
  [677256, [43.9, 23.85]],
  [2155329, [-25.31936, 152.8041]],
  [2839997, [50.35906, 6.42178]],
  [1270482, [15.32864, 74.75638]],
  [3020152, [47.86584, 7.35052]],
  [2801846, [50.37218, 3.80582]],
  [2944254, [49.77389, 8.88444]],
  [4916311, [42.13919, -87.92896]],
  [2969506, [45.66866, 3.17144]],
  [3977509, [25.74809, -103.2655]],
  [3381428, [5.47952, -53.20524]],
  [2019004, [57.1334, 103.9112]],
  [2818471, [47.9, 9.46667]],
  [3128066, [40.57191, -2.07588]],
  [2913366, [51.90693, 8.37853]],
  [8185137, [-8.3307, 113.4145]],
  [731239, [42.68333, 23.7]],
  [2658185, [46.3186, 7.60743]],
  [1623502, [1.0392, 120.8169]],
  [3009390, [48.4278, -4.31565]],
  [3013742, [45.25565, 5.01904]],
  [2906098, [51.53333, 11.48333]],
  [3092197, [53.49615, 15.06166]],
  [5101244, [40.45622, -74.44321]],
  [7759918, [-6.7737, 105.7226]],
  [8334019, [23.31921, 113.86001]],
  [4481150, [36.5246, -76.17827]],
  [8349171, [-35.32464, 149.07763]],
  [893485, [-21.05, 31.66667]],
  [3589643, [15.47745, -90.31105]],
  [2659670, [47.24529, 8.46347]],
  [3345230, [51.63448, -4.12937]],
  [2649715, [50.15441, -5.07113]],
  [478963, [42.71149, 46.78663]],
  [3824899, [18.64994, -96.65097]],
  [2642005, [55.52272, -4.06551]],
  [3174664, [46.5363, 10.13348]],
  [8443819, [48.2708, 119.97994]],
  [11523836, [-42.8828, 147.32582]],
  [1255631, [13.33914, 78.21175]],
  [2847982, [50.26667, 7.01667]],
  [2766435, [48.044, 14.62182]],
  [5187432, [41.11923, -78.76003]],
  [3938056, [-10.94861, -76.745]],
  [5570299, [40.4993, -124.10644]],
  [1798043, [29.13278, 111.37539]],
  [2978281, [48.43655, 2.56679]],
  [8860443, [19.42972, -98.01444]],
  [3176614, [41.24634, 14.76342]],
  [2971930, [48.60232, -2.94335]],
  [51299, [4.01897, 44.51111]],
  [585568, [40.49182, 50.14292]],
  [736322, [40.47539, 22.86891]],
  [2844947, [53.62966, 13.03849]],
  [1924887, [28.36016, 115.56156]],
  [319069, [39.30278, 35.64389]],
  [6111953, [50.59, -127.08638]],
  [1791094, [32.46397, 115.40812]],
  [3113590, [40.31721, -4.70025]],
  [3092402, [51.55597, 15.45004]],
  [2991802, [49.2977, 2.92078]],
  [2824419, [47.95942, 12.72933]],
  [4557287, [39.91178, -75.27713]],
  [2838756, [49.81667, 6.83333]],
  [2832887, [48.53815, 10.13025]],
  [7463951, [-8.605, 122.743]],
  [2961459, [51.55, -9.26667]],
  [2524572, [37.96607, 13.97314]],
  [1256671, [29.4497, 77.30959]],
  [2657073, [52.00713, -0.26565]],
  [1277769, [23.26206, 82.56051]],
  [8431236, [39.51068, 76.80144]],
  [2863593, [50.55505, 8.39845]],
  [3801447, [17.02083, -96.41727]],
  [3123745, [43.23376, -1.63671]],
  [755810, [51.2494, 21.08259]],
  [3129322, [41.89467, 1.04625]],
  [9972940, [-33.77349, 150.69466]],
  [2142940, [-24.13065, 149.45657]],
  [5344994, [34.0239, -118.17202]],
  [3113251, [41.44406, -5.12491]],
  [2806884, [51.5065, 11.51299]],
  [3991118, [27.51987, -109.25233]],
  [5035679, [44.48024, -93.42856]],
  [9036830, [38.02734, 23.43663]],
  [2981956, [50.6582, 2.76872]],
  [2997638, [49.5217, 5.76192]],
  [101631, [30.49987, 38.21603]],
  [3020720, [49.48385, 0.87617]],
  [2525053, [38.38171, 16.40951]],
  [2894637, [51.1, 6.5]],
  [2034528, [40.73456, 116.63712]],
  [3825951, [20.41603, -99.95563]],
  [667105, [46.97136, 26.86726]],
  [5610946, [42.95158, -112.46637]],
  [1800831, [22.89147, 115.21138]],
  [3022124, [46.49473, 5.38931]],
  [3980487, [22.92997, -101.09537]],
  [8425098, [-8.4923, 123.0015]],
  [4504225, [39.44595, -75.02879]],
  [7036130, [-7.164, 111.6507]],
  [2793070, [51.03859, 3.74458]],
  [3005651, [45.63417, 5.1456]],
  [3524049, [25.90797, -99.40479]],
  [3064142, [49.64003, 17.31901]],
  [674530, [45.66644, 21.9618]],
  [3125649, [41.67246, -5.30473]],
  [3173160, [41.11399, 13.89157]],
  [8860646, [19.85528, -98.96333]],
  [5386073, [33.02032, -117.20281]],
  [2898151, [50.35861, 7.84611]],
  [665193, [46.91667, 27.33333]],
  [2345785, [9.61667, 11.43333]],
  [1259244, [27.86371, 77.20432]],
  [2517322, [38.88022, -5.04759]],
  [11704374, [-33.83078, 150.99725]],
  [2973821, [50.70199, 2.77829]],
  [681202, [45.0667, 25.76161]],
  [1942015, [9.86989, 106.34939]],
  [1494090, [55.48333, 86.2]],
  [260473, [38.60751, 22.71348]],
  [2806307, [49.90702, 6.46597]],
  [3155247, [60.52408, 6.7194]],
  [686680, [46.55511, 21.58433]],
  [2561124, [34.95368, -2.10027]],
  [5265499, [43.11167, -88.49927]],
  [1817977, [39.47, 112.81472]],
  [475868, [45.37639, 40.83583]],
  [8310303, [-8.5574, 114.112]],
  [3523129, [15.0256, -92.1408]],
  [3169392, [43.72145, 11.45183]],
  [2925147, [50.76667, 11.55]],
  [8548895, [46.80381, 85.45172]],
  [5246437, [44.74027, -88.44482]],
  [1706298, [11.65111, 122.345]],
  [12104196, [-33.89511, 25.13914]],
  [3455923, [-20.73722, -48.91472]],
  [2068688, [-35.09446, 139.89342]],
  [4047914, [26.55035, -81.8687]],
  [3120596, [40.39237, -5.06713]],
  [3824786, [18.86522, -96.06996]],
  [933510, [-24.16667, 21.83333]],
  [2883895, [51.65483, 12.48713]],
  [2972613, [47.01288, -1.11173]],
  [527191, [52.8978, 40.4907]],
  [2501465, [36.76775, 2.95924]],
  [677165, [44.78639, 21.79444]],
  [3800163, [16.47508, -94.46802]],
  [1157232, [13.43047, 99.40967]],
  [1695751, [9.24627, 118.4082]],
  [1794685, [26.15467, 109.7138]],
  [2172356, [-27.68333, 153.25]],
  [3673431, [5.13969, -73.39739]],
  [2857291, [51.35613, 8.48899]],
  [8950100, [45.68661, 11.60433]],
  [2771287, [48.36047, 14.79429]],
  [3012664, [45.54422, 3.24901]],
  [1806167, [27.04146, 118.31897]],
  [8384330, [-7.0378, 113.8989]],
  [3014103, [47.72975, 7.41801]],
  [297466, [35.9025, 36.06272]],
  [4951431, [42.44454, -71.68701]],
  [3126139, [43.32596, -4.06893]],
  [4081644, [32.64541, -85.37828]],
  [191837, [-1.19347, 34.61967]],
  [3020374, [48.10388, -3.97796]],
  [2925668, [49.75806, 8.17417]],
  [5087105, [42.87453, -71.18117]],
  [3030847, [50.77123, 3.08459]],
  [6534725, [44.24155, 9.65578]],
  [792202, [42.55806, 20.45278]],
  [3460505, [-20.34583, -41.53583]],
  [5364059, [32.70811, -116.99725]],
  [8329949, [-8.0392, 111.8493]],
  [2915173, [51.26667, 12.5]],
  [2648095, [52.18546, -0.14574]],
  [8949960, [39.2812, 16.28351]],
  [3517999, [19.58429, -99.8805]],
  [2772812, [48.18912, 16.09117]],
  [2514211, [37.78627, -3.61226]],
  [1807969, [26.16905, 118.99626]],
  [8348244, [-35.13514, 138.56697]],
  [2982330, [49.70038, 2.78959]],
  [1568510, [10.01245, 105.08091]],
  [4461941, [35.4868, -80.86007]],
  [2799351, [50.69653, 4.66657]],
  [1627357, [-7.87639, 110.35889]],
  [3183174, [44.33566, 8.33411]],
  [755551, [51.78924, 23.20836]],
  [2326790, [5.77743, 7.21794]],
  [1812737, [32.36357, 120.71003]],
  [5325011, [37.69465, -122.11385]],
  [1703612, [7.57326, 126.50684]],
  [3167940, [41.83844, 15.56535]],
  [7084015, [-8.21304, 114.15636]],
  [3172860, [43.3427, 13.57074]],
  [3583379, [13.38333, -88.35]],
  [3514048, [23.97319, -99.77233]],
  [1262410, [20.73299, 77.36694]],
  [2978326, [44.93718, 4.36865]],
  [3372562, [37.83333, -25.15]],
  [559752, [58.37583, 58.32111]],
  [682363, [44.21667, 24.88333]],
  [5794097, [47.44843, -122.15734]],
  [2673773, [56.17772, 13.82828]],
  [8349374, [-31.93971, 115.87015]],
  [685021, [45.23333, 24.91667]],
  [6308432, [-13.78076, -73.4625]],
  [8323102, [-8.1542, 111.7757]],
  [4018018, [21.66551, -104.83949]],
  [2962334, [53.21583, -6.66694]],
  [3700921, [8.37233, -81.58141]],
  [3692219, [-6.62501, -78.94576]],
  [2784998, [49.91299, 5.56784]],
  [3010335, [47.78362, -2.10657]],
  [3823852, [18.84018, -96.99297]],
  [3176196, [45.20633, 9.36137]],
  [8862497, [19.7625, -99.22833]],
  [2842928, [50.53333, 7.56667]],
  [4595864, [34.73706, -82.25428]],
  [3027350, [47.18159, 0.96863]],
  [2762358, [47.55, 10.63333]],
  [8859124, [19.36972, -98.47722]],
  [772270, [49.90818, 20.19879]],
  [1725428, [15.6602, 121.0414]],
  [7039088, [-8.4755, 115.1518]],
  [2896882, [48.27965, 9.94246]],
  [589166, [58.98167, 26.25444]],
  [5005247, [45.5528, -84.7854]],
  [2659533, [47.27145, 7.83935]],
  [2991199, [48.64215, 7.71378]],
  [258382, [39.49454, 21.68392]],
  [4013714, [19.70466, -103.4617]],
  [3117862, [43.61517, -5.79344]],
  [3458131, [-23.31861, -46.58667]],
  [3171307, [43.69646, 12.49785]],
  [8425127, [-8.3871, 123.013]],
  [1057681, [-12.61667, 49.35]],
  [4478334, [35.11681, -80.72368]],
  [3079824, [49.78645, 18.52444]],
  [2341695, [11.68566, 8.36986]],
  [7932573, [-37.67852, 144.56883]],
  [2035202, [42.73583, 112.65139]],
  [8347964, [-34.88601, 138.48588]],
  [2500737, [33.51667, 6.95]],
  [2977861, [45.40374, 4.08155]],
  [2890204, [52.10956, 10.58996]],
  [1510998, [55.38425, 91.61872]],
  [2958391, [49.25132, 11.23659]],
  [3201217, [45.35, 14.43028]],
  [285603, [29.26917, 47.97806]],
  [2981179, [45.54508, 4.48826]],
  [9973208, [-34.86616, 138.5618]],
  [1682913, [10.0631, 123.4453]],
  [1791522, [35.78148, 102.39726]],
  [257174, [39.23758, 20.52958]],
  [1808751, [21.6981, 110.3004]],
  [3027260, [48.02185, 0.33096]],
  [7729845, [14.45558, 120.99816]],
  [3163870, [45.33153, 11.37661]],
  [2885196, [51.35506, 12.17101]],
  [2644849, [54.85, -5.81667]],
  [6156102, [49.52579, -96.68451]],
  [8539232, [32.87559, 119.76138]],
  [12156818, [43.75503, -79.33018]],
  [2786595, [50.86185, 4.21317]],
  [2514994, [39.28333, -0.56667]],
  [3128112, [42.81291, -2.71019]],
  [784024, [43.90358, 22.26405]],
  [763534, [49.62177, 20.69705]],
  [8949343, [45.40268, 9.53047]],
  [297195, [38.80414, 40.99222]],
  [3018781, [47.58464, -2.04458]],
  [2970015, [50.26785, 3.47705]],
  [2163755, [-33.87868, 151.20526]],
  [2813180, [50.40808, 7.14669]],
  [1693970, [17.7905, 121.478]],
  [3526932, [18.14479, -95.14873]],
  [5956869, [54.40007, -116.80238]],
  [4220866, [31.15051, -81.36954]],
  [7055350, [-8.4657, 115.4483]],
  [1728334, [15.5495, 120.6943]],
  [3179649, [44.51825, 10.73727]],
  [2222568, [9.3839, 13.50231]],
  [1496100, [54.3656, 81.8994]],
  [3024639, [44.24661, 0.42025]],
  [7622992, [26.68778, 118.15623]],
  [6535613, [44.33005, 9.93308]],
  [3988887, [24.99356, -107.72796]],
  [3217418, [46.85056, 12.76861]],
  [2821143, [48.39468, 13.00627]],
  [1215326, [3.73956, 96.83608]],
  [8347757, [-28.30042, 153.57064]],
  [3526613, [18.82642, -97.08865]],
  [1279741, [39.56667, 98.96667]],
  [1262469, [15.30928, 76.3383]],
  [3090673, [52.97159, 18.09036]],
  [3094871, [50.3356, 18.14332]],
  [3701882, [7.9839, -81.16141]],
  [3667959, [4.65374, -76.64442]],
  [670954, [45.33121, 26.89586]],
  [736762, [40.84352, 22.30178]],
  [1700952, [9.7049, 122.4188]],
  [4592904, [33.84542, -81.66177]],
  [1255175, [21.3527, 72.03524]],
  [3689941, [4.55099, -74.53517]],
  [149879, [-4.81629, 34.74358]],
  [3184177, [40.39639, 19.72972]],
  [1550507, [28.52081, 108.191]],
  [2745677, [51.995, 5.97361]],
  [1794775, [25.93821, 119.47973]],
  [2922583, [48.13451, 12.58152]],
  [3218730, [45.93833, 14.63278]],
  [2951327, [49.56056, 8.31944]],
  [1682118, [15.31193, 120.86116]],
  [5729080, [42.43933, -123.33067]],
  [12159595, [-7.00104, 113.86971]],
  [668873, [45.38333, 27.05]],
  [1807553, [32.12722, 115.03944]],
  [2984484, [48.55042, 3.06621]],
  [2989751, [43.52329, 1.5889]],
  [4498899, [34.33878, -78.70307]],
  [3810758, [-6.55788, -79.80611]],
  [5640350, [45.78329, -108.50069]],
  [2898004, [48.8, 8.11667]],
  [2974979, [49.78333, 3.21667]],
  [2824729, [49.95, 10.33333]],
  [1172128, [27.55898, 68.21204]],
  [1807816, [22.81354, 115.15442]],
  [5478550, [34.86478, -106.05169]],
  [537147, [61.51981, 30.19917]],
  [781979, [40.61222, 20.09806]],
  [2643156, [57.00293, -5.83082]],
  [7888859, [-7.0308, 107.0933]],
  [2980492, [46.16051, 2.83379]],
  [2978927, [47.31738, -0.80333]],
  [8541454, [26.72577, 93.14659]],
  [4885961, [42.32058, -88.35509]],
  [2766514, [48.16425, 15.02076]],
  [3463124, [-11.04833, -45.19306]],
  [2753945, [52.43, 5.84167]],
  [3859965, [-31.24508, -64.46563]],
  [3178658, [43.85205, 7.93144]],
  [3516936, [18.54983, -97.43953]],
  [3003165, [43.52796, 2.06534]],
  [2789914, [50.7207, 3.78049]],
  [3526966, [15.01981, -92.38249]],
  [2640832, [53.43612, -1.53902]],
  [7766944, [-6.5609, 106.2443]],
  [2746311, [53.21343, 5.90961]],
  [3020314, [48.65569, 6.48447]],
  [4011366, [21.51535, -104.94436]],
  [1529338, [41.10244, 95.50189]],
  [590949, [58.73667, 26.5225]],
  [1637426, [0.8755, 124.0098]],
  [495062, [59.20998, 38.51066]],
  [285839, [29.07694, 48.08389]],
  [2649269, [57.61445, -3.09947]],
  [2973994, [48.06394, 7.10233]],
  [3173169, [43.67468, 12.96601]],
  [3063883, [50.29538, 14.51727]],
  [2317818, [13.05313, 8.48574]],
  [5465262, [32.38954, -106.8139]],
  [264714, [38.47958, 22.5835]],
  [2784615, [50.71124, 5.53891]],
  [3718420, [19.93984, -72.83037]],
  [8862862, [19.25917, -97.08028]],
  [8859778, [18.24528, -96.30556]],
  [8858896, [20.78, -103.46222]],
  [8057042, [-7.5132, 108.6198]],
  [683033, [47.85, 26.11667]],
  [1259261, [10.63375, 78.84139]],
  [4161178, [28.50167, -81.54091]],
  [2633894, [51.16236, -3.32208]],
  [3120006, [41.66667, 2.26667]],
  [3207383, [51.87713, 9.19538]],
  [2516443, [27.91855, -15.43433]],
  [8860118, [17.98722, -94.91806]],
  [2843682, [48.35825, 13.20162]],
  [3109387, [41.21373, -6.27101]],
  [3519402, [17.8879, -96.52474]],
  [4325424, [29.44216, -90.29925]],
  [749334, [40.34529, 35.05136]],
  [6674621, [-11.88982, -75.28571]],
  [8948981, [40.88872, 14.09091]],
  [8078564, [-6.9439, 112.0864]],
  [1798946, [36.43111, 114.17]],
  [9973062, [-34.94347, 138.64728]],
  [2610726, [55.62112, 8.48069]],
  [4921471, [40.94783, -84.99163]],
  [7852777, [-7.2063, 106.9634]],
  [8862602, [20.07294, -97.45743]],
  [3529989, [20.03557, -98.31015]],
  [1904024, [29.85834, 105.97306]],
  [3080508, [49.64396, 19.54227]],
  [255594, [38.03021, 22.94902]],
  [2779947, [48.53333, 16.35]],
  [2934697, [52.00449, 9.69578]],
  [2263426, [38.62561, -9.03043]],
  [3022121, [46.55696, 4.99797]],
  [1815863, [39.24611, 116.94139]],
  [2969941, [48.83828, 6.74543]],
  [3117076, [42.94033, -8.97187]],
  [577206, [59.4091, 56.8204]],
  [1791448, [29.66143, 116.29865]],
  [2634202, [50.61448, -2.45991]],
  [3542753, [22.88042, -80.39098]],
  [2651878, [56.06303, -3.49674]],
  [2810178, [53.23697, 9.13084]],
  [1818953, [22.33023, 114.15945]],
  [2957742, [50.36667, 7.98333]],
  [7874116, [18.5, -70.0]],
  [3104725, [42.16075, -3.81421]],
  [8714246, [45.94649, 8.49226]],
  [168102, [34.80454, 36.00077]],
  [1214258, [2.1046, 98.7637]],
  [580872, [53.73893, 36.66429]],
  [4120792, [34.86676, -92.40432]],
  [2890838, [47.88333, 10.91667]],
  [685903, [45.17157, 24.86064]],
  [3064029, [49.29092, 15.48211]],
  [1628057, [-7.3167, 106.8395]],
  [1710097, [13.0695, 123.5999]],
  [5515714, [36.90697, -100.53932]],
  [8862709, [20.32472, -98.3725]],
  [365137, [15.64453, 32.47773]],
  [1713759, [17.02327, 121.68402]],
  [2869592, [53.63333, 7.58333]],
  [2945948, [53.85981, 11.12636]],
  [3038706, [50.0652, 1.67612]],
  [553389, [56.35305, 41.00941]],
  [4354260, [39.72064, -78.77419]],
  [798605, [41.94122, 21.33365]],
  [3936963, [-14.43194, -71.27306]],
  [2967616, [48.13669, -0.29106]],
  [3972140, [22.02824, -101.59193]],
  [2986032, [48.11283, -0.97179]],
  [2995721, [46.98155, 0.48944]],
  [2789053, [50.61489, 5.83417]],
  [2154912, [-37.79798, 144.94512]],
  [3062835, [48.98241, 16.15025]],
  [2988063, [42.46579, 9.45884]],
  [694030, [45.64627, 34.83767]],
  [2970237, [47.28556, 0.74079]],
  [3168328, [41.96234, 14.94481]],
  [8348547, [-27.79359, 152.88443]],
  [3345400, [51.56036, -2.66279]],
  [2025527, [53.15611, 103.0675]],
  [2867402, [50.68333, 7.78333]],
  [2977114, [45.11278, 5.32239]],
  [5343303, [38.44546, -121.8233]],
  [1811860, [39.02868, 116.9074]],
  [2878270, [47.82672, 10.0205]],
  [5388867, [34.08057, -118.07285]],
  [696478, [48.66832, 22.75328]],
  [3033677, [46.20045, 3.07995]],
  [5216354, [40.77009, -76.03743]],
  [3024701, [48.94492, 2.68566]],
  [686186, [44.51667, 23.36667]],
  [5353775, [39.21906, -121.06106]],
  [1650600, [1.05949, 101.29054]],
  [668994, [45.78333, 26.83333]],
  [5143946, [41.09649, -73.97292]],
  [2968504, [43.43568, 1.66281]],
  [2638138, [50.62613, -1.1785]],
  [2665570, [55.96133, 12.85611]],
  [683938, [46.8481, 27.9027]],
  [3125517, [42.03284, -5.15007]],
  [2972126, [45.66114, 0.2591]],
  [3444176, [-18.33473, -59.76142]],
  [8948777, [40.77473, 14.67619]],
  [8046790, [-7.1944, 108.3552]],
  [10103835, [-32.94128, 151.76544]],
  [666660, [46.53959, 27.29773]],
  [1724532, [16.1348, 120.5271]],
  [2168822, [-41.32965, 147.06522]],
  [3173379, [41.51628, 14.67315]],
  [1606588, [13.54753, 100.27362]],
  [6104231, [49.8833, -112.78516]],
  [3166341, [45.35754, 11.50819]],
  [8949668, [43.79401, 7.56571]],
  [1262491, [27.0631, 73.82304]],
  [8948708, [40.88882, 14.32015]],
  [1920236, [38.32232, 114.22928]],
  [98198, [33.11508, 45.93344]],
  [3444914, [-14.86611, -40.83944]],
  [8859248, [18.07861, -93.35056]],
  [2855047, [52.31928, 10.2352]],
  [933305, [-24.40659, 25.49508]],
  [666641, [44.98333, 26.85]],
  [2977593, [47.58685, -3.00032]],
  [573073, [52.78285, 39.87214]],
  [3170892, [45.99273, 9.68942]],
  [2804792, [53.97836, 13.75502]],
  [3533195, [21.16495, -98.66594]],
  [8949864, [45.38005, 10.87858]],
  [5220369, [40.00898, -77.11554]],
  [5839264, [43.42215, -110.79326]],
  [3993209, [18.22377, -100.8739]],
  [681850, [45.26667, 25.05]],
  [5312634, [35.64474, -109.09565]],
  [3194020, [46.33167, 16.50694]],
  [2264357, [38.8, -9.16667]],
  [1529438, [44.19035, 86.88521]],
  [2337762, [7.35067, 5.11333]],
  [1136575, [36.69736, 67.69826]],
  [3078957, [48.8986, 14.64227]],
  [2509496, [39.53333, -1.43333]],
  [2522244, [39.53333, -0.35]],
  [761825, [51.62889, 21.15435]],
  [7258303, [39.96591, -74.96441]],
  [4792867, [37.4032, -79.17808]],
  [4537884, [34.22842, -98.68451]],
  [4018403, [20.54495, -100.68462]],
  [2979541, [46.4714, -0.66437]],
  [3028684, [44.2924, -0.26196]],
  [3030004, [47.05264, -0.1868]],
  [3164451, [44.14144, 9.88692]],
  [3055539, [46.89397, 18.13737]],
  [685457, [44.89583, 22.04472]],
  [4862659, [42.30693, -93.64022]],
  [7367852, [34.67274, 107.23029]],
  [662818, [45.88632, 23.35289]],
  [2652437, [54.85404, -1.8316]],
  [5881150, [-11.05528, -171.08833]],
  [684888, [46.13333, 24.51667]],
  [2945270, [51.33597, 12.61024]],
  [1514486, [41.35415, 60.81787]],
  [3173308, [43.50366, 13.13275]],
  [677578, [43.73333, 25.7]],
  [2900825, [50.43474, 7.66903]],
  [3025910, [48.66853, 2.84015]],
  [8859883, [19.42722, -100.39889]],
  [1702708, [8.825, 125.19306]],
  [293690, [32.09556, 34.95664]],
  [3981866, [24.69254, -101.46575]],
  [1684610, [9.69722, 125.58556]],
  [3217427, [45.85686, 13.32182]],
  [5254124, [43.31748, -90.84485]],
  [4671840, [26.35646, -98.75419]],
  [2642927, [54.71434, -3.49509]],
  [1274428, [9.31575, 76.61513]],
  [2524868, [38.56514, 16.19521]],
  [3176260, [40.1613, 9.19462]],
  [1800829, [21.45713, 110.76591]],
  [3673404, [5.13429, -75.14001]],
  [2642780, [52.32954, 1.07979]],
  [2700199, [59.72564, 17.78753]],
  [6682582, [23.47436, 116.76713]],
  [1641943, [-7.2627, 106.7222]],
  [5369413, [34.25917, -117.50005]],
  [3097420, [53.42048, 16.81756]],
  [3124959, [41.56113, 1.13935]],
  [1622136, [-7.3332, 108.6876]],
  [5128490, [40.57399, -74.11598]],
  [2981953, [45.81017, 4.59797]],
  [2147140, [-37.75405, 145.14864]],
  [878184, [-10.81667, 39.13333]],
  [8550778, [19.88659, 105.67879]],
  [3175030, [40.87972, 14.63013]],
  [742143, [41.26546, 43.1258]],
  [3933867, [-10.66846, -76.77165]],
  [704708, [48.2835, 26.35822]],
  [3180749, [45.59656, 12.8758]],
  [317732, [39.53845, 39.90765]],
  [2163788, [-32.66755, 152.17831]],
  [1263255, [24.60998, 93.88873]],
  [4083414, [32.58374, -86.46553]],
  [99182, [31.66789, 47.2945]],
  [2187834, [-38.1, 176.08333]],
  [2523010, [38.78091, 16.51175]],
  [5806391, [46.57038, -123.29737]],
  [732491, [43.27884, 24.08251]],
  [2521908, [39.86667, -0.4]],
  [5096655, [40.64094, -74.3107]],
  [1914504, [23.62043, 112.28655]],
  [8858347, [20.055, -98.76333]],
  [1797995, [24.67555, 116.88774]],
  [4003341, [20.56816, -103.98639]],
  [1263621, [21.39602, 79.2635]],
  [3172226, [45.24159, 7.56983]],
  [666204, [45.06359, 21.85216]],
  [2206066, [-42.83475, 147.31152]],
  [1277240, [22.95387, 88.40098]],
  [4991753, [42.5092, -84.65581]],
  [3028486, [50.49235, 2.95815]],
  [676085, [46.87825, 26.66282]],
  [2150825, [-36.62846, 142.63152]],
  [1799160, [35.20854, 100.85286]],
  [1253392, [8.7333, 76.7167]],
  [1792350, [24.66667, 101.63333]],
  [675832, [44.5, 24.03333]],
  [542133, [51.965, 59.89667]],
  [788099, [41.70224, 20.75723]],
  [76482, [13.5571, 44.01521]],
  [2879106, [51.1826, 11.90954]],
  [3981483, [20.66139, -100.50769]],
  [659180, [60.99596, 24.46434]],
  [6074409, [43.61616, -79.51269]],
  [1180729, [28.88846, 64.40616]],
  [3817481, [18.92694, -99.68593]],
  [6534903, [45.09685, 8.35954]],
  [3108011, [41.13425, -4.65121]],
  [2798272, [49.69983, 5.3074]],
  [3118360, [41.81407, -1.75276]],
  [5915148, [45.5668, -75.78265]],
  [11611224, [39.8028, 141.13466]],
  [2265223, [38.56902, -8.90126]],
  [7564566, [-8.5531, 118.2923]],
  [8071163, [-6.9751, 111.4492]],
  [3177532, [46.1165, 8.29313]],
  [3217941, [45.79139, 12.55]],
  [2479609, [35.52832, -0.19369]],
  [2169068, [-35.53245, 144.95364]],
  [2524734, [37.08343, 15.15332]],
  [2329821, [10.26858, 13.26701]],
  [3866465, [-28.56091, -66.80588]],
  [3176854, [42.95488, 12.70268]],
  [6534933, [45.88198, 9.26644]],
  [1965433, [-8.0878, 112.6704]],
  [1810155, [30.05468, 113.09562]],
  [7783025, [-6.5294, 106.0777]],
  [3202698, [45.28861, 17.38194]],
  [295587, [33.02224, 35.57716]],
  [3863557, [-25.12033, -66.16519]],
  [3841937, [-35.146, -64.50089]],
  [8422682, [-8.4631, 124.1308]],
  [670330, [45.41667, 27.01667]],
  [2018116, [43.12165, 133.12347]],
  [1790510, [29.5172, 110.83746]],
  [1261309, [16.399, 74.38285]],
  [740820, [41.4485, 32.75361]],
  [2995824, [47.29314, 1.32897]],
  [2517682, [39.18333, -0.3]],
  [8863100, [20.445, -99.96889]],
  [668274, [43.85577, 24.72789]],
  [2651820, [52.85524, -0.76115]],
  [2635343, [52.02398, -2.94833]],
  [557413, [45.82083, 44.64722]],
  [8724752, [20.38966, -97.29812]],
  [3069796, [49.81569, 14.51827]],
  [2659845, [47.19135, 7.79102]],
  [3178338, [44.95744, 6.87596]],
  [3614835, [15.8, -84.28333]],
  [3406503, [-6.75, -35.63333]],
  [3196621, [45.31556, 18.18361]],
  [3198531, [46.48472, 15.97139]],
  [2694838, [58.61667, 16.25]],
  [3091424, [53.35296, 14.91875]],
  [2970351, [45.92671, 5.35141]],
  [2981574, [47.72024, -1.48654]],
  [2867127, [49.38333, 6.78333]],
  [669463, [46.62154, 27.64503]],
  [2641389, [53.15, -0.75]],
  [6104876, [45.38338, -73.9825]],
  [8861888, [19.46917, -101.70056]],
  [2930747, [49.84289, 9.16355]],
  [4027756, [16.948, -100.10784]],
  [8860530, [19.42889, -98.05333]],
  [3460723, [-23.98222, -48.87556]],
  [2702805, [63.69586, 16.88256]],
  [676897, [45.17309, 23.348]],
  [3182533, [41.95, 12.71667]],
  [2967229, [48.51003, 1.70029]],
  [2656628, [52.67265, -1.34274]],
  [3107278, [42.08662, -6.14475]],
  [2811034, [53.67523, 10.47389]],
  [1684542, [13.84166, 120.08872]],
  [4005539, [20.66682, -103.39182]],
  [2014624, [66.42989, 112.4021]],
  [2780019, [47.85, 13.2]],
  [1940937, [31.78364, 113.6347]],
  [616719, [40.50848, 43.82092]],
  [7644932, [8.43269, 151.74628]],
  [8261472, [40.76663, 23.90039]],
  [3068969, [49.90461, 14.7842]],
  [7510916, [18.9137, 100.94169]],
  [2792893, [51.19487, 3.63333]],
  [3402465, [-6.34389, -37.74667]],
  [4311963, [38.11118, -85.87024]],
  [2523067, [38.25231, 15.79928]],
  [7338063, [-8.8467, 121.6447]],
  [5736645, [44.95816, -124.01789]],
  [592232, [58.08114, 24.49719]],
  [6085665, [50.24987, -117.80226]],
  [4147257, [27.92308, -82.84316]],
  [6544075, [39.84182, 3.13291]],
  [3063877, [50.08478, 14.7695]],
  [3172593, [42.11661, 12.77234]],
  [3738301, [8.57562, -73.09489]],
  [673097, [44.80383, 26.08689]],
  [485785, [53.18222, 34.55111]],
  [8862648, [25.49667, -103.5575]],
  [3166099, [43.98987, 10.19357]],
  [684649, [47.21667, 24.76667]],
  [2016187, [44.59884, 132.82593]],
  [5164449, [40.84506, -80.61452]],
  [518909, [64.4165, 40.8122]],
  [1725892, [16.45, 120.51667]],
  [4000411, [21.12454, -104.35811]],
  [2939420, [52.19519, 11.53736]],
  [758633, [50.22909, 22.11968]],
  [3035624, [48.65, 6.8]],
  [3073900, [50.19291, 14.99942]],
  [3436134, [-34.64966, -58.38341]],
  [3583196, [13.51667, -88.95]],
  [3171517, [41.22082, 15.2756]],
  [1261563, [26.91649, 94.73611]],
  [3183581, [42.8812, 11.6722]],
  [2962304, [52.86194, -8.19667]],
  [5268798, [45.50607, -91.73823]],
  [3014657, [44.38788, -0.04382]],
  [6534975, [45.50198, 9.94596]],
  [1787438, [30.83795, 118.61173]],
  [3523295, [18.31019, -99.6091]],
  [302150, [37.98632, 36.09056]],
  [7085886, [-8.7191, 116.284]],
  [5202340, [41.12203, -75.36463]],
  [180087, [0.11667, 34.0]],
  [684187, [45.36738, 22.352]],
  [6295183, [47.49419, 8.73948]],
  [3057069, [49.40429, 18.62258]],
  [3170069, [40.83375, 8.40531]],
  [3802874, [17.90439, -91.91329]],
  [12041364, [41.1708, -1.604]],
  [3109389, [41.60847, -4.43397]],
  [5174870, [40.82728, -83.28131]],
  [3946350, [-13.51227, -72.81296]],
  [629018, [52.4089, 31.3237]],
  [2155132, [-26.4, 153.06667]],
  [8950221, [39.22271, 8.43313]],
  [5264870, [43.84635, -91.24819]],
  [2762021, [48.72845, 15.01419]],
  [4519486, [39.2595, -84.55717]],
  [7766675, [-6.4334, 105.9098]],
  [3168983, [45.2463, 7.96426]],
  [3000761, [46.99507, 6.5445]],
  [7891286, [31.44538, 35.20071]],
  [2947894, [53.28972, 10.73372]],
  [2979044, [45.80427, 4.51908]],
  [3532801, [19.32072, -96.71874]],
  [1520208, [43.37361, 76.94]],
  [408883, [14.29496, 44.68504]],
  [10104158, [-23.37941, 150.48175]],
  [3108008, [43.13484, -2.07801]],
  [1265891, [9.17167, 77.86989]],
  [4907762, [42.16753, -87.89701]],
  [6620349, [51.69677, -2.68142]],
  [622406, [52.7867, 28.0186]],
  [3032279, [50.71843, 2.28601]],
  [2633771, [52.66622, 0.15938]],
  [667713, [45.26388, 24.44472]],
  [2156345, [-33.838, 150.64963]],
  [775358, [52.62931, 21.58745]],
  [1646082, [-6.9482, 106.4498]],
  [1710378, [9.6531, 124.3697]],
  [4458372, [36.13209, -78.75667]],
  [676372, [46.39447, 27.55614]],
  [1628310, [4.00232, 117.03456]],
  [6138360, [48.53338, -71.08236]],
  [2159630, [-23.44212, 144.24921]],
  [3192279, [43.82222, 17.60917]],
  [4471143, [34.26961, -77.93165]],
  [1722032, [9.12261, 125.53549]],
  [3439652, [-34.03631, -54.28054]],
  [8541797, [-10.1535, 120.6278]],
  [3028437, [44.44285, 0.6214]],
  [8861880, [19.47944, -100.39722]],
  [2655242, [53.22846, -1.29204]],
  [3013080, [49.51259, 1.07962]],
  [8349127, [-42.83506, 147.29493]],
  [1279865, [31.48056, 79.79803]],
  [2984785, [50.70362, 1.93333]],
  [1165057, [34.32959, 71.17852]],
  [8860474, [20.44083, -101.23361]],
  [3127174, [41.68096, -2.26964]],
  [2982876, [43.76408, 7.48207]],
  [692638, [49.40163, 31.09557]],
  [8862345, [16.45528, -93.82722]],
  [710951, [45.28119, 34.89428]],
  [2986285, [50.47358, 2.88884]],
  [3096003, [50.97281, 18.21816]],
  [8858686, [19.65833, -98.775]],
  [484449, [55.95722, 37.84333]],
  [2654589, [52.14508, -0.52906]],
  [3801070, [17.72475, -97.53499]],
  [4984692, [43.80196, -83.00078]],
  [967106, [-33.60047, 22.19955]],
  [3126075, [40.00932, -6.60073]],
  [2981120, [45.08397, -0.60959]],
  [2165791, [-43.16337, 146.92549]],
  [557968, [42.61128, 47.60067]],
  [3167821, [45.60879, 11.67355]],
  [2754450, [51.82667, 5.17639]],
  [5268544, [44.04192, -89.09845]],
  [2844072, [49.96528, 9.39389]],
  [3021319, [46.70065, 0.43166]],
  [1200474, [24.83333, 91.65]],
  [254045, [37.82158, 21.29425]],
  [1811833, [32.64658, 120.34791]],
  [2885716, [51.6712, 11.77068]],
  [3166387, [41.71829, 13.61356]],
  [2524086, [39.52644, 16.11225]],
  [2760388, [48.1099, 16.46267]],
  [3173437, [45.10436, 7.43373]],
  [8950188, [40.2539, 15.74768]],
  [2987003, [48.17185, 2.25185]],
  [2995461, [47.06672, 3.01183]],
  [1796910, [30.3247, 120.07763]],
  [2777927, [47.44272, 13.90122]],
  [2633911, [53.76099, -0.44186]],
  [8860224, [21.21417, -100.20472]],
  [2513045, [37.08846, -3.63321]],
  [3859904, [-32.02873, -60.30658]],
  [241150, [-4.76667, 55.5]],
  [2708743, [59.31667, 16.6]],
  [2057575, [-31.54678, 115.63171]],
  [2765168, [47.21667, 15.4]],
  [503067, [50.8389, 35.8515]],
  [709270, [49.79614, 36.59047]],
  [738468, [40.44631, 34.45259]],
  [2659997, [47.3126, 7.65465]],
  [3196464, [43.96861, 15.77417]],
  [3171558, [42.06909, 12.76684]],
  [2960737, [49.88778, 5.81639]],
  [3827083, [19.89556, -99.75278]],
  [4937222, [42.06538, -71.24783]],
  [8862188, [17.56028, -99.06306]],
  [1150200, [8.66733, 99.93078]],
  [2661176, [45.83203, 9.03119]],
  [2659683, [47.25944, 8.37633]],
  [2782511, [48.37778, 16.82806]],
  [3184290, [40.7375, 19.70222]],
  [582423, [56.18738, 52.47919]],
  [8347951, [-32.74706, 151.99714]],
  [3433567, [-26.48705, -58.31198]],
  [2999303, [48.01733, 6.68436]],
  [2338246, [7.79148, 5.50865]],
  [4011992, [32.20039, -116.89074]],
  [437967, [36.58249, 36.52744]],
  [2636504, [52.90269, -0.09235]],
  [698462, [49.95948, 34.60634]],
  [3981075, [20.26203, -101.62735]],
  [6534235, [45.67206, 8.82691]],
  [3942351, [-15.45769, -69.4379]],
  [2112454, [39.58926, 140.56724]],
  [318879, [37.52947, 30.48028]],
  [712819, [46.94368, 32.77936]],
  [3529926, [19.86611, -99.09875]],
  [2876161, [49.55, 7.85]],
  [1724519, [15.65653, 120.38119]],
  [3069035, [50.12849, 13.69585]],
  [3115259, [42.63828, -2.16476]],
  [3021230, [50.07437, 2.12596]],
  [2794349, [50.83837, 5.55007]],
  [763510, [52.46249, 23.08571]],
  [1803807, [32.56306, 120.68389]],
  [3022774, [49.55226, -1.68619]],
  [4024223, [20.69598, -100.9806]],
  [7766572, [-6.4034, 105.9797]],
  [1513567, [41.38972, 69.465]],
  [3892892, [-37.47793, -73.34495]],
  [4330209, [30.08076, -92.67153]],
  [1278905, [22.67486, 81.75908]],
  [2964310, [52.46722, -7.69111]],
  [2771148, [47.28333, 10.95]],
  [306193, [37.17839, 41.87878]],
  [1520025, [47.72521, 84.20629]],
  [3201247, [46.38333, 15.85]],
  [2895545, [53.71119, 12.96925]],
  [2990887, [45.25465, -1.02485]],
  [2511306, [36.77808, -6.3515]],
  [686915, [48.71157, 32.59655]],
  [3014686, [45.60339, -0.84217]],
  [1813746, [34.86007, 109.31576]],
  [3071665, [50.6042, 13.61812]],
  [8052957, [-7.3802, 108.456]],
  [2151932, [-33.89279, 151.20415]],
  [1848124, [30.2347, 130.47942]],
  [1722851, [16.48333, 121.23333]],
  [2986567, [42.25815, 9.18621]],
  [1724106, [15.6321, 121.1448]],
  [3049491, [47.63053, 17.35717]],
  [3025736, [47.0384, 0.34973]],
  [1264215, [31.10335, 74.99631]],
  [576572, [56.97669, 29.89065]],
  [3065864, [48.79785, 14.61497]],
  [1816526, [33.62121, 113.6918]],
  [7176035, [47.86602, -122.1551]],
  [3973105, [23.58671, -101.91157]],
  [6967990, [31.43954, 34.38053]],
  [1005544, [-28.76197, 31.89329]],
  [1808486, [30.12932, 121.2371]],
  [2654305, [54.92181, -3.05671]],
  [3187142, [44.26472, 15.2325]],
  [1121418, [32.93742, 68.64622]],
  [8949138, [41.83449, 13.09092]],
  [3124975, [40.96662, -5.01405]],
  [8859382, [18.46778, -95.23806]],
  [4164143, [25.79065, -80.13005]],
  [3134011, [59.02064, 8.5202]],
  [2992878, [43.4565, 1.52156]],
  [8299847, [50.95397, -2.74971]],
  [3345437, [51.54057, -0.14334]],
  [3183518, [45.16404, 10.43323]],
  [262420, [39.60111, 20.31194]],
  [681038, [46.47371, 26.76813]],
  [8949601, [46.03229, 11.39115]],
  [524854, [56.29123, 28.7642]],
  [1255265, [16.48333, 80.6]],
  [548605, [57.43914, 42.12894]],
  [5779036, [41.30716, -111.96022]],
  [1271839, [21.96957, 71.57828]],
  [683328, [44.28333, 25.80333]],
  [2270380, [32.65043, -16.97718]],
  [1346489, [38.81998, 66.46441]],
  [1861584, [33.55, 133.43333]],
  [3007636, [48.64711, -3.71638]],
  [8860890, [30.8375, -112.82028]],
  [2844277, [50.65, 6.2]],
  [3514459, [20.77676, -98.67999]],
  [674826, [46.55, 26.95]],
  [3096149, [51.99217, 15.78392]],
  [2995830, [49.54492, 2.78972]],
  [934649, [-19.98417, 57.61417]],
  [8863200, [17.13167, -91.54278]],
  [2980976, [42.63229, 3.03333]],
  [261120, [39.33386, 26.31092]],
  [2674649, [55.64277, 13.20638]],
  [2035776, [49.1, 121.03333]],
  [2177233, [-37.82289, 147.61041]],
  [3531139, [20.28635, -99.41319]],
  [8068029, [-6.6367, 111.4822]],
  [3930612, [-13.53333, -75.33333]],
  [3926310, [-14.50889, -71.88528]],
  [286621, [17.01505, 54.09237]],
  [2648970, [53.83333, -2.91667]],
  [1503335, [57.42972, 60.05972]],
  [670474, [44.95, 26.01667]],
  [3038673, [49.50112, 4.34203]],
  [2977952, [48.63479, 2.30831]],
  [2268411, [38.84432, -7.58585]],
  [2187404, [-36.99282, 174.87986]],
  [2648079, [51.93821, 0.87551]],
  [1548531, [32.58611, 120.13333]],
  [2975025, [50.28236, 3.56373]],
  [2158538, [-33.92598, 151.19347]],
  [8449230, [-8.4898, 117.4248]],
  [1792467, [30.71361, 114.91613]],
  [2982327, [45.83333, 1.91667]],
  [2781411, [47.33333, 14.41667]],
  [2646903, [52.55971, 0.38959]],
  [2970761, [48.82345, 2.29025]],
  [2905643, [51.01624, 14.74381]],
  [2638892, [52.36667, -1.43333]],
  [2332249, [9.04439, 6.57089]],
  [1969686, [-3.92753, 119.7993]],
  [2654357, [51.1893, -1.76009]],
  [5097421, [40.8201, -74.36487]],
  [4024921, [20.45212, -101.29337]],
  [2505629, [35.38901, 5.36584]],
  [3029373, [48.97064, 7.22262]],
  [1184845, [29.14269, 71.25771]],
  [782393, [40.70583, 20.69972]],
  [2984686, [45.91374, 4.77698]],
  [3124248, [41.95, 3.01667]],
  [2655882, [53.53333, -1.15]],
  [2769631, [48.20157, 15.64917]],
  [1000501, [-33.30422, 26.53276]],
  [71136, [15.4, 47.76667]],
  [2797490, [50.61391, 5.39008]],
  [1684818, [18.0343, 120.5488]],
  [2157076, [-31.01775, 151.07062]],
  [766020, [49.9163, 20.69395]],
  [3430186, [-25.56332, -59.33781]],
  [3521610, [17.22715, -92.41511]],
  [2859302, [47.93333, 7.95]],
  [3167647, [41.08932, 14.49743]],
  [8323079, [-8.1609, 111.7603]],
  [2327236, [8.03453, 3.34759]],
  [1538642, [53.5777, 83.7577]],
  [1174062, [28.58459, 65.41501]],
  [3105103, [41.49224, -5.68359]],
  [2652558, [53.91167, -1.41174]],
  [7059590, [29.20685, 110.00508]],
  [3101613, [53.16905, 15.42054]],
  [2770838, [47.17554, 16.10184]],
  [485824, [56.42274, 40.44668]],
  [1260135, [19.17279, 75.17425]],
  [3126161, [42.3413, -4.60071]],
  [3080396, [51.09587, 18.38974]],
  [1726323, [16.15528, 120.5275]],
  [678105, [46.07912, 25.15103]],
  [3816419, [16.15602, -91.41346]],
  [3588043, [15.34672, -90.8705]],
  [1815589, [26.82139, 118.84361]],
  [321297, [38.01412, 41.32372]],
  [2863049, [49.29516, 9.61604]],
  [1265972, [31.11728, 77.53936]],
  [5323781, [36.96356, -121.77912]],
  [2885656, [52.4455, 13.57455]],
  [1510633, [55.7447, 83.3643]],
  [2514399, [37.21538, -2.06677]],
  [6534491, [45.79138, 9.55165]],
  [1213559, [5.0136, 95.9153]],
  [3429866, [-27.00147, -59.84243]],
  [11704373, [-32.07167, 152.53491]],
  [1278297, [24.57578, 77.73123]],
  [3007769, [48.47395, 4.88368]],
  [2643183, [55.33333, -4.81667]],
  [8077988, [-7.0771, 108.8205]],
  [6943733, [47.8994, -73.7772]],
  [8861592, [21.23472, -98.825]],
  [2933965, [51.00762, 14.58621]],
  [892156, [-19.06667, 31.75]],
  [2506795, [26.96667, 1.08333]],
  [462522, [47.1474, 42.4721]],
  [4290988, [37.69395, -85.85913]],
  [8067580, [-7.1053, 108.557]],
  [3068162, [49.46614, 17.01502]],
  [9883901, [52.79534, 1.47569]],
  [3025312, [49.50622, 2.84391]],
  [3096106, [49.5193, 19.9849]],
  [1806958, [31.48328, 120.34296]],
  [2739920, [40.64899, -8.62943]],
  [8134743, [29.06531, 111.68521]],
  [2882103, [50.87136, 11.6725]],
  [3522563, [15.50852, -92.10958]],
  [3687903, [3.91667, -76.5]],
  [2520529, [38.1, -6.33333]],
  [3489331, [18.26844, -78.3481]],
  [3105620, [40.75865, -4.87379]],
  [4257137, [38.87838, -87.08584]],
  [932984, [-24.03333, 21.9]],
  [1185106, [24.91965, 89.94812]],
  [8948821, [40.25822, 16.25661]],
  [8862558, [21.20972, -98.87028]],
  [1696614, [13.96689, 121.69835]],
  [1795579, [36.24111, 115.66722]],
  [2078732, [-31.49985, 115.58737]],
  [1626103, [-6.85861, 107.91639]],
  [3166930, [41.81311, 14.48655]],
  [3111916, [42.6296, -4.50343]],
  [1710105, [8.2, 123.53333]],
  [3067577, [50.16027, 14.82502]],
  [2516507, [37.81104, -2.54116]],
  [2637461, [53.76987, -0.60107]],
  [1260688, [16.5779, 81.29463]],
  [2643096, [53.16495, -1.19384]],
  [668167, [44.25, 23.43333]],
  [1706393, [10.3991, 123.9992]],
  [3314419, [53.13815, -6.84024]],
  [2823423, [53.71009, 12.58998]],
  [2473738, [33.82063, 10.85394]],
  [6127749, [51.5501, -108.00136]],
  [729114, [43.4125, 23.225]],
  [8503652, [37.04634, -88.56856]],
  [2987275, [42.96106, -0.07385]],
  [2638946, [52.28927, -0.60184]],
  [2904397, [53.41667, 7.18333]],
  [2061745, [-34.83934, 138.48228]],
  [4868092, [40.71471, -94.23523]],
  [2774875, [47.70766, 13.6105]],
  [2980716, [46.74339, -1.11421]],
  [3181402, [45.48548, 12.41696]],
  [8392876, [-6.9653, 113.5521]],
  [3860791, [-28.6, -62.95]],
  [2746804, [51.58417, 4.31944]],
  [2500401, [36.4525, 6.63639]],
  [3208325, [48.67065, 11.46983]],
  [4172206, [30.19274, -81.37064]],
  [759412, [52.16772, 22.29006]],
  [763914, [49.82901, 22.42704]],
  [2973841, [48.95, 2.38333]],
  [6535392, [45.49886, 7.90262]],
  [664102, [44.43474, 24.69524]],
  [6535517, [44.59224, 8.27214]],
  [3832756, [-31.94585, -65.18958]],
  [4236555, [37.75199, -89.06785]],
  [3489344, [17.96526, -77.60528]],
  [3704187, [7.98179, -80.43764]],
  [3201412, [43.46972, 17.15639]],
  [3125625, [41.39329, -3.80196]],
  [8445586, [-9.7018, 120.4918]],
  [3827138, [19.78643, -99.66879]],
  [2854957, [53.29695, 14.23616]],
  [2205977, [-34.08384, 151.15016]],
  [5508487, [39.5138, -119.92603]],
  [3513966, [19.7967, -99.09946]],
  [7536146, [61.92215, 33.38676]],
  [2795102, [50.80178, 3.92595]],
  [669371, [47.76667, 26.68333]],
  [2784280, [50.97832, 5.711]],
  [1702540, [14.8443, 120.81039]],
  [2921547, [54.20272, 12.30168]],
  [4147258, [27.92141, -82.81705]],
  [7375252, [-8.2051, 112.3427]],
  [1548471, [32.32415, 120.17484]],
  [751493, [40.56972, 36.13917]],
  [5160854, [40.77145, -82.48433]],
  [3005763, [47.31107, -1.31976]],
  [1785570, [30.42009, 110.50125]],
  [1975071, [24.60237, 118.10399]],
  [2187826, [-39.55, 174.13333]],
  [3076778, [49.60192, 13.34423]],
  [3190776, [43.27306, 19.99944]],
  [2659692, [45.95455, 8.94725]],
  [3130479, [40.81909, -5.44699]],
  [5127474, [42.71257, -76.4216]],
  [2650341, [51.24639, 1.30776]],
  [3167357, [46.55, 11.0]],
  [1253275, [18.46523, 78.86894]],
  [2641183, [51.30222, -2.32472]],
  [2172987, [-38.33333, 142.51667]],
  [2997966, [47.75714, -2.78713]],
  [5140675, [40.72149, -73.7693]],
  [1783857, [25.55823, 116.27568]],
  [294508, [32.18529, 34.82082]],
  [1645978, [-7.53333, 110.75]],
  [680831, [46.95, 26.86667]],
  [8067980, [-6.9904, 111.3746]],
  [1913808, [20.05278, 110.41347]],
  [7579585, [-8.0649, 111.9694]],
  [3117575, [40.79139, -5.14772]],
  [3197974, [45.73221, 16.10143]],
  [5026959, [46.92911, -92.91965]],
  [5196357, [40.81645, -79.52199]],
  [2860537, [51.13294, 14.40488]],
  [7026819, [45.71924, 33.3903]],
  [627123, [54.4611, 30.0018]],
  [3394549, [-7.88944, -37.12]],
  [11593866, [47.13124, 15.34764]],
  [8862528, [18.51278, -97.09389]],
  [4965529, [44.47841, -68.03834]],
  [3129004, [41.86162, -3.7298]],
  [3115735, [41.84923, -2.29474]],
  [4092333, [33.75566, -87.05]],
  [3030114, [47.99847, 1.77937]],
  [8950247, [43.70852, 10.50462]],
  [4273096, [39.46527, -95.73637]],
  [8862072, [16.76833, -98.58833]],
  [8861021, [17.04722, -92.68472]],
  [616744, [40.56497, 45.2367]],
  [1727369, [15.5019, 121.0275]],
  [3173246, [44.10028, 10.96135]],
  [1259773, [16.29078, 80.26233]],
  [2064546, [-31.86298, 115.75624]],
  [2818142, [49.90166, 9.67123]],
  [7911937, [-7.3278, 107.7939]],
  [566199, [54.21386, 49.61838]],
  [4025172, [19.62116, -101.13331]],
  [2966515, [53.56667, -7.76667]],
  [3515385, [20.12274, -97.85053]],
  [3024468, [44.44863, 0.02543]],
  [8647245, [-2.9637, 114.2994]],
  [1254908, [28.6686, 75.03207]],
  [509768, [56.23333, 43.63333]],
  [687824, [48.03104, 38.80934]],
  [3816921, [15.14598, -92.70138]],
  [2761670, [48.43211, 15.4321]],
  [2747545, [53.075, 6.67083]],
  [3170724, [42.85, 12.75]],
  [2980192, [49.55137, 0.45599]],
  [669804, [46.43091, 23.44034]],
  [2851310, [51.28333, 14.76667]],
  [2639924, [52.27183, -3.00579]],
  [2768953, [48.01737, 16.26354]],
  [2991361, [49.04712, 2.62493]],
  [3172031, [40.18238, 9.14679]],
  [2694329, [63.5489, 19.38735]],
  [3946475, [-15.62139, -71.97889]],
  [2024461, [53.06972, 132.94011]],
  [3814639, [-4.07771, -80.7369]],
  [8349351, [-27.60709, 151.96688]],
  [3208536, [54.08333, 9.78333]],
  [584329, [53.82861, 58.59333]],
  [3168509, [41.9814, 14.56818]],
  [4026809, [19.30717, -100.21551]],
  [1622278, [-8.3884, 123.1648]],
  [8859565, [20.75056, -100.23556]],
  [3247449, [50.77664, 6.08342]],
  [2981041, [48.84598, 2.20289]],
  [8070864, [-6.8032, 111.3914]],
  [6535667, [38.17997, 15.30629]],
  [229746, [0.84917, 32.47306]],
  [8863137, [19.25, -99.6575]],
  [2748314, [53.09763, 5.75993]],
  [2969502, [45.88234, 6.17709]],
  [2770941, [47.35, 10.66667]],
  [8056360, [-6.5094, 111.0227]],
  [1846544, [34.68164, 126.90694]],
  [705883, [45.44445, 29.26396]],
  [769534, [51.22952, 21.98158]],
  [1219002, [37.83573, 65.21058]],
  [2772105, [46.8925, 15.84972]],
  [8986865, [45.48202, 13.61305]],
  [3134331, [59.26754, 10.40762]],
  [1640904, [-7.1854, 108.3704]],
  [1726591, [11.04194, 122.85222]],
  [3397763, [-6.22222, -57.75278]],
  [2524400, [37.8805, 15.30735]],
  [2660840, [46.11422, 7.49407]],
  [7890118, [31.80725, 35.3277]],
  [8949740, [42.29167, 14.01126]],
  [2941155, [49.83333, 7.71667]],
  [672842, [44.66667, 25.23333]],
  [1805661, [29.89545, 106.5639]],
  [3011437, [49.39386, 6.27727]],
  [2934643, [53.57629, 11.20497]],
  [2781219, [47.31667, 16.1]],
  [6535538, [44.68455, 8.70395]],
  [3519570, [21.65072, -99.81916]],
  [11919765, [43.28198, 5.39607]],
  [2798897, [51.10385, 4.9999]],
  [2960410, [49.58769, 6.17065]],
  [3071497, [49.06358, 17.05428]],
  [4017930, [20.22048, -103.94397]],
  [3036843, [48.98333, 2.41667]],
  [8402893, [-9.8676, 124.2584]],
  [2680460, [59.28333, 18.3]],
  [2636018, [54.04349, -0.71827]],
  [3696127, [-5.86722, -78.26167]],
  [2169568, [-26.98333, 152.8]],
  [2925494, [49.22778, 8.05806]],
  [8861444, [18.52722, -97.09194]],
  [2945136, [50.2736, 7.64508]],
  [2992070, [48.15159, -1.23743]],
  [8861422, [20.95056, -97.46056]],
  [8858355, [19.38333, -102.03611]],
  [709098, [48.06553, 29.14595]],
  [2840130, [49.80719, 7.23724]],
  [3393832, [-4.70667, -40.56306]],
  [3067827, [48.92726, 16.66691]],
  [3762713, [17.90008, -95.96175]],
  [674871, [44.36683, 24.1522]],
  [2983041, [48.63931, -0.96605]],
  [2655643, [52.94978, -0.95907]],
  [682731, [45.91095, 21.15686]],
  [1731248, [12.2188, 125.4808]],
  [1921612, [25.89519, 116.70631]],
  [1791521, [28.0388, 117.33908]],
  [2641773, [52.48333, -2.08333]],
  [3029995, [45.29419, 3.38423]],
  [2811278, [50.96667, 7.86667]],
  [313087, [36.8158, 38.94655]],
  [3176627, [44.29094, 7.80353]],
  [7410831, [-8.4457, 115.6424]],
  [3172170, [44.76246, 8.787]],
  [3098309, [52.03855, 18.05972]],
  [2984855, [50.17921, 2.98221]],
  [3943259, [-15.1, -73.76667]],
  [3823795, [18.83, -96.77639]],
  [3022868, [48.91906, 2.60431]],
  [3446019, [-29.94333, -51.71806]],
  [1797156, [28.04528, 120.24756]],
  [3461080, [-20.43806, -48.01222]],
  [2810184, [48.51916, 9.95494]],
  [2145135, [-27.58333, 152.93333]],
  [2967604, [47.26, 2.22201]],
  [1815131, [23.02921, 114.30492]],
  [1647382, [-6.9729, 112.8851]],
  [2992104, [43.72004, 2.32605]],
  [2025691, [58.75131, 130.60613]],
  [4584125, [33.66766, -79.83063]],
  [2961873, [53.50767, -7.1351]],
  [2675416, [63.16667, 17.26667]],
  [3178930, [44.38505, 8.02769]],
  [8950153, [42.25926, 12.68847]],
  [4036284, [-19.05451, -169.91768]],
  [5690958, [47.95335, -102.13489]],
  [2805357, [51.78503, 11.92419]],
  [3844899, [-32.6978, -62.10672]],
  [3169594, [42.20931, 14.18493]],
  [2773804, [47.11667, 15.11667]],
  [2652264, [52.71384, -0.6633]],
  [701067, [46.05708, 29.39201]],
  [2145532, [-41.16026, 146.18242]],
  [1644979, [-9.5686, 119.3172]],
  [2995256, [47.00551, -0.3437]],
  [3530938, [18.90139, -97.10806]],
  [1787580, [26.64083, 118.50806]],
  [3834814, [-26.85275, -65.70983]],
  [3172444, [44.31693, 8.16815]],
  [1550699, [29.09, 107.58194]],
  [3124128, [42.05927, -5.05539]],
  [2655712, [52.13847, -0.50687]],
  [6589669, [45.37727, 10.79664]],
  [3589577, [14.93052, -91.91388]],
  [2976292, [50.47135, 3.33544]],
  [4815462, [39.92035, -80.74314]],
  [3168860, [46.25008, 11.17233]],
  [282615, [32.22111, 35.25444]],
  [3175804, [45.72895, 9.54097]],
  [2809286, [52.23071, 14.59107]],
  [3175458, [40.73793, 13.94862]],
  [3287657, [43.02194, 17.58389]],
  [2522293, [28.06205, -17.24073]],
  [3176019, [44.89645, 9.05667]],
  [1812410, [38.82722, 100.79806]],
  [541629, [57.06676, 30.09032]],
  [8724457, [20.43686, -97.70114]],
  [6822119, [34.48562, 135.69698]],
  [696483, [48.68755, 24.26322]],
  [1220905, [38.38917, 69.32272]],
  [525262, [56.43028, 48.99472]],
  [8067277, [-7.6628, 108.6662]],
  [3164908, [44.41181, 7.38068]],
  [3021397, [44.52563, 5.0618]],
  [8949411, [39.66906, 16.43182]],
  [3062360, [49.281, 17.87682]],
  [3164172, [45.59938, 10.45275]],
  [3093268, [52.84436, 19.17852]],
  [2930613, [49.58028, 7.51305]],
  [9863201, [26.2695, 86.06346]],
  [774472, [51.11177, 22.13269]],
  [1919846, [23.08521, 116.36664]],
  [2644268, [52.45784, 0.30603]],
  [4025210, [19.65272, -101.31571]],
  [2716867, [59.89304, 18.49174]],
  [1784185, [24.355, 102.54222]],
  [2809341, [50.34065, 6.67332]],
  [3818597, [20.24253, -98.10069]],
  [3085182, [50.09416, 18.44896]],
  [3981505, [18.90301, -100.73731]],
  [3127933, [42.97287, -4.92419]],
  [1271306, [25.58333, 83.58526]],
  [2784821, [50.58907, 5.86241]],
  [2554006, [33.44976, -7.65239]],
  [1788462, [36.49889, 111.70472]],
  [1184008, [27.75431, 68.75866]],
  [3038596, [45.34016, 4.85324]],
  [2786773, [50.22585, 4.4165]],
  [683058, [47.31185, 22.06225]],
  [7787740, [-6.82336, 106.0528]],
  [10062600, [54.69981, 25.18393]],
  [7035314, [-8.0811, 112.2912]],
  [688361, [46.50966, 30.07587]],
  [667921, [44.8007, 23.87074]],
  [2972439, [49.79917, 1.17526]],
  [3515040, [20.21173, -87.46325]],
  [2161034, [-38.43194, 145.82355]],
  [662769, [46.31667, 22.51667]],
  [735563, [40.30069, 21.78896]],
  [3925749, [-14.21806, -71.43167]],
  [711938, [47.8193, 31.12491]],
  [4073357, [34.59065, -87.67697]],
  [9972848, [-33.76434, 151.29727]],
  [683248, [46.07058, 27.2301]],
  [3926751, [-12.74136, -75.80942]],
  [2989880, [49.55336, 2.26175]],
  [3186472, [46.315, 15.47972]],
  [3694075, [-8.25, -78.01667]],
  [5044315, [45.47858, -94.15415]],
  [3103236, [51.90372, 18.01003]],
  [663197, [44.23778, 26.24861]],
  [2021017, [63.89482, 122.7654]],
  [3519281, [16.18175, -96.76099]],
  [2948551, [50.43333, 11.75]],
  [762851, [51.49416, 22.85287]],
  [3181594, [45.97557, 13.45442]],
  [7036331, [-7.1095, 111.7419]],
  [2944335, [50.1, 7.11667]],
  [6696812, [-22.81226, 148.69926]],
  [8861864, [19.56167, -101.27778]],
  [689225, [49.20656, 27.6556]],
  [7921738, [-7.0984, 107.8827]],
  [2809092, [52.65, 9.83333]],
  [2804283, [48.65244, 10.39882]],
  [233508, [0.05621, 32.47949]],
  [8949998, [40.5165, 17.13837]],
  [2890646, [49.20056, 9.98226]],
  [1267887, [10.91667, 79.83333]],
  [8950381, [40.81516, 14.7864]],
  [2146282, [-27.03221, 153.09825]],
  [1919861, [23.03153, 116.352]],
  [2511752, [38.08799, -0.72544]],
  [6943660, [18.53017, 73.85263]],
  [2038660, [45.2, 115.5]],
  [4499389, [35.72127, -77.91554]],
  [7231955, [37.98996, -75.42272]],
  [5940934, [48.8786, -72.23142]],
  [1257277, [31.50988, 77.79395]],
  [5483380, [35.2006, -106.70114]],
  [2624595, [55.30353, 10.46428]],
  [3180673, [42.25001, 13.67455]],
  [5223505, [41.4501, -71.4495]],
  [2350428, [12.28835, 4.47139]],
  [7005616, [42.87324, 124.05192]],
  [680871, [45.25323, 24.8241]],
  [688333, [47.42089, 31.87288]],
  [747205, [40.01927, 35.20788]],
  [1162868, [28.17723, 68.04367]],
  [11609983, [23.82037, 90.12175]],
  [2960120, [49.72722, 5.98639]],
  [3167830, [45.91391, 12.25101]],
  [3180649, [43.05064, 9.84298]],
  [1529639, [46.52089, 90.21681]],
  [1538415, [44.4607, 84.52827]],
  [246013, [31.9545, 35.81831]],
  [2978122, [48.49724, 1.07767]],
  [3196425, [46.05861, 14.8225]],
  [8310696, [-28.3641, 153.5748]],
  [561670, [43.16354, 45.47238]],
  [588454, [59.42607, 24.5533]],
  [2921996, [50.64852, 11.00471]],
  [1816419, [29.81644, 113.08251]],
  [3620994, [10.18561, -84.39076]],
  [3384482, [5.05594, -54.98043]],
  [3045076, [46.28181, 18.99524]],
  [2770993, [47.6066, 15.67226]],
  [5860661, [59.67611, -151.5575]],
  [7350558, [-8.2397, 113.2745]],
  [8509014, [47.96193, 126.39573]],
  [2989579, [41.71724, 8.91783]],
  [3939585, [-16.03287, -71.87794]],
  [3073928, [50.77359, 14.47287]],
  [7847439, [-7.5238, 107.4909]],
  [2921831, [50.35, 7.93333]],
  [1734199, [4.24482, 117.89115]],
  [2983779, [45.93577, 4.81453]],
  [8949161, [45.60181, 11.3674]],
  [616418, [40.1814, 44.40033]],
  [2880079, [50.08333, 6.33333]],
  [3102513, [52.66884, 19.50399]],
  [2787614, [50.70863, 4.78266]],
  [3125445, [42.34183, -8.08982]],
  [2796629, [50.42675, 5.53304]],
  [8411515, [39.12197, 117.15751]],
  [1718412, [10.8315, 124.7518]],
  [676109, [46.90877, 22.91302]],
  [2994194, [48.6118, 6.13959]],
  [2660869, [47.67057, 9.08573]],
  [3682245, [3.15, -75.81667]],
  [656220, [68.65986, 27.53891]],
  [1724959, [9.91845, 123.60743]],
  [12795, [33.03247, 36.5742]],
  [787650, [42.42389, 20.80167]],
  [2657340, [51.71399, -1.905]],
  [5909294, [44.59132, -75.68705]],
  [11748016, [48.01245, 13.65472]],
  [610864, [42.10916, 43.03602]],
  [1802036, [31.77082, 118.86817]],
  [1881661, [30.45607, 120.54673]],
  [294205, [33.02781, 35.25658]],
  [4161685, [30.04413, -82.07177]],
  [155016, [-8.93333, 36.13333]],
  [8322507, [40.7916, 30.295]],
  [8861096, [18.75361, -98.53667]],
  [7760146, [-6.7589, 105.8671]],
  [3164608, [45.75246, 8.89734]],
  [2936412, [53.954, 12.26147]],
  [3100984, [53.79589, 17.97647]],
  [582504, [43.1425, 44.8525]],
  [3130476, [41.47285, -4.05711]],
  [5039554, [45.10274, -93.07911]],
  [8722408, [21.10861, -98.47194]],
  [609924, [48.82981, 58.15042]],
  [8516167, [50.55731, 30.33217]],
  [3816514, [16.33366, -92.07771]],
  [1794532, [31.9925, 121.61417]],
  [172922, [33.74424, 36.70394]],
  [667515, [46.36667, 22.11667]],
  [2798798, [50.88476, 2.8171]],
  [11549806, [41.43884, 2.16893]],
  [733941, [40.47966, 23.13695]],
  [4496065, [35.80903, -80.37283]],
  [3217719, [45.94679, 12.60607]],
  [2780859, [46.82833, 12.84528]],
  [3458817, [-11.38333, -41.83194]],
  [1693502, [7.2838, 125.8535]],
  [7685356, [27.77722, 115.12625]],
  [7367770, [34.29057, 107.51583]],
  [2166746, [-27.45475, 152.65321]],
  [1253958, [18.39258, 77.11756]],
  [2654271, [52.94525, 0.72608]],
  [2986760, [48.80234, -3.15737]],
  [5771179, [41.61465, -112.12662]],
  [1672228, [23.5654, 119.58627]],
  [6534420, [45.71456, 7.73451]],
  [3169213, [45.68829, 9.43712]],
  [757886, [51.04776, 20.82922]],
  [3199632, [42.5, 19.19583]],
  [3076812, [50.587, 15.66432]],
  [7262515, [46.18399, -122.91715]],
  [721913, [48.25561, 21.89195]],
  [1642207, [-6.3595, 105.9416]],
  [3021394, [48.84137, 6.06782]],
  [3981115, [30.82385, -108.29884]],
  [3105458, [41.61005, -5.54545]],
  [4533130, [35.49756, -97.26892]],
  [5365937, [33.93807, -118.35258]],
  [774158, [51.24838, 21.57475]],
  [2336621, [12.8985, 10.80688]],
  [6534487, [45.57258, 9.50295]],
  [2852376, [50.76031, 7.64871]],
  [2704654, [56.78685, 15.14023]],
  [2035635, [40.9241, 122.80437]],
  [549191, [53.8119, 49.0916]],
  [3171186, [45.23606, 8.41651]],
  [1272025, [20.92206, 75.32641]],
  [3170622, [40.39017, 16.55919]],
  [2034135, [42.93444, 119.02389]],
  [3655720, [-3.47523, -80.23084]],
  [4190940, [32.97904, -82.60791]],
  [8428969, [37.53946, 121.32661]],
  [3046505, [46.16033, 18.42321]],
  [681430, [46.73333, 21.58333]],
  [5394086, [33.74141, -118.10479]],
  [3105046, [40.33468, -3.23399]],
  [2967414, [50.33224, 3.41252]],
  [2947739, [49.23724, 7.25617]],
  [2073124, [-12.46113, 130.84185]],
  [3087499, [53.0867, 19.19415]],
  [3822011, [16.67641, -99.17764]],
  [3987248, [19.39039, -100.10639]],
  [7648330, [20.32792, 103.40856]],
  [3532655, [16.76195, -96.72137]],
  [3407297, [-6.82833, -35.75833]],
  [3087992, [52.3344, 18.89915]],
  [10371104, [4.89333, 7.00228]],
  [8019924, [53.5871, 27.0535]],
  [3122399, [41.02361, -5.76252]],
  [3111305, [40.31667, -4.48333]],
  [3016282, [43.25296, -0.05201]],
  [546521, [56.29929, 39.38304]],
  [8333945, [21.63912, 110.63235]],
  [438184, [36.06907, 36.37076]],
  [8057385, [-7.4072, 108.5861]],
  [8349227, [-20.07453, 146.2817]],
  [9036154, [46.08729, 10.34075]],
  [4780337, [37.20985, -80.49005]],
  [624861, [54.8789, 26.9371]],
  [2981816, [45.669, 3.10788]],
  [2650776, [56.00062, -2.51418]],
  [2806002, [53.0313, 10.29848]],
  [511839, [54.85355, 32.03154]],
  [2977869, [48.31279, -3.16605]],
  [2864613, [52.72434, 14.27922]],
  [9610406, [54.81998, 23.84462]],
  [8548593, [45.61898, 85.05703]],
  [4013665, [21.92347, -105.25405]],
  [2858426, [51.39172, 11.65312]],
  [2755302, [52.12333, 5.15278]],
  [3026220, [47.28229, 3.22648]],
  [2647346, [51.82655, 0.24651]],
  [1683642, [6.72532, 124.62342]],
  [709114, [46.56646, 30.85965]],
  [3699544, [-7.71444, -79.10778]],
  [8860798, [20.57694, -99.63028]],
  [8861158, [19.22845, -99.58291]],
  [2521128, [36.79075, -4.16146]],
  [4188197, [34.6126, -83.52489]],
  [3030742, [49.93725, 1.63431]],
  [2645368, [52.87141, -0.99169]],
  [8067315, [-7.6764, 108.6324]],
  [6666346, [-9.88619, -76.50318]],
  [3936391, [-13.0, -74.71667]],
  [3074731, [49.31689, 17.46374]],
  [3114138, [41.34145, -4.58225]],
  [2979122, [45.12996, 2.13922]],
  [8348205, [-25.1699, 152.36127]],
  [3038073, [47.16533, 2.22753]],
  [11746753, [48.16139, 14.03838]],
  [7342980, [34.06363, 103.21358]],
  [1790364, [26.11312, 115.56313]],
  [3047967, [45.9902, 18.68621]],
  [2502958, [36.37489, 3.902]],
  [3069787, [49.615, 14.6986]],
  [5309842, [34.54002, -112.4685]],
  [342856, [5.78333, 36.56667]],
  [2805657, [48.35, 12.33333]],
  [684054, [47.66667, 24.15]],
  [2984895, [43.75959, 5.34087]],
  [2745195, [52.01088, 5.09285]],
  [3173504, [44.38275, 9.37667]],
  [8860347, [20.60944, -98.3625]],
  [662671, [46.83703, 24.29503]],
  [1812597, [35.85, 117.7]],
  [152169, [-4.15, 37.85]],
  [1816964, [33.87028, 113.06528]],
  [5653639, [45.5916, -111.19773]],
  [684193, [47.34616, 24.17731]],
  [2857649, [47.63333, 11.23333]],
  [3197594, [45.23167, 16.53917]],
  [8546200, [48.71603, 128.94221]],
  [1500468, [54.4399, 83.4984]],
  [1898493, [31.16097, 120.71177]],
  [2743977, [52.09, 5.23333]],
  [3114957, [43.25541, -2.01873]],
  [875389, [56.23361, 43.9825]],
  [2727748, [55.4, 13.6]],
  [3012057, [47.88452, 7.19604]],
  [298088, [39.17375, 41.45402]],
  [3179387, [45.91247, 13.18167]],
  [5439284, [39.21304, -106.93782]],
  [1703964, [7.55556, 125.72667]],
  [8859335, [19.02194, -97.81028]],
  [5342434, [36.65912, -119.59374]],
  [8392684, [-10.1633, 123.688]],
  [3182927, [46.14611, 12.79009]],
  [7463779, [-8.6832, 122.2073]],
  [2987005, [50.92723, 2.33033]],
  [3513988, [19.31769, -98.84967]],
  [7161969, [38.89472, -76.88654]],
  [1526900, [45.0, 72.78333]],
  [8860943, [19.39806, -98.03528]],
  [3035441, [44.07281, 4.05137]],
  [716151, [47.93333, 20.11667]],
  [7036091, [-7.1358, 111.6394]],
  [294435, [32.39517, 35.00977]],
  [3109957, [41.81667, 3.0]],
  [7373359, [33.77105, 105.81809]],
  [2874747, [51.25671, 12.14164]],
  [2867868, [49.96878, 6.40469]],
  [686587, [45.93003, 23.9937]],
  [2111425, [36.55, 140.41667]],
  [1605074, [16.72229, 104.74135]],
  [3353871, [-22.35, 16.13333]],
  [1692184, [14.6, 120.9833]],
  [618120, [46.63674, 29.41114]],
  [3201750, [46.14194, 15.11278]],
  [542452, [47.0114, 42.2072]],
  [2974108, [47.73805, -1.30589]],
  [7559741, [-9.5739, 119.4456]],
  [5793780, [47.58482, -122.59875]],
  [3080652, [50.94116, 16.49466]],
  [2973108, [45.13011, 1.30136]],
  [596128, [55.73333, 24.35]],
  [7083111, [-8.15454, 113.72249]],
  [3023754, [42.65292, 2.66053]],
  [3994167, [29.92573, -110.62933]],
  [1919652, [23.59737, 116.47587]],
  [3116802, [42.58544, -0.95478]],
  [1704897, [6.6189, 124.88156]],
  [668811, [45.05545, 24.33125]],
  [3530948, [20.63176, -88.21689]],
  [2787572, [51.09589, 3.20743]],
  [3172540, [45.29358, 9.68135]],
  [6115355, [44.18342, -77.56618]],
  [5511806, [35.81692, -115.63223]],
  [3532559, [18.72559, -97.18447]],
  [1734821, [2.6931, 101.7498]],
  [3083053, [50.30979, 19.76715]],
  [3001243, [49.30216, 1.17758]],
  [151062, [-2.55, 33.2]],
  [3516627, [16.80381, -96.72046]],
  [2657125, [55.65018, -4.80659]],
  [1808077, [30.49875, 119.00447]],
  [1716771, [7.22361, 124.24639]],
  [2833657, [50.77266, 12.24361]],
  [3169465, [44.65451, 7.39026]],
  [6822182, [35.73531, 140.12445]],
  [663002, [44.35417, 22.82566]],
  [735481, [40.75, 23.06667]],
  [3181990, [43.89575, 11.32095]],
  [2782782, [48.02633, 14.79009]],
  [1262648, [9.34169, 78.51388]],
  [2981448, [45.71823, 4.34045]],
  [3513760, [12.13333, -68.26667]],
  [7083726, [-8.5538, 115.3847]],
  [7751198, [29.06239, 115.22124]],
  [2972084, [48.40137, -2.40186]],
  [2874545, [52.12773, 11.62916]],
  [3526696, [18.85364, -97.06229]],
  [701846, [48.12868, 25.04827]],
  [8861803, [20.94944, -101.40417]],
  [681828, [45.83729, 24.83322]],
  [2981249, [44.84237, 0.65323]],
  [8950222, [45.66634, 10.34386]],
  [2917011, [54.23333, 10.16667]],
  [2316473, [4.1898, 19.8833]],
  [3033056, [43.79861, 1.60624]],
  [772662, [50.27288, 21.84365]],
  [3515530, [20.94427, -89.71443]],
  [2990637, [47.5484, 0.54962]],
  [4351065, [39.32316, -76.6133]],
  [2364812, [9.76806, 1.10528]],
  [4817641, [39.26674, -81.56151]],
  [3174435, [45.76701, 8.99897]],
  [4341033, [30.23576, -92.09457]],
  [1513600, [40.66886, 72.56002]],
  [2637582, [52.47583, 0.92912]],
  [1916419, [29.8987, 119.53928]],
  [2861698, [51.93025, 12.59554]],
  [621074, [52.6329, 29.7389]],
  [2337319, [8.08747, 4.39264]],
  [8514877, [30.62215, 113.76161]],
  [11371802, [19.67322, -98.9028]],
  [3164557, [45.60372, 9.62852]],
  [3985618, [25.4151, -104.27844]],
  [1699169, [13.8526, 120.98935]],
  [1347927, [30.75236, 67.71133]],
  [8859820, [27.07722, -109.5275]],
  [3036062, [43.56627, 1.05781]],
  [1835235, [36.34913, 127.38493]],
  [3983392, [24.62768, -107.65629]],
  [8951163, [45.29838, 7.46136]],
  [1915237, [21.39545, 110.72832]],
  [4687678, [29.53107, -96.07107]],
  [764751, [51.70374, 22.8883]],
  [1513064, [40.84472, 69.59833]],
  [2660119, [46.92436, 7.41457]],
  [3017020, [47.68333, 7.26667]],
  [8348702, [-27.54628, 151.97218]],
  [3121339, [40.99981, -5.90687]],
  [3172747, [41.7053, 15.96068]],
  [1813171, [29.12944, 110.47833]],
  [2739590, [40.15085, -8.86179]],
  [117111, [36.1552, 45.4788]],
  [1502060, [58.3638, 60.0407]],
  [2759180, [52.22417, 6.68333]],
  [3386693, [-7.2075, -36.82667]],
  [758626, [52.40677, 22.25307]],
  [2346632, [8.49056, 7.34139]],
  [1728074, [10.681, 124.9595]],
  [653333, [60.53271, 25.10917]],
  [625818, [52.2472, 26.8047]],
  [3107675, [40.97181, -1.79764]],
  [3926003, [-13.58832, -73.99836]],
  [3816188, [16.69921, -92.40292]],
  [1704533, [12.8334, 123.2612]],
  [4718153, [32.97013, -97.47474]],
  [2866022, [49.29181, 9.26975]],
  [2512313, [39.81645, -2.94936]],
  [1926055, [33.5, 132.55]],
  [1858926, [35.28333, 136.91667]],
  [3037113, [50.85786, 1.98168]],
  [1713086, [16.01402, 120.30757]],
  [3031702, [48.18312, 1.38524]],
  [3336568, [55.4, 12.85]],
  [6620339, [-20.73765, 116.84629]],
  [7848916, [-7.4435, 107.2553]],
  [3457398, [-15.39722, -42.86639]],
  [1566093, [20.3881, 106.43834]],
  [120292, [35.7214, 51.109]],
  [3818842, [18.92553, -97.51198]],
  [2845559, [48.73859, 13.52271]],
  [3991100, [19.92728, -102.87271]],
  [3011455, [48.2548, 7.39491]],
  [2791733, [50.46707, 5.2428]],
  [2777701, [48.57422, 14.95789]],
  [3178745, [45.19093, 7.88981]],
  [2651347, [52.92277, -1.47663]],
  [294525, [32.3471, 34.91628]],
  [2547782, [34.83898, -2.86699]],
  [3801612, [16.918, -96.41286]],
  [2988769, [45.47633, 5.55597]],
  [8742289, [20.83139, -97.99028]],
  [2993152, [44.16067, 0.45981]],
  [3002149, [48.89435, 0.33817]],
  [1600752, [17.89747, 102.14981]],
  [2840634, [47.99027, 11.45591]],
  [9083429, [19.30123, -98.11751]],
  [7523551, [30.09487, 121.49822]],
  [1983329, [27.44944, 110.97306]],
  [7610642, [-8.9426, 120.9731]],
  [2981767, [46.18918, 6.39259]],
  [1783842, [26.1486, 119.10216]],
  [3189707, [46.36444, 16.21417]],
  [3165232, [43.18749, 11.66818]],
  [200575, [0.46667, 35.96667]],
  [4358772, [38.61595, -76.61302]],
  [5438567, [39.83776, -105.00137]],
  [5766087, [44.08471, -103.43797]],
  [3522156, [18.25503, -92.0911]],
  [2982855, [43.71959, 0.57932]],
  [3043293, [47.77591, 19.13612]],
  [79088, [13.36473, 44.49046]],
  [551839, [53.8029, 59.569]],
  [2797460, [50.60657, 5.95614]],
  [5120595, [40.75538, -73.6668]],
  [2913773, [49.81667, 7.98333]],
  [662570, [46.35, 25.51667]],
  [702612, [51.0521, 25.40059]],
  [5106279, [41.13121, -74.36737]],
  [2948052, [53.57771, 13.13105]],
  [3938455, [-17.42083, -70.51333]],
  [2644492, [51.34487, -2.31409]],
  [3038341, [46.74048, -1.60911]],
  [691331, [48.75751, 38.96333]],
  [2324576, [12.26938, 14.46552]],
  [676542, [45.40368, 26.75883]],
  [1795611, [33.41235, 115.08091]],
  [3592753, [14.91132, -92.05788]],
  [8950328, [39.34764, 16.13197]],
  [2766549, [47.80335, 16.60875]],
  [2510716, [37.22797, -2.2847]],
  [2519277, [39.46246, -3.608]],
  [621729, [54.0249, 28.0894]],
  [2764186, [46.89444, 14.32861]],
  [692372, [49.26223, 23.85609]],
  [2986960, [48.41767, 7.14579]],
  [2033196, [40.81, 114.87944]],
  [5285182, [35.13469, -119.45623]],
  [5097441, [40.76732, -74.20487]],
  [681691, [44.73333, 26.08333]],
  [3694781, [-6.40417, -79.28444]],
  [2979694, [43.65401, 3.04244]],
  [771317, [50.19163, 21.03144]],
  [738033, [41.06444, 42.50081]],
  [1276867, [26.32293, 91.00632]],
  [4764910, [37.96957, -75.46381]],
  [3526907, [20.17035, -97.11306]],
  [701466, [49.43055, 23.75073]],
  [3826976, [19.52639, -99.935]],
  [1784790, [27.85081, 104.96874]],
  [763483, [50.95383, 20.48367]],
  [6534593, [45.01996, 9.17356]],
  [5515613, [36.72947, -102.51324]],
  [3196375, [46.16667, 16.38333]],
  [283119, [31.47329, 34.94461]],
  [3030562, [47.77395, -2.94508]],
  [1728504, [9.05694, 125.62278]],
  [1553576, [30.00237, 118.21411]],
  [667312, [44.67843, 21.71381]],
  [3014674, [44.81789, -0.21615]],
  [665371, [46.91667, 24.51667]],
  [2743585, [53.24817, 6.04746]],
  [6100086, [49.76862, -119.74633]],
  [685070, [47.51864, 23.50556]],
  [2012780, [49.6513, 117.32557]],
  [3644656, [8.45443, -70.55441]],
  [2750769, [51.70167, 4.62639]],
  [670875, [47.1447, 22.26058]],
  [3038611, [49.11905, -1.1061]],
  [8067899, [-6.9604, 111.3243]],
  [686228, [47.34519, 23.01353]],
  [5184422, [41.21479, -79.38532]],
  [3154209, [59.12478, 11.38754]],
  [3017601, [48.4747, -0.97354]],
  [6822170, [37.43055, 140.60335]],
  [2996055, [46.1466, 6.35552]],
  [5404476, [39.15017, -123.20778]],
  [2798381, [50.70445, 5.71482]],
  [5087122, [42.9162, -70.86366]],
  [7258973, [40.91045, -79.91562]],
  [5843495, [44.0169, -107.95537]],
  [3062042, [49.43405, 13.00193]],
  [681349, [45.86438, 21.97198]],
  [8553249, [20.29905, 105.95198]],
  [741529, [41.0179, 28.56176]],
  [2483746, [33.56391, 6.70326]],
  [2840568, [48.48333, 12.41667]],
  [3011252, [43.4763, 2.63929]],
  [7261421, [46.69882, -111.95207]],
  [7081648, [-8.157, 112.1708]],
  [154635, [-8.98333, 33.96667]],
  [2805006, [48.30003, 12.8426]],
  [2968749, [43.70392, 7.31088]],
  [3120121, [40.54275, -0.75308]],
  [3014638, [46.33096, 6.11363]],
  [3035719, [46.58774, 3.3072]],
  [366487, [10.63246, 30.3797]],
  [408689, [36.09419, 28.01565]],
  [679763, [46.21667, 24.7]],
  [5131321, [41.60815, -74.29904]],
  [8409070, [29.53086, 108.21126]],
  [1803217, [39.23868, 117.19819]],
  [3203026, [45.18528, 18.07]],
  [667518, [45.65037, 24.33646]],
  [7334302, [-8.56487, 115.1655]],
  [2997437, [43.3165, -1.35399]],
  [2835537, [49.33047, 11.02346]],
  [3533431, [18.01714, -98.54018]],
  [761652, [50.43324, 21.2812]],
  [2791735, [50.40573, 4.3953]],
  [3165267, [43.62305, 10.67325]],
  [2637422, [53.67603, -0.50474]],
  [4208442, [33.44734, -84.14686]],
  [1687058, [14.56111, 120.3925]],
  [261071, [38.58145, 21.09169]],
  [3172653, [43.36078, 12.32346]],
  [2710690, [56.73333, 14.53333]],
  [3017483, [45.51715, 3.58847]],
  [713062, [45.58604, 34.5668]],
  [681280, [47.38333, 27.1]],
  [3398920, [-8.00167, -35.29278]],
  [3527017, [17.80448, -98.56417]],
  [3115659, [40.28908, -4.01197]],
  [2638851, [54.49183, -3.58987]],
  [735587, [41.35533, 26.43691]],
  [2788157, [51.15405, 5.5624]],
  [252424, [38.82181, 20.65511]],
  [2843441, [49.71139, 9.17917]],
  [5295860, [32.94782, -112.71683]],
  [1716780, [8.10611, 124.61972]],
  [2978078, [45.04375, 0.34647]],
  [5138029, [41.97343, -74.21209]],
  [7302437, [27.08267, 142.20137]],
  [2636786, [50.91765, -0.45473]],
  [3115666, [40.82063, -3.67266]],
  [2966690, [53.53167, -6.96111]],
  [9036116, [45.87971, 9.97537]],
  [673098, [47.15, 27.51667]],
  [3077754, [50.69972, 13.93964]],
  [1693798, [13.0664, 121.52032]],
  [2991314, [46.55394, -1.158]],
  [4136413, [34.30317, -94.33827]],
  [8017640, [-7.4729, 108.1079]],
  [5238717, [44.25089, -72.76095]],
  [3170687, [46.10078, 8.73873]],
  [289975, [25.61068, 51.09108]],
  [3467314, [-23.47056, -47.73472]],
  [8861713, [16.90306, -99.27028]],
  [1572018, [20.98167, 105.98486]],
  [1822644, [11.12421, 105.05722]],
  [3827493, [19.70405, -99.48507]],
  [7034881, [-8.2027, 112.0024]],
  [677479, [45.95, 26.23333]],
  [4573390, [35.11567, -82.15011]],
  [1524703, [43.76453, 69.17856]],
  [258427, [39.42336, 20.07094]],
  [2725384, [56.275, 12.575]],
  [2262582, [39.47581, -8.54348]],
  [8049739, [28.90196, 89.27481]],
  [1632604, [-7.1313, 108.2661]],
  [8576609, [37.64233, 120.58638]],
  [8424937, [-8.3308, 123.1517]],
  [233886, [0.64996, 33.17128]],
  [2791935, [50.61789, 3.80324]],
  [681047, [45.38333, 26.38333]],
  [10347304, [8.32548, -82.81807]],
  [2853071, [50.7, 13.23333]],
  [3843026, [-32.36597, -62.31126]],
  [2786782, [49.97073, 5.63453]],
  [7337732, [-8.7918, 121.5904]],
  [3068494, [50.19378, 14.11782]],
  [4633573, [36.06006, -87.95281]],
  [3705800, [7.78598, -80.4106]],
  [3025057, [48.75867, 3.21705]],
  [3075686, [49.71631, 17.22412]],
  [7669018, [49.05801, -122.47087]],
  [8347881, [-33.85889, 151.14111]],
  [671184, [43.9, 28.5]],
  [5488600, [33.32897, -105.60443]],
  [2635604, [53.61326, -2.34071]],
  [3121554, [42.94818, -1.28624]],
  [3800608, [15.90658, -96.61295]],
  [3036527, [46.78333, -0.05]],
  [6955345, [-25.90432, 153.09174]],
  [5128492, [40.56538, -74.10292]],
  [2532797, [32.68011, -5.57738]],
  [8384779, [40.60779, 29.1736]],
  [3123445, [42.41636, 1.79223]],
  [146414, [34.93815, 32.97551]],
  [662323, [45.06827, 26.00835]],
  [615005, [41.46556, 46.10528]],
  [3094794, [52.26469, 18.42407]],
  [748879, [40.54889, 34.95333]],
  [1584909, [19.04996, 104.88124]],
  [1708865, [7.56861, 124.98778]],
  [2947067, [47.56667, 9.66667]],
  [3070593, [48.94767, 16.31252]],
  [125609, [31.51338, 50.82672]],
  [4568395, [18.43161, -66.58073]],
  [4149956, [30.60853, -87.33998]],
  [2930210, [49.90417, 8.06528]],
  [5107785, [43.15868, -76.33271]],
  [12076674, [49.16446, -122.23755]],
  [5085688, [43.24202, -71.53813]],
  [2980291, [45.43389, 4.39]],
  [2837470, [50.59465, 12.64139]],
  [3116800, [41.98886, -5.87133]],
  [9645074, [10.78069, 106.69944]],
  [2523354, [37.91624, 14.96288]],
  [8507868, [46.26932, 125.66413]],
  [5541966, [39.55857, -111.86187]],
  [1163905, [32.21707, 70.38315]],
  [2782056, [46.87556, 15.90861]],
  [3014221, [48.56259, -3.15289]],
  [3713588, [9.10863, -79.33694]],
  [2824448, [50.54297, 12.07933]],
  [2958268, [51.67926, 10.9683]],
  [480453, [55.99926, 43.02359]],
  [8860934, [20.41833, -101.11861]],
  [970341, [-23.88782, 30.28708]],
  [1586530, [10.17412, 106.11051]],
  [3526664, [19.90553, -97.81762]],
  [4845411, [41.76204, -72.74204]],
  [694017, [48.42395, 26.29729]],
  [1257313, [22.97415, 75.8271]],
  [4928364, [41.52588, -85.36665]],
  [2817146, [50.35, 7.08333]],
  [1572071, [20.32279, 105.75189]],
  [2974076, [48.0533, 1.78701]],
  [2525506, [37.96667, 16.1]],
  [3018174, [42.96535, 1.60705]],
  [545727, [45.33, 46.0428]],
  [3032932, [45.86098, -0.83714]],
  [4509035, [38.50341, -82.64711]],
  [3690928, [-9.32788, -77.5522]],
  [2929177, [48.88832, 9.86999]],
  [8950372, [43.75536, 13.05896]],
  [7781143, [-6.2923, 105.9717]],
  [2994768, [45.85687, 6.61775]],
  [3174451, [41.04681, 9.20553]],
  [2851567, [48.07579, 11.71572]],
  [2888587, [50.26667, 6.48333]],
  [3818675, [19.90403, -97.59047]],
  [3581514, [18.08124, -88.56328]],
  [3080058, [51.46672, 16.90564]],
  [6072470, [45.2334, -75.46603]],
  [616989, [40.78003, 43.90027]],
  [3168712, [45.75082, 7.64815]],
  [2937959, [51.83864, 12.24555]],
  [757848, [52.25948, 22.25289]],
  [1626548, [-1.14473, 136.03134]],
  [2028445, [46.9, 109.75]],
  [3979962, [26.70483, -109.52103]],
  [3461501, [-22.50917, -48.55778]],
  [1628858, [-2.05853, 125.97786]],
  [8863151, [16.99639, -92.41417]],
  [4820129, [39.52981, -80.11674]],
  [8862370, [17.36861, -98.51722]],
  [4711253, [26.22618, -97.95889]],
  [3667290, [4.8727, -74.14435]],
  [4025778, [18.66205, -101.5085]],
  [8861607, [21.17056, -101.62944]],
  [3167983, [40.22628, 15.04476]],
  [2648057, [51.70419, -0.70797]],
  [2833228, [54.46667, 9.56667]],
  [3177651, [45.5658, 8.86265]],
  [3018886, [47.31732, 1.19045]],
  [3051324, [47.69081, 19.7893]],
  [1153669, [19.90858, 99.8325]],
  [2976583, [46.41487, -1.54877]],
  [1684606, [6.07328, 118.31226]],
  [4748305, [37.52487, -77.55777]],
  [3126673, [40.45203, -2.23983]],
  [666877, [47.46667, 27.21667]],
  [2958121, [51.70161, 11.67648]],
  [4635031, [36.01562, -86.58194]],
  [562309, [57.25363, 41.10849]],
  [7259771, [37.89113, -85.96363]],
  [3516931, [17.41983, -97.37411]],
  [6244895, [-41.13827, 175.0502]],
  [6535072, [45.81916, 7.306]],
  [3174014, [45.696, 11.43237]],
  [2654872, [53.08511, -1.65595]],
  [3164835, [41.92552, 13.23127]],
  [3010004, [45.90675, 6.43445]],
  [4783160, [38.23319, -78.36917]],
  [3518629, [17.22358, -98.1441]],
  [2347456, [12.83772, 8.74513]],
  [3097708, [54.24557, 16.16947]],
  [11001689, [46.9311, 26.40885]],
  [3690673, [-9.13833, -77.74361]],
  [4068590, [34.7304, -86.58594]],
  [8389781, [-6.8894, 113.045]],
  [688000, [48.53812, 25.66149]],
  [1262482, [25.37459, 86.47455]],
  [3019267, [49.09904, -0.50421]],
  [6941086, [52.39186, -1.6504]],
  [2994085, [45.53583, 2.14699]],
  [8860839, [19.47333, -100.29889]],
  [2344582, [7.72175, 10.04526]],
  [3178520, [42.03283, 13.775]],
  [6138380, [46.70013, -65.08204]],
  [2654728, [54.08306, -0.19192]],
  [1919656, [23.57264, 116.72381]],
  [4929501, [42.5612, -71.58979]],
  [8070793, [-6.7625, 111.4877]],
  [8440120, [49.18953, 118.89007]],
  [754912, [50.24226, 22.18826]],
  [8860109, [19.03222, -99.96361]],
  [11900983, [7.8894, -75.67015]],
  [2791445, [51.01746, 3.54106]],
  [282783, [32.04935, 35.16593]],
  [2761834, [47.29419, 11.5907]],
  [773148, [53.98668, 20.39749]],
  [1787523, [29.3979, 107.60649]],
  [8862979, [17.06711, -98.68338]],
  [2658793, [47.04896, 9.44103]],
  [1274914, [18.76059, 73.86351]],
  [1627831, [-2.3077, 112.2118]],
  [4780220, [37.54209, -77.91889]],
  [1260824, [24.63925, 87.84239]],
  [2972071, [45.87599, 4.96502]],
  [5188793, [40.29009, -76.93386]],
  [1272639, [27.47188, 77.32564]],
  [262091, [38.52903, 21.53226]],
  [1220112, [38.31408, 69.03784]],
  [2685750, [63.1792, 14.63566]],
  [5037784, [44.9133, -93.50329]],
  [1805611, [31.03361, 112.20472]],
  [4565621, [18.16163, -65.99544]],
  [1274664, [9.44203, 76.53604]],
  [3182605, [45.97903, 10.11284]],
  [5130328, [40.87093, -73.51096]],
  [2525647, [39.99249, 9.07616]],
  [4560271, [39.72037, -77.50666]],
  [2744498, [52.635, 5.15694]],
  [4012341, [20.62546, -101.67242]],
  [3089125, [50.38017, 18.92653]],
  [8347733, [-28.76689, 114.63338]],
  [2268360, [39.53642, -9.08308]],
  [6692230, [32.71336, -95.19756]],
  [3171081, [45.61306, 8.10422]],
  [1836553, [38.20701, 128.59181]],
  [2657837, [51.64548, -3.32727]],
  [2675397, [59.36004, 18.00086]],
  [8505319, [46.81036, 124.39203]],
  [3598277, [14.81794, -90.51568]],
  [8859257, [21.92222, -102.2375]],
  [2853928, [48.46458, 9.22796]],
  [4160705, [25.70816, -80.407]],
  [2037810, [45.85559, 130.42741]],
  [636493, [63.11667, 21.7]],
  [3616019, [11.43927, -85.93891]],
  [2992603, [42.89535, 1.79426]],
  [2783204, [51.26825, 4.71296]],
  [299354, [37.09595, 41.36834]],
  [3618030, [12.43787, -86.87804]],
  [5346413, [37.46466, -122.26802]],
  [1806114, [32.41445, 121.19539]],
  [2775712, [47.58333, 9.78333]],
  [1628034, [-8.4355, 115.4753]],
  [1813993, [28.01667, 107.3]],
  [2523151, [37.45384, 13.88046]],
  [678821, [46.81361, 28.10083]],
  [3981352, [25.2109, -106.57134]],
  [1793883, [28.11789, 107.74458]],
  [2179103, [-36.83333, 175.7]],
  [1710276, [9.4442, 123.1657]],
  [113343, [37.79658, 48.90521]],
  [4033557, [-17.74744, -149.16123]],
  [1650939, [-7.282, 107.2224]],
  [1712107, [7.77583, 125.64056]],
  [293655, [32.86422, 35.29707]],
  [3055772, [47.64361, 17.97798]],
  [8860681, [17.99639, -94.96139]],
  [6157355, [50.13441, -97.32676]],
  [3098182, [49.7136, 19.64561]],
  [2977015, [45.26981, 4.49512]],
  [2988299, [44.79621, 1.47213]],
  [5267332, [42.73416, -90.47846]],
  [683961, [44.48333, 23.63333]],
  [8348304, [-32.07511, 115.86613]],
  [7426618, [-8.7375, 121.9543]],
  [679464, [43.86667, 23.03333]],
  [7373480, [33.84103, 105.23117]],
  [8861786, [16.72833, -98.6225]],
  [2122090, [69.70287, 170.29993]],
  [2876484, [50.23333, 7.43333]],
  [4453762, [34.67989, -78.98864]],
  [2959977, [49.96547, 5.9339]],
  [134809, [35.70685, 59.85006]],
  [4261231, [38.02615, -87.51585]],
  [4214294, [31.34102, -83.5935]],
  [3112758, [42.38911, -8.50235]],
  [3173298, [44.61807, 8.5996]],
  [3014310, [49.00139, 1.19305]],
  [3035843, [47.7996, 3.57033]],
  [7626330, [61.75904, 11.95937]],
  [5264924, [42.93446, -88.40537]],
  [2974180, [43.20959, 5.41872]],
  [7472713, [26.70718, 115.16898]],
  [3832711, [-40.76173, -71.64631]],
  [4035294, [-13.61749, -172.19919]],
  [756577, [50.82367, 22.74273]],
  [2975361, [50.54873, 3.02731]],
  [2937860, [51.3545, 10.47439]],
  [2701918, [59.56087, 18.22007]],
  [4780641, [39.13677, -77.71472]],
  [3189848, [44.71634, 18.25783]],
  [1793493, [32.94912, 119.15764]],
  [3078301, [49.63657, 18.72038]],
  [2798934, [50.87491, 5.57582]],
  [686434, [47.03054, 22.04812]],
  [1529044, [47.35574, 88.01785]],
  [4056017, [33.15901, -87.34083]],
  [2991812, [49.51121, 1.25557]],
  [2750979, [51.46167, 6.08611]],
  [308840, [39.67551, 28.49446]],
  [2762892, [48.19625, 14.07614]],
  [3203646, [44.55537, 16.36897]],
  [11009130, [-6.22806, 106.51389]],
  [2972270, [49.63829, -1.56639]],
  [462377, [52.66697, 36.37426]],
  [2660089, [47.31805, 8.58401]],
  [1712520, [10.1214, 123.2742]],
  [455898, [56.86014, 24.36544]],
  [1302439, [21.33489, 95.08438]],
  [3686002, [5.09351, -76.64973]],
  [8068048, [-6.6684, 111.4739]],
  [680137, [44.37025, 24.31415]],
  [3111157, [42.2818, -1.26951]],
  [1256739, [23.29356, 81.3619]],
  [2647365, [51.09015, -0.70785]],
  [2658613, [47.12213, 9.48601]],
  [1795546, [29.44305, 111.51816]],
  [8949720, [38.86502, 16.69753]],
  [5550954, [33.94281, -113.17408]],
  [3016961, [46.55248, 5.2923]],
  [664923, [46.65346, 27.14067]],
  [2990821, [44.50478, 6.02204]],
  [2263732, [38.67734, -9.00836]],
  [668448, [44.85906, 23.01991]],
  [2655622, [50.79606, -0.83067]],
  [255498, [39.41936, 20.01469]],
  [3028259, [44.69675, -0.44487]],
  [1574546, [14.95513, 108.88586]],
  [8858556, [17.38611, -94.98806]],
  [3174308, [45.52313, 12.15477]],
  [8859602, [19.44956, -99.34435]],
  [2858964, [47.40724, 10.27939]],
  [2995930, [45.30531, 5.08147]],
  [11550598, [38.01389, -1.08105]],
  [2974789, [47.81444, 6.68334]],
  [2980007, [46.46388, 4.77514]],
  [9017016, [26.78935, 102.99189]],
  [521495, [54.05, 49.23333]],
  [523051, [59.12393, 33.34537]],
  [2654926, [53.88118, -1.35452]],
  [774265, [50.76665, 21.88683]],
  [5515553, [39.00213, -119.95601]],
  [743316, [40.90713, 29.05499]],
  [3202333, [45.57111, 15.18889]],
  [358620, [26.74906, 33.93891]],
  [1791485, [25.98997, 117.86722]],
  [3167096, [40.72586, 8.55552]],
  [7511049, [7.56991, 99.94353]],
  [6422521, [25.33722, 111.73111]],
  [11694038, [44.05305, 80.87173]],
  [674318, [44.56667, 24.18333]],
  [2836089, [49.47372, 8.6636]],
  [3034461, [49.36562, 4.17051]],
  [3115576, [40.4152, -4.87155]],
  [769453, [49.92636, 21.07349]],
  [5117663, [40.61872, -74.0332]],
  [1279528, [31.76446, 93.60966]],
  [5347742, [36.29773, -119.20678]],
  [6545326, [52.15051, 21.05041]],
  [2339293, [10.48536, 5.14501]],
  [6294991, [47.53743, 8.72915]],
  [3182351, [41.12066, 16.86982]],
  [3038712, [48.96115, 2.06882]],
  [2900176, [53.62994, 7.5752]],
  [3180663, [45.15857, 10.22998]],
  [8862738, [24.80861, -107.85944]],
  [3129049, [42.60844, -2.34987]],
  [7602506, [-8.52074, 179.1968]],
  [2759350, [50.94083, 5.79722]],
  [1493224, [54.2312, 85.7972]],
  [2979986, [45.71, 4.49202]],
  [3181992, [40.47956, 9.38403]],
  [2498752, [36.4701, 2.62528]],
  [1804198, [24.35405, 106.2437]],
  [2996605, [48.86603, 2.99226]],
  [2989924, [49.5397, 4.4447]],
  [1818121, [24.33988, 115.69706]],
  [8860810, [19.00551, -99.78806]],
  [2525328, [39.24951, 16.95446]],
  [2059470, [-35.55042, 138.62154]],
  [4740200, [32.47876, -94.05963]],
  [563420, [51.84056, 40.80556]],
  [1802545, [26.41417, 109.10222]],
  [3177471, [45.13306, 7.57512]],
  [3064043, [49.86912, 15.58002]],
  [7545196, [-8.2137, 114.3818]],
  [5244738, [45.40135, -91.84906]],
  [3076733, [49.60365, 15.64807]],
  [8595948, [21.4506, 107.75592]],
  [8223953, [39.60661, -7.24402]],
  [2982541, [47.31553, 6.22901]],
  [2840086, [48.07292, 9.29486]],
  [3178499, [45.16187, 9.70216]],
  [3177221, [46.18455, 12.75161]],
  [1924868, [28.6991, 116.65764]],
  [3036985, [48.50227, -1.39682]],
  [2976566, [48.59472, -4.13797]],
  [2349276, [6.19824, 6.73187]],
  [4024554, [20.84146, -101.53751]],
  [4038554, [13.38608, 144.67226]],
  [2144583, [-37.75, 145.23333]],
  [3047206, [47.29986, 19.23057]],
  [2812644, [48.11717, 10.92161]],
  [5197796, [40.15511, -74.82877]],
  [3007541, [43.65155, 4.07248]],
  [8348482, [-33.10637, 151.64402]],
  [664212, [46.35, 27.18333]],
  [2629833, [66.04148, -17.33834]],
  [666462, [47.45918, 23.22755]],
  [8410622, [5.1053, 73.07115]],
  [3427404, [-32.39727, -58.74596]],
  [4330740, [31.14352, -93.261]],
  [787985, [41.98029, 21.64982]],
  [2345003, [4.74807, 6.76618]],
  [4547915, [35.76482, -94.96551]],
  [2989796, [48.46313, 7.481]],
  [1273943, [30.46944, 77.77275]],
  [3673142, [9.33189, -75.5417]],
  [1842944, [36.12176, 128.11981]],
  [2908974, [48.28426, 8.17602]],
  [8862081, [17.54528, -93.11222]],
  [3572601, [26.87137, -77.51131]],
  [1726441, [9.94718, 123.37515]],
  [8224209, [53.19647, -0.53421]],
  [2649672, [51.29424, -0.75565]],
  [755442, [52.0404, 21.95575]],
  [575591, [53.77166, 38.12408]],
  [666954, [46.71589, 24.26243]],
  [3072890, [50.48378, 13.3575]],
  [8392858, [-6.981, 113.5398]],
  [3652315, [-0.9236, -80.44946]],
  [5250847, [43.01418, -88.4726]],
  [3089301, [52.18333, 16.8]],
  [3125636, [41.38918, -5.26408]],
  [3858553, [-25.0741, -65.49174]],
  [1492822, [54.90583, 61.82444]],
  [2353122, [6.19031, 6.99357]],
  [3319577, [47.23127, 11.41018]],
  [1645953, [-6.5563, 106.2901]],
  [1908252, [22.30175, 110.97425]],
  [2804161, [51.0, 11.63333]],
  [8862775, [20.00578, -97.53606]],
  [3539258, [22.27967, -83.8401]],
  [4565353, [18.37439, -65.78711]],
  [10113308, [-26.15901, 152.68979]],
  [3628055, [11.17203, -68.4128]],
  [8348880, [-23.36887, 150.54917]],
  [1918568, [27.67491, 119.44944]],
  [733986, [40.26147, 21.32533]],
  [3981165, [20.54217, -102.79222]],
  [2778949, [47.929, 15.08818]],
  [11611204, [52.22735, 10.531]],
  [9972637, [-33.78655, 151.20925]],
  [670623, [43.9, 23.16667]],
  [8349329, [-33.70228, 150.91086]],
  [2153623, [-38.203, 145.23488]],
  [2871713, [50.26948, 7.30814]],
  [700446, [51.20286, 29.08228]],
  [3035830, [47.3, 5.95]],
  [2967252, [45.34722, 2.43727]],
  [3164084, [46.55899, 11.22336]],
  [8382526, [-10.8435, 123.0579]],
  [2516742, [39.72551, -5.66692]],
  [2510098, [37.36881, -6.15847]],
  [3175750, [40.28281, 15.88877]],
  [8861720, [18.72139, -97.6625]],
  [3458696, [-21.24528, -44.99972]],
  [592351, [58.40861, 24.37389]],
  [3097241, [49.64246, 19.32701]],
  [7084629, [-8.246, 114.9778]],
  [3816128, [16.70671, -92.69669]],
  [2942999, [49.83333, 7.4]],
  [1848373, [34.96667, 136.61667]],
  [7765928, [-6.3777, 105.8291]],
  [2525003, [37.67692, 13.27074]],
  [1928037, [29.28083, 110.15504]],
  [2960363, [49.66444, 6.07306]],
  [1525014, [54.90596, 70.44155]],
  [3172185, [46.21939, 9.44971]],
  [3172402, [45.87594, 10.89106]],
  [5396291, [33.89001, -116.35251]],
  [3038682, [49.6088, -1.74956]],
  [3429710, [-31.53713, -58.01527]],
  [2905857, [50.57835, 10.33308]],
  [561999, [60.34127, 30.49551]],
  [3022710, [46.21667, -0.71667]],
  [3979723, [19.5493, -103.81111]],
  [2751793, [52.1279, 6.23217]],
  [10062597, [-42.89459, 147.30924]],
  [6680101, [24.15477, 115.74307]],
  [3200396, [43.66795, 18.97564]],
  [3165355, [42.54472, 13.64595]],
  [2523123, [39.74733, 16.4551]],
  [12127991, [40.25363, 66.68849]],
  [8949098, [45.78747, 12.5627]],
  [3217259, [45.75398, 13.65931]],
  [1019933, [-28.73122, 29.35187]],
  [7055440, [-8.2725, 115.2644]],
  [3027699, [47.81386, 3.79835]],
  [4818074, [39.15232, -80.04036]],
  [9517784, [19.41234, -100.04811]],
  [774393, [53.14332, 22.98889]],
  [2967942, [45.71866, 5.71264]],
  [3015422, [48.55604, -3.1853]],
  [8860179, [17.13889, -96.77306]],
  [2987429, [47.76915, 7.30295]],
  [1705572, [13.884, 122.2604]],
  [2976059, [49.65968, 4.79706]],
  [5211128, [40.67514, -75.62044]],
  [1811309, [30.89019, 121.01195]],
  [2888398, [51.78396, 11.88464]],
  [686528, [47.39024, 22.24528]],
  [8070789, [-6.7674, 111.4127]],
  [3207803, [50.25368, 7.96114]],
  [2341218, [12.10157, 8.75029]],
  [4001301, [20.28348, -100.80502]],
  [2998640, [47.80089, 4.08776]],
  [1510019, [51.92528, 87.65194]],
  [2167444, [-37.7, 145.15]],
  [8859315, [18.92944, -99.43444]],
  [3038604, [49.38118, 2.38621]],
  [5882873, [43.85012, -79.03288]],
  [2977638, [44.34594, 4.76356]],
  [680459, [47.4193, 26.28176]],
  [2659050, [47.20622, 8.69686]],
  [2942305, [53.38596, 10.5655]],
  [8862631, [25.55694, -108.51056]],
  [2510853, [38.33232, -1.98485]],
  [1816648, [24.59614, 115.33842]],
  [3115463, [42.14153, -8.80656]],
  [2988867, [48.76699, 2.66871]],
  [2814846, [49.37804, 12.70905]],
  [2975184, [47.5, 0.58333]],
  [8348830, [-34.91107, 138.6536]],
  [2650440, [51.48915, -1.47918]],
  [1797972, [29.84431, 117.71937]],
  [3090787, [54.13333, 18.2022]],
  [1645162, [-7.0256, 108.7564]],
  [3141747, [58.72057, 9.23422]],
  [3170208, [45.27315, 10.09248]],
  [2820179, [48.06667, 9.83333]],
  [4402331, [38.68338, -90.30761]],
  [3111717, [42.23475, -1.85214]],
  [7037542, [-8.2542, 113.8191]],
  [1785197, [33.44139, 106.56447]],
  [8862390, [22.40194, -101.10722]],
  [4027543, [17.31665, -100.3976]],
  [3515713, [18.62233, -97.04025]],
  [8513415, [29.84328, 112.889]],
  [2791831, [50.43969, 4.79562]],
  [5213126, [41.99757, -76.53717]],
  [2979698, [45.89126, 6.71678]],
  [3182400, [42.24908, 12.06774]],
  [3514074, [19.23369, -96.94477]],
  [2995582, [47.64815, 1.30662]],
  [2659099, [47.22557, 8.82228]],
  [8400054, [31.35639, 108.67045]],
  [949703, [-29.20932, 26.83898]],
  [3107574, [42.0254, -7.61735]],
  [2524446, [39.31964, 16.31143]],
  [4016968, [20.52932, -105.28662]],
  [664575, [47.51667, 26.63333]],
  [5380626, [33.72255, -116.37697]],
  [1893287, [22.61935, 112.46783]],
  [698339, [48.43992, 26.61836]],
  [3209379, [45.79167, 15.9]],
  [1965847, [-8.2169, 112.5781]],
  [6317136, [-3.84028, -32.41083]],
  [2967345, [48.919, 7.37955]],
  [3680601, [1.05516, -77.49669]],
  [8421260, [-8.216, 124.5175]],
  [672926, [46.47523, 26.48907]],
  [2984054, [47.31629, 5.20783]],
  [4822050, [39.39564, -80.30009]],
  [1799110, [29.60624, 120.15078]],
  [1166164, [26.04694, 68.94917]],
  [3075257, [50.1121, 14.61036]],
  [2915129, [53.77851, 12.50471]],
  [3199162, [45.96028, 14.52889]],
  [2980248, [44.18757, 4.70568]],
  [3531371, [20.89, -97.4375]],
  [11670102, [47.87053, 9.56944]],
  [3457107, [-21.13361, -51.10167]],
  [6534238, [45.51017, 9.08704]],
  [3322796, [47.77389, 12.9575]],
  [5011569, [42.76226, -84.9925]],
  [8349218, [-32.05625, 115.86242]],
  [2830661, [49.79002, 11.81949]],
  [1817193, [30.09403, 105.71297]],
  [8862375, [21.38861, -98.80083]],
  [3165808, [45.85416, 13.05608]],
  [3025826, [47.15199, -1.98666]],
  [2525667, [39.52154, 9.38066]],
  [1817490, [23.06242, 112.83391]],
  [3673187, [6.40756, -73.28824]],
  [2335402, [12.32666, 4.2004]],
  [2970962, [43.57803, 7.05451]],
  [3111031, [41.99498, -0.22278]],
  [254782, [37.95899, 22.77131]],
  [2846593, [51.36667, 11.21667]],
  [692122, [48.9622, 24.19658]],
  [1717569, [11.689, 122.2909]],
  [3180754, [45.84867, 9.27063]],
  [2764988, [46.54444, 14.60583]],
  [3073172, [50.57165, 15.40403]],
  [1279844, [39.15088, 77.78813]],
  [1272552, [25.91174, 93.7217]],
  [1789600, [29.49051, 121.43303]],
  [6535054, [45.41026, 7.74422]],
  [2772003, [47.96667, 16.6]],
  [1572074, [10.72294, 106.88341]],
  [1266285, [16.69563, 74.23167]],
  [6682579, [23.52387, 116.70915]],
  [3163844, [45.42893, 11.50387]],
  [8407272, [-9.7057, 123.8993]],
  [664163, [45.33731, 23.1524]],
  [2769074, [48.7, 16.01667]],
  [666655, [47.76428, 26.04656]],
  [2990677, [49.4313, 4.03409]],
  [3526893, [16.92888, -97.1467]],
  [7372877, [33.43112, 105.75843]],
  [828886, [52.12139, 26.07278]],
  [2993763, [43.9982, 0.75624]],
  [1638852, [-8.6962, 118.0205]],
  [7037156, [-8.0853, 113.0723]],
  [2229748, [6.2, 10.66667]],
  [5992144, [44.18339, -81.63307]],
  [2970827, [45.94638, 4.42962]],
  [3701917, [9.01667, -79.5]],
  [7932635, [-37.88766, 145.09519]],
  [2510896, [39.43997, -6.27242]],
  [2989263, [48.57489, 2.45206]],
  [3516848, [16.86848, -96.62919]],
  [4722542, [27.42143, -97.85111]],
  [1521677, [50.23091, 73.40146]],
  [3124085, [40.58258, -2.03612]],
  [1680623, [17.90483, 121.64099]],
  [137268, [31.5635, 48.18958]],
  [2938323, [53.0511, 8.63091]],
  [5955895, [56.72676, -111.38103]],
  [2492913, [36.821, 5.76352]],
  [2784077, [50.60925, 3.424]],
  [2523056, [37.33471, 13.99739]],
  [80393, [15.21518, 43.0702]],
  [3601282, [14.45, -89.18333]],
  [3702688, [9.5662, -79.29185]],
  [6535038, [43.00515, 13.44498]],
  [1677112, [24.83833, 121.00778]],
  [3817513, [18.90274, -99.85582]],
  [2146594, [-38.48434, 142.98033]],
  [2950349, [50.9856, 7.13298]],
  [1207047, [23.30916, 90.22698]],
  [1711663, [8.64028, 124.87306]],
  [7585470, [-8.5098, 118.605]],
  [8190875, [-8.1645, 113.5609]],
  [1801401, [25.0319, 102.638]],
  [2991035, [48.50559, 2.49505]],
  [1681655, [16.0271, 120.6369]],
  [8858675, [19.23054, -98.9309]],
  [3193187, [45.28861, 17.80639]],
  [3926308, [-15.27333, -73.02167]],
  [684583, [45.0, 26.01667]],
  [2975244, [43.49161, 0.59185]],
  [3823425, [19.31421, -96.90105]],
  [2023469, [52.29778, 104.29639]],
  [8348828, [-31.03732, 150.97073]],
  [1485997, [58.44937, 92.17968]],
  [310859, [37.5847, 36.92641]],
  [8539257, [-38.16053, 146.36449]],
  [933753, [-21.8327, 28.72882]],
  [3061851, [49.38263, 17.35283]],
  [295730, [31.98133, 34.87213]],
  [1283600, [27.1715, 87.04607]],
  [2791556, [50.10086, 4.69653]],
  [1271692, [8.85785, 77.78019]],
  [7338035, [-8.8397, 121.6449]],
  [8860760, [27.27806, -109.99639]],
  [3587064, [13.63333, -88.26667]],
  [3075025, [49.30365, 18.06062]],
  [5927969, [48.95001, -57.95202]],
  [5202382, [40.38452, -77.88222]],
  [1912568, [38.94167, 117.04917]],
  [8950556, [46.99576, 11.98026]],
  [3202752, [46.55556, 16.4975]],
  [8382477, [-10.7525, 123.033]],
  [3348313, [-12.77611, 15.73917]],
  [2708152, [63.11041, 16.35377]],
  [3104624, [42.1, 3.0]],
  [2873288, [47.94733, 10.39608]],
  [1707179, [12.3392, 125.0313]],
  [8950325, [44.87592, 9.97308]],
  [2970970, [46.21619, -0.55135]],
  [5700094, [41.97833, -100.57625]],
  [4535949, [34.42036, -98.02698]],
  [2293260, [7.13113, -3.20415]],
  [735856, [40.71539, 23.04588]],
  [682800, [47.71667, 22.5]],
  [699413, [47.97362, 35.89806]],
  [1520831, [45.45055, 78.31481]],
  [679936, [44.2, 26.58333]],
  [2857147, [49.95, 7.46667]],
  [2973732, [45.60187, 3.4082]],
  [399518, [19.12361, 41.91111]],
  [10104165, [-26.64166, 153.04959]],
  [3801849, [16.5686, -96.75514]],
  [5948870, [44.73345, -63.78201]],
  [3823836, [18.84722, -96.95389]],
  [8200783, [21.25487, 105.2953]],
  [2806442, [50.36667, 10.23333]],
  [3016328, [47.8537, -0.60918]],
  [4012121, [20.93027, -103.02771]],
  [114930, [37.39669, 57.92952]],
  [3387663, [-8.07361, -37.26444]],
  [4091847, [33.44872, -86.47998]],
  [3074238, [48.82859, 16.46616]],
  [2780259, [48.17204, 15.98391]],
  [2974185, [47.24329, 0.6952]],
  [4566171, [17.9783, -66.88934]],
  [3134327, [58.66263, 6.71694]],
  [669037, [47.06667, 23.53333]],
  [1486209, [56.8519, 60.6122]],
  [2146955, [-23.18333, 150.46667]],
  [3174784, [46.01217, 11.30427]],
  [1064980, [-17.38095, 49.40826]],
  [3070383, [49.90462, 18.02954]],
  [3515877, [18.72468, -98.44633]],
  [1682562, [14.15759, 120.84655]],
  [759399, [49.87163, 20.99625]],
  [587839, [59.47297, 25.32321]],
  [2749203, [52.1, 5.77222]],
  [1725729, [16.4812, 121.1497]],
  [5293390, [32.97201, -110.77649]],
  [3521433, [19.29375, -96.93866]],
  [265206, [39.74481, 22.36575]],
  [684170, [45.81667, 26.0]],
  [3518581, [17.21583, -96.82528]],
  [5330993, [39.44377, -123.38529]],
  [1858296, [34.23222, 132.56658]],
  [9973104, [-34.62323, 138.72166]],
  [671607, [47.08333, 27.03333]],
  [1255766, [20.62915, 78.69207]],
  [3457928, [-18.8625, -41.12278]],
  [11790569, [47.13632, 7.83226]],
  [8571816, [4.36901, 96.19555]],
  [3525904, [20.29632, -99.96296]],
  [3862515, [-31.87296, -63.11715]],
  [2978834, [47.4606, -0.71017]],
  [2808619, [51.62564, 9.03694]],
  [1897715, [28.71494, 121.41029]],
  [3942538, [-10.03778, -76.21528]],
  [1185920, [22.89983, 89.90326]],
  [7576449, [54.27425, 18.6038]],
  [2997493, [45.91422, 0.58617]],
  [3800935, [17.45306, -97.28481]],
  [314766, [37.58669, 43.56163]],
  [3603330, [15.23333, -87.96667]],
  [1800480, [31.10881, 121.37471]],
  [3551608, [22.87199, -82.42328]],
  [3171251, [42.49342, 12.35845]],
  [2619782, [55.75621, 9.85643]],
  [1488780, [55.87321, 72.19673]],
  [2776866, [47.51071, 12.11912]],
  [1718271, [6.44413, 124.88138]],
  [4996690, [44.75973, -83.54442]],
  [1292313, [17.65399, 95.78813]],
  [5414392, [39.42971, -106.04391]],
  [3408100, [-7.15833, -35.63]],
  [1805565, [28.24425, 103.63849]],
  [296957, [37.50887, 40.98989]],
  [3185223, [42.3525, 19.48917]],
  [2156671, [-33.76667, 150.81667]],
  [2906676, [52.2279, 11.00985]],
  [1510943, [51.28528, 87.71806]],
  [12156904, [43.74564, -79.51347]],
  [1853371, [38.07817, 138.43939]],
  [8449075, [-8.9001, 117.7682]],
  [3976884, [25.85055, -108.95932]],
  [8859008, [19.87237, -97.59424]],
  [8411566, [39.15348, 117.21477]],
  [3995950, [25.39948, -103.2674]],
  [3084060, [53.41864, 18.09731]],
  [2947370, [49.83333, 7.68333]],
  [3120506, [40.46221, -2.96039]],
  [666619, [43.85, 25.43333]],
  [7927940, [-7.6286, 108.073]],
  [5107760, [40.65649, -73.6093]],
  [379261, [14.3476, 32.9437]],
  [1809305, [32.16702, 120.59468]],
  [3173153, [45.80463, 13.53292]],
  [4925561, [41.49616, -85.37665]],
  [707440, [47.44407, 30.52626]],
  [2823372, [48.44281, 13.26955]],
  [632860, [60.6, 22.3]],
  [4254304, [39.72199, -86.08998]],
  [557563, [54.83244, 56.41264]],
  [3025420, [45.74345, -0.35096]],
  [1529283, [46.47974, 90.74567]],
  [3180985, [41.44827, 14.50828]],
  [3520310, [19.27404, -99.99648]],
  [1272045, [15.77203, 77.48345]],
  [3944440, [-12.0287, -75.37847]],
  [2338548, [6.56585, 5.35455]],
  [2637522, [51.47411, -0.91212]],
  [7780389, [-6.3319, 106.0414]],
  [735135, [40.84098, 22.77483]],
  [1957769, [5.05083, 119.83972]],
  [2752627, [53.38667, 6.39167]],
  [2994019, [46.81979, 1.21123]],
  [2264923, [39.3558, -9.38112]],
  [1801364, [31.53862, 111.31825]],
  [3061954, [49.17054, 16.32526]],
  [294286, [32.72815, 34.95631]],
  [1142758, [34.55132, 65.79302]],
  [696823, [49.53131, 26.14401]],
  [662523, [45.41528, 23.96685]],
  [5141962, [40.77732, -73.72263]],
  [3130772, [42.41547, 0.14008]],
  [2658303, [46.92228, 7.8452]],
  [3175032, [40.33915, 15.3381]],
  [3680531, [2.64867, -75.63457]],
  [2746545, [50.95417, 5.84722]],
  [8424577, [-8.2869, 123.2571]],
  [7035532, [-8.0593, 112.4281]],
  [5735238, [42.22487, -121.78167]],
  [2525478, [39.92871, 8.53242]],
  [1556130, [32.40774, 118.64768]],
  [2344131, [5.63108, 7.83024]],
  [1260421, [30.43666, 77.62462]],
  [2639583, [51.58571, 0.60459]],
  [3121437, [40.30571, -3.73295]],
  [788035, [41.64167, 21.59]],
  [302954, [38.00478, 42.24386]],
  [840909, [45.23888, 39.80499]],
  [7064804, [28.26829, 117.42458]],
  [2869542, [47.72439, 8.93479]],
  [3102686, [51.28581, 17.90325]],
  [5888001, [48.75667, -91.62409]],
  [694253, [47.68261, 30.11192]],
  [6534615, [45.03388, 11.1078]],
  [3680840, [5.20856, -74.73584]],
  [1610510, [16.02397, 103.38444]],
  [3064285, [50.59332, 16.17026]],
  [774030, [51.29928, 23.14124]],
  [522312, [51.7676, 55.3694]],
  [3095978, [54.18896, 19.1484]],
  [3035228, [48.52525, 2.38604]],
  [7780957, [-6.3554, 105.8989]],
  [2896021, [49.30028, 9.65303]],
  [8442318, [49.19546, 123.73209]],
  [3033598, [49.08837, 2.37159]],
  [2939032, [54.42775, 10.12596]],
  [7315262, [38.80524, -76.79126]],
  [1506260, [50.99417, 81.46611]],
  [3115956, [42.12843, -6.33719]],
  [2688053, [60.96667, 14.63333]],
  [1806965, [38.5, 101.21667]],
  [3979643, [19.30183, -102.31885]],
  [3016985, [47.86391, -0.66698]],
  [2778953, [46.96667, 10.18333]],
  [8859125, [19.37917, -99.69278]],
  [4566021, [18.0383, -66.90462]],
  [315905, [37.87462, 30.85042]],
  [1975225, [-4.0525, 120.0288]],
  [8950076, [45.55869, 11.70512]],
  [8068090, [-6.6921, 111.4882]],
  [4034912, [-13.51439, -172.67522]],
  [2874695, [53.21242, 13.31483]],
  [4921100, [41.58337, -87.50004]],
  [2658284, [47.0942, 7.15699]],
  [1686141, [13.8894, 122.6375]],
  [2763099, [46.80746, 15.31516]],
  [3525644, [20.34306, -99.02737]],
  [692563, [47.89217, 30.90439]],
  [3009083, [43.86735, -0.4056]],
  [2865293, [50.97729, 14.60881]],
  [2971386, [49.27914, 2.28094]],
  [1628302, [-0.27393, 116.99835]],
  [2620835, [56.25762, 9.86316]],
  [2512787, [39.82522, -6.41251]],
  [12070746, [52.1792, -113.87981]],
  [3608112, [13.45361, -87.41333]],
  [2509550, [38.376, -7.16753]],
  [7084546, [-8.1963, 114.9445]],
  [3067892, [49.0339, 17.34064]],
  [4388632, [38.48978, -90.8168]],
  [769609, [49.56848, 20.82364]],
  [3018032, [47.34238, 5.02007]],
  [6534991, [45.14917, 9.92637]],
  [1814163, [37.66641, 121.05299]],
  [2979633, [48.26783, -2.56743]],
  [8314893, [-8.1966, 111.611]],
  [3046719, [46.62417, 19.95156]],
  [3132792, [59.76667, 5.41667]],
  [663008, [46.16747, 23.9247]],
  [2869963, [54.07698, 13.83226]],
  [3218409, [45.7778, 12.42429]],
  [3022987, [43.54944, 3.70556]],
  [2640375, [53.25, -2.35]],
  [616645, [40.12793, 44.41669]],
  [2988015, [48.79656, 1.68147]],
  [8949790, [45.78226, 9.45441]],
  [782117, [41.43083, 20.45556]],
  [8070150, [-7.4301, 108.7146]],
  [2265120, [39.66978, -8.9958]],
  [1814807, [33.43333, 101.43333]],
  [2281120, [4.42295, -7.3528]],
  [2817659, [49.86639, 8.06861]],
  [4739052, [32.43347, -97.10251]],
  [2876212, [50.98831, 14.00268]],
  [3979983, [21.83601, -105.06451]],
  [1916754, [29.13061, 118.34925]],
  [4557109, [39.93759, -77.6611]],
  [1910319, [34.51723, 109.31779]],
  [689346, [44.94023, 33.84573]],
  [3445782, [-23.76639, -53.325]],
  [3668605, [11.24079, -74.19904]],
  [3117441, [42.3177, -4.3967]],
  [8547457, [32.83543, 110.87664]],
  [3217367, [46.87944, 12.65417]],
  [1792184, [28.71287, 117.472]],
  [3014175, [44.636, -1.06815]],
  [1279716, [39.6, 98.4]],
  [2037918, [46.16931, 129.40379]],
  [8949163, [42.80769, 12.41024]],
  [630952, [65.37181, 25.8458]],
  [4015683, [24.52576, -104.77327]],
  [7670876, [50.93396, -2.70229]],
  [179525, [-3.39879, 37.68336]],
  [3179252, [43.52025, 11.48693]],
  [1715427, [15.9902, 119.8828]],
  [254115, [37.75427, 26.97699]],
  [728317, [42.03372, 24.29995]],
  [504376, [44.47097, 38.40317]],
  [3817502, [18.96146, -99.66827]],
  [3991072, [20.62027, -102.81075]],
  [8349252, [-17.65318, 145.41401]],
  [305041, [38.38669, 39.66408]],
  [3196496, [46.62833, 16.22806]],
  [1685218, [9.789, 125.495]],
  [154666, [-9.33333, 33.75]],
  [1326105, [22.91335, 93.67779]],
  [8950357, [44.50816, 8.91429]],
  [231083, [1.87633, 32.06225]],
  [3203378, [46.07111, 16.27111]],
  [1800779, [24.28859, 116.11768]],
  [792129, [42.07056, 21.50278]],
  [3817450, [18.89414, -99.70867]],
  [3603649, [15.23333, -87.96667]],
  [2655250, [54.9426, -1.45349]],
  [1611881, [16.34147, 100.79317]],
  [213940, [-4.42741, 26.66656]],
  [8949191, [43.83027, 11.48655]],
  [3389652, [-7.9575, -36.20472]],
  [4012699, [22.36625, -102.30008]],
  [3611995, [15.06667, -88.61667]],
  [678864, [44.42262, 27.38007]],
  [7751201, [29.92657, 115.189]],
  [641660, [63.38333, 25.56667]],
  [2013918, [60.41553, 134.54405]],
  [3023408, [45.79296, 4.29972]],
  [8636696, [2.00848, 98.96658]],
  [6385775, [-7.56639, 109.00333]],
  [685830, [45.16667, 23.76667]],
  [8709119, [22.06123, -98.17412]],
  [8859564, [20.15694, -101.33556]],
  [8770183, [26.89722, -109.40389]],
  [3976513, [24.3616, -107.0763]],
  [3166586, [41.67045, 13.8507]],
  [3522732, [19.64177, -99.3068]],
  [8662038, [-12.78771, -74.63224]],
  [730268, [42.08439, 23.0571]],
  [1806248, [28.19377, 115.7836]],
  [12110944, [52.29462, 10.56841]],
  [3469932, [-21.09, -45.56583]],
  [3825059, [18.43663, -95.17833]],
  [697616, [48.3479, 36.39637]],
  [2929859, [50.25, 7.06667]],
  [3049574, [47.48552, 19.57876]],
  [7262027, [36.05082, -106.06897]],
  [3687677, [6.74989, -76.02539]],
  [2352698, [6.33465, 6.98863]],
  [1817279, [28.36101, 109.97433]],
  [2635563, [55.94439, -2.95412]],
  [1853066, [34.5, 135.85]],
  [1581326, [20.94099, 106.33302]],
  [1887188, [31.76408, 121.77791]],
  [132428, [31.44686, 49.52951]],
  [8315890, [-8.0604, 111.5793]],
  [1526168, [42.43015, 68.8087]],
  [1788899, [25.0, 112.55]],
  [1647616, [-7.0594, 108.4337]],
  [8349311, [-28.1393, 153.35297]],
  [3126968, [40.94638, -5.55258]],
  [3064014, [49.71147, 15.01356]],
  [2935702, [48.35008, 8.50901]],
  [3065404, [49.78225, 14.19735]],
  [1548721, [32.37003, 119.45963]],
  [3526458, [18.68081, -98.80336]],
  [3176521, [45.27941, 7.66425]],
  [2812626, [50.48438, 8.26249]],
  [3980191, [20.43121, -102.49508]],
  [3174256, [42.36568, 12.4852]],
  [1648691, [-2.10967, 133.52461]],
  [2917198, [54.63333, 8.71667]],
  [540690, [44.65889, 42.81417]],
  [3390295, [-8.66877, -35.16277]],
  [790035, [45.82648, 20.31791]],
  [1848689, [34.18333, 131.46667]],
  [3071787, [48.63932, 14.22933]],
  [5597920, [48.3102, -116.51353]],
  [1263940, [22.23566, 75.08917]],
  [3076604, [49.00167, 17.28476]],
  [3462916, [-20.02472, -48.94056]],
  [513426, [55.5802, 32.4127]],
  [3515431, [19.54005, -99.19538]],
  [1803145, [34.74417, 109.27771]],
  [1260138, [9.26667, 76.78333]],
  [3712616, [8.22818, -81.57421]],
  [668772, [47.41667, 24.81667]],
  [701619, [44.50958, 34.18817]],
  [1792346, [39.6178, 113.44835]],
  [2796047, [50.4846, 4.4953]],
  [2722836, [62.53074, 17.38123]],
  [2657680, [53.94452, -1.88424]],
  [3674124, [7.30061, -72.74667]],
  [2065642, [-35.34685, 138.62032]],
  [2660121, [47.45793, 8.78295]],
  [513998, [56.15053, 28.6758]],
  [1927954, [29.00751, 109.88227]],
  [691887, [47.69992, 31.30747]],
  [1650460, [-7.73333, 113.91667]],
  [686667, [46.13485, 27.18891]],
  [2634925, [51.84675, -0.92105]],
  [2018896, [51.21724, 109.21305]],
  [7849205, [-7.4796, 107.4287]],
  [1632406, [-6.594, 106.1911]],
  [3073490, [50.62466, 15.22822]],
  [3063448, [49.14064, 18.0076]],
  [1651019, [-7.1554, 108.3377]],
  [2816547, [54.61667, 9.03333]],
  [3062924, [50.37234, 17.18626]],
  [684882, [47.15, 21.91667]],
  [3125134, [41.48696, -1.48012]],
  [4468261, [35.38488, -77.99277]],
  [3664301, [-7.3, -70.21667]],
  [3093768, [50.42769, 19.39465]],
  [463053, [54.69216, 36.02225]],
  [4557707, [39.97204, -76.51997]],
  [2815297, [49.84645, 11.3481]],
  [2791744, [50.22678, 5.34416]],
  [665588, [44.28333, 25.28333]],
  [8411514, [39.11218, 117.16017]],
  [741538, [40.02621, 30.57707]],
  [8349040, [-32.12133, 115.84776]],
  [3036223, [48.70049, 1.88696]],
  [3122027, [42.68176, -4.81734]],
  [8402775, [-9.8529, 124.2342]],
  [3607419, [15.61667, -87.11667]],
  [4914081, [42.19808, -88.25342]],
  [4951579, [41.66678, -70.18474]],
  [674328, [46.01822, 26.21208]],
  [1718910, [9.92314, 123.49629]],
  [784661, [45.24376, 21.03498]],
  [3191062, [46.25639, 15.12194]],
  [3469140, [-21.63333, -46.395]],
  [2641833, [52.64994, 0.77539]],
  [682369, [43.81667, 28.1]],
  [8949554, [45.26163, 9.12007]],
  [11745794, [34.86886, 126.60706]],
  [2973814, [47.55163, 7.33713]],
  [3523180, [20.1383, -98.67364]],
  [4528015, [39.94478, -83.2688]],
  [5143168, [41.30982, -74.22904]],
  [2155796, [-36.95785, 140.7383]],
  [3085877, [53.65792, 16.66549]],
  [2990493, [46.67613, -0.46011]],
  [665614, [46.2774, 22.47352]],
  [1565157, [10.06522, 105.55863]],
  [672113, [47.80569, 22.73085]],
  [5052032, [45.31524, -94.4086]],
  [5775615, [41.28133, -111.98828]],
  [1253251, [13.96005, 79.58032]],
  [3021185, [48.98726, -1.03235]],
  [3109589, [40.17637, -6.22441]],
  [4394348, [39.0214, -94.31162]],
  [734919, [40.94739, 24.14311]],
  [2995907, [50.28557, 3.23806]],
  [5245993, [44.42385, -89.7804]],
  [2657658, [53.57077, -1.18454]],
  [2265472, [37.59798, -8.63972]],
  [2978130, [46.21629, 4.25301]],
  [8861412, [20.185, -101.92528]],
  [7511026, [15.26686, 102.02024]],
  [671422, [44.56588, 27.30136]],
  [12069991, [-33.65078, 115.34932]],
  [656183, [61.05251, 26.48117]],
  [2393883, [6.76667, 2.16667]],
  [2650584, [53.30032, -3.41262]],
  [1108161, [24.3944, 67.99298]],
  [3197466, [45.68333, 17.73333]],
  [3702342, [8.03412, -82.86869]],
  [582493, [43.25861, 45.53917]],
  [2940669, [54.13398, 8.85756]],
  [1806882, [22.456, 113.0482]],
  [600040, [54.36667, 24.33333]],
  [467886, [51.76732, 59.74245]],
  [551669, [45.4453, 40.6765]],
  [4503617, [39.95456, -74.31515]],
  [2659275, [46.31208, 8.98579]],
  [2923234, [50.43333, 7.2]],
  [3012036, [49.06969, 6.25263]],
  [1625902, [-3.10245, 104.27802]],
  [1928363, [28.82278, 110.02694]],
  [281285, [32.07213, 35.06327]],
  [7259396, [48.42087, -101.33914]],
  [3075049, [50.48859, 14.335]],
  [3107084, [40.22877, -0.81079]],
  [1255122, [16.47311, 76.31085]],
  [2052496, [40.46274, 116.09136]],
  [2510007, [39.72588, -5.28296]],
  [2908565, [51.44542, 10.44544]],
  [3120196, [42.21804, -2.49004]],
  [3533413, [20.59732, -98.06876]],
  [5274887, [44.63194, -88.03927]],
  [3925075, [-10.78356, -65.33552]],
  [2636534, [51.40424, -0.41817]],
  [3515292, [17.73378, -95.87234]],
  [3875024, [-33.61169, -70.57577]],
  [5243872, [45.30691, -92.36214]],
  [1256959, [21.15054, 75.88938]],
  [3449195, [-21.64611, -41.74694]],
  [1814996, [22.3229, 112.58156]],
  [2986633, [48.53333, -3.78333]],
  [5578171, [40.07304, -106.1028]],
  [7685749, [29.90664, 119.81474]],
  [1800932, [38.36667, 101.2]],
  [712618, [45.37465, 36.08212]],
  [732704, [43.46667, 23.93333]],
  [3181727, [45.94748, 10.20243]],
  [6368451, [-5.18847, -79.42641]],
  [8305920, [-9.6739, 120.1302]],
  [2717532, [59.32653, 18.71152]],
  [3171500, [43.54422, 11.31438]],
  [2789586, [50.58008, 5.12632]],
  [2772709, [47.93348, 16.47915]],
  [2908373, [51.85943, 11.25]],
  [3980761, [27.86715, -108.21517]],
  [2974681, [48.94472, 2.52746]],
  [148987, [-7.00667, 39.29849]],
  [727801, [41.99012, 24.0873]],
  [3021349, [48.21931, -4.16547]],
  [2019819, [44.19641, 132.50223]],
  [1630649, [-7.68333, 112.61667]],
  [4743605, [31.15852, -94.42631]],
  [2997646, [49.44181, 5.60508]],
  [5116495, [40.73649, -73.87791]],
  [767908, [52.17643, 22.06819]],
  [2951717, [52.35, 9.31667]],
  [3600026, [15.82562, -87.92968]],
  [3083907, [50.02004, 18.34597]],
  [8348144, [-35.01517, 117.90096]],
  [3530528, [18.33922, -99.7214]],
  [2937714, [52.64494, 8.47166]],
  [921906, [-12.25694, 44.53194]],
  [2611518, [55.34535, 10.17594]],
  [2640455, [51.47444, -3.47889]],
  [8949589, [44.90415, 10.31596]],
  [3617551, [13.17611, -84.95522]],
  [3482937, [22.54215, -98.55349]],
  [99759, [33.84667, 44.23444]],
  [6640206, [-33.93133, 151.15222]],
  [3615030, [15.68333, -87.31667]],
  [5158234, [41.48922, -81.40178]],
  [3009729, [47.95739, -2.5012]],
  [7092271, [7.86338, 98.36437]],
  [2766440, [48.50233, 14.07821]],
  [1998357, [34.22495, 109.18229]],
  [2968549, [42.69376, 2.8014]],
  [8348921, [-34.852, 138.52158]],
  [3116924, [43.25326, -2.38568]],
  [3182019, [45.87356, 7.4231]],
  [168852, [34.92789, 36.124]],
  [4876141, [43.18109, -95.85613]],
  [3165963, [43.60185, 11.78714]],
  [3075679, [49.14149, 16.91369]],
  [3816688, [16.46472, -93.58611]],
  [3094638, [52.25592, 19.17037]],
  [697450, [46.96951, 30.95914]],
  [2858906, [49.02611, 9.32083]],
  [670660, [47.65369, 22.73236]],
  [2965070, [53.22611, -8.73306]],
  [4023321, [21.28888, -101.27582]],
  [3394817, [-9.56722, -48.39167]],
  [3174154, [45.84891, 9.3763]],
  [3186511, [46.49183, 15.62315]],
  [2705584, [57.51529, 14.27746]],
  [3516923, [17.25316, -96.87825]],
  [2954012, [50.17819, 9.57547]],
  [3104435, [40.95917, -6.48904]],
  [3173560, [40.78468, 14.75369]],
  [2869808, [48.1004, 8.42219]],
  [1855203, [30.73333, 131.0]],
  [1492299, [51.29167, 85.67722]],
  [3505128, [19.72693, -70.92294]],
  [1683690, [5.81167, 125.17278]],
  [7081757, [-8.00635, 112.76085]],
  [174764, [39.90802, 44.66721]],
  [11085899, [18.88635, -99.62693]],
  [3010634, [43.92428, 4.26322]],
  [1631524, [-4.05421, 103.87668]],
  [3194918, [43.78853, 19.50033]],
  [1241964, [6.5831, 79.9593]],
  [2655172, [51.29158, 0.30478]],
  [4128767, [34.46426, -93.13379]],
  [3075127, [50.13173, 14.33879]],
  [4004392, [25.91248, -105.22319]],
  [6534279, [41.35082, 14.36803]],
  [3030902, [46.6356, -1.17222]],
  [6534282, [45.29259, 11.81841]],
  [3692029, [-6.93395, -76.77158]],
  [8347882, [-27.63026, 153.15784]],
  [3012497, [48.64827, 2.15292]],
  [3004334, [46.21451, 1.0816]],
  [2983912, [49.38777, 3.14924]],
  [2922008, [52.31362, 9.60033]],
  [663593, [45.83866, 27.69138]],
  [3527652, [15.03244, -92.15634]],
  [2613293, [55.52253, 9.72125]],
  [2669113, [58.18035, 13.95824]],
  [2657487, [54.89732, -2.25653]],
  [754210, [50.98037, 20.66314]],
  [2167777, [-33.87952, 151.23677]],
  [7626382, [63.23034, 11.03099]],
  [2949906, [52.5516, 7.94836]],
  [725588, [43.63472, 23.3535]],
  [5333944, [34.42333, -118.47203]],
  [1529103, [46.11651, 85.67769]],
  [3163941, [44.21365, 8.39553]],
  [1630639, [-5.35822, 104.97441]],
  [6138175, [45.565, -73.90554]],
  [1692985, [13.291, 123.5171]],
  [3027770, [45.56371, 5.37607]],
  [6068315, [53.95015, -115.13547]],
  [7455970, [-8.4817, 118.3064]],
  [7510964, [15.97439, 102.66983]],
  [4872729, [42.3236, -92.59936]],
  [2037002, [45.6374, 120.08348]],
  [8357714, [13.71215, 45.30318]],
  [7739060, [27.99607, 116.35509]],
  [2661494, [46.11367, 8.93324]],
  [584821, [40.99249, 45.62838]],
  [6534212, [45.35052, 9.20268]],
  [4875666, [42.4997, -95.29305]],
  [2468561, [33.87309, 7.87765]],
  [3201595, [46.30083, 16.105]],
  [8504949, [59.94091, 30.25377]],
  [6691072, [50.93331, 6.95954]],
  [2266895, [32.7162, -16.76758]],
  [1500764, [55.26889, 66.49472]],
  [3625965, [10.79006, -68.32564]],
  [3036724, [44.99725, -0.68892]],
  [3177087, [45.67408, 10.85911]],
  [2069647, [-33.95057, 120.12635]],
  [2654998, [51.44914, -1.13082]],
  [2070147, [-34.35763, 138.68074]],
  [312405, [36.79944, 36.51778]],
  [675741, [46.38758, 24.46444]],
  [3825205, [17.70315, -95.07213]],
  [8863188, [17.37694, -99.02083]],
  [3028512, [48.56743, -0.14564]],
  [2834963, [50.90977, 9.44658]],
  [131691, [35.962, 50.6799]],
  [1818023, [34.04488, 113.26256]],
  [1638182, [-8.1346, 113.8752]],
  [2646552, [51.13908, 0.42881]],
  [2845273, [51.03333, 6.68333]],
  [3218907, [45.91444, 14.22583]],
  [3648187, [10.96757, -64.1805]],
  [8392969, [-6.9508, 113.6066]],
  [2648347, [51.7485, 0.75239]],
  [1796281, [33.22952, 113.44726]],
  [3628142, [8.88724, -64.16512]],
  [1492009, [52.16985, 85.85573]],
  [3183814, [40.76889, 19.87778]],
  [7035576, [-8.0934, 112.463]],
  [3456814, [-16.735, -43.86167]],
  [2872777, [48.99958, 8.80337]],
  [3089298, [51.80384, 18.72311]],
  [3518352, [19.32536, -98.49863]],
  [3179546, [44.43556, 10.40329]],
  [3068311, [50.53247, 15.8202]],
  [3182514, [41.70217, 14.45861]],
  [2266702, [39.0473, -8.70236]],
  [238373, [4.62275, 21.88645]],
  [8080337, [-6.88, 112.2726]],
  [3061431, [50.55528, 14.15665]],
  [4593372, [34.87957, -81.96621]],
  [616159, [40.79849, 44.10144]],
  [2772317, [48.14393, 13.40521]],
  [2980796, [47.65595, 1.48847]],
  [8410160, [-9.9543, 124.4632]],
  [788105, [42.12556, 21.59778]],
  [2981204, [43.97165, 4.27838]],
  [1850345, [34.4833, 136.84186]],
  [306517, [39.50832, 29.76233]],
  [767310, [49.9892, 22.46378]],
  [3172810, [42.67682, 13.98966]],
  [2989860, [47.09496, -0.79596]],
  [3708911, [7.81531, -80.28345]],
  [2639079, [57.41145, -2.46455]],
  [3183329, [41.43225, 15.12304]],
  [2522720, [39.88505, 8.77831]],
  [2634768, [54.43431, -6.29929]],
  [1816544, [25.0, 114.58333]],
  [1493268, [54.9901, 62.5442]],
  [685868, [46.31047, 23.79399]],
  [3118636, [42.60718, -1.40728]],
  [3094105, [50.06408, 19.49215]],
  [3530044, [19.03375, -99.20428]],
  [1684553, [14.09532, 120.93355]],
  [5108688, [40.95982, -73.06788]],
  [8068034, [-6.6455, 111.4832]],
  [1861949, [35.3, 136.8]],
  [8348006, [-34.93478, 138.63639]],
  [1688749, [14.3595, 121.0473]],
  [685126, [44.78803, 21.51368]],
  [8348779, [-38.10793, 144.33839]],
  [2057132, [-10.6146, 123.3852]],
  [2798023, [50.40578, 3.89603]],
  [8347840, [-33.05645, 151.64955]],
  [2980266, [46.14446, 1.51369]],
  [3054648, [47.51083, 18.92717]],
  [8859028, [19.87417, -97.58083]],
  [571987, [53.03333, 51.71667]],
  [8067935, [-6.9981, 111.3134]],
  [4002426, [20.73694, -103.99806]],
  [2969106, [45.63403, 5.42657]],
  [671228, [45.0, 25.98333]],
  [3518478, [18.67649, -97.85793]],
  [3527004, [20.20357, -98.57603]],
  [2751040, [50.96167, 5.74167]],
  [4332143, [29.92394, -91.78305]],
  [1720786, [7.24639, 122.23417]],
  [5552301, [33.4356, -112.3496]],
  [2521873, [38.91667, -0.28333]],
  [3084483, [49.92103, 18.76637]],
  [6615338, [51.51414, -0.1551]],
  [8950198, [41.62298, 13.50762]],
  [3180578, [45.3995, 7.9602]],
  [617914, [47.63632, 28.14296]],
  [739816, [40.55652, 42.34266]],
  [8417351, [39.65019, 111.83683]],
  [314677, [37.41683, 41.26994]],
  [5591399, [43.72325, -111.11133]],
  [4019820, [18.41942, -100.07237]],
  [8348819, [-27.53781, 152.86187]],
  [6385665, [-7.51278, 109.02722]],
  [2523229, [39.61465, 8.82088]],
  [1898292, [31.43624, 120.55601]],
  [2980761, [47.46071, -2.00415]],
  [3035867, [47.90181, -0.35195]],
  [3518724, [17.85985, -96.58647]],
  [5138674, [43.05812, -76.20743]],
  [3166122, [45.41123, 12.01418]],
  [3014208, [47.82529, -1.8422]],
  [2310046, [1.86391, 9.76582]],
  [1608527, [15.70472, 100.13717]],
  [7257798, [41.27738, -72.81511]],
  [3982266, [32.56717, -116.62509]],
  [3942602, [-15.16307, -73.29399]],
  [146654, [34.87551, 33.58684]],
  [1731572, [9.46694, 125.57722]],
  [2156878, [-38.21792, 145.03876]],
  [293783, [32.14613, 34.8394]],
  [8860150, [19.43472, -101.92167]],
  [2751129, [53.32277, 5.80198]],
  [736602, [40.71799, 22.29545]],
  [2172318, [-33.42406, 148.80904]],
  [747030, [40.42153, 42.34799]],
  [2340576, [11.31568, 7.44957]],
  [2990420, [48.95165, 7.64271]],
  [2053350, [40.49526, 123.32985]],
  [791429, [41.06944, 21.45583]],
  [3517254, [17.04712, -96.63573]],
  [1705222, [5.87472, 124.88833]],
  [2938880, [49.15, 7.88333]],
  [2032007, [43.57083, 104.425]],
  [7591845, [-10.205, 120.5344]],
  [3103719, [50.32607, 19.12565]],
  [3018605, [47.52415, 6.90535]],
  [3523298, [17.17563, -95.91174]],
  [1269970, [14.23976, 75.64507]],
  [265082, [38.38333, 22.63333]],
  [697802, [48.51668, 24.88317]],
  [294995, [32.39775, 34.93213]],
  [4555204, [35.23759, -96.24167]],
  [1796317, [27.24306, 119.72389]],
  [2824770, [49.65002, 9.90389]],
  [3129343, [42.58867, -1.76504]],
  [4807374, [39.55814, -80.18174]],
  [3170591, [41.92244, 14.23632]],
  [3021774, [48.2479, 0.25733]],
  [616361, [40.37793, 45.18048]],
  [11608841, [50.44718, 10.94951]],
  [2068416, [-35.03825, 138.51635]],
  [8949562, [44.79164, 8.25124]],
  [1605483, [16.93636, 104.71039]],
  [1630202, [-7.4789, 108.7414]],
  [2951321, [50.26667, 6.88333]],
  [8401600, [-6.951, 113.7559]],
  [2726275, [59.28333, 17.76667]],
  [3053489, [46.80713, 18.92763]],
  [8348609, [-32.52429, 115.76315]],
  [3818875, [18.86722, -97.98475]],
  [5259202, [43.13084, -88.54454]],
  [3191156, [44.29896, 18.85532]],
  [3115691, [41.33036, -5.08095]],
  [281356, [31.70701, 35.10366]],
  [680038, [47.78266, 26.51664]],
  [8861178, [20.58222, -101.46083]],
  [3116968, [42.5, -8.75]],
  [4743274, [30.12938, -97.47277]],
  [3120414, [40.18442, -2.90889]],
  [2070830, [-34.98079, 138.515]],
  [5731777, [45.7054, -121.52146]],
  [663984, [45.315, 26.87748]],
  [1784585, [35.6775, 107.19639]],
  [2862914, [50.52864, 7.83168]],
  [4035113, [-13.68733, -172.61864]],
  [545277, [47.58278, 41.09222]],
  [2759554, [51.4475, 4.92917]],
  [2654211, [53.81667, -0.2]],
  [3181102, [43.52774, 13.55257]],
  [3984691, [20.0069, -101.02189]],
  [2774482, [46.59306, 14.18889]],
  [787016, [41.46722, 21.12306]],
  [1829595, [13.59623, 103.9639]],
  [2995334, [48.66667, 1.4]],
  [3077184, [49.89412, 18.46079]],
  [2950564, [54.31667, 9.21667]],
  [3091831, [50.17103, 18.9041]],
  [1106643, [-20.20151, 57.77448]],
  [1648916, [-8.2088, 111.7755]],
  [883183, [-18.96167, 32.40557]],
  [2938931, [53.88719, 14.06596]],
  [674526, [44.23333, 26.58333]],
  [2993024, [47.99696, 2.73261]],
  [7415125, [-8.6604, 121.2238]],
  [463655, [45.92407, 47.04739]],
  [8416218, [29.70317, 107.39521]],
  [3674678, [5.29622, -75.8839]],
  [787610, [45.59861, 20.13556]],
  [3086288, [52.33046, 19.86766]],
  [8349421, [-34.99601, 117.85925]],
  [3944134, [-14.47278, -71.39389]],
  [684666, [43.9, 23.5]],
  [1698063, [8.96917, 125.29472]],
  [4146823, [25.88759, -80.13116]],
  [3038744, [48.7859, 1.44006]],
])

export default Coordinates
